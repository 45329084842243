var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        "max-width": "500px",
        "sub-title": _vm.$t("fileShareDesc"),
        title: _vm.$t("fileShare"),
        contentClass: "file-share-wrapper",
        "show-actions": "",
        "show-bottom-divider": "",
        "auto-close": false,
        cancelButtonText: _vm.$t(_vm.cancelText),
        confirmButtonText: _vm.$t(_vm.confirmText),
        loading: _vm.loading,
        "confirm-button-disabled": _vm.isConfirmButtonDisabled,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c(
        "div",
        { staticClass: "file-share-modal mb-4" },
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "file-share px-8" },
                  [
                    _c("TextField", {
                      attrs: {
                        "hide-details": "",
                        label: _vm.$t("searchUsersByUserName"),
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between mt-3 mb-6",
                      },
                      [
                        _c("Checkbox", {
                          staticClass: "file-share--select-all",
                          attrs: {
                            color: "white",
                            "hide-details": "",
                            label: _vm.$t("shareWithAllRoom"),
                            "input-value": _vm.allRoom,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.changeAllRoomStatus.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "file-share--count" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("participantSelected", {
                                count: _vm.selectedUserCount,
                              })
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "file-share--user-list" },
                      _vm._l(_vm.filteredUsers, function (user) {
                        return _c(
                          "div",
                          {
                            key: user.id,
                            staticClass:
                              "d-flex cursor-pointer mb-4 align-center",
                            on: {
                              click: function ($event) {
                                return _vm.selectUser(user.id)
                              },
                            },
                          },
                          [
                            _c("Checkbox", {
                              staticClass: "file-share--select-user",
                              attrs: {
                                color: "white",
                                "hide-details": "",
                                "input-value":
                                  _vm.allRoom ||
                                  _vm.selectedUsers.indexOf(user.id) !== -1,
                              },
                            }),
                            _c("Avatar", {
                              attrs: {
                                "display-name": user.displayName,
                                image: user.avatar,
                                "avatar-size": 48,
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ml-4 file-share--user-list--name ellipsis",
                              },
                              [_vm._v(_vm._s(user.displayName))]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm.isWebinarStarted
                      ? _c("Checkbox", {
                          staticClass: "file-share--share-with-webinar",
                          attrs: {
                            color: "white",
                            "hide-details": "",
                            label: _vm.$t("shareWithWebinar"),
                          },
                          model: {
                            value: _vm.shareWithWebinar,
                            callback: function ($$v) {
                              _vm.shareWithWebinar = $$v
                            },
                            expression: "shareWithWebinar",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-tab-item", [
                _c("div", { staticClass: "file-upload" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropzone d-flex flex-column align-center justify-space-between px-5 pb-1",
                      on: { dragover: _vm.dragover, drop: _vm.drop },
                    },
                    [
                      _c("Icon", {
                        staticClass: "mr-4",
                        attrs: { icon: "paperclip" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "choose-file-wrapper d-flex align-center justify-center",
                        },
                        [
                          _c("label", { attrs: { for: "assetsFieldHandle" } }, [
                            _c("input", {
                              ref: "file",
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                multiple: "",
                                name: "fields[assetsFieldHandle][]",
                                id: "assetsFieldHandle",
                              },
                              on: { change: _vm.onChange },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "choose-file cursor-pointer mr-1",
                              },
                              [_vm._v(_vm._s(_vm.$t("chooseAFile")))]
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("or")) +
                                " " +
                                _vm._s(_vm.$t("dragAndDrop"))
                            ),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "dropzone--sub-text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("maxSize", { size: 50, type: "MB" }))
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "file-upload--list my-6 mx-5" },
                    _vm._l(_vm.filelist, function (file, index) {
                      return _c("FileUploadItem", {
                        key: file.name,
                        staticClass: "mb-7",
                        attrs: {
                          index: index,
                          fileName: file.name,
                          progress: _vm.fileStatus[file.name],
                          status: _vm.fileState[file.name],
                        },
                        on: { delete: _vm.deleteFile },
                      })
                    }),
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }