<template>
  <Modal
    :title="$t('profileSettings')"
    show-actions
    :confirm-button-disabled="!localDisplayName"
    :confirm-button-text="$t('save')"
    :cancel-button-text="$t('cancel')"
    persistent
    @close="onModalClose"
  >
    <div class="mb-3">
      <LangSwitch />
    </div>
    <div>
      <v-form autocomplete="off">
        <text-field
          class="mb-4"
          v-model="localDisplayName"
          :label="$t('userName')"
          :error-messages="errors"
          hide-details
          @keydown.enter.prevent="save"
        ></text-field>
      </v-form>
    </div>
  </Modal>
</template>

<script>
import LangSwitch from '@/components/Shared/LangSwitch';

export default {
  name: 'ModeratorCameraPermissionWarning',
  components: {
    LangSwitch,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    displayName: {
      type: [String, Number],
    },
  },
  data() {
    return {
      localDisplayName: '',
      errors: null,
    };
  },
  methods: {
    onModalClose(type) {
      if (type === 'confirm') {
        this.save();
      } else {
        this.close();
      }
    },
    save() {
      this.errors = !this.localDisplayName ? [this.$t('requiredField')] : null;

      if (this.localDisplayName) {
        this.$emit('save', { displayName: this.localDisplayName });
      }
    },
    close() {
      this.$emit('close', { displayName: this.localDisplayName });
    },
  },
  watch: {
    displayName: {
      handler: function() {
        this.localDisplayName = this.displayName;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
