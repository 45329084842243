<template>
  <div v-if="leftBottomLogo" class="logoLeftBottom">
    <img :src="leftBottomLogo" />
  </div>
</template>

<script>
export default {
  name: 'LogoLeftBottom',
  computed: {
    leftBottomLogo() {
      return this.$customerConfig?.resources?.leftBottomLogo || '';
    },
  },
};
</script>

<style lang="scss">
.logoLeftBottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 0;

  img {
    height: auto;
    max-height: 200px;
    max-width: 300px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .logoLeftBottom {
    position: absolute;
    bottom: 60px;
    left: 1px;
    z-index: 0;

    img {
      height: auto;
      max-height: 150px;
      max-width: 200px;
    }
  }
}
</style>
