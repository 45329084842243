<template>
  <div class="survey-nav">
    <icon icon="chevron-left" class="back-button mr-6" v-if="showBackButton" @click="back" />
    <div>{{ title }}</div>
  </div>
</template>

<script>
import Icon from '@/components/Shared/Icon';

export default {
  name: 'SurveyNav',
  components: {
    Icon,
  },
  props: {
    title: String,
    showBackButton: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-nav {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  font-weight: normal !important;

  .back-button {
    cursor: pointer;
  }
}
</style>
