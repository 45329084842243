<template>
  <div class="notification-hands-up d-flex w-full justify-space-between align-center">
    <div class="d-flex">
      <div class="d-flex flex-column w-full">
        <span class="user-name">{{ user.displayName }}</span>
        <div class="content d-flex">
          <Icon icon="hand" color="warning" :size="20" />
          <span class="action-text ml-2">{{ $t(`notifications.${notification.text}`) }}</span>
        </div>
      </div>
    </div>
    <div class="actions d-flex" v-if="showAllowSpeakRequestButton">
      <div class="action check text-center mr-3 d-flex justify-center align-center cursor-pointer" @click="confirm">
        <Icon v-if="!loading" icon="check" color="white" :size="14" />
        <v-progress-circular v-else indeterminate color="white" :size="14" :width="2"></v-progress-circular>
      </div>
      <div class="action close text-center cursor-pointer" @click="cancel">
        <Icon icon="close" color="white" :size="14" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NotificationFileShare',
  props: {
    user: {
      type: Object,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getUserById', 'getLocalUser']),
    showAllowSpeakRequestButton() {
      return this.getLocalUser.role === 'moderator';
    },
  },
  methods: {
    ...mapActions('Conference', ['allowSpeakRequest', 'handsDown']),
    ...mapMutations('Notification', ['REMOVE_ROOM_NOTIFICATION']),
    confirm() {
      this.allowSpeakRequest({ participantId: this.notification.userId });
      this.REMOVE_ROOM_NOTIFICATION(this.notification.id);
    },
    cancel() {
      this.handsDown({ participantId: this.notification.userId });
      this.REMOVE_ROOM_NOTIFICATION(this.notification.id);
    },
  },
};
</script>
