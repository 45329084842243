import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home';
import Meet from '../views/Meet';
import Webinar from '@/views/Webinar';
import Jibri from '@/views/Jibri';
import Rejected from '@/views/Rejected';
import PreJoin from '@/views/PreJoin';
import Kicked from '@/views/Kicked';
import ConferenceEnded from '@/views/ConferenceEnded';
import Theme from '@/views/Theme';
import PrivateRoomEntrance from '@/views/PrivateRoomEntrance';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/prejoin/:roomName',
    name: 'prejoin',
    component: PreJoin,
  },
  {
    path: '/jibri/:roomName',
    name: 'jibri',
    component: Jibri,
    props: true,
  },
  {
    path: '/rejected',
    name: 'rejected',
    component: Rejected,
    props: true,
  },
  {
    path: '/theme',
    name: 'theme',
    component: Theme,
  },
  {
    path: '/kicked',
    name: 'kicked',
    component: Kicked,
    props: true,
  },
  {
    path: '/ended',
    name: 'ended',
    component: ConferenceEnded,
    props: true,
  },
  {
    path: '/webinar/:roomName',
    name: 'webinar',
    component: Webinar,
    props: true,
  },
  {
    path: '/private-room-entrance/:roomName?',
    name: 'privateRoomEntrance',
    component: PrivateRoomEntrance,
  },
  {
    path: '/:roomName',
    name: 'meet',
    component: Meet,
    props: true,
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('@/views/NotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
