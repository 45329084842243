var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-settings pa-3 pl-6" },
    [
      _c("div", { staticClass: "device-settings-title pa-0 mb-5" }, [
        _vm._v(" " + _vm._s(_vm.$t("audioAndVideo")) + " "),
      ]),
      _c(
        "v-col",
        { staticClass: "px-0", attrs: { cols: "12", sm: "12", md: "12" } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("div", { staticClass: "label mr-3" }, [
                _vm._v(_vm._s(_vm.$t("noiseCanceling"))),
              ]),
              _c("v-switch", {
                staticClass: "my-0",
                attrs: { color: "success", "hide-details": true },
                model: {
                  value: _vm.noiseCanceling,
                  callback: function ($$v) {
                    _vm.noiseCanceling = $$v
                  },
                  expression: "noiseCanceling",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("DeviceSelector", {
        ref: "deviceSelector",
        on: {
          cameraChange: _vm.handleCameraChange,
          microphoneChange: _vm.handleMicrophoneChange,
          speakerChange: _vm.handleSpeakerChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }