export const DEVICES = {
  kinds: {
    MICROPHONE: 'audioinput',
    SPEAKER: 'audiooutput',
    CAMERA: 'videoinput',
  },
  types: {
    audioinput: 'audio',
    audiooutput: 'speaker',
    videoinput: 'video',
  },
};

export const MEDIA_DEVICE_STATE = {
  PROMPT: 1,
  NOT_ALLOWED_ERROR: 2,
  NOT_FOUND_ERROR: 3,
  ALLOWED: 4,
};

export const CHANGE_DEVICE_TYPE = {
  ADDED: 'added',
  REMOVED: 'removed',
};
