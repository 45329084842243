const videoLayoutCache = {};
export function calculateVideoLayout(participantCount, screenSize) {
  const aspectRatio = 16 / 9;
  const { screenWidth, screenHeight } = screenSize;
  const cacheKey = `${participantCount}_${screenWidth}_${screenHeight}`;
  let defaultLayout = {
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
  };

  if (videoLayoutCache[cacheKey]) {
    return videoLayoutCache[cacheKey];
  }

  const h = screenHeight - 192;

  for (let cols = 1; cols <= participantCount; cols += 1) {
    const rows = Math.ceil(participantCount / cols);
    const hScale = screenWidth / (cols * aspectRatio);
    const vScale = h / rows;
    let width;
    let height;
    if (hScale <= vScale) {
      width = Math.floor(screenWidth / cols);
      height = Math.floor(width / aspectRatio);
    } else {
      height = Math.floor(h / rows);
      width = Math.floor(height * aspectRatio);
    }
    const area = width * height;
    if (area > defaultLayout.area) {
      defaultLayout = {
        area,
        width,
        height,
        rows,
        cols,
      };
    }
  }
  videoLayoutCache[cacheKey] = defaultLayout;
  return defaultLayout;
}
