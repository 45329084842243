var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "expension-panel-notification-item d-flex justify-space-between align-center my-4 px-4",
    },
    [
      _c(
        "div",
        { staticClass: "mr-3 d-flex justify-center align-center" },
        [
          _c("span", {
            staticClass: "notification-highlight mr-2",
            class: { unread: !_vm.notification.read },
          }),
          _c("avatar", {
            staticClass: "avatar",
            attrs: {
              iconSize: "50%",
              "avatar-size": 48,
              "display-name": _vm.avatarProperties.displayName,
              image: _vm.avatarProperties.avatar,
            },
          }),
        ],
        1
      ),
      _c(_vm.notificationComponent, {
        tag: "component",
        attrs: { notification: _vm.notification, user: _vm.user },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }