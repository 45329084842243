export const ONPREMISE_FEATURES = {
  fileShare: true,
  roomPassword: true,
  screenShare: true,
  userStatistics: true,
  chat: true,
  videoShare: true,
  sendMeetingRequest: true,
  reports: true,
  highDefinitionAudio: true,
  highDefinitionVideo: true,
  bgCustomize: true,
  layoutCustomize: true,
  survey: true,
  lobby: true,
  record: true,
  recording: true,
  handsUp: true,
  whiteBoard: true,
  SIP: true,
  E2EE: true,
  livestreaming: true,
};
