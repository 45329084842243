<template>
  <div class="survey-list-item d-flex align-center mb-2" :class="{ shared: data.shared && data.sharedWithWebinar }" @click="go">
    <span class="text-body-2">{{ itemName }}</span>
    <v-spacer />
    <icon size="14" class="ml-3" icon="eye" v-if="!isPrivate" v-tooltip="$t('publicSurvey')" />
    <icon size="14" class="ml-3 trash-icon" icon="trash" v-if="showActionButtons" v-tooltip="$t('delete')" @click.stop="showConfirm('delete')" />
    <icon size="14" class="ml-3" icon="edit" v-if="showActionButtons" v-tooltip="$t('edit')" @click.stop="edit" />
    <icon size="14" class="ml-3" icon="share-2" v-if="isSharable" v-tooltip="$t('shareSurvey')" @click.stop="showConfirm('share')" />
    <icon size="14" class="ml-3" icon="bar-chart-2" v-if="showResultButton" v-tooltip="$t('showResult')" @click.stop="goToResult" />
    <v-progress-linear indeterminate style="width: 80px" v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '@/components/Shared/Icon';

export default {
  name: 'SurveyListItem',
  components: {
    Icon,
  },
  props: {
    data: Object,
    isMySurveys: Boolean,
    isSharedSurvey: Boolean,
  },
  data() {
    return {
      questions: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('Webinar', ['webinarSessionId']),
    ...mapGetters('Webinar', ['getLocalUser']),
    ...mapGetters('Conference', { conferenceLocalUser: 'getLocalUser', conferenceUsers: 'getUsers' }),
    itemName() {
      return this.data.Title;
    },
    showActionButtons() {
      return this.isMySurveys && !this.loading;
    },
    showShareButton() {
      return this.webinarSessionId ? this.data.shared && this.data.sharedWithWebinar : this.data.shared;
    },
    isWebinarLocalUser() {
      return this?.getLocalUser?.role === 'moderator';
    },
    isSharable() {
      return !this.loading && this.isMySurveys && !this.showShareButton;
    },
    isPrivate() {
      return this.data.IsPrivate;
    },
    showResultButton() {
      return !this.loading && (this.isMySurveys || this.isSharedSurvey);
    },
  },
  methods: {
    showConfirm(type = 'delete') {
      this.$emit('openConfirm', {
        type,
        survey: {
          ...this.data,
          shareWithRoom: this.data.shared ? false : true,
          shareWithWebinar: false,
        },
      });
    },
    go() {
      const eventName = this.isMySurveys ? 'goToDetail' : 'goToSurvey';
      if (!this.isSharedSurvey) this.$emit(eventName, this.data);
    },
    goToResult() {
      this.$emit('goToResult', this.data);
    },
    edit() {
      this.$emit('edit', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-list-item {
  cursor: pointer;
  border: 1px solid var(--v-primary-base);
  border-radius: 8px;
  padding: 16px 12px;
  overflow-wrap: anywhere;
  .trash-icon {
    min-width: 14px;
  }
}
</style>
