var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pagination-box d-flex align-center justify-center",
      class: { hidden: _vm.hide },
    },
    [
      _c("icon", {
        staticClass: "mx-2 cursor-pointer",
        attrs: { icon: "chevron-left" },
        nativeOn: {
          click: function ($event) {
            return _vm.goToPreviousPage.apply(null, arguments)
          },
        },
      }),
      _c("div", { staticClass: "mx-1" }, [
        _vm._v(" " + _vm._s(_vm.activePage) + " "),
        _c("span", { staticClass: "px-1" }, [_vm._v("/")]),
        _vm._v(" " + _vm._s(_vm.getLastPage) + " "),
      ]),
      _c("icon", {
        staticClass: "mx-2 cursor-pointer",
        attrs: { icon: "chevron-right" },
        nativeOn: {
          click: function ($event) {
            return _vm.goToNextPage.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }