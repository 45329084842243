<template>
  <div class="webinar-top-menu d-flex align-center" :class="{ mobile: $isMobile }">
    <div class="d-flex flex-grow-1">
      <logo class="mr-8" />
      <div v-if="webinarTitle" class="webinar-title">
        {{ webinarTitle }}
      </div>
      <div class="flex-grow-1 d-flex justify-end">
        <participant-counter v-if="!$isMobile && isWebinarStarted" class="mr-5" webinar />
        <menu-button v-if="showActions" icon="settings" :useInnerModel="true" @click="openSettingsModal" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ParticipantCounter from '@/components/Shared/ParticipantCounter';

export default {
  name: 'WebinarTopMenu',
  components: {
    ParticipantCounter,
  },
  props: {
    showActions: {
      type: Boolean,
      default: true,
    },
    isWebinarStarted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Webinar', ['webinarTitle']),
  },
  methods: {
    ...mapActions('Webinar', ['fetchTitle']),
    openSettingsModal() {
      this.$emit('openSettingsModal');
    },
  },
  watch: {
    isWebinarStarted() {
      if (this.isWebinarStarted) {
        this.fetchTitle();
      }
    },
  },
};
</script>
<style lang="scss">
.webinar-top-menu {
  z-index: 10;
  height: 96px;
  padding: 0 40px;
  .webinar-title {
    z-index: 1;
    font-size: 13px;
    max-width: 50vw;
    max-height: 3.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    word-wrap: break-word;
    position: absolute;
    left: 12rem;
    top: 2.2rem;
  }
  &.mobile {
    height: 50px;
    margin-top: 0.5rem;

    img {
      height: 22px;
    }
    .webinar-title {
      max-width: 90vw;
      font-size: 10px;
      left: 1rem;
      top: 4rem;
    }
  }
}
</style>
