<template>
  <div class="avatar" :class="{ 'avatar--absolute': absolute, 'avatar--fullscreen': fullScreen }">
    <div class="avatar-wrapper">
      <template v-if="error">
        <div class="avatar-default-icon" :alt="displayName" :title="displayName" :style="computedStyle">
          <icon icon="user" :style="{ width: iconSize, height: iconSize }" />
        </div>
      </template>
      <template v-else-if="loading">
        <div class="avatar-default-icon" :alt="displayName" :title="displayName" :style="computedStyle">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </template>
      <template v-else-if="image">
        <img class="avatar-image" :src="image" :alt="displayName" :title="displayName" @error="imgOnError" :style="computedStyle" />
      </template>
      <template v-else>
        <img
          v-if="autoRandomAvatar"
          :src="randomAvatar"
          class="avatar-image"
          :alt="displayName"
          :title="displayName"
          @error="imgOnError"
          :style="computedStyle"
        />
        <div v-else class="avatar-default-icon" :alt="displayName" :title="displayName" :style="computedStyle">
          <icon icon="user" :style="{ width: iconSize, height: iconSize }" />
        </div>
      </template>
      <div v-if="editable" data-test-id="G-AU-A-AAI" class="add-icon">
        <icon icon="plus" size="18"></icon>
      </div>
    </div>
  </div>
</template>

<script>
import { PREDEFINED_RANDOM_AVATARS } from '@/constants/avatar';

export default {
  name: 'Avatar',
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    displayName: {
      type: String,
    },
    absolute: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '30%',
    },
    minAvatarSize: {
      type: Number,
      default: 48,
    },
    avatarSize: {
      type: Number,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    autoRandomAvatar() {
      return this.$customerConfig?.system?.users?.autoRandomAvatar;
    },
    computedStyle() {
      let avatarStyle = {
        'min-width': `${this.minAvatarSize}px`,
        'min-height': `${this.minAvatarSize}px`,
      };
      if (this.avatarSize) {
        avatarStyle.width = `${this.avatarSize}px`;
        avatarStyle.height = `${this.avatarSize}px`;
      }
      return avatarStyle;
    },
    randomAvatar() {
      return PREDEFINED_RANDOM_AVATARS[Math.floor(Math.random() * PREDEFINED_RANDOM_AVATARS.length)];
    },
  },
  methods: {
    imgOnError() {
      this.error = true;
    },
  },
  watch: {
    image() {
      this.error = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-self: center;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
  position: relative;

  &--absolute {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .avatar-image {
    border-radius: 50%;
    width: calc(var(--card-width) * 0.2);
    height: calc(var(--card-width) * 0.2);
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .avatar-default-icon {
    border-radius: 50%;
    width: calc(var(--card-width) * 0.2);
    height: calc(var(--card-width) * 0.2);
    background: linear-gradient(132.45deg, rgba(248, 248, 248, 0.4) 15.74%, rgba(248, 248, 248, 0.1) 88.03%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--v-date-base);
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    height: 32px;
    width: 32px;
    border: 4px solid var(--v-darkblue-base);
  }

  .avatar-wrapper {
    float: left;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
  }
}
@media (max-width: $breakpoint-tablet) {
  .avatar-wrapper {
    font-size: 15px !important;
  }
}
@media (max-width: $breakpoint-mobile) {
  .video-card:not(.my-video-card) {
    .avatar {
      border-radius: 0;
    }
  }
}
</style>
