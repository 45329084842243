import JitsiMeetJS from "../JitsiMeetLib";
import MediaDevices from "./MediaDevices";
import RoomConfig from "./RoomConfig";
import ENUMS, { MEDIA_DEVICE_TYPE } from "../constants/enums";
import axios from "axios";
import { parseJwt } from "../helpers/jwt";

import { consoleError, consoleLog, consoleWarning } from "../utils/logger"; //eslint-disable-line

import EventEmitter from "../utils/EventEmitter";
import { ErrorWithCodes } from "../errors/errors";
import PresenterEffect from "./stream-effects/presenter/PresenterEffect";
import { AudioMixerEffect } from "./stream-effects/audio-mixer/AudioMixerEffect";
import { NoiseSuppressionEffect } from "./stream-effects/noise-suppression/NoiseSuppressionEffect";
import request from "../services/Request";
const eventEmitter = new EventEmitter();

const {
  START_MUTED_POLICY,
  START_VIDEO_MUTED_POLICY,
  MODERATOR_VIDEO_MUTED,
  MODERATOR_AUDIO_MUTED,
  USER_VIDEO_MUTED,
  USER_AUDIO_MUTED,
} = ENUMS.RoomConfigEnums;
let conferenceEventEmitter = null;

class LocalUser {
  constructor(conference, conferenceEventEmitter_) {
    this.tracks = [];
    this.conferenceInstance = conference;
    this.user = { id: null, displayName: "", role: "participant", isJibri: false };
    this.audioMutedByModerator = false;
    this.videoMutedByModerator = false;
    this.screenSharing = false;
    this.openCameraAfterScreenShare = false;
    this.audioContainerId = null;
    this.videoContainerId = null;
    this.lobbyAutoJoinRequest = false;
    conferenceEventEmitter = conferenceEventEmitter_;
    this.activeEffect = null;
    this.desktopAudioStream = null;
    this.prejoinConfig = {
      startWithAudioMuted: false,
      startWithVideoMuted: false,
    };
    this.noiseSuppressionEnabled = false;
    this.checkIsJibri();
  }

  get getUser() {
    return this.user;
  }

  get videoElementId() {
    return `local-video-${this.getUser.id}`;
  }
  get audioElementId() {
    return `local-audio-${this.getUser.id}`;
  }

  async initWithRoomConfig() {
    try {
      const roomConfigObject = await RoomConfig.fetchDataSource();
      const startMutedPolicy = roomConfigObject[START_MUTED_POLICY];
      const startVideoMutedPolicy = roomConfigObject[START_VIDEO_MUTED_POLICY];

      this.onAudioStartMutedPolicyChange(startMutedPolicy, this.prejoinConfig.startWithAudioMuted);
      this.onVideoStartMutedPolicyChange(startVideoMutedPolicy, this.prejoinConfig.startWithVideoMuted);
    } catch (err) {
      consoleError("InitWithRoomConfigError: ", err);
    }
  }

  setUserId(id) {
    this.user.id = id;
  }

  setUserRole(role) {
    this.user.role = role;
  }

  setVideoContainerId(id) {
    this.videoContainerId = id;
  }

  setAudioContainerId(id) {
    this.audioContainerId = id;
  }

  setAudioMutedByModerator(state) {
    this.audioMutedByModerator = state;
    conferenceEventEmitter.emit("UserAudioMutedByModeratorChanged", { userId: this.getUser.id, audioMutedByModerator: state });
  }

  setVideoMutedByModerator(state) {
    this.videoMutedByModerator = state;
    conferenceEventEmitter.emit("UserVideoMutedByModeratorChanged", { userId: this.getUser.id, videoMutedByModerator: state });
  }

  setLobbyAutoJoinRequest(state) {
    this.lobbyAutoJoinRequest = state;
  }

  setDisplayName(name) {
    this.user.displayName = name;
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setDisplayName(name);
    }
  }

  setSenderVideoConstraint(resolution = 720) {
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setSenderVideoConstraint(resolution);
    }
  }

  setReceiverVideoConstraint(resolution = 720) {
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setReceiverVideoConstraint(resolution);
    }
  }

  setPrejoinConfig(config) {
    this.prejoinConfig = {
      ...this.prejoinConfig,
      ...config,
    };
  }

  checkIsJibri() {
    const usernameOverride = localStorage.getItem("xmpp_username_override");
    const passwordOverride = localStorage.getItem("xmpp_password_override");

    if (usernameOverride && passwordOverride) {
      this.user.isJibri = true;
      return true;
    }

    return false;
  }

  setAvatar(avatar) {
    this.setLocalParticipantProperty("avatar", avatar);
  }

  setSpeakerMuted(isMuted) {
    this.setLocalParticipantProperty("speakerMuted", isMuted.toString());
  }

  setLocalParticipantProperty(prop, value) {
    this.conferenceInstance.room.setLocalParticipantProperty(prop, value);
    conferenceEventEmitter.emit("UserPropertyChange", { userId: this.getUser.id, property: prop, value });
  }

  async setEffect(deviceId, attachElement = true) {
    const tracks = await JitsiMeetJS.createLocalTracks({
      devices: [MEDIA_DEVICE_TYPE.VIDEO],
      ...this.conferenceInstance.confOptions,
      cameraDeviceId: deviceId || MediaDevices.videoInputDeviceId,
      micDeviceId: MediaDevices.audioInputDeviceId,
    });

    const desktopTrack = this.tracks[1];
    const cameraTrack = tracks[0];
    const videoStream = cameraTrack.stream;
    const presenterEffect = new PresenterEffect(videoStream);
    presenterEffect.startEffect(desktopTrack.stream);
    await desktopTrack.setEffect(presenterEffect);
    this.activeEffect = presenterEffect;

    if (attachElement) {
      this.tracks[1].attach(document.getElementById(this.videoElementId));
    }
  }

  async removeEffect(attachElement = true) {
    if (this.activeEffect) {
      this.activeEffect.stopEffect();
    }
    if (this.tracks.length && this.tracks.length > 1) {
      await this.tracks[1].setEffect();
      if (attachElement) {
        this.tracks[1].attach(document.getElementById(this.videoElementId));
      }
    }
    this.activeEffect = null;
  }

  createLocalTracksF(deviceType, options = {}, firePermissionPromptIsShownEvent, muted) {
    if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
      options.cameraDeviceId = options.cameraDeviceId || MediaDevices.videoInputDeviceId || null;
    } else if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
      options.micDeviceId = options.micDeviceId || MediaDevices.audioInputDeviceId || null;
    }

    return new Promise((resolve, reject) => {
      JitsiMeetJS.createLocalTracks(
        {
          devices: [deviceType],
          ...options,
        },
        firePermissionPromptIsShownEvent,
      )
        .then(async (tracks) => {
          // Start muted
          if (muted) {
            await tracks[0].mute();
            this.onMuteChanged(tracks[0]);
          }
          if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
            this.tracks[0] = tracks[0];
          } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
            this.tracks[1] = tracks[0];
          }
          return resolve(tracks);
        })
        .catch((err) => {
          consoleError("Failed to create local tracks", deviceType, err);

          return reject(err);
        });
    });
  }

  createLocalTracks(deviceType, room, firePermissionPromptIsShownEvent = true, muted = false) {
    return new Promise((resolve, reject) => {
      this.createLocalTracksF(deviceType, {}, firePermissionPromptIsShownEvent, muted)
        .then((tracks) => {
          this.onLocalTrack(tracks, room, this.conferenceInstance.isJoined);
          resolve(tracks);
        })
        .catch((err) => {
          consoleError(err);
          reject(err);
        });
    });
  }

  replaceLocalTrack(track) {
    return new Promise(async (resolve) => {
      const room = this.conferenceInstance.room;

      let oldTrack = track.getType() === MEDIA_DEVICE_TYPE.AUDIO ? room.getLocalAudioTrack() : room.getLocalVideoTrack();
      const elementId = track.getType() === MEDIA_DEVICE_TYPE.AUDIO ? this.audioElementId : this.videoElementId;
      // If old track is exist, dispose it
      consoleLog("replaceLocalTrack", oldTrack);
      if (oldTrack) {
        try {
          await room.removeTrack(oldTrack);
          track.attach(document.getElementById(elementId));
          await room.replaceTrack(oldTrack, track);
          resolve();
        } catch (e) {
          consoleError("Xpermeet - Room remove track throw err: ", e);
        }
      } else {
        track.attach(document.getElementById(elementId));
        await room.addTrack(track);
        resolve();
      }
    });
  }

  /**
   * get keycloak token get sub property. set it to localParticipantProperty
   * @returns {boolean}
   */
  setKeycloakId() {
    const token = localStorage.getItem("keycloak-token") || null;
    if (!token) return false;
    const keycloakJwtObject = parseJwt(token);
    this.setLocalParticipantProperty("KeycloakId", keycloakJwtObject.sub);
  }

  switchLocalTrack(deviceType, deviceId) {
    return new Promise(async (resolve, reject) => {
      const room = this.conferenceInstance.room;

      let oldDeviceId;
      if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
        oldDeviceId = room.getLocalAudioTrack()?.deviceId;
      } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
        oldDeviceId = room.getLocalVideoTrack()?.deviceId;
      }
      if (oldDeviceId === deviceId) {
        resolve();
        consoleLog("Didn't change local track with same deviceId");
        return;
      }

      if (this.screenSharing && !this.activeEffect) {
        resolve();
        consoleLog("Didn't change local track");
        return;
      }

      if (deviceType === MEDIA_DEVICE_TYPE.VIDEO && this.videoMutedByModerator) {
        reject(ErrorWithCodes.MODERATOR_MUTED);
      }

      if (deviceType === MEDIA_DEVICE_TYPE.AUDIO && this.audioMutedByModerator) {
        reject(ErrorWithCodes.MODERATOR_MUTED);
      }

      if (deviceType === MEDIA_DEVICE_TYPE.VIDEO && this.screenSharing) {
        await this.removeEffect();
        await this.setEffect(deviceId);
        resolve();
      } else {
        const oldTrack = deviceType === MEDIA_DEVICE_TYPE.AUDIO ? room.getLocalAudioTrack() : room.getLocalVideoTrack();
        const isMuted = oldTrack ? oldTrack.isMuted() : true;
        const options = {};

        if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
          options.cameraDeviceId = deviceId;
        } else if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
          options.micDeviceId = deviceId;
        }

        this.createLocalTracksF(deviceType, options)
          .then((tracks) => {
            // Set previous mute state
            if (isMuted) {
              tracks[0].mute();
            }

            if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
              room
                .replaceTrack(oldTrack, tracks[0])
                .then(() => {
                  setTimeout(() => {
                    oldTrack.dispose();
                  });
                  this.setNoiseSuppressionEnabled(this.noiseSuppressionEnabled, true);
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
              let newTrack = tracks[0];

              try {
                room.replaceTrack(oldTrack, newTrack).then(() => {
                  if (oldTrack) {
                    oldTrack.dispose().then(() => {
                      newTrack.attach(document.getElementById(this.videoElementId));
                      resolve();
                    });
                  } else {
                    newTrack.attach(document.getElementById(this.videoElementId));
                    resolve();
                  }
                });
              } catch (e) {
                consoleError("Xpermeet - Room remove track throw err: ", e);
                reject(e);
              }
            }
          })
          .catch((e) => {
            consoleError(e);
            reject(e);
          });
      }
    });
  }

  setAudioOutputDevice(deviceId) {
    JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId);
  }

  async startScreenShare() {
    if (!this.conferenceInstance.isScreenShareAllowed()) {
      return Promise.reject(ErrorWithCodes.NOT_ALLOWED);
    }

    this.screenSharing = true;

    // Check camera state
    const videoTrack = this.tracks.find((track) => {
      if (track) {
        return track.type === MEDIA_DEVICE_TYPE.VIDEO;
      }
    });
    this.openCameraAfterScreenShare = videoTrack && !videoTrack.isMuted();

    if (videoTrack) {
      // stop camera video
      const videoTrackIndex = this.tracks.findIndex((track) => {
        if (track) {
          return track.type === MEDIA_DEVICE_TYPE.VIDEO;
        }
      });
      if (videoTrackIndex > -1) {
        this.tracks[videoTrackIndex].dispose();
        this.tracks.splice(videoTrackIndex, 1);
      }
    }

    JitsiMeetJS.createLocalTracks({
      devices: [MEDIA_DEVICE_TYPE.DESKTOP],
      ...this.conferenceInstance.confOptions,
    })
      .then((tracks) => {
        const desktopVideoTrack = tracks.find((track) => track.type === MEDIA_DEVICE_TYPE.VIDEO);
        const desktopAudioStream = tracks.find((track) => track.type === MEDIA_DEVICE_TYPE.AUDIO);

        const room = this.conferenceInstance.room;
        const oldAudioTrack = room.getLocalAudioTrack();

        if (desktopAudioStream && oldAudioTrack) {
          this.desktopAudioStream = desktopAudioStream;
          const mixerEffect = new AudioMixerEffect(this.desktopAudioStream);
          oldAudioTrack.setEffect(mixerEffect);
        }

        this.tracks[1] = desktopVideoTrack;

        // TODO check desktop share mute state will be exist?
        // this.tracks[1].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.onMuteChanged.bind(this));
        this.tracks[1].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, this.onScreenShareStopped.bind(this));

        const existingTrack = this.tracks[1].getType() === "video" ? room.getLocalVideoTrack() : room.getLocalAudioTrack();
        if (existingTrack) {
          room.replaceTrack(existingTrack, this.tracks[1]);
        } else {
          room.addTrack(this.tracks[1]);
        }

        eventEmitter.emit("LocalTrackAdded", { userId: this.getUser.id, track: this.tracks[1] });

        this.setLocalParticipantProperty("ScreenSharing", "true");
        conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: true });
        conferenceEventEmitter.emit("ScreenShareStarted", { userId: this.getUser.id });

        return Promise.resolve();
      })
      .catch((err) => {
        consoleError("Screen Share Error: ", err);
        this.stopScreenShare();
      });
  }

  onScreenShareStopped() {
    consoleLog("onScreenShareStopped");
    this.stopScreenShare();
  }

  onAudioStartMutedPolicyChange(policy, forceMuted) {
    const room = this?.conferenceInstance?.room;
    const isModerator = room?.getRole() === "moderator";
    const audioTrack = room.getLocalAudioTrack();
    const participantCount = room.getParticipantCount();
    const moderatorAudioMuted = policy === MODERATOR_AUDIO_MUTED;
    // if participant count more than nine, all participants must be start muted due to performance reasons
    const muteAudio = participantCount > 9 || policy === USER_AUDIO_MUTED || moderatorAudioMuted || forceMuted;

    if (audioTrack) {
      if (muteAudio) {
        this.muteAudio(true);
      }
      this.setNoiseSuppressionEnabled(!this.checkIsJibri());
    } else {
      this.createLocalTracks(MEDIA_DEVICE_TYPE.AUDIO, room, true, muteAudio).then((tracks) => {
        this.replaceLocalTrack(tracks[0]).then(() => {
          this.setNoiseSuppressionEnabled(!this.checkIsJibri());
        });
      });
    }

    if (!isModerator) {
      this.setAudioMutedByModerator(moderatorAudioMuted);
    }
  }

  onVideoStartMutedPolicyChange(policy, forceMuted) {
    const room = this?.conferenceInstance?.room;
    const participantCount = room.getParticipantCount();
    const isModerator = room?.getRole() === "moderator";
    const videoTrack = room.getLocalVideoTrack();
    const moderatorVideoMuted = policy === MODERATOR_VIDEO_MUTED;
    // if participant count more than nine, all participants must be start muted due to performance reasons
    const muteVideo = participantCount > 9 || policy === USER_VIDEO_MUTED || moderatorVideoMuted || forceMuted;

    if (videoTrack) {
      if (muteVideo) {
        this.muteVideo(true);
      }
    } else {
      if (!muteVideo) {
        this.createLocalTracks(MEDIA_DEVICE_TYPE.VIDEO, room, true, muteVideo).then((tracks) => {
          this.replaceLocalTrack(tracks[0]);
        });
      } else {
        // conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: true });
      }
    }

    if (!isModerator) {
      this.setVideoMutedByModerator(moderatorVideoMuted);
    }
  }

  async stopScreenShare() {
    if (this.screenSharing === false) {
      return;
    }
    this.screenSharing = false;

    // If shared screen with desktop audio, dispose audio on stopping share
    const oldAudioTrack = this.conferenceInstance.room.getLocalAudioTrack();
    if (this.desktopAudioStream && oldAudioTrack) {
      await this.desktopAudioStream.dispose();

      const oldMuteState = oldAudioTrack.isMuted();
      await oldAudioTrack.setEffect();
      // TODO jitsi bug fix
      // Jitsi setEffect sonrasında audio track'i unmute ediyordu. Mute state'i kullanıcının önceki state'inde kalmalı.
      this.muteAudio(oldMuteState);
    }

    if (this.activeEffect) {
      await this.removeEffect();
    }
    // If video track exist, remove it.
    const videoTrackIndex = this.tracks.findIndex((track) => {
      if (track) {
        return track.type === MEDIA_DEVICE_TYPE.VIDEO;
      }
    });
    if (videoTrackIndex !== -1) {
      this.tracks.splice(videoTrackIndex, 1);
    }
    // If video track exist, dispose it.
    const videoTracks = this.conferenceInstance.room.getLocalTracks(MEDIA_DEVICE_TYPE.VIDEO);
    if (videoTracks.length) {
      await this.conferenceInstance.room.removeTrack(videoTracks[0]);
      await videoTracks[0].dispose();
    }

    this.setLocalParticipantProperty("ScreenSharing", "false");
    conferenceEventEmitter.emit("ScreenShareStopped", { userId: this.getUser.id });

    consoleLog("MediaDevices.videoInputDeviceId", MediaDevices.videoInputDeviceId);

    // If the camera will be open, create track for camera
    if (this.openCameraAfterScreenShare) {
      this.createLocalTracksF(MEDIA_DEVICE_TYPE.VIDEO).then((tracks) => {
        this.replaceLocalTrack(tracks[0]);
      });
    }

    conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: !this.openCameraAfterScreenShare });
  }

  handsUp(state) {
    this.setLocalParticipantProperty("handsUp", state);
  }

  toggleE2ee(state) {
    this.setLocalParticipantProperty("localUserE2ee", state.toString());
  }

  fileShare(data) {
    conferenceEventEmitter.emit("UserPropertyChange", { userId: this.getUser.id, property: "fileShare", value: data });
  }

  muteAudio(mute) {
    if (this.conferenceInstance.room.getLocalAudioTrack()) {
      if (mute) {
        return this.conferenceInstance.room.getLocalAudioTrack().mute();
      } else {
        return this.conferenceInstance.room.getLocalAudioTrack().unmute();
      }
    } else {
      consoleWarning("Local user audio track could not find...");
    }
  }

  async muteVideo(mute) {
    const videoTrack = this.tracks.findIndex((track) => {
      if (track) {
        return track.type === MEDIA_DEVICE_TYPE.VIDEO;
      }
    });
    if (videoTrack !== -1) {
      if (this.screenSharing) {
        if (!mute) {
          this.setEffect();
          conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: false });
        } else {
          await this.removeEffect();
          conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: true });
        }
      } else {
        if (mute) {
          return this.conferenceInstance.room.getLocalVideoTrack().mute();
        } else {
          return this.conferenceInstance.room.getLocalVideoTrack().unmute();
        }
      }
    } else {
      // ekran paylaşımı kapatıp ve kamera unmute edilirken bu state'e düşer.
      const localTrack = this.conferenceInstance.room.getLocalVideoTrack();
      if (localTrack) {
        await localTrack.dispose();
      }

      this.createLocalTracksF(MEDIA_DEVICE_TYPE.VIDEO)
        .then(async (tracks) => {
          await this.replaceLocalTrack(tracks[0]);

          conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: false });

          // Bug fix: Kamera track oluşturulduğunda diğer kullanıcılara track type desktop olarak gidiyor.
          // mute olarak kalıyor. Bu sebepten mute/unmute yapıldı
          tracks[0].mute().then(() => {
            if (!mute) {
              tracks[0].unmute();
            }
          });
        })
        .catch((error) => {
          consoleError(error);
        });
    }
  }

  onLocalTrack(tracks = [], room) {
    const userId = room.myUserId();

    tracks.forEach((track) => {
      track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, this.onAudioLevelChanged.bind(this));
      track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.onMuteChanged.bind(this));
      track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, this.onTrackStopped.bind(this));
      track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioOutputChanged.bind(this));

      if (!this.getUser.isJibri) {
        eventEmitter.emit("LocalTrackAdded", { userId, track });
      }
    });
  }

  onMuteChanged(track) {
    consoleLog("onMuteChanged", track);
    if (track.getType() === MEDIA_DEVICE_TYPE.VIDEO) {
      conferenceEventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    } else {
      conferenceEventEmitter.emit("UserAudioMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    }
  }

  onTrackStopped() {
    consoleLog("local track stopped");
  }

  onAudioOutputChanged(deviceId) {
    consoleLog(`track audio output device was changed to ${deviceId}`);
  }

  // eslint-disable-next-line
  onAudioLevelChanged(audioLevel) {
    // consoleLog(audioLevel);
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }

  clearEvents() {
    eventEmitter.clear();
  }

  sendMeetingRequest(payload) {
    const mailServer =
      window.XPER_CONFIG.mailServer || process.env.VUE_APP_MAIL_SERVER || "https://meet-orchestrator-v1.xpermeet.com:41006/api/Communication/SendEmail";
    let header = request.getHeaderByType("json");
    if (window?.XPER_CONFIG?.communicationTenantHeader) {
      header.headers.tenant = window?.XPER_CONFIG?.communicationTenantHeader;
    }
    return axios.post(mailServer, payload, header);
  }

  /**
   * Attempt to enable or disable noise suppression using the {@link NoiseSuppressionEffect}.
   *
   * @param {boolean} enabled - Enable or disable noise suppression.
   * @param {boolean} forced - Pass previous state control.
   */
  async setNoiseSuppressionEnabled(enabled, forced) {
    const localAudio = this.conferenceInstance.room.getLocalAudioTrack();

    consoleLog(`Attempting to set noise suppression enabled state: ${enabled}`);

    if (enabled === this.noiseSuppressionEnabled && !forced) {
      consoleWarning(`Noise suppression enabled state already: ${enabled}`);

      return;
    }

    // If there is no local audio, simply set the enabled state. Once an audio track is created
    // the effects list will be applied.
    if (!localAudio) {
      return;
    }

    try {
      if (enabled) {
        await localAudio.setEffect(new NoiseSuppressionEffect());

        consoleLog("Noise suppression enabled.");
      } else {
        await localAudio.setEffect(undefined);

        consoleLog("Noise suppression disabled.");
      }
      this.noiseSuppressionEnabled = enabled;
    } catch (error) {
      consoleError(`Failed to set noise suppression enabled to: ${enabled}`, error);
    }
  }
}

export default LocalUser;
