<template>
  <div class="tab-menu d-flex align-center w-full">
    <v-btn
      v-for="item in tabItems"
      :key="item.name"
      small
      height="32"
      :width="`${parseInt(100 / tabItems.length)}%`"
      class="tab-btn flex-grow-1"
      :color="item.value === sharedWith ? 'body' : backgroundColor"
      @click="$emit('onTabClick', item.value)"
    >
      <icon v-if="item.icon" :icon="item.icon" :color="color" :size="iconSize" />
      <span class="tab-btn-text ml-2">{{ item.text }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    iconSize: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: 'white',
    },
    backgroundColor: {
      type: String,
      default: 'primary',
    },
    tabItems: {
      type: Array,
    },
    sharedWith: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.tab-menu {
  background-color: var(--v-primary-base);
  border-radius: 8px !important;
  padding: 4px 4px;
  height: 40px;
  .tab-btn {
    height: 100%;
    .tab-btn-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 27px;
    }
  }
}
</style>
