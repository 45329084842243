var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "chat-message-link",
      attrs: { href: _vm.url, target: "_blank" },
    },
    [_vm._v(_vm._s(_vm.message.body))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }