<template>
  <Modal v-if="dialog" :title="$t(title)" :confirm-button-text="$t('confirm')" @close="close" :cancel-button-text="$t('cancel')" show-actions>
    <div class="text-center">
      {{ $t(message) }}
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LOGOUT_MODAL_ENUM from '@/constants/ENUMS'; // TODO: global import gerekiyor

export default {
  name: 'LogoutConfirmModal',
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState('Conference', ['showLogoutConferenceModal']),
    message() {
      return this.showLogoutConferenceModal?.message || 'doYouWantToLeaveTheRoom';
    },

    title() {
      if (this.showLogoutConferenceModal?.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.LEAVE_TYPE_CALL) {
        return 'leaveCall';
      } else if (this.showLogoutConferenceModal?.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.LOG_OUT) {
        return 'logout';
      } else if (this.showLogoutConferenceModal?.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.DESTROY_ROOM) {
        return 'destroyRoomAndLeave';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_LOGOUT_CONFIRM_MODAL']),
    ...mapActions('Conference', ['logout', 'leaveCall', 'destroyRoomAndLeave']),
    close(type) {
      if (type === 'confirm') {
        this.leave();
      }

      this.dialog = false;
    },
    leave() {
      if (this.showLogoutConferenceModal.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.LEAVE_TYPE_CALL) {
        this.leaveCall();
      } else if (this.showLogoutConferenceModal.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.LOG_OUT) {
        this.logout();
      } else if (this.showLogoutConferenceModal.type === LOGOUT_MODAL_ENUM.LOGOUT_MODAL_ENUM.DESTROY_ROOM) {
        this.destroyRoomAndLeave();
      }
    },
  },
  watch: {
    showLogoutConferenceModal() {
      this.dialog = !!this.showLogoutConferenceModal;
    },
  },
};
</script>

<style scoped></style>
