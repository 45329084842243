<template>
  <div class="logo">
    <img :src="getLogo" :style="{ height: `${logoHeight}px` }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Logo',
  props: {
    height: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters(['getLogo']),
    logoHeight() {
      const standartHeight = this.height || 40;
      const heightDifference = standartHeight - 40;

      let height = standartHeight;
      if (this.$customerConfig?.resources?.logoHeight) {
        height = this.$customerConfig?.resources?.logoHeight + heightDifference;
      }
      if (window.innerWidth < 769) {
        return height - 10;
      }
      return height;
    },
  },
};
</script>

<style lang="scss">
.logo {
  img {
    height: 40px;
  }

  @media (max-width: $breakpoint-tablet) {
    img {
      height: 30px;
    }
  }
}
</style>
