var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat", class: { show: true } }, [
    _vm.showActions
      ? _c(
          "div",
          { staticClass: "d-flex justify-end align-center my-3 mr-2" },
          [
            _c("span", { staticClass: "text-body-2 more-action-text" }, [
              _vm._v(_vm._s(_vm.$t("actions"))),
            ]),
            _c("menu-button", {
              attrs: {
                "data-test-id": "chat-action-list-button",
                icon: "more-vertical",
                width: "24",
                "icon-size": "16",
                dropdownClass: "mt-2",
                "use-inner-model": "",
                "hide-caret-down": "",
                "dropdown-list": _vm.getMoreButtonList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "messageBodyElement",
        staticClass: "body px-6 py-3",
        class: { [_vm.bodyClass]: true },
        attrs: { id: _vm.bodyId },
      },
      [
        _vm._l(_vm.filteredMessages, function (message) {
          return _c("message", {
            key: message.id,
            attrs: {
              "show-avatar": _vm.showAvatar,
              message: message,
              enablePrivateMessage: _vm.enablePrivateMessage,
              currentTime: _vm.currentTime,
            },
            on: { privateMessageClicked: _vm.onPrivateMessageClicked },
          })
        }),
        _c("IntersectionObserver", { on: { intersect: _vm.onIntersect } }),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "footer px-6 py-3",
        class: {
          "flex-row": !_vm.showFileShareButton,
          "d-flex": !_vm.showFileShareButton,
          "align-center": !_vm.showFileShareButton,
        },
      },
      [
        _vm.sendPrivateMessageTo
          ? _c("div", { staticClass: "d-flex flex-row-reverse" }, [
              _c(
                "span",
                {
                  staticClass:
                    "footer--cancel-private-channel mb-1 text-decoration-underline",
                  attrs: { role: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("privateMessageCancelled")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("closeThePrivateChannel")) + " ")]
              ),
            ])
          : _vm._e(),
        _c("text-field", {
          staticClass: "mb-2",
          attrs: {
            placeholder: _vm.placeholder,
            autocomplete: "message-text",
            disabled: !_vm.chatEnabled,
            "hide-details": "",
          },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.send.apply(null, arguments)
            },
            focus: _vm.onFocus,
          },
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        }),
        _c(
          "div",
          { staticClass: "d-flex align-center justify-end mb-2" },
          [
            _vm.showFileShareButton
              ? _c("icon-button", {
                  attrs: {
                    disabled: !_vm.chatEnabled,
                    icon: "attach",
                    "background-color": "transparent",
                    circle: "",
                  },
                  on: { click: _vm.showFileShareModal },
                })
              : _vm._e(),
            _c(
              "v-btn",
              {
                staticClass: "ml-2",
                attrs: {
                  disabled: !_vm.chatEnabled,
                  color: "secondary",
                  fab: "",
                  "x-small": "",
                },
                on: { click: _vm.send },
              },
              [
                _c("icon", {
                  staticStyle: { "margin-top": "3px", "margin-right": "3px" },
                  attrs: { icon: "send", size: "20" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "showing-old-messages",
            class: { active: _vm.showOldMessageButton },
          },
          [
            _c(
              "v-btn",
              {
                staticClass: "showing-old-messages--button",
                attrs: { small: "", color: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.setScrollToBottom(true)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("youAreViewingOldMessages")) + " ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }