var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "app-link",
      attrs: { target: _vm.target, href: _vm.href },
      on: { click: _vm.onClick },
    },
    [_vm._v(_vm._s(_vm.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }