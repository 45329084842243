import storage from '@/services/storage';
import { MY_ROOM_FILE_KEYS, MY_WEBINAR_FILE_KEYS } from '../constants/file';

export const downloadFile = (blob, fileFullName) => {
  let data = new Blob(['\uFEFF' + blob], { type: 'text/csv;charset=utf-8;' });
  let url = window.URL.createObjectURL(data);
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileFullName);
  link.click();
  link.remove();
};

export const getFileNameWithoutExt = name => {
  return name.replace(/\.[^/.]+$/, '');
};

export const getFileExtension = name => {
  const reg = /(?:\.([^.]+))?$/;
  return reg.exec(name)[1];
};

export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const mimeTypeToExt = mimeType => {
  const mimeTypes = {
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/tiff': 'tiff',
    'image/vnd.wap.wbmp': 'wbmp',
    'image/x-icon': 'ico',
    'image/x-jng': 'jng',
    'image/x-ms-bmp': 'bmp',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
  };
  return mimeType in mimeTypes ? mimeTypes[mimeType] : '';
};

export const addFileKeyToStorage = (fileKeys, shareTo = ['room']) => {
  if (shareTo.includes('room')) {
    storage.setItem(MY_ROOM_FILE_KEYS, [...getMySharedFileKeys('room'), ...fileKeys]);
  }

  if (shareTo.includes('webinar')) {
    storage.setItem(MY_WEBINAR_FILE_KEYS, [...getMySharedFileKeys('webinar'), ...fileKeys]);
  }
};

export const getMySharedFileKeys = roomType => {
  const storageKey = roomType === 'room' ? MY_ROOM_FILE_KEYS : MY_WEBINAR_FILE_KEYS;
  return storage.getItem(storageKey) || [];
};

export const clearSharedFileKeys = () => {
  storage.setItem(MY_ROOM_FILE_KEYS, []);
  storage.setItem(MY_WEBINAR_FILE_KEYS, []);
};
