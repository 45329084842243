var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "users pb-2 pl-3 pr-2" }, [
    _vm.isModerator
      ? _c(
          "div",
          { staticClass: "d-flex align-center px-4 mt-6 mb-8" },
          [
            _c("span", { staticClass: "text-sm" }, [
              _vm._v(_vm._s(_vm.$t("muteUnmuteAll"))),
            ]),
            _c("v-spacer"),
            _vm.showMuteButton
              ? _c("icon-dropdown", {
                  staticClass: "ml-2",
                  attrs: {
                    dropdownClass: "mt-2",
                    "use-inner-model": !_vm.disableAudioControl,
                    "list-items": _vm.muteAudioDropdownList,
                    "icon-color": _vm.getAllAudioMutedState
                      ? "logo-color"
                      : undefined,
                    icon: _vm.getAllAudioMutedState ? "mic-off" : "mic",
                  },
                })
              : _vm._e(),
            _vm.showMuteButton
              ? _c("icon-dropdown", {
                  staticClass: "ml-2",
                  attrs: {
                    dropdownClass: "mt-2",
                    "use-inner-model": !_vm.disableVideoControl,
                    "list-items": _vm.muteVideoDropdownList,
                    "icon-color": _vm.getAllVideoMutedState
                      ? "logo-color"
                      : undefined,
                    icon: _vm.getAllVideoMutedState ? "video-off" : "video",
                  },
                })
              : _vm._e(),
            _c("icon-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("downloadUserList"),
                  expression: "$t('downloadUserList')",
                },
              ],
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                icon: "download",
                "background-color": "darkblue",
                width: "28",
                "icon-size": "18",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.downloadUserList(_vm.users, "conference")
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d-flex flex-column" },
      [
        _vm.localUser.isModerator
          ? _c("text-field", {
              staticClass: "mx-2 mb-2",
              attrs: {
                name: "search-user",
                autocomplete: "off",
                backgroundColor: "body",
                label: _vm.$t("searchUsersByUserName"),
                "hide-details": "",
              },
              model: {
                value: _vm.searchByUserNameText,
                callback: function ($$v) {
                  _vm.searchByUserNameText = $$v
                },
                expression: "searchByUserNameText",
              },
            })
          : _vm._e(),
        _vm._l(_vm.participants, function (user) {
          return _c(
            "div",
            {
              key: user.id,
              staticClass: "user",
              class: {
                me: user.id === _vm.localUser.id,
                moderator: user.type === "moderator",
                handsUp: user.handsUp,
              },
            },
            [
              _c("avatar", {
                attrs: {
                  iconSize: "50%",
                  "avatar-size": 48,
                  "display-name": user.displayName,
                  image: user.avatar,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "user-display-name ml-2",
                  class: `user-display-name--title--${_vm.activeActionCount([
                    user.handsUp,
                    user.localRecording ||
                      (_vm.remoteRecordStartedBy &&
                        _vm.remoteRecordStartedBy === user.id),
                    user.id !== _vm.getLocalUser.id,
                  ])}`,
                  attrs: { title: user.displayName },
                },
                [_vm._v(" " + _vm._s(user.displayName) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "user-actions d-flex align-center justify-content-center",
                },
                [
                  user.handsUp
                    ? _c("menu-button", {
                        staticClass: "mr-1",
                        attrs: { width: "24", color: "warning", icon: "hand" },
                      })
                    : _vm._e(),
                  user.localRecording ||
                  (_vm.remoteRecordStartedBy &&
                    _vm.remoteRecordStartedBy === user.id)
                    ? _c("menu-button", {
                        staticClass: "mr-1",
                        attrs: {
                          width: "24",
                          "icon-size": "16",
                          color: "logo-color",
                          icon: "recording",
                          "click-disabled": "",
                        },
                      })
                    : _vm._e(),
                  _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      "data-test-id": "user-list-mic-button",
                      width: "24",
                      "icon-size": "16",
                      color:
                        user.audioMuted || !user.hasAudioTrack
                          ? "logo-color"
                          : "",
                      icon:
                        user.audioMuted || !user.hasAudioTrack
                          ? "mic-off"
                          : "mic",
                      disabled: !_vm.showMuteButton || !user.hasAudioTrack,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "audioMuteClicked",
                          user.id,
                          !user.audioMuted
                        )
                      },
                    },
                  }),
                  _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      "data-test-id": "user-list-video-button",
                      width: "24",
                      "icon-size": "16",
                      color:
                        user.videoMuted || !user.hasVideoTrack
                          ? "logo-color"
                          : "",
                      icon:
                        user.videoMuted || !user.hasVideoTrack
                          ? "video-off-1"
                          : "video-solid",
                      disabled: !_vm.showMuteButton || !user.hasVideoTrack,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "videoMuteClicked",
                          user.id,
                          !user.videoMuted
                        )
                      },
                    },
                  }),
                  user.id !== _vm.getLocalUser.id
                    ? _c("menu-button", {
                        attrs: {
                          "data-test-id": "user-list-more-button",
                          icon: "more-vertical",
                          width: "24",
                          "icon-size": "16",
                          "use-inner-model": "",
                          "hide-caret-down": "",
                          "dropdown-list": _vm.getMoreButtonList(user),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
    _vm.viewers && _vm.viewers.length
      ? _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("div", {
              staticClass: "mt-4 mb-4 ml-n3 mr-n2",
              staticStyle: {
                "background-color": "var(--v-body-base)",
                height: "2px",
              },
            }),
            _c(
              "div",
              { staticClass: "d-flex align-center px-4 mt-6 mb-4" },
              [
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("viewerList"))),
                ]),
                _c("v-spacer"),
                _c("icon-button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("downloadWebinarUserList"),
                      expression: "$t('downloadWebinarUserList')",
                    },
                  ],
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    icon: "download",
                    "background-color": "darkblue",
                    width: "28",
                    "icon-size": "18",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.downloadUserList(_vm.viewers, "webinar")
                    },
                  },
                }),
              ],
              1
            ),
            _vm.localUser.isModerator
              ? _c("text-field", {
                  staticClass: "mt-1 mx-2 mb-2",
                  attrs: {
                    backgroundColor: "body",
                    label: _vm.$t("searchWebinarUsersByViewerName"),
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.searchByViewerNameText,
                    callback: function ($$v) {
                      _vm.searchByViewerNameText = $$v
                    },
                    expression: "searchByViewerNameText",
                  },
                })
              : _vm._e(),
            _vm._l(_vm.filteredViewers, function (user) {
              return _c(
                "div",
                {
                  key: user.id,
                  staticClass: "user",
                  class: {
                    me: user.id === _vm.localUser.id,
                    handsUp: user.handsUp,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "webinar-viewer-list",
                      attrs: { title: user.displayName },
                    },
                    [_vm._v(_vm._s(user.displayName))]
                  ),
                  _c(
                    "div",
                    { staticClass: "user-actions d-flex" },
                    [
                      user.handsUp
                        ? _c("icon", {
                            staticClass: "mr-2",
                            attrs: {
                              color: "warning",
                              size: "16",
                              icon: "hand",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("handsUpClicked", user)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.webinarLocalUser.role === "moderator"
                        ? _c(
                            "confirm-popover",
                            {
                              attrs: {
                                confirmText: "yes",
                                cancelText: "no",
                                description: "areYouSure",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.$emit("getInClicked", user)
                                },
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "cursor-pointer",
                                attrs: { icon: "user-plus", size: "16" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }