var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "survey" }, [
    _c("div", { staticClass: "survey-container" }, [
      _vm.loading ? _c("div", [_c("v-progress-circular")], 1) : _vm._e(),
      _c(
        "div",
        { staticClass: "survey-content px-4 py-6" },
        [
          !_vm.isHomePage && _vm.title
            ? _c("survey-nav", {
                staticClass: "mb-5",
                attrs: {
                  title: _vm.title,
                  showAddButton: _vm.showAddButton,
                  showBackButton: _vm.showBackButton,
                },
                on: { back: _vm.back },
              })
            : _vm._e(),
          _vm.currentView === _vm.views.INDEX
            ? _c(
                "div",
                { staticClass: "survey-home" },
                [
                  _vm.isModerator
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-6",
                          attrs: {
                            color: "primary",
                            block: "",
                            disabled: !_vm.isAuth,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pushRoute(_vm.views.MY_SURVEYS)
                            },
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "mr-5",
                            attrs: { icon: "check-circle" },
                          }),
                          _c("div", { staticClass: "badge-title" }, [
                            _vm._v(" " + _vm._s(_vm.$t("mySurveys")) + " "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        color: "primary",
                        block: "",
                        disabled: !_vm.isAuth,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pushRoute(_vm.views.ROOM_SURVEYS)
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-5",
                        attrs: { icon: "check-circle" },
                      }),
                      _c(
                        "v-badge",
                        { attrs: { content: _vm.sharedSurveyCount } },
                        [
                          _c("div", { staticClass: "badge-title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t(_vm.getSurveyTitle)) + " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        block: "",
                        disabled: !_vm.isAuth,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pushRoute(_vm.views.SURVEY_RESULTS)
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-5",
                        attrs: { icon: "check-circle" },
                      }),
                      _c(
                        "v-badge",
                        { attrs: { content: _vm.sharedSurveyResultsCount } },
                        [
                          _c("div", { staticClass: "badge-title" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(_vm.getSurveyResultsTitle)) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.showSurveyList
            ? _c("survey-list", {
                attrs: {
                  surveys: _vm.surveyListItems,
                  isMySurveys: _vm.isMySurveys,
                  isSharedSurvey: _vm.isSharedSurvey,
                },
                on: {
                  goToDetail: _vm.goToDetail,
                  goToSurvey: _vm.goToSurvey,
                  getSurveys: _vm.getMySurveys,
                  goToResult: _vm.goToResult,
                  openConfirm: _vm.openConfirm,
                },
              })
            : _vm.showSurveyDetail
            ? _c("survey-detail", {
                attrs: { survey: _vm.survey },
                on: { cancel: _vm.back, openConfirm: _vm.openConfirm },
              })
            : _vm.showSurveyResult
            ? _c("survey-result", {
                attrs: {
                  isWebinarSurvey: _vm.isWebinarSurvey,
                  survey: _vm.survey,
                  isSharedResult: _vm.isSharedResult,
                },
              })
            : _vm.showSurvey
            ? _c("survey", {
                attrs: {
                  survey: _vm.survey,
                  assignedSurvey: _vm.assignedSurvey,
                  goToHome: _vm.goToHome,
                  addCompleted: _vm.addCompleted,
                },
              })
            : _vm._e(),
          _c("v-overlay", {
            attrs: { value: _vm.showConfirm, absolute: "", "z-index": "6" },
          }),
          _vm.showConfirm
            ? _c(
                "div",
                { staticClass: "survey-container--confirm text-center" },
                [
                  _c("icon", { attrs: { icon: "share" } }),
                  _c("div", { staticClass: "my-4 text-body-2" }, [
                    _c("div", { staticClass: "ellipsis" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.confirmText, {
                              surveyTitle: _vm.confirmObject.survey.Title,
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mx-auto my-8",
                        staticStyle: { width: "150px" },
                      },
                      [
                        _vm.isModerator &&
                        !_vm.isDeleteConfirm &&
                        !_vm.confirmObject.survey.shared
                          ? _c("Checkbox", {
                              attrs: {
                                color: "white",
                                disabled:
                                  _vm.confirmObject.survey.sharedWithWebinar,
                                label: _vm.$t("shareWithThisRoom"),
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.confirmObject.survey.shareWithRoom,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.confirmObject.survey,
                                    "shareWithRoom",
                                    $$v
                                  )
                                },
                                expression:
                                  "confirmObject.survey.shareWithRoom",
                              },
                            })
                          : _vm._e(),
                        _vm.isModerator &&
                        !_vm.isDeleteConfirm &&
                        _vm.isWebinarStarted &&
                        !_vm.confirmObject.survey.sharedWithWebinar
                          ? _c("Checkbox", {
                              attrs: {
                                color: "white",
                                disabled: _vm.confirmObject.survey.shared,
                                label: _vm.$t("shareWithWebinar"),
                                "hide-details": "",
                              },
                              model: {
                                value:
                                  _vm.confirmObject.survey.shareWithWebinar,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.confirmObject.survey,
                                    "shareWithWebinar",
                                    $$v
                                  )
                                },
                                expression:
                                  "confirmObject.survey.shareWithWebinar",
                              },
                            })
                          : _vm._e(),
                        !_vm.isDeleteConfirm
                          ? _c("Checkbox", {
                              attrs: {
                                color: "white",
                                label: _vm.$t("resultsPublic"),
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.confirmObject.survey.ShareResults,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.confirmObject.survey,
                                    "ShareResults",
                                    $$v
                                  )
                                },
                                expression: "confirmObject.survey.ShareResults",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.confirmObject = null
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { color: _vm.confirmBtnColor },
                          on: { click: _vm.confirm },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t(_vm.confirmObject.type)) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }