<template>
  <div class="shared-video d-flex align-center justify-center overflow-hidden">
    <template v-if="videoType === SHARED_VIDEO_TYPES.YOUTUBE">
      <YoutubePlayer
        ref="player"
        :videoId="videoId"
        :initialTime="initialTime"
        :width="width"
        :height="height"
        :controls="controls"
        :forceMuted="speakerMuted"
        @onPlay="onPlay"
        @onPause="onPause"
        @muted="onMute"
        @unmuted="onUnMute"
        @volumeChanged="onVolumeChange"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import YoutubePlayer from '@/components/SharedVideo/Players/YoutubePlayer';

/**
 * Return true if the diffenrece between the two timees is larger than 5.
 *
 * @param {number} newTime - The current time.
 * @param {number} previousTime - The previous time.
 * @private
 * @returns {boolean}
 */
function shouldSeekToPosition(newTime, previousTime) {
  return Math.abs(newTime - previousTime) > 5;
}

const SHARED_VIDEO_TYPES = {
  YOUTUBE: 0,
};
export default {
  name: 'SharedVideo',
  components: { YoutubePlayer },
  props: {
    width: {
      type: [String, Number],
      default: '400px',
    },
    height: {
      type: [String, Number],
      default: '300px',
    },
  },
  data() {
    return {
      SHARED_VIDEO_TYPES,
      volume: 100,
      muted: false,
      initialTime: 0,
      videoPlayerStarted: false,
      interval: null,
    };
  },
  computed: {
    ...mapState('Conference', ['sharedVideo', 'speakerMuted']),
    ...mapGetters('Conference', ['getLocalUser', 'getUsers']),
    videoId() {
      return this.sharedVideo.value;
    },
    videoType() {
      return SHARED_VIDEO_TYPES.YOUTUBE;
    },
    controls() {
      return this.sharedVideo.from === this.getLocalUser.id;
    },
    playing() {
      return this.sharedVideo.status === 'playing';
    },
    participantCount() {
      return this.getUsers.length;
    },
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    ...mapActions('Conference', ['sharedVideoUpdate']),
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    onPlay(e) {
      this.videoPlayerStarted = true;
      this.sharedVideoUpdate({ status: 'playing', time: e.getCurrentTime(), muted: this.muted.toString(), volume: this.volume.toString() });

      this.clearInterval();
      this.interval = setInterval(() => {
        this.$refs.player.getCurrentTime().then(time => {
          this.sharedVideoUpdate({ status: 'playing', time: time, muted: this.muted.toString(), volume: this.volume.toString() });
        });
      }, 5000);
    },
    onPause(e) {
      this.clearInterval();
      this.sharedVideoUpdate({ status: 'pause', time: e.getCurrentTime() });
    },
    onMute() {
      this.muted = true;
      if (this.playing) {
        this.sharedVideoUpdate({ status: 'playing', muted: this.muted.toString(), volume: this.volume.toString() });
      }
    },
    onUnMute() {
      this.muted = false;
      if (this.playing) {
        this.sharedVideoUpdate({ status: 'playing', muted: this.muted.toString(), volume: this.volume.toString() });
      }
    },
    onVolumeChange(volume) {
      this.volume = volume;
      if (this.playing) {
        this.sharedVideoUpdate({ status: 'playing', muted: this.muted.toString(), volume: this.volume.toString() });
      }
    },
  },
  watch: {
    sharedVideo: {
      handler: function(newVal, oldVal) {
        if (newVal.from !== this.getLocalUser.id) {
          if (newVal.status === 'pause') {
            this.$refs.player.pauseVideo();
          } else if (newVal.status === 'playing') {
            if (newVal?.time && oldVal?.time) {
              if (oldVal.status === 'pause') {
                this.$refs.player.seekTo(parseInt(newVal.time));
                this.$refs.player.playVideo();
              } else {
                this.$refs.player.getCurrentTime().then(time => {
                  if (shouldSeekToPosition(parseInt(newVal.time), parseInt(oldVal.time)) || shouldSeekToPosition(parseInt(newVal.time), time)) {
                    this.$refs.player.seekTo(parseInt(newVal.time));
                    this.$refs.player.playVideo();
                  }
                });
              }
            } else {
              this.$refs.player.playVideo();
            }

            this.$refs.player.setVolume(newVal.volume);
            if (newVal.muted === 'true') {
              this.$refs.player.mute();
            } else {
              this.$refs.player.unMute();
            }
          } else if (newVal.status === 'update') {
            this.initialTime = parseInt(newVal.time);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
