var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "video-grid-layout grid-1" },
    [
      _c(
        "grid-layout",
        {
          attrs: {
            responsive: false,
            layout: _vm.localLayout,
            "col-num": _vm.colNum,
            "row-height": _vm.rowHeight,
            "is-draggable": _vm.editEnabled && _vm.editMode,
            "is-resizable": false,
            "is-mirrored": false,
            "vertical-compact": true,
            margin: [10, 10],
            "use-css-transforms": true,
          },
        },
        [
          _vm._l(_vm.localLayout, function (item) {
            return _c(
              "grid-item",
              {
                key: item.userId,
                class: {
                  "grid-item-fullscreen":
                    (_vm.isJibri && _vm.participantCount === 1) ||
                    (_vm.getUserById(item.userId) &&
                      _vm.getUserById(item.userId).fullScreen),
                  ["grid-item-size-" + item.w]: true,
                  "grid-item-pinned": _vm.checkPinUserVideo(item.userId),
                },
                attrs: {
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  i: item.i,
                  static: item.static,
                },
              },
              [
                _vm.getUserById(item.userId)
                  ? _c("video-card", {
                      class: { "edit-mode-video-card": _vm.editMode },
                      staticStyle: { margin: "0" },
                      style: _vm.getVideoCardStyle(item),
                      attrs: {
                        "avatar-size": item.w <= 3 ? 18 : 48,
                        "data-test-user-id": item.userId,
                        user: _vm.getUserById(item.userId),
                        "participant-count": _vm.participantCount,
                        hideFullScreen:
                          !_vm.getLocalUser.isModerator &&
                          _vm.roomConfig &&
                          _vm.roomConfig.followModeratorLayout,
                        fullScreen:
                          (_vm.isJibri && _vm.participantCount === 1) ||
                          (_vm.getUserById(item.userId) &&
                            _vm.getUserById(item.userId).fullScreen),
                        pinned: _vm.checkPinUserVideo(item.userId),
                        hidden:
                          _vm.isJibri &&
                          _vm.getFullScreenUserId &&
                          _vm.getFullScreenUserId !== _vm.user.id,
                      },
                      on: {
                        dblclick: function ($event) {
                          _vm.setFullScreen(_vm.getUserById(item.userId))
                        },
                      },
                    })
                  : _vm._e(),
                _vm.editMode && _vm.getUserById(item.userId)
                  ? _c("div", { staticClass: "edit-mode-video-container" }, [
                      _c("div", { staticClass: "user-abbreviation text-h3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.abbreviation(
                              _vm.getUserById(item.userId).displayName
                            )
                          )
                        ),
                      ]),
                      item.static
                        ? _c("div", { staticClass: "static-placeholder" })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-end edit-mode-buttons",
                        },
                        [
                          _c("menu-button", {
                            staticClass: "card-menu-btn mt-1 mx-0",
                            attrs: {
                              icon: !item.static ? "unlock" : "lock",
                              color: "white",
                              "icon-background-color": "darkblue",
                              "icon-size": "12",
                              width: "24",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                item.static = !item.static
                              },
                            },
                          }),
                          _c("menu-button", {
                            staticClass: "card-menu-btn mt-1 mx-0",
                            attrs: {
                              icon: "minimize-2",
                              color: "white",
                              "icon-background-color": "darkblue",
                              "icon-size": "12",
                              width: "24",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.sizeDown(item)
                              },
                            },
                          }),
                          _c("menu-button", {
                            staticClass: "card-menu-btn mt-1 mx-0",
                            attrs: {
                              icon: "maximize-2",
                              color: "white",
                              "icon-background-color": "darkblue",
                              "icon-size": "12",
                              width: "24",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.sizeUp(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm.editEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.getFullScreenUserId,
                      expression: "!getFullScreenUserId",
                    },
                  ],
                  staticClass: "d-flex justify-end",
                },
                [
                  _c("menu-button", {
                    attrs: {
                      id: "edit-grid-layout",
                      icon: _vm.editMode ? "save" : "edit",
                      color: "white",
                      iconBackgroundColor: "secondary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.editMode = !_vm.editMode
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }