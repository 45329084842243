<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-left="300" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <menu-button
        id="notification-top-button"
        class="mr-1 notification-top-button"
        :class="notificationWrapperClass"
        icon="bell"
        icon-size="20"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <div class="notification-wrapper py-3">
      <div class="head d-flex justify-space-between px-4">
        <div class="head-title">{{ $t('notifications.title') }}</div>
        <Icon class="cursor-pointer" color="white" icon="x" size="12" @click="hideNotificationsMenu" />
      </div>
      <div class="content" :class="{ 'mt-2': roomNotifications.length, 'mt-8': !roomNotifications.length }">
        <Notifications v-if="roomNotifications.length" />
        <div v-else class="px-4 pb-4 d-flex flex-column align-center text-center">
          <Icon color="white" icon="bell" size="32" class="mb-3" />
          <span class="no-notification-text">{{ $t('notifications.noNotificationsYet') }}</span>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Notifications from '@/components/Notifications/RoomNotifications/Notifications';
import Icon from '@/components/Shared/Icon';
import ENUMS from '@/constants/ENUMS';

export default {
  name: 'NotificationsWrapper',
  components: {
    Notifications,
    Icon,
  },
  data() {
    return {
      menu: false,
      ENUMS,
    };
  },
  computed: {
    ...mapState('Notification', ['roomNotificationHighlight', 'roomNotifications']),
    ...mapGetters('Notification', ['getNotificationByType', 'lastNotificationBadgeColor']),
    handsUpNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.HANDS_UP);
    },
    recordNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.RECORD);
    },
    notificationWrapperClass() {
      return this.roomNotificationHighlight ? `active ${this.lastNotificationBadgeColor}` : '';
    },
  },
  methods: {
    ...mapActions('Notification', ['readRoomNotifications']),
    hideNotificationsMenu() {
      this.menu = false;
    },
  },
  watch: {
    menu: {
      handler() {
        if (!this.menu) {
          this.readRoomNotifications();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.notification-wrapper {
  width: 300px;
  background: var(--v-primary-base);
  border-radius: 20px;
  box-shadow: 0px 20px 40px rgba(26, 33, 51, 0.5);
  .head {
    .head-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
    }
  }
  .content {
    overflow-y: scroll;
    max-height: 330px;
    .no-notification-text {
      opacity: 0.75;
      max-width: 200px;
      font-size: 14px;
    }
  }
}
</style>
