<template>
  <Modal
    :title="$t('shareVideoTitle')"
    :confirm-button-text="$t('share')"
    :cancel-button-text="$t('cancel')"
    show-actions
    :confirm-button-disabled="isDisabled"
    @close="onModalClose"
  >
    <div class="text-center mb-7">
      {{ $t('shareVideoDescription') }}
    </div>
    <div>
      <TextField v-model="videoUrl" :error-messages="$t(errorText)" :placeholder="$t('videoYoutubeUrl')" hide-details="auto" />
    </div>
  </Modal>
</template>

<script>
import { getIdFromUrl } from 'vue-youtube';
import { mapActions } from 'vuex';

export default {
  name: 'ShareVideoModal',
  data() {
    return {
      dialog: true,
      videoUrl: '',
      errorText: '',
    };
  },
  computed: {
    isDisabled() {
      return !this.videoUrl || !/youtube.com/.test(this.videoUrl);
    },
  },
  methods: {
    ...mapActions('Conference', ['sharedVideoUpdate']),
    valid() {
      this.errorText = '';
      let valid = false;

      if (this.videoUrl === '') {
        this.errorText = 'requiredField';
      } else if (!getIdFromUrl(this.videoUrl)) {
        this.errorText = 'inValidUrl';
      } else {
        valid = true;
      }

      return valid;
    },
    share() {
      if (this.valid()) {
        const videoId = getIdFromUrl(this.videoUrl);
        const status = 'start';
        this.sharedVideoUpdate({ videoId, status });
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$modal?.delayedClose();
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.share();
      } else {
        this.$emit('close', type);
      }
    },
  },
};
</script>
