var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.showRecordInfo
        ? _c(
            "div",
            {
              staticClass: "record-control px-2 cursor-pointer",
              on: { click: _vm.handleIconClick },
            },
            [
              _c("icon", {
                attrs: { color: "logo-color", icon: "stop-circle", size: "16" },
              }),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(_vm.$t("conferenceIsRecording"))),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showSharedPropertiesInfo
        ? _c("v-popover", { attrs: { open: _vm.showPopover } }, [
            _c(
              "div",
              {
                staticClass: "record-control px-2 mt-1",
                on: {
                  mouseenter: function ($event) {
                    _vm.showPopover = true
                  },
                  mouseleave: function ($event) {
                    _vm.showPopover = false
                  },
                },
              },
              [
                _c("icon", {
                  attrs: { color: "secondary", icon: "info", size: "16" },
                }),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("someSettingsControlledByModerator"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { "text-align": "left" },
                attrs: { slot: "popover" },
                slot: "popover",
              },
              _vm._l(_vm.moderatorControlledSettings, function (key) {
                return _c("ul", { key: key }, [
                  _c("li", [_vm._v(_vm._s(_vm.$t(key)))]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }