<template>
  <div style="margin-top: 100px;">
    <div>
      <v-btn>Default Button Text</v-btn>
      <v-btn color="primary">Default Button Text</v-btn>
      <v-btn color="secondary" x-large>
        <icon left icon="plus" />
        Konferans Oluştur
      </v-btn>
    </div>

    <div>
      <text-field style="width: 300px" placeholder="Text Field" hide-details></text-field>
      <text-field style="width: 300px" label="Text Field" hide-details class="mt-2"></text-field>
      <text-field style="width: 300px" label="Text Field" filled :single-line="false" :solo="false" class="mt-2"></text-field>
    </div>

    <div>
      <icon icon="settings" />
      <icon icon="loader" loading />
    </div>

    <div>
      <MenuButton icon="mic" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Theme',
};
</script>
