export function createATagAndClick(url, target = '_self') {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.target = target;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 100);
}
