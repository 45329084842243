var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "video-dynamic-layout",
      staticClass:
        "video-dynamic-layout d-flex justify-center align-center flex-wrap",
    },
    [
      _vm._l(_vm.getActivePageUsers, function (user) {
        return _c("video-card", {
          key: user.id,
          ref: "video-card",
          refInFor: true,
          attrs: {
            "data-test-user-id": user.id,
            user: user,
            hideFullScreen:
              !_vm.getLocalUser.isModerator &&
              _vm.roomConfig &&
              _vm.roomConfig.followModeratorFullScreen,
            "participant-count": _vm.participantCount,
            fullScreen: user.fullScreen,
            pinned: _vm.checkPinUserVideo(user.id),
            pinnedPosition: _vm.getPinnedUserPosition(user.id),
            "avatar-size": 18,
          },
          on: {
            pinnedPositionChanged: _vm.setPinnedDominantUserPosition,
            dblclick: function ($event) {
              return _vm.$emit("fullscreen", user)
            },
          },
        })
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pageChanging,
              expression: "pageChanging",
            },
          ],
          staticClass: "page-loading justify-center align-center",
          class: { "d-flex": _vm.pageChanging },
        },
        [_c("icon", { attrs: { icon: "loader", loading: "" } })],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }