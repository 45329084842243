var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        width: "550",
        title: _vm.$t("e2eeModalTitle"),
        "confirm-button-text": _vm.isLoggedIn
          ? _vm.$t("save")
          : _vm.$t("login"),
        "cancel-button-text": _vm.$t("cancel"),
        "sub-title": _vm.$t(
          "encryptYourAudioAndVideoByActivatingSecretMeetingMode"
        ),
        "auto-close": false,
        "show-actions": "",
        persistent: "",
        "show-back-button":
          _vm.encryptType === "password" && _vm.activeTab === "register",
      },
      on: {
        back: function ($event) {
          return _vm.setActiveTab("login")
        },
        close: _vm.close,
      },
    },
    [
      _vm.available
        ? _c("div", { staticClass: "mx-3 mb-0 smart-card-container" }, [
            !_vm.isLoggedIn
              ? _c("div", { staticClass: "d-flex flex-column align-start" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("roomEncryptedPleaseLogin")) + " "
                  ),
                ])
              : _c("div", [
                  _vm.e2eeSupported
                    ? _c(
                        "div",
                        { staticClass: "mx-3 mb-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ml-0" },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-0",
                                  attrs: { row: "", "hide-details": "" },
                                  model: {
                                    value: _vm.encryptType,
                                    callback: function ($$v) {
                                      _vm.encryptType = $$v
                                    },
                                    expression: "encryptType",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      color: "white",
                                      label: _vm.$t("withPassword"),
                                      value: "password",
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      color: "white",
                                      label: _vm.$t("withSmartCard"),
                                      value: "smartCard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                text: "",
                                prominent: "",
                                type: _vm.onMessage.type,
                                icon: "mdi-alert",
                                dismissible: "",
                              },
                              model: {
                                value: _vm.onMessage.show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onMessage, "show", $$v)
                                },
                                expression: "onMessage.show",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(this.onMessage.text)) + " "
                              ),
                            ]
                          ),
                          _vm.encryptType === "smartCard"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center mt-6",
                                },
                                [
                                  _c("div", {
                                    staticClass: "smart-card-adaptor-file",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("smartCardAdaptorExe")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-6",
                                      attrs: {
                                        dense: "",
                                        type: "info",
                                        icon: "feather icon-info",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("smartCardOnlyGoogleChrome")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.encryptType === "password"
                            ? _c(
                                "div",
                                [
                                  _vm.activeTab === "login"
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-body-2 white-text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "activateSecretMeetingModeWithPassword"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              label: _vm.$t("e2eePassword"),
                                              color: "white",
                                              required: "",
                                              autocomplete: "off",
                                              "hide-details": "auto",
                                              type: _vm.showE2eePassword
                                                ? "text"
                                                : "password",
                                              "append-icon":
                                                _vm.showE2eePassword
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                              "aria-required": "true",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onE2eePromptModalSave.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showE2eePassword =
                                                  !_vm.showE2eePassword
                                              },
                                            },
                                            model: {
                                              value: _vm.e2eePassword,
                                              callback: function ($$v) {
                                                _vm.e2eePassword = $$v
                                              },
                                              expression: "e2eePassword",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "mt-6" },
                                        [
                                          _c("text-field", {
                                            attrs: {
                                              label:
                                                _vm.$t("e2eeMasterPassword"),
                                              color: "white",
                                              required: "",
                                              autocomplete: "off",
                                              type: _vm.showE2eeMasterPassword1
                                                ? "text"
                                                : "password",
                                              "append-icon":
                                                _vm.showE2eeMasterPassword1
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onE2eePromptModalSave.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showE2eeMasterPassword1 =
                                                  !_vm.showE2eeMasterPassword1
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.register
                                                  .e2eeMasterPassword1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.register,
                                                  "e2eeMasterPassword1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "register.e2eeMasterPassword1",
                                            },
                                          }),
                                          _c("text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "reE2eeMasterPassword"
                                              ),
                                              required: "",
                                              color: "white",
                                              autocomplete: "off",
                                              type: _vm.showE2eeMasterPassword2
                                                ? "text"
                                                : "password",
                                              "append-icon":
                                                _vm.showE2eeMasterPassword2
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onE2eePromptModalSave.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showE2eeMasterPassword2 =
                                                  !_vm.showE2eeMasterPassword2
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.register
                                                  .e2eeMasterPassword2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.register,
                                                  "e2eeMasterPassword2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "register.e2eeMasterPassword2",
                                            },
                                          }),
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: "info",
                                                icon: "feather icon-info",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "e2eePromptModalRegisterImportantNote"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.progress
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  color: "amber",
                                                  indeterminate: "",
                                                  rounded: "",
                                                  height: "6",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                  _vm.activeTab === "login"
                                    ? _c(
                                        "div",
                                        { staticClass: "change-tab mt-6" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setActiveTab(
                                                    "register"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("createPassword"))
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.errors && _vm.errors.length
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            dense: "",
                                            outlined: "",
                                            type: "error",
                                            dismissible: "",
                                          },
                                          model: {
                                            value: _vm.showFormError,
                                            callback: function ($$v) {
                                              _vm.showFormError = $$v
                                            },
                                            expression: "showFormError",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(_vm.errors) + " ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.encryptType === "close"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center",
                                },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-6",
                                      attrs: {
                                        dense: "",
                                        type: "info",
                                        icon: "feather icon-info",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("closeE2ee")))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
          ])
        : _c(
            "v-alert",
            {
              attrs: {
                text: "",
                prominent: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("e2eWebinarError")) + " ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }