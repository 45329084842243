import axios from "axios";

export default class RoomNameService {
  constructor() {
    const config = {
      baseURL: window.XPER_CONFIG?.roomNameService || "https://meet.xpermeet.com:3000",
    };

    this.api = axios.create(config);
  }

  validate(room) {
    return this.api.post("/roomname/validate", { room });
  }

  generate() {
    return this.api.get("/roomname/generate");
  }
}
