var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "notification-file-share d-flex w-full justify-space-between align-center",
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "d-flex flex-column w-full" }, [
          _c("span", { staticClass: "user-name" }, [
            _vm._v(
              _vm._s(
                _vm.user
                  ? _vm.user.displayName
                  : _vm.$t("notifications.participant")
              )
            ),
          ]),
          _c(
            "div",
            { staticClass: "content d-flex" },
            [
              _c("Icon", { attrs: { icon: "file", color: "white", size: 20 } }),
              _c("span", { staticClass: "action-text ml-2" }, [
                _vm._v(
                  _vm._s(_vm.$t(`notifications.${_vm.notification.text}`))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "actions d-flex" }, [
        _c(
          "div",
          { staticClass: "check cursor-pointer", on: { click: _vm.confirm } },
          [
            !_vm.loading
              ? _c("Icon", {
                  attrs: { icon: "download-1", color: "white", size: 20 },
                })
              : _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    color: "white",
                    size: 20,
                    width: 2,
                  },
                }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }