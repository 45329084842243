<template>
  <div class="webinar-container" id="webinar-container" :class="{ mobile: $isMobile }">
    <v-container fluid class="webinar-wrapper">
      <div class="webinar-entrance">
        <div class="webinar-entrance-image-wrapper">
          <img src="/img/webinar-entrance.svg" />
        </div>
      </div>
      <webinar-top-menu :show-actions="!isLoggedOut && !autoPlayError" :is-webinar-started="isWebinarStarted" @openSettingsModal="showProfileModal = true" />
      <template v-if="isWebinarEnded">
        <div class="webinar-ended">
          <span class="text-h6">{{ $t('webinarEndedDescription') }}</span>
        </div>
      </template>
      <template v-else-if="isLoggedOut">
        <div class="webinar-ended">
          <span class="text-h6">{{ $t('webinarLoggedOut') }}</span>
        </div>
      </template>
      <template v-else-if="isMaxViewerReached">
        <div class="webinar-ended">
          <span class="text-h6">{{ $t('webinarMaxViewerReached') }}</span>
        </div>
      </template>
      <template v-else>
        <div class="webinar-page">
          <div class="webinar">
            <template v-if="getProtocol === 'hls'">
              <span v-if="hlsLoading" style="margin-bottom: 10rem">{{ $t('webinarStartsSoon') }}</span>
              <video
                @dblclick.stop="obDblClick"
                id="webinar-video"
                v-bind:muted="speakerMuted"
                class="playing"
                data-setup="{}"
                key="hls-player"
                autoplay=""
                playsinline=""
                @loadeddata="handleEvent('loadeddata')"
              ></video>
            </template>
            <template v-else>
              <div v-if="!sldpLoading" @dblclick.stop="obDblClick" id="webinar-element" key="sldp-player">
                <div v-show="showPoster">
                  <div class="webinar-preload">
                    <logo height="80"></logo>
                    <span class="title">{{ $t('welcome') }}!</span>
                    <span class="content">{{ $t('webinarStartsSoon') }}!</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <v-progress-circular color="#8e9bbb" indeterminate />
              </div>
            </template>
          </div>
        </div>
      </template>

      <div v-if="autoPlayError && !hlsLoading" v-show="!showPoster" class="play-container" @click="playVideo">
        <svg class="cursor-pointer" :class="{ mobile: $isMobile }" :width="259" height="82" viewBox="0 0 259 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M70.9502 69C63.468 77 52.8179 82 41 82C18.3563 82 0 63.6437 0 41C0 18.3563 18.3563 0 41 0C52.8179 0 63.468 5.00002 70.9502 13H251C255.418 13 259 16.5817 259 21V61C259 65.4183 255.418 69 251 69H70.9502ZM69.8173 69C62.5164 76.5126 52.3033 81.18 41 81.18C18.8092 81.18 0.82 63.1908 0.82 41C0.82 18.8092 18.8092 0.82 41 0.82C52.3033 0.82 62.5164 5.48738 69.8173 13H63.6293C57.4464 7.9968 49.5732 5 41 5C21.1177 5 5 21.1177 5 41C5 60.8823 21.1177 77 41 77C49.5732 77 57.4464 74.0032 63.6293 69H69.8173Z"
            fill="url(#paint0_linear)"
          />
          <text fill="#F9F9F9" xml:space="preserve" style="white-space: pre" font-family="Poppins" font-size="18" font-weight="500" letter-spacing="0px">
            <tspan x="83.9688" y="47.3">{{ $t('webinarPlayButtonExplanation') }}</tspan>
          </text>
          <path
            d="M40.6016 58.5996C50.5427 58.5996 58.6016 50.5407 58.6016 40.5996C58.6016 30.6585 50.5427 22.5996 40.6016 22.5996C30.6604 22.5996 22.6016 30.6585 22.6016 40.5996C22.6016 50.5407 30.6604 58.5996 40.6016 58.5996Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37 33.4004L47.8 40.6004L37 47.8004V33.4004Z"
            fill="white"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear" x1="55.528" y1="13.0908" x2="78.8546" y2="107.235" gradientUnits="userSpaceOnUse">
              <stop stop-color="#7C8AEF" />
              <stop offset="1" stop-color="#7C8AEF" stop-opacity="0.2" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <webinar-bottom-menu
        v-if="!isLoggedOut && !autoPlayError && isWebinarStarted"
        :speakerMuted="speakerMuted"
        @itemClicked="onConferenceMenuItemClicked"
        @setLogout="setLogout"
      />
    </v-container>
    <WebinarRightMenu :show="showChatScreen" @close="closeMenu" />
    <ProfileModal v-if="showProfileModal" :displayName="displayName" @save="onProfileModalSave" @close="onProfileModalClose" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import WebinarBottomMenu from '@/components/Webinar/Menu/WebinarBottomMenu';
import ProfileModal from '@/components/Profile/Modal/Profile';
import WebinarRightMenu from '@/components/Webinar/RightMenu/WebinarRightMenu';
import storage from '@/services/storage';
import { consoleLog } from '@/helpers/logger';
import { isSafari } from '@/helpers/detect-browser';
import WebinarTopMenu from '@/components/Webinar/Menu/WebinarTopMenu';
import { createATagAndClick } from '@/helpers/create-element';
import { setSkipPrivateRoomEntrance } from '@/helpers/private-room';

export default {
  name: 'Webinar',
  components: { WebinarTopMenu, WebinarRightMenu, ProfileModal, WebinarBottomMenu },
  data() {
    return {
      showProfileModal: false,
      showRightMenu: false,
      displayName: '',
      timeout: null,
      speakerMuted: false,
      meetingDuration: '00:00',
      videoPlaying: false,
      isLoggedOut: false,
      showPoster: false,
      protocol: 'sldp',
      sldpLoading: false,
      hlsLoading: false,
      videoTimeout: null,
      webinarEnded: false,
    };
  },
  computed: {
    ...mapState('Conference', ['showChatScreen']),
    ...mapState('Survey', ['sharedSurveys']),
    ...mapState('Webinar', ['autoPlayError', 'meetingId', 'isMaxViewerReached']),
    ...mapGetters('Webinar', ['isWebinarStarted', 'isWebinarEnded', 'isWebinarRequestPending']),
    ...mapGetters('Survey', ['getUncompletedSharedSurveysByType']),
    roomName() {
      return this.$route.params?.roomName;
    },
    getProtocol() {
      return this.$route?.query?.protocol && ['hls', 'sldp'].includes(this.$route.query.protocol) ? this.$route.query.protocol : this.protocol;
    },
  },
  created() {
    if (!('MediaSource' in window) || isSafari()) {
      this.setProtocol('hls');
    }

    const displayName = storage.getItem('displayName');
    if (displayName) {
      this.displayName = displayName;
    } else {
      this.showProfileModal = true;
    }
  },
  mounted() {
    if (document.querySelector('.logo')) {
      document.querySelector('.logo').style.cssText += 'z-index: ' + 10 + ' !important';
    }
    setSkipPrivateRoomEntrance(this.roomName);
  },
  methods: {
    ...mapActions('Webinar', ['start', 'changeAlias', 'streamCheck', 'playVideoManually']),
    ...mapActions('Survey', ['getSharedSurveys']),
    ...mapMutations('Webinar', ['SET_AUTO_PLAY_ERROR']),
    ...mapMutations('Conference', ['TOGGLE_CHAT_SCREEN', 'TOGGLE_RIGHT_MENU']),
    ...mapMutations('Survey', ['SET_NEW_SURVEY_SHARED']),
    setProtocol(protocol) {
      if (protocol === 'hls') {
        this.speakerMuted = true;
      }

      this.protocol = protocol;
    },
    handleEvent(eventName) {
      if (eventName === 'loadeddata') {
        this.hlsLoading = false;
      }
    },
    onVideoPlay() {
      this.videoPlaying = true;
    },
    onConferenceMenuItemClicked(itemName) {
      if (itemName === 'speakerMuted') {
        this.speakerMuted = !this.speakerMuted;
      }
    },
    playVideo() {
      this.tryStartLive();
      this.hlsLoading = true;

      if (isSafari()) {
        this.playVideoManually({
          video: document.getElementById('webinar-video'),
          protocol: this.getProtocol,
        });
      }

      this.SET_AUTO_PLAY_ERROR(false);
    },
    async tryStartLive() {
      consoleLog('Webinar stream not found. Checking webinar room');
      let token = this.$route.query?.token || localStorage.getItem('event-token');
      const previousToken = localStorage.getItem('previous-event-token');
      if (!token && previousToken) {
        localStorage.setItem('event-token', previousToken);
        localStorage.removeItem('previous-event-token');
        token = previousToken;
      }

      this.streamCheck({ roomName: this.roomName, protocol: this.getProtocol, token })
        .then(() => {
          clearTimeout(this.timeout);
          consoleLog('Webinar stream check returned true');
          // stream check yapılıdıktan sonra true dondugun anda stream baslamıs jibri baslamıs ama media serverda yayın henuz olmayabilir
          // bu nedenden dolayı hemen arkasından baglantı istegi sldp playr baslatılmaya calısıldıgında baslamayabiliyor.
          // bu latency icin 5 sec delay koyuluyor.
          setTimeout(() => {
            this.showPoster = false;
            this.start({
              displayName: this.displayName,
              videoId: 'webinar-video',
              roomId: this.roomName,
              token,
              protocol: this.getProtocol,
            });
            this.SET_AUTO_PLAY_ERROR(false);
            this.clearQueryParams();
            this.sldpLoading = false;
          }, 5000);
        })
        .catch(() => {
          this.showPoster = true;
          this.timeout = setTimeout(() => {
            this.sldpLoading = false;
            this.tryStartLive();
          }, 3000);
        });
    },
    onProfileModalSave({ displayName }) {
      this.displayName = displayName;
      storage.setItem('displayName', displayName);
      this.showProfileModal = false;
      if (this.isWebinarStarted) {
        this.changeAlias(displayName);
      } else {
        this.playVideo();
      }
    },
    onProfileModalClose() {
      const displayName = storage.getItem('displayName');
      if (!displayName) {
        const homeUrl = window.location.origin;
        createATagAndClick(homeUrl);
      }
      this.showProfileModal = false;
    },
    closeMenu() {
      this.TOGGLE_CHAT_SCREEN({ close: true });
    },
    setLogout() {
      this.isLoggedOut = true;
      this.closeMenu();
    },
    clearQueryParams() {
      const query = {};
      if (this.$route?.query?.protocol) {
        query.protocol = this.$route.query.protocol;
      }
      this.$router.replace({ query, params: { roomName: this.roomName } }).catch(() => {});
    },

    isVideoPlaying(video) {
      return !!(video && video.currentTime > 0 && !video.ended && video.readyState > 2);
    },
    checkHlsVideo() {
      const videoDiv = document.getElementById('webinar-element');
      const video = videoDiv.querySelector('video');

      const isPlaying = this.isVideoPlaying(video);
      if (!isPlaying) {
        if (!this.webinarEnded) {
          this.setProtocol('hls');
        } else {
          if (this.protocol === 'sldp') {
            this.sldpLoading = true;
          }
        }
        this.tryStartLive();
      } else {
        this.SET_AUTO_PLAY_ERROR(false);
      }
      clearTimeout(this.videoTimeout);
    },
    obDblClick() {
      if (window.fullScreen || (window.innerWidth === screen.width && window.innerHeight === screen.height)) {
        this.closeFullscreen();
      } else {
        this.openFullscreen();
      }
    },
    openFullscreen() {
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
  },
  watch: {
    showChatScreen: {
      immediate: true,
      handler() {
        if (document.querySelector('.v-main')) {
          if (this.showChatScreen) {
            document.querySelector('.v-main').style.cssText += 'z-index: ' + 4 + ' !important';
          } else {
            document.querySelector('.v-main').style.cssText += 'z-index: ' + 0 + ' !important';
          }
        }
      },
    },
    meetingId: {
      immediate: true,
      handler() {
        this.getSharedSurveys('webinar');
      },
    },
    sharedSurveys: {
      immediate: true,
      deep: true,
      handler() {
        const surveys = this.getUncompletedSharedSurveysByType('webinar');
        if (surveys.length > 0) {
          this.SET_NEW_SURVEY_SHARED('room');
          this.TOGGLE_RIGHT_MENU({ open: true, tab: 'survey' });
          /*notification.confirm('aNewSurveyWasShared', false).show();*/
        }
      },
    },
    isWebinarStarted: {
      immediate: true,
      handler() {
        if (this.isWebinarStarted) {
          if (this.getProtocol === 'sldp') {
            this.videoTimeout = setTimeout(() => {
              this.checkHlsVideo();
            }, 5000);
          } else if (this.getProtocol === 'hls' && this.webinarEnded) {
            window.location.reload();
          }
        }
      },
    },
    speakerMuted: {
      immediate: true,
      handler() {
        const video = document.querySelector('video');
        if (video) {
          video.muted = this.speakerMuted;
        }
      },
    },
    isWebinarEnded: {
      immediate: true,
      handler() {
        if (this.isWebinarEnded) {
          this.webinarEnded = true;
          if (this.protocol === 'hls') {
            this.videoTimeout = setTimeout(() => {
              this.tryStartLive();
            }, 5000);
          }
          if (window.fullScreen || (window.innerWidth === screen.width && window.innerHeight === screen.height)) {
            this.closeFullscreen();
          }
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'prejoin') {
      next(vm => {
        if (vm.isWebinarEnded) {
          vm.$router.go();
        }
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
.webinar-container {
  display: flex;
  background-color: var(--v-darkblue-60);

  &.mobile {
    height: 100%;
  }

  .webinar-wrapper {
    padding: 0;
    position: relative;

    .webinar-preload {
      inset: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .logo {
        margin-bottom: 55px;
      }

      .title {
        font-weight: 500;
        font-size: 26px;
        line-height: 27px;
        margin-bottom: 12px;
      }

      .content {
        font-weight: normal;
        font-size: 22px;
        line-height: 27px;
      }
    }

    .webinar-entrance {
      position: absolute;
      height: 50%;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .webinar-entrance-image-wrapper {
        max-width: 859px;
        text-align: center;
        margin-bottom: 100px;
      }

      img {
        width: 90%;
      }
    }

    .menu-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .webinar-ended {
      height: calc(100% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .webinar-page {
    height: calc(100vh - 192px);
    display: flex;
    align-items: center;
    justify-content: center;

    .webinar {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: center;
      align-items: center;
      z-index: 0;
      width: 100%;
      height: 100%;

      .room-information-box {
        position: fixed;
        right: 20px;
        top: 8px;
        display: flex;
        z-index: 2;
        align-items: center;

        .room-name {
          font-size: 12px;

          border-radius: var(--default-button-radius) !important;
          transition: var(--default-transition);
          position: relative;
          top: 0;
          height: 40px;
          text-shadow: none;
          text-align: left;
          border-radius: 4px;
          display: inline-block;
          padding: 0.1rem 1rem;
          margin: 0 0rem;
          flex-grow: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre;
          max-width: 200px;
          cursor: pointer;
        }
      }

      #webinar-video {
        width: 100%;
        height: 100%;
        grid-row: 1;
        grid-column: 1;
        object-fit: contain;
        max-height: calc(100vh - 200px);

        outline: none !important;

        ::v-deep video {
          outline: none !important;
        }

        video {
          width: 100%;
          height: 100%;
          grid-row: 1;
          grid-column: 1;
          object-fit: contain;
          max-height: calc(100vh - 200px);
        }
        &.playing {
          video {
            position: absolute;
            left: 0;
            right: 0;
            top: 80px;
            bottom: 0;
            width: 100%;
            height: 100%;
            max-height: calc(100vh - 82px);
          }
        }
      }
    }
  }
  .play-container {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    top: 0px;
    left: 0px;
    justify-content: center;

    .mobile {
      width: 160px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .webinar-container {
    .webinar-page {
      .webinar {
        .room-information-box {
          top: unset;
          bottom: 0.5rem;
          left: 0.5rem;
          right: 0.5rem;

          .room-name {
            max-width: initial;
          }
        }
      }
    }
  }
}
</style>
