var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "home-container px-5 py-4 px-md-15 py-md-8 overflow-auto",
      attrs: { fluid: "" },
    },
    [
      _c(
        "div",
        { staticClass: "header d-flex justify-space-between align-center" },
        [_c("logo"), _c("lang-switch")],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column justify-center align-center" },
        [
          _c("div", [
            _c("img", {
              staticClass: "w-full welcome-image",
              style: {
                "margin-left": _vm.$vuetify.breakpoint.smAndUp ? "-40px" : "0",
              },
              attrs: { src: require("@/assets/images/welcome-image.svg") },
            }),
          ]),
          !_vm.isGoToConferenceTab
            ? _c(
                "v-row",
                { staticClass: "mt-16" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "6", md: "6" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-capitalize",
                          class: { active: true },
                          attrs: {
                            "min-width": "220",
                            color: "primary",
                            block: _vm.$vuetify.breakpoint.mobile,
                            large: _vm.$vuetify.breakpoint.smAndUp,
                            "data-test-id": "W_H_GC",
                          },
                          on: { click: _vm.onJoinClicked },
                        },
                        [
                          _c("icon", {
                            staticClass: "mr-2 text-capitalize",
                            attrs: {
                              left: "",
                              dense: "",
                              icon: "link",
                              size: "24",
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("joinConference")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", sm: "6", md: "6" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "min-width": "220",
                            color: "secondary",
                            block: _vm.$vuetify.breakpoint.mobile,
                            large: _vm.$vuetify.breakpoint.smAndUp,
                            "data-test-id": "W_H_CC",
                          },
                          on: { click: _vm.onCreateClicked },
                        },
                        [
                          _c("icon", {
                            staticClass: "mr-2 text-capitalize",
                            attrs: {
                              left: "",
                              dense: "",
                              icon: "plus-circle",
                              size: "24",
                            },
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("createAConference")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "mt-4 mt-xl-16 join-conference" },
                [
                  _c(
                    "div",
                    { staticClass: "pl-0" },
                    [
                      _c("icon", {
                        staticClass: "mr-4",
                        attrs: { color: "secondary", icon: "video" },
                      }),
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(_vm.$t("conferenceName"))),
                      ]),
                    ],
                    1
                  ),
                  _c("text-field", {
                    staticClass: "mt-5",
                    attrs: {
                      label: _vm.$t("enterAConferenceName"),
                      "error-messages": _vm.$t(_vm.conferenceNameError),
                      "data-test-id": "W_H_CN",
                      autocomplete: "conference-name",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.goToConference()
                      },
                    },
                    model: {
                      value: _vm.conferenceName,
                      callback: function ($$v) {
                        _vm.conferenceName = $$v
                      },
                      expression: "conferenceName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end flex-wrap" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "min-width": "140",
                            outlined: "",
                            "data-test-id": "W_H_GC_C_BTN",
                            block: _vm.$vuetify.breakpoint.xs,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "ml-4": _vm.$vuetify.breakpoint.smAndUp,
                            "mt-3": _vm.$vuetify.breakpoint.xs,
                          },
                          attrs: {
                            "min-width": "140",
                            color: "secondary",
                            "data-test-id": "W_H_GC_BTN",
                            block: _vm.$vuetify.breakpoint.xs,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goToConference()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }