var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("recordList"),
        "confirm-button-text": _vm.$t("downloadRecords"),
        "cancel-button-text": _vm.$t("close"),
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c(
        "div",
        [
          _c("Tab", {
            staticClass: "mb-2",
            attrs: { tabItems: _vm.tabItems, sharedWith: _vm.selectedTab },
            on: {
              onTabClick: function ($event) {
                _vm.selectedTab = $event
              },
            },
          }),
          _vm.selectedTab === "local"
            ? _c(
                "div",
                [
                  !_vm.localRecords || _vm.localRecords.length === 0
                    ? _c("div", { staticClass: "pl-4 mb-5 text-center" }, [
                        _c("p", { staticClass: "mt-6" }, [
                          _vm._v(_vm._s(_vm.$t("noData"))),
                        ]),
                      ])
                    : _c(
                        "v-list",
                        { attrs: { "two-line": "" } },
                        [
                          _c("v-virtual-scroll", {
                            attrs: {
                              items: _vm.localRecords,
                              "item-height": 50,
                              height: "210",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c("RecordListItem", {
                                        attrs: {
                                          title: item.name,
                                          loading: _vm.localRecordDownloading,
                                        },
                                        on: {
                                          download: function ($event) {
                                            return _vm.downloadLocalRecord(item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2680481706
                            ),
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedTab === "remote"
            ? _c(
                "div",
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "list-item-two-line@5" },
                      })
                    : !_vm.remoteRecords || _vm.remoteRecords.length === 0
                    ? _c("div", { staticClass: "pl-4 mb-5 text-center" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("recordsDescription")))]),
                        _c("p", { staticClass: "mt-6" }, [
                          _vm._v(_vm._s(_vm.$t("noData"))),
                        ]),
                      ])
                    : _c(
                        "v-list",
                        { attrs: { "two-line": "" } },
                        [
                          _c("v-virtual-scroll", {
                            attrs: {
                              items: _vm.remoteRecords,
                              "item-height": 50,
                              height: "210",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c("RecordListItem", {
                                        attrs: {
                                          title: item.name,
                                          subTitle: _vm.fileStatus[item.fileKey]
                                            ? `%${_vm.fileStatus[item.fileKey]}`
                                            : "",
                                          loading:
                                            !!_vm.fileStatus[item.fileKey],
                                        },
                                        on: {
                                          download: function ($event) {
                                            return _vm.download(item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1315704336
                            ),
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }