// axios
import axios from 'axios';

const domain = process.env.VUE_APP_ORCHESTRATOR_SERVICE + '/';

const config = {
  baseURL: domain,
  timeout: 30000,
};

const axiosConfig = axios.create(config);

export default axiosConfig;
