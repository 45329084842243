<template>
  <Modal v-model="dialog" :title="$t('speakerStats')" max-width="500px">
    <div class="ma-3 mb-0 speaker-stats">
      <div class="d-flex justify-end">
        <IconButton v-if="$can('downloadUserStatistics', 'Meet')" icon="download" v-tooltip="$t('downloadSpeakerStats')" @click.stop="downloadStatList" />
      </div>

      <v-virtual-scroll :items="getUsers" :height="height" item-height="64">
        <template v-slot="{ item }">
          <v-list-item class="mt-3" dense :key="stats[item.id]._userId" v-if="stats[item.id] && (stats[item.id]._isLocalStats || stats[item.id].displayName)">
            <v-list-item-content>
              <div class="d-flex align-center">
                <avatar :display-name="item.displayName" :image="item.avatar" :avatar-size="48" />
                <span class="ml-2">{{ stats[item.id]._isLocalStats ? `${item.displayName} (${$t('you')})` : item.displayName }}</span>
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-row>{{ msToHour(stats[item.id].getTotalDominantSpeakerTime()) }}s</v-row>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="stats[item.id] && (stats[item.id]._isLocalStats || stats[item.id].displayName)"></v-divider>
        </template>
      </v-virtual-scroll>
    </div>
  </Modal>
</template>

<script>
import Xpermeet from 'xpermeet-lib';
import dayjs from 'dayjs';

import { mapGetters, mapState } from 'vuex';

import IconButton from '@/components/Shared/IconButton';
import Avatar from '@/components/Shared/Avatar';
import { msToHour } from '@/helpers/time';
import { jsonToCsv } from '@/helpers/export-csv';

export default {
  name: 'SpeakerStats',
  components: { IconButton, Avatar },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stats: {},
      dialog: false,
    };
  },
  computed: {
    ...mapState('Conference', ['roomName']),
    ...mapGetters('Conference', ['getUsers', 'getLocalUser']),
    height() {
      const { width } = window?.screen || {};
      if (width < 1400) return 200;
      if (width >= 1400 && width <= 1920) return 200;
      return 300;
    },
  },
  mounted() {
    this.stats = Xpermeet.conference.room.getSpeakerStats();

    this._updateInterval = setInterval(() => {
      if (Xpermeet.conference.room) {
        this.stats = Xpermeet.conference.room.getSpeakerStats();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this._updateInterval);
  },
  methods: {
    msToHour,
    close() {
      this.$modal?.close();
    },
    downloadStatList() {
      let statList = Object.values(this.stats)
        .filter(u => !u.hasLeft())
        .map(stat => ({
          Name: stat._isLocalStats ? `${this.getLocalUser.displayName} (${this.$t('you')})` : stat.getDisplayName(),
          Time: msToHour(stat.getTotalDominantSpeakerTime()) + 's',
        }));
      let name = 'statList.csv';

      let time = dayjs().format('MM_DD_YYYY_HH_mm');
      let fileName = `${this.roomName}-${time}-${name}`;
      jsonToCsv(statList, fileName);
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.dialog = this.show;
      },
    },
  },
};
</script>

<style lang="scss">
.speaker-stats {
  .avatar-default-icon {
    min-width: 36px !important;
    min-height: 36px !important;
  }
}
</style>
