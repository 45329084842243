/* global $ */
import { $iq, Strophe } from "strophe.js";
import isEqual from "lodash.isequal";

import { consoleError, consoleLog } from "../utils/logger";
import EventEmitter from "../utils/EventEmitter";
import Enums from "../constants/enums";
import DefaultRoomConfig from "../constants/defaultRoomConfig";
import Conference from "./Conference";

const eventEmitter = new EventEmitter();

const EVENT_NAME = "XPER-CONFIG";
const EVENT_TYPE_NAME = "xperconfig-notify";
const WEBINAR_TYPE_NAME = "webinar-streamid-notify";
const ROOM_ENTRY_BLOCKED = "room-entry-blocked";

/**
 *
 * @type {(string)[]}
 * if you add something as a property to this.configObject in constructor
 * you must define that key to fallowing CONFIG_KEYS array to restriction.
 */
const CONFIG_KEYS = [
  Enums.RoomConfigEnums.FULL_SCREEN_USER_ID,
  Enums.RoomConfigEnums.FOLLOW_MODERATOR_FULL_SCREEN,
  Enums.RoomConfigEnums.LAYOUT,

  // config keys
  Enums.RoomConfigEnums.ALLOW_FILE_SHARE,
  Enums.RoomConfigEnums.ALLOW_PRIVATE_CHAT,
  Enums.RoomConfigEnums.ALLOW_SCREEN_SHARE,
  Enums.RoomConfigEnums.ALLOW_ADMIN_START_VIDEO,
  Enums.RoomConfigEnums.START_MUTED_POLICY,
  Enums.RoomConfigEnums.START_VIDEO_MUTED_POLICY,
  Enums.RoomConfigEnums.REMOTE_RECORD_STARTED_BY,
  Enums.RoomConfigEnums.SHARED_PROPERTIES,
  Enums.RoomConfigEnums.E2EE_ENABLED,
  Enums.RoomConfigEnums.WEBINAR_STREAM_CHANNEL,
  Enums.RoomConfigEnums.PACKAGE_CONFIG,
  Enums.RoomConfigEnums.AR_FILE_LIST,
  Enums.RoomConfigEnums.WEBINAR_TITLE,
  Enums.RoomConfigEnums.LOBBY_ENABLED,
];

class RoomConfig {
  constructor() {
    this.muc = "roomconfig_xperconfig";
    this.enums = Object.freeze(Enums.RoomConfigEnums);
    this.roomjid = null;
    this.xmppConnection = null;
    this.configObject = DefaultRoomConfig;
    this.startingSettings = null;
  }

  init(roomjid, xmppConnection, startingSettings) {
    this.roomjid = roomjid;
    this.xmppConnection = xmppConnection;
    this.startingSettings = startingSettings;

    this.addHandlers();
  }

  async setStartingSettings() {
    if (this.startingSettings) {
      if (!Conference.remoteUsers.length) {
        if (this.startingSettings.startMutedPolicy && this.startingSettings.startVideoMutedPolicy) {
          await this.fetchDataSource();

          this.updateDataSource({
            ...this.configObject,
            ...this.startingSettings,
          });
        }
      }
    }
  }

  addHandlers() {
    this.xmppConnection.addHandler(this.xmppOnMessage.bind(this), null, "message", null, null);
  }

  xmppOnMessage(msg) {
    const jsonMessageString = $(msg).find("json-message").text();
    try {
      const jsonMessage = JSON.parse(jsonMessageString);

      if (jsonMessage.event === EVENT_NAME && jsonMessage.type === EVENT_TYPE_NAME) {
        const propertiesString = jsonMessage.value;
        const properties = JSON.parse(propertiesString);
        const propertyDifference = this.getDifference(properties);

        if (propertyDifference.length === 0) {
          consoleLog("There is no difference in room property!");
          return true;
        }

        if (propertyDifference.length > 1) {
          consoleLog("Multiple changed detected!");
        }

        propertyDifference.forEach((item) => {
          eventEmitter.emit("configParameterChanged", item);
        });
        this.setConfigObject(properties);
      } else if (jsonMessage.event === EVENT_NAME && jsonMessage.type === WEBINAR_TYPE_NAME) {
        if (jsonMessage?.streamId) {
          localStorage.setItem("moderatorSecret", jsonMessage.streamId);
        }
      } else if (jsonMessage.event === EVENT_NAME && jsonMessage.type === ROOM_ENTRY_BLOCKED) {
        Conference.emit("RoomEntryBlocked");
      }
    } catch (e) {
      consoleLog("xmppMessage format is wrong");
    }
    return true;
  }

  getDifference(newProperties) {
    let diff = [];
    Object.keys(newProperties).forEach((key) => {
      if (!isEqual(this.configObject[key], newProperties[key])) {
        diff.push({ key, value: newProperties[key] });
      }
    });
    return diff;
  }

  getProperty(key) {
    return this.configObject[key];
  }

  setProperty(key, value) {
    if (!CONFIG_KEYS.includes(key)) {
      return;
    }
    this.updateDataSource({ [key]: value });
  }

  setProperties(value) {
    const propertyObject = JSON.parse(JSON.stringify(value));

    // Remove invalid room properties
    Object.keys(propertyObject).forEach((key) => {
      if (!CONFIG_KEYS.includes(key)) {
        delete propertyObject[key];
      }
    });

    this.updateDataSource(propertyObject);
  }

  setConfigObject(properties) {
    Object.entries(properties).forEach((item) => {
      if (CONFIG_KEYS.includes(item[0])) {
        this.configObject[item[0]] = item[1];
      }
    });
  }

  fetchDataSource() {
    const getInfo = $iq({
      type: "get",
      to: this.roomjid,
    }).c("query", { xmlns: Strophe.NS.DISCO_INFO });

    return new Promise((resolve, reject) => {
      const onError = (err) => {
        consoleError("error fetchDataSource");
        eventEmitter.emit("error", { key: "fetchDataSource", error: err });
        reject(err);
      };

      const onSuccess = (result) => {
        try {
          const configXml = $(result).find(`>query>x[type="result"]>field[var="muc#${this.muc}"]>value`);
          const objectString = configXml.text();
          this.configObject = { ...DefaultRoomConfig, ...JSON.parse(objectString) };
        } catch (e) {
          consoleLog("There is no room property.");
        }

        resolve(this.configObject);
      };

      this.xmppConnection.sendIQ(getInfo, onSuccess, onError);
    });
  }

  updateDataSource(configObject) {
    const onError = (err) => {
      consoleLog("error fetchDataSource");
      eventEmitter.emit("error", { key: "updateDataSource", error: err });
    };
    const onSuccess = () => {
      consoleLog(`RoomConfig.updateDataSource: ${JSON.stringify(configObject)}`);
    };

    // Set config object
    const config = JSON.stringify({ ...this.configObject, ...configObject });

    const formsubmit = $iq({
      to: this.roomjid,
      type: "set",
    }).c("query", {
      xmlns: "http://jabber.org/protocol/muc#owner",
    });

    formsubmit.c("x", {
      xmlns: "jabber:x:data",
      type: "submit",
    });
    formsubmit.c("field", { var: "FORM_TYPE" }).c("value").t("http://jabber.org/protocol/muc#roomconfig").up().up();
    formsubmit
      .c("field", { var: `muc#${this.muc}` })
      .c("value")
      .t(config)
      .up()
      .up();
    this.xmppConnection.sendIQ(formsubmit, onSuccess, onError);
  }

  emit(eventType, callbackFn) {
    eventEmitter.emit(eventType, callbackFn);
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }

  clearEvents() {
    this.localUser.clearEvents();
    eventEmitter.clear();
  }
}

export default new RoomConfig();
