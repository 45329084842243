<template>
  <div class="survey-detail mt-5">
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="question-list">
          <v-btn text @click="addQuestion" class="question-list--add-question">
            <icon icon="plus-circle" class="mr-2"></icon>
            {{ $t('addQuestion') }}
          </v-btn>
          <div class="ellipsis mb-2">{{ survey.Title }}</div>
          <div class="question-list--item d-flex flex-column px-3 py-2 mb-2 " v-for="(q, i) in questions" :key="q.id">
            <div class="question-list--item--index">{{ `${$t('question')} ${i + 1}` }}</div>
            <div class="question-list--item--text">{{ q.Questions }}</div>
            <v-progress-linear indeterminate color="secondary" class="my-2" v-if="loading" />
            <div v-else class=" d-flex justify-end">
              <v-btn icon x-small class="ml-3" @click="deleteQuestion(q.Id)">
                <icon size="14" icon="trash"></icon>
              </v-btn>
              <v-btn icon x-small class="ml-3" @click="editQuestion(q)">
                <icon size="14" icon="edit"></icon>
              </v-btn>
            </div>
          </div>
          <v-row class="mt-5">
            <v-col sm="12" md="6">
              <v-btn block outlined @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>
            </v-col>
            <v-col sm="12" md="6">
              <v-btn block color="secondary" @click="publish" :disabled="!survey.Title || disableShareButton" :loading="loading">{{ $t('publish') }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item>
        <SurveyQuestionCreateNUpdate :edit-model="selectedQuestion" :survey="survey" @cancel="setTab(0)" @onSaveQuestion="getQuestions" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { consoleError } from '@/helpers/logger';
import SurveyQuestionCreateNUpdate from './SurveyQuestionCreateNUpdate';

export default {
  name: 'SurveyDetail',
  components: {
    SurveyQuestionCreateNUpdate,
  },
  props: {
    survey: Object,
  },
  data() {
    return {
      mockId: 'new-question',
      tab: 0,
      data: null,
      questions: [],
      selectedQuestion: null,
      loading: false,
    };
  },
  computed: {
    ...mapState('Webinar', ['webinarSessionId']),
    disableShareButton() {
      return this.webinarSessionId ? this.survey.shared && this.survey.sharedWithWebinar : this.survey.shared;
    },
  },
  mounted() {
    this.getQuestions();
  },
  methods: {
    ...mapActions('Survey', ['getSurveyQuestions', 'deleteSurveyQuestion']),
    publish() {
      this.$emit('openConfirm', {
        type: 'share',
        survey: {
          ...this.survey,
          shareWithRoom: this.survey.shared ? false : true,
          shareWithWebinar: false,
        },
        completed: () => {
          this.setTab(0);
        },
      });
    },
    editQuestion(question) {
      this.selectedQuestion = { ...question };
      this.tab = 1;
    },
    setTab(tab = 0) {
      if (tab === 1 && this.questions.length === 0) {
        this.tab = 0;
      } else {
        this.tab = tab;
      }
    },
    getQuestions() {
      if (this.survey.Id === this.mockId) {
        return;
      }

      const payload = {
        SurveyId: this.survey.Id,
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 10,
          },
        },
      };

      this.getSurveyQuestions(payload)
        .then(response => {
          this.questions = response.data.Result.List;
        })
        .catch(err => {
          consoleError(err);
        });
    },
    async deleteQuestion(id) {
      try {
        this.loading = true;
        await this.deleteSurveyQuestion([id]);
        this.getQuestions();
        this.loading = false;
      } catch (err) {
        consoleError(err);
        this.loading = false;
      }
    },
    addQuestion() {
      this.tab = 1;
      this.selectedQuestion = null;
    },
  },
  watch: {
    survey: {
      deep: true,
      immediate: true,
      handler() {
        this.data = this.survey;
        this.data.questions = this.data.questions || [];
      },
    },
    questions: {
      deep: true,
      handler() {
        if (this.questions.length > 0) {
          this.tab = 0;
        } else {
          this.tab = 1;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.survey-detail {
  .v-tabs-items {
    border-top: none;
  }

  .question-list {
    &--add-question {
      position: absolute;
      top: -3.3rem;
      right: -1rem;
    }

    &--item {
      position: relative;
      border: 1px solid #454c66;
      border-radius: 8px;
      &--index {
        font-weight: 300;
        font-size: 10px;
        color: #afb2c6;
      }

      &--text {
        font-size: 14px;
        word-break: break-word;
        max-width: 300px;
      }
    }
  }
}
</style>
