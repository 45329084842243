<template>
  <div class="expension-panel-notification-item d-flex justify-space-between align-center my-4 px-4">
    <div class="mr-3 d-flex justify-center align-center">
      <span class="notification-highlight mr-2" :class="{ unread: !notification.read }"></span>
      <avatar class="avatar" iconSize="50%" :avatar-size="48" :display-name="avatarProperties.displayName" :image="avatarProperties.avatar" />
    </div>
    <component :is="notificationComponent" :notification="notification" :user="user" />
  </div>
</template>
<script>
import NotificationDefault from '@/components/Notifications/RoomNotifications/NotificationDefault';
import NotificationFileShare from '@/components/Notifications/RoomNotifications/NotificationFileShare';
import NotificationHandsUp from '@/components/Notifications/RoomNotifications/NotificationHandsUp';
import ENUMS from '@/constants/ENUMS';
const { ROOM_NOTIFICATION_TYPES } = ENUMS;

export default {
  name: 'ExpansionPanelNotificationItem',
  props: {
    user: {
      type: Object,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notificationComponent() {
      if (this.notification.type === ROOM_NOTIFICATION_TYPES.HANDS_UP) {
        return NotificationHandsUp;
      } else if (this.notification.type === ROOM_NOTIFICATION_TYPES.FILE_SHARE) {
        return NotificationFileShare;
      } else {
        return NotificationDefault;
      }
    },
    avatarProperties() {
      return {
        displayName: this?.user?.displayName || '',
        avatar: this?.user?.avatar || '',
      };
    },
  },
};
</script>
<style lang="scss">
.expension-panel-notification-item {
  .notification-highlight {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.unread {
      background: var(--v-logo-color-base);
    }
  }
  .user-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .content {
    .action-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .actions {
    .action {
      width: 20px;
      height: 20px;
      border-radius: 8px;
      &.check {
        background: var(--v-success-base);
        &.disabled {
          background: var(--v-success-darken3);
        }
      }
      &.close {
        background: var(--v-error-base);
      }
    }
  }
}
</style>
