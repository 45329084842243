<template>
  <svg
    v-if="svgContent"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    :viewBox="`0 0 ${width} ${width}`"
    :aria-labelledby="icon"
    role="presentation"
    :style="{ color: getColor }"
    v-on="$listeners"
  >
    <g :stroke-width="stroke" stroke="currentColor" fill="currentColor" v-html="svgContent"></g>
  </svg>
  <v-icon v-else v-bind="{ ...$props, ...$attrs }" dark v-on="$listeners" :class="{ 'spinner-animation': loading }">{{ iconLibName }} icon-{{ icon }}</v-icon>
</template>

<script>
import { FEATHER_ICON_NAMES } from '@/constants/icons';

const SIZE_MAP = {
  xSmall: '12px',
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px',
};

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconLib: {
      type: String,
    },
    stroke: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 'default',
    },
  },
  data() {
    return {
      svgContent: '',
    };
  },
  computed: {
    iconLibName() {
      return this.iconLib || FEATHER_ICON_NAMES.includes(this.icon) ? 'feather' : 'bizbize-icons';
    },
    getColor() {
      if (this.color) {
        if (this.color.startsWith('#')) {
          return this.color;
        } else if (this.color.startsWith('var(')) {
          return this.color;
        } else {
          return `var(--v-${this.color}-base)`;
        }
      }
      return undefined;
    },
    width() {
      if (this.size in SIZE_MAP) {
        return SIZE_MAP[this.size].replace('px', '');
      } else if (this.size.toString().endsWith('px')) {
        return this.size.replace('px', '');
      } else {
        return this.size || 18;
      }
    },
  },
  created() {
    this.applySvgContent();
  },
  mounted() {
    this.applySvgSize();
  },
  updated() {
    this.applySvgContent();
    this.$nextTick(() => {
      this.applySvgSize();
    });
  },
  methods: {
    applySvgContent() {
      try {
        this.svgContent = require(`!!html-loader!@/assets/icons/${this.icon}.svg`);
      } catch (e) {
        this.svgContent = null;
      }
    },
    applySvgSize() {
      if (this.svgContent) {
        const elm = this.$el.querySelector('svg');
        elm.setAttribute('width', this.width);
        elm.setAttribute('height', this.width);
      }
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
