<template>
  <Modal
    :title="$t('goToConference')"
    :confirm-button-text="$t('joinConference')"
    :cancel-button-text="$t('cancel')"
    :confirm-button-disabled="isDisabled"
    @close="close"
    show-actions
  >
    <div class="mb-6 text-center">{{ $t('createConferenceInfoText') }}</div>
    <TextField
      v-model="conferenceName"
      :label="$t('conferenceName')"
      :error-messages="$t(conferenceNameError)"
      @keydown.enter.prevent="goToConference()"
      hide-details="auto"
    ></TextField>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConferenceCreate',
  data() {
    return {
      conferenceName: '',
      conferenceNameError: false,
      setting: 'startConferenceUnmuted',
    };
  },
  computed: {
    ...mapGetters('Conference', ['remoteCameraControlSupported']),
    isDisabled() {
      return this.conferenceName.length < 4 || this.conferenceName.length > 5;
    },
  },
  methods: {
    close(type) {
      if (type === 'confirm') {
        this.goToConference();
      }
    },
    goToConference() {
      if (!this.conferenceName) {
        this.conferenceNameError = 'conferenceNameRequired';
        return null;
      }

      window.location.href = `/${this.conferenceName}`;
    },
  },
};
</script>
