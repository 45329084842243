/**
 * This module is responsible for handling click events when a user starts
 * streaming AR track in conference
 */

import ConferenceInstance from "./Conference";
import RoomConfig from "./RoomConfig";
import Enums from "../constants/enums";
import { consoleLog } from "../utils/logger"; // eslint-disable-line

class DrawingData {
  constructor() {
    this.min = { x: Number.MAX_VALUE, y: Number.MAX_VALUE };
    this.max = { x: Number.MIN_VALUE, y: Number.MIN_VALUE };
    this.imageData = [];
  }

  update(x, y) {
    this.min.x = x < this.min.x ? x : this.min.x;
    this.min.y = y < this.min.y ? y : this.min.y;

    this.max.x = x > this.max.x ? x : this.max.x;
    this.max.y = y > this.max.y ? y : this.max.y;

    this.imageData.push(x);
    this.imageData.push(y);
  }

  normalize(w, h) {
    this.imageData.forEach((coordData, index, arr) => {
      if (index % 2 == 0) arr[index] = coordData - this.min.x;
      else arr[index] = coordData - this.min.y;
    });

    this.min.x /= w;
    this.min.y /= h;

    this.max.x /= w;
    this.max.y /= h;
  }
}

class CanvasManager {
  constructor(drawingCallbackFunction) {
    this.isDrawing = false;
    this.lastMouseEvent = null;
    this.canvas = null;
    this.arVideoID = null;
    this.currentDrawingData = null;
    this.context = null;
    this.drawingCallback = drawingCallbackFunction;

    this.resizeObserver = new ResizeObserver((elements) => {
      this.resizeCallback(elements);
    });
  }

  setColor(color) {
    this.color = color;
    this.context.strokeStyle = this.color;
  }

  resizeCallback(/* elements */) {
    //Adjusts canvas
    //let resizedElement = elements[0];
    let videoElement = document.getElementById(`remote-video-${this.arVideoID}`);
    let videoRect = videoElement.getBoundingClientRect();

    //this.canvas.style.left = resizedElement.contentRect.width / 2 - videoRect.width / 2 + "px";
    this.canvas.width = videoRect.width;

    //this.canvas.style.top = resizedElement.contentRect.height / 2 - videoRect.height / 2 + "px";
    this.canvas.height = videoRect.height;

    //Add event listeners & style initializations
    this.context = this.canvas.getContext("2d");
    this.context.strokeStyle = this.color;
    this.context.lineWidth = 3;
    this.context.lineCap = "round";

    this.isDrawing = false;
    this.lastMousePosition = null;
    this.canvas.addEventListener("mousedown", (event) => {
      this.canvasMouseDownEventHandler(event);
    });
    this.canvas.addEventListener("mouseup", (event) => {
      this.canvasMouseUpEventHandler(event);
    });
    this.canvas.addEventListener("mousemove", (event) => {
      this.canvasMouseMoveEventHandler(event);
    });
    this.canvas.addEventListener("mouseleave", (event) => {
      this.canvasMouseLeaveEventHandler(event);
    });
  }

  initializeCanvas(arVideoID, color) {
    this.arVideoID = arVideoID;
    this.color = color;

    //Create canvas
    this.canvas = document.createElement("canvas");
    this.canvas.setAttribute("id", `canvas-${this.arVideoID}`);
    this.canvas.style.position = "absolute";
    this.canvas.style.right = "0";
    this.canvas.style.left = "0";
    this.canvas.style.top = "0";
    this.canvas.style.margin = "auto";
    this.canvas.style.zIndex = "10";

    //Add canvas to parent div of video`
    document.getElementById(`remote-video-${this.arVideoID}`).parentElement.appendChild(this.canvas);

    //Setup resize observer to adjust canvas
    this.resizeObserver.observe(document.getElementById(`remote-video-${this.arVideoID}`).parentElement);
  }

  destroyCanvas() {
    document.getElementById(`remote-video-${this.arVideoID}`).parentElement.removeChild(this.canvas);
    this.resizeObserver.unobserve(document.getElementById(`remote-video-${this.arVideoID}`).parentElement);
  }

  canvasMouseDownEventHandler(event) {
    this.isDrawing = true;
    this.lastMouseEvent = event;
    this.currentDrawingData = new DrawingData();

    //Initial point
    this.currentDrawingData.update(event.offsetX, event.offsetY, this.canvas.width, this.canvas.height);
  }

  canvasMouseUpEventHandler(event) {
    this.isDrawing = false;
    consoleLog(event);

    let w = this.currentDrawingData.max.x - this.currentDrawingData.min.x;
    let h = this.currentDrawingData.max.y - this.currentDrawingData.min.y;

    if (w >= 1 && h >= 1 && this.currentDrawingData != null) {
      this.currentDrawingData.width = w;
      this.currentDrawingData.height = h;
      this.currentDrawingData.normalize(this.canvas.width, this.canvas.height);

      this.drawingCallback(this.currentDrawingData);
    }

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  canvasMouseLeaveEventHandler(event) {
    this.isDrawing = false;
    consoleLog(event);
  }

  canvasMouseMoveEventHandler(event) {
    if (this.isDrawing) {
      this.context.beginPath();
      this.context.moveTo(this.lastMouseEvent.offsetX, this.lastMouseEvent.offsetY);
      this.context.lineTo(event.offsetX, event.offsetY);
      this.context.stroke();

      this.currentDrawingData.update(event.offsetX, event.offsetY, this.canvas.width, this.canvas.height);
      this.lastMouseEvent = event;
    }
  }
}

class ARModule {
  constructor() {
    this.arVideoID = null;
    this.isEnabled = false;
    this.canvasManager = null;
    this.arFileList = null;

    this.arTapWrapper = (event) => {
      this.arClickHandler(event);
    };

    this.color = "#22B14C";
    this.objectId = "0";
    this.actionType = 0;
    this.customValues = [];
  }

  setColor(color) {
    this.color = color;

    if (this.canvasManager) {
      this.canvasManager.setColor(this.color);
    }
  }

  setObject(objectId) {
    if (Object.values(Enums.AR_OBJECT_TYPES).some((item) => item === objectId)) {
      this.objectId = objectId;
    } else if (RoomConfig.configObject.arFileList.fileList?.some((item) => item.fileKey === objectId)) {
      this.objectId = objectId;
    } else {
      consoleLog("Unkown object id!");
    }
  }

  sendArModelList(modelList) {
    const message = this.createArModelListMessage(modelList);
    ConferenceInstance.sendMessage(message, this.arVideoID);
  }

  setActionType(actionType) {
    if (Object.values(Enums.AR_ACTION_TYPES).some((item) => item === actionType)) {
      //Switch to free drawing mode
      if (this.actionType !== Enums.AR_ACTION_TYPES.ADD_DRAWING && actionType === Enums.AR_ACTION_TYPES.ADD_DRAWING) {
        this.canvasManager = new CanvasManager((drawingData) => {
          this.drawingCallback(drawingData);
        });
        this.canvasManager.initializeCanvas(this.arVideoID, this.color);
      }

      //Disable canvas if free drawing disabled
      if (this.actionType === Enums.AR_ACTION_TYPES.ADD_DRAWING && actionType !== Enums.AR_ACTION_TYPES.ADD_DRAWING) {
        this.canvasManager.destroyCanvas();
        this.canvasManager = null;
      }

      this.actionType = actionType;
    } else {
      consoleLog("Unkown object id!");
    }
  }

  enableAREventHandler(arVideoID) {
    this.arVideoID = arVideoID;
    this.isEnabled = true;

    if (this.actionType === Enums.AR_ACTION_TYPES.ADD_DRAWING) {
      this.canvasManager = new CanvasManager((drawingData) => {
        this.drawingCallback(drawingData);
      });
      this.canvasManager.initializeCanvas(this.arVideoID, this.color);
    }
    const remoteVideo = document.getElementById(`remote-video-${this.arVideoID}`);
    remoteVideo.style.width = "auto";
    remoteVideo.style.height = "100%";
    remoteVideo.style.margin = "auto";
    remoteVideo.style.right = "0";
    remoteVideo.addEventListener("click", this.arTapWrapper);
  }

  disableAREventHandler() {
    if (this.isEnabled) {
      const remoteVideo = document.getElementById(`remote-video-${this.arVideoID}`);
      remoteVideo.style.removeProperty("width");
      remoteVideo.style.removeProperty("height");
      remoteVideo.style.removeProperty("margin");
      remoteVideo.style.removeProperty("right");

      remoteVideo.removeEventListener("click", this.arTapWrapper);

      if (this.canvasManager != null) this.canvasManager.destroyCanvas();

      this.isEnabled = false;
      this.arVideoID = null;
      this.canvasManager = null;
      this.objectId = 0;
      this.actionType = 0;
    }
  }

  drawingCallback(drawingData) {
    let message = this.createAddDrawingObjectMessage(drawingData);
    ConferenceInstance.sendMessage(message, this.arVideoID);
  }

  arClickHandler(event) {
    const xPos = event.offsetX;
    const yPos = event.offsetY;

    const width = event.srcElement.clientWidth;
    const height = event.srcElement.clientHeight;

    let placementX = xPos / width;
    let placementY = yPos / height;

    let message;

    if (this.actionType === Enums.AR_ACTION_TYPES.ADD) message = this.createAddObjectMessage(placementX, placementY);
    else if (this.actionType === Enums.AR_ACTION_TYPES.DELETE) message = this.createInteractiveMessage(placementX, placementY);
    else if (this.actionType === Enums.AR_ACTION_TYPES.ADD_TEXT) message = this.createTextMessage(placementX, placementY);
    else if (this.actionType === Enums.AR_ACTION_TYPES.ADD_NUMBER) message = this.createNumberInput(placementX, placementY);
    else if (this.actionType === Enums.AR_ACTION_TYPES.INTERACT) message = this.createInteractiveMessage(placementX, placementY);

    if (message) {
      ConferenceInstance.sendMessage(message, this.arVideoID);
    }
  }

  setCustomValues(values) {
    this.customValues = values;
  }

  createTextMessage(placementX, placementY) {
    let msgText;
    if (this.customValues.length) {
      msgText = this.customValues[0]?.value || null;
    }

    let messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = this.actionType;
    messageObj.x = placementX;
    messageObj.y = placementY;
    messageObj.objColor = this.color;
    messageObj.msgText = msgText.toString();
    return JSON.stringify(messageObj);
  }

  createNumberInput(placementX, placementY) {
    const message = this.createTextMessage(placementX, placementY);

    if (message) {
      if (this.customValues.length && this.customValues[0]?.value) {
        this.customValues[0].value = parseInt(this.customValues[0].value);
        this.customValues[0].value++;
      }
    }
    return message;
  }

  deleteAllARObjects() {
    let message = this.createDeleteAllMessage();
    ConferenceInstance.sendMessage(message, this.arVideoID);
  }

  showDeletables() {
    const message = {
      type: "SET_AR_DATA",
      actionType: Enums.AR_ACTION_TYPES.SHOW_DELETABLES,
    };
    consoleLog('ARModule::showDeletables ', { message });

    ConferenceInstance.sendMessage(JSON.stringify(message), this.arVideoID);
  }

  createArModelListMessage(arModelList) {
    const messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = Enums.AR_ACTION_TYPES.LOAD_MODELS_FROM_DRIVE;
    messageObj.arModelList = arModelList;
    return JSON.stringify(messageObj);
  }

  createAddDrawingObjectMessage(drawingData) {
    let messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = this.actionType;
    messageObj.drawingData = drawingData;
    messageObj.color = this.color;
    return JSON.stringify(messageObj);
  }

  createAddObjectMessage(x, y) {
    let messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = this.actionType;
    messageObj.x = x;
    messageObj.y = y;
    messageObj.objId = this.objectId;
    messageObj.objColor = this.color;
    return JSON.stringify(messageObj);
  }

  //Includes eraser and interact functionality messages
  //Object type defines action behaviour
  createInteractiveMessage(x, y) {
    let messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = this.actionType;
    messageObj.x = x;
    messageObj.y = y;
    return JSON.stringify(messageObj);
  }

  createDeleteAllMessage() {
    let messageObj = {};
    messageObj.type = "SET_AR_DATA";
    messageObj.actionType = this.actionType;
    return JSON.stringify(messageObj);
  }
}

export default new ARModule();
