<template>
  <div class="device-selector-wrapper">
    <v-menu v-model="showCameraMenu" class="popup-menu" offset-y content-class="rounded" :disabled="!hasCamera">
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title py-6 align-center" v-bind="attrs" v-on="on" :class="{ 'cursor-default': !hasCamera }">
          <Icon :color="!hasCamera ? 'logo-color' : 'secondary'" :icon="!hasCamera ? 'video-off-1' : 'video'" class="mr-2 flex-shrink-0"></Icon>
          <span class="default-device-name ellipsis mr-2">{{ cameraText }}</span>
          <v-spacer></v-spacer>
          <Icon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasCamera }"></Icon>
        </div>
      </template>
      <CameraDeviceSelector
        v-if="showCameraMenu"
        ref="cameraDeviceSelector"
        :showPreview="showVideoPreview"
        v-model="selectedCameraId"
        data-test-id="P_PJ_C_1"
        @change="$emit('cameraChange', selectedCameraId)"
      />
    </v-menu>

    <v-menu v-model="showMicrophoneMenu" class="popup-menu" offset-y content-class="rounded" :disabled="!hasMicrophone">
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title py-6 align-center" v-bind="attrs" v-on="on" :class="{ 'cursor-default': !hasMicrophone }">
          <Icon :color="!hasMicrophone ? 'logo-color' : 'secondary'" :icon="!hasMicrophone ? 'mic-off' : 'mic'" class="mr-2"></Icon>
          <span class="default-device-name ellipsis mr-2">{{ microphoneText }}</span>
          <v-spacer></v-spacer>
          <MicrophoneMeter v-show="!hasMicrophone" class="mx-6" :level="audioLevels[selectedMicrophoneId]" />
          <Icon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasMicrophone }"></Icon>
        </div>
      </template>
      <MicrophoneDeviceSelector
        v-if="showMicrophoneMenu"
        v-model="selectedMicrophoneId"
        data-test-id="P_PJ_M_1"
        @onAudioLevelChange="onAudioLevelChanged"
        @change="$emit('microphoneChange', selectedMicrophoneId)"
      />
    </v-menu>

    <v-menu v-if="showSpeakerIcon" v-model="showSpeakerMenu" class="popup-menu" offset-y content-class="rounded" :disabled="!hasSpeaker">
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title py-6 align-center" v-bind="attrs" v-on="on">
          <Icon :color="!hasSpeaker ? 'logo-color' : 'secondary'" :icon="!hasSpeaker ? 'volume-off' : 'volume-1'" class="mr-2"></Icon>
          <span class="default-device-name ellipsis mr-2">{{ speakerText }}</span>
          <v-spacer></v-spacer>
          <Icon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasSpeaker }"></Icon>
        </div>
      </template>
      <SpeakerDeviceSelector v-if="showSpeakerMenu" data-test-id="P_PJ_S_1" v-model="selectedSpeakerId" @change="$emit('speakerChange', selectedSpeakerId)" />
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CameraDeviceSelector from '@/components/Device/CameraDeviceSelector';
import MicrophoneDeviceSelector from '@/components/Device/MicrophoneDeviceSelector';
import MicrophoneMeter from '@/components/Device/MicrophoneMeter';
import SpeakerDeviceSelector from '@/components/Device/SpeakerDeviceSelector';
import { isSafari, isFirefox } from '@/helpers/detect-browser';
import { isMacos } from '@/helpers/os';

export default {
  name: 'DeviceSelector',
  components: { SpeakerDeviceSelector, MicrophoneMeter, MicrophoneDeviceSelector, CameraDeviceSelector },
  data() {
    return {
      selectedCameraId: null,
      selectedMicrophoneId: null,
      selectedSpeakerId: null,
      audioLevels: {},
      showCameraMenu: false,
      showMicrophoneMenu: false,
      showSpeakerMenu: false,
    };
  },
  computed: {
    ...mapGetters('Devices', [
      'getCameras',
      'hasCamera',
      'getMicrophones',
      'hasMicrophone',
      'getSpeakers',
      'hasSpeaker',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
      'getSelectedSpeakerId',
    ]),
    defaultCameraDeviceName() {
      return this.getCameras.find((item) => item.deviceId === this.selectedCameraId)?.label;
    },
    defaultMicrophoneDeviceName() {
      return this.getMicrophones.find((item) => item.deviceId === this.selectedMicrophoneId)?.label;
    },
    defaultSpeakerDeviceName() {
      return this.getSpeakers.find((item) => item.deviceId === this.selectedSpeakerId)?.label;
    },
    cameraText() {
      if (!this.hasCamera) {
        return this.$t('noCameraDetected');
      }
      return this.defaultCameraDeviceName;
    },
    microphoneText() {
      if (!this.hasMicrophone) {
        return this.$t('noMicrophoneDetected');
      }
      return this.defaultMicrophoneDeviceName;
    },
    speakerText() {
      if (!this.hasSpeaker) {
        return this.$t('noSpeakerDetected');
      }
      return this.defaultSpeakerDeviceName;
    },
    showSpeakerIcon() {
      return !isSafari() && !isFirefox();
    },
    showVideoPreview() {
      return !this.$isMobile && !isMacos();
    },
  },
  beforeDestroy() {
    // Destroy video stream
    this.$refs.cameraDeviceSelector?.clearTracks();
  },
  methods: {
    onAudioLevelChanged(v) {
      this.audioLevels = v;
    },
  },
  watch: {
    getSelectedMicrophoneId: {
      handler: function () {
        this.selectedMicrophoneId = this.getSelectedMicrophoneId || null;
      },
      immediate: true,
    },
    getSelectedSpeakerId: {
      handler: function () {
        this.selectedSpeakerId = this.getSelectedSpeakerId || null;
      },
      immediate: true,
    },
    getSelectedCameraId: {
      handler: function () {
        this.selectedCameraId = this.getSelectedCameraId || null;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.device-selector-wrapper {
  .device-item-title {
    border-bottom: 1px solid var(--v-gray-40) !important;
    display: flex;
    font-size: 16px;

    .icon {
      flex: 0;
    }
  }
  .popup-menu {
    .v-menu__content {
      box-shadow: none !important;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .popup-menu {
      .v-menu__content {
        .device-selector {
          overflow: auto;
          height: 200px;
          border-radius: 15px 15px 0 0 !important;
          border: none !important;
          background-color: var(--v-primary-base) !important;
        }
      }
    }
  }
}
</style>
