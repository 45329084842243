var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        "max-width": "700px",
        "sub-title": _vm.$t("viewAndUpdateAllSettings"),
        "confirm-button-text": _vm.$t("save"),
        persistent: true,
        "cancel-button-text": _vm.$t("cancel"),
        title: _vm.$t("settings"),
        "show-actions": "",
        "content-class": "settings-modal",
        "show-back-button": _vm.panel && (_vm.$isTablet || _vm.$isMobile),
        showBottomDivider: "",
      },
      on: { back: _vm.goBack, close: _vm.onModalClose },
    },
    [
      _vm.panel
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-end",
              class: { "mb-5": _vm.$isTablet || _vm.$isMobile },
            },
            [_c("lang-switch")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "settings-modal-wrapper",
          class: {
            "panel-opened": !!_vm.panel,
            "tablet-breakpoint": _vm.$isTablet || _vm.$isMobile,
          },
        },
        [
          _vm.showTitles()
            ? _c(
                "div",
                {
                  staticClass: "settings-titles",
                  attrs: { "selected-index": _vm.panel },
                },
                _vm._l(_vm.settingVariables, function (variable, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "settings-title",
                      class: { active: variable.value === _vm.panel },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "settings-title-content",
                          attrs: { "data-test-id": variable.id },
                          on: {
                            click: function ($event) {
                              return _vm.changeMenu(variable.value)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            variable.value === _vm.panel
                              ? _c("div", { staticClass: "circle" })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "setting-title",
                                class: { "ml-2": variable.value === _vm.panel },
                              },
                              [_vm._v(_vm._s(variable.title))]
                            ),
                          ]),
                          variable.value === _vm.panel ||
                          _vm.$isTablet ||
                          _vm.$isMobile
                            ? _c(
                                "div",
                                { staticClass: "right-icon" },
                                [
                                  _c("icon", {
                                    attrs: { icon: "chevron-right" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "contents",
              class: { "contents--active": !!_vm.panel },
            },
            [
              _c("ProfileSettings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panel === "0",
                    expression: "panel === '0'",
                  },
                ],
                ref: "ProfileSettings",
              }),
              _c("ConferenceSettings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panel === "1",
                    expression: "panel === '1'",
                  },
                ],
                ref: "ConferenceSettings",
              }),
              _c("DeviceSettings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panel === "2",
                    expression: "panel === '2'",
                  },
                ],
                ref: "DeviceSettings",
              }),
              _c("QualitySettings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panel === "3",
                    expression: "panel === '3'",
                  },
                ],
                ref: "QualitySettings",
              }),
              _c("NotificationSettings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.panel === "4",
                    expression: "panel === '4'",
                  },
                ],
                ref: "NotificationSettings",
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }