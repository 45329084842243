var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ar-right-menu pt-2" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "flex-grow-0",
          attrs: { dense: "", color: "transparent", elevation: "0" },
        },
        [
          _c("div", { staticClass: "text-body-1" }, [
            _vm._v(_vm._s(_vm.$t("ar.placementObject"))),
          ]),
        ]
      ),
      _c("div"),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap px-3 align-start" },
        _vm._l(_vm.selectionButtons, function (button) {
          return _c(
            "div",
            {
              key: `${button.value}-${button.actionType}`,
              staticClass: "mb-4",
              staticStyle: { width: "25%" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "menu-item d-flex align-center my-1 mx-auto justify-center flex-column cursor-pointer",
                  class: { selected: _vm.selectedValue === button.value },
                  on: {
                    click: function ($event) {
                      return _vm.onItemClicked(button)
                    },
                  },
                },
                [
                  _c("icon", {
                    style: {
                      opacity: _vm.selectedValue !== button.value ? "0.6" : "1",
                    },
                    attrs: { icon: button.icon, size: "22" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "caption text-center mt-2 mx-auto" }, [
                _vm._v(_vm._s(_vm.$t(button.title))),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.showActionProperties
        ? [
            _c(
              "div",
              { staticClass: "px-4 d-flex" },
              [
                _vm.selectedValue === _vm.ENUMS.AR_OBJECT_TYPES.TEXT
                  ? [
                      _c("text-field", {
                        attrs: { label: _vm.$t("text") },
                        on: { input: _vm.updateActionProperties },
                        model: {
                          value: _vm.textActionTextValue,
                          callback: function ($$v) {
                            _vm.textActionTextValue = $$v
                          },
                          expression: "textActionTextValue",
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _c(
        "v-toolbar",
        {
          staticClass: "flex-grow-0",
          attrs: { dense: "", color: "transparent", elevation: "0" },
        },
        [
          _c("div", { staticClass: "text-body-1" }, [
            _vm._v(_vm._s(_vm.$t("ar.arTools"))),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap px-3 align-start" },
        [
          _vm._l(_vm.arToolsButtons, function (button) {
            return _c(
              "div",
              {
                key: `${button.value}-${button.actionType}`,
                staticClass: "mb-4",
                staticStyle: { width: "25%" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "menu-item d-flex align-center my-1 mx-auto justify-center flex-column cursor-pointer",
                    class: { selected: _vm.selectedValue === button.value },
                    on: {
                      click: function ($event) {
                        return _vm.onItemClicked(button)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      style: {
                        opacity:
                          _vm.selectedValue !== button.value ? "0.6" : "1",
                      },
                      attrs: { icon: button.icon, size: "22" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "caption text-center mt-2 mx-auto" }, [
                  _vm._v(_vm._s(_vm.$t(button.title))),
                ]),
              ]
            )
          }),
          _c("v-select", {
            attrs: {
              items: _vm.getARFileList,
              "item-text": "name",
              "item-value": "fileKey",
              label: "Select",
              "persistent-hint": "",
              "return-object": "",
              "single-line": "",
              clearable: "",
            },
            on: { input: _vm.onArListItemSelected },
            model: {
              value: _vm.selectedArItem,
              callback: function ($$v) {
                _vm.selectedArItem = $$v
              },
              expression: "selectedArItem",
            },
          }),
        ],
        2
      ),
      _c(
        "v-toolbar",
        {
          staticClass: "flex-grow-0",
          attrs: { dense: "", color: "transparent", elevation: "0" },
        },
        [
          _c("div", { staticClass: "text-body-1" }, [
            _vm._v(_vm._s(_vm.$t("ar.placementObjectColor"))),
          ]),
        ]
      ),
      _c(
        "div",
        [
          _c("ColorBar", {
            on: { change: _vm.setColor },
            model: {
              value: _vm.color,
              callback: function ($$v) {
                _vm.color = $$v
              },
              expression: "color",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }