import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import XperMeetLib from 'xpermeet-lib';
import storage from '@/services/storage';
import ENUMS from '@/constants/ENUMS';
import { NOTIFICATION_SOUND_TYPES, NOTIFICATION_SOUNDS } from '@/constants/ENUMS';
const { ROOM_NOTIFICATION_TYPES, ROOM_NOTIFICATION_BADGE_COLORS, NOTIFICATION_TYPES } = ENUMS;

const defaultNotificationSoundSettings = () => {
  return {
    enableSound: true,
    [NOTIFICATION_SOUND_TYPES.USER_JOINED]: true,
    [NOTIFICATION_SOUND_TYPES.WEBINAR_STARTED]: true,
    [NOTIFICATION_SOUND_TYPES.WEBINAR_STOPPED]: true,
    [NOTIFICATION_SOUND_TYPES.CONFERENCE_INCOMING_MESSAGE]: true,
    [NOTIFICATION_SOUND_TYPES.WEBINAR_INCOMING_MESSAGE]: false,
    [NOTIFICATION_SOUND_TYPES.RECORDING]: true,
  };
};

export default {
  namespaced: true,
  state: {
    roomNotifications: [],
    roomNotificationHighlight: false,
    toastNotifications: [],
    hideChatNotifications: false,
    notificationSoundSettings: defaultNotificationSoundSettings(),
  },
  mutations: {
    SET_NOTIFICATION_SOUND_SETTING(state, payload) {
      state.notificationSoundSettings = { ...payload };
      storage.setItem('notificationSoundSettings', state.notificationSoundSettings);
    },
    SET_NOTIFICATION_FROM_LOCAL_STORAGE(state) {
      const notificationSoundSettings = storage.getItem('notificationSoundSettings');
      if (notificationSoundSettings) {
        state.notificationSoundSettings = { ...defaultNotificationSoundSettings(), ...notificationSoundSettings };
      }
    },
    ADD_ROOM_NOTIFICATION(state, payload) {
      state.roomNotifications.unshift(payload);
      state.roomNotificationHighlight = true;
    },
    READ_ROOM_NOTIFICATIONS(state) {
      const notifications = state.roomNotifications.map(notification => {
        return {
          ...notification,
          read: true,
        };
      });
      Vue.set(state, 'roomNotifications', notifications);
    },
    SET_ROOM_NOTIFICATION_HIGHLIGHT(state, payload) {
      state.roomNotificationHighlight = payload;
    },
    REMOVE_ROOM_NOTIFICATION(state, payload) {
      const findIndex = state.roomNotifications.findIndex(n => n.id === payload);
      if (findIndex > -1) {
        Vue.delete(state.roomNotifications, findIndex);
      }
      if (!state.roomNotifications.length || !state.roomNotifications.some(notification => !notification.read)) {
        state.roomNotificationHighlight = false;
      }
    },
    ADD_TOAST_NOTIFICATION(state, payload) {
      state.toastNotifications.push(payload);
    },
    REMOVE_TOAST_NOTIFICATION(state, payload) {
      const findIndex = state.toastNotifications.findIndex(n => n.id === payload);
      if (findIndex > -1) {
        Vue.delete(state.toastNotifications, findIndex);
      }
    },
    SHOW_HIDE_CHAT_NOTIFICATION(state, payload) {
      state.hideChatNotifications = payload;
    },
  },
  actions: {
    onNotificationReceived({ commit, dispatch }, payload) {
      const { type, data } = payload;
      switch (type) {
        case NOTIFICATION_TYPES.CHAT:
          break;
        case NOTIFICATION_TYPES.ROOM:
          if (data.type === ROOM_NOTIFICATION_TYPES.FILE_SHARE) {
            commit('Conference/TOGGLE_RIGHT_MENU', { open: true, tab: 'shared-files' }, { root: true });
          }
          dispatch('showRoomNotification', data);
          break;
        case NOTIFICATION_TYPES.TOAST:
          break;
        default:
          break;
      }
    },
    sendChatNotification({ rootGetters }, payload) {
      const { type, sender, data } = payload;
      XperMeetLib.conference.sendCustomNotification(NOTIFICATION_TYPES.CHAT, type, sender || rootGetters['Conference/getLocalUser']?.id, data || {});
    },
    showRoomNotification({ commit }, payload) {
      const { text, type, date, userId, file } = payload;

      const RoomNotificationObject = {
        id: uuidv4(),
        text: text,
        date: date || Date.now(),
        userId: userId,
        type: type || ROOM_NOTIFICATION_TYPES.TEXT, // ROOM_NOTIFICATION_TYPES,
        file: file || '',
        read: false,
      };

      commit('ADD_ROOM_NOTIFICATION', RoomNotificationObject);
    },
    sendRoomNotification({ }, payload) {
      const { text, type, date, userId, file } = payload;
      const RoomNotificationObject = {
        id: uuidv4(),
        text: text,
        date: date || Date.now(),
        userId: userId,
        type: type || ROOM_NOTIFICATION_TYPES.TEXT, // ROOM_NOTIFICATION_TYPES,
        file: file || '',
        read: false,
      };
      XperMeetLib.conference.sendCustomNotification(NOTIFICATION_TYPES.ROOM, type, userId, RoomNotificationObject);
    },

    removeRoomNotificationByType({ commit, getters }, payload) {
      const { type, userId } = payload;
      const notification = getters.getNotificationByType(type).find(notification => notification.userId === userId);
      if (notification) {
        commit('REMOVE_ROOM_NOTIFICATION', notification.id);
      }
    },
    showToastNotification({ commit }, payload) {
      let body;
      let title;
      let subTitle;
      let config;
      let translate = true;

      if (typeof payload === 'string') {
        body = payload;
      } else {
        body = payload?.body;
        title = payload?.title;
        subTitle = payload?.subTitle;
        translate = 'translate' in payload ? payload.translate : true;
        config = payload?.config;
      }

      const ToastNotificationObject = {
        id: uuidv4(),
        body: body,
        title: title,
        subTitle: subTitle,
        translate: translate,
        config: config || null,
      };
      commit('ADD_TOAST_NOTIFICATION', ToastNotificationObject);

      return ToastNotificationObject;
    },
    playSound({ state, rootState }, payload) {
      if (state.notificationSoundSettings.enableSound && state.notificationSoundSettings[payload] && !rootState.Conference.speakerMuted) {
        let sound = `./sound/${NOTIFICATION_SOUNDS[payload]}`;
        const audio = new Audio(sound);
        audio.volume = 0.5;
        audio.play();
      }
    },
    playSoundWhatEver(_, payload) {
      let sound = `./sound/${NOTIFICATION_SOUNDS[payload]}`;
      const audio = new Audio(sound);
      audio.volume = 0.5;
      audio.play();
    },
    readRoomNotifications({ commit }) {
      commit('SET_ROOM_NOTIFICATION_HIGHLIGHT', false);
      commit('READ_ROOM_NOTIFICATIONS');
    },
  },
  getters: {
    getNotificationByType: state => type => {
      return state.roomNotifications.filter(notification => notification.type === type);
    },
    lastNotificationBadgeColor(state) {
      if (state.roomNotifications.length) {
        return ROOM_NOTIFICATION_BADGE_COLORS[state.roomNotifications[state.roomNotifications.length - 1].type];
      }
      return '';
    },
  },
};
