<template>
  <div class="chat" :class="{ show: true }">
    <div class="d-flex justify-end align-center my-3 mr-2" v-if="showActions">
      <span class="text-body-2 more-action-text">{{ $t('actions') }}</span>
      <menu-button
        data-test-id="chat-action-list-button"
        icon="more-vertical"
        width="24"
        icon-size="16"
        dropdownClass="mt-2"
        use-inner-model
        hide-caret-down
        :dropdown-list="getMoreButtonList"
      ></menu-button>
    </div>
    <div class="body px-6 py-3" :class="{ [bodyClass]: true }" ref="messageBodyElement" :id="bodyId">
      <message
        v-for="message in filteredMessages"
        :key="message.id"
        :show-avatar="showAvatar"
        :message="message"
        :enablePrivateMessage="enablePrivateMessage"
        :currentTime="currentTime"
        @privateMessageClicked="onPrivateMessageClicked"
      />
      <IntersectionObserver @intersect="onIntersect" />
    </div>
    <div class="footer px-6 py-3" :class="{ 'flex-row': !showFileShareButton, 'd-flex': !showFileShareButton, 'align-center': !showFileShareButton }">
      <div class="d-flex flex-row-reverse" v-if="sendPrivateMessageTo">
        <span class="footer--cancel-private-channel mb-1 text-decoration-underline" role="button" @click="$emit('privateMessageCancelled')">
          {{ $t('closeThePrivateChannel') }}
        </span>
      </div>
      <text-field
        v-model="message"
        :placeholder="placeholder"
        autocomplete="message-text"
        :disabled="!chatEnabled"
        v-on:keyup.enter="send"
        hide-details
        class="mb-2"
        @focus="onFocus"
      />
      <div class="d-flex align-center justify-end mb-2">
        <icon-button
          v-if="showFileShareButton"
          :disabled="!chatEnabled"
          icon="attach"
          background-color="transparent"
          circle
          @click="showFileShareModal"
        ></icon-button>
        <v-btn class="ml-2" :disabled="!chatEnabled" color="secondary" fab x-small @click="send">
          <icon icon="send" size="20" style="margin-top: 3px; margin-right: 3px" />
        </v-btn>
      </div>
      <div class="showing-old-messages" :class="{ active: showOldMessageButton }">
        <v-btn class="showing-old-messages--button" small color="secondary" @click="setScrollToBottom(true)">
          {{ $t('youAreViewingOldMessages') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
let chatBody;

import { mapGetters, mapState, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import Message from '@/components/Chat/Message';
import IntersectionObserver from '@/components/Shared/IntersectionObserver';
import Icon from '@/components/Shared/Icon';
import FileShareModal from '@/components/File/Modal/FileShareModal';
import { jsonToCsv } from '@/helpers/export-csv';
import { convertChatNotification } from '@/helpers/convert';

export default {
  name: 'Chat',
  components: {
    Icon,
    Message,
    IntersectionObserver,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    sendPrivateMessageTo: {
      type: Object,
    },
    enablePrivateMessage: {
      type: Boolean,
      default: false,
    },
    chatEnabled: {
      type: Boolean,
    },
    bodyClass: {
      type: String,
      default: 'chat-window',
    },
    bodyId: {
      type: String,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showDownloadMessages: {
      type: Boolean,
      default: false,
    },
    showFileShareButton: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: '',
      privateTo: null,
      currentTime: new Date(),
      menu: false,
    };
  },
  computed: {
    ...mapState('Conference', ['roomName', 'activeRightMenuTab', 'isChatScrolled', 'showChatScreen']),
    ...mapState('Notification', ['hideChatNotifications']),
    ...mapState('Lobby', ['showLobbyPreMeeting']),
    ...mapGetters('Conference', ['getLocalUser', 'getUserById']),
    ...mapGetters('Webinar', { webinarLocalUser: 'getLocalUser', showWebinarChatTab: 'showWebinarChatTab' }),
    placeholder() {
      return this.sendPrivateMessageTo ? `${this.$t('privateMessageTo')}: ${this.sendPrivateMessageTo.name}` : this.$t('yourMessage');
    },
    chatNotificationText() {
      return this.hideChatNotifications ? 'notifications.showNotifications' : 'notifications.hideNotifications';
    },
    filteredMessages() {
      if (this.hideChatNotifications) {
        return this.messages.filter(message => message?.type !== 'notification-message');
      } else {
        return this.messages;
      }
    },
    getMoreButtonList() {
      return [
        {
          notRender: !this.showDownloadMessages,
          label: 'downloadMessages',
          id: 'chat-download-message-list',
          onClick: () => this.downloadMessages(),
        },
        {
          label: this.chatNotificationText,
          id: 'set-chat-notification',
          onClick: () => this.SHOW_HIDE_CHAT_NOTIFICATION(!this.hideChatNotifications),
        },
      ];
    },
    showOldMessageButton() {
      if (!this.chatType) {
        return false;
      }
      return this.isChatScrolled[this.chatType];
    },
    chatType() {
      let type = null;
      if (this.activeRightMenuTab === 'chat') {
        type = 'conference';
      } else if (this.activeRightMenuTab === 'webinar-chat') {
        type = 'webinar';
      }
      return type;
    },
    chatElement() {
      let element = chatBody;
      if (this.chatType === 'webinar') {
        element = document.querySelector('#conference-webinar-chat');
      }
      return element;
    },
    meetChatId() {
      if (this.showLobbyPreMeeting) {
        return 'lobby-chat';
      }
      return this.$route?.name === 'webinar' ? 'webinar-chat' : 'conference-chat';
    },
    webinarHasEvent() {
      return this.activeRightMenuTab === 'webinar-chat' && this.chatElement && !this.chatElement.classList.contains('event-added');
    },
  },
  mounted() {
    this._updateInterval = setInterval(() => {
      this.currentTime = new Date();
    }, 60000);
    chatBody = document.querySelector(`#${this.meetChatId}`);
    if (chatBody) {
      chatBody.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy() {
    clearInterval(this._updateInterval);
  },
  methods: {
    ...mapMutations('Conference', ['SET_IS_CHAT_SCROLLED']),
    ...mapMutations('Notification', ['SHOW_HIDE_CHAT_NOTIFICATION']),
    onFocus() {
      const elem = this.$refs.messageBodyElement;
      const hasScroll = elem.scrollHeight > elem.clientHeight;
      if (hasScroll) {
        if (this.isScrollEnd(elem.scrollTop, elem.clientHeight, elem.scrollHeight)) {
          this.$emit('readMessages');
        }
      } else {
        this.$emit('readMessages');
      }
    },
    onIntersect() {
      this.$emit('scrollEnd');
    },
    send() {
      if (this.message) {
        this.$emit('sendButtonClicked', this.message);
        this.message = '';
      }
    },
    isScrollEnd(scrollTop, clientHeight, scrollHeight) {
      return scrollTop + clientHeight + 1 >= scrollHeight;
    },
    onPrivateMessageClicked(event) {
      this.$emit('privateMessageClicked', event);
    },
    downloadMessages() {
      let messageList = this.filteredMessages.map(message => {
        const Name = message.sender || this.getUserById(message.userId)?.displayName;
        let Message;
        if (message?.type === 'notification-message') {
          Message = convertChatNotification(message);
        } else if (message?.file?.fileName) {
          Message = `${this.$t('fileShared')} (${message.file.fileName})`;
        } else {
          Message = message.body;
        }
        return {
          Name,
          Message,
          Date: dayjs(message.date).format('MM/DD/YYYY HH:mm'),
        };
      });
      let fileName = `${this.roomName}-${this.getCurrentTime()}-Messages.csv`;
      jsonToCsv(messageList, fileName);
      this.menu = false;
    },
    getCurrentTime() {
      return dayjs().format('MM_DD_YYYY_HH_mm');
    },
    showFileShareModal() {
      this.$showModal(FileShareModal);
    },
    handleScroll: debounce(function(event) {
      let { scrollTop } = event.target;
      this.SET_IS_CHAT_SCROLLED({
        type: this.chatType,
        value: !this.isScrollEnd(scrollTop, this.chatElement.clientHeight, this.chatElement.scrollHeight),
      });
    }, 500),
    setScrollToLastMessage() {
      this.$nextTick(() => {
        if (!this.isChatScrolled?.[this.chatType]) {
          this.setScrollToBottom(false);
        }
      });
    },
    setScrollToBottom(withAnimated) {
      if (this.chatType && this.chatElement?.scrollHeight) {
        let scrollToOptions = {
          top: this.chatElement.scrollHeight,
        };
        if (withAnimated) {
          scrollToOptions.behavior = 'smooth';
        }
        this.chatElement.scrollTo(scrollToOptions);
      }
    },
    addWebinarChatEvent() {
      let webinarChatBody = this.chatElement;
      webinarChatBody.addEventListener('scroll', this.handleScroll);
      webinarChatBody.classList.add('event-added');
    },
  },
  watch: {
    messages: {
      handler: function() {
        this.$nextTick(() => {
          if (this.activeRightMenuTab === 'lobby') {
            const lobbyElement = document.querySelector('#conference-lobby-chat');
            lobbyElement.scrollTo({
              top: lobbyElement.scrollHeight,
            });
          } else {
            this.setScrollToLastMessage();
            if (this.webinarHasEvent) {
              this.addWebinarChatEvent();
            }
          }
        });
      },
      immediate: true,
    },
    activeRightMenuTab() {
      this.$nextTick(() => {
        if (this.showChatScreen) {
          if (this.webinarHasEvent) {
            this.addWebinarChatEvent();
          } else if (this.activeRightMenuTab === 'chat') {
            this.setScrollToLastMessage();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  background-color: var(--v-darkblue-base);
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(var(--100vh) - 48px);
  width: 100%;

  &.show {
    margin-right: 0;
  }

  .header {
    background-color: var(--v-darkblue-base);
    text-align: right;
  }
  .more-action-text {
    opacity: 0.7;
  }
  .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
    background-color: var(--v-darkblue-base) !important;
    overflow-y: overlay;
  }

  .footer {
    position: relative;
    &--cancel-private-channel {
      font-size: 10px;
      color: var(--v-gray-base);
    }
  }
  .showing-old-messages {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    transition: all 0.3s ease;
    visibility: hidden;
    &.active {
      opacity: 1;
      top: -30px;
      transition: all 0.3s ease;
      visibility: visible;
    }
    &--button {
      left: -50%;
    }
  }
}
</style>
