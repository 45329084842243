var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "webinar-top-menu d-flex align-center",
      class: { mobile: _vm.$isMobile },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-grow-1" },
        [
          _c("logo", { staticClass: "mr-8" }),
          _vm.webinarTitle
            ? _c("div", { staticClass: "webinar-title" }, [
                _vm._v(" " + _vm._s(_vm.webinarTitle) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-grow-1 d-flex justify-end" },
            [
              !_vm.$isMobile && _vm.isWebinarStarted
                ? _c("participant-counter", {
                    staticClass: "mr-5",
                    attrs: { webinar: "" },
                  })
                : _vm._e(),
              _vm.showActions
                ? _c("menu-button", {
                    attrs: { icon: "settings", useInnerModel: true },
                    on: { click: _vm.openSettingsModal },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }