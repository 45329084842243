var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-selector-wrapper" },
    [
      _c(
        "v-menu",
        {
          staticClass: "popup-menu",
          attrs: {
            "offset-y": "",
            "content-class": "rounded",
            disabled: !_vm.hasCamera,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "device-item-title py-6 align-center",
                          class: { "cursor-default": !_vm.hasCamera },
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("Icon", {
                        staticClass: "mr-2 flex-shrink-0",
                        attrs: {
                          color: !_vm.hasCamera ? "logo-color" : "secondary",
                          icon: !_vm.hasCamera ? "video-off-1" : "video",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "default-device-name ellipsis mr-2" },
                        [_vm._v(_vm._s(_vm.cameraText))]
                      ),
                      _c("v-spacer"),
                      _c("Icon", {
                        class: { "opacity-50": !_vm.hasCamera },
                        attrs: {
                          icon:
                            attrs["aria-expanded"] === "true"
                              ? "chevron-up"
                              : "chevron-down",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showCameraMenu,
            callback: function ($$v) {
              _vm.showCameraMenu = $$v
            },
            expression: "showCameraMenu",
          },
        },
        [
          _vm.showCameraMenu
            ? _c("CameraDeviceSelector", {
                ref: "cameraDeviceSelector",
                attrs: {
                  showPreview: _vm.showVideoPreview,
                  "data-test-id": "P_PJ_C_1",
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("cameraChange", _vm.selectedCameraId)
                  },
                },
                model: {
                  value: _vm.selectedCameraId,
                  callback: function ($$v) {
                    _vm.selectedCameraId = $$v
                  },
                  expression: "selectedCameraId",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          staticClass: "popup-menu",
          attrs: {
            "offset-y": "",
            "content-class": "rounded",
            disabled: !_vm.hasMicrophone,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "device-item-title py-6 align-center",
                          class: { "cursor-default": !_vm.hasMicrophone },
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("Icon", {
                        staticClass: "mr-2",
                        attrs: {
                          color: !_vm.hasMicrophone
                            ? "logo-color"
                            : "secondary",
                          icon: !_vm.hasMicrophone ? "mic-off" : "mic",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "default-device-name ellipsis mr-2" },
                        [_vm._v(_vm._s(_vm.microphoneText))]
                      ),
                      _c("v-spacer"),
                      _c("MicrophoneMeter", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.hasMicrophone,
                            expression: "!hasMicrophone",
                          },
                        ],
                        staticClass: "mx-6",
                        attrs: {
                          level: _vm.audioLevels[_vm.selectedMicrophoneId],
                        },
                      }),
                      _c("Icon", {
                        class: { "opacity-50": !_vm.hasMicrophone },
                        attrs: {
                          icon:
                            attrs["aria-expanded"] === "true"
                              ? "chevron-up"
                              : "chevron-down",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMicrophoneMenu,
            callback: function ($$v) {
              _vm.showMicrophoneMenu = $$v
            },
            expression: "showMicrophoneMenu",
          },
        },
        [
          _vm.showMicrophoneMenu
            ? _c("MicrophoneDeviceSelector", {
                attrs: { "data-test-id": "P_PJ_M_1" },
                on: {
                  onAudioLevelChange: _vm.onAudioLevelChanged,
                  change: function ($event) {
                    return _vm.$emit(
                      "microphoneChange",
                      _vm.selectedMicrophoneId
                    )
                  },
                },
                model: {
                  value: _vm.selectedMicrophoneId,
                  callback: function ($$v) {
                    _vm.selectedMicrophoneId = $$v
                  },
                  expression: "selectedMicrophoneId",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showSpeakerIcon
        ? _c(
            "v-menu",
            {
              staticClass: "popup-menu",
              attrs: {
                "offset-y": "",
                "content-class": "rounded",
                disabled: !_vm.hasSpeaker,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass:
                                  "device-item-title py-6 align-center",
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("Icon", {
                              staticClass: "mr-2",
                              attrs: {
                                color: !_vm.hasSpeaker
                                  ? "logo-color"
                                  : "secondary",
                                icon: !_vm.hasSpeaker
                                  ? "volume-off"
                                  : "volume-1",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "default-device-name ellipsis mr-2",
                              },
                              [_vm._v(_vm._s(_vm.speakerText))]
                            ),
                            _c("v-spacer"),
                            _c("Icon", {
                              class: { "opacity-50": !_vm.hasSpeaker },
                              attrs: {
                                icon:
                                  attrs["aria-expanded"] === "true"
                                    ? "chevron-up"
                                    : "chevron-down",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2896583454
              ),
              model: {
                value: _vm.showSpeakerMenu,
                callback: function ($$v) {
                  _vm.showSpeakerMenu = $$v
                },
                expression: "showSpeakerMenu",
              },
            },
            [
              _vm.showSpeakerMenu
                ? _c("SpeakerDeviceSelector", {
                    attrs: { "data-test-id": "P_PJ_S_1" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("speakerChange", _vm.selectedSpeakerId)
                      },
                    },
                    model: {
                      value: _vm.selectedSpeakerId,
                      callback: function ($$v) {
                        _vm.selectedSpeakerId = $$v
                      },
                      expression: "selectedSpeakerId",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }