import { Strophe } from "strophe.js";
import Conference from "./Conference";
import { consoleError, consoleLog } from "../utils/logger";
import { ERROR_WITH_CODES } from "../main";
import JitsiMeetJS from "../JitsiMeetLib";
import XMPPEvents from "../constants/XMPPEvents";
import EventEmitter from "../utils/EventEmitter";

const eventEmitter = new EventEmitter();

class Lobby {
  constructor() {
    this.lobbyWhiteList = [];
  }

  handleEvents() {
    // Lobby events
    Conference.room.on(JitsiMeetJS.events.conference.MEMBERS_ONLY_CHANGED, this.onMembersOnlyChanged.bind(this));
    Conference.room.on(JitsiMeetJS.events.conference.LOBBY_USER_JOINED, this.onLobbyUserJoined.bind(this));
    Conference.room.on(JitsiMeetJS.events.conference.LOBBY_USER_UPDATED, this.onLobbyUserUpdated.bind(this));
    Conference.room.on(JitsiMeetJS.events.conference.LOBBY_USER_LEFT, this.onLobbyUserLeft.bind(this));
    Conference.room.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onUserRoleChanged.bind(this));
  }

  handleLobbyXMPPEvents() {
    if (!Conference.room.room.lobby.lobbyRoom) {
      return;
    }
    this.removeLobbyXMPPEvents();

    Conference.room.room.lobby.lobbyRoom.addEventListener(XMPPEvents.MESSAGE_RECEIVED, this.onMessageReceived);
    Conference.room.room.lobby.lobbyRoom.addEventListener(XMPPEvents.MUC_JOINED, this.onMucJoined);
    Conference.room.room.lobby.lobbyRoom.addEventListener(XMPPEvents.MUC_LEFT, this.onMucLeft);
    Conference.room.room.lobby.lobbyRoom.addEventListener(XMPPEvents.MUC_MEMBER_JOINED, this.onMemberJoined);
    Conference.room.room.lobby.lobbyRoom.addEventListener(XMPPEvents.MUC_MEMBER_LEFT, this.onMemberLeft);
  }

  removeLobbyXMPPEvents() {
    if (!Conference.room.room.lobby.lobbyRoom) {
      return;
    }

    Conference.room.room.lobby.lobbyRoom.removeEventListener(XMPPEvents.MESSAGE_RECEIVED, this.onMessageReceived);
    Conference.room.room.lobby.lobbyRoom.removeEventListener(XMPPEvents.MUC_JOINED, this.onMucJoined);
    Conference.room.room.lobby.lobbyRoom.removeEventListener(XMPPEvents.MUC_LEFT, this.onMucLeft);
    Conference.room.room.lobby.lobbyRoom.removeEventListener(XMPPEvents.MUC_MEMBER_JOINED, this.onMemberJoined);
    Conference.room.room.lobby.lobbyRoom.removeEventListener(XMPPEvents.MUC_MEMBER_LEFT, this.onMemberLeft);
  }

  onMemberJoined(...args) {
    const [from, nick, role, isHiddenDomain, statsID, status, identity, botType, jid] = args; // eslint-disable-line
    const id = Strophe.getResourceFromJid(from);
    eventEmitter.emit("LobbyMucMemberJoined", { id, name: nick, role });
    consoleLog("onMemberJoined", args); // eslint-disable-line
  }

  onMemberLeft(...args) {
    const [from, nick, role, isHiddenDomain, statsID, status, identity, botType, jid] = args; // eslint-disable-line
    const id = Strophe.getResourceFromJid(from);
    eventEmitter.emit("LobbyMucMemberLeft", { id, name: nick, role });
    consoleLog("onMemberLeft", args); // eslint-disable-line
  }

  onMucJoined(...args) {
    const myRoomJid = Conference.room.room.lobby.lobbyRoom.myroomjid;
    const id = Strophe.getResourceFromJid(myRoomJid);
    eventEmitter.emit("LobbyMucJoined", { id });
    consoleLog("onMucJoined", args); // eslint-disable-line
  }

  onMucLeft(...args) {
    const myRoomJid = Conference.room.room.lobby.lobbyRoom.myroomjid;
    const id = Strophe.getResourceFromJid(myRoomJid);
    eventEmitter.emit("LobbyMucLeft", { id });
    consoleLog("onMucLeft", args); // eslint-disable-line
  }

  onMessageReceived(...args) {
    const [jid, message, myJid] = args; // eslint-disable-line
    const userId = Strophe.getResourceFromJid(jid);
    consoleLog("onMessageReceived", args); // eslint-disable-line
    eventEmitter.emit("MessageReceived", [userId, message]);
  }

  onPrivateMessageReceived(...args) {
    consoleLog("onPrivateMessageReceived", args); // eslint-disable-line
  }

  sendMessage(message) {
    consoleLog("sendMessage", message); // eslint-disable-line
    Conference.room.room.lobby.lobbyRoom.sendMessage(message, "body");
  }

  onMembersOnlyChanged(enabled) {
    eventEmitter.emit("LobbyEnabled", enabled);

    if (enabled) {
      this.handleLobbyXMPPEvents();
    } else {
      this.removeLobbyXMPPEvents();
    }
  }

  onUserRoleChanged(userId, role) {
    if (Conference.localUser.getUser.id === userId) {
      if (role === "moderator") {
        this.handleLobbyXMPPEvents();
      } else {
        this.removeLobbyXMPPEvents();
      }
    }
  }

  onLobbyUserJoined(id, name) {
    if (!name) {
      return;
    }
    setTimeout(() => {
      if (this.checkMemberIsExists(id)) {
        const whiteListExisting = this.lobbyWhiteList.find((item) => item.id === id);
        if (whiteListExisting) {
          Conference.room.lobbyApproveAccess(id);
        }
        eventEmitter.emit("LobbyUserJoined", { id, name });
        consoleLog("LOBBY_USER_JOINED", id, name);
      }
    }, 2000);
  }

  onLobbyUserUpdated(id, participant) {
    consoleLog("LOBBY_USER_UPDATED", id, participant);
    eventEmitter.emit("LobbyUserUpdated", { id, participant });
  }

  onLobbyUserLeft(id) {
    eventEmitter.emit("LobbyUserLeft", { id });
  }

  addUserToWhiteList(user) {
    this.lobbyWhiteList.push(user);
  }

  getUserFromWhiteList(viewerId) {
    return this.lobbyWhiteList.find((item) => item.viewerId === viewerId);
  }

  enable() {
    if (!this.isSupported()) {
      consoleError(ERROR_WITH_CODES.LOBBY_NOT_SUPPORTED);
      return;
    }
    consoleLog("Lobby enabling");
    Conference.room.enableLobby();
  }

  disable() {
    if (!this.isSupported()) {
      consoleError(ERROR_WITH_CODES.LOBBY_NOT_SUPPORTED);
      return;
    }
    Conference.room.disableLobby();
  }

  isSupported() {
    return Conference.room.isLobbySupported();
  }

  approveAccess(id) {
    Conference.room.lobbyApproveAccess(id);
  }

  denyAccess(id) {
    Conference.room.lobbyDenyAccess(id);
  }

  join(name, email) {
    return new Promise((resolve, reject) => {
      Conference.room
        .joinLobby(name, email)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          eventEmitter.emit("LobbyJoinError", e);
          this.removeLobbyXMPPEvents();
          reject(e);
        });
      this.handleLobbyXMPPEvents();
    });
  }

  checkMemberIsExists(userId) {
    const { members = {} } = Conference?.room?.room?.lobby?.lobbyRoom || {};
    const jids = Object.keys(members);
    return jids.some((jid) => Strophe.getResourceFromJid(jid) === userId);
  }

  emit(eventType, callbackFn) {
    eventEmitter.emit(eventType, callbackFn);
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }
}

export default new Lobby();
