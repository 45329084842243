var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "notification-default d-flex w-full justify-space-between align-center",
    },
    [
      _c("div", { staticClass: "d-flex flex-column w-full" }, [
        _c("span", { staticClass: "user-name" }, [
          _vm._v(_vm._s(_vm.user.displayName)),
        ]),
        _c(
          "div",
          { staticClass: "content d-flex" },
          [
            _c("Icon", {
              attrs: {
                icon: _vm.notificationIcon,
                color: _vm.notificationIconColor,
                size: 20,
              },
            }),
            _c(
              "span",
              {
                staticClass: "action-text",
                class: { "ml-2": _vm.notificationIcon },
              },
              [_vm._v(_vm._s(_vm.$t(`notifications.${_vm.notification.text}`)))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }