var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("Logo", {
        staticClass: "px-5 py-4 px-md-15 py-md-8",
        attrs: { "hide-on-mobile": false },
      }),
      _c(
        "Modal",
        {
          attrs: {
            persistent: "",
            title: _vm.$t("kicked"),
            "sub-title": _vm.$t("kickedDescription"),
          },
          on: {
            close: function ($event) {
              return _vm.$router.push("/")
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-center pt-2 mb-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("goToHome")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }