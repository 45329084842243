<template>
  <div class="d-flex px-5 py-4 px-md-15 py-md-8">
    <Logo :hide-on-mobile="false" />

    <Modal persistent :title="$t('conferenceEnded')" :sub-title="$t('conferenceEndedDescription')">
      <div class="d-flex justify-center pt-2 mb-3">
        <v-btn color="secondary" @click="$router.push('/')">{{ $t('goToHome') }}</v-btn>
      </div>
    </Modal>
  </div>
</template>

<script>
import Logo from '@/components/Shared/Logo';

export default {
  name: 'ConferenceEnded',
  components: { Logo },
};
</script>
