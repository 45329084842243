<template>
  <div class="toast" :style="computedStyle" :class="[`toast--${config.type}`]" @click="onClick">
    <div v-if="icon" class="toast__icon ml-1 mr-4">
      <Icon :icon="icon" size="20" class="mt-2" />
    </div>
    <div class="flex-grow-1 d-flex justify-center flex-column">
      <div v-if="toast.title" class="toast__title">
        {{ toast.translate ? $t(toast.title) : toast.title }}
      </div>
      <div v-if="toast.subTitle" class="toast__sub-title">
        {{ toast.translate ? $t(toast.subTitle) : toast.subTitle }}
      </div>
      <div v-if="toast.body" class="toast__body">
        <template v-if="config.type === TOAST_TYPE.COPY">
          <ToastCopy :toast="toast" />
        </template>
        <template v-else>
          <div>{{ toast.translate ? $t(toast.body) : toast.body }}</div>
          <div v-if="hasButton" class="d-flex mt-2">
            <button
              v-for="(button, key) in buttons"
              :key="key"
              :class="{ 'prompt-button--bold': button.bold }"
              class="prompt-button mx-1"
              @click.stop="onButtonClick(button)"
            >
              {{ toast.translate ? $t(button.text) : button.text }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { TOAST_DEFAULT_CONFIG, TOAST_TYPE } from '@/constants/toast';
import ToastCopy from '@/components/Notifications/Toast/ToastCopy';

const typeConfig = {
  [TOAST_TYPE.INFO]: { icon: 'info' },
  [TOAST_TYPE.SUCCESS]: { icon: 'check-1' },
  [TOAST_TYPE.ERROR]: { icon: 'alert-circle-1' },
  [TOAST_TYPE.WARNING]: { icon: 'info' },
  [TOAST_TYPE.COPY]: { icon: 'info' },
};

export default {
  name: 'Toast',
  components: { ToastCopy },
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TOAST_TYPE,
    };
  },
  computed: {
    config() {
      return { ...TOAST_DEFAULT_CONFIG, ...this.toast?.config };
    },
    hasButton() {
      return this.config.buttons && this.config.buttons.length;
    },
    buttons() {
      return this.config.buttons;
    },
    icon() {
      return this.config.icon || typeConfig[this.config.type].icon;
    },
    computedStyle() {
      return {
        cursor: this.config?.closeOnClick ? 'pointer' : 'default',
        'background-color': this.config?.color || undefined,
      };
    },
  },
  mounted() {
    let { timeout } = TOAST_DEFAULT_CONFIG;
    if (Number.isInteger(this.config.timeout) && this.config.timeout > -1) {
      timeout = this.config?.timeout;
    }

    if (timeout > 0) {
      setTimeout(() => {
        this.REMOVE_TOAST_NOTIFICATION(this.toast.id);
      }, timeout);
    }
  },
  methods: {
    ...mapMutations('Notification', ['REMOVE_TOAST_NOTIFICATION']),
    onClick() {
      if (this.config.closeOnClick) {
        this.REMOVE_TOAST_NOTIFICATION(this.toast.id);
      }
    },
    onButtonClick(button) {
      if (button.action) {
        button.action();
      }

      if (button.close) {
        this.REMOVE_TOAST_NOTIFICATION(this.toast.id);
      }
    },
  },
};
</script>

<style lang="scss">
.toast {
  --promp-button-bg-color: var(--v-popup-main-base);
  --promp-button-border-color: var(--v-secondary-base);
  --promp-button-dark-bg-color: var(--v-popup-main-darken3);

  &--success {
    --promp-button-bg-color: var(--v-success-darken2);
    --promp-button-border-color: var(--v-success-darken1);
    --promp-button-dark-bg-color: var(--v-success-darken3);
  }
  &--error {
    --promp-button-bg-color: var(--v-error-base);
    --promp-button-border-color: var(--v-error-darken1);
    --promp-button-dark-bg-color: var(--v-error-darken3);
  }
  &--warning {
    --promp-button-bg-color: var(--v-warning-darken1);
    --promp-button-border-color: var(--v-warning-base);
    --promp-button-dark-bg-color: var(--v-warning-darken3);
  }
  &--info {
    --promp-button-bg-color: var(--v-popup-main-base);
    --promp-button-border-color: var(--v-secondary-base);
    --promp-button-dark-bg-color: var(--v-popup-main-darken3);
  }

  display: flex;
  height: 100%;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  pointer-events: auto;
  transition: ease all 0.5s;
  padding: 8px 12px;
  background-color: var(--promp-button-bg-color);

  &__title {
    font-size: 13px;
    font-weight: 500;
  }

  &__sub-title {
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-height: 3em;
  }
  &__body {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;

    .prompt-button {
      background-color: var(--promp-button-bg-color);
      border: solid 1px var(--promp-button-border-color);
      padding: 0 8px;
      border-radius: 8px;
      box-sizing: border-box;
      min-width: 72px;
      font-size: 11px;

      &:hover {
        opacity: 0.8;
      }

      &--bold {
        background-color: var(--promp-button-dark-bg-color);
        border: none;
      }
    }
  }
}
</style>
