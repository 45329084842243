<template>
  <div class="file">
    <icon icon="file" size="20" />
    <div class="flex-grow-1 pl-3">
      {{ truncate(message.file.fileName) }}
    </div>
    <div>
      <v-btn icon @click="download(message.file)" small :loading="!!fileStatus">
        <icon icon="download-1" size="20" />
      </v-btn>
      <div v-if="fileStatus" class="file-status">%{{ fileStatus }}</div>
    </div>
  </div>
</template>

<script>
import Xperdrive from 'xperdrive-lib';
import truncate from 'lodash.truncate';

export default {
  name: 'MessageFile',
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {
      fileStatus: null,
    };
  },
  methods: {
    truncate,
    download(file) {
      Xperdrive.FileDownloadAnonymous(file, progress => {
        this.fileStatus = Math.floor((progress.loaded * 100) / progress.total);

        if (this.fileStatus === 100) {
          setTimeout(() => {
            this.fileStatus = null;
          }, 1000);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.file {
  display: flex;
  align-items: center;
  min-width: 185px;
  color: var(--v-white-base);

  .file-status {
    bottom: 3px;
    font-size: 10px;
    text-align: right;
    font-weight: normal;
  }
}
</style>
