export const PREDEFINED_BACKGROUNDS = [
  '/img/background/background-default.png',
  '/img/background/lightthemebackground.jpg',
  '/img/background/bg1.jpg',
  '/img/background/bg2.jpg',
  '/img/background/bg5.jpg',
  '/img/background/bg7.jpg',
  '/img/background/bg8.jpg',
  '/img/background/bg9.jpg',
  '/img/background/bg11.jpg',
  '/img/background/bg13.jpg',
  '/img/background/bg14.jpg',
  '/img/background/background-3.jpg',
];
