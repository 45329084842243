<template>
  <div class="background-selector" :class="{ 'hide-background': hideBackground, disabled: disabled }">
    <div class="overlay" v-if="disabled"></div>
    <div v-if="showTitle" style="font-size: 16px" class="my-3">
      {{ $t('backgroundSelection') }}
      <span v-if="disabled" class="text-caption">{{ ' ' + `(${$t('lockedByModerator')})` }}</span>
    </div>

    <div class="background-list">
      <div
        class="background-box"
        v-for="(bg, index) in PREDEFINED_BACKGROUNDS"
        :key="index"
        :style="{
          backgroundColor: getBackground(bg),
          backgroundImage: getBackground(bg),
        }"
        @click.stop.prevent="setBackground(bg)"
      >
        <div v-if="background === bg" class="selected-box d-flex justify-center align-center">
          <icon icon="check" />
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <div class="mb-3 mt-5 text-body-2">
        {{ $t('selectBackgroundFromComputer.preLink') }}
        <Link @click="fileSelect" :text="$t('selectBackgroundFromComputer.selectFile')" />
        {{ $t('selectBackgroundFromComputer.afterLink') }}

        <input v-show="false" type="file" id="background-file" ref="background-file" accept="image/*" @change="onFileChange" />
      </div>
      <div class="background-list">
        <div
          class="user-background-box"
          v-for="(bg, index) in backgrounds"
          :key="index"
          :style="{
            backgroundColor: getBackground(bg),
            backgroundImage: getBackground(bg),
          }"
          @click.stop.prevent="setBackground(bg)"
        >
          <div v-if="background === bg" class="selected-box d-flex justify-center align-center">
            <icon icon="check" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-progress-circular class="my-2" indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="pt-3" v-if="fileSizeError">
      <v-alert dense outlined type="error" class="pt-1 pb-1">
        <span class="text-body-2">{{ $t('fileSizeError_customSize', { size: '10 MB' }) }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { PREDEFINED_BACKGROUNDS } from '@/constants/background';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'BackgroundSelector',
  props: {
    hideBackground: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    updateStorageOnChange: {
      type: Boolean,
    },
  },
  data() {
    return {
      PREDEFINED_BACKGROUNDS: PREDEFINED_BACKGROUNDS,
      backgrounds: [],
      fileSizeError: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['background', 'theme', 'radius']),
    ...mapGetters(['getThemeIcon', 'getRadiusIcon', 'getThemeLang', 'getRadiusLang', 'getCustomerBackground']),
  },
  created() {
    const userBackgrounds = localStorage.getItem('backgrounds') ? JSON.parse(localStorage.getItem('backgrounds')) : [];
    this.backgrounds = [...new Set(userBackgrounds)];
    this.updateLocalStorage();
  },
  methods: {
    ...mapMutations(['SET_THEME', 'SET_BACKGROUND', 'SET_RADIUS']),
    ...mapActions('Conference', ['imageUploadAnonymous']),
    ...mapActions('Notification', ['showToastNotification']),
    setBackground(background) {
      if (!this.disabled && !this.loading) {
        this.SET_BACKGROUND(background);
      }
    },
    getBackground(background) {
      if (!background && this.loading) {
        return '';
      } else if (background.startsWith('data:') || background.startsWith('/')) {
        return `url("${background}")`;
      } else if (background.startsWith('http:') || background.startsWith('https:')) {
        return `url("${background}")`;
      } else {
        return background;
      }
    },
    fileSelect() {
      this.$refs['background-file'].click();
      this.fileSizeError = false;
    },
    setLoading(val) {
      this.loading = val;
      this.$emit('setLoading', val);
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 10485760) {
          this.fileSizeError = true;
          return;
        }
        this.setLoading(true);
        try {
          const formData = new FormData();
          formData.append('formFile', file);
          const res = await this.imageUploadAnonymous({ params: { width: 1920, height: 1080 }, errorText: 'backgroundImageUploadError', payload: formData });
          this.setLoading(false);

          if (res) {
            this.setBackground(res);
            this.backgrounds = [res];
            this.updateLocalStorage();
          } else {
            this.showToastNotification({ body: 'backgroundImageUploadError', config: { type: TOAST_TYPE.ERROR } });
          }
        } catch (error) {
          this.setLoading(false);
          this.showToastNotification({ body: 'backgroundImageUploadError', config: { type: TOAST_TYPE.ERROR } });
        }
      }
    },
    updateLocalStorage() {
      this.backgrounds = [...new Set([...this.backgrounds])].filter(b => b).slice(0, 22);
      const backgrounds = this.backgrounds.filter(bg => !PREDEFINED_BACKGROUNDS.includes(bg) && bg !== this.getCustomerBackground);
      localStorage.setItem('backgrounds', JSON.stringify(backgrounds));
    },
  },
};
</script>

<style scoped lang="scss">
.background-selector {
  padding: 5px;
  position: relative;

  .overlay {
    background: #151515;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 8px;
  }
  .background-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;

    .background-box,
    .user-background-box {
      position: relative;
      height: 60px;
      border-radius: 8px;
      border: 1px solid var(--v-primary-base);
      cursor: pointer;
      background-size: 100% 100%;
      &.loading {
        opacity: 0.3;
      }
      .selected-box {
        position: absolute;
        border-radius: 8px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    @media (max-width: $md-breakpoint) {
      grid-template-columns: repeat(3, 1fr);

      .background-box,
      .user-background-box {
        min-width: 60px;
        height: 50px;
      }
    }
  }
}
</style>
