var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.systemErrorCode
    ? _c(
        "Modal",
        {
          attrs: {
            persistent: "",
            "show-close-button": false,
            title: _vm.$t(_vm.title),
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.isDeviceError
                ? [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("mediaDevicesUnreachable"))),
                    ]),
                    _vm.isIos
                      ? _c("p", [_vm._v(_vm._s(_vm.$t("youCanUseSafari")))])
                      : _vm._e(),
                    _vm.isMobile
                      ? _c("p", [_vm._v(_vm._s(_vm.$t("dontHaveApp")))])
                      : _vm._e(),
                    _vm.isMobile
                      ? _c("a", { attrs: { href: _vm.appLink } }, [
                          _vm._v(_vm._s(_vm.$t("downloadTheApp"))),
                        ])
                      : _vm._e(),
                  ]
                : [_c("p", [_vm._v(_vm._s(_vm.$t(this.message)))])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center pt-4 mb-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pl-10 pr-10",
                  attrs: { color: "secondary", block: _vm.$isMobile, to: "/" },
                },
                [_vm._v(_vm._s(_vm.$t("goToHome")))]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }