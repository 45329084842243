<template>
  <div class="color-bar">
    <div
      v-for="color in colors"
      :key="color"
      class="color-item"
      :class="{ selected: color === selectedColor, bgColor: color === '' }"
      :style="{ backgroundColor: color }"
      @click="selectColor(color)"
    >
      <icon v-if="color === selectedColor" icon="check" :color="color === '#FFFFFF' ? '#000000' : '#FFFFFF'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorBar',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {
      colors: [
        '',
        '#301d52',
        '#7F7F7F',
        '#880015',
        '#ED1C24',
        // '#FF7F27',
        '#FFF200',
        '#22B14C',
        '#00A2E8',
        '#3F48CC',
        '#A349A4',
        '#FFFFFF',
        '#C3C3C3',
        '#8d5c61',
        '#FFAEC9',
        // '#FFC90E',
        '#EFE4B0',
        '#B5E61D',
        '#99D9EA',
        '#7092BE',
        '#C8BFE7',
      ],
      selectedColor: null,
    };
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      this.$emit('change', color);
    },
  },
  watch: {
    value: {
      handler: function() {
        if (this.value !== this.selectedColor) {
          this.selectedColor = this.value;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.color-bar {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 8px;
  padding: 1rem;

  .color-item {
    border-radius: 8px;

    &.selected {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.bgColor {
      background-image: url('~@/assets/images/checkerboard.png');
      background-position: center;
      background-size: cover;
      background-color: rgb(189 189 189 / 45%);
    }
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}
</style>
