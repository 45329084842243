<template>
  <div class="pre-join px-5 py-4 px-md-15 py-lg-0 py-xl-8 py-md-8">
    <div class="d-flex justify-space-between align-center">
      <logo class="mt-6"></logo>
      <lang-switch class="lang-switch" />
    </div>
    <div class="pre-join--content">
      <div class="description text-center mt-sm-1 mt-md-7 mb-sm-2 mb-md-10 mb-lg-14">
        <div class="title mb-2">{{ $t('preview') }}</div>
        <div class="text">
          {{ $t('prejoin.userNameWarning') }}
          <br />
          {{ $t('prejoin.description') }}
        </div>
      </div>

      <div v-if="!errorCode">
        <v-row style="margin: 0px">
          <v-col class="prejoin-avatar-container" cols="12" sm="12" md="7">
            <div class="prejoin-avatar-container--video d-flex justify-center align-center" :class="{ avatar: !isCameraActive || !hasCameraAndAllowed }">
              <video class="pre-join-video" :class="{ hide: !isCameraActive || !hasCameraAndAllowed }" playsinline muted id="local-video" style="width: 100%" />
              <div class="prejoin-avatar-container--video--close d-flex justify-center align-center" :class="{ hide: isCameraActive && hasCameraAndAllowed }">
                <Avatar v-if="avatar" :image="avatar" :avatar-size="150" :display-name="displayName" />
                <Icon v-else icon="user" size="50" />
              </div>
              <div class="d-flex justify-center prejoin-avatar-container--video--actions">
                <menu-button
                  :click-disabled="!hasMicrophonAndAllowed"
                  :disabled="!hasMicrophonAndAllowed"
                  class="mr-2"
                  :icon="micIcon"
                  icon-size="24"
                  :color="micIconColor"
                  @click="toggleMicrophone"
                />
                <menu-button
                  :click-disabled="!hasCameraAndAllowed"
                  :disabled="!hasCameraAndAllowed"
                  :icon="videoIcon"
                  :color="videoIconColor"
                  @click="isCameraActive = !isCameraActive"
                />
              </div>
            </div>
            <div class="hints-and-keypoints py-4">
              <icon icon="lamp" />
              <a href="javascript:0;" @click="openHintsAndKeypointsModal">{{ $t('hintsAndKeypoints') }}</a>
            </div>
            <v-alert v-if="errorText" :icon="false" outlined dense type="warning" class="mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2">
              <Icon icon="info-circle" />
              <span class="ml-2 text-body-2">{{ errorText }}</span>
            </v-alert>
            <v-alert v-else-if="checkVideoAndAudioStatus" :icon="false" outlined dense type="warning" class="mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2">
              <Icon icon="info-circle" />
              <span class="ml-2 text-body-2">{{ checkVideoAndAudioStatus }}</span>
            </v-alert>
          </v-col>
          <v-col cols="12" sm="12" md="5" class="pt-0" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
            <div class="ml-4 mr-1 join-conference">
              <div class="d-flex align-center">
                <avatar-uploader class="avatar-selector-prejoin mr-4 align-center" :localDisplayName="displayName" @change="avatar = $event"></avatar-uploader>
                <TextField
                  data-test-id="P_PJ_DN"
                  autofocus
                  v-model="displayName"
                  :label="$t('participantName')"
                  :error-messages="showError ? [$t('requiredField')] : null"
                  :disabled="displayNameDisabled"
                  @blur="onDisplayNameBlur"
                  @keyup="onDisplayNameKeyUp"
                  class="set-name mt-5"
                ></TextField>
              </div>
              <v-tabs class="prejoin-tabs-header" v-if="isCreate" v-model="tab" right background-color="transparent" grow>
                <v-tab v-for="item in items" :key="item" :ripple="false">
                  <span class="prejoin-tab-titles">{{ item }}</span>
                </v-tab>
              </v-tabs>

              <v-tabs-items touchless :value="tab" class="prejoin-tabs" :class="{ 'join-tabs': !isCreate }">
                <v-tab-item :value="0" data-test-id="P-PJ-T-AAV">
                  <DeviceSelector ref="deviceSelector" @cameraChange="handleCameraChange" />
                </v-tab-item>
                <v-tab-item :value="1" v-if="isCreate" data-test-id="P-PJ-T-AS">
                  <div class="prejoin-advenced-settings mt-1">
                    <StartMutedPolicySettings
                      :size-prop="{ middle: '12', small: '12' }"
                      :remoteCameraControlSupported="remoteCameraControlSupported"
                      :initial-data="{ ...settings }"
                      @change="v => (settings = v)"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>

              <div class="py-4 d-flex justify-end">
                <v-btn :block="$isMobile" data-test-id="P_PJ_JB" class="pa-5" color="secondary" style="width: 50%" @click="join">{{ $t('join') }}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="false">
          <v-col>
            <MediaDevicesInitiator
              v-if="!showMobileJoinModal"
              ref="mediaDeviceInitiator"
              :camera-device-id="selectedCameraId"
              @initiated="mediaDeviceInitiated"
            />
          </v-col>
        </v-row>
      </div>
      <template v-else-if="errorCode">
        <SystemErrorModal :errorCode="errorCode" />
      </template>
      <JoinMeetingFromMobileModal v-if="showMobileJoinModal" :room-name="getRoomName" @close="closeJoinFromMobileModal" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import LangSwitch from '@/components/Shared/LangSwitch';
import MediaDevicesInitiator from '@/components/Device/MediaDevicesInitiator';
import SystemErrorModal from '@/components/Shared/Modal/SystemErrorModal';
import JoinMeetingFromMobileModal from '@/components/Shared/Modal/JoinMeetingFromMobileModal';
import HintsAndKeypointsModal from '@/components/HintsAndKeypoints/Modal/HintsAndKeypoints';
import AvatarUploader from '@/components/AvatarUpload/AvatarUploader';
import { mobileAndTabletCheck } from '@/helpers/detect-browser';
import { MEDIA_DEVICE_STATE } from '@/constants/devices';
import ENUMS from '@/constants/ENUMS';
import storage from '@/services/storage';
import DeviceSelector from '@/components/Device/DeviceSelector';
import StartMutedPolicySettings from '@/components/Settings/StartMutedPolicySettings';
import { isMobile } from '@/helpers/os';
import { clearSharedFileKeys } from '@/helpers/file';

export default {
  name: 'PreJoin',
  components: {
    DeviceSelector,
    AvatarUploader,
    MediaDevicesInitiator,
    LangSwitch,
    SystemErrorModal,
    StartMutedPolicySettings,
    JoinMeetingFromMobileModal,
  },
  data() {
    const { AUDIO_UNMUTED, VIDEO_UNMUTED, START_MUTED_POLICY, START_VIDEO_MUTED_POLICY, ALLOW_ADMIN_START_VIDEO } = ENUMS.RoomConfigEnums;
    const defaultMutedPolicy = window.customerConfig?.system?.conference?.defaultSettings?.mutedPolicy || {};
    return {
      disableMobileJoinPage: false,
      isCameraActive: true,
      isMicrophoneActive: true,
      isSpeakerActive: true,
      selectedSpeakerId: null,
      displayName: '',
      timeouts: [],
      showError: false,
      errorCode: null,
      avatar: '',
      displayNameDisabled: true,
      selectedCameraId: storage.getItem('selected_videoinput') || '',
      settings: {
        [START_MUTED_POLICY]: defaultMutedPolicy?.[START_MUTED_POLICY] || AUDIO_UNMUTED,
        [START_VIDEO_MUTED_POLICY]: defaultMutedPolicy?.[START_VIDEO_MUTED_POLICY] || VIDEO_UNMUTED,
        [ALLOW_ADMIN_START_VIDEO]: defaultMutedPolicy?.[ALLOW_ADMIN_START_VIDEO] || undefined,
      },
      tab: 0,
    };
  },
  computed: {
    ...mapGetters('Devices', [
      'getMicrophones',
      'getCameras',
      'getSpeakers',
      'hasCamera',
      'hasMicrophone',
      'hasSpeaker',
      'cameraAllowed',
      'microphoneAllowed',
      'getSelectedSpeakerId',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
    ]),
    ...mapGetters('Conference', ['remoteCameraControlSupported']),
    showMobileJoinModal() {
      return isMobile() && !this.disableMobileJoinPage;
    },
    getRoomName() {
      return this.$route.params.roomName;
    },
    checkVideoAndAudioStatus() {
      const audioSettings = this.$route.query?.startMutedPolicy;
      const videoSettings = this.$route.query?.startVideoMutedPolicy;
      const adminRights = this.$route.query?.allowAdminStartVideo;
      let warningText = '';
      if (audioSettings === 'userAudioMuted' && videoSettings === 'userVideoMuted') {
        warningText += ` ${this.$t('warningVideoAndAudioStatus')}`;
      } else if (videoSettings === 'userVideoMuted') {
        warningText += ` ${this.$t('warningVideoStatus')}`;
      } else if (audioSettings === 'userAudioMuted') {
        warningText += ` ${this.$t('warningAudioStatus')}`;
      }

      if (videoSettings === 'moderatorVideoMuted') {
        warningText += ` ${this.$t('warningVideoStatus')}`;
      }
      if (audioSettings === 'moderatorAudioMuted') {
        warningText += ` ${this.$t('warningAudioStatus')}`;
      }
      if (adminRights && adminRights === 'true') {
        warningText += ` ${this.$t('moderatorWarning')}`;
      }
      return warningText;
    },
    isMobile() {
      return mobileAndTabletCheck();
    },
    errorText() {
      let errorText = '';
      let permissionDevices;

      if (!this.hasCamera || !this.hasMicrophone) {
        let missingDevices = [];
        if (!this.hasCamera) {
          missingDevices.push(this.$t('camera'));
        }
        if (!this.hasMicrophone) {
          missingDevices.push(this.$t('microphone'));
        }
        return `${this.$t('deviceNotFound')} (${missingDevices.join(', ')})`;
      }

      if (this.cameraAllowed && !this.microphoneAllowed) {
        permissionDevices = this.$t('microphone');
      } else if (!this.cameraAllowed && this.microphoneAllowed) {
        permissionDevices = this.$t('camera');
      } else if (!this.cameraAllowed && !this.microphoneAllowed) {
        permissionDevices = `${this.$t('camera')}, ${this.$t('microphone')}`;
      }

      if (permissionDevices) {
        errorText = this.$t('mediaPermissionDenied', { devices: permissionDevices });
      }

      return errorText;
    },
    videoIcon() {
      return this.isCameraActive ? 'video-solid' : 'video-off-1';
    },
    videoIconColor() {
      return this.isCameraActive ? 'white' : 'logo-color';
    },
    micIcon() {
      return this.isMicrophoneActive ? 'mic-solid' : 'mic-off';
    },
    micIconColor() {
      return this.isMicrophoneActive ? 'white' : 'logo-color';
    },
    isCreate() {
      return this.$route.query?.create === 'true' || this.$route.query?.create === true;
    },
    items() {
      return [this.$t('audioAndVideoSettings'), this.$t('advancedSettings')];
    },
    hasCameraAndAllowed() {
      return this.hasCamera && this.cameraAllowed;
    },
    hasMicrophonAndAllowed() {
      return this.hasMicrophone && this.microphoneAllowed;
    },
  },
  created() {
    clearSharedFileKeys();
    this.displayNameDisabled = storage.getItem('disableDisplayNameChange');
    this.displayName = storage.getItem('displayName') || '';
    this.isMicrophoneActive = !storage.getItem('startWithAudioMuted');
    this.isCameraActive = !storage.getItem('startWithVideoMuted');
    this.isSpeakerActive = !storage.getItem('startWithSpeakerMuted');
    this.showError = false;
    this.avatar = storage.getItem('avatar');

    if (XperMeetLib?.keycloakManager?.keycloakInstance?.authenticated) {
      this.displayNameDisabled = !this.$customerConfig?.system?.users?.allowChangeDisplayName;

      if (this.displayNameDisabled || !this.displayName) {
        XperMeetLib.keycloakManager?.keycloakInstance?.loadUserProfile().then(profile => {
          this.displayName = `${profile.firstName} ${profile.lastName}`;
          storage.setItem('displayName', this.displayName);
        });
      }
    } else {
      this.displayNameDisabled = false;
    }
  },
  mounted() {
    if (!isMobile()) {
      this.initPrejoin();
    }
  },
  beforeDestroy() {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
    XperMeetLib.mediaDevices.off('DeviceListChanged', this.setDevices);
  },
  methods: {
    ...mapMutations('Devices', ['SET_DEVICE', 'SELECT_DEVICE', 'SET_DEVICE_STATE', 'CLEAR_DEVICES']),
    initPrejoin() {
      XperMeetLib.lib.init();
      XperMeetLib.lib.setLogLevel(XperMeetLib.lib.logLevels.ERROR);
      this.enumerateDevices(true, true, true);
      XperMeetLib.mediaDevices.on('DeviceListChanged', this.setDevices);
    },
    closeJoinFromMobileModal() {
      this.disableMobileJoinPage = true;
      this.initPrejoin();
    },
    handleCameraChange(deviceId) {
      this.selectedCameraId = deviceId;
    },
    toggleMicrophone() {
      this.isMicrophoneActive = !this.isMicrophoneActive;
    },
    openHintsAndKeypointsModal() {
      this.$showModal(HintsAndKeypointsModal);
    },
    setDevices(devices) {
      if (this.$route.name === 'prejoin') {
        this.CLEAR_DEVICES();
        devices.forEach(d => this.SET_DEVICE(d));
        const videoDevices = devices.filter(d => d.kind === 'videoinput');
        const micDevices = devices.filter(d => d.kind === 'audioinput');

        const selectedCameraIsExists = !this.selectedCameraId || videoDevices.find(d => d.deviceId === this.selectedCameraId);
        const selectedMicIsExists = !this.selectedMicrophoneId || micDevices.find(d => d.deviceId === this.selectedMicrophoneId);

        if (!selectedCameraIsExists) {
          if (videoDevices[0]) {
            this.selectedCameraId = videoDevices[0].deviceId;
          } else {
            this.selectedCameraId = undefined;
          }
        }

        if (!selectedMicIsExists) {
          if (micDevices[0]) {
            this.selectedMicrophoneId = micDevices[0].deviceId;
          } else {
            this.selectedMicrophoneId = undefined;
          }
        }
      }
    },
    enumerateDevices(selectMicrophone, selectCamera, selectSpeaker) {
      XperMeetLib.lib.mediaDevices.enumerateDevices(devices => {
        this.setDevices(devices);

        if (selectCamera) {
          if (this.getSelectedCameraId) {
            this.$refs.deviceSelector.selectedCameraId = this.getSelectedCameraId;
          }
        }

        if (selectMicrophone) {
          if (this.getSelectedMicrophoneId) {
            this.$refs.deviceSelector.selectedMicrophoneId = this.getSelectedMicrophoneId;
          } else if (this.getMicrophones.length > 0) {
            this.$refs.deviceSelector.selectedMicrophoneId = this.getMicrophones[0].deviceId;
          }
        }

        if (selectSpeaker) {
          if (this.getSelectedSpeakerId) {
            this.$refs.deviceSelector.selectedSpeakerId = this.getSelectedSpeakerId;
          }

          if (!this.getSpeakers.length) {
            this.SET_DEVICE_STATE({ type: 'speaker', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
          }
        }
      });
    },
    mediaDeviceInitiated({ audioStream, videoStream, errorCode }) {
      if (errorCode) {
        this.errorCode = errorCode;
        return;
      }

      if (videoStream) {
        const video = document.querySelector('#local-video');
        video.srcObject = videoStream;
        video.onloadedmetadata = function () {
          video.play();
        };
        this.$refs.deviceSelector.selectedCameraId = videoStream.getVideoTracks()[0].getSettings().deviceId;
      } else {
        this.$refs.deviceSelector.isCameraActive = false;
      }

      if (!audioStream) {
        this.$refs.deviceSelector.isMicrophoneActive = false;
      }
    },
    onDisplayNameBlur() {
      if (!this.displayName || !this.displayName.trim()) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    join() {
      if (!this.displayName || !this.displayName.trim()) {
        let setName = this.$el.querySelector('.set-name');
        setName.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        this.showError = true;
        return;
      }

      const camera = this.getCameras.find(s => s.deviceId === this.$refs.deviceSelector.selectedCameraId);
      if (camera) {
        this.SELECT_DEVICE(camera);
      }
      const microphone = this.getMicrophones.find(s => s.deviceId === this.$refs.deviceSelector.selectedMicrophoneId);
      if (microphone) {
        this.SELECT_DEVICE(microphone);
      }
      const speaker = this.getSpeakers.find(s => s.deviceId === this.$refs.deviceSelector.selectedSpeakerId);
      if (speaker) {
        this.SELECT_DEVICE(speaker);
      }

      storage.setItem('displayName', this.displayName);
      storage.setItem('skipPreJoinRoom', this.getRoomName);
      storage.setItem('startWithAudioMuted', !this.isMicrophoneActive);
      storage.setItem('startWithVideoMuted', !this.isCameraActive);
      storage.setItem('startWithSpeakerMuted', !this.isSpeakerActive);

      // Destroy video stream
      this.$refs.mediaDeviceInitiator?.destroyVideoStreams();
      const video = document.querySelector('#local-video');
      video.srcObject = null;
      const queryParams = {
        name: 'meet',
        params: { roomName: this.getRoomName },
        query: { ...this.$route.query },
      };
      if (this.isCreate) {
        queryParams.query = { ...this.$route.query, ...this.settings };
      }
      this.$router.push(queryParams);
    },
    onDisplayNameKeyUp() {
      if (!this.displayName || !this.displayName.trim()) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
  },
  watch: {
    cameraAllowed() {
      const t = setTimeout(() => {
        this.enumerateDevices(false, true, false);
      }, 500);
      this.timeouts.push(t);
    },
    microphoneAllowed() {
      const t = setTimeout(() => {
        this.enumerateDevices(true, false, true);
      }, 1000);
      this.timeouts.push(t);
    },
  },
};
</script>

<style lang="scss">
.pre-join {
  height: calc(var(--vh, 1vh) * 100); /* Fallback for browsers that do not support Custom Properties */
  overflow: auto;
  margin: auto;
  overflow-x: hidden;
  position: relative;

  .hints-and-keypoints {
    display: flex;
    align-items: center;
    a {
      margin-left: 10px;
      color: white;
      text-decoration: none;
      font-size: 14px;
    }
  }
  &--content {
    max-width: 1250px;
    margin: auto;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 4rem;
    }

    .prejoin-avatar-container {
      padding: 0;
      width: 100%;
      justify-content: center;
      margin-top: 10px;

      &--video {
        position: relative;
        border-radius: 8px;

        .hide {
          visibility: hidden;
        }

        &--close {
          position: absolute;
          background-color: var(--v-date-base);
          border-radius: 50%;
          width: 140px;
          height: 140px;

          @media (max-width: $breakpoint-mobile) {
            width: 90px;
            height: 90px;
          }
        }

        &--actions {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
        }

        &.avatar {
          height: 400px; /* duzgun bir yere tasınacak sonradan */
          background: linear-gradient(107.98deg, rgba(248, 248, 248, 0.3) 1.97%, rgba(248, 248, 248, 0) 101.01%);
          border: 2px solid var(--v-body-60);
        }
      }
    }

    .description {
      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .text {
        font-size: 14px;
        line-height: 21px;
      }

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 1rem;
      }
    }

    .default-device-name {
      font-size: 16px;
      margin-left: 10px;
    }

    #local-video {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      border-radius: 8px;
    }

    .v-expansion-panel-content__wrap {
      padding: 15px 0 !important;
    }
  }

  .avatar-wrapper {
    @media (max-width: $breakpoint-mobile) {
      img {
        max-width: 80px !important;
        max-height: 80px !important;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .pre-join-video {
      max-height: 225px;
    }
  }
  .prejoin-tabs-header {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none;
    }
    .prejoin-tab-titles {
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      text-transform: initial;
    }
  }

  .prejoin-tabs {
    background-color: transparent;
    .v-window-item {
      background-color: transparent;
    }
    &.join-tabs {
      border-top: none;
    }
  }
  .prejoin-advenced-settings {
    .start-muted-policy-settings {
      .advanced-settings-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
      }
      .muted-policy-checkbox {
        padding: 0;
        .v-input__control {
          .v-input__slot {
            .v-label {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
}
</style>
