var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        "show-actions": "",
        "confirm-button-text": _vm.$t("send"),
        "cancel-button-text": _vm.$t("cancel"),
        title: _vm.$t("meetingRequest"),
        persistent: "",
        "auto-close": false,
        "confirm-button-disabled": _vm.requestLoading,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c(
        "div",
        { staticClass: "meeting-request-container" },
        [
          _c("div", { staticClass: "text-center mb-10 opacity-80" }, [
            _vm._v(_vm._s(_vm.$t("meetingRequestSubtitle"))),
          ]),
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.currentMeeting,
                    callback: function ($$v) {
                      _vm.currentMeeting = $$v
                    },
                    expression: "currentMeeting",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: {
                      value: true,
                      color: "white",
                      label: _vm.$t("forCurrentMeeting"),
                      "hide-details": "",
                    },
                  }),
                  _c("v-radio", {
                    attrs: {
                      value: false,
                      color: "white",
                      label: _vm.$t("forNewMeeting"),
                      "hide-details": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "12" } },
                    [
                      _c("CopyLink", {
                        attrs: {
                          text: _vm.getConferenceUrl,
                          "copy-text": _vm.getConferenceUrl,
                        },
                      }),
                      _c("h3", { staticClass: "opacity-90" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.currentMeeting
                                ? _vm.$t("conferenceInformation")
                                : _vm.$t("newConference")
                            ) +
                            " "
                        ),
                      ]),
                      _c("text-field", {
                        staticClass: "pb-4",
                        attrs: {
                          "hide-details": "",
                          label: _vm.$t("conferenceName"),
                          placeholder: _vm.$t("enterAConferenceName"),
                          value: _vm.getRoomName,
                          readonly: "",
                        },
                      }),
                      _c("text-field", {
                        staticClass: "modal-inputs pb-5",
                        attrs: {
                          "hide-details": "",
                          label: _vm.$t("enterAConferencePassword"),
                          type: _vm.showConferencePassword
                            ? "text"
                            : "password",
                          "append-icon": _vm.showConferencePassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          name: "new-password",
                          autocomplete: "new-password",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showConferencePassword =
                              !_vm.showConferencePassword
                          },
                        },
                        model: {
                          value: _vm.meetingPassword,
                          callback: function ($$v) {
                            _vm.meetingPassword = $$v
                          },
                          expression: "meetingPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "opacity-90",
                          attrs: {
                            dense: "",
                            type: "info",
                            icon: "feather icon-info",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("LockRoomAlert")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { ref: "participantInformation" },
                [
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "12" } },
                    [
                      _c("h3", { staticClass: "opacity-90" }, [
                        _vm._v(_vm._s(_vm.$t("participantInformation"))),
                      ]),
                      _vm.showEmailAutoComplete
                        ? [
                            _c(
                              "v-autocomplete",
                              {
                                staticClass: "primary autocomplete-custom",
                                attrs: {
                                  autocomplete: "email-list",
                                  items: _vm.emailList,
                                  label: _vm.$t("participantsEmailAddress"),
                                  "search-input": _vm.emailSearchValue,
                                  "hide-details": "",
                                  outlined: "",
                                  "deletable-chips": "",
                                  multiple: "",
                                  "small-chips": "",
                                  attach: "",
                                  "hide-selected": "",
                                  "hide-no-data": !_vm.emailList.length,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.emailSearchValue = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.emailSearchValue = $event
                                  },
                                  blur: _vm.onBlurAutoComplete,
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      ) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      ) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "tab",
                                        9,
                                        $event.key,
                                        "Tab"
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                    return _vm.onEnterEmail.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedParticipantEmails,
                                  callback: function ($$v) {
                                    _vm.selectedParticipantEmails = $$v
                                  },
                                  expression: "selectedParticipantEmails",
                                },
                              },
                              [
                                _c("template", { slot: "no-data" }, [
                                  _vm.emailListSearching
                                    ? _c(
                                        "div",
                                        { staticClass: "autocomplete-data" },
                                        [_vm._v(_vm._s(_vm.$t("searching")))]
                                      )
                                    : !_vm.emailListSearching
                                    ? _c("div", {
                                        staticClass: "autocomplete-data",
                                      })
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            ),
                          ]
                        : [
                            _c(
                              "v-combobox",
                              {
                                staticClass: "primary autocomplete-custom",
                                attrs: {
                                  items: _vm.selectedParticipantEmails,
                                  label: _vm.$t("participantsEmailAddress"),
                                  color: "white",
                                  "hide-details": "",
                                  outlined: "",
                                  "deletable-chips": "",
                                  multiple: "",
                                  "small-chips": "",
                                  attach: "",
                                  "hide-selected": "",
                                  "hide-no-data": !_vm.emailList.length,
                                },
                                model: {
                                  value: _vm.selectedParticipantEmails,
                                  callback: function ($$v) {
                                    _vm.selectedParticipantEmails = $$v
                                  },
                                  expression: "selectedParticipantEmails",
                                },
                              },
                              [
                                _c("template", { slot: "no-data" }, [
                                  _vm.emailListSearching
                                    ? _c(
                                        "div",
                                        { staticClass: "autocomplete-data" },
                                        [_vm._v(_vm._s(_vm.$t("searching")))]
                                      )
                                    : !_vm.emailListSearching
                                    ? _c(
                                        "div",
                                        { staticClass: "autocomplete-data" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("noData")) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            ),
                          ],
                      _vm.emailFieldError
                        ? _c(
                            "v-alert",
                            { staticClass: "mt-4", attrs: { type: "error" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("typeAnEmailAddressAndPressEnter")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              !_vm.currentMeeting
                ? _c(
                    "v-row",
                    { attrs: { align: "baseline" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { xs: "12", sm: "12", md: "12" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                disabled: _vm.currentMeeting,
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  label: _vm.$t("startDate"),
                                                  placeholder:
                                                    _vm.displayedStartDate,
                                                  readonly: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.displayedStartDate,
                                                  callback: function ($$v) {
                                                    _vm.displayedStartDate = $$v
                                                  },
                                                  expression:
                                                    "displayedStartDate",
                                                },
                                              },
                                              "text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3734085793
                              ),
                              model: {
                                value: _vm.startDatePicker,
                                callback: function ($$v) {
                                  _vm.startDatePicker = $$v
                                },
                                expression: "startDatePicker",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { min: _vm.minStartDate },
                                on: {
                                  input: function ($event) {
                                    _vm.startDatePicker = false
                                  },
                                },
                                model: {
                                  value: _vm.startDate,
                                  callback: function ($$v) {
                                    _vm.startDate = $$v
                                  },
                                  expression: "startDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "12", sm: "6", md: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.timeMask,
                                expression: "timeMask",
                              },
                            ],
                            staticClass: "start-time-input",
                            attrs: {
                              label: _vm.$t("startTime"),
                              placeholder: "- - : - -",
                              disabled: _vm.currentMeeting,
                              name: "start-time",
                              autocomplete: "false",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.startTime,
                              callback: function ($$v) {
                                _vm.startTime = $$v
                              },
                              expression: "startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "12", sm: "6", md: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "duration-input",
                            attrs: {
                              label: _vm.$t("duration"),
                              type: "number",
                              min: "0",
                              disabled: _vm.currentMeeting,
                              name: "duration",
                              placeholder: "- - -",
                              autocomplete: "false",
                              "hide-details": "",
                            },
                            on: { blur: _vm.onDurationBlur },
                            model: {
                              value: _vm.duration,
                              callback: function ($$v) {
                                _vm.duration = $$v
                              },
                              expression: "duration",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { xs: "12", sm: "6", md: "3" } }, [
                        _c("span", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v(_vm._s(_vm.durationText)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("vue-editor", {
                        staticClass: "vue-editor",
                        attrs: { editorToolbar: _vm.htmlEditorProperty },
                        model: {
                          value: _vm.htmlBody,
                          callback: function ($$v) {
                            _vm.htmlBody = $$v
                          },
                          expression: "htmlBody",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { xs: "12", sm: "12", md: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("shareParticipantListWithOthers"))
                          ),
                        ]),
                        _c("v-switch", {
                          staticClass: "my-0 pl-4",
                          attrs: { color: "success", "hide-details": true },
                          model: {
                            value: _vm.shareParticipantListWithOthers,
                            callback: function ($$v) {
                              _vm.shareParticipantListWithOthers = $$v
                            },
                            expression: "shareParticipantListWithOthers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }