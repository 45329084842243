var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: {
        "avatar--absolute": _vm.absolute,
        "avatar--fullscreen": _vm.fullScreen,
      },
    },
    [
      _c(
        "div",
        { staticClass: "avatar-wrapper" },
        [
          _vm.error
            ? [
                _c(
                  "div",
                  {
                    staticClass: "avatar-default-icon",
                    style: _vm.computedStyle,
                    attrs: { alt: _vm.displayName, title: _vm.displayName },
                  },
                  [
                    _c("icon", {
                      style: { width: _vm.iconSize, height: _vm.iconSize },
                      attrs: { icon: "user" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm.loading
            ? [
                _c(
                  "div",
                  {
                    staticClass: "avatar-default-icon",
                    style: _vm.computedStyle,
                    attrs: { alt: _vm.displayName, title: _vm.displayName },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm.image
            ? [
                _c("img", {
                  staticClass: "avatar-image",
                  style: _vm.computedStyle,
                  attrs: {
                    src: _vm.image,
                    alt: _vm.displayName,
                    title: _vm.displayName,
                  },
                  on: { error: _vm.imgOnError },
                }),
              ]
            : [
                _vm.autoRandomAvatar
                  ? _c("img", {
                      staticClass: "avatar-image",
                      style: _vm.computedStyle,
                      attrs: {
                        src: _vm.randomAvatar,
                        alt: _vm.displayName,
                        title: _vm.displayName,
                      },
                      on: { error: _vm.imgOnError },
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "avatar-default-icon",
                        style: _vm.computedStyle,
                        attrs: { alt: _vm.displayName, title: _vm.displayName },
                      },
                      [
                        _c("icon", {
                          style: { width: _vm.iconSize, height: _vm.iconSize },
                          attrs: { icon: "user" },
                        }),
                      ],
                      1
                    ),
              ],
          _vm.editable
            ? _c(
                "div",
                {
                  staticClass: "add-icon",
                  attrs: { "data-test-id": "G-AU-A-AAI" },
                },
                [_c("icon", { attrs: { icon: "plus", size: "18" } })],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }