var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("lobby"),
        "sub-title": _vm.$t("joinMeeting.warning"),
        "confirm-button-text": _vm.$t("askToJoin"),
        "cancel-button-text": _vm.$t("cancel"),
        "show-actions": false,
        "content-class": "lobby-join-modal",
        persistent: "",
        "auto-close": false,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c(
        "div",
        { staticClass: "waiting text-center" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                value: _vm.activeRightMenuTab,
                grow: "",
                "show-arrows": "",
                "icons-and-text": "",
                height: "52",
              },
            },
            [
              _c(
                "v-tab",
                {
                  attrs: { href: "#chat", ripple: false },
                  nativeOn: {
                    click: function ($event) {
                      _vm.activeRightMenuTab = "chat"
                    },
                  },
                },
                [
                  _c("div", { staticClass: "h-full" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c("icon", { attrs: { icon: "chat-1" } })],
                      1
                    ),
                    _c("span", { staticClass: "mt-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("chat")) + " "),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-tab",
                {
                  attrs: { href: "#participants", ripple: false },
                  nativeOn: {
                    click: function ($event) {
                      _vm.activeRightMenuTab = "participants"
                    },
                  },
                },
                [
                  _c("div", { staticClass: "h-full" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c("icon", { attrs: { icon: "users" } })],
                      1
                    ),
                    _c("span", { staticClass: "mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("userList")) +
                          " (" +
                          _vm._s(_vm.waitingUsers.length) +
                          ")"
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-tab",
                {
                  attrs: { href: "#join", ripple: false },
                  nativeOn: {
                    click: function ($event) {
                      _vm.activeRightMenuTab = "join"
                    },
                  },
                },
                [
                  _c("div", { staticClass: "h-full" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c("icon", { attrs: { icon: "key" } })],
                      1
                    ),
                    _c("span", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("join"))),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            { attrs: { touchless: "", value: _vm.activeRightMenuTab } },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "chat" } },
                [
                  _c("Chat", {
                    staticClass: "lobby-chat",
                    attrs: {
                      "body-id": "lobby-chat",
                      "show-file-share-button": false,
                      "chat-enabled": true,
                      messages: _vm.lobbyMessages,
                      enablePrivateMessage: false,
                    },
                    on: { sendButtonClicked: _vm.onSendButtonClicked },
                  }),
                ],
                1
              ),
              _c("v-tab-item", { attrs: { value: "participants" } }, [
                _c(
                  "div",
                  _vm._l(_vm.waitingUsers, function (user) {
                    return _c("UserCard", {
                      key: user.id,
                      attrs: {
                        "display-name": _vm.getDisplayName(user),
                        me: _vm.getLocalUser && user.id === _vm.getLocalUser.id,
                        moderator: user.role === "moderator",
                        participant: user.role === "participant",
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c("v-tab-item", { attrs: { value: "join" } }, [
                _c(
                  "div",
                  { staticClass: "px-8 py-6" },
                  [
                    _c("p", { staticClass: "my-5" }, [
                      _vm._v(_vm._s(_vm.$t("joinWithPasswordDesc"))),
                    ]),
                    _c("text-field", {
                      staticClass: "mb-5",
                      attrs: {
                        type: "password",
                        placeholder: _vm.$t("conferencePassword"),
                        "hide-details": "",
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.join.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          color: "secondary",
                          block: "",
                          disabled: !_vm.password,
                        },
                        on: { click: _vm.join },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("join")) + " ")]
                    ),
                    _vm.joinErrorMessage
                      ? _c(
                          "v-alert",
                          { attrs: { type: "error", outlined: "" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(_vm.joinErrorMessage)) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }