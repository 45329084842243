var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.privateRoomName
    ? _c("icon", {
        staticClass: "private-room-icon ml-1",
        attrs: { icon: "blue-t", size: "18" },
        on: { click: _vm.handleClick },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }