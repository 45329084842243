<template>
  <Modal
    max-width="700px"
    :sub-title="$t('viewAndUpdateAllSettings')"
    :confirm-button-text="$t('save')"
    :persistent="true"
    :cancel-button-text="$t('cancel')"
    :title="$t('settings')"
    show-actions
    content-class="settings-modal"
    :show-back-button="panel && ($isTablet || $isMobile)"
    showBottomDivider
    @back="goBack"
    @close="onModalClose"
  >
    <div v-if="panel" :class="{ 'mb-5': $isTablet || $isMobile }" class="d-flex justify-end">
      <lang-switch />
    </div>
    <div class="settings-modal-wrapper" :class="{ 'panel-opened': !!panel, 'tablet-breakpoint': $isTablet || $isMobile }">
      <div v-if="showTitles()" class="settings-titles" :selected-index="panel">
        <div v-for="(variable, index) in settingVariables" :key="index" class="settings-title" :class="{ active: variable.value === panel }">
          <div :data-test-id="variable.id" class="settings-title-content" @click="changeMenu(variable.value)">
            <div class="d-flex align-center">
              <div class="circle" v-if="variable.value === panel"></div>
              <span class="setting-title" :class="{ 'ml-2': variable.value === panel }">{{ variable.title }}</span>
            </div>

            <div v-if="variable.value === panel || $isTablet || $isMobile" class="right-icon">
              <icon icon="chevron-right" />
            </div>
          </div>
        </div>
      </div>
      <div class="contents" :class="{ 'contents--active': !!panel }">
        <ProfileSettings ref="ProfileSettings" v-show="panel === '0'" />
        <ConferenceSettings ref="ConferenceSettings" v-show="panel === '1'" />
        <DeviceSettings ref="DeviceSettings" v-show="panel === '2'" />
        <QualitySettings ref="QualitySettings" v-show="panel === '3'" />
        <NotificationSettings ref="NotificationSettings" v-show="panel === '4'" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from '../../Shared/Icon.vue';
import ConferenceSettings from './ConferenceSettings';
import DeviceSettings from './DeviceSettings';
import ProfileSettings from './ProfileSettings';
import QualitySettings from './QualitySettings';
import NotificationSettings from './NotificationSettings';
import LangSwitch from '@/components/Shared/LangSwitch';

export default {
  name: 'Settings',
  components: { Icon, ProfileSettings, ConferenceSettings, DeviceSettings, QualitySettings, NotificationSettings, LangSwitch },
  props: {
    defaultPanel: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      panel: this.defaultPanel,
    };
  },
  computed: {
    settingVariables() {
      return [
        { title: this.$t('profileSettings'), value: '0', id: 'profileSettings' },
        { title: this.$t('conferenceSettings'), value: '1', id: 'conferenceSettings' },
        { title: this.$t('audioAndVideo'), value: '2', id: 'audioAndVideo' },
        { title: this.$t('qualitySettings.title'), value: '3', id: 'qualitySettings' },
        { title: this.$t('notificationSettings.title'), value: '4', id: 'notificationSettings' },
      ];
    },
    settingsComponent() {
      switch (this.panel) {
        case '0':
          return ProfileSettings;
        case '1':
          return ConferenceSettings;
        case '2':
          return DeviceSettings;
        case '3':
          return QualitySettings;
        case '4':
          return NotificationSettings;
        default:
          return false;
      }
    },
  },
  created() {
    if (this.$isTablet || this.$isMobile) {
      this.panel = null;
    }
  },
  methods: {
    onModalClose(type) {
      if (type === 'confirm') {
        this.$refs.ProfileSettings.save();
        this.$refs.ConferenceSettings.save();
        this.$refs.DeviceSettings.save();
        this.$refs.QualitySettings.save();
        this.$refs.NotificationSettings.save();
      }
    },
    showTitles() {
      if (this.$isTablet || this.$isMobile) {
        return !this.panel;
      }

      return true;
    },
    changeMenu(value) {
      this.panel = value;
    },
    goBack() {
      this.panel = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-modal-wrapper {
  display: flex;
  overflow: hidden;
  height: 100%;

  .settings-titles {
    width: 36%;
    overflow: auto;

    .settings-title {
      display: flex;
      height: 44px;
      cursor: pointer;
      &.active {
        background: var(--v-primary-base);
        border-radius: 8px;
      }

      .settings-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 16px;
        width: 100%;
        .setting-title {
          color: var(--v-white-base);
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
        .right-icon {
          margin-right: 16px;
        }
        .circle {
          width: 6px;
          height: 6px;
          background: var(--v-logo-color-base);
          border-radius: 50%;
        }
      }
    }
  }
  .contents {
    width: 64%;
    overflow: auto;
    overflow-x: hidden;
  }
  &.tablet-breakpoint {
    /*height: calc(100vh - 370px);*/
    position: relative;

    /*&.panel-opened {
      height: calc(100vh - 390px);
    }*/

    .settings-titles {
      width: 100%;

      &[selected-index]:not([selected-index='']) {
        .settings-title {
          display: none;

          &.active {
            display: flex;
          }
        }
      }
    }
    .contents {
      position: absolute;
      left: 100%;
      width: 100%;
      transition: ease all 1s;
      padding: 0;
      &--active {
        height: 100%;
        left: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.modal-container {
  &.v-dialog.settings-modal {
    .v-card {
      height: 90vh;
    }
  }
}
</style>
