/**
 *
 * @type {{MODERATOR_VIDEO_MUTED: string, ALLOW_FILE_SHARE: string, ALLOW_ADMIN_START_VIDEO: string, ALLOW_PRIVATE_CHAT: string, AUDIO_UNMUTED: string, MODERATOR_AUDIO_MUTED: string, START_MUTED_POLICY: string, USER_AUDIO_MUTED: string, USER_VIDEO_MUTED: string, ALLOW_SCREEN_SHARE: string}}
 * GLOBAL ENUM DECLERATIONS
 * used by xper_web, xper_lib
 */

const RoomConfigEnums = {
  AUDIO_UNMUTED: "audioUnmuted",
  VIDEO_UNMUTED: "videoUnmuted",
  MODERATOR_AUDIO_MUTED: "moderatorAudioMuted",
  MODERATOR_VIDEO_MUTED: "moderatorVideoMuted",
  USER_AUDIO_MUTED: "userAudioMuted",
  USER_VIDEO_MUTED: "userVideoMuted",
  LOBBY_ENABLED: "lobbyEnabled",

  LAYOUT: "layout",
  FOLLOW_MODERATOR_FULL_SCREEN: "followModeratorFullScreen",
  FULL_SCREEN_USER_ID: "fullScreenUserId",

  START_MUTED_POLICY: "startMutedPolicy",
  START_VIDEO_MUTED_POLICY: "startVideoMutedPolicy",
  ALLOW_FILE_SHARE: "allowFileShare",
  ALLOW_PRIVATE_CHAT: "allowPrivateChat",
  ALLOW_SCREEN_SHARE: "allowScreenShare",
  ALLOW_ADMIN_START_VIDEO: "allowAdminStartVideo",
  REMOTE_RECORD_STARTED_BY: "onRecord",

  SHARED_PROPERTIES: "sharedProperties",
  E2EE_ENABLED: "e2eeEnabled",

  WEBINAR_STREAM_CHANNEL: "webinarStreamChannel",

  PACKAGE_CONFIG: "packageConfig",
  AR_FILE_LIST: "arFileList",

  WEBINAR_TITLE: "webinarTitle",
};

const AR_OBJECT_TYPES = Object.freeze({
  ARROW: "0",
  SCREWDRIVER: "1",
  WRENCH: "2",
  DESK: "3",
  ENGINE: "4",
  ERASER: "5",
  BIN: "6",
  FREEDRAW: "7",
  TEXT: "8",
  NUMBER: "9",
  INTERACTIVE: "10",
});

const AR_ACTION_TYPES = Object.freeze({
  ADD: 0,
  DELETE: 1,
  DELETE_ALL: 2,
  ADD_DRAWING: 3,
  ADD_TEXT: 4,
  ADD_NUMBER: 5,
  INTERACT: 6,
  LOAD_MODELS_FROM_DRIVE: 7,
  SHOW_DELETABLES: 8,
});

/**
 * The set of media types.
 *
 * @enum {string}
 */
export const MEDIA_TYPE = {
  AUDIO: "audio",
  PRESENTER: "presenter",
  VIDEO: "video",
};

/**
 * The supported remote video resolutions. The values are currently based on
 * available simulcast layers.
 *
 * @type {object}
 */
export const VIDEO_QUALITY_LEVELS = {
  ULTRA: 2160,
  HIGH: 720,
  STANDARD: 360,
  LOW: 180,
};

/**
 * The set of media device types.
 *
 * @enum {string}
 */
export const MEDIA_DEVICE_TYPE = {
  AUDIO: "audio",
  DESKTOP: "desktop",
  VIDEO: "video",
};

export const ERROR_HANDLER_ENUMS = {
  NETWORK_ERROR: 1,
  SOCKET_ERROR: 2,
  STROPHE_ERROR: 3,
  SRVICE_ERROR: 1000,
};

export const ENDPOINT_MESSAGE_TYPES = {
  WEBINAR_STREAM_ID: "webinar-stream-key",
  CHAT_NOTIFICATION: "chat-notification-message",
};

export default {
  RoomConfigEnums,
  AR_OBJECT_TYPES,
  AR_ACTION_TYPES,
  VIDEO_QUALITY_LEVELS,
  ERROR_HANDLER_ENUMS,
  ENDPOINT_MESSAGE_TYPES,
};
