<template>
  <Modal
    :title="$t(title)"
    :confirm-button-text="$t(confirmText)"
    :cancel-button-text="$t('cancel')"
    show-actions
    :confirm-button-disabled="confirmDisabled"
    @close="onModalClose"
  >
    <div class="pl-2 pr-2 text-center">
      <div class="ma-0 pt-1 pb-3">
        {{ text }}
      </div>
      <div v-if="isWebinarStarted" class="mt-5">
        <TextField class="started-webinar-title-field" v-model="webinarTitle" :label="$t('webinarTitle')" maxlength="90" hide-details>
          <template v-slot:append>
            <v-btn color="secondary" :loading="loadingWebinarTitle" @click="saveWebinarTitle">
              {{ $t('save') }}
            </v-btn>
          </template>
        </TextField>
        <div v-if="webinarTitleUpdated" class="webinar-title-update-info-text">
          <span :class="`webinar-title-update-info-text--${webinarTitleUpdated}`">{{ $t(`updateWebinarTitleResponseText.${webinarTitleUpdated}`) }}</span>
        </div>
      </div>
      <div class="mt-2 mb-3" v-if="!isWebinarStarted && isYoutubeStreamEnabled">
        <div class="d-flex">
          <span class="mt-1 pr-3 white--text">
            {{ $t('selectStreamChannel') }}
          </span>
          <div class="d-flex flex-column">
            <div class="flex-grow-1 d-flex flex-column align-end">
              <v-radio-group v-model="streamChannel" row hide-details dense class="ma-0">
                <v-radio class="webinar-radio" label="BizBize" value="xpermeet" color="white"></v-radio>
                <v-radio class="webinar-radio" label="YouTube" value="youtube" color="white" :disabled="isYoutubeStreamDisabled"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div v-if="streamChannel === 'youtube'" class="mt-5">
          <TextField autofocus v-model="streamKey" :label="$t('liveStreamKey')" hide-details></TextField>
        </div>
        <div class="mt-5">
          <TextField v-model="webinarTitle" :label="$t('webinarTitle')" hide-details></TextField>
        </div>

        <div class="d-flex flex-row justify-space-between align-center mt-6">
          <div class="d-flex align-center white--text">
            <div class="pr-3">{{ $t('saveWebinarStream') }}</div>
            <v-switch class="mt-0 pt-0" v-model="saveWebinarStream" :disabled="!enableWebinarRecord" color="success" hide-details></v-switch>
          </div>
        </div>
        <v-alert v-if="!enableWebinarRecord" dense type="info" icon="feather icon-info" class="mt-2">{{ $t('remoteRecordLicenseInfo') }}</v-alert>
        <v-alert v-else-if="showErrorMessageForMaxChar" dense type="error" outlined class="mt-2">{{ $t('maxChar', { char: 90 }) }}</v-alert>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ManageService from '@/services/manage';

export default {
  name: 'WebinarStartConfirm',
  props: {
    isWebinarStarted: {
      type: Boolean,
    },
    enableWebinarRecord: Boolean,
  },
  data() {
    return {
      showModal: true,
      streamChannel: 'xpermeet',
      streamKey: '',
      webinarTitle: '',
      title: this.isWebinarStarted ? this.$t('webinarStop') : this.$t('webinarStart'),
      text: this.isWebinarStarted ? this.$t('webinarStopConfirm') : this.$t('webinarStartConfirm'),
      confirmText: this.isWebinarStarted ? this.$t('stop') : this.$t('start'),
      saveWebinarStream: false,
      loadingWebinarTitle: false,
      webinarTitleUpdated: '',
      showErrorMessageForMaxChar: false,
    };
  },
  computed: {
    ...mapState('Conference', ['meetingId']),
    confirmDisabled() {
      return (this.streamChannel === 'youtube' && this.streamKey === '') || this.showErrorMessageForMaxChar;
    },
    streamKeyPrefix() {
      return this.streamChannel === 'youtube' ? 'youtube.' : '';
    },
    isYoutubeStreamEnabled() {
      // TODO: System.getModules function must be add. At this function will return the user type like admin

      return this.$customerConfig?.system?.admin?.modules?.webinar?.youtubeEnabled;
    },
    isYoutubeStreamDisabled() {
      return this.$customerConfig?.system?.admin?.modules?.webinar?.disableYoutube;
    },
  },
  mounted() {
    if (this.isWebinarStarted) {
      this.fetchWebinarTitle();
    }
  },
  methods: {
    ...mapActions('Conference', ['setWebinarTitle']),
    ...mapActions('Webinar', ['sendFetcWebinarTitleMessage']),
    onConfirmClicked() {
      if (!this.confirmDisabled) {
        this.$emit('confirm', `${this.streamKeyPrefix}${this.streamKey}`, this.saveWebinarStream, this.webinarTitle);
        this.closeModal();
      }
    },
    closeModal() {
      this.showModal = false;
      this.$modal?.delayedClose();
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.onConfirmClicked();
      } else {
        this.$emit('close', type);
      }
    },
    async fetchWebinarTitle() {
      const res = await ManageService.getWebinarTitle(this.meetingId);
      this.webinarTitle = res?.data?.title;
    },
    saveWebinarTitle() {
      this.loadingWebinarTitle = true;
      try {
        this.setWebinarTitle({ prosody_meeting_id: this.meetingId, title: this.webinarTitle }).then(() => {
          this.sendFetcWebinarTitleMessage(this.webinarTitle);
          this.webinarTitleUpdated = 'success';
        });
      } catch (error) {
        this.webinarTitleUpdated = 'error';
      } finally {
        this.loadingWebinarTitle = false;
        setTimeout(() => {
          this.webinarTitleUpdated = '';
        }, 3000);
      }
    },
  },
  watch: {
    webinarTitle(v) {
      this.showErrorMessageForMaxChar = Boolean(v && v.length > 90);
    },
  },
};
</script>
<style lang="scss">
.v-input {
  &.started-webinar-title-field {
    .v-input__append-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0 !important;
      height: 100%;
    }
  }
}
.webinar-title-update-info-text {
  text-align: initial;
  margin-top: 10px;
  &--success {
    color: var(--v-success-base);
  }
  &--error {
    color: var(--v-error-base);
  }
}
</style>
