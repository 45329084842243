<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'TextField',
  extends: VTextField,
  mixins: [
    {
      props: {
        label: {
          type: String,
        },
        backgroundColor: {
          default: 'primary',
        },
        filled: { default: true },
        solo: { default: false },
        singleLine: { default: false },
        flat: { default: false },
        height: { default: '50px' },
        autocomplete: { default: 'off' },
      },
    },
  ],
  mounted() {
    const elms = this.$el?.querySelectorAll('input') || [];

    for (let i = 0; i < elms.length; i++) {
      elms[i].setAttribute('autocomplete', this.autocomplete);
    }
  },
};
</script>
