var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "webinar-bottom-menu d-flex align-center",
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("menu-button", {
            staticClass: "mr-1",
            attrs: {
              "data-test-id": "webinar-hands-up-button",
              icon: "hand",
              color: _vm.user.handsUp ? "warning" : "",
            },
            on: { click: _vm.handsUp },
          }),
          _c("menu-button", {
            staticClass: "mr-1",
            attrs: {
              "data-test-id": "webinar-chat-button",
              badgeWebinarContent: _vm.getWebinarUnreadMessageCount,
              icon: "chat",
            },
            on: { click: _vm.toggleScreenAndReadMessages },
          }),
          _c("menu-button", {
            attrs: {
              "data-test-id": "webinar-speaker-button",
              icon: _vm.speakerIcon,
              color: _vm.speakerColor,
              translateItemLabels: false,
              "tooltip-text": !_vm.speakerMuted
                ? _vm.$t("muteSpeaker")
                : _vm.$t("unmuteSpeaker"),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("itemClicked", "speakerMuted")
              },
            },
          }),
          !_vm.$isMobile
            ? _c("div", { staticClass: "menu-btn-spacer mr-1" })
            : _vm._e(),
          _c("menu-button", {
            attrs: {
              "data-test-id": "webinar-log-out-button",
              icon: "phone",
              color: "red",
            },
            on: { click: _vm.logout },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }