<template>
  <Modal :title="$t('conferencePassword')" :confirm-button-text="$t('go')" :cancel-button-text="$t('cancel')" show-actions persistent @close="onModalClose">
    <password
      type="password"
      v-model="password"
      name="conference-password"
      class="mb-3 mx-2"
      :placeholder="$t('password')"
      required
      single-line
      solo
      dense
      @keydown.enter.prevent="goToConference"
      :toggle="true"
      :badge="false"
      :showStrengthMeter="false"
      aria-required="true"
      autocomplete="off"
    />
    <div v-if="wrongPassword" class="mt-2">
      <v-alert dense outlined type="error">
        {{ $t('enteredIncorrectPassword') }}
      </v-alert>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import Password from 'vue-password-strength-meter';

export default {
  name: 'PasswordRequired',
  components: {
    Password,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    roomName: {
      type: [String, Number],
    },
  },
  data() {
    return {
      dialog: false,
      password: '',
      wrongPassword: this.$route.query?.p,
    };
  },

  methods: {
    ...mapActions('Conference', ['redirectKeycloakLoginPage']),
    goToConference() {
      if (this.password) {
        this.$router.push({ query: { p: btoa(this.password) } }).catch(() => {});
      }
      location.reload();
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.goToConference();
      } else {
        this.$emit('close', type);
      }
    },
  },
  watch: {
    value: {
      handler: function() {
        this.dialog = this.value;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
