export const TOAST_POSITION = {
  LEFT_TOP: 'leftTop',
  LEFT_CENTER: 'leftCenter',
  LEFT_BOTTOM: 'leftBottom',
  RIGHT_TOP: 'rightTop',
  RIGHT_CENTER: 'rightCenter',
  RIGHT_BOTTOM: 'rightBottom',
  CENTER_TOP: 'centerTop',
  CENTER_CENTER: 'centerCenter',
  CENTER_BOTTOM: 'centerBottom',
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  COPY: 'copy',
};

export const TOAST_DEFAULT_CONFIG = {
  timeout: 3000,
  type: TOAST_TYPE.INFO,
  closeOnClick: true,
  buttons: null,
  icon: null,
};

export default {
  TOAST_POSITION,
  TOAST_TYPE,
  TOAST_DEFAULT_CONFIG,
};
