var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avatar-uploader" },
    [
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "file",
        attrs: {
          type: "file",
          accept: "image/jpeg, image/png, image/gif, image/bmp",
        },
        on: { change: _vm.setFile },
      }),
      _c(
        "v-form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "confirm-popover",
            {
              attrs: {
                popoverClass: "avatar-popover",
                confirmText: "upload",
                cancelText: "remove",
              },
              on: { confirm: _vm.avatarUploader, cancel: _vm.clearAvatar },
            },
            [
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "avatar-uploader--list px-4" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "avatar-list" },
                      _vm._l(_vm.PREDEFINED_AVATARS, function (avatar, index) {
                        return _c(
                          "v-col",
                          {
                            key: index,
                            staticClass: "d-flex justify-center align-center",
                            attrs: { cols: "2", sm: "2", md: "2" },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "close-popover",
                                  rawName: "v-close-popover",
                                },
                              ],
                              staticClass: "avatar-images",
                              attrs: {
                                height: "35px",
                                width: "35px",
                                src: avatar,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setUserAvatar(avatar)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("Avatar", {
                staticClass: "avatar-icon",
                attrs: {
                  "avatar-size": 80,
                  loading: _vm.loading,
                  image: _vm.userAvatar,
                  "display-name": _vm.localDisplayName,
                  editable: "",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }