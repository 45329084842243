// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/checkerboard.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".color-bar[data-v-2e024d3a] {\n  display: grid;\n  grid-template-columns: repeat(9, 1fr);\n  grid-auto-rows: 1fr;\n  grid-gap: 8px;\n  padding: 1rem;\n}\n.color-bar .color-item[data-v-2e024d3a] {\n  border-radius: 8px;\n}\n.color-bar .color-item.selected[data-v-2e024d3a] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.color-bar .color-item.bgColor[data-v-2e024d3a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: cover;\n  background-color: rgba(189, 189, 189, 0.45);\n}\n.color-bar[data-v-2e024d3a]::before {\n  content: \"\";\n  width: 0;\n  padding-bottom: 100%;\n  grid-row: 1/1;\n  grid-column: 1/1;\n}\n.color-bar > *[data-v-2e024d3a]:first-child {\n  grid-row: 1/1;\n  grid-column: 1/1;\n}", ""]);
// Exports
module.exports = exports;
