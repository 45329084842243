<template>
  <div class="right-menu" :class="{ show: show }">
    <div class="right-menu--header">
      <div class="py-6 px-4 close-icon"><icon @click="$emit('close')" icon="x"></icon></div>
      <v-tabs :value="activeRightMenuTab" grow show-arrows icons-and-text>
        <v-tab href="#chat" @click.native="SET_CURRENT_RIGHT_MENU_TAB('chat')">
          <icon icon="message-circle"></icon>
          <span>{{ $t('chat') }}</span>
        </v-tab>
        <v-tab href="#user-list" @click="SET_CURRENT_RIGHT_MENU_TAB('user-list')">
          <icon icon="users"></icon>
          <span>{{ $t('userList') }}</span>
        </v-tab>
        <v-tab v-if="isSurveyUsable" href="#survey" @click.native="SET_CURRENT_RIGHT_MENU_TAB('survey')">
          <icon icon="check-circle"></icon>
          <span>{{ $t('survey') }}</span>
        </v-tab>
        <v-tab href="#shared-files" :ripple="false" @click.native="SET_CURRENT_RIGHT_MENU_TAB('shared-files')">
          <icon icon="folder"></icon>
          <span class="text-capitalize">
            {{ $t('sharedFiles') }}
          </span>
        </v-tab>
      </v-tabs>
    </div>

    <div>
      <v-tabs-items touchless :value="activeRightMenuTab" style="flex-grow: 1;">
        <v-tab-item value="chat">
          <Chat
            show-actions
            body-id="webinar-chat"
            :chat-enabled="isWebinarStarted"
            :messages="messages"
            :show-avatar="false"
            :show-file-share-button="fileShareSupported"
            @sendButtonClicked="onSendButtonClicked"
            @scrollEnd="onWebinarChatScrollEnd"
            @readMessages="READ_WEBINAR_MESSAGES"
          />
        </v-tab-item>
        <v-tab-item value="user-list">
          <WebinarUsers />
        </v-tab-item>
        <v-tab-item value="survey">
          <survey :isModerator="false" :isWebinarSurvey="true" />
        </v-tab-item>
        <v-tab-item value="shared-files">
          <shared-file-list is-webinar></shared-file-list>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import Icon from '@/components/Shared/Icon';
import WebinarUsers from '@/components/Webinar/WebinarUsers';
import Chat from '@/components/Chat/Chat';
import Survey from '@/components/Survey/SurveyHome';
import SharedFileList from '@/components/File/SharedFileList';

export default {
  name: 'WebinarRightMenu',
  components: { Chat, WebinarUsers, Icon, Survey, SharedFileList },
  props: {
    show: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('Conference', ['allAudioMuted', 'allVideoMuted', 'activeRightMenuTab']),
    ...mapState('Webinar', ['messages']),
    ...mapGetters('Conference', ['getUsers', 'getLocalUser']),
    ...mapGetters('Webinar', ['isWebinarStarted']),
    isSurveyUsable() {
      return this.$customerConfig?.system?.modules?.survey;
    },
    fileShareSupported() {
      return this.$customerConfig?.system?.modules?.fileShare && this.$can('see', 'FileShare') && this.roomConfig?.allowFileShare;
    },
  },
  methods: {
    ...mapMutations('Conference', ['SET_CURRENT_RIGHT_MENU_TAB']),
    ...mapMutations('Webinar', ['READ_WEBINAR_MESSAGES']),
    ...mapActions('Conference', ['setAudioMute', 'setVideoMute', 'setRemoteAudioMute', 'setRemoteVideoMute', 'setAllRemoteAudioMute', 'setAllRemoteVideoMute']),
    ...mapActions('Webinar', ['sendMessage']),
    onSendButtonClicked(message) {
      this.sendMessage({
        message: message,
      });
    },
    onWebinarChatScrollEnd() {
      this.READ_WEBINAR_MESSAGES();
    },
  },
};
</script>

<style scoped lang="scss">
.right-menu {
  animation-duration: 0.5s;
  animation-name: slideout;
  z-index: 11;
  background-color: var(--v-darkblue-base);

  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 370px;
  min-width: 320px;
  width: 100%;
  margin-right: -370px;
  transition: ease all 0.3s;
  grid-template-rows: auto 1fr;
  display: grid;
  height: 100vh;

  ::v-deep .v-slide-group__content {
    justify-content: center;
  }

  @media (max-width: $breakpoint-tablet) {
    & {
      width: 100%;
      max-width: 450px;
      margin-right: -450px;
      position: absolute;
    }
  }

  &--header {
    min-width: 370px;

    .close-icon {
      text-align: right;
      .v-icon {
        cursor: pointer;
      }
    }
  }

  ::v-deep .text {
    font-weight: 100;

    font-size: 17px;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }

  &.show {
    margin-right: 0;
  }

  .v-tab {
    max-width: 80px !important;
    @media (max-width: $breakpoint-tablet) {
      min-width: 80px;
    }
  }
}
</style>
