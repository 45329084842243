<template>
  <div v-if="!survey && surveys.length === 0 && !loading" class="survey-list-wrapper mt-5">
    <div class="mt-7 mb-6 text-center">
      {{ this.$t(emptyStateText) }}
    </div>
    <div class="d-flex align-center" v-if="isMySurveys">
      <v-btn text @click="addNewSurvey" :disabled="!getLocalUser.authenticated" class="mx-auto">
        <icon icon="plus-circle" class="mr-2" />
        <span>
          {{ $t('addSurvey') }}
        </span>
      </v-btn>
      <icon v-if="!getLocalUser.authenticated" icon="info" v-tooltip="$t('surveyCreateAuthInfo')" class="ml-2 info-icon flex-shrink-0" />
    </div>
  </div>
  <div class="survey-list" v-else>
    <div v-if="loading">
      <v-row justify="center" style="height: 100px; width: 100%">
        <v-progress-circular indeterminate color="black"></v-progress-circular>
      </v-row>
    </div>
    <div v-else-if="surveys.length > 0 && !survey">
      <v-btn text @click="addNewSurvey" v-if="isMySurveys" class="survey-list-wrapper--add-survey">
        <icon icon="plus-circle" class="mr-2"></icon>
        <span>
          {{ $t('addSurvey') }}
        </span>
      </v-btn>

      <survey-list-item
        v-for="survey in surveys"
        :key="survey.Id"
        :data="survey"
        :isMySurveys="isMySurveys"
        :isSharedSurvey="isSharedSurvey"
        @edit="edit"
        @goToDetail="goToDetail"
        @goToSurvey="goToSurvey"
        @goToResult="goToResult"
        @openConfirm="openConfirm"
      />
    </div>
    <div v-else-if="survey">
      <div class="d-flex align-center justify-space-between ">
        <span class="text-body-2">{{ $t(title) }}</span>
        <div class="d-flex align-center">
          <div class="d-flex align-center justify-center mr-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium color="primary" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('privateSurveyExplanation') }}</span>
            </v-tooltip>
          </div>
          <v-switch color="secondary" v-model="survey.IsPrivate" :label="$t('privateSurvey')"></v-switch>
        </div>
      </div>
      <text-field class="mb-5" hide-details v-model="survey.Title" :label="$t('surveyTitle')"></text-field>
      <v-alert v-if="maxCharError" type="error" icon="feather icon-info">
        {{ $t('maxChar', { char: '200' }) }}
      </v-alert>
      <v-row>
        <v-col sm="12" md="6">
          <v-btn block outlined @click="survey = null">{{ $t('cancel') }}</v-btn>
        </v-col>
        <v-col sm="12" md="6">
          <v-btn block color="secondary" @click="save" :disabled="!survey.Title" :loading="loading">{{ $t('save') }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SurveyListItem from '@/components/Survey/SurveyListItem';
import Icon from '@/components/Shared/Icon';
import { consoleError } from '@/helpers/logger';

export default {
  name: 'SurveyList',
  components: {
    Icon,
    SurveyListItem,
  },
  props: {
    surveys: Array,
    isMySurveys: Boolean,
    isSharedSurvey: Boolean,
  },
  data() {
    return {
      survey: null,
      loading: false,
      confirmObject: null,
      maxCharError: false,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser']),
    emptyStateText() {
      if (this.isMySurveys) {
        return 'emptySurvetList';
      }

      return 'noData';
    },
    title() {
      return this.survey?.Id ? 'editSurvey' : 'addSurvey';
    },
    defaultSurveyObject() {
      return {
        Title: '',
        IsPrivate: true,
        ShareResults: false,
      };
    },
    isModerator() {
      return this?.getLocalUser?.role === 'moderator';
    },
  },
  methods: {
    ...mapActions('Survey', ['updateSurvey', 'addSurvey', 'deleteSurvey']),
    addNewSurvey() {
      this.survey = { ...this.defaultSurveyObject };
    },
    goToDetail(survey) {
      this.$emit('goToDetail', survey);
    },
    goToSurvey(survey) {
      this.$emit('goToSurvey', survey);
    },
    goToResult(survey) {
      const data = { survey, isSharedResult: this.isSharedSurvey };
      this.$emit('goToResult', data);
    },
    save() {
      this.loading = true;
      this.maxCharError = false;
      const method = this.survey.Id ? 'updateSurvey' : 'addSurvey';
      this[method]({
        ...this.survey,
        IsDeleted: false,
      })
        .then(() => {
          this.survey = null;
          this.loading = false;
          this.$emit('getSurveys');
        })
        .catch(err => {
          this.loading = false;
          //TODO Burak EP'yi değiştirince kontrol değişecek
          if (err?.response?.data?.Message?.includes("The length of 'Title' must be 200 characters or fewer")) {
            this.maxCharError = true;
          }
          consoleError(err);
        });
    },
    edit(survey) {
      this.survey = survey;
    },
    openConfirm(confirmObject) {
      this.$emit('openConfirm', confirmObject);
    },
  },
};
</script>

<style lang="scss">
.survey-list-wrapper {
  position: relative;

  &--empty-text {
    font-weight: 300;
    font-size: 12px;
  }

  &--add-survey {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}
.survey-list {
  height: calc(100% - 80px);
}
</style>
