var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "survey-detail mt-5" },
    [
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "question-list" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "question-list--add-question",
                    attrs: { text: "" },
                    on: { click: _vm.addQuestion },
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "plus-circle" },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("addQuestion")) + " "),
                  ],
                  1
                ),
                _c("div", { staticClass: "ellipsis mb-2" }, [
                  _vm._v(_vm._s(_vm.survey.Title)),
                ]),
                _vm._l(_vm.questions, function (q, i) {
                  return _c(
                    "div",
                    {
                      key: q.id,
                      staticClass:
                        "question-list--item d-flex flex-column px-3 py-2 mb-2",
                    },
                    [
                      _c("div", { staticClass: "question-list--item--index" }, [
                        _vm._v(_vm._s(`${_vm.$t("question")} ${i + 1}`)),
                      ]),
                      _c("div", { staticClass: "question-list--item--text" }, [
                        _vm._v(_vm._s(q.Questions)),
                      ]),
                      _vm.loading
                        ? _c("v-progress-linear", {
                            staticClass: "my-2",
                            attrs: { indeterminate: "", color: "secondary" },
                          })
                        : _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3",
                                  attrs: { icon: "", "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteQuestion(q.Id)
                                    },
                                  },
                                },
                                [
                                  _c("icon", {
                                    attrs: { size: "14", icon: "trash" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3",
                                  attrs: { icon: "", "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editQuestion(q)
                                    },
                                  },
                                },
                                [
                                  _c("icon", {
                                    attrs: { size: "14", icon: "edit" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                }),
                _c(
                  "v-row",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12", md: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { block: "", outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("cancel")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "12", md: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              color: "secondary",
                              disabled:
                                !_vm.survey.Title || _vm.disableShareButton,
                              loading: _vm.loading,
                            },
                            on: { click: _vm.publish },
                          },
                          [_vm._v(_vm._s(_vm.$t("publish")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
          _c(
            "v-tab-item",
            [
              _c("SurveyQuestionCreateNUpdate", {
                attrs: {
                  "edit-model": _vm.selectedQuestion,
                  survey: _vm.survey,
                },
                on: {
                  cancel: function ($event) {
                    return _vm.setTab(0)
                  },
                  onSaveQuestion: _vm.getQuestions,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }