var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.leftBottomLogo
    ? _c("div", { staticClass: "logoLeftBottom" }, [
        _c("img", { attrs: { src: _vm.leftBottomLogo } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }