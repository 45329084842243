import axios from 'axios';

const config = {
  baseURL: window.XPER_CONFIG?.manageService,
  headers: {
    'content-type': 'application/json',
  },
};

class ManageService {
  constructor() {
    this.api = axios.create(config);

    this.api.interceptors.request.use(config => {
      const token = localStorage.getItem('keycloak-token');

      if (token) {
        config.headers.set('Authorization', `Bearer ${token}`);
      }

      return config;
    });
  }

  async fetchUserInfo() {
    return this.api.get('/user');
  }

  async fetchMeetingSummary(roomName) {
    return this.api.get(`/meeting/config/${roomName}`);
  }

  async uploadUserAvatar(image) {
    return this.api.post(`/upload/user/avatar`, image);
  }

  async deleteUserAvatar() {
    return this.api.delete(`/delete/user/avatar`);
  }

  sendFileShareAnalyticData(payload) {
    return this.api.post(`/meetingfileshare`, payload);
  }

  setAndUpdateWebinarTitle(payload) {
    return this.api.put(`/livewebinar/changeTitle`, payload);
  }

  getWebinarTitle(webinarTitle) {
    return this.api.get(`/webinarInfo/${webinarTitle}`);
  }

  fetchRoomOwnerInfo(roomName) {
    return this.api.get(`/private/roomName/${roomName}`);
  }

  sendNotificationToRoomOwner(roomName) {
    return this.api.post('/private/notificationsms', { room_name: roomName });
  }
}

export default new ManageService();
