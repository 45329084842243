import Vue from 'vue';

const baseUrl = 'https://bizbize-client.s3.eu-west-1.amazonaws.com';
let domainName = window.location.hostname;
if (window.location.hostname === 'localhost' || window.location.hostname === 'dev-api.bizbize.live') {
  domainName = 'dev-api.bizbize.live';
}
const configPath = window.USE_CLOUD_CONFIG ? `${baseUrl}/${domainName}` : '';

function fetchXperConfig() {
  return new Promise((resolve, reject) => {
    fetch(`${configPath}/xper_config.json`)
      .then((res) => res.json())
      .then((res) => {
        window.XPER_CONFIG = res;
        resolve();
      })
      .catch(reject);
  });
}

function fetchCustomerConfig() {
  return new Promise((resolve, reject) => {
    fetch(`${configPath}/customer_config.json`)
      .then((res) => res.json())
      .then((res) => {
        window.customerConfig = res;
        Vue.prototype.$customerConfig = res;

        const favicon = document.getElementById('favicon');
        if (window.customerConfig?.resources?.favicon) {
          favicon.href = window.customerConfig?.resources?.favicon;
        }

        resolve();
      })
      .catch(reject);
  });
}

export function fetchConfigs() {
  const xperPromise = fetchXperConfig();
  const customerPromise = fetchCustomerConfig();

  return new Promise((resolve, reject) => {
    Promise.all([xperPromise, customerPromise])
      .then(() => {
        document.getElementById('loader').style.opacity = '0';
        setTimeout(() => {
          document.getElementById('loader').style.display = 'none';
        }, 500);

        resolve();
      })
      .catch((err) => {
        document.getElementById('loader').style.display = 'none';
        document.getElementById('config-failed').style.display = 'flex';
        reject(err);
      });
  });
}

export function getServerConfig() {
  const xperConfig = window.XPER_CONFIG;

  if (xperConfig.isKeycloakActive) {
    return xperConfig.serverConfig.keycloak;
  } else {
    return xperConfig.serverConfig.withoutKeycloak;
  }
}
