<template>
  <div class="private-room-entrance">
    <div class="private-room-entrance--header px-5 py-4 px-md-15 py-md-8">
      <router-link to="/">
        <Logo />
      </router-link>
      <LangSwitch />
    </div>
    <v-progress-circular v-if="loading" indeterminate color="white" :size="14" :width="2"></v-progress-circular>
    <div v-else-if="errorText" class="d-flex flex-column justify-center">
      <v-alert color="amber" border="bottom" outlined shaped class="mb-5">{{ errorText }}</v-alert>
      <v-btn color="secondary" to="/">{{ $t('goToHome') }}</v-btn>
    </div>
    <div v-else class="private-room-entrance--content">
      <icon v-if="pageTypeIsJoin" icon="blue-t" size="34" class="icon" />
      <div class="title" :style="titleStyle">{{ title }}</div>
      <div v-if="subTitle" class="sub-title">{{ subTitle }}</div>
      <v-btn color="#29847e" class="private-room-entrance--content--button" :style="buttonStyle" @click="primaryBtnClicked">
        <icon v-if="!roomOwnerInfo" class="icon" icon="white-t" size="24" />
        <div>{{ primaryActionText }}</div>
      </v-btn>
      <div class="private-room-entrance--content-separator">
        <span>{{ $t('or') }}</span>
      </div>
      <v-btn color="secondary" class="private-room-entrance--content--button" :style="buttonStyle" @click="secondaryBtnClicked">
        <icon v-if="!roomOwnerInfo" class="icon" icon="plus-circle" size="24" />
        <div>{{ secondaryActionText }}</div>
      </v-btn>
    </div>
    <div class="private-room-entrance--footer">
      <div v-if="pageTypeIsJoin || errorText">
        <span v-html="$t('clickForSetupYourPrivateRoomName', { url: setupPrivateRoomUrl })"></span>
        <span v-html="$t('clickIfYouDontHaveLicence', { url: buyLicenceUrl })"></span>
      </div>
      <span v-else v-html="$t('clickForSettingsYourPrivateRoomName', { url: setupPrivateRoomUrl })"></span>
    </div>
    <JoinMeetingFromMobileModal v-if="showMobileJoinModal" :room-name="roomName" @close="disableMobileJoinPage = true" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import XperMeetLib from 'xpermeet-lib';

import JoinMeetingFromMobileModal from '@/components/Shared/Modal/JoinMeetingFromMobileModal';
import LangSwitch from '@/components/Shared/LangSwitch';
import { sendGaEvent } from '@/helpers/gtag';
import { setSkipPrivateRoomEntrance } from '@/helpers/private-room';
import { consoleError, consoleLog } from '@/helpers/logger';
import { isMobile } from '@/helpers/os';
import { TOAST_TYPE } from '@/constants/toast';

const JOIN_PAGE = 'join';
const CREATE_PAGE = 'create';
const NOTIFICATION_SPAM_ERROR_CODE = 'ERR_STHO';

export default {
  name: 'CreatePrivateRoom',
  components: {
    LangSwitch,
    JoinMeetingFromMobileModal,
  },
  props: {
    roomName: {
      type: String,
    },
    pageType: {
      type: String,
      default: CREATE_PAGE,
    },
  },
  data: () => ({
    disableMobileJoinPage: false,
    loading: true,
    roomOwnerInfo: null,
    user: null,
  }),
  computed: {
    errorText() {
      if (this.isUserOwnerInfoNotFound) {
        return this.$t('errorMessages.privateRoomNotFound', { roomName: this.roomName });
      }

      if (this.isCurrentUserpricateRoomNotFound) {
        return this.$t('errorMessages.youDontHaveAPrivateRoom');
      }

      return null;
    },
    isUserOwnerInfoNotFound() {
      return this.roomName && !this.roomOwnerInfo;
    },
    isCurrentUserpricateRoomNotFound() {
      return !this.roomName && !this.currentUserRoomName;
    },
    buyLicenceUrl() {
      let pricePath = this.$i18n.locale === 'tr' ? 'fiyatlandirma' : 'en/pricing';
      return `${process.env.VUE_APP_BIZBIZE_WEB_URL}/${pricePath}`;
    },
    setupPrivateRoomUrl() {
      // FIXME: update here when url is defined on manage
      return `${window.XPER_CONFIG.manageUrl}/personal-room`;
    },
    pageTypeIsJoin() {
      return this.pageType === JOIN_PAGE;
    },
    isMyPrivateRoom() {
      return this.roomName === this.currentUserRoomName;
    },
    currentUserRoomName() {
      return this.user?.private_room_name;
    },
    title() {
      if (this.roomOwnerInfo?.fullName) {
        return this.$t('privateRoomFor', { roomOwnerName: this.roomOwnerInfo.fullName });
      }

      return this.$t('createAConference');
    },
    subTitle() {
      if (this.pageTypeIsJoin) {
        return this.$t('privateRoomEntranceSubtitle');
      }

      return null;
    },
    primaryActionText() {
      if (this.pageTypeIsJoin) {
        return this.$t('send');
      }

      return `${window.location.hostname}/${this.currentUserRoomName}`;
    },
    secondaryActionText() {
      if (this.pageTypeIsJoin) {
        return this.$t('leaveTheRoom');
      }

      return this.$t('openAnyRoom');
    },
    titleStyle() {
      if (this.pageTypeIsJoin) {
        return {
          'margin-bottom': '8px',
        };
      }

      return {
        'margin-bottom': '42px',
      };
    },
    buttonStyle() {
      if (this.pageTypeIsJoin) {
        return {
          'min-width': '250px!important',
        };
      }

      return {
        'min-width': '325px!important',
      };
    },
    showMobileJoinModal() {
      return this.roomName && isMobile() && !this.disableMobileJoinPage && !this.pageTypeIsJoin;
    },
  },
  created() {
    this.fetchInitialData();
  },
  methods: {
    ...mapActions('Conference', ['generateRoomName', 'fetchRoomOwnerInfo', 'fetchUserInfo', 'sendNotificationToRoomOwner']),
    ...mapActions('Notification', ['showToastNotification']),
    async fetchInitialData() {
      try {
        this.roomOwnerInfo = this.roomName && (await this.fetchRoomOwnerInfo({ roomName: this.roomName }));
        this.user = await this.fetchUserInfo();
      } catch (err) {
        consoleError('err', err);
      } finally {
        this.loading = false;
      }
    },
    primaryBtnClicked() {
      if (this.pageTypeIsJoin) {
        this.sendNotifyToOwnerAndGoToMeeting();
      } else {
        this.openRoom(this.currentUserRoomName);
      }
    },
    secondaryBtnClicked() {
      if (this.pageTypeIsJoin) {
        this.$router.replace({ name: 'home' });
      } else {
        this.openRoom();
      }
    },
    async sendNotifyToOwnerAndGoToMeeting() {
      try {
        consoleLog('Notify sent for room: ', this.roomName);
        await this.sendNotificationToRoomOwner(this.roomName);
        setSkipPrivateRoomEntrance(this.roomName);
        this.$emit('ok');
      } catch (err) {
        const errorCode = err?.response?.data?.error_code;
        let message = 'error';

        if (errorCode === NOTIFICATION_SPAM_ERROR_CODE) {
          message = 'errorMessages.notificationSpam';
        }

        this.showToastNotification({ body: message, config: { type: TOAST_TYPE.ERROR } });
      }
    },
    async openRoom(privateRoomName) {
      sendGaEvent('konferans oluştur', 'meet-bizbize');
      let roomName = privateRoomName;

      if (!roomName) {
        const response = await this.generateRoomName();
        roomName = response.data.room;
      }

      const auth = XperMeetLib.keycloakManager.keycloakInstance.authenticated;
      const settings = { create: true };

      setSkipPrivateRoomEntrance(roomName);

      if (auth) {
        const route = {
          name: 'meet',
          params: {
            roomName,
          },
          query: settings,
        };

        return this.$router.push(route).catch(() => {});
      } else {
        this.redirectLoginPage(roomName, settings);
      }
    },
  },
  watch: {
    isMyPrivateRoom(val) {
      if (val) {
        this.openRoom(this.currentUserRoomName);
      }
    },
  },
};
</script>

<style>
.private-room-entrance {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: var(--v-popup-color-base);
}

.private-room-entrance--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 70px;
}

.private-room-entrance--content {
  width: 380px;
  text-align: center;
}

.private-room-entrance--content > .icon {
  margin-bottom: 17px;
}

.private-room-entrance--content > .title {
  font-size: 22px;
  font-weight: 600;
}

.private-room-entrance--content > .sub-title {
  font-size: 14px;
  margin-bottom: 25px;
}

.private-room-entrance--content--button {
  position: relative;
  padding: 0 2.5rem !important;
}

.private-room-entrance--content-separator {
  margin: 20px 0;
  position: relative;
  max-width: 500px;
  text-align: center;
  font-size: 12px;
}

.private-room-entrance--content-separator::before {
  content: ' ';
  display: block;
  height: 2px;
  border-radius: 8px;
  width: 146px;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--v-body-base);
}

.private-room-entrance--content-separator::after {
  content: ' ';
  display: block;
  border-radius: 8px;
  height: 2px;
  width: 146px;
  position: absolute;
  top: 50%;
  right: 0;
  background: var(--v-body-base);
}

.private-room-entrance--content--button .icon {
  position: absolute;
  left: -2rem;
}

.private-room-entrance--footer {
  text-align: center;
  margin-top: 40px;
}

.private-room-entrance--footer span {
  display: block;
  font-size: 14px;
  line-height: 27px;
}

.private-room-entrance--footer a {
  color: var(--v-link-base) !important;
}
</style>
