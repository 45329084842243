//eslint-disable-next-line
import { consoleError, consoleLog } from "../utils/logger";
import PasswerksServices from "../services";
import request from "../services/Request";
class Passwerks {
  constructor(conference) {
    consoleLog("Passwerks Initialized...");
    this.passwerksServices = new PasswerksServices(conference);
    // passwerksServices.register("bekirkaplan");
    this.conference = conference;
    this.initialized = false;
    this._pk = null;
  }

  /**
   * XpermeetWeb client sets their own master password
   * this parameter created on the fly and only available from user session
   * !Important we can not hold masterPassword any storage like localstorage
   * we set it from ui -> settings -> profile settings
   * @param masterPassword
   */
  init(masterPassword, msgCallback) {
    let activateE2e = (passwordChecker) => {
      passwordChecker.then((isPasswordCorrect) => {
        // if correct initialize jitsi meet lib for meet e2ee
        if (isPasswordCorrect) {
          // set master password to jitsi-lib conference object
          this.conference.room.setLocalParticipantMasterPassword(masterPassword); // smart card adapter de yok

          // public key function setted this function calls from jitsi-lib to reach passwerks services from meet-lib
          this.conference.room.setPublicKeyFunction(this.passwerksServices.getPublicKeyCustomer());

          // set pk to jitis-lib conference object
          // smart card adapter de yok bunun yerine conerence room da
          // set decryption function 'setDecryptionFunction'
          this.conference.room.setLocalParticipantPrivateKey(this._pk);

          // set room property e2eeEnabled to true
          // when participants enter the conference (room) they gets this parameter from room object
          // and they will prompted by e2eePromptModal
          msgCallback("encryptionSuccess", "success"); // return true to meet-lib
        } else {
          msgCallback("encryptionError", "error");
        }
      });
    };

    if (!this._pk) {
      this.passwerksServices.getPrivateKeyCustomer(async (isSuccess, pk) => {
        /* passwerks_e2ee */

        if (!isSuccess) {
          msgCallback(pk, "error");
          return;
        }
        // check master password with pk
        this._pk = pk;
        let isPasswordCorrect = this.conference.room.checkMasterPassword(this._pk, masterPassword, msgCallback);
        activateE2e(isPasswordCorrect);
      });
    } else {
      let isPasswordCorrect = this.conference.room.checkMasterPassword(this._pk, masterPassword, msgCallback);
      activateE2e(isPasswordCorrect);
    }
  }

  smartCardInit(customerId, msgCallback) {

    this.conference.room.setPublicKeyFunction(this.passwerksServices.getPublicKeyCustomer());
    this.conference.room.setDecryptionFunction((cipher) => {
      // 127.0.0.1 e cutomer Id ile gonder digim yere gonderecem

      let urlc = `http://127.0.0.1:23658/cipher/decrypt`;
      let bodyc = {
        customerId: customerId,
        cipher: cipher,
      };

      return new Promise((resolve) => {
        request.post(
          urlc,
          bodyc,
          {
            then: (data) => {
              // data.result rsa utile gonderdigim encrypted text olmasını bekliyorum
              if (data.status === "OK") {
                resolve(data.result);
              } else {
                resolve(null);
                // refresh page
              }
            },
            error: (e) => {
              resolve(null);
              console.log(e);
              // refresh page
            },
          },
          "json",
          20000,
        );
      });
    });

    msgCallback('encryptionSuccess', 'success');
  }

  chipperTest(customerId, callback) {
    this.passwerksServices.chipper(customerId, callback);
  }

  register(masterPasword, callback) {
    this.passwerksServices.register(masterPasword, callback);
  }
  /**
   *
   * @returns {*}
   * @public
   */
  static isE2EEEnabled() {
    return this.conference.room._isE2EEEnabled();
  }
}

export default Passwerks;
