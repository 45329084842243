var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user",
      class: {
        me: _vm.me,
        moderator: _vm.moderator,
        participant: _vm.participant,
      },
    },
    [
      _c("avatar", {
        staticStyle: { "--card-width": "200px" },
        attrs: { image: _vm.avatar, "display-name": _vm.displayName },
      }),
      _c(
        "div",
        { staticClass: "user-display-name", attrs: { title: _vm.displayName } },
        [_vm._v(_vm._s(_vm.displayName))]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }