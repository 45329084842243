class EventEmitter {
  constructor() {
    this.callbacks = {};
  }

  on(event, cb) {
    if (!this.callbacks[event]) this.callbacks[event] = [];
    this.callbacks[event].push(cb);
  }

  off(event, cb) {
    if ("event" in this.callbacks) {
      if (cb) {
        for (let i = 0; i < this.callbacks[event].length; i++) {
          if (this.callbacks[event][i] === cb) {
            this.callbacks[event].splice(i, 1);
          }
        }
      } else {
        delete this.callbacks[event];
      }
    }
  }

  emit(event, data) {
    let cbs = this.callbacks[event];
    if (cbs) {
      cbs.forEach((cb) => cb(data));
    }
  }

  clear() {
    this.callbacks = {};
  }
}

export default EventEmitter;
