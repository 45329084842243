var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-left": 300,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "menu-button",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-1 notification-top-button",
                      class: _vm.notificationWrapperClass,
                      attrs: {
                        id: "notification-top-button",
                        icon: "bell",
                        "icon-size": "20",
                      },
                    },
                    "menu-button",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c("div", { staticClass: "notification-wrapper py-3" }, [
        _c(
          "div",
          { staticClass: "head d-flex justify-space-between px-4" },
          [
            _c("div", { staticClass: "head-title" }, [
              _vm._v(_vm._s(_vm.$t("notifications.title"))),
            ]),
            _c("Icon", {
              staticClass: "cursor-pointer",
              attrs: { color: "white", icon: "x", size: "12" },
              on: { click: _vm.hideNotificationsMenu },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "content",
            class: {
              "mt-2": _vm.roomNotifications.length,
              "mt-8": !_vm.roomNotifications.length,
            },
          },
          [
            _vm.roomNotifications.length
              ? _c("Notifications")
              : _c(
                  "div",
                  {
                    staticClass:
                      "px-4 pb-4 d-flex flex-column align-center text-center",
                  },
                  [
                    _c("Icon", {
                      staticClass: "mb-3",
                      attrs: { color: "white", icon: "bell", size: "32" },
                    }),
                    _c("span", { staticClass: "no-notification-text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("notifications.noNotificationsYet"))
                      ),
                    ]),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }