import JitsiMeetJS from "../JitsiMeetLib";
import ConnectionConfig from "./ConnectionConfig";
import MediaDevices from "./MediaDevices";
import EventEmitter from "../utils/EventEmitter";
import { consoleError, consoleLog } from "../utils/logger";
import { ErrorWithCodes } from "../errors/errors";
const eventEmitter = new EventEmitter();

class ConnectionManager {
  constructor() {
    this.connection = null;
    this.appId = undefined;
    this.connHasListeners = false;
  }

  createConnection({ config, roomName, id, password, joinAsGuest }) {
    const connectionConfig = JSON.parse(JSON.stringify(config));

    const authenticatorToken = (() => {
      if (joinAsGuest) {
        return null;
      }

      return localStorage.getItem("event-token") || localStorage.getItem("authenticator-token");
    })();
    const usernameOverride = localStorage.getItem("xmpp_username_override");
    const passwordOverride = localStorage.getItem("xmpp_password_override");

    if (usernameOverride && usernameOverride.length > 0) {
      id = usernameOverride;
    }
    if (passwordOverride && passwordOverride.length > 0) {
      password = passwordOverride;
    }

    connectionConfig.serviceUrl = connectionConfig.bosh + "?room=" + roomName;

    if (authenticatorToken) {
      connectionConfig.serviceUrl += "&token=" + authenticatorToken;
      // TODO incele neden siliniyor?
      localStorage.removeItem("authenticator-token");
    }

    // auth token her durumda silinmezse odada bekleyen misafir
    // kullanıcı host geldiğinde lobye düşmüyor
    localStorage.removeItem("authenticator-token");

    try {
      JitsiMeetJS.init(ConnectionConfig(connectionConfig));
      this.connection = new JitsiMeetJS.JitsiConnection(this.appId, authenticatorToken, ConnectionConfig(connectionConfig));
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
      this.connection.connect({ id, password });
      consoleLog("connection successful");
    } catch (e) {
      consoleError(ErrorWithCodes.CONNECTION_FAILED, e);
    }

    // TODO bak
    // this.dominantUserId = null;

    this.addListeners();

    if (!this.connHasListeners) {
      MediaDevices.init();
      this.connHasListeners = true;
    }
    if (typeof window !== "undefined") {
      window.onbeforeunload = () => {
        this.disconnect();
        this.connection.disconnect();
      };
    }
  }

  disconnect() {
    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);
    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);
    // this.connection.removeEventListener(JitsiMeetJS.lib.events.conference.AUTH_STATUS_CHANGED, xperUser.authStatusChanged);
    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect);
    this.connection.removeEventListener(JitsiMeetJS.events.connection.PASSWORD_REQUIRED, this.onPasswordRequired);
  }

  onConnectionSuccess() {
    eventEmitter.emit("ConnectionSuccess");
  }
  onConnectionFailed(...args) {
    eventEmitter.emit("ConnectionFailed", args);
  }

  addListeners() {
    // this.connection.disconnect();

    this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);
    this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);
    //this.connection.addEventListener(JitsiMeetJS.events.conference.AUTH_STATUS_CHANGED, xperUser.authStatusChanged);
    this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect);
    this.connection.addEventListener(JitsiMeetJS.errors.conference.PASSWORD_REQUIRED, this.onPasswordRequired);
  }

  onPasswordRequired() {
    consoleError("onPasswordRequired");
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }

  clearEvents() {
    eventEmitter.clear();
  }
}

export default new ConnectionManager();
