<template>
  <div v-show="false" class="audio-container">
    <audio v-if="getLocalUser" muted class="local-audio" :id="`local-audio-${getLocalUser.id}`" />
    <template v-for="userId in remoteUserIds">
      <audio :key="userId" autoplay class="remote-audio" :id="`remote-audio-${userId}`" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';

export default {
  name: 'UsersAudioContainer',
  data() {
    return {
      remoteUserIds: [],
    };
  },
  computed: {
    ...mapGetters('Conference', ['getUsers', 'getLocalUser']),
  },
  created() {
    XperMeetLib.conference.on('RemoteUserJoined', remoteUser => {
      this.remoteUserIds.push(remoteUser.id);
    });
    XperMeetLib.conference.on('RemoteUserLeft', remoteUserId => {
      const index = this.remoteUserIds.indexOf(remoteUserId);
      if (index > -1) {
        this.remoteUserIds.splice(index, 1);
      }
    });
  },
};
</script>
