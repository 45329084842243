<template>
  <div>
    <v-list color="primary" @mouseover.native="onMouseOver" @mouseleave.native="onMouseLeave">
      <template>
        <v-list-item v-if="item.type === 'text'" class="list-type-text" :class="{ [item.class]: item.class }">
          <div>{{ item.label }}</div>
        </v-list-item>
        <div v-else-if="item.type === 'divider'" class="list-type-divider"></div>
        <v-list-item
          v-else-if="!item.notRender && !item.confirmPopover"
          dense
          v-bind:disabled="item.disabled"
          :class="{ active: item.selected, disabled: item.disabled, [item.class]: item.class, 'has-child ': item.childs }"
          :data-test-id="item.id ? item.id : undefined"
          @click.stop="$emit('onChildItemClicked', item)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <icon :icon="item.icon" v-if="!!item.icon && !item.childs" width="20" height="20" :color="item.iconColor" class="ml-4 mr-2" />
              <v-menu v-if="hasChildren" open-on-hover top offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <div class="menu-button-child-item pr-2 w-full d-flex align-center justify-space-between" v-bind="attrs" v-on="on">
                    <div class="d-flex align-center">
                      <icon :icon="item.icon" v-if="!!item.icon" :color="item.iconColor" width="20" height="20" class="ml-4 mr-2" />
                      {{ !item.doNotTranslate ? $t(item.label) : item.label }}
                    </div>
                    <icon icon="chevron-right" color="white" width="20" height="20" class="ml-2" />
                  </div>
                </template>
                <menu-button-list
                  v-for="(child, k) in item.childs"
                  :key="k"
                  :index="k"
                  :item="child"
                  @onMouseOver="onMouseOver"
                  @onMouseLeave="onMouseLeave"
                  @onChildItemClicked="$emit('onChildItemClicked', child)"
                />
              </v-menu>
              <div v-else class="w-full d-flex justify-space-between align-center mr-4">
                <div>
                  {{ !item.doNotTranslate ? $t(item.label) : item.label }}
                </div>
                <icon v-if="item.active" class="ml-2" icon="check"></icon>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <confirm-popover
          v-else-if="!item.notRender && item.confirmPopover"
          @confirm="$emit('onChildItemClicked', item)"
          confirmText="yes"
          cancelText="no"
          description="areYouSure"
          :key="index"
          class="menu-button-popover"
        >
          <v-list-item dense v-bind:disabled="item.disabled" :class="{ active: item.selected, disabled: item.disabled, [item.class]: item.class }">
            <v-list-item-content>
              <v-list-item-title>
                <icon :icon="item.icon" v-if="!!item.icon" class="mr-2" />
                {{ !item.doNotTranslate ? $t(item.label) : item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </confirm-popover>
      </template>
    </v-list>
  </div>
</template>
<script>
import ConfirmPopover from '@/components/Shared/ConfirmPopover';

export default {
  name: 'MenuButtonList',
  components: {
    ConfirmPopover,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    hasChildren() {
      const { childs } = this.item;
      return childs && childs.length > 0;
    },
  },
  methods: {
    onMouseOver() {
      this.$emit('onMouseOver', '2');
    },
    onMouseLeave() {
      this.$emit('onMouseLeave', null);
    },
  },
};
</script>
