var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("themeSettings"),
        "confirm-button-text": _vm.$t("confirm"),
        "cancel-button-text": _vm.$t("cancel"),
        showActions: "",
        "confirm-button-disabled": _vm.loading,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "layout-settings" },
        [
          _c("div", { staticClass: "text-center text-body-2 mb-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("layout.description_1")) + " "),
          ]),
          _vm.$can("setLayoutSettings", "Meet")
            ? _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("layoutSettings")) + ":")]),
                _vm.disableLayoutChange
                  ? _c("span", { staticClass: "text-caption" }, [
                      _vm._v(_vm._s(" " + `(${_vm.$t("lockedByModerator")})`)),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex mb-2" },
            [
              _c(
                "v-card",
                {
                  staticClass: "layout-selector py-2 px-4 my-2",
                  attrs: { color: "primary", elevation: "0" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-wrap mt-n3",
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-3 mt-3",
                        attrs: { icon: "grid", size: "medium" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center text-caption mt-3",
                        },
                        [
                          _c(
                            "label",
                            {
                              class: {
                                "opacity-50": _vm.layoutType === "dynamic",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("layout.grid")))]
                          ),
                          _c(
                            "div",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 pt-0 ml-3 mr-0",
                                attrs: {
                                  "input-value": _vm.isLayoutDynamic,
                                  "hide-details": "",
                                  color: "line",
                                  disabled: _vm.disableLayoutChange,
                                },
                                on: { change: _vm.onLayoutSwitchChanged },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "label",
                            {
                              class: {
                                "opacity-50": _vm.layoutType === "grid",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("layout.dynamic")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.layoutType === "dynamic"
            ? _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("layout.layoutSelection")) + ":"),
                ]),
                _vm.disablePageSizeChange
                  ? _c("span", { staticClass: "text-caption" }, [
                      _vm._v(_vm._s(" " + `(${_vm.$t("lockedByModerator")})`)),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "layout-selector py-2 px-4 my-2",
                        attrs: { color: "primary", elevation: "0" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex page-sizes",
                            class: { disabled: _vm.disablePageSizeChange },
                          },
                          _vm._l(_vm.pageSizes, function (pageSize) {
                            return _c(
                              "div",
                              {
                                key: pageSize,
                                staticClass: "px-1 mx-1 cursor-pointer",
                                class: {
                                  selected: pageSize === _vm.selectedPageSize,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedPageSize = pageSize
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(pageSize) + " ")]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _vm.$can("setBackground", "Meet") && !_vm.configHasBg
                ? [
                    _c("BackgroundSelector", {
                      attrs: {
                        disabled: _vm.disableBackgroundChange,
                        "show-title": true,
                      },
                      on: { setLoading: _vm.setLoading },
                    }),
                  ]
                : _vm._e(),
              _vm.isModerator
                ? [
                    _c("div", { staticClass: "subtitle-2 mt-4" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("layout.applyInteractionsToParticipants")
                        ) + ":"
                      ),
                    ]),
                    _vm._l(_vm.layoutShareItems, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.name,
                          staticClass:
                            "d-flex justify-space-between align-center my-2",
                        },
                        [
                          _vm._v(" " + _vm._s(item.text) + " "),
                          _c("v-switch", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              "data-test-id": `M_LS_${item.testId}`,
                              color: "success",
                              "hide-details": "",
                            },
                            model: {
                              value: item.shared,
                              callback: function ($$v) {
                                _vm.$set(item, "shared", $$v)
                              },
                              expression: "item.shared",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }