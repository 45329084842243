var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("shareVideoTitle"),
        "confirm-button-text": _vm.$t("share"),
        "cancel-button-text": _vm.$t("cancel"),
        "show-actions": "",
        "confirm-button-disabled": _vm.isDisabled,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "text-center mb-7" }, [
        _vm._v(" " + _vm._s(_vm.$t("shareVideoDescription")) + " "),
      ]),
      _c(
        "div",
        [
          _c("TextField", {
            attrs: {
              "error-messages": _vm.$t(_vm.errorText),
              placeholder: _vm.$t("videoYoutubeUrl"),
              "hide-details": "auto",
            },
            model: {
              value: _vm.videoUrl,
              callback: function ($$v) {
                _vm.videoUrl = $$v
              },
              expression: "videoUrl",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }