<template>
  <iframe :src="whiteBoardUrl" class="white-board"></iframe>
</template>

<script>
import XperMeetLib from 'xpermeet-lib';

export default {
  name: 'WhiteBoard',
  computed: {
    whiteBoardUrl() {
      const { meetingId } = XperMeetLib.conference.room.room;
      return `${window.XPER_CONFIG.whiteBoard.endpoint}/boards/${meetingId}?lang=${this.$vuetify.lang.current}`;
    },
  },
};
</script>

<style scoped lang="scss">
.white-board {
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
}
</style>
