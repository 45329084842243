var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "survey-list-item d-flex align-center mb-2",
      class: { shared: _vm.data.shared && _vm.data.sharedWithWebinar },
      on: { click: _vm.go },
    },
    [
      _c("span", { staticClass: "text-body-2" }, [
        _vm._v(_vm._s(_vm.itemName)),
      ]),
      _c("v-spacer"),
      !_vm.isPrivate
        ? _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("publicSurvey"),
                expression: "$t('publicSurvey')",
              },
            ],
            staticClass: "ml-3",
            attrs: { size: "14", icon: "eye" },
          })
        : _vm._e(),
      _vm.showActionButtons
        ? _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("delete"),
                expression: "$t('delete')",
              },
            ],
            staticClass: "ml-3 trash-icon",
            attrs: { size: "14", icon: "trash" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showConfirm("delete")
              },
            },
          })
        : _vm._e(),
      _vm.showActionButtons
        ? _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("edit"),
                expression: "$t('edit')",
              },
            ],
            staticClass: "ml-3",
            attrs: { size: "14", icon: "edit" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.edit.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm.isSharable
        ? _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("shareSurvey"),
                expression: "$t('shareSurvey')",
              },
            ],
            staticClass: "ml-3",
            attrs: { size: "14", icon: "share-2" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showConfirm("share")
              },
            },
          })
        : _vm._e(),
      _vm.showResultButton
        ? _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("showResult"),
                expression: "$t('showResult')",
              },
            ],
            staticClass: "ml-3",
            attrs: { size: "14", icon: "bar-chart-2" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.goToResult.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm.loading
        ? _c("v-progress-linear", {
            staticStyle: { width: "80px" },
            attrs: { indeterminate: "" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }