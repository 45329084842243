<template>
  <div :class="{ hidden: hide }" class="pagination-box d-flex align-center justify-center">
    <icon icon="chevron-left" class="mx-2 cursor-pointer" @click.native="goToPreviousPage" />
    <div class="mx-1">
      {{ activePage }}
      <span class="px-1">/</span>
      {{ getLastPage }}
    </div>
    <icon icon="chevron-right" class="mx-2 cursor-pointer" @click.native="goToNextPage" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'Pagination',
  computed: {
    ...mapState('Conference', ['activePage', 'isJibri', 'users', 'roomConfig']),
    ...mapGetters('Conference', ['getPaginationEnabled', 'getLastPage', 'getFullScreenUserId']),
    participantCount() {
      return this.users?.length || 0;
    },
    hide() {
      return !(this.getPaginationEnabled && !this.isJibri && !this.$isMobile && !this.getFullScreenUserId);
    },
  },
  methods: {
    ...mapMutations('Conference', ['SET_ACTIVE_PAGE']),
    goToPreviousPage() {
      if (this.activePage <= 1) {
        this.SET_ACTIVE_PAGE(1);
      } else {
        this.SET_ACTIVE_PAGE(this.activePage - 1);
      }
    },
    goToNextPage() {
      if (this.activePage >= this.getLastPage) {
        this.SET_ACTIVE_PAGE(this.getLastPage);
      } else {
        this.SET_ACTIVE_PAGE(this.activePage + 1);
      }
    },
    checkPageCount() {
      if (this.activePage > this.getLastPage) {
        this.SET_ACTIVE_PAGE(this.getLastPage);
      }
    },
  },
  watch: {
    participantCount() {
      this.checkPageCount();
    },
    getPageSize() {
      if (this.activePage > this.getLastPage) {
        this.SET_ACTIVE_PAGE(this.getLastPage);
      }
    },
    'roomConfig.sharedProperties'() {
      this.checkPageCount();
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-box {
  height: 40px;
  border-radius: 8px;
  min-width: 140px;
  background-color: var(--v-primary-base);

  &.hidden {
    visibility: hidden;
  }
}
</style>
