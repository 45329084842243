import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';
import tr from './tr.json';
import ru from './ru.json';
import es from './es.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  messages: {
    en,
    tr,
    ru,
    es,
  },
});
