<template>
  <ConfirmModal
    :title="$t(`newDeviceDetected.${getFirstAddedDevice.kind}`)"
    @confirm="onConfirm"
    @cancel="onCancel"
    :text="getFirstAddedDevice.label"
    :confirm-text="$t('newDeviceDetected.change')"
    :cancel-text="$t('newDeviceDetected.notChange')"
    persistent
  />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import ConfirmModal from '@/components/Shared/Modal/ConfirmModal';
import { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';

export default {
  name: 'NewDeviceModal',
  components: {
    ConfirmModal,
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getFirstAddedDevice', 'getMicrophones', 'getCameras', 'getSpeakers']),
    ...mapGetters('Conference', ['getLocalUser']),
    hasDevice() {
      let devices = this.getMicrophones;
      if (this.getFirstAddedDevice.kind === 'audiooutput') {
        devices = this.getSpeakers;
      } else if (this.getFirstAddedDevice.kind === 'videoinput') {
        devices = this.getCameras;
      }
      return devices.find(device => device.deviceId === this.getFirstAddedDevice.deviceId);
    },
  },
  methods: {
    ...mapMutations('Devices', ['REMOVE_ADDED_DEVICE', 'SET_SHOW_NEW_DEVICE_ADDED_MODAL']),
    ...mapMutations('Conference', ['SET_VIDEO_MUTE_STATE']),
    ...mapActions('Devices', ['changeDevice', 'fetchDevices']),
    ...mapActions('Conference', ['setVideoMute']),
    ...mapActions('Notification', ['playSoundWhatEver']),
    onConfirm() {
      if (this.hasDevice) {
        this.changeDevice(this.getFirstAddedDevice);
        // if (this.getFirstAddedDevice.kind === 'videoinput') {
        //   setTimeout(() => {
        //     this.setVideoMute(false);
        //     this.SET_VIDEO_MUTE_STATE({ userId: this.getLocalUser.id, muteState: false });
        //   }, 500);
        // }
        this.playSoundWhatEver(NOTIFICATION_SOUND_TYPES.NEW_DEVICE_SELECTED);
      }
      this.onRemoveAddedDevice();
    },
    onCancel() {
      this.onRemoveAddedDevice();
    },
    onSetTimer() {
      this.timer = setTimeout(() => {
        this.onTimerHandle();
      }, 1000);
    },
    onRemoveAddedDevice() {
      this.REMOVE_ADDED_DEVICE(this.getFirstAddedDevice);
      this.SET_SHOW_NEW_DEVICE_ADDED_MODAL(false);
      this.onSetTimer();
    },
    onTimerHandle() {
      if (this.getFirstAddedDevice) {
        this.SET_SHOW_NEW_DEVICE_ADDED_MODAL(true);
      }
      clearTimeout(this.timer);
    },
  },
};
</script>
