import XperMeetLib from 'xpermeet-lib';
import { consoleError } from 'xpermeet-lib/src/utils/logger';

import { mapSurvey, generateUserGroupName, getCompletedSurveys } from '@/helpers/survey';

const initialState = () => {
  return {
    mySurveys: [],
    sharedSurveys: [],
    newSurveyShared: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_NEW_SURVEY_SHARED(state, payload) {
      state.newSurveyShared = payload;
    },
    ADD_MY_SURVEYS(state, payload) {
      state.mySurveys = payload;
    },
    SET_SHARED_SURVEYS(state, payload) {
      state.sharedSurveys = payload;
    },
    SET_AS_COMPLETED_SURVEY(state, payload) {
      state.sharedSurveys = state.sharedSurveys.map(survey => ({
        ...survey,
        completed: survey.completed || (survey.Id === payload.Id && survey.type === payload.type),
      }));
    },
  },
  actions: {
    async shareSurvey({ getters }, data) {
      const { survey, type, meetingId } = data;
      const payload = {
        survey,
        userGroupName: generateUserGroupName({
          type,
          meetingId,
          roomName: getters.getRoomName,
        }),
        isAnonymous: true,
        dueDate: {
          seconds: 0,
          nanos: 0,
        },
      };

      await XperMeetLib.survey.service.createUserGroup(payload);

      if (type === 'room') {
        XperMeetLib.conference.sendShareSurveyCommand();
      } else {
        XperMeetLib.webinar.sendShareSurveyCommand();
      }

      // dispatch('getMySurveys');
    },
    async getSharedSurveys({ commit, getters, rootState }, type) {
      const meetingId = type === 'webinar' ? rootState?.Webinar?.meetingId : rootState?.Conference?.meetingId;
      if (!meetingId) return;

      const userGroupName = generateUserGroupName({
        type,
        meetingId,
        roomName: getters.getRoomName,
      });
      const completedSurveys = getCompletedSurveys(userGroupName);

      const result = await XperMeetLib.survey.service.getSharedSurveys(userGroupName);
      const sharedSurveys = mapSurvey(
        (result?.data?.Result?.List || []).map(survey => {
          survey.completed = completedSurveys.includes(survey.Survey.Id);
          return survey;
        }),
        type,
      );

      commit('SET_SHARED_SURVEYS', sharedSurveys);

      return sharedSurveys;
    },
    async addUser() {
      return await XperMeetLib.survey.service.addUser();
    },
    async addSurvey({}, payload) {
      return await XperMeetLib.survey.service.addSurvey(payload);
    },

    async updateSurvey({}, payload) {
      return await XperMeetLib.survey.service.updateSurvey(payload);
    },
    async deleteSurvey({}, payload) {
      return await XperMeetLib.survey.service.deleteSurvey(payload);
    },
    async assignSurvey({}, payload) {
      return await XperMeetLib.survey.service.assignSurvey(payload);
    },
    async getMySurveys({ commit, getters, rootGetters, rootState }) {
      if (!rootGetters['Conference/getLocalUser']?.isModerator) {
        return;
      }

      try {
        const meetingId = rootState?.Conference?.meetingId;
        const response = await XperMeetLib.survey.service.getSurveys();

        const generatedWebinarUserGroupName = generateUserGroupName({
          meetingId,
          type: 'webinar',
          roomName: getters.getRoomName,
        });

        const generatedRoomUserGroupName = generateUserGroupName({
          meetingId,
          type: 'room',
          roomName: getters.getRoomName,
        });

        const webinarSharedSurveyResponse = await XperMeetLib.survey.service.getSharedSurveys(generatedWebinarUserGroupName);
        const roomSharedSurveyResponse = await XperMeetLib.survey.service.getSharedSurveys(generatedRoomUserGroupName);

        const mySurveys = (response?.data?.Result?.List || []).map(survey => {
          const webinarSharedSurveys = (webinarSharedSurveyResponse?.data?.Result?.List || []).find(r => r.Survey.Id === survey.Id);
          const roomSharedSurveys = (roomSharedSurveyResponse?.data?.Result?.List || []).find(r => r.Survey.Id === survey.Id);
          delete survey['CreatedDate'];
          return {
            ...survey,
            shared: !!roomSharedSurveys,
            sharedWithWebinar: !!webinarSharedSurveys,
          };
        });

        commit('ADD_MY_SURVEYS', mySurveys);
      } catch (err) {
        consoleError('Survey Fetch Error:', err);
      }
    },
    async getQuestionOptions({}, payload) {
      return await XperMeetLib.survey.service.getOptions(payload);
    },
    async deleteQuestionOption({}, payload) {
      return await XperMeetLib.survey.service.deleteOption(payload);
    },
    async addQuestion({}, payload) {
      return await XperMeetLib.survey.service.addQuestion(payload);
    },
    async updateQuestion({}, payload) {
      return await XperMeetLib.survey.service.updateQuestion(payload);
    },
    async addQuestionOption({}, payload) {
      return await XperMeetLib.survey.service.addOption(payload);
    },
    async updateOption({}, payload) {
      return await XperMeetLib.survey.service.updateOption(payload);
    },
    async getSurveyQuestions({}, payload) {
      return await XperMeetLib.survey.service.getQuestions(payload);
    },
    async updateSurveyAnswers({}, payload) {
      return await XperMeetLib.survey.service.updateSurveyAnswers(payload);
    },
    async deleteSurveyQuestion({}, payload) {
      return await XperMeetLib.survey.service.deleteQuestion(payload);
    },
    async getSurveyStatistic({}, payload) {
      return await XperMeetLib.survey.service.getSurveyStatistic(payload);
    },
    async getAssignedUserGroup({}, payload) {
      return await XperMeetLib.survey.service.getAssignedUserGroup(payload);
    },
  },
  getters: {
    getRoomName(state, getters, rootState) {
      return rootState.Conference.roomName || rootState.Webinar.roomName;
    },
    getSharedSurveysByType: state => type => {
      return state.sharedSurveys.filter(s => s.type === type);
    },
    getUncompletedSharedSurveysByType: (state, getters) => type => {
      return getters.getSharedSurveysByType(type).filter(s => !s.completed);
    },
  },
};
