import Vue from 'vue';
import XperMeetLib from 'xpermeet-lib';
import router from '@/router';
import i18n from '@/i18n/index';

const initialState = () => {
  return {
    showLobbyPreMeeting: false,
    lobbyEnabled: false,
    lobbyUsers: [],
    lobbyMucMembers: [],
    joinResponseWaiting: false,
    lobbyMessages: [],
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_SHOW_LOBBY_PRE_MEETING(state, payload) {
      state.showLobbyPreMeeting = payload;
    },
    ADD_LOBBY_USER(state, payload) {
      const { id, name } = payload;
      state.lobbyUsers.push({ id, name });
    },
    REMOVE_LOBBY_USER(state, userId) {
      const userIndex = state.lobbyUsers.findIndex(u => u.id === userId);
      if (userIndex > -1) {
        Vue.delete(state.lobbyUsers, userIndex);
      }
    },
    ADD_LOBBY_MUC_MEMBER(state, payload) {
      const { id, name, role, type } = payload;
      state.lobbyMucMembers.push({ id, name, role, type });
    },
    REMOVE_LOBBY_MUC_MEMBER(state, userId) {
      const userIndex = state.lobbyMucMembers.findIndex(u => u.id === userId);
      if (userIndex > -1) {
        Vue.delete(state.lobbyMucMembers, userIndex);
      }
    },
    SET_JOIN_RESPONSE_WAITING(state, payload) {
      state.joinResponseWaiting = payload;
    },
    SET_LOBBY_ENABLE_STATE(state, payload) {
      state.lobbyEnabled = payload;
    },
    // eslint-disable-next-line
    SET_INITIAL_STATE(state) {
      state = initialState();
    },
    ADD_MESSAGE(state, payload) {
      state.lobbyMessages.push(payload);
    },
  },
  actions: {
    handleLobbyEvents({ state, commit, dispatch, getters, rootGetters }) {
      XperMeetLib.lobby.on('LobbyUserJoined', user => {
        commit('ADD_LOBBY_USER', user);
      });

      XperMeetLib.lobby.on('LobbyUserLeft', user => {
        commit('REMOVE_LOBBY_USER', user.id);
      });

      XperMeetLib.lobby.on('LobbyEnabled', enabled => {
        dispatch('Conference/enableLobby', enabled, { root: true });
      });

      XperMeetLib.lobby.on('LobbyJoinError', () => {
        // Window is reloaded when user click to join room button after a moderator closed lobby
        router.go();
      });

      XperMeetLib.lobby.on('LobbyMucMemberJoined', user => {
        commit('ADD_LOBBY_MUC_MEMBER', { ...user, type: 'remote' });
      });

      XperMeetLib.lobby.on('LobbyMucMemberLeft', user => {
        commit('REMOVE_LOBBY_MUC_MEMBER', user.id);
      });

      XperMeetLib.lobby.on('LobbyMucJoined', user => {
        commit('ADD_LOBBY_MUC_MEMBER', {
          ...user,
          name: rootGetters['Conference/getLocalUser']?.displayName || i18n.t('you'),
          type: 'local',
        });
      });

      XperMeetLib.lobby.on('LobbyMucLeft', user => {
        commit('REMOVE_LOBBY_MUC_MEMBER', user.id);
      });

      XperMeetLib.lobby.on('MessageReceived', ([userId, message]) => {
        const user = state.lobbyMucMembers.find(u => u.id === userId);

        let sender;
        if (user) {
          if (user.name) {
            sender = user.name;
          } else if (user.type === 'local') {
            sender = i18n.t('you');
          } else if (user.role === 'moderator') {
            sender = i18n.t('moderator');
          } else {
            sender = i18n.t('guest');
          }
        } else {
          sender = i18n.t('guest');
        }

        commit('ADD_MESSAGE', {
          userId: userId,
          id: state.lobbyMessages.length + 1,
          date: new Date(),
          body: message,
          sender,
          incoming: userId !== getters.getLocalMucMember?.id,
        });
      });
    },
    // eslint-disable-next-line
    enableLobby({}, enable) {
      if (XperMeetLib.lobby.isSupported()) {
        if (enable) {
          XperMeetLib.lobby.enable();
        } else {
          XperMeetLib.lobby.disable();
        }
      }
    },
    // eslint-disable-next-line
    lobbyApproveAccess({}, userId) {
      XperMeetLib.lobby.approveAccess(userId);
    },
    // eslint-disable-next-line
    lobbyDenyAccess({}, userId) {
      XperMeetLib.lobby.denyAccess(userId);
    },
    // eslint-disable-next-line
    joinLobby({ commit, dispatch }, payload) {
      const { name, email } = payload;
      dispatch('Conference/setDisplayName', name, { root: true });
      XperMeetLib.lobby.join(name, email);

      commit('SET_JOIN_RESPONSE_WAITING', true);
    },
    sendMessage({}, payload) {
      const { message } = payload;
      XperMeetLib.lobby.sendMessage(message);
    },
    joinWithPassword({}, payload) {
      return XperMeetLib.conference.room.join(payload);
    },
  },
  getters: {
    getLobbyUsers(state, getters, rootState, rootGetters) {
      return rootGetters['Conference/getLocalUser'].isModerator ? state.lobbyUsers : state.lobbyMucMembers;
    },
    getUserById: (state, getters) => id => {
      return getters.getLobbyUsers.find(u => u.id === id);
    },
    getLocalLobbyUser(state, getters, rootState, rootGetters) {
      return getters.getLobbyUsers.find(u => u.type === 'local') || rootGetters['Conference/getLocalUser'];
    },
    getLocalMucMember(state, getters, rootState, rootGetters) {
      return state.lobbyMucMembers.find(u => u.type === 'local') || rootGetters['Conference/getLocalUser'];
    },
  },
};
