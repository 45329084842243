import dayjs from 'dayjs';

import storage from '@/services/storage';

export const mapSurvey = (surveys, type) => {
  return surveys.map(survey => {
    return {
      type,
      completed: survey.completed || false,
      Id: survey?.Survey?.Id,
      IsDeleted: survey?.Survey?.IsDeleted,
      IsPrivate: survey?.Survey?.IsPrivate,
      ShareResults: survey?.Survey?.ShareResults,
      Title: survey?.Survey?.Title,
      SurveyId: survey?.Id,
      userGroup: {
        DueDate: survey?.DueDate,
        Id: survey?.Id,
        IsAnonymous: survey?.IsAnonymous,
        UserGroupName: survey?.UserGroupName,
      },
    };
  });
};

export const generateUserGroupName = ({ type, meetingId, roomName }) => `${type}--${meetingId}--${roomName}--${dayjs().format('DD-MM-YYYY')}`;

export const getCompletedSurveys = userGroupName => {
  const completedSurveys = storage.getItem('completedSurveys') || {};
  return completedSurveys[userGroupName] || [];
};

export const filterCompletedSurveys = ({ sharedSurveys, userGroupName }) => {
  const completedSurveys = getCompletedSurveys(userGroupName);
  return sharedSurveys.filter(s => !completedSurveys.includes(s.Survey.Id));
};
