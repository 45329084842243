<template>
  <Modal
    :title="$t('lobby')"
    :sub-title="$t('joinMeeting.warning')"
    :confirm-button-text="$t('askToJoin')"
    :cancel-button-text="$t('cancel')"
    :show-actions="false"
    content-class="lobby-join-modal"
    persistent
    :auto-close="false"
    @close="onModalClose"
  >
    <div class="waiting text-center">
      <v-tabs :value="activeRightMenuTab" grow show-arrows icons-and-text height="52">
        <v-tab href="#chat" :ripple="false" @click.native="activeRightMenuTab = 'chat'">
          <div class="h-full">
            <div class="icon">
              <icon icon="chat-1"></icon>
            </div>
            <span class="mt-2">
              {{ $t('chat') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#participants" :ripple="false" @click.native="activeRightMenuTab = 'participants'">
          <div class="h-full">
            <div class="icon">
              <icon icon="users"></icon>
            </div>
            <span class="mt-2">{{ $t('userList') }} ({{ waitingUsers.length }})</span>
          </div>
        </v-tab>
        <v-tab href="#join" :ripple="false" @click.native="activeRightMenuTab = 'join'">
          <div class="h-full">
            <div class="icon">
              <icon icon="key"></icon>
            </div>
            <span class="mt-2">{{ $t('join') }}</span>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items touchless :value="activeRightMenuTab">
        <v-tab-item value="chat">
          <Chat
            class="lobby-chat"
            body-id="lobby-chat"
            :show-file-share-button="false"
            :chat-enabled="true"
            :messages="lobbyMessages"
            :enablePrivateMessage="false"
            @sendButtonClicked="onSendButtonClicked"
          />
        </v-tab-item>
        <v-tab-item value="participants">
          <div>
            <UserCard
              v-for="user in waitingUsers"
              :key="user.id"
              :display-name="getDisplayName(user)"
              :me="getLocalUser && user.id === getLocalUser.id"
              :moderator="user.role === 'moderator'"
              :participant="user.role === 'participant'"
            />
          </div>
        </v-tab-item>
        <v-tab-item value="join">
          <div class="px-8 py-6">
            <p class="my-5">{{ $t('joinWithPasswordDesc') }}</p>
            <text-field type="password" v-model="password" :placeholder="$t('conferencePassword')" v-on:keyup.enter="join" hide-details class="mb-5" />
            <v-btn color="secondary" block class="mb-5" @click="join" :disabled="!password">
              {{ $t('join') }}
            </v-btn>
            <v-alert type="error" outlined v-if="joinErrorMessage">
              {{ $t(joinErrorMessage) }}
            </v-alert>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import Chat from '@/components/Chat/Chat';
import UserCard from '@/components/Users/UserCard';
import { createATagAndClick } from '@/helpers/create-element';
import { consoleError } from '@/helpers/logger';
import storage from '@/services/storage';

export default {
  name: 'LobbyJoinModal',
  components: { UserCard, Chat },
  data() {
    return {
      displayName: '',
      activeRightMenuTab: 'chat',
      password: null,
      joinErrorMessage: null,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser']),
    ...mapState('Lobby', ['lobbyUsers', 'lobbyMucMembers', 'lobbyMessages']),
    joinDisabled() {
      return this.displayName.trim() === '';
    },
    waitingUsers() {
      const users = this.getLocalUser.isModerator ? this.lobbyUsers : this.lobbyMucMembers;
      return users.filter(m => m.role !== 'moderator');
    },
  },
  mounted() {
    if (storage.getItem('displayName')) {
      this.displayName = storage.getItem('displayName');
    }
  },
  methods: {
    ...mapActions('Lobby', ['sendMessage', 'lobbyApproveAccess', 'lobbyDenyAccess', 'joinWithPassword']),
    onSendButtonClicked(message) {
      this.sendMessage({
        message: message,
      });
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.join();
      } else if (type === 'cancel') {
        const homeUrl = window.location.origin;
        createATagAndClick(homeUrl);
      }
    },
    getDisplayName(user) {
      return user?.name || '';
    },
    async join() {
      try {
        await this.joinWithPassword(this.password);

        setTimeout(() => {
          this.joinErrorMessage = 'enteredIncorrectPassword';
          this.clearError();
        }, 500);
      } catch (err) {
        this.joinErrorMessage = 'enteredIncorrectPassword';
        consoleError(err);
        this.clearError();
      }
    },
    clearError: debounce(function() {
      this.joinErrorMessage = null;
    }, 3000),
  },
};
</script>

<style scoped lang="scss">
.waiting {
  width: 100%;
  height: 485px;

  .lobby-chat {
    height: 415px;

    ::v-deep .body {
      overflow-y: initial;
    }
  }
}
</style>

<style lang="scss">
.lobby-join-modal.modal-container.v-dialog {
  .modal-title {
  }
  .v-card {
    max-height: 70vh;
  }
}

.lobby-join-modal {
  .v-tabs-items > .v-window__container,
  .v-tabs-items > .v-window-item,
  .v-tabs-items > .v-window__container > .v-window-item {
    & > div {
      height: 415px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .lobby-join-modal {
    .v-tabs-items > .v-window__container,
    .v-tabs-items > .v-window-item,
    .v-tabs-items > .v-window__container > .v-window-item {
      & > div {
        height: 360px;
      }
    }
  }
}
</style>
