<template>
  <div
    class="video-card"
    ref="wrapper"
    :class="classList"
    :style="computedStyle"
    :draggable="isDraggable"
    v-click-outside="onClickOutside"
    @dblclick="onDoubleClick"
    @mouseenter="toggleCardOptions(true)"
    @mouseleave="onMouseLeave"
    @dragstart="onDragStart"
    @dragover.prevent="onDragOver"
    @dragend="onDragEnd"
  >
    <v-col class="video-card-container">
      <v-row class="video-header">
        <template v-if="user.handsUp">
          <menu-button
            v-if="getLocalUser.isModerator"
            class="hands-up-more-actions py-1"
            icon="hand"
            color="warning"
            icon-background-color="var(--v-darkblue-80)"
            icon-size="18"
            width="24"
            use-inner-model
            hide-caret-down
            dropdownClass="mt-2"
            :dropdown-list="getLocalUser.isModerator ? handsUpButtonList(user) : null"
          ></menu-button>
          <div v-else class="hands-up-icon py-1">
            <icon default color="warning" icon="hand" size="18" />
          </div>
        </template>
        <div class="header-icon-name-container px-2 py-1">
          <div class="header-icons">
            <icon v-if="user.isModerator" size="18" color="logo-color" icon="user-full-color" class="user-icon" />
            <icon v-else-if="user.authenticated" size="18" color="white" icon="user" class="user-icon" />
          </div>
          <div class="display-name">
            <span>
              {{ user.displayName }}
            </span>
            <PrivateRoomMenu :private-room-name="user.privateRoomName" :is-local-user="user.id !== getLocalUser.id" />
          </div>
        </div>

        <div class="mr-2 mt-3 card-menu-container d-flex align-center">
          <div v-show="cardOption">
            <menu-button
              v-if="user.id !== getLocalUser.id"
              icon="more-vertical"
              color="white"
              icon-background-color="primary"
              icon-size="12"
              width="20"
              use-inner-model
              hide-caret-down
              send-on-dropdown-status-change
              dropdownClass="mt-2"
              :dropdown-list="getMoreButtonList(user)"
              @click="disableHideShowMoreAction = true"
              @changeDropdownStatus="onChangeDropdownStatus"
              class="show-more-action hidden-mobile"
            ></menu-button>
          </div>
        </div>
      </v-row>

      <v-col class="video-content">
        <avatar
          class="video-avatar"
          v-if="!showVideoContainer"
          absolute
          :min-avatar-size="avatarSize"
          :display-name="user.displayName"
          :full-screen="fullScreen"
          :image="user.avatar"
        />

        <video v-show="showVideoContainer" ref="video" :id="`${user.type}-video-${user.id}`" autoplay playsinline :class="`${user.type}-video`" />
      </v-col>

      <v-row class="video-footer" align="end">
        <v-col class="pa-0">
          <div class="d-flex items-center">
            <template v-if="user.audioMuted || !user.hasAudioTrack">
              <div class="footer-icon" :class="{ 'mr-1': user.videoMuted || !user.hasVideoTrack || user.speakerMuted }">
                <icon color="logo-color" icon="mic-off-solid" class="muted-audio" />
              </div>
            </template>
            <template v-if="user.speakerMuted">
              <div class="footer-icon">
                <icon size="18" color="logo-color" icon="volume-off-1" />
              </div>
            </template>
          </div>
        </v-col>
      </v-row>

      <div class="stats-popover">
        <template v-if="user.interrupted">
          <icon icon="alert-circle-1" color="warning" v-tooltip="$t('stats.connectionLost')"></icon>
        </template>
        <template v-else-if="user.inactive">
          <icon icon="alert-circle" color="info" v-tooltip="$t('stats.inactive')"></icon>
        </template>
        <template v-else>
          <div class="security-icon-container d-flex align-center justify-center" :class="{ 'mr-1': !user.features_jigasi }">
            <icon size="12" icon="shield-1" color="var(--v-warning)" v-tooltip="isE2eeEnabled ? $t('e2e-encrypted') : $t('dtls-encrypted')"></icon>
          </div>
          <Stats if="!user.features_jigasi" :userId="user.id" class="d-flex" />
        </template>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { attachTrackToElements, detachUserVideoTracks } from '@/helpers/conference';
import PrivateRoomMenu from '@/components/VideoCard/PrivateRoomMenu';
import Avatar from '@/components/Shared/Avatar';
import Icon from '@/components/Shared/Icon';
import ENUMS from '@/constants/ENUMS';
import Stats from '@/components/VideoCard/Stats';

let dragObjectX = 0;
let dragObjectY = 0;

export default {
  name: 'VideoCard',
  components: {
    Stats,
    Avatar,
    Icon,
    PrivateRoomMenu,
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    participantCount: {
      type: Number,
      default: 1,
    },
    cardSize: {
      type: Object,
      default: () => ({
        width: 320,
        height: 180,
      }),
    },
    user: {
      type: Object,
      required: true,
    },
    hideFullScreen: {
      type: Boolean,
      default: false,
    },
    pinned: {
      type: Boolean,
      default: false,
    },
    pinnedPosition: {
      type: Object,
      default: () => ({}),
    },
    avatarSize: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      audioIsMuted: true,
      speaking: false,
      moderator: true,
      cardOption: false,
      disableHideShowMoreAction: false,
      style: {
        visibility: 'hidden',
      },
      toLeft: 0,
      toTop: 0,
      hoveredActive: false,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser', 'getDominantUserId']),
    ...mapGetters('Webinar', ['isWebinarStarted']),
    ...mapState('Conference', ['currentSpeakingUserId', 'roomConfig', 'showChatScreen', 'users']),
    showWebinarKickButton() {
      return this.isWebinarStarted;
    },
    classList() {
      return {
        'full-screen': this.fullScreen,
        'screen-sharing': this.user.screenSharing,
        dominant: this.getDominantUserId === this.user.id,
        'dominant-border': this.currentSpeakingUserId === this.user.id && !this.$isMobile && !this.fullScreen && this.users?.length !== 1,
        bordered: !this.showVideoContainer && !this.$isMobile,
        'show-video': this.showVideoContainer,
        'my-video-card': this.user.id === this.getLocalUser.id,
        'pinned-video-card': this.pinned,
        'hovered-video-card': this.canHovered,
        'hovered-active': this.hoveredActive,
        muted: !this.showVideoContainer,
        'menu-opened': this.showChatScreen && this.$vuetify?.breakpoint?.width > 1329,
      };
    },
    userVideoTrack() {
      return this.user.tracks.find(t => t?.type === 'video');
    },
    computedStyle() {
      let style = {
        order: this.videoOrder,
      };

      if (this.pinnedPosition) {
        style = {
          ...style,
          ...this.pinnedPosition,
        };
      }

      if (this.hidden) {
        style.visibility = 'hidden';
      }

      return style;
    },
    isE2eeEnabled() {
      return this.user?.localUserE2ee;
    },
    showVideoContainer() {
      if (this.user.hasVideoTrack && !this.user.interrupted && !this.user.inactive) {
        return !this.user.videoMuted || this.user.screenSharing;
      } else {
        return false;
      }
    },
    microphoneIcon() {
      return this.user && this.user.audioMuted ? 'microphone-slash' : 'microphone';
    },
    muteMicrophoneText() {
      return this.user && this.user.audioMuted ? 'unmuteAudio' : 'muteAudio';
    },
    muteVideoText() {
      return this.user && this.user.videoMuted ? 'unmuteVideo' : 'muteVideo';
    },
    videoOrder() {
      return this.user.role === 'moderator' ? 0 : 2;
    },
    showAllowSpeakRequestButton() {
      return this.user.handsUp && this.getLocalUser.role === 'moderator';
    },
    isARTrack() {
      return this.user?.ARTrack;
    },
    isMobile() {
      return this.user?.isMobile ? true : false;
    },
    allowGrandAndRevokeModeratorRole() {
      return this.$customerConfig?.system?.modules?.allowGrandAndRevokeModerator;
    },
    isDraggable() {
      // TODO It will be fixed with #MEET-375
      // return this.pinned;
      return false;
    },
    canHovered() {
      return !(this.pinned || this.fullScreen);
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      attachTrackToElements([this.user], false, true);
    });
  },
  beforeDestroy() {
    detachUserVideoTracks(this.user.id, this.user.id === this.getLocalUser.id);
  },
  methods: {
    ...mapGetters(['getAvatar']),
    ...mapMutations('Conference', ['SET_CURRENT_RIGHT_MENU_TAB', 'TOGGLE_CHAT_SCREEN', 'TOGGLE_CHAT_SCREEN']),
    ...mapActions('Conference', [
      'setRemoteAudioMute',
      'setRemoteVideoMute',
      'kickParticipant',
      'allowSpeakRequest',
      'handsDown',
      'setFullScreen',
      'grantModerator',
      'revokeModerator',
      'sendToLobby',
      'setPrivateMessageTo',
      'stopParticipantScreenShare',
    ]),
    ...mapActions('Webinar', ['kickUserToWebinar']),
    getMoreButtonList(user) {
      return [
        {
          notRender: user.id === this.getLocalUser.id || !this.$can('sendPrivateMessage', 'Chat') || user.features_jigasi,
          label: 'sendPrivateMessage',
          onClick: () => {
            this.sendPrivateMessage(user);
            this.onClickSubItems();
          },
        },
        {
          notRender: !(this.getLocalUser.isModerator && user.id !== this.getLocalUser.id) || user.features_jigasi || this.user.screenSharing,
          label: this.muteVideoText,
          onClick: () => {
            this.setRemoteVideoMute({
              participantId: user.id,
              muteState: !user.videoMuted,
            }),
              this.onClickSubItems();
          },
        },
        {
          notRender: !this.getLocalUser.isModerator || !this.user.screenSharing,
          label: 'stopScreenSharing',
          confirmPopover: true,
          onClick: () => {
            this.stopParticipantScreenShare(user.id);
            this.onClickSubItems();
          },
        },
        {
          notRender: !(this.getLocalUser.isModerator && user.id !== this.getLocalUser.id) || user.features_jigasi,
          label: this.muteMicrophoneText,
          onClick: () => {
            this.setRemoteAudioMute({
              participantId: user.id,
              muteState: !user.audioMuted,
            });
            this.onClickSubItems();
          },
        },
        {
          notRender:
            !(
              this.allowGrandAndRevokeModeratorRole &&
              this.getLocalUser.isModerator &&
              user.id !== this.getLocalUser.id &&
              !user.isModerator &&
              user.authenticated
            ) || user.features_jigasi,
          label: 'grantModerator',
          confirmPopover: true,
          onClick: () => {
            this.grantModerator(user.id);
            this.onClickSubItems();
          },
        },
        {
          notRender:
            !(
              this.allowGrandAndRevokeModeratorRole &&
              this.getLocalUser.isModerator &&
              user.id !== this.getLocalUser.id &&
              user.isModerator &&
              user.authenticated
            ) || user.features_jigasi,
          label: 'revokeModerator',
          confirmPopover: true,
          onClick: () => {
            this.revokeModerator(user.id);
            this.onClickSubItems();
          },
        },
        {
          notRender: !this.getLocalUser.isModerator || !this.showWebinarKickButton || user.features_jigasi,
          label: 'sendUserToWebinar',
          confirmPopover: true,
          onClick: () => {
            this.kickUserToWebinar(user.id);
            this.onClickSubItems();
          },
        },
        {
          notRender: !this.getLocalUser.isModerator,
          label: 'kickParticipant',
          confirmPopover: true,
          onClick: () => {
            this.kickParticipant(user.id);
            this.onClickSubItems();
          },
        },
        {
          notRender: !this.getLocalUser.isModerator || !this.roomConfig.lobbyEnabled,
          label: 'sendUserToLobby',
          id: 'send-user-to-lobby-button',
          confirmPopover: true,
          onClick: () => this.sendToLobby(user.id),
        },
      ];
    },
    handsUpButtonList(user) {
      return [
        {
          notRender: this.microphoneIcon !== 'microphone-slash' && this.isMobile,
          label: 'allowSpeakRequest',
          onClick: () => this.allowSpeakRequest({ participantId: user.id }),
        },
        {
          notRender: !(this.getLocalUser.isModerator && user.id !== this.getLocalUser.id) || user.features_jigasi,
          label: 'handsDown',
          onClick: () => this.handsDown({ participantId: user.id }),
        },
      ];
    },
    openARMenu() {
      this.TOGGLE_CHAT_SCREEN();
      this.SET_CURRENT_RIGHT_MENU_TAB('ar');
    },
    toggleCardOptions(state) {
      this.cardOption = state;
    },
    sendPrivateMessage(user) {
      if (this.roomConfig[ENUMS.RoomConfigEnums.ALLOW_PRIVATE_CHAT]) {
        this.TOGGLE_CHAT_SCREEN({ state: true });
      }
      this.setPrivateMessageTo({ id: user.id, name: user.displayName });
    },
    onDragStart(e) {
      if (this.isDraggable) {
        this.toLeft = e.pageX - e.target.getBoundingClientRect().left;
        this.toTop = e.pageY - e.target.getBoundingClientRect().top;
      }
    },
    onDragOver(e) {
      dragObjectX = e.pageX;
      dragObjectY = e.pageY;
    },
    onDragEnd() {
      if (this.isDraggable) {
        this.$nextTick(() => {
          let left = dragObjectX - this.toLeft;
          let top = dragObjectY - this.toTop;
          if (left < 30) {
            left = 30;
          }
          if (top < 60) {
            top = 60;
          }
          if (top > window.innerHeight - (this.cardSize.height + 33)) {
            top = window.innerHeight - (this.cardSize.height + 33);
          }
          if (left > window.innerWidth - (this.cardSize.width - 88)) {
            left = window.innerWidth - (this.cardSize.width - 88);
          }
          let videoCard = this.$refs.wrapper;
          videoCard.style.left = `${left}px`;
          videoCard.style.top = `${top}px`;
          this.$emit('pinnedPositionChanged', {
            left: `${left}px`,
            top: `${top}px`,
          });
        });
      }
    },
    onDoubleClick() {
      if (!this.pinned) {
        this.$emit('dblclick');
      }
    },
    onMouseLeave() {
      if (!this.disableHideShowMoreAction) {
        this.toggleCardOptions(false);
      }
    },
    onClickSubItems() {
      this.disableHideShowMoreAction = false;
    },
    onClickOutside() {
      this.toggleCardOptions(false);
      this.disableHideShowMoreAction = false;
    },
    onChangeDropdownStatus(status) {
      this.hoveredActive = status;
    },
  },
  watch: {
    pinned() {
      if (!this.pinned) {
        let videoCard = this.$refs.wrapper;
        videoCard.style.removeProperty('left');
        videoCard.style.removeProperty('top');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$cardRadius: 0px;
.v-list {
  .v-popover.menu-button-popover:hover {
    cursor: pointer;
    background-color: var(--modal-border-color);
  }
}

.video-card {
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  box-sizing: content-box;
  display: flex;
  margin: 0.4rem;
  border-radius: 8px;
  background: linear-gradient(107.98deg, rgba(248, 248, 248, 0.3) 1.97%, rgba(248, 248, 248, 0) 101.01%);
  -webkit-transform: translate3d(0, 0, 0);
  padding: 2px;
  &.muted {
    backdrop-filter: blur(20px);
  }
  &.pinned-video-card {
    position: fixed;
    right: 20px;
    bottom: 70px;
    z-index: 16;
    cursor: move;
    width: 200px;
    height: 150px;
    --card-width: 200px;
    --card-height: 150px;
  }
  &.hovered-video-card {
    &.hovered-active {
      z-index: 17;
    }
  }
  &.hovered-video-card:hover {
    z-index: 17;
  }
  &.full-screen {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    padding: 0;
    border-radius: 0 !important;
    left: 0;
    top: 0;
    z-index: 3;

    &.menu-opened {
      width: calc(100% - 380px) !important;
    }

    .avatar {
      border-radius: 8px !important;
    }

    .remote-video,
    .local-video {
      border-radius: 0px !important;
    }
  }
  .hands-up-icon {
    background-color: var(--v-darkblue-80);
    width: 32px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  &.bordered {
    border: 2px solid var(--v-body-60);
    padding: 0px;

    &.edit-mode-video-card {
      border: none;
    }
  }

  .card-menu-btn {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .show-more-action {
    margin: 0.3rem 0.6rem 0 0;
  }

  &.dominant-border {
    display: block;
    border: solid 2px var(--v-secondary-base);

    &.show-video {
      width: calc(var(--card-width) - 4px);
      height: calc(var(--card-height) - 4px);
    }
  }

  .header-icons {
    float: left;
    padding: 0px 0px;
    opacity: 0.7;
    text-align: center;
    line-height: 35px;
    margin-right: 0px;
    display: flex;
  }

  .footer-icon {
    background-color: var(--v-darkblue-80);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .display-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #f8f8f8;
    overflow: hidden;
    max-width: 100%;
    max-height: 2.5em;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  .video-card-container {
    display: flex;
    flex-direction: column;
  }

  .header-icon-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    background-color: var(--v-darkblue-80);
    height: 24px;
    border-radius: 8px;
    margin: 0.5rem;
  }

  .card-menu-container {
    position: absolute;
    right: 0;
    top: 0;
  }

  .card-menu-btn {
    width: 35px;
    height: 35px;
    margin-right: unset !important;
    display: inline-block;
  }

  .moderator-icon {
    transition: var(--default-transition);
    margin: 0px 0px 0px 3px;
  }

  .user-icon {
    font-size: 18px;
    margin-right: 10px;
  }

  .circle-ripple {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    margin: 14px;
  }

  $color: #ecfd00;

  ::v-deep .muted-audio {
    font-size: 0.7rem;
  }

  .mobile-user,
  .sip-user {
    bottom: 5px;
    left: 5px;
    font-size: 0.7rem;
  }

  .video-header {
    z-index: 10;
    float: none;
    flex: none;
    padding: 1px 0px 0px 7px;
    align-items: center !important;

    .card-menu-btn {
      border-radius: var(--default-button-radius);
      transition: var(--default-transition);
    }
  }

  .video-content {
    height: auto;

    .video-avatar {
      ::v-deep.avatar-wrapper {
        background: url('~@/assets/images/avatar-border.svg');
        background-size: cover;
        padding: 3%;
      }
    }
  }

  .video-footer {
    position: absolute;
    z-index: 10;
    flex: 0;
    bottom: 1rem;
    left: 18px;
    background-color: var(--video-footer-background-color);
    border-radius: var(--default-radius);

    .col {
      padding-bottom: 0px;
    }
  }

  .stats-popover {
    position: absolute;
    right: 6px;
    bottom: 5px;

    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--default-transition);

    .security-icon-container {
      width: 20px;
      height: 20px;
      background-color: var(--v-darkblue-80);
      border-radius: 8px;
    }
  }

  .local-video,
  .remote-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #141414;
    display: flex;
    transition: var(--default-transition);
    border-radius: 8px;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@media (max-width: $breakpoint-tablet) {
  .video-header {
    font-size: 12px !important;
    top: 60px;
    z-index: 10;
    float: none;
    flex: none;
  }
}

@media (max-width: $breakpoint-mobile) {
  .video-card {
    display: none;

    .video-card:not(.my-video-card) {
      .local-video,
      .remote-video {
        border-radius: 0 !important;
      }
    }

    .stats-popover {
      display: none;
    }

    &.my-video-card {
      position: fixed;
      z-index: 9;
      width: 150px !important;
      display: block;
      height: 85px !important;
      right: 10px;
      bottom: 4rem;
      --card-width: 100px;

      .stats-popover {
        right: 13px !important;
        bottom: 3px !important;
        height: 24px !important;
      }

      .video-header {
        top: 0px !important;
      }

      .header-icon-name-container {
        display: none;
      }

      .video-footer {
        bottom: 3px !important;
        left: 3px !important;
        display: block;
        .footer-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .header-icon-name-container {
      background-color: var(--v-whisper-50);
      margin-left: 1rem !important;
    }

    .header-icons {
      float: left;
      /* background: black; */
      padding: 0px 0px;
      opacity: 0.7;
      text-align: center;
      line-height: 15px;
      margin-right: 0px;
    }

    .display-name {
      float: left;
      padding: 0px 0px;
      opacity: 0.7;
      text-align: center;
      line-height: 19px;
      border-radius: 5px;

      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
      max-width: 100%;
    }

    &.dominant {
      display: block !important;
      position: fixed !important;
      height: 100% !important;
      width: 100% !important;
      border-radius: 0;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0;

      .stats-popover {
        bottom: 3px;
        right: 7px;
      }

      .video-footer {
        bottom: 60px;
        left: 17px;
      }
    }

    .video-content {
      padding: 0;
    }

    .video-header {
      position: relative;
      top: 20px;
      z-index: 10;
      float: none;
      flex: none;

      .card-menu-btn {
        display: none;
      }
    }

    .video-footer {
      display: none;
      margin: 0;
      padding: 0 0px;
      position: absolute;
      z-index: 10;
      left: 3px;
      flex: 0;
      bottom: 3px;
      background-color: var(--video-footer-background-color);

      .col {
        padding-bottom: 0px;
        padding-left: 0px;
      }
    }

    .full-screen-btn {
      display: none;
    }

    .hidden-mobile {
      display: none;
    }
  }
}
</style>
