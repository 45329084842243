var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jibri" },
    [
      !_vm.isYoutube
        ? _c("div", {
            staticClass: "jibri-overlay",
            style: { backgroundImage: _vm.backgroundImage },
          })
        : _vm._e(),
      _c(
        "div",
        {
          ref: "video-dynamic-layout",
          staticClass: "meet-container",
          attrs: { id: "meet-container" },
        },
        [
          _c(
            "v-container",
            { staticClass: "meet-wrapper", attrs: { fluid: "" } },
            [
              _c(
                "div",
                {
                  staticClass: "meet-page",
                  attrs: { "data-layout-type": _vm.activeLayoutType },
                },
                [
                  _vm.activeLayoutType === "grid"
                    ? [
                        _c(
                          "div",
                          { staticClass: "users-container" },
                          [
                            _c("VideoGridlayout", {
                              attrs: {
                                layout: _vm.activeLayout,
                                users: _vm.getUsers,
                                screenSize: _vm.screenSize,
                              },
                              on: { fullscreen: _vm.onFullScreenClicked },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-center align-center flex-wrap",
                          },
                          _vm._l(_vm.getUsers, function (user) {
                            return _c("video-card", {
                              key: user.id,
                              attrs: {
                                user: user,
                                "avatar-size": 24,
                                "participant-count": _vm.participantCount,
                                fullScreen:
                                  _vm.participantCount === 1 || user.fullScreen,
                                hidden:
                                  _vm.getFullScreenUserId &&
                                  _vm.getFullScreenUserId !== user.id,
                              },
                              nativeOn: {
                                dblclick: function ($event) {
                                  return _vm.onFullScreenClicked(user)
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("UsersAudioContainer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }