<template>
  <div class="users pl-3 pr-2">
    <div class="hosts-title flex-grow-0 px-5 py-3">
      <div class="text">{{ $t('hosts') }}</div>
    </div>
    <div>
      <text-field
        name="search-user"
        autocomplete="off"
        backgroundColor="body"
        class="mx-2"
        v-model="searchByUserNameText"
        :label="$t('searchUsersByUserName')"
        hide-details
      ></text-field>
      <div class="user" :class="{ moderator: user.role === 'moderator', participant: user.role === 'participant' }" v-for="user in participants" :key="user.id">
        <avatar :display-name="user.displayName" :image="user.avatar" :avatar-size="48" />
        <div :title="user.displayName" class="user-display-name webinar">
          {{ user.displayName }}
        </div>
        <div class="user-actions">
          <icon v-if="user.handsUp" class="user-action-icon hand-action-icon" icon="hand" size="medium" />
        </div>
      </div>
    </div>
    <div class="mt-4 mb-4 ml-n3 mr-n2" style="background-color: var(--v-body-base); height: 2px" />
    <div class="viewers-title flex-grow-0 px-5 pb-3">
      <div class="text">{{ $t('viewerList') }}</div>
    </div>
    <div>
      <text-field
        class="mt-1 mx-2"
        v-model="searchByViewerNameText"
        backgroundColor="body"
        :label="$t('searchWebinarUsersByViewerName')"
        hide-details
      ></text-field>

      <div class="user" :class="{ me: getLocalUser && user.id === getLocalUser.id }" v-for="user in filteredViewers" :key="user.id">
        <avatar v-if="user.role === 'moderator'" :display-name="user.displayName" :image="user.avatar" />
        <div :title="user.displayName" class="user-display-name">
          {{ user.displayName }}
        </div>
        <div class="user-actions">
          <icon v-if="user.handsUp" class="user-action-icon hand-action-icon" icon="hand" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Shared/Icon';
import { turkishToLower } from '@/helpers/regex';

export default {
  name: 'WebinarUsers',
  components: { Icon },
  data() {
    return {
      searchByUserNameText: '',
      searchByViewerNameText: '',
    };
  },
  computed: {
    ...mapGetters('Webinar', ['getUsers', 'getLocalUser']),
    filteredViewers() {
      if (this.searchByViewerNameText) {
        return this.getUsers.filter(u => u.role === 'viewer' && turkishToLower(u.displayName).includes(turkishToLower(this.searchByViewerNameText)));
      } else {
        return this.getUsers.filter(u => u.role === 'viewer');
      }
    },
    participants() {
      return this.getUsers.filter(u => {
        return u.role !== 'viewer' && turkishToLower(u.displayName).includes(turkishToLower(this.searchByUserNameText));
      });
    },
  },
};
</script>

<style scoped lang="scss">
.users {
  overflow: auto;
  display: flex;
  flex-direction: column;

  .viewers-title {
    margin-top: 0.5rem;
  }

  .user-list-admin-btn {
    padding: 0.5rem 0.2rem;
    min-width: auto;
    height: auto;
    margin-left: 0.5rem;
  }

  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: var(--default-radius);
    margin: 0.5rem 0.5rem 0px 0.5rem;
    font-size: 12px;
    line-height: 1.5;
    padding: 0.5rem 0.5rem;

    .hand-action-icon {
      color: var(--v-warning-base);
      background-color: var(--v-primary-base);
      padding: 4px;
      border-radius: 8px;
    }

    &.me {
      order: 2;
    }

    &.moderator {
      order: 0;
    }

    &.participant {
      order: 1;
    }
  }
}
</style>
