var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "participant-counter" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center",
          class: { "cursor-pointer": !_vm.webinar },
          on: { click: _vm.toggleUserList },
        },
        [
          _c("icon", { attrs: { icon: "users", size: "20" } }),
          _c("span", { staticClass: "participant-count px-2" }, [
            _vm._v(_vm._s(_vm.participantCount)),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _vm.isWebinarStarted || _vm.webinar
        ? [
            _c("v-divider", { staticClass: "mr-2", attrs: { vertical: "" } }),
            _c("icon", { attrs: { icon: "tv-2", size: "20" } }),
            _c("span", { staticClass: "participant-count px-2" }, [
              _vm._v(_vm._s(_vm.getViewerCount)),
            ]),
          ]
        : _vm._e(),
      _vm.roomConfig.lobbyEnabled && _vm.getLocalUser.isModerator
        ? _c(
            "div",
            {
              staticClass: "d-flex cursor-pointer",
              on: { click: _vm.openRightMenuLobby },
            },
            [
              _c("v-divider", { staticClass: "mr-2", attrs: { vertical: "" } }),
              _c("icon", { attrs: { icon: "user-clock", size: "20" } }),
              _c("span", { staticClass: "participant-count px-2" }, [
                _vm._v(_vm._s(_vm.lobbyUserCount)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("menu-button", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: !_vm.webinar && _vm.showMustLoggedIn,
            expression: "!webinar && showMustLoggedIn",
          },
        ],
        staticClass: "plus-menu-icon",
        attrs: {
          "icon-background-color": "body",
          rounded: "",
          icon: "plus",
          width: "26",
          "icon-size": "16",
          dropdownClass: "mt-4",
          disabled: !_vm.webinar && !_vm.isLoggedIn,
          "dropdown-list": _vm.inviteItems,
          "use-inner-model": true,
          "click-disabled": !_vm.webinar && !_vm.isLoggedIn,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }