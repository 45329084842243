<template>
  <div class="youtube-video-wrapper">
    <youtube
      :video-id="videoId"
      :width="width"
      :height="height"
      ref="youtube"
      :player-vars="playerVars"
      class="youtube-video-player"
      :class="{ 'disable-interaction': !controls }"
      @ready="onReady"
      @ended="onEnded"
      @playing="onPlaying"
      @paused="onPaused"
      @buffering="onBuffering"
      @cued="onCued"
      @error="onError"
    ></youtube>
    <div v-if="!isPlaying">
      <div class="play-icon" @click="playVideo">
        <div class="my-2">
          <v-btn
            x-large
            color="red"
            dark
            style=" border-radius: 20px;
    box-shadow: 0px 0px 20px 4px black !important;"
          >
            <v-icon class="" medium>mdi-play</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { consoleError } from '@/helpers/logger';

export default {
  name: 'YoutubePlayer',
  props: {
    videoId: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    controls: {
      type: Boolean,
    },
    initialTime: {
      type: Number,
      default: 0,
    },
    forceMuted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      volume: 100,
      isPlaying: false,
    };
  },
  computed: {
    playerVars() {
      return {
        autoplay: 1,
        controls: this.controls ? 1 : 0,
        fs: 0,
      };
    },
  },
  beforeDestroy() {
    this.$refs.youtube.player.removeEventListener('onVolumeChange', this.onVolumeChange);
  },
  methods: {
    getCurrentTime() {
      return this.$refs.youtube.player.getCurrentTime();
    },
    pauseVideo() {
      this.$refs.youtube.player.pauseVideo();
    },
    playVideo() {
      this.$refs.youtube.player.playVideo();
    },
    seekTo(time) {
      this.$refs.youtube.player.seekTo(time);
    },
    setVolume(volume) {
      this.$refs.youtube.player.setVolume(volume);
    },
    mute() {
      this.$refs.youtube.player.mute();
    },
    unMute() {
      if (!this.forceMuted) {
        this.$refs.youtube.player.unMute();
      }
    },
    onVolumeChange(e) {
      if (this.forceMuted) {
        return;
      }

      if (!e.data.muted) {
        this.$emit('unmuted', e);
      } else {
        this.$emit('muted', e);
      }
      this.$emit('volumeChanged', e.data.volume);
    },
    onReady(e) {
      this.$refs.youtube.player.addEventListener('onVolumeChange', this.onVolumeChange);

      if (this.forceMuted) {
        this.mute();
      }

      if (e.isMuted()) {
        this.$emit('muted', e);
      }

      this.$emit('volumeChanged', e.getVolume());

      if (this.initialTime) {
        this.seekTo(this.initialTime);
        this.playVideo();
      }
      this.$emit('onReady', e);
    },
    onEnded() {
      this.isPlaying = false;
    },
    onPlaying(e) {
      this.$emit('onPlay', e);
      this.isPlaying = true;
    },
    onPaused(e) {
      this.$emit('onPause', e);
    },
    onBuffering() {},
    onCued() {},
    onError(e) {
      consoleError(e);
    },
  },
  watch: {
    forceMuted(newVal) {
      if (newVal) {
        this.mute();
      } else {
        this.$refs.youtube.player.unMute();
      }
    },
  },
};
</script>

<style lang="scss">
.youtube-video-player {
  border: 3px solid var(--v-body-60);
  border-radius: 8px;
  &.disable-interaction {
    pointer-events: none;
  }

  .play-icon {
    position: absolute;
    cursor: pointer;
    top: 70px;
    left: 100px;
    z-index: 10;
  }
}
.youtube-video-wrapper {
  position: relative;
}
</style>
