var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        "cancel-button-text": _vm.cancelText,
        persistent: _vm.persistent,
        "confirm-button-text": _vm.confirmText,
        "show-actions": "",
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm._v(" " + _vm._s(_vm.text) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }