<template>
  <Modal
    :title="$t('startRecording')"
    :confirm-button-text="$t('startRecording')"
    :cancel-button-text="$t('cancel')"
    show-actions
    persistent
    :confirm-button-disabled="startButtonIsDisabled"
    @close="onModalClose"
  >
    <div class="text-center">{{ $t(desc) }}</div>
    <div class="mt-8">
      <v-radio-group v-model="recordCheck" hide-details>
        <div class="d-flex flex-row align-start">
          <v-radio :label="$t('saveDevice')" :disabled="localRecordIsDisabled" :value="1" color="white"></v-radio>
          <v-radio :label="remoteRecordText" :disabled="remoteRecordIsDisabled" :value="0" color="white" class="ml-10"></v-radio>
        </div>
      </v-radio-group>
    </div>

    <div class="mt-5">
      <template v-if="recordCheck === 1">
        <div v-if="localRecord && !userSharedScreen">
          <v-alert v-if="remoteRecordWarningText" dense type="info" icon="feather icon-info">{{ $t(remoteRecordWarningText) }}</v-alert>
          <v-alert dense type="info" icon="feather icon-info">
            <p class="mb-2">{{ $t('localRemoteRecordInfo.description') }}</p>
            <p class="mb-1">{{ $t('localRemoteRecordInfo.localDescription') }}</p>
          </v-alert>
        </div>
        <div v-else-if="userSharedScreen">
          <v-alert dense type="info" icon="feather icon-info">{{ $t('localRecordScreenSharingWarning') }}</v-alert>
        </div>
      </template>
      <template v-if="recordCheck === 0">
        <div>
          <v-alert type="info" icon="feather icon-info">
            <p class="mb-2">{{ $t('localRemoteRecordInfo.description') }}</p>
            <p class="mb-1">{{ remoteRecordDescription }}</p>
            <p v-if="!changeRemoteRecordText">
              {{ $t('localRemoteRecordInfo.remoteLinkDescription') }}
              <Link :text="$t('localRemoteRecordInfo.remoteLink')" :href="priceUrl" />
            </p>
          </v-alert>
        </div>
      </template>

      <div v-if="getE2eeEnabled && !localRecord">
        <v-alert type="info" icon="feather icon-info">
          {{ $t('localRemoteRecordInfo.remoteE2eInfo') }}
        </v-alert>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'RecordModal',
  data() {
    return {
      recordCheck: 1,
      priceUrl: `${process.env.VUE_APP_BIZBIZE_WEB_URL}/prices`,
    };
  },
  computed: {
    ...mapState('Conference', ['recordingStatus']),
    ...mapGetters('Conference', ['getIsRecording', 'getLocalUser', 'getE2eeEnabled']),
    desc() {
      return this.$can('startRemote', 'Record') ? 'recordingStartDesc' : 'localRecordingStartDesc';
    },
    user() {
      return this.getLocalUser || {};
    },
    allowRemoteRecord() {
      return this.user.authenticated && this.$can('startRemote', 'Record');
    },
    userSharedScreen() {
      return this.user.screenSharing;
    },
    localRecord() {
      return this.recordCheck === 1;
    },
    remoteRecordWarningText() {
      if (!this.getLocalUser.authenticated) {
        return 'remoteRecordAuthInfo';
      }

      if (this.getLocalUser.isModerator && !this.$can('startRemote', 'Record')) {
        return 'remoteRecordLicenseInfo';
      }

      return null;
    },
    localRecordIsDisabled() {
      return this.getLocalUser.screenSharing;
    },
    remoteRecordIsDisabled() {
      return !this.$can('startRemote', 'Record');
    },
    startButtonIsDisabled() {
      return (this.localRecordIsDisabled && this.remoteRecordIsDisabled) || this.recordCheck === null;
    },
    remoteRecordText() {
      return this.changeRemoteRecordText ? this.$t('saveRemoteServer') : this.$t('saveXperDrive');
    },
    remoteRecordDescription() {
      return this.changeRemoteRecordText ? this.$t('localRemoteRecordInfo.customRemoteDescription') : this.$t('localRemoteRecordInfo.remoteDescription');
    },
    changeRemoteRecordText() {
      return this.$customerConfig?.system?.modules?.changeRemoteRecordText;
    },
  },
  created() {
    if (this.localRecordIsDisabled) {
      this.recordCheck = this.remoteRecordIsDisabled ? null : 0;
    }
  },
  methods: {
    ...mapActions('Conference', ['startRecording']),
    onModalClose(type) {
      if (type === 'confirm') {
        this.startRecording(this.localRecord);
      }
    },
  },
  watch: {
    userSharedScreen(val) {
      if (val) {
        this.recordCheck = this.remoteRecordIsDisabled ? null : 0;
      }
    },
  },
};
</script>
