var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conference-settings-wrapper" },
    [
      _c(
        "v-col",
        { staticClass: "conference-settings pa-3 pl-6" },
        [
          _c("div", { staticClass: "advanced-settings-title pa-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("conferenceSettings")) + " "),
          ]),
          !_vm.localUserIsModerator
            ? _c(
                "div",
                { staticClass: "mt-4 mb-n4" },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        type: "info",
                        icon: "feather icon-info",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("doesntChangeConferenceSettings")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { style: { opacity: _vm.localUserIsModerator ? "1" : "0.5" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("text-field", {
                    staticClass: "modal-inputs",
                    attrs: {
                      disabled: !_vm.localUserIsModerator,
                      label: _vm.$t("setConferencePassword"),
                      "hide-details": "",
                      autocomplete: "off",
                      type: _vm.showPassword ? "text" : "password",
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                      "click:append": function ($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                    },
                    model: {
                      value: _vm.localPassword,
                      callback: function ($$v) {
                        _vm.localPassword = $$v
                      },
                      expression: "localPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        type: "info",
                        icon: "feather icon-info",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("LockRoomAlert")))]
                  ),
                ],
                1
              ),
              _vm.lobbySupported
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "12", md: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t("youCanPutParticipantsInLobby"),
                                expression:
                                  "$t('youCanPutParticipantsInLobby')",
                              },
                            ],
                            staticClass: "mr-3 tooltip-icon",
                            attrs: { size: "20", icon: "alert-circle" },
                          }),
                          _c("div", { staticClass: "label mr-3" }, [
                            _vm._v(_vm._s(_vm.$t("enableLobby"))),
                          ]),
                          _c("v-switch", {
                            staticClass: "my-0",
                            attrs: {
                              color: "success",
                              disabled: !_vm.localUserIsModerator,
                              "hide-details": true,
                            },
                            model: {
                              value: _vm.config.lobbyEnabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "lobbyEnabled", $$v)
                              },
                              expression: "config.lobbyEnabled",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.localUserIsModerator
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "12", md: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t("followModeratorFullScreenDesc"),
                                expression:
                                  "$t('followModeratorFullScreenDesc')",
                              },
                            ],
                            staticClass: "mr-3 tooltip-icon",
                            attrs: { size: "20", icon: "alert-circle" },
                          }),
                          _c("div", { staticClass: "label mr-3" }, [
                            _vm._v(_vm._s(_vm.$t("followModeratorFullScreen"))),
                          ]),
                          _c("v-switch", {
                            staticClass: "my-0",
                            attrs: {
                              color: "success",
                              disabled: !_vm.localUserIsModerator,
                              "hide-details": true,
                            },
                            model: {
                              value: _vm.config.followModeratorFullScreen,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.config,
                                  "followModeratorFullScreen",
                                  $$v
                                )
                              },
                              expression: "config.followModeratorFullScreen",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("StartMutedPolicySettings", {
                    attrs: {
                      "size-prop": { middle: "12", small: "12" },
                      disabled: !_vm.localUserIsModerator,
                      initialData: _vm.config,
                      remoteCameraControlSupported:
                        _vm.remoteCameraControlSupported,
                    },
                    on: { change: _vm.onChangeStartMutedPolicy },
                  }),
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.expansionPanel,
                        callback: function ($$v) {
                          _vm.expansionPanel = $$v
                        },
                        expression: "expansionPanel",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "head" }, [
                              _vm._v(" " + _vm._s(_vm.$t("otherConfig")) + " "),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "other-configs" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center w-full justify-space-between",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label mr-3" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("allowFileShare"))
                                              ),
                                            ]
                                          ),
                                          _c("v-switch", {
                                            staticClass: "my-0",
                                            attrs: {
                                              disabled:
                                                !_vm.localUserIsModerator,
                                              color: "success",
                                              "hide-details": true,
                                            },
                                            model: {
                                              value: _vm.config.allowFileShare,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.config,
                                                  "allowFileShare",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "config.allowFileShare",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center w-full justify-space-between mt-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label mr-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("allowPrivateChat")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("v-switch", {
                                            staticClass: "my-0",
                                            attrs: {
                                              disabled:
                                                !_vm.localUserIsModerator,
                                              color: "success",
                                              "hide-details": true,
                                            },
                                            model: {
                                              value:
                                                _vm.config.allowPrivateChat,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.config,
                                                  "allowPrivateChat",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "config.allowPrivateChat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center w-full justify-space-between mt-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label mr-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("allowScreenShare")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("v-switch", {
                                            staticClass: "my-0",
                                            attrs: {
                                              disabled:
                                                !_vm.localUserIsModerator,
                                              color: "success",
                                              "hide-details": true,
                                            },
                                            model: {
                                              value:
                                                _vm.config.allowScreenShare,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.config,
                                                  "allowScreenShare",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "config.allowScreenShare",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.remoteCameraControlSupported
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center w-full justify-space-between mt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label mr-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "allowAdminVideoStart"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("v-switch", {
                                                staticClass: "my-0",
                                                attrs: {
                                                  disabled:
                                                    !_vm.localUserIsModerator,
                                                  color: "success",
                                                  "hide-details": true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .allowAdminStartVideo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "allowAdminStartVideo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.allowAdminStartVideo",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }