<template>
  <span>{{ message.body }}</span>
</template>

<script>
export default {
  name: 'MessageText',
  props: {
    message: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
