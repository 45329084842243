var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticStyle: { "box-shadow": "0 0 20px 6px #000000" },
      attrs: {
        "content-class": _vm.contentClass,
        fullscreen: _vm.fullscreen,
        "hide-overlay": "",
        persistent: "",
        "no-click-animation": "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-0 ma-0",
          staticStyle: {
            "box-shadow": "0 0 20px 6px #000000",
            position: "relative",
          },
          on: {
            mouseenter: function ($event) {
              _vm.showControls = true
            },
            mouseleave: function ($event) {
              _vm.showControls = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "position: absolute; left:0; right: 0; bottom: 0; top:0;",
            },
            [
              _c("SharedVideo", {
                ref: "sharedVideo",
                attrs: { width: _vm.width, height: _vm.height },
              }),
            ],
            1
          ),
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            [
              _vm.showControls
                ? [
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$isMobile,
                          expression: "!$isMobile",
                        },
                      ],
                      key: "top-overlay",
                      staticClass: "top-overlay",
                      class: _vm.fullscreen ? "top-overlay-huge" : "",
                    }),
                    _vm.position === 3 || _vm.position === 9
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isMobile,
                                expression: "!$isMobile",
                              },
                            ],
                            key: "left",
                            staticClass: "pos-button pos-button-4 pa-0",
                            attrs: { small: "", color: "transparent" },
                            on: { click: _vm.moveLeft },
                          },
                          [_c("icon", { attrs: { icon: "arrow-left" } })],
                          1
                        )
                      : _vm._e(),
                    _vm.position === 1 || _vm.position === 7
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isMobile,
                                expression: "!$isMobile",
                              },
                            ],
                            key: "right",
                            staticClass: "pos-button pos-button-6 pa-0",
                            attrs: { small: "", color: "transparent" },
                            on: { click: _vm.moveRight },
                          },
                          [_c("icon", { attrs: { icon: "arrow-right" } })],
                          1
                        )
                      : _vm._e(),
                    _vm.position === 3 || _vm.position === 1
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isMobile,
                                expression: "!$isMobile",
                              },
                            ],
                            key: "top",
                            staticClass: "pos-button pos-button-8 pa-0",
                            attrs: { small: "", color: "transparent" },
                            on: { click: _vm.moveTop },
                          },
                          [
                            _c("icon", {
                              staticStyle: { transform: "rotate(90deg)" },
                              attrs: { icon: "arrow-left" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.position === 7 || _vm.position === 9
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isMobile,
                                expression: "!$isMobile",
                              },
                            ],
                            key: "bottom",
                            staticClass: "pos-button pos-button-2 pa-0",
                            attrs: { small: "", color: "transparent" },
                            on: { click: _vm.moveBottom },
                          },
                          [
                            _c("icon", {
                              staticStyle: { transform: "rotate(-90deg)" },
                              attrs: { icon: "arrow-left" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showCloseButton
                      ? _c(
                          "v-btn",
                          {
                            key: "close",
                            staticClass: "close-button",
                            attrs: { color: "transparent", small: "" },
                            on: { click: _vm.close },
                          },
                          [_c("icon", { attrs: { icon: "x" } })],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$isMobile,
                            expression: "!$isMobile",
                          },
                        ],
                        key: "fullscreen",
                        staticClass: "fullscreen-button",
                        attrs: { color: "transparent", small: "" },
                        on: { click: _vm.setFullscreen },
                      },
                      [
                        _vm.fullscreen
                          ? [_c("icon", { attrs: { icon: "minimize" } })]
                          : [_c("icon", { attrs: { icon: "maximize" } })],
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }