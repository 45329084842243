<template>
  <Modal persistent :show-close-button="false" :title="$t(title)" v-if="systemErrorCode">
    <div class="text-center">
      <template v-if="isDeviceError">
        <p>{{ $t('mediaDevicesUnreachable') }}</p>
        <p v-if="isIos">{{ $t('youCanUseSafari') }}</p>
        <p v-if="isMobile">{{ $t('dontHaveApp') }}</p>
        <a v-if="isMobile" :href="appLink">{{ $t('downloadTheApp') }}</a>
      </template>
      <template v-else>
        <p>{{ $t(this.message) }}</p>
      </template>
    </div>
    <div class="d-flex justify-center pt-4 mb-5">
      <v-btn color="secondary" :block="$isMobile" to="/" class="pl-10 pr-10">{{ $t('goToHome') }}</v-btn>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

import enums from '@/constants/ENUMS';
import { getOsName } from '@/helpers/os';

const iosLink = 'https://apps.apple.com/eg/app/xpermeet/id1544250116';
const androidLink = 'https://play.google.com/store/apps/details?id=com.bites.xpermeet';

export default {
  name: 'SystemErrorModal',
  computed: {
    ...mapState(['systemErrorCode']),
    isDeviceError() {
      return this.systemErrorCode === enums.UI_ERROR_CODES.UNSUPPORTED_DEVICE;
    },
    title() {
      switch (this.systemErrorCode) {
        case enums.UI_ERROR_CODES.UNSUPPORTED_DEVICE:
          return 'deviceAccessError';

        default:
          return 'error';
      }
    },
    message() {
      switch (this.systemErrorCode) {
        case enums.UI_ERROR_CODES.UNSUPPORTED_DEVICE:
          return 'deviceAccessError';

        case enums.UI_ERROR_CODES.USER_CANT_JOIN:
          return 'errorMessages.userCantJoin';

        default:
          return 'error';
      }
    },
    isIos() {
      return getOsName() === enums.OS.IOS;
    },
    appLink() {
      return this.isIos ? iosLink : androidLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.system-error {
  text-align: center;
  font-size: 20px;
  line-height: 1.5rem;
}
</style>
