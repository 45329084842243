<template>
  <a :target="target" class="app-link" :href="href" @click="onClick">{{ text }}</a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    href: {
      type: String,
    },
    text: {
      type: String,
    },
    to: {
      type: String,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  methods: {
    onClick(e) {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit('click', e);
      }
    },
  },
};
</script>
<style lang="scss">
.app-link {
  color: var(--v-link-base) !important;
  text-decoration: underline;
}
</style>
