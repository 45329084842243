<template>
  <div class="ar-right-menu pt-2">
    <v-toolbar class="flex-grow-0" dense color="transparent" elevation="0">
      <div class="text-body-1">{{ $t('ar.placementObject') }}</div>
    </v-toolbar>
    <div></div>
    <div class="d-flex flex-wrap px-3 align-start">
      <div v-for="button in selectionButtons" :key="`${button.value}-${button.actionType}`" class="mb-4" style="width: 25%;">
        <div
          class="menu-item d-flex align-center my-1 mx-auto justify-center flex-column cursor-pointer"
          :class="{ selected: selectedValue === button.value }"
          @click="onItemClicked(button)"
        >
          <icon :icon="button.icon" size="22" :style="{ opacity: selectedValue !== button.value ? '0.6' : '1' }" />
        </div>
        <div class="caption text-center mt-2 mx-auto">{{ $t(button.title) }}</div>
      </div>
    </div>
    <template v-if="showActionProperties">
      <div class="px-4 d-flex">
        <template v-if="selectedValue === ENUMS.AR_OBJECT_TYPES.TEXT">
          <text-field v-model="textActionTextValue" :label="$t('text')" @input="updateActionProperties"></text-field>
        </template>
      </div>
    </template>
    <v-toolbar class="flex-grow-0" dense color="transparent" elevation="0">
      <div class="text-body-1">{{ $t('ar.arTools') }}</div>
    </v-toolbar>
    <div class="d-flex flex-wrap px-3 align-start">
      <div v-for="button in arToolsButtons" :key="`${button.value}-${button.actionType}`" class="mb-4" style="width: 25%;">
        <div
          class="menu-item d-flex align-center my-1 mx-auto justify-center flex-column cursor-pointer"
          :class="{ selected: selectedValue === button.value }"
          @click="onItemClicked(button)"
        >
          <icon :icon="button.icon" size="22" :style="{ opacity: selectedValue !== button.value ? '0.6' : '1' }" />
        </div>
        <div class="caption text-center mt-2 mx-auto">{{ $t(button.title) }}</div>
      </div>
      <v-select
        v-model="selectedArItem"
        :items="getARFileList"
        item-text="name"
        item-value="fileKey"
        label="Select"
        persistent-hint
        return-object
        single-line
        clearable
        @input="onArListItemSelected"
      ></v-select>
    </div>
    <v-toolbar class="flex-grow-0" dense color="transparent" elevation="0">
      <div class="text-body-1">{{ $t('ar.placementObjectColor') }}</div>
    </v-toolbar>
    <div>
      <ColorBar v-model="color" @change="setColor" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import { ENUMS } from 'xpermeet-lib';
import Icon from '@/components/Shared/Icon';
import ColorBar from '@/components/Shared/ColorBar';
import { consoleLog } from 'xpermeet-lib/src/utils/logger';

export default {
  name: 'ArRightMenu',
  components: { ColorBar, Icon },
  data() {
    return {
      color: '#22B14C',
      selectedValue: -1,
      actionType: 0,
      ENUMS,
      selectedArItem: null,
      selectionButtons: [
        {
          title: 'ar.tools.arrow',
          icon: 'arrow-down-2',
          value: ENUMS.AR_OBJECT_TYPES.ARROW,
          actionType: ENUMS.AR_ACTION_TYPES.ADD,
        },
        {
          title: 'ar.tools.screwDriver',
          icon: 'screw-driver',
          value: ENUMS.AR_OBJECT_TYPES.SCREWDRIVER,
          actionType: ENUMS.AR_ACTION_TYPES.ADD,
        },
        {
          title: 'ar.tools.wrench',
          icon: 'wrench',
          value: ENUMS.AR_OBJECT_TYPES.WRENCH,
          actionType: ENUMS.AR_ACTION_TYPES.ADD,
        },
        {
          title: 'ar.tools.desk',
          icon: 'desk',
          value: ENUMS.AR_OBJECT_TYPES.DESK,
          actionType: ENUMS.AR_ACTION_TYPES.ADD,
        },
        {
          title: 'ar.tools.engine',
          icon: 'engine',
          value: ENUMS.AR_OBJECT_TYPES.ENGINE,
          actionType: ENUMS.AR_ACTION_TYPES.ADD,
        },
        {
          title: 'ar.tools.freeDrawing',
          icon: 'pencil',
          value: ENUMS.AR_OBJECT_TYPES.FREEDRAW,
          actionType: ENUMS.AR_ACTION_TYPES.ADD_DRAWING,
        },
        {
          title: 'ar.tools.textBubble',
          icon: 'text-bubble',
          value: ENUMS.AR_OBJECT_TYPES.TEXT,
          actionType: ENUMS.AR_ACTION_TYPES.ADD_TEXT,
        },
        {
          title: 'ar.tools.number',
          icon: 'number',
          value: ENUMS.AR_OBJECT_TYPES.NUMBER,
          actionType: ENUMS.AR_ACTION_TYPES.ADD_NUMBER,
        },
      ],
      arToolsButtons: [
        {
          title: 'ar.tools.interact',
          icon: 'interactive',
          value: ENUMS.AR_OBJECT_TYPES.INTERACTIVE,
          actionType: ENUMS.AR_ACTION_TYPES.INTERACT,
        },
        {
          title: 'ar.tools.eraser',
          icon: 'eraser-1',
          value: ENUMS.AR_OBJECT_TYPES.ERASER,
          actionType: ENUMS.AR_ACTION_TYPES.DELETE,
        },
        {
          title: 'ar.tools.clearAll',
          icon: 'trash',
          value: ENUMS.AR_OBJECT_TYPES.BIN,
          actionType: ENUMS.AR_ACTION_TYPES.DELETE_ALL,
        },
      ],
      textActionTextValue: '',
      numberActionNumberValue: 1,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getARFileList']),
    showActionProperties() {
      return this.actionType === ENUMS.AR_ACTION_TYPES.ADD_TEXT;
    },
  },
  created() {
    this.setArFileList();
  },
  methods: {
    ...mapActions('Conference', ['setRoomConfig', 'setArFileList']),
    setColor() {
      XperMeetLib.ARModule.setColor(this.color);
    },
    onArListItemSelected(item) {
      this.onItemClicked({
        value: item.fileKey,
        actionType: ENUMS.AR_ACTION_TYPES.ADD,
      });
    },
    onItemClicked(button) {
      this.selectedValue = button.value;
      this.actionType = button.actionType;
      consoleLog('arItemClicked: ', button);

      XperMeetLib.ARModule.setActionType(this.actionType);

      if (this.actionType === ENUMS.AR_ACTION_TYPES.DELETE_ALL) {
        XperMeetLib.ARModule.deleteAllARObjects();
      } else if (this.actionType === ENUMS.AR_ACTION_TYPES.ADD_NUMBER) {
        XperMeetLib.ARModule.setCustomValues([{ value: this.numberActionNumberValue }]);
      } else if (this.actionType === ENUMS.AR_ACTION_TYPES.ADD_TEXT) {
        // do nothing
      } else {
        XperMeetLib.ARModule.setObject(this.selectedValue);
        if (this.actionType === ENUMS.AR_ACTION_TYPES.DELETE) {
          XperMeetLib.ARModule.showDeletables();
        }
      }
    },
    updateActionProperties() {
      if (this.actionType === ENUMS.AR_ACTION_TYPES.ADD_TEXT) {
        XperMeetLib.ARModule.setCustomValues([{ value: this.textActionTextValue }]);
      }
    },
  },
};
</script>

<style lang="scss">
.ar-right-menu {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  color: #7d7d7d;
  $width: 70px;
  .menu-item {
    min-width: clamp($width, $width, $width);
    max-width: clamp($width, $width, $width);
    min-height: clamp($width, $width, $width);
    max-height: clamp($width, $width, $width);

    background-color: var(--v-primary-base);
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--v-white-base);

    &.selected {
      border: solid 2px var(--v-secondary-base);
      /*box-shadow: 0px 0px 10px 1px #15172a;*/

      filter: drop-shadow(0px 10px 10px #000000);
    }
  }

  .caption {
    max-width: $width;
    line-height: 18px;
  }
}
</style>
