var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("profileSettings"),
        "show-actions": "",
        "confirm-button-disabled": !_vm.localDisplayName,
        "confirm-button-text": _vm.$t("save"),
        "cancel-button-text": _vm.$t("cancel"),
        persistent: "",
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "mb-3" }, [_c("LangSwitch")], 1),
      _c(
        "div",
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c("text-field", {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("userName"),
                  "error-messages": _vm.errors,
                  "hide-details": "",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.localDisplayName,
                  callback: function ($$v) {
                    _vm.localDisplayName = $$v
                  },
                  expression: "localDisplayName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }