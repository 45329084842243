var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "webinar-container",
      class: { mobile: _vm.$isMobile },
      attrs: { id: "webinar-container" },
    },
    [
      _c(
        "v-container",
        { staticClass: "webinar-wrapper", attrs: { fluid: "" } },
        [
          _c("div", { staticClass: "webinar-entrance" }, [
            _c("div", { staticClass: "webinar-entrance-image-wrapper" }, [
              _c("img", { attrs: { src: "/img/webinar-entrance.svg" } }),
            ]),
          ]),
          _c("webinar-top-menu", {
            attrs: {
              "show-actions": !_vm.isLoggedOut && !_vm.autoPlayError,
              "is-webinar-started": _vm.isWebinarStarted,
            },
            on: {
              openSettingsModal: function ($event) {
                _vm.showProfileModal = true
              },
            },
          }),
          _vm.isWebinarEnded
            ? [
                _c("div", { staticClass: "webinar-ended" }, [
                  _c("span", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("webinarEndedDescription"))),
                  ]),
                ]),
              ]
            : _vm.isLoggedOut
            ? [
                _c("div", { staticClass: "webinar-ended" }, [
                  _c("span", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("webinarLoggedOut"))),
                  ]),
                ]),
              ]
            : _vm.isMaxViewerReached
            ? [
                _c("div", { staticClass: "webinar-ended" }, [
                  _c("span", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("webinarMaxViewerReached"))),
                  ]),
                ]),
              ]
            : [
                _c("div", { staticClass: "webinar-page" }, [
                  _c(
                    "div",
                    { staticClass: "webinar" },
                    [
                      _vm.getProtocol === "hls"
                        ? [
                            _vm.hlsLoading
                              ? _c(
                                  "span",
                                  { staticStyle: { "margin-bottom": "10rem" } },
                                  [_vm._v(_vm._s(_vm.$t("webinarStartsSoon")))]
                                )
                              : _vm._e(),
                            _c("video", {
                              key: "hls-player",
                              staticClass: "playing",
                              attrs: {
                                id: "webinar-video",
                                "data-setup": "{}",
                                autoplay: "",
                                playsinline: "",
                              },
                              domProps: { muted: _vm.speakerMuted },
                              on: {
                                dblclick: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.obDblClick.apply(null, arguments)
                                },
                                loadeddata: function ($event) {
                                  return _vm.handleEvent("loadeddata")
                                },
                              },
                            }),
                          ]
                        : [
                            !_vm.sldpLoading
                              ? _c(
                                  "div",
                                  {
                                    key: "sldp-player",
                                    attrs: { id: "webinar-element" },
                                    on: {
                                      dblclick: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.obDblClick.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showPoster,
                                            expression: "showPoster",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "webinar-preload" },
                                          [
                                            _c("logo", {
                                              attrs: { height: "80" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("welcome")) +
                                                    "!"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "content" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("webinarStartsSoon")
                                                  ) + "!"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        color: "#8e9bbb",
                                        indeterminate: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                    ],
                    2
                  ),
                ]),
              ],
          _vm.autoPlayError && !_vm.hlsLoading
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showPoster,
                      expression: "!showPoster",
                    },
                  ],
                  staticClass: "play-container",
                  on: { click: _vm.playVideo },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "cursor-pointer",
                      class: { mobile: _vm.$isMobile },
                      attrs: {
                        width: 259,
                        height: "82",
                        viewBox: "0 0 259 82",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M70.9502 69C63.468 77 52.8179 82 41 82C18.3563 82 0 63.6437 0 41C0 18.3563 18.3563 0 41 0C52.8179 0 63.468 5.00002 70.9502 13H251C255.418 13 259 16.5817 259 21V61C259 65.4183 255.418 69 251 69H70.9502ZM69.8173 69C62.5164 76.5126 52.3033 81.18 41 81.18C18.8092 81.18 0.82 63.1908 0.82 41C0.82 18.8092 18.8092 0.82 41 0.82C52.3033 0.82 62.5164 5.48738 69.8173 13H63.6293C57.4464 7.9968 49.5732 5 41 5C21.1177 5 5 21.1177 5 41C5 60.8823 21.1177 77 41 77C49.5732 77 57.4464 74.0032 63.6293 69H69.8173Z",
                          fill: "url(#paint0_linear)",
                        },
                      }),
                      _c(
                        "text",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: {
                            fill: "#F9F9F9",
                            "xml:space": "preserve",
                            "font-family": "Poppins",
                            "font-size": "18",
                            "font-weight": "500",
                            "letter-spacing": "0px",
                          },
                        },
                        [
                          _c("tspan", { attrs: { x: "83.9688", y: "47.3" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("webinarPlayButtonExplanation"))
                            ),
                          ]),
                        ]
                      ),
                      _c("path", {
                        attrs: {
                          d: "M40.6016 58.5996C50.5427 58.5996 58.6016 50.5407 58.6016 40.5996C58.6016 30.6585 50.5427 22.5996 40.6016 22.5996C30.6604 22.5996 22.6016 30.6585 22.6016 40.5996C22.6016 50.5407 30.6604 58.5996 40.6016 58.5996Z",
                          stroke: "white",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M37 33.4004L47.8 40.6004L37 47.8004V33.4004Z",
                          fill: "white",
                          stroke: "white",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _c(
                        "defs",
                        [
                          _c(
                            "linearGradient",
                            {
                              attrs: {
                                id: "paint0_linear",
                                x1: "55.528",
                                y1: "13.0908",
                                x2: "78.8546",
                                y2: "107.235",
                                gradientUnits: "userSpaceOnUse",
                              },
                            },
                            [
                              _c("stop", {
                                attrs: { "stop-color": "#7C8AEF" },
                              }),
                              _c("stop", {
                                attrs: {
                                  offset: "1",
                                  "stop-color": "#7C8AEF",
                                  "stop-opacity": "0.2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLoggedOut && !_vm.autoPlayError && _vm.isWebinarStarted
            ? _c("webinar-bottom-menu", {
                attrs: { speakerMuted: _vm.speakerMuted },
                on: {
                  itemClicked: _vm.onConferenceMenuItemClicked,
                  setLogout: _vm.setLogout,
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c("WebinarRightMenu", {
        attrs: { show: _vm.showChatScreen },
        on: { close: _vm.closeMenu },
      }),
      _vm.showProfileModal
        ? _c("ProfileModal", {
            attrs: { displayName: _vm.displayName },
            on: {
              save: _vm.onProfileModalSave,
              close: _vm.onProfileModalClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }