import JitsiMeetJS from "../JitsiMeetLib";
import Connection from "./ConnectionManager";
import ConnectionConfig from "./ConnectionConfig";
import LocalUser from "./LocalUser";
import EventEmitter from "../utils/EventEmitter";
import { consoleLog } from "../utils/logger"; // eslint-disable-line
import Queue from "../utils/Queue";
import RemoteUser from "./RemoteUser";
import { ERROR_WITH_CODES } from "../main";
import Webinar from "./Webinar";
import Lobby from "./Lobby";
import RoomConfig from "./RoomConfig";
import ARModule from "./ARModule";
import { VIDEO_QUALITY_LEVELS, MEDIA_DEVICE_TYPE, ENDPOINT_MESSAGE_TYPES } from "../constants/enums";
import Stats from "./Stats";
import RemoteRecordingManager from "./RemoteRecordingManager";

const eventEmitter = new EventEmitter();

/**
 * TODO: openBridgeChannel: "websocket", oldugunda APP.conference._room._restartMediaSessions()
 * alanında hatalar aazlıyor fakat test edilmesi gerekli...
 */
class ConferenceManager {
  constructor() {
    this.room = null;
    this.localUser = new LocalUser(this, eventEmitter);
    window.localUser = this.localUser;
    this.roomConfig = RoomConfig;
    this.isJoined = false;
    this.remoteUsers = [];
    this.dominantUserId = null;
    this.confOptions = Object.assign({
      openBridgeChannel: true,
      statisticsDisplayName: "xper_meet_stats",
    });
    this.startMutedPolicy = false;

    const trackAttachQueueCallback = (items) => {
      items.forEach(({ track, userId }) => {
        const userType = track.isLocal() ? "local" : "remote";
        const element = document.getElementById(`${userType}-${track.getType()}-${userId}`);

        if (!element) {
          return;
        }

        if (!track.isMuted()) {
          if (!track.containers.some((e) => e.id === element.id)) {
            track.attach(element);
          }
        }
      });
    };

    this.audioTrackAttachQueue = new Queue(trackAttachQueueCallback);
    this.videoTrackAttachQueue = new Queue(trackAttachQueueCallback);
  }

  /* Room Config */
  setRoomProperty(key, val) {
    this.roomConfig.setProperty(key, val);
  }

  shareSurvey(surveyName) {
    eventEmitter.emit("SurveyShared", surveyName);
  }
  setModeratorStopParticipantScreenShare(message) {
    const value = JSON.parse(message.value);
    if (value?.participantId && value?.participantId === this.localUser.getUser.id) {
      eventEmitter.emit("StopParticipantScreenShare");
    }
  }

  fetchRoomConfig() {
    return this.roomConfig.fetchDataSource();
  }

  isScreenShareAllowed() {
    const isModerator = this.room?.getRole() === "moderator";
    return isModerator || this.roomConfig.getProperty(this.roomConfig.enums.ALLOW_SCREEN_SHARE);
  }

  createRoom(config, name, roomPassword, autoJoin = true) {
    this.confOptions = {
      openBridgeChannel: true,
      statisticsDisplayName: "xper_meet_stats",
      ...config,
    };
    this.room = Connection.connection.initJitsiConference(name, this.confOptions);

    consoleLog("room created");
    this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack.bind(this));
    this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined.bind(this));
    this.room.on(JitsiMeetJS.events.conference.AUTH_STATUS_CHANGED, this.onAuthStateChanged.bind(this));

    this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onTrackRemoved.bind(this));
    this.room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined.bind(this));
    this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft.bind(this));
    this.room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackMuteChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.onTrackAudioLevelChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, this.onDisplayNameChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onUserRoleChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.USER_STATUS_CHANGED, this.onUserStatusChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.KICKED, this.onKicked.bind(this));
    this.room.on(JitsiMeetJS.events.conference.PARTICIPANT_KICKED, this.onParticipantKicked.bind(this));
    this.room.on(JitsiMeetJS.events.conference.AUDIO_INPUT_STATE_CHANGE, this.onAudioInputStateChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, this.onParticipantPropertyChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED, this.onStartMutedPolicyChanged.bind(this));

    this.room.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, this.onDominantUserChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.CONFERENCE_CREATED_TIMESTAMP, this.onConferenceTimestampChanged.bind(this));
    this.room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, this.onPhoneNumberChanged.bind(this));
    this.room.on(JitsiMeetJS.errors.conference.PASSWORD_REQUIRED, this.onPasswordRequired.bind(this));

    this.room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, this.onConferenceFailed.bind(this));
    this.room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, this.onMessageReceived.bind(this));
    this.room.on(JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED, this.onPrivateMessageReceived.bind(this));
    this.room.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, this.onEndpointMessageReceived.bind(this));
    this.room.on(JitsiMeetJS.events.conference.CHAT_ERROR, this.onChatError.bind(this));
    this.room.on(JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED, this.onParticipantConnStatusChanged.bind(this));

    this.room.on(JitsiMeetJS.events.conference.LOCK_STATE_CHANGED, this.onLockStateChanged.bind(this));

    this.room.room.on("xmpp.meeting_id_set", this.onMeetingIdSet.bind(this));

    /* Remote Record Events */
    RemoteRecordingManager.handleEvents();

    /* Handle Lobby Events */
    Lobby.handleEvents();

    /* Handle Webinar Events */
    Webinar.getInstance().handleEvents();

    /* Handle Stats Events */
    Stats.handleEvents();

    this.room.addCommandListener("REDIRECT_TO_WEBINAR", this.redirectToWebinar.bind(this));

    // this.room.addCommandListener("MUTE_AUDIO", this.muteAudioByModerator.bind(this));
    // this.room.addCommandListener("MUTE_VIDEO", this.muteVideoByModerator.bind(this));
    this.room.addCommandListener("SET_MODERATOR_MUTED_AUDIO", this.setModeratorMutedAudio.bind(this));
    this.room.addCommandListener("SET_MODERATOR_MUTED_VIDEO", this.setModeratorMutedVideo.bind(this));
    this.room.addCommandListener("STOP_PARTICIPANT_SCREEN_SHARE", this.setModeratorStopParticipantScreenShare.bind(this));
    this.room.addCommandListener("SET_HANDS_DOWN", this.setHandsDown.bind(this));
    this.room.addCommandListener("SURVEY_SHARED", this.shareSurvey.bind(this));

    // The command name registered as kebab-case ("shared-video") because it is registered same name on XperMeet Mobile
    this.room.addCommandListener("shared-video", this.setSharedVideo.bind(this));

    let connectionConfiguration = ConnectionConfig(window.XPER_CONFIG);

    /* if connectionConfiguration has value channelLastN */
    if (connectionConfiguration.channelLastN > -1) {
      this.room.setLastN(connectionConfiguration.channelLastN);
    }

    this.localUser.setUserId(this.room.myUserId());

    eventEmitter.emit("LocalUserJoined", this.localUser.getUser);

    this.localUser.setKeycloakId();

    consoleLog("checkConnecton");

    if (autoJoin) {
      // TODO burasını nasıl handle etmek gerekir, incele?
      // this.checkRoomIsLocked();
      const conferencePassword = roomPassword || undefined;
      if (conferencePassword) {
        this.room.join(conferencePassword);

        // TODO Neden settimeout gerekli ?
        setTimeout(() => {
          if (this.room.getRole() === "moderator") {
            this.room.lock(conferencePassword);
            this.room.authEnabled = true;
          }
        }, 5000);
      } else {
        this.room.join();
      }
    }
  }

  redirectToWebinar(message) {
    if (message && message?.tagName === "REDIRECT_TO_WEBINAR") {
      const value = JSON.parse(message.value);
      if (value.userId === this.localUser.getUser.id) {
        eventEmitter.emit("RedirectToWebinar");
      }
    }
  }

  onUserRoleChanged(userId, role) {
    if (this.localUser.getUser.id === userId) {
      this.localUser.setUserRole(role);
      this.roomConfig.setStartingSettings();
    } else {
      // Do nothing because of remote user role getting from jitsi
    }
    eventEmitter.emit("UserRoleChanged", { userId, role });
  }

  // eslint-disable-next-line
  onUserStatusChanged(userId, role) { }

  kickParticipant(participantId, reason) {
    this.room.kickParticipant(participantId, reason);
  }

  kickAllParticipants() {
    this.remoteUsers.forEach((user) => {
      this.kickParticipant(user.id, "CLOSE_ROOM");
    });
  }

  onParticipantKicked(actorParticipant, kickedParticipant, reason) {
    eventEmitter.emit("UserKicked", {
      actorParticipantId: actorParticipant?.getId() || this.localUser.getUser.id,
      kickedParticipantId: kickedParticipant.getId(),
      reason,
    });
  }

  onKicked(actorParticipant, reason) {
    eventEmitter.emit("Kicked", { actorParticipantId: actorParticipant?.getId(), reason });
  }

  grantModeratorRole(participantId) {
    const participant = this.room.getParticipantById(participantId);

    if (!participant) {
      consoleLog(`grantModeratorRole: Participant not found: ${participantId}`);
      return;
    }

    this.room.room.setAffiliation(participant.getConnectionJid(), "owner");
  }

  sendWebinarStreamId(webinarStreamId, participantId) {
    // Send endpoint message
    const data = {
      type: ENDPOINT_MESSAGE_TYPES.WEBINAR_STREAM_ID,
      webinarStreamId,
    };
    this.sendMessage(data, participantId);
  }

  revokeModeratorRole(participantId) {
    const participant = this.room.getParticipantById(participantId);

    if (!participant) {
      consoleLog(`revokeModeratorRole: Participant not found: ${participantId}`);
      return;
    }

    this.room.room.setAffiliation(participant.getConnectionJid(), "member");
  }

  // eslint-disable-next-line
  onAudioInputStateChanged() { }

  sendMessage(message, userId) {
    this.room.sendMessage(message, userId);
  }

  sendCustomNotification(customType, message, sender, data = {}) {
    this.room.sendEndpointMessage("", { message, type: ENDPOINT_MESSAGE_TYPES.CHAT_NOTIFICATION, customType, time: new Date(), sender, data });
  }

  onParticipantPropertyChanged(user, property) {
    let value = user.getProperty(property);

    if (property === "ScreenSharing") {
      const event = value === "true" ? "ScreenShareStarted" : "ScreenShareStopped";
      eventEmitter.emit(event, { userId: user.getId() });
    } else if (property === "AR_TRACK") {
      const isEnabled = value === "true";

      if (isEnabled) {
        // TODO AR kullanıcısı odaya girdiğinde video element oluşmadan event listener eklenmeye çalışıyor ve hata alıyor.
        // bu sebepten 2 saniye erteleme yapıldı.
        setTimeout(() => {
          ARModule.enableAREventHandler(user._id);
        }, 2000);
      } else {
        ARModule.disableAREventHandler();
      }
      eventEmitter.emit("UserPropertyChange", { userId: user.getId(), property: "ARTrack", value });
    } else {
      eventEmitter.emit("UserPropertyChange", { userId: user.getId(), property, value });
    }
  }

  onConferenceTimestampChanged(timestamp) {
    eventEmitter.emit("ConferenceTimestampChanged", timestamp);
  }

  // eslint-disable-next-line
  onDominantUserChanged(...args) {
    const userId = args[0];

    this.dominantUserId = userId;

    if (this.localUser.getUser.id !== userId) {
      const audioQueueIndex = this.audioTrackAttachQueue.stack.findIndex((item) => item.userId === userId);
      if (audioQueueIndex > 0) {
        this.audioTrackAttachQueue.move(audioQueueIndex, 0);
      } else if (audioQueueIndex === -1) {
        const participant = this.room.getParticipantById(userId);
        const tracks = participant.getTracksByMediaType("audio");
        if (tracks.length) {
          const track = tracks[tracks.length - 1];
          this.attachTrackToElement(null, null, userId, track);
          this.audioTrackAttachQueue.move(this.audioTrackAttachQueue.stack.length - 1, 0);
        }
      }
    }
    if (this.localUser.getUser.id !== userId) {
      const videoQueueIndex = this.videoTrackAttachQueue.stack.findIndex((item) => item.userId === userId);

      if (videoQueueIndex > 0) {
        this.videoTrackAttachQueue.move(videoQueueIndex, 0);
      } else if (videoQueueIndex === -1) {
        const participant = this.room.getParticipantById(userId);
        const tracks = participant.getTracksByMediaType("video");
        if (tracks.length) {
          const track = tracks[tracks.length - 1];
          this.attachTrackToElement(null, null, userId, track);
          this.videoTrackAttachQueue.move(this.videoTrackAttachQueue.stack.length - 1, 0);
        }
      }
    }
    eventEmitter.emit("onDominantUserChanged", args);
  }

  onPhoneNumberChanged() {
    consoleLog(`${this.room.getPhoneNumber()} - ${this.room.getPhonePin()}`);
  }

  onPasswordRequired(isAuthEnabled, authIdentity) {
    consoleLog("onPasswordRequired", isAuthEnabled, authIdentity);
  }

  onStartMutedPolicyChanged(policy) {
    eventEmitter.emit("StartMutedPolicyChanged", { policy, policyType: this.getStartMutedPolicyType(policy) });
  }

  // messages
  onMessageReceived(...args) {
    eventEmitter.emit("onMessageReceived", args);
  }

  onPrivateMessageReceived(...args) {
    eventEmitter.emit("onPrivateMessageReceived", args);
  }

  onEndpointMessageReceived(...args) {
    const endpointMessage = args[1];
    if (endpointMessage && typeof endpointMessage === "object" && "type" in endpointMessage) {
      switch (endpointMessage.type) {
        case ENDPOINT_MESSAGE_TYPES.WEBINAR_STREAM_ID:
          const { webinarStreamId } = endpointMessage;
          Webinar.getInstance().changeRole(webinarStreamId);
          break;
        case ENDPOINT_MESSAGE_TYPES.CHAT_NOTIFICATION:
          const { message, customType, time, sender, data } = endpointMessage;
          eventEmitter.emit("onChatNotificationMessageReceived", { message, type: customType, time, sender, data });
          break;
      }
    }

    eventEmitter.emit("onEndpointMessageReceived", args);
  }

  onConferenceFailed(errorCode) {
    if (this.localUser.lobbyAutoJoinRequest) {
      if (errorCode === ERROR_WITH_CODES.MEMBERS_ONLY) {
        Lobby.join(this.localUser.getUser.displayName).then(() => {
          Webinar.getInstance().informJid();
          Webinar.getInstance().closeWS();
          Webinar.getInstance().clearEvents();
        });
      }
    } else {
      eventEmitter.emit("ConferenceFailed", errorCode);
    }
  }

  onLockStateChanged(locked) {
    eventEmitter.emit("LockStateChanged", locked);
  }

  onChatError() { }

  onParticipantConnStatusChanged(userId, status) {
    /* status can be interrupted (means user connection lost) or active */
    eventEmitter.emit("ParticipantConnStatusChanged", { userId, status });
  }
  /**
   *
   * @param containerId
   * @param userType 'local'|'remote'
   * @param userId
   * @param track Track Object
   */
  attachTrackToElement(element, userType, userId, track) {
    if (track.getType() === "audio") {
      this.audioTrackAttachQueue.enqueue({ element, track, userId });

      if (userId === this.dominantUserId) {
        this.audioTrackAttachQueue.move(this.audioTrackAttachQueue.stack.length - 1, 0);
      }
    } else {
      this.videoTrackAttachQueue.enqueue({ element, track, userId });

      if (userId === this.dominantUserId) {
        this.videoTrackAttachQueue.move(this.videoTrackAttachQueue.stack.length - 1, 0);
      }
    }
  }

  onRemoteTrack(track) {
    if (track.isLocal()) {
      return;
    }

    const participantId = track.getParticipantId();
    const remoteUser = this.getRemoteUserById(participantId);

    if (!remoteUser) {
      return;
    }
    remoteUser.addTrack(track);

    eventEmitter.emit("RemoteTrackAdded", { participantId, track });
  }

  onTrackRemoved(track) {
    track.containers.forEach((container) => {
      consoleLog("track detached");
      track.detach(container);
    });

    if (track.getType() === "audio") {
      const audioQueueIndex = this.audioTrackAttachQueue.stack.findIndex((item) => item.userId === track.getParticipantId());
      if (audioQueueIndex > -1) {
        this.audioTrackAttachQueue.remove(audioQueueIndex);
      }
    } else {
      const videoQueueIndex = this.videoTrackAttachQueue.stack.findIndex((item) => item.userId === track.getParticipantId());
      if (videoQueueIndex > -1) {
        this.videoTrackAttachQueue.remove(videoQueueIndex);
      }
    }

    if (track.isLocal()) {
      eventEmitter.emit("LocalTrackRemoved", { userId: this.localUser.getUser.id, track });
    } else {
      eventEmitter.emit("RemoteTrackRemoved", { userId: track.getParticipantId(), track });
      const participant = this.room.participants[track.getParticipantId()];
      if (participant) {
        const tracks = participant.getTracksByMediaType(track.getType());
        const eventName = track.getType() === "audio" ? "UserAudioMuteChanged" : "UserVideoMuteChanged";
        if (!tracks.length) {
          eventEmitter.emit(eventName, { userId: track.getParticipantId(), muteState: true });
        }
      }
    }
  }

  // eslint-disable-next-line
  onUserJoined(id, user) {
    if (!id) {
      return;
    }

    if (user.getStatsID() === "jibri" && user.isHidden()) {
      return;
    }

    const remoteUser = new RemoteUser(id, user, this, eventEmitter);
    this.remoteUsers.push(remoteUser);

    eventEmitter.emit("RemoteUserJoined", remoteUser.getUser);
  }

  getRemoteUserById(id) {
    return this.remoteUsers.find((item) => item.getUser.id === id);
  }

  onUserLeft(id) {
    const audioQueueIndex = this.audioTrackAttachQueue.stack.findIndex((item) => item.userId === id);
    if (audioQueueIndex > -1) {
      this.audioTrackAttachQueue.remove(audioQueueIndex);
    }

    const videoQueueIndex = this.videoTrackAttachQueue.stack.findIndex((item) => item.userId === id);
    if (videoQueueIndex > -1) {
      this.videoTrackAttachQueue.remove(videoQueueIndex);
    }

    const remoteUser = this.getRemoteUserById(id);

    if (!remoteUser) {
      return;
    }

    eventEmitter.emit("RemoteUserLeft", id);

    remoteUser.tracks.forEach((track) => {
      track.containers.forEach((container) => {
        consoleLog("track detached");
        track.detach(container);
        //container.remove();
      });
    });

    const index = this.remoteUsers.findIndex((item) => item.getUser.id === id);
    if (index > -1) {
      this.remoteUsers.splice(index, 1);
    }
  }

  onTrackMuteChanged(track) {
    const userId = track.getParticipantId();

    if (!track.isMuted()) {
      this.attachTrackToElement(null, null, userId, track);
    }

    consoleLog(`Conference Mute Changed ${userId} ${track.getType()} - ${track.isMuted()}`);
    if (track.getType() === MEDIA_DEVICE_TYPE.AUDIO) {
      eventEmitter.emit("UserAudioMuteChanged", { userId, muteState: track.isMuted() });
    } else if (track.getType() === "video") {
      eventEmitter.emit("UserVideoMuteChanged", { userId, muteState: track.isMuted() });
    }
  }

  // eslint-disable-next-line
  onTrackAudioLevelChanged(userID, audioLevel) {
    // consoleLog(`${userID} - ${audioLevel}`);
  }

  onDisplayNameChanged(userId, displayName) {
    consoleLog(`${userId} - ${displayName}`);
    eventEmitter.emit("UserDisplayNameChanged", { userId, displayName });
  }

  onAuthStateChanged(isAuthEnabled, authIdentity) {
    consoleLog("onAuthStateChanged", isAuthEnabled, authIdentity);

    const authenticated = !!authIdentity;

    const keycloakId = this.room.getLocalParticipantProperty("KeycloakId");
    if (keycloakId) {
      this.localUser.setLocalParticipantProperty("authenticated", authenticated.toString());
    } else {
      this.localUser.setLocalParticipantProperty("authenticated", "false");
    }
  }

  onConferenceJoined() {
    this.isJoined = true;
    consoleLog("onConferenceJoined");

    //for (let i = 0; i < this.localUser.tracks.length; i++) {
    // this.room.addTrack(this.localUser.tracks[i]);
    //}

    eventEmitter.emit("ConferenceJoined");

    // Emit lock state to participants
    eventEmitter.emit("LockStateChanged", this.room.room.locked);

    // Set video constraint on start
    this.localUser.setSenderVideoConstraint(VIDEO_QUALITY_LEVELS.HIGH);
    this.localUser.initWithRoomConfig();
  }

  handsDown(participantId) {
    if (this.room.getRole() === "moderator") {
      this.room.sendCommandOnce("SET_HANDS_DOWN", {
        value: JSON.stringify({
          participantId,
        }),
      });
    }
  }

  sendShareSurveyCommand() {
    if (this.room.getRole() === "moderator") {
      this.room.sendCommandOnce("SURVEY_SHARED", {
        value: JSON.stringify({
          username: this.localUser.getUser.displayName,
        }),
      });
    }
  }

  muteRemoteAudio(participantId, muteState) {
    if (this.room.getRole() === "moderator") {
      this.room.sendCommandOnce("SET_MODERATOR_MUTED_AUDIO", {
        value: JSON.stringify({
          muteState: muteState,
          participantId,
        }),
      });
    }
  }

  muteRemoteVideo(participantId, muteState) {
    if (this.room.getRole() === "moderator") {
      this.room.sendCommandOnce("SET_MODERATOR_MUTED_VIDEO", {
        value: JSON.stringify({
          muteState: muteState,
          participantId,
        }),
      });
    }
  }

  stopParticipantScreenShare(participantId) {
    if (this.room.getRole() === "moderator") {
      this.room.sendCommandOnce("STOP_PARTICIPANT_SCREEN_SHARE", {
        value: JSON.stringify({
          participantId,
        }),
      });
    }
  }

  // eslint-disable-next-line
  setHandsDown(message, userId) {
    if (message && message?.tagName !== "SET_HANDS_DOWN") {
      return;
    }

    if (userId === this.localUser.getUser.id && this.localUser.getUser.role !== "moderator") {
      return;
    } else {
      const participant = this.getRemoteUserById(userId);
      if (participant.getUser.role !== "moderator") {
        return;
      }
    }

    const value = JSON.parse(message.value);
    if (value.participantId === this.localUser.getUser.id) {
      this.localUser.handsUp("false");
    }
  }
  // eslint-disable-next-line
  setModeratorMutedAudio(message, userId) {
    if (message && message?.tagName === "SET_MODERATOR_MUTED_AUDIO") {
      if (userId === this.localUser.getUser.id) {
        if (this.localUser.getUser.role !== "moderator") {
          return;
        }
      } else {
        const participant = this.getRemoteUserById(userId);
        if (participant.getUser.role !== "moderator") {
          return;
        }
      }

      const value = JSON.parse(message.value);
      if (value.participantId === this.localUser.getUser.id || value.participantId === "all") {
        this.localUser.muteAudio(value.muteState).then(() => {
          const userCanUnmute = this.roomConfig.getProperty(this.roomConfig.enums.START_MUTED_POLICY) === this.roomConfig.enums.USER_AUDIO_MUTED;
          const allowAdminStartVideo = this.roomConfig.getProperty(this.roomConfig.enums.ALLOW_ADMIN_START_VIDEO);
          const moderatorMuted = value.muteState && allowAdminStartVideo && !userCanUnmute;
          let moderatorDisplayName;
          if (userId === this.localUser.getUser.id) {
            moderatorDisplayName = this.localUser.getUser.displayName;
          } else {
            const participant = this.getRemoteUserById(userId);
            moderatorDisplayName = participant.getUser.displayName;
          }
          this.localUser.setAudioMutedByModerator(moderatorMuted);
          eventEmitter.emit("UserAudioMuteChangedByModerator", {
            userId: this.localUser.getUser.id,
            muteState: value.muteState,
            updatedBy: userId,
            moderatorDisplayName,
            userCanUnmute,
          });
        });
      }
    }
  }

  sharedVideoUpdate(videoId, status, localParticipantId, time, volume, muted) {
    this.room.sendCommandOnce("shared-video", {
      attributes: {
        from: localParticipantId || this.localUser.getUser.id,
        state: status,
        time,
        volume,
        muted,
      },
      value: videoId,
    });
  }

  setSharedVideo(message, userId) {
    const { value, attributes, provider = "youtube" } = message;
    const { from, time, volume, muted } = attributes;
    const status = attributes.state;

    eventEmitter.emit("SharedVideo", { userId, value, from, status, provider, time, volume, muted });
  }

  // eslint-disable-next-line
  setModeratorMutedVideo(message, userId) {
    if (message && message?.tagName === "SET_MODERATOR_MUTED_VIDEO") {
      if (userId === this.localUser.getUser.id) {
        if (this.localUser.getUser.role !== "moderator") {
          return;
        }
      } else {
        const participant = this.getRemoteUserById(userId);
        if (participant.getUser.role !== "moderator") {
          return;
        }
      }

      const value = JSON.parse(message.value);
      if (value.participantId === this.localUser.getUser.id || value.participantId === "all") {
        this.localUser.muteVideo(value.muteState).then(() => {
          const userCanUnmute = this.roomConfig.getProperty(this.roomConfig.enums.START_MUTED_VIDEO_POLICY) === this.roomConfig.enums.START_MUTED_VIDEO_POLICY;
          const allowAdminStartVideo = this.roomConfig.getProperty(this.roomConfig.enums.ALLOW_ADMIN_START_VIDEO);
          const moderatorMuted = allowAdminStartVideo && value.muteState;
          let moderatorDisplayName;
          if (userId === this.localUser.getUser.id) {
            moderatorDisplayName = this.localUser.getUser.displayName;
          } else {
            const participant = this.getRemoteUserById(userId);
            moderatorDisplayName = participant.getUser.displayName;
          }
          this.localUser.setVideoMutedByModerator(moderatorMuted);
          eventEmitter.emit("UserVideoMuteChangedByModerator", {
            userId: this.localUser.getUser.id,
            muteState: value.muteState,
            updatedBy: userId,
            moderatorDisplayName,
            userCanUnmute,
          });
        });
      }
    }
  }

  lock(password) {
    this.room.lock(password);
  }

  unlock() {
    this.room.unlock();
  }

  e2eeEncrypt(key) {
    this.room.setE2EEKey(key);
  }

  e2eeSupported() {
    return this.room.isE2EESupported();
  }

  selectParticipant(participantId) {
    this.room.selectParticipant(participantId);
    eventEmitter.emit("ParticipantSelected");
  }

  selectParticipants(participantId) {
    this.room.selectParticipants(participantId);
    eventEmitter.emit("ParticipantsSelected");
  }

  setLastN(lastN) {
    if (this.room.getLastN() !== lastN) {
      this.room.setLastN(lastN);
    }
  }

  /**
   *
   * {
   *  'lastN': 20, // Number of videos requested from the bridge.
   *  'selectedEndpoints': ['A', 'B', 'C'], // The endpoints ids of the participants that are prioritized first.
   *  'onStageEndpoints': ['A'], // The endpoint ids of the participants that are prioritized up to a higher resolution.
   *  'defaultConstraints': { 'maxHeight': 180 }, // Default resolution requested for all endpoints.
   *  'constraints': { // Endpoint specific resolution.
   *      'A': { 'maxHeight': 720 }
   *    }
   *  }
   */
  setReceiverConstraints(constraints) {
    this.room.setReceiverConstraints(constraints);
  }

  // Only for mobile app
  setStartMutedPolicy({ startMutedPolicy, startVideoMutedPolicy }) {
    const { MODERATOR_AUDIO_MUTED, MODERATOR_VIDEO_MUTED, USER_AUDIO_MUTED, USER_VIDEO_MUTED } = this.roomConfig.enums;

    const policy = {
      audio: false,
      video: false,
      moderatorAudio: false,
      moderatorVideo: false,
    };

    switch (startMutedPolicy) {
      case USER_AUDIO_MUTED:
        policy.audio = true;
        break;

      case MODERATOR_AUDIO_MUTED:
        policy.user = true;
        policy.moderatorAudio = true;
        break;

      default:
        break;
    }

    switch (startVideoMutedPolicy) {
      case USER_VIDEO_MUTED:
        policy.video = true;
        break;

      case MODERATOR_VIDEO_MUTED:
        policy.video = true;
        policy.moderatorVideo = true;
        break;

      default:
        break;
    }

    this.room.setStartMutedPolicy(policy);
  }

  getStartMutedPolicyType(policy) {
    if (!policy || !("audio" in policy) || !("video" in policy)) return;

    if (policy.audio === false && policy.video === false) {
      return "startConferenceAudioUnmuted";
    } else if (policy.audio === true && policy.video === false) {
      return "startConferenceAudioMuted";
    }
    // TODO startConferenceAudioMutedModerator will be added
    // else if (policy.audio === true && policy.video === false) {
    //   return "startConferenceAudioMutedModerator";
    // }
  }

  onMeetingIdSet(meetingId) {
    eventEmitter.emit("MeetingIdSet", { meetingId });
  }

  emit(eventType, callbackFn) {
    eventEmitter.emit(eventType, callbackFn);
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }

  off(eventType, callbackFn) {
    eventEmitter.off(eventType, callbackFn);
  }

  clearEvents() {
    this.localUser.clearEvents();
    eventEmitter.clear();
  }
}

export default new ConferenceManager();
