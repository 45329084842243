import errorHandler from 'xpermeet-lib/src/utils/ErrorHandler';
import XperMeetLib from '../../xpermeet-lib';
import { ENUMS } from '../../xpermeet-lib';
import store from '@/store';
import { TOAST_TYPE } from '@/constants/toast';

errorHandler.onError(err => {
  if (XperMeetLib.conference.localUser.getUser.isJibri) {
    return;
  }
  if (err.code === ENUMS.ERROR_HANDLER_ENUMS.NETWORK_ERROR || err.code === ENUMS.ERROR_HANDLER_ENUMS.STROPHE_ERROR) {
    setTimeout(async () => {
      if (store.state.Notification.toastNotifications.some(t => t.body === 'networkError')) {
        return;
      }

      store.dispatch('Notification/showToastNotification', {
        body: 'networkError',
        config: {
          type: TOAST_TYPE.ERROR,
          timeout: 0,
          buttons: [
            {
              text: 'close',
              close: true,
            },
            {
              text: 'reload',
              action: () => {
                location.reload();
              },
              close: true,
              bold: true,
            },
          ],
        },
      });
    }, 2000);
  }
});

export default errorHandler;
