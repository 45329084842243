var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-menu", class: { show: _vm.show } }, [
    _c(
      "div",
      { staticClass: "right-menu--header" },
      [
        _c(
          "div",
          { staticClass: "py-6 px-4 close-icon" },
          [
            _c("icon", {
              attrs: { icon: "x" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "v-tabs",
          {
            attrs: {
              value: _vm.activeRightMenuTab,
              grow: "",
              "show-arrows": "",
              "icons-and-text": "",
            },
          },
          [
            _c(
              "v-tab",
              {
                attrs: { href: "#chat" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("chat")
                  },
                },
              },
              [
                _c("icon", { attrs: { icon: "message-circle" } }),
                _c("span", [_vm._v(_vm._s(_vm.$t("chat")))]),
              ],
              1
            ),
            _c(
              "v-tab",
              {
                attrs: { href: "#user-list" },
                on: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("user-list")
                  },
                },
              },
              [
                _c("icon", { attrs: { icon: "users" } }),
                _c("span", [_vm._v(_vm._s(_vm.$t("userList")))]),
              ],
              1
            ),
            _vm.isSurveyUsable
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#survey" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.SET_CURRENT_RIGHT_MENU_TAB("survey")
                      },
                    },
                  },
                  [
                    _c("icon", { attrs: { icon: "check-circle" } }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("survey")))]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-tab",
              {
                attrs: { href: "#shared-files", ripple: false },
                nativeOn: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("shared-files")
                  },
                },
              },
              [
                _c("icon", { attrs: { icon: "folder" } }),
                _c("span", { staticClass: "text-capitalize" }, [
                  _vm._v(" " + _vm._s(_vm.$t("sharedFiles")) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "v-tabs-items",
          {
            staticStyle: { "flex-grow": "1" },
            attrs: { touchless: "", value: _vm.activeRightMenuTab },
          },
          [
            _c(
              "v-tab-item",
              { attrs: { value: "chat" } },
              [
                _c("Chat", {
                  attrs: {
                    "show-actions": "",
                    "body-id": "webinar-chat",
                    "chat-enabled": _vm.isWebinarStarted,
                    messages: _vm.messages,
                    "show-avatar": false,
                    "show-file-share-button": _vm.fileShareSupported,
                  },
                  on: {
                    sendButtonClicked: _vm.onSendButtonClicked,
                    scrollEnd: _vm.onWebinarChatScrollEnd,
                    readMessages: _vm.READ_WEBINAR_MESSAGES,
                  },
                }),
              ],
              1
            ),
            _c(
              "v-tab-item",
              { attrs: { value: "user-list" } },
              [_c("WebinarUsers")],
              1
            ),
            _c(
              "v-tab-item",
              { attrs: { value: "survey" } },
              [
                _c("survey", {
                  attrs: { isModerator: false, isWebinarSurvey: true },
                }),
              ],
              1
            ),
            _c(
              "v-tab-item",
              { attrs: { value: "shared-files" } },
              [_c("shared-file-list", { attrs: { "is-webinar": "" } })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }