var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lang-switch-temp text-center" },
    [
      _c(
        "v-menu",
        {
          staticClass: "text-center lang-switch-menu",
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "text-uppercase change-lang-btn",
                          attrs: {
                            height: "30",
                            width: "60",
                            small: "",
                            color: "var(--v-date-20)",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("span", { staticClass: "lang-text" }, [
                        _vm._v(_vm._s(_vm.lang)),
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1 lang-switch-chevron-icon",
                          attrs: {
                            right: "",
                            "x-small": "",
                            left: "",
                            dense: "",
                          },
                        },
                        [_vm._v("feather icon-chevron-down")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            {
              staticClass: "select-language-list ma-0 pa-0",
              attrs: { color: "blackrussian", tile: "" },
            },
            _vm._l(_vm.languages, function (language, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  staticClass:
                    "px-0 select-language-list-items d-flex justify-center align-center",
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-uppercase select-lang-button change-lang-btn",
                      attrs: {
                        small: "",
                        width: "52",
                        height: "18",
                        color:
                          _vm.lang === language
                            ? "var(--v-date-20)"
                            : "transparent",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setLang(language)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "lang-text" }, [
                        _vm._v(" " + _vm._s(language) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }