<template>
  <div class="profile-settings  pl-6 pr-3">
    <v-col class="profile-settings-content pt-0 pb-0">
      <div class="mb-5 ml-2 profile-settings-title">
        {{ $t('profileSettings') }}
      </div>
      <div class="avatar-username-container align-center d-flex">
        <avatar-uploader :localDisplayName="localDisplayName" class="mr-6 mb-2"></avatar-uploader>
        <text-field
          data-test-id="M-S-PS-UN"
          class="mb-2"
          v-model="localDisplayName"
          :disabled="!displayNameChangeable"
          :label="$t('userName')"
          :error-messages="errors"
          hide-details
          @keydown.enter.prevent="save"
        ></text-field>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import storage from '@/services/storage';
import { PREDEFINED_AVATARS } from '@/constants/avatar';
import AvatarUploader from '@/components/AvatarUpload/AvatarUploader';
import XperMeetLib from 'xpermeet-lib';

export default {
  name: 'ProfileModal',
  components: {
    AvatarUploader,
  },
  data() {
    return {
      userHasToken: !!window.localStorage.getItem('keycloak-token'),
      localDisplayName: '',
      errors: null,
      staticAvatars: PREDEFINED_AVATARS,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser']),
    displayNameChangeable() {
      const authenticated = XperMeetLib?.keycloakManager?.keycloakInstance?.authenticated;
      if (storage.getItem('disableDisplayNameChange') === true) {
        return false;
      } else if ((this.getLocalUser.role === 'moderator' || authenticated) && this.$customerConfig?.system?.users?.allowChangeDisplayName === false) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations('Conference', ['UPDATE_USER']),
    ...mapActions('Conference', ['setDisplayName', 'setMasterKeyCustomer', 'setAvatar']),
    save() {
      this.errors = !this.localDisplayName ? [this.$t('requiredField')] : null;
      if (this.localDisplayName) {
        this.setDisplayName(this.localDisplayName);
      }
    },
    close(type) {
      this.$emit('close', type);
    },
  },
  watch: {
    'getLocalUser.displayName': {
      handler: function() {
        this.localDisplayName = this.getLocalUser.displayName || '';
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-settings {
  .profile-settings-content {
    .profile-settings-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0px;
      opacity: 0.9;
      color: var(--v-white-base);
    }

    @media (max-width: $breakpoint-tablet) {
      .avatar-username-container {
        flex-direction: column;

        ::v-deep .avatar-wrapper {
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
  .avatar-icon {
    cursor: pointer;
    height: 80px;
    min-width: 80px;
    width: 80px;
    margin: 4px;
  }
  .avatar-images {
    cursor: pointer;
  }
  .actions {
    width: 100%;
  }
}
</style>
