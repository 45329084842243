<template>
  <div>
    <div class="message-notification-wrapper">
      <span v-if="extraMessageText" :style="{ color: extraMessageText.color }">({{ extraMessageText.text }})</span>
      <span class="notification-message">{{ convertChatNotification(message) }}</span>
    </div>
  </div>
</template>

<script>
import { convertChatNotification } from '@/helpers/convert';

import ENUMS from '@/constants/ENUMS';

const { CHAT_NOTIFICATION_TYPES } = ENUMS;

export default {
  name: 'MessageNotification',
  props: {
    message: {
      type: Object,
    },
  },
  computed: {
    extraMessageText() {
      switch (this.message.body) {
        case CHAT_NOTIFICATION_TYPES.START_RECORDING_NOTIFICATION_BY_USER:
          return { text: this.$t('recordOn'), color: 'green' };
        case CHAT_NOTIFICATION_TYPES.STOP_RECORDING_NOTIFICATION_BY_USER:
        case CHAT_NOTIFICATION_TYPES.STOP_REMOTE_RECORDING_NOTIFICATION_BY_USER:
          return { text: this.$t('recordOff'), color: 'red' };
        case CHAT_NOTIFICATION_TYPES.USER_START_WEBINAR_BROADCAST:
          return { text: this.$t('webinarOn'), color: 'green' };
        case CHAT_NOTIFICATION_TYPES.USER_END_WEBINAR_BROADCAST:
          return { text: this.$t('webinarOff'), color: 'red' };
        default:
          return false;
      }
    },
  },
  methods: {
    convertChatNotification,
  },
};
</script>
<style scoped lang="scss">
.message-notification-wrapper {
  .notification-message {
    word-break: break-word;
  }
}
</style>
