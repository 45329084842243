<template>
  <v-navigation-drawer :value="showDrawerMenu" fixed :permanent="showDrawerMenu" class="mobile-left-menu" width="100%" color="primary" @input="onDrawerInput">
    <div class="mobile-menu-header d-flex justify-space-between px-6">
      <logo :hideOnMobile="false" height="30" style="left: 30px" />
      <icon icon="x" @click="closeDrawer" />
    </div>
    <v-list nav dense class="mobile-menu-list">
      <template v-for="(item, index) in menuItems">
        <v-list-item :key="index">
          <v-list-item-title class="pl-3" :class="item.class" @click="item.onClick">{{ $t(item.label) }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>

    <template slot="append">
      <div class="mobile-menu-bottom-text">
        {{ aboutUsText }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MeetMobileLeftMenu',
  props: {
    aboutUsText: {
      type: String,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      showDrawerMenu: true,
    };
  },
  methods: {
    closeDrawer() {
      this.showDrawerMenu = false;
      this.$emit('input', false);
    },
    onDrawerInput($event) {
      this.$emit('input', $event);
      this.showDrawerMenu = $event;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function() {
        this.showDrawerMenu = this.value;
      },
    },
  },
};
</script>

<style lang="scss">
.mobile-left-menu {
  padding-top: 96px;

  &.v-navigation-drawer--fixed {
    z-index: 200;
  }

  .mobile-menu-header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 30px;
  }

  .mobile-menu-list {
  }
  .notification {
    &.active {
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: calc(50% - 40px);
        background: var(--v-logo-color-base);
      }
    }
  }
  .mobile-menu-bottom-text {
    color: white;
    opacity: 0.6;
    font-size: 10px;
    text-align: center;
    padding: 16px 16px;
    border-top: solid 1px var(--v-whisper-50);
  }
}
</style>
