<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    :persistent="persistent"
    :max-width="maxWidth"
    :content-class="`modal-container ${contentClass} ${!showActions ? 'hide-actions' : ''} `"
    overlay-opacity="0.6"
    overlay-color="modal-overlay"
    :fullscreen="isFullScreen"
    @click:outside="close('outside')"
  >
    <v-card color="popup-color" elevation="0">
      <div data-test-id="modal-close-icon" v-if="showCloseButton" style="position: absolute; right: 10px; top: 10px">
        <icon class="cursor-pointer" color="white" icon="x" @click="close('cancel')" />
      </div>
      <div data-test-id="modal-close-icon" v-if="smallTitle" style="position: absolute; left: 10px; top: 14px">
        {{ title }}
      </div>
      <div v-if="showBackButton" style="position: absolute; left: 10px; top: 10px">
        <icon class="cursor-pointer" color="white" icon="arrow-left" @click="$emit('back')" />
      </div>
      <v-card-title class="modal-title justify-center" v-if="!smallTitle">
        <div>
          {{ title }}
        </div>
      </v-card-title>
      <v-card-subtitle v-if="subTitle">
        <div>{{ subTitle }}</div>
      </v-card-subtitle>
      <v-card-text :class="{ 'padding-0': paddingNone }">
        <slot />
      </v-card-text>
      <v-divider v-if="showBottomDivider" />
      <v-card-actions v-if="showActions">
        <div class="w-full d-flex justify-center align-center flex-wrap-reverse mb-n2">
          <v-btn
            v-if="!hideCancelBtn"
            data-test-id="modal-cancel-button"
            class="mb-2"
            :block="$isMobile"
            :class="{ 'mb-3': $isMobile, 'mx-2': !$isMobile }"
            min-width="185"
            outlined
            @click="close('cancel')"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            v-if="!hideConfirmBtn"
            data-test-id="modal-confirm-button"
            :block="$isMobile"
            :class="{ 'mb-3': $isMobile, 'mx-2': !$isMobile }"
            min-width="185"
            color="secondary"
            class="mb-2"
            :disabled="confirmButtonDisabled"
            @click="close('confirm')"
            :loading="loading"
          >
            {{ confirmButtonText }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    hideConfirmBtn: {
      type: Boolean,
      default: false,
    },
    hideCancelBtn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    showActions: {
      type: Boolean,
    },
    cancelButtonText: {
      type: String,
    },
    confirmButtonText: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    confirmButtonDisabled: {
      type: Boolean,
    },
    smallTitle: {
      type: Boolean,
    },
    paddingNone: {
      type: Boolean,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
    },
    showBottomDivider: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    contentClass: {
      type: String,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: true,
    };
  },
  computed: {
    isFullScreen() {
      return this.$isMobile || this.fullScreen;
    },
  },
  methods: {
    close(type) {
      this.$emit('close', type);
      if (this.autoClose && !(type === 'outside' && this.persistent)) {
        this.showDialog = false;
        // Modal Plugin Close Function
        this.$modal?.delayedClose();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-container {
  border: solid 1px var(--v-date-base);

  &.v-dialog {
    &--fullscreen {
      overflow: hidden;
      max-height: 100vh;
    }
    .v-card {
      display: flex;
      flex-direction: column;
      max-height: 60vh;

      &__title {
        font-size: 18px;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 40px;
        word-break: initial;
        text-align: center;

        @media (max-width: $breakpoint-mobile) {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 80px;
        }
      }
      &__subtitle {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        font-weight: 400;
        opacity: 0.8;
        margin-top: 8px;
        padding-bottom: 0;
        color: var(--v-white-base);
      }

      &__text {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 16px 48px 8px 48px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        @media (max-width: $breakpoint-mobile) {
          padding: 16px 24px;
        }
      }

      &__actions {
        padding: 24px 48px 24px 48px;

        @media (max-width: $breakpoint-mobile) {
          padding: 24px;
        }
      }
    }
  }

  &.hide-actions {
    &.v-dialog {
      .v-card {
        &__text {
          padding: 16px 48px 24px 48px;
          @media (max-width: $breakpoint-mobile) {
            padding: 24px;
            &.padding-0 {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
