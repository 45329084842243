var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "shared-video d-flex align-center justify-center overflow-hidden",
    },
    [
      _vm.videoType === _vm.SHARED_VIDEO_TYPES.YOUTUBE
        ? [
            _c("YoutubePlayer", {
              ref: "player",
              attrs: {
                videoId: _vm.videoId,
                initialTime: _vm.initialTime,
                width: _vm.width,
                height: _vm.height,
                controls: _vm.controls,
                forceMuted: _vm.speakerMuted,
              },
              on: {
                onPlay: _vm.onPlay,
                onPause: _vm.onPause,
                muted: _vm.onMute,
                unmuted: _vm.onUnMute,
                volumeChanged: _vm.onVolumeChange,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }