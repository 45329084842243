<template>
  <div class="license-tooltip-wrapper" @mouseleave="showLicenseTooltip = false">
    <div @mouseenter="showLicenseTooltip = true" @click.stop="showLicenseTooltip = true" v-click-outside="closeLicenseTooltip" class="license-name">
      <div>
        {{ licenseName }}
        <span v-if="isExpired" class="expired">({{ $t('expired') }})</span>
      </div>
    </div>
    <transition name="fade">
      <div v-show="showLicenseTooltip" class="license-tooltip">
        <div class="font-weight-bold">{{ licenseName }} {{ $t('license') }}</div>
        <div class="license-information">
          {{ licenseTooltip }}
          <a v-show="isOwner" class="license-tooltip-link text-decoration-underline font-weight-medium" :href="upgradePackageUrl" target="_blank">
            {{ licenseTooltipUpgrade }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LicenseInformation',
  data() {
    return {
      showLicenseTooltip: false,
    };
  },
  computed: {
    ...mapState('Conference', ['user']),
    ...mapGetters('Conference', ['getLocalUser']),
    licenseType() {
      return this.licenseInformationByType('license_type');
    },
    licenseName() {
      return this.licenseType ? this.$t(`licenseTypes.${this.licenseType}`) : null;
    },
    licenseTooltip() {
      if (!this.licenseType) {
        return null;
      }
      let tooltipType = 'licenseTooltip';
      if (this.isExpired) {
        tooltipType += this.isOwner ? '.expiredLicense.owner' : '.expiredLicense';
      }
      return this.$t(`${tooltipType}.${this.licenseType}`);
    },
    upgradePackageUrl() {
      if (this.licenseType === 'FREE') {
        let pricePath = this.$i18n.locale === 'tr' ? 'fiyatlandirma' : 'en/pricing';
        return `${process.env.VUE_APP_BIZBIZE_WEB_URL}/${pricePath}`;
      } else if (this.licenseType === '"CORPORATE"') {
        let pricePath = this.$i18n.locale === 'tr' ? 'iletisim' : 'en/contact';
        return `${process.env.VUE_APP_BIZBIZE_WEB_URL}/${pricePath}`;
      }
      return `${window.XPER_CONFIG.manageUrl}/upgrade`;
    },
    licenseTooltipUpgrade() {
      if (this.licenseType === 'FREE') {
        return this.$t('upgradePackage');
      } else if (this.licenseType === 'STANDARD' || this.licenseType === 'PROFESSIONAL') {
        if (this.isExpired && this.isOwner) {
          return this.upgradePackageUrl;
        }
        return this.$t('clickHere');
      } else if (this.licenseType === 'CORPORATE' && this.isExpired && this.isOwner) {
        return this.upgradePackageUrl;
      }
      return null;
    },
    isExpired() {
      if (this.licenseType === 'FREE') {
        return false;
      }
      return this.licenseInformationByType('status') === 'Expired';
    },
    isOwner() {
      if (this.licenseType === 'FREE') {
        return false;
      }
      if (this.getLocalUser.authenticated) {
        return this.user?.role === 'Owner';
      }
      return false;
    },
  },
  methods: {
    closeLicenseTooltip() {
      this.showLicenseTooltip = false;
    },
    licenseInformationByType(type) {
      if (this.getLocalUser.authenticated) {
        const data = this.user?.license?.BIZBIZE?.[type];
        if (data) {
          return data || null;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.license-tooltip-wrapper {
  pointer-events: initial !important;
  position: relative;
  .expired {
    color: #e31113;
  }
}

.license-tooltip {
  position: absolute;
  left: -5px;
  width: 275px;
  padding: 5px;
  border-radius: 8px;
  font-size: 12px;
  background: var(--v-darkblue-base);

  .license-tooltip-link {
    color: var(--v-white-base);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
