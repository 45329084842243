var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.roomNotifications.length
      ? _c(
          "div",
          _vm._l(_vm.roomNotifications, function (notification, key) {
            return _c("ExpansionPanelNotificationItem", {
              key: key,
              attrs: {
                user: _vm.getUserById(notification.userId),
                notification: notification,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }