<template>
  <RejectedModal />
</template>

<script>
import RejectedModal from '@/components/Lobby/Modal/RejectedModal';

export default {
  name: 'Rejected',
  components: { RejectedModal },
  data() {
    return {
      timeout: null,
    };
  },
  mounted() {
    this.timeout = setTimeout(() => {
      // TODO store sıfırlandığında router üzerinden gönderilmeli.
      // this.$router.push({ name: 'home' });
      window.location = window.location.origin;
    }, 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style scoped></style>
