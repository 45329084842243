import { mobileAndTabletCheck } from "./Utils";

export default class Queue {
  constructor(callback) {
    this.stack = [];
    this.callback = callback;
    this.interval = null;
    this.time = mobileAndTabletCheck() ? 1000 : 10;
    this.dequeueCount = 1;
  }

  dequeue() {
    return this.stack.shift();
  }

  timingDequeue(time, dequeueCount) {
    this.interval = setInterval(() => {
      const items = [];
      const _dequeueCount = this.length >= dequeueCount ? dequeueCount : this.length;

      for (let i = 0; i < _dequeueCount; i++) {
        const item = this.dequeue();
        items.push(item);
      }

      if (this.isEmpty) {
        clearInterval(this.interval);
        this.interval = null;
      }

      if (items.length) {
        this.callback(items);
      }
    }, time);
  }

  enqueue(item) {
    this.stack.push(item);

    if (!this.interval && this.callback) {
      this.timingDequeue(this.time, this.dequeueCount);
    }
  }

  move(from, to) {
    this.stack.splice(to, 0, this.stack.splice(from, 1)[0]);
  }

  remove(index) {
    this.stack.splice(index, 1);
  }

  get isEmpty() {
    return this.stack.length === 0;
  }

  get length() {
    return this.stack.length;
  }
}
