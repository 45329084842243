import NoSleep from 'nosleep.js';

export const stayAwake = async () => {
  document.addEventListener(
    'click',
    function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      const noSleep = new NoSleep();
      noSleep.enable();
    },
    false,
  );
};
