var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "survey-nav" },
    [
      _vm.showBackButton
        ? _c("icon", {
            staticClass: "back-button mr-6",
            attrs: { icon: "chevron-left" },
            on: { click: _vm.back },
          })
        : _vm._e(),
      _c("div", [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }