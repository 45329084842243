export const PREDEFINED_AVATARS = [
  '/img/avatar/avatar1.png',
  '/img/avatar/avatar2.png',
  '/img/avatar/avatar3.png',
  '/img/avatar/avatar4.png',
  '/img/avatar/avatar5.png',
  '/img/avatar/avatar6.png',
  '/img/avatar/avatar7.png',
  '/img/avatar/avatar8.png',
  '/img/avatar/avatar9.png',
  '/img/avatar/avatar10.png',
  '/img/avatar/avatar11.png',
  '/img/avatar/avatar12.png',
  '/img/avatar/avatar13.png',
  '/img/avatar/avatar14.png',
  '/img/avatar/avatar15.png',
  '/img/avatar/avatar16.png',
  '/img/avatar/avatar17.png',
  '/img/avatar/avatar18.png',
  '/img/avatar/avatar19.png',
  '/img/avatar/avatar20.png',
  '/img/avatar/avatar21.png',
  '/img/avatar/avatar22.png',
  '/img/avatar/avatar23.png',
  '/img/avatar/avatar24.png',
  '/img/avatar/avatar25.png',
  '/img/avatar/avatar26.png',
  '/img/avatar/avatar27.png',
  '/img/avatar/avatar28.png',
  '/img/avatar/avatar29.png',
];

export const PREDEFINED_RANDOM_AVATARS = [
  '/img/avatar/random1.png',
  '/img/avatar/random2.png',
  '/img/avatar/random3.png',
  '/img/avatar/random4.png',
  '/img/avatar/random5.png',
  '/img/avatar/random6.png',
  '/img/avatar/random7.png',
  '/img/avatar/random8.png',
  '/img/avatar/random9.png',
  '/img/avatar/random10.png',
  '/img/avatar/random11.png',
  '/img/avatar/random12.png',
];
