<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon>mdi-file-video</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-text="title"></v-list-item-title>
      <v-list-item-subtitle v-text="subTitle"></v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon @click="$emit('download')" small :loading="loading">
        <v-icon color="grey lighten-1">feather icon-download</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'RecordListItem',
  props: {
    title: String,
    subTitle: String,
    loading: Boolean,
  },
};
</script>
