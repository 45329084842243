var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "xpermeet", class: _vm.classList },
    [
      _vm.showBackground
        ? _c("div", {
            staticClass: "xpermeet-bg",
            style: {
              backgroundImage: _vm.backgroundImage,
              backgroundColor: _vm.backgroundColor,
            },
          })
        : _vm._e(),
      _c("v-main", [_c("router-view")], 1),
      _c("logo-left-bottom"),
      _c("GDPR"),
      _c("toaster"),
      _c("SystemErrorModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }