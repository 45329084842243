<template>
  <Modal persistent :title="$t('youAreRejected.title')" :show-close-button="false">
    <div class="text-center">{{ $t('youAreRejected.warning') }}</div>
  </Modal>
</template>

<script>
export default {
  name: 'RejectedModal',
  data() {
    return {
      showDialog: true,
    };
  },
};
</script>
