var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      ref: "wrapper",
      staticClass: "video-card",
      class: _vm.classList,
      style: _vm.computedStyle,
      attrs: { draggable: _vm.isDraggable },
      on: {
        dblclick: _vm.onDoubleClick,
        mouseenter: function ($event) {
          return _vm.toggleCardOptions(true)
        },
        mouseleave: _vm.onMouseLeave,
        dragstart: _vm.onDragStart,
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.onDragOver.apply(null, arguments)
        },
        dragend: _vm.onDragEnd,
      },
    },
    [
      _c(
        "v-col",
        { staticClass: "video-card-container" },
        [
          _c(
            "v-row",
            { staticClass: "video-header" },
            [
              _vm.user.handsUp
                ? [
                    _vm.getLocalUser.isModerator
                      ? _c("menu-button", {
                          staticClass: "hands-up-more-actions py-1",
                          attrs: {
                            icon: "hand",
                            color: "warning",
                            "icon-background-color": "var(--v-darkblue-80)",
                            "icon-size": "18",
                            width: "24",
                            "use-inner-model": "",
                            "hide-caret-down": "",
                            dropdownClass: "mt-2",
                            "dropdown-list": _vm.getLocalUser.isModerator
                              ? _vm.handsUpButtonList(_vm.user)
                              : null,
                          },
                        })
                      : _c(
                          "div",
                          { staticClass: "hands-up-icon py-1" },
                          [
                            _c("icon", {
                              attrs: {
                                default: "",
                                color: "warning",
                                icon: "hand",
                                size: "18",
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _c(
                "div",
                { staticClass: "header-icon-name-container px-2 py-1" },
                [
                  _c(
                    "div",
                    { staticClass: "header-icons" },
                    [
                      _vm.user.isModerator
                        ? _c("icon", {
                            staticClass: "user-icon",
                            attrs: {
                              size: "18",
                              color: "logo-color",
                              icon: "user-full-color",
                            },
                          })
                        : _vm.user.authenticated
                        ? _c("icon", {
                            staticClass: "user-icon",
                            attrs: { size: "18", color: "white", icon: "user" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display-name" },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.user.displayName) + " "),
                      ]),
                      _c("PrivateRoomMenu", {
                        attrs: {
                          "private-room-name": _vm.user.privateRoomName,
                          "is-local-user": _vm.user.id !== _vm.getLocalUser.id,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mr-2 mt-3 card-menu-container d-flex align-center",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.cardOption,
                          expression: "cardOption",
                        },
                      ],
                    },
                    [
                      _vm.user.id !== _vm.getLocalUser.id
                        ? _c("menu-button", {
                            staticClass: "show-more-action hidden-mobile",
                            attrs: {
                              icon: "more-vertical",
                              color: "white",
                              "icon-background-color": "primary",
                              "icon-size": "12",
                              width: "20",
                              "use-inner-model": "",
                              "hide-caret-down": "",
                              "send-on-dropdown-status-change": "",
                              dropdownClass: "mt-2",
                              "dropdown-list": _vm.getMoreButtonList(_vm.user),
                            },
                            on: {
                              click: function ($event) {
                                _vm.disableHideShowMoreAction = true
                              },
                              changeDropdownStatus: _vm.onChangeDropdownStatus,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "video-content" },
            [
              !_vm.showVideoContainer
                ? _c("avatar", {
                    staticClass: "video-avatar",
                    attrs: {
                      absolute: "",
                      "min-avatar-size": _vm.avatarSize,
                      "display-name": _vm.user.displayName,
                      "full-screen": _vm.fullScreen,
                      image: _vm.user.avatar,
                    },
                  })
                : _vm._e(),
              _c("video", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showVideoContainer,
                    expression: "showVideoContainer",
                  },
                ],
                ref: "video",
                class: `${_vm.user.type}-video`,
                attrs: {
                  id: `${_vm.user.type}-video-${_vm.user.id}`,
                  autoplay: "",
                  playsinline: "",
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "video-footer", attrs: { align: "end" } },
            [
              _c("v-col", { staticClass: "pa-0" }, [
                _c(
                  "div",
                  { staticClass: "d-flex items-center" },
                  [
                    _vm.user.audioMuted || !_vm.user.hasAudioTrack
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "footer-icon",
                              class: {
                                "mr-1":
                                  _vm.user.videoMuted ||
                                  !_vm.user.hasVideoTrack ||
                                  _vm.user.speakerMuted,
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "muted-audio",
                                attrs: {
                                  color: "logo-color",
                                  icon: "mic-off-solid",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.user.speakerMuted
                      ? [
                          _c(
                            "div",
                            { staticClass: "footer-icon" },
                            [
                              _c("icon", {
                                attrs: {
                                  size: "18",
                                  color: "logo-color",
                                  icon: "volume-off-1",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "stats-popover" },
            [
              _vm.user.interrupted
                ? [
                    _c("icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("stats.connectionLost"),
                          expression: "$t('stats.connectionLost')",
                        },
                      ],
                      attrs: { icon: "alert-circle-1", color: "warning" },
                    }),
                  ]
                : _vm.user.inactive
                ? [
                    _c("icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("stats.inactive"),
                          expression: "$t('stats.inactive')",
                        },
                      ],
                      attrs: { icon: "alert-circle", color: "info" },
                    }),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass:
                          "security-icon-container d-flex align-center justify-center",
                        class: { "mr-1": !_vm.user.features_jigasi },
                      },
                      [
                        _c("icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.isE2eeEnabled
                                ? _vm.$t("e2e-encrypted")
                                : _vm.$t("dtls-encrypted"),
                              expression:
                                "isE2eeEnabled ? $t('e2e-encrypted') : $t('dtls-encrypted')",
                            },
                          ],
                          attrs: {
                            size: "12",
                            icon: "shield-1",
                            color: "var(--v-warning)",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("Stats", {
                      staticClass: "d-flex",
                      attrs: {
                        if: "!user.features_jigasi",
                        userId: _vm.user.id,
                      },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }