import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from 'dayjs';

require('dayjs/locale/tr');
require('dayjs/locale/es');
require('dayjs/locale/en');
require('dayjs/locale/ru');

import Conference from '@/store/modules/conference';
import Devices from '@/store/modules/devices';
import Webinar from '@/store/modules/webinar';
import Lobby from '@/store/modules/lobby';
import Stats from '@/store/modules/stats';
import Survey from '@/store/modules/survey';
import Notification from '@/store/modules/notification';

import i18n from '@/i18n';

import storage from '@/services/storage';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: 'en',
    $toast: null,
    theme: storage.getItem('themeBg') || 'dark',
    radius: storage.getItem('themeRadius') || 'flat',
    background: storage.getItem('background') || '/img/background/background.svg',
    systemErrorCode: null,
  },
  mutations: {
    SET_SYSTEM_ERROR(state, payload) {
      state.systemErrorCode = payload;
    },
    SET_LANG(state, payload) {
      document.documentElement.lang = payload;
      document.body.setAttribute('lang', payload);
      state.lang = payload;
      setTimeout(() => {
        i18n.locale = payload;
        dayjs.locale(payload);
        storage.setItem('lang', payload);
      }, 100);
    },
    SET_TOAST(state, payload) {
      Vue.set(state, '$toast', payload);
    },
    SET_BACKGROUND(state, payload) {
      state.background = payload;
    },
    SET_THEME(state, payload) {
      storage.setItem('themeBg', payload);
      Vue.set(state, 'theme', payload);
    },
    SET_RADIUS(state, payload) {
      storage.setItem('themeRadius', payload);
      Vue.set(state, 'radius', payload);
    },
  },
  getters: {
    getLogo(state) {
      const darkLogo = Vue.prototype?.$customerConfig?.resources?.darkLogo || '/img/logo.svg';
      const lightLogo = Vue.prototype?.$customerConfig?.resources?.lightLogo || '/img/logo.svg';

      return state.theme === 'dark' ? darkLogo : lightLogo;
    },
    getThemeIcon(state) {
      return state.theme === 'dark' ? 'sun' : 'moon';
    },
    getRadiusIcon(state) {
      return state.radius === 'flat' ? 'circle' : 'square';
    },
    getMobileRadiusIcon(state) {
      return state.radius === 'flat' ? 'wave-sine' : 'wave-triangle';
    },
    getRadiusLang(state) {
      return state.radius === 'flat' ? 'radiusTheme' : 'flatTheme';
    },
    getThemeLang(state) {
      return state.theme === 'dark' ? 'lightTheme' : 'darkTheme';
    },
    getAppVersion() {
      return process.env.VUE_APP_VERSION || '-';
    },
    getIsOnPremiseSetup() {
      const manageService = window.XPER_CONFIG?.manageService;
      return !manageService;
    },
    getCustomerBackground() {
      let customerBackground = Vue.prototype.$customerConfig?.resources?.backgroundImage || '';
      if (customerBackground && !customerBackground.includes('://')) {
        customerBackground = `/img/${customerBackground}`;
      }
      return customerBackground;
    },
  },
  actions: {},
  modules: {
    Conference,
    Devices,
    Webinar,
    Lobby,
    Stats,
    Survey,
    Notification,
  },
});
