import JitsiMeetJS from "./JitsiMeetLib";

import KeycloakManager from "./modules/KeycloakManager";
import Conference from "./modules/Conference";
import ConnectionManager from "./modules/ConnectionManager";
import MediaDevices from "./modules/MediaDevices";
import LocalRecordingManager from "./modules/LocalRecordingManager";
import Webinar from "./modules/Webinar";
import Lobby from "./modules/Lobby";
import RoomConfig from "./modules/RoomConfig";
import DialInManager from "./modules/DialInManager";
import ARModule from "./modules/ARModule";
import Survey from "./modules/Survey";
import Passwerks from "./modules/Passwerks";
import RoomNameService from "./services/RoomNameService";

import { consoleError, consoleLog } from "./utils/logger";
import { ErrorWithCodes } from "./errors/errors";

import Enums from "./constants/enums";
import DefaultRoomConfig from "./constants/defaultRoomConfig";
import Stats from "./modules/Stats";
import { parseJwt } from "./helpers/jwt";
import RemoteRecordingManager from "./modules/RemoteRecordingManager";

export class XperMeetLib {
  constructor() {
    this.lib = JitsiMeetJS;
    this.config = null;
    this.keycloakManager = null;
    this.connectionManager = ConnectionManager;
    this.conference = Conference;
    this.mediaDevices = MediaDevices;
    this.localRecordingManager = LocalRecordingManager;
    this.remoteRecordingManager = RemoteRecordingManager;
    this.lobby = Lobby;
    this.dialIn = DialInManager;
    this.ARModule = ARModule;
    this.stats = Stats;

    this.roomNameService = null;
    this.survey = null;
    this.webinar = null;
    this.passwerks = null;
  }

  initServices() {
    this.roomNameService = new RoomNameService();
    this.survey = new Survey();
    this.passwerks = new Passwerks(this.conference);
    this.webinar = Webinar.getInstance();
  }

  updateAPPVar() {
    // Set Global variables for Jibri
    if (window.APP) {
      consoleError("window.APP reserved variable, please check this!");
    }
    // Mock variable for Jibri, see CallPage.kt in Jibri Library
    window.APP = {
      conference: {
        _room: this.conference.room,
      },
    };
  }

  initKeycloakManager(checkLoginIframe) {
    return new Promise((resolve, reject) => {
      this.keycloakManager = new KeycloakManager();
      this.keycloakManager
        .createKeycloakInstance()
        .then((kc) => {
          this.keycloakManager.checkSSO(kc, checkLoginIframe).then(resolve).catch(reject);
          window.kc = kc;
        })
        .catch((err) => {
          consoleLog("Kc Error", err);
          reject(err);
        });
    });
  }

  init({ config, roomName, token, joinAsGuest }) {
    return new Promise((resolve) => {
      if (!this.configValidation(config)) {
        consoleError(ErrorWithCodes.INVALID_CONFIG);
        return;
      }

      // Set config
      this.config = config;

      const activeEventToken = token || localStorage.getItem("event-token");
      if (activeEventToken) {
        // TODO icislerindeki LMS bağlantısında login butonuna tıklandığında keycloak undefined döndüğü için yapıldı.
        // #MEET-394 ile bu kodun güncellenmesi gerekecek.
        localStorage.setItem("event-token", activeEventToken);
        // this.initKeycloakManager("checkLoginIframe");
        return resolve();
      }

      // If JWT token exist, passed keycloak manager.
      const authenticatorToken = localStorage.getItem("authenticator-token");
      if (authenticatorToken) {
        const parsedToken = parseJwt(authenticatorToken);
        const isNotModerator = "moderator" in parsedToken && parsedToken.moderator === false;

        if (isNotModerator || joinAsGuest) {
          localStorage.removeItem("authenticator-token");
        }

        return resolve();
      }

      if (!window.XPER_CONFIG?.isKeycloakActive) {
        return resolve();
      }

      this.keycloakManager
        .handleAuthentication(roomName)
        .then(() => {
          consoleLog("Auth successful");
          resolve();
        })
        .catch((e) => {
          if (e === "WrongUserLogin") {
            resolve(e);
          } else if (e === "GuestLogin") {
            resolve("GuestLogin");
          } else if (e === "GetJwtFailed") {
            resolve("GetJwtFailed");
          } else {
            resolve("GuestLogin");
          }
        });
    });
  }

  // deviceDeviceIds = {
  //    audioInput: ''
  //    audioOutput: ''
  //    videoInput: ''
  // }
  initWithConnection({ config, roomName, password, deviceDeviceIds, startMutedPolicyConfig, token, startWithAudioMuted, startWithVideoMuted, joinAsGuest }) {
    this.mediaDevices.setDefaultDevices(deviceDeviceIds);

    return new Promise((resolve, reject) => {
      this.init({ config, roomName, token, joinAsGuest })
        .then((response) => {
          try {
            this.connectionManager.createConnection({ config, roomName, joinAsGuest });

            resolve(response);

            this.connectionManager.on("ConnectionSuccess", () => {
              try {
                this.conference.localUser.setPrejoinConfig({ startWithAudioMuted, startWithVideoMuted });

                this.conference.createRoom(config, roomName, password, true);
                this.updateAPPVar();
                RoomConfig.init(this.conference.room.room.roomjid, this.conference.room.room.connection, startMutedPolicyConfig);

                // FIXME: required only for rn mobile app, will be removed in the future
                this.conference.setStartMutedPolicy(startMutedPolicyConfig);
              } catch (e) {
                consoleError("ConnectionSuccess Event Error:", e);
              }
            });

            this.conference.on("ConferenceFailed", (errorCode) => {
              consoleLog("ConferenceFailed", errorCode);
              if (errorCode === ErrorWithCodes.AUTHENTICATION_REQUIRED) {
                setTimeout(() => {
                  this.conference.emit("RemoteUserLeft", this.conference.localUser.getUser.id);
                  this.connectionManager.disconnect();
                  this.connectionManager.connection.disconnect();
                  this.connectionManager.createConnection({ config, roomName });
                }, 5000);
              }
            });
          } catch (e) {
            consoleError(e);
            reject(e);
          }
        })
        .catch(reject);
    });
  }

  configValidation(config) {
    return !!config;
  }

  leaveCall(config) {
    const redirectUri = config?.redirectUri || window.location.origin;

    this.conference.clearEvents();
    this.mediaDevices.clearEvents();
    this.connectionManager.clearEvents();

    localStorage.removeItem("moderatorSecret");

    this.conference.localUser.tracks.forEach((track) => {
      track.dispose();
    });

    this.conference?.room
      ?.leave()
      .then(() => {
        // Connection disconnect
        this.connectionManager.disconnect();
        this.connectionManager.connection
          .disconnect()
          .then(() => {
            // Redirect page
            location.href = redirectUri;
          })
          .catch((e) => {
            consoleLog("lib main.js leave call -> connectionManager.disconnect error...", e);
          });
      })
      .catch((e) => {
        consoleLog("lib main.js leave call -> room.leave error...", e);
      });
  }

  logout(config) {
    this.connectionManager.disconnect();

    this.connectionManager.connection.disconnect().then(() => {
      if (window.localStorage.getItem("ticket")) {
        window.localStorage.removeItem("ticket");
        location.href = "/";
      } else if (config?.forceLogout || this.conference?.room?.isLoggedIn()) {
        // this.keycloakManager.keycloakInstance.authenticated is false incognito tabs
        const redirectUri = config?.redirectUri || window.location.origin;
        const iframeLogout = config?.iframeLogout;

        const logoutUrl = this.keycloakManager.keycloakInstance.createLogoutUrl({ redirectUri });

        localStorage.removeItem("keycloak-token");
        localStorage.removeItem("moderatorSecret");

        if (iframeLogout) {
          let iframe = document.createElement("iframe");
          iframe.src = logoutUrl;
          iframe.style = "display: none;";
          document.body.appendChild(iframe);
        } else {
          location.href = logoutUrl;
        }
      }
    });
  }

  destroyRoomAndLeave() {
    this.conference.kickAllParticipants();
    this.leaveCall();
  }
}
export default new XperMeetLib();
export const ERROR_WITH_CODES = ErrorWithCodes;
export const ENUMS = Enums;
export const DEFAULT_ROOM_CONFIG = DefaultRoomConfig;
