<template>
  <Modal
    :title="$t('maxUsersLimitReachedTitle')"
    :show-close-button="false"
    persistent
    :confirm-button-text="$t('goToHome')"
    :cancel-button-text="$t('tryAgain')"
    show-actions
    @close="onModalClose"
  >
    <div class="text-center">{{ $t('maxUsersLimitReached') }}</div>
  </Modal>
</template>

<script>
import { createATagAndClick } from '@/helpers/create-element';

export default {
  name: 'MaxUserReachedModal',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    goToHome() {
      const homeUrl = window.location.origin;
      createATagAndClick(homeUrl);
    },
    tryAgain() {
      return this.$router.go();
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.goToHome();
      } else {
        this.tryAgain();
      }
    },
  },
  watch: {
    value: {
      handler: function() {
        this.dialog = this.value;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
