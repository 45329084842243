var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        persistent: "",
        title: _vm.$t("youAreRejected.title"),
        "show-close-button": false,
      },
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.$t("youAreRejected.warning"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }