var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "license-tooltip-wrapper",
      on: {
        mouseleave: function ($event) {
          _vm.showLicenseTooltip = false
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeLicenseTooltip,
              expression: "closeLicenseTooltip",
            },
          ],
          staticClass: "license-name",
          on: {
            mouseenter: function ($event) {
              _vm.showLicenseTooltip = true
            },
            click: function ($event) {
              $event.stopPropagation()
              _vm.showLicenseTooltip = true
            },
          },
        },
        [
          _c("div", [
            _vm._v(" " + _vm._s(_vm.licenseName) + " "),
            _vm.isExpired
              ? _c("span", { staticClass: "expired" }, [
                  _vm._v("(" + _vm._s(_vm.$t("expired")) + ")"),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLicenseTooltip,
                expression: "showLicenseTooltip",
              },
            ],
            staticClass: "license-tooltip",
          },
          [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.licenseName) + " " + _vm._s(_vm.$t("license"))),
            ]),
            _c("div", { staticClass: "license-information" }, [
              _vm._v(" " + _vm._s(_vm.licenseTooltip) + " "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOwner,
                      expression: "isOwner",
                    },
                  ],
                  staticClass:
                    "license-tooltip-link text-decoration-underline font-weight-medium",
                  attrs: { href: _vm.upgradePackageUrl, target: "_blank" },
                },
                [_vm._v(" " + _vm._s(_vm.licenseTooltipUpgrade) + " ")]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }