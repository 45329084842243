var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-menu d-flex align-center w-full" },
    _vm._l(_vm.tabItems, function (item) {
      return _c(
        "v-btn",
        {
          key: item.name,
          staticClass: "tab-btn flex-grow-1",
          attrs: {
            small: "",
            height: "32",
            width: `${parseInt(100 / _vm.tabItems.length)}%`,
            color: item.value === _vm.sharedWith ? "body" : _vm.backgroundColor,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("onTabClick", item.value)
            },
          },
        },
        [
          item.icon
            ? _c("icon", {
                attrs: {
                  icon: item.icon,
                  color: _vm.color,
                  size: _vm.iconSize,
                },
              })
            : _vm._e(),
          _c("span", { staticClass: "tab-btn-text ml-2" }, [
            _vm._v(_vm._s(item.text)),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }