import axios from "axios";
import { consoleError, consoleLog } from "../utils/logger";
import errorHandler from "../utils/ErrorHandler";

const defaultGetSurveyQuery = {
  Filters: [
    {
      Property: "IsDeleted",
      Comparison: "",
      Value: "false",
    },
  ],
  Order: {
    Ascending: false,
    Property: "CreatedDate",
  },
};

/**
 * Service provider of survey module
 * @class
 * @name SurveyService
 */
export default class SurveyService {
  constructor(tokenName = "keycloak-token") {
    const config = {
      baseURL: window.XPER_CONFIG?.survey?.baseURL || "https://meet-orchestrator-test.xpermeet.com:41001/api/Survey",
      headers: {
        "Content-Type": "message/x-protobuf",
        "Access-Control-Allow-Origin": "*",
        Tenant: location.hostname.indexOf("local") > -1 ? "dev-api.bizbize.live" : location.hostname,
      },
    };

    if (window?.XPER_CONFIG?.surveyServiceTenant) {
      config.headers.Tenant = window?.XPER_CONFIG?.surveyServiceTenant;
    }

    this.api = axios.create(config);
    this.api = errorHandler.setAxiosErrorInterceptor(this.api);

    // Request interceptor for API calls
    this.api.interceptors.request.use(
      async (config) => {
        const token = window.localStorage.getItem(tokenName);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        errorHandler.addError(error);
      },
    );
  }

  /**
   * @async
   * @name addUser
   * Send an empty post request for checking the user exists.
   * If the user does not exist, the service will create a new user.
   * Authorization header is required for createing new user.
   * @return {Promise<Object>} Server response.
   */
  addUser() {
    return new Promise((resolve, reject) => {
      this.api
        .post("/User", {})
        .then(() => {
          consoleLog("Survey: User added successfully.");
          resolve();
        })
        .catch((err) => {
          if (err.response?.data?.Message === "User already exists") {
            resolve();
          } else {
            consoleError("Survey: Couldn't add user to survey service.");
            reject();
          }
        });
    });
  }

  /**
   * @async
   * @name addCategory
   * @param {Object} category
   * @param {string} category.categoryName
   * @return {Promise<Object>} Server response.
   */
  addCategory(category) {
    return this.api.post("/Category", category);
  }

  /**
   * @async
   * @name addSurveyCategory
   * @param {Object} payload
   * @param {string} payload.categoryName
   * @return {Promise<Object>} Server response.
   */
  addSurveyCategory(payload) {
    return this.api.post("/SurveyCategory", payload);
  }

  /**
   * @async
   * @name updateCategory
   * @param {Object} category
   * @param {string} category.Id
   * @param {string} category.categoryName
   * @return {Promise<Object>} Server response.
   */
  updateCategory(category) {
    return this.api.put("/Category", category);
  }

  /**
   * @async
   * @name getCategories
   * @param {Object} category
   * @param {number} query.Page.Index
   * @param {number} query.Page.Size
   * @param {boolean} query.Order.Ascending
   * @param {number} query.Order.property
   * @return {Promise<Object>} Server response.
   */
  getCategories(query) {
    return this.api.post("/GridCategories", query);
  }

  /**
   * @async
   * @name getSurveyCategory
   * @return {Promise<Object>} Server response.
   */
  getSurveyCategory(payload) {
    return this.api.get("/Category", payload);
  }

  /**
   * @async
   * @name deleteCategory
   * @param {Object} payload
   * @param {number} payload.Id
   * @return {Promise<Object>} Server response.
   */
  deleteCategory(id) {
    return this.api.delete("/Category", {
      data: {
        id,
      },
    });
  }

  /**
   * @async
   * @name addSurvey
   * @param {Object} survey
   * @param {string} survey.Title
   * @return {Promise<Object>} Server response.
   */
  addSurvey(survey) {
    return this.api.post("/Survey?", survey);
  }

  /**
   * @async
   * @name deleteSurvey
   * @param {Object} survey
   * @param {string} survey.Id
   * @return {Promise<Object>} Server response.
   */
  deleteSurvey(id) {
    return this.api.delete("/Survey", {
      data: {
        id,
      },
    });
  }

  /**
   * @async
   * @name createUserGroup
   * @return {Promise<Object>} Server response.
   */
  createUserGroup(payload) {
    return this.api.post("/AssignSurveyToUserGroup", payload);
  }

  /**
   * @async
   * @name assignSurvey
   * @return {Promise<Object>} Server response.
   */
  assignSurvey(payload) {
    return this.api.post("/AssignSurveyAnonymous", payload);
  }

  /**
   * @async
   * @name getAssignedUserGroup
   * @return {Promise<Object>} Server response.
   */
  getAssignedUserGroup(payload) {
    return this.api.post("/ListAssignedUserGroup", payload);
  }

  /**
   * @async
   * @name updateSurvey
   * @param {Object} survey
   * @param {string} survey.Id
   * @param {string} survey.Title
   * @return {Promise<Object>} Server response.
   */
  updateSurvey(survey) {
    return this.api.put("/Survey", survey);
  }

  /**
   * @async
   * @name getSurveys
   * @param {Array<Object>} Filters
   * @param {Object} Order
   * @param {boolean} Order.Ascending
   * @param {string} Order.Property
   * @param {Object} Page
   * @param {number} Page.Index
   * @param {number} Page.Size
   * @return {Promise<Object>} Server response.
   */
  getSurveys(query = defaultGetSurveyQuery) {
    return this.api.post("/ListSurveys", query);
  }

  /**
   * @async
   * @name getSurveysByUser
   * @return {Promise<Object>} Server response.
   */
  getSurveysByUser() {
    return this.api.post("/ListSurveyDataOfUser");
  }

  /**
   * @async
   * @name getQuestions
   * @param {Object} payload
   * @param {Object} payload.Survey
   * @param {string} payload.Survey.Id
   * @param {Array<String>} payload.Questions
   * @param {boolean} payload.HasMultichoices
   * @param {boolean} payload.IsDeleted
   * @return {Promise<Object>} Server response.
   */
  addQuestion(payload) {
    return this.api.post("/Questions", payload);
  }

  /**
   * @async
   * @name getQuestions
   * @param {Object} payload
   * @param {Object} payload.Survey
   * @param {string} payload.Survey.Id
   * @param {Array<String>} payload.Questions
   * @param {boolean} payload.HasMultichoices
   * @param {boolean} payload.IsDeleted
   * @return {Promise<Object>} Server response.
   */
  updateQuestion(payload) {
    return this.api.put("/Question", payload);
  }

  /**
   * @async
   * @name getQuestions
   * @param {Object} payload
   * @param {string} payload.SurveyId
   * @return {Promise<Object>} Server response.
   */
  getQuestions(payload) {
    return this.api.post("/ListQuestionsOfSurvey", payload);
  }

  /**
   * @async
   * @name deleteQuestion
   * @param {Array<string>} id
   * @return {Promise<Object>} Server response.
   */
  deleteQuestion(ids) {
    return this.api.delete("/SurveyQuestions", {
      data: {
        QuestionsIds: ids,
      },
    });
  }

  /**
   * @async
   * @name getOptions
   * @return {Promise<Object>} Server response.
   */
  getOptions(payload) {
    return this.api.post("/ListChoicesOfSurveyQuestions", payload);
  }

  /**
   * @async
   * @name addOption
   * @param {Object} payload
   * @param {Object} payload.SurveyQuestions
   * @param {string} payload.SurveyQuestions.Id
   * @param {Array<string>} payload.Choice
   * @param {boolean} payload.IsDeleted
   * @return {Promise<Object>} Server response.
   */
  addOption(payload) {
    return this.api.post("/Choices", payload);
  }

  /**
   * @async
   * @name updateOption
   * @param {Object} payload
   * @param {Object} payload.SurveyQuestions
   * @param {string} payload.SurveyQuestions.Id
   * @param {Array<string>} payload.Choice
   * @param {boolean} payload.IsDeleted
   * @return {Promise<Object>} Server response.
   */
  updateOption(payload) {
    return this.api.put("/Choices", payload);
  }

  /**
   * @async
   * @name deleteOption
   * @param {string} Id
   * @return {Promise<Object>} Server response.
   */
  deleteOption(id) {
    return this.api.delete("/Choices", {
      data: {
        ChoiceIds: [id],
      },
    });
  }

  assignSurveyAnswers(payload) {
    return this.api.post("AssignSurveyAnswers", payload);
  }

  updateSurveyAnswers(payload) {
    return this.api.put("UpdateUserAnswers", payload);
  }

  getSurveyStatistic(payload) {
    return this.api.get(`SurveyStatisticsResponse/${payload.SurveyId}/${payload.AssignedUserGroupId}`);
  }

  /**
   * @async
   * @name getSharedSurveys
   * @param {string} payload userGroupId
   * @return {Promise<Object>} Server response.
   */
  getSharedSurveys(payload) {
    return this.api.get(`SurveyByAssignedUserGroup/${payload}`);
  }
}
