export function clearTokens() {
  const token = 'keycloak-token';
  const rtoken = 'keycloak-refresh-token';

  const isExpected = v => !v || v === 'undefined' || v === undefined || v === '';

  if (isExpected(window.localStorage.getItem(token))) {
    window.localStorage.removeItem(token);
  }

  if (isExpected(window.localStorage.getItem(rtoken))) {
    window.localStorage.removeItem(rtoken);
  }
}

export const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
