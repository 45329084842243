var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-selector microphone-device-selector" },
    [
      _vm.hasMicrophone
        ? _vm._l(_vm.getMicrophones, function (item) {
            return _c(
              "div",
              {
                key: item.deviceId,
                staticClass:
                  "microphone-device-selector--item px-8 py-3 d-flex align-center",
                class: { selected: _vm.selectedMicId === item.deviceId },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    _vm.selectedMicId = item.deviceId
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-body-2 ellipsis",
                    staticStyle: { "max-width": "220px" },
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
                _c("v-spacer"),
                _c("MicrophoneMeter", {
                  staticClass: "ml-4",
                  attrs: { level: _vm.audioLevels[item.deviceId] },
                }),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }