<template>
  <div class="notification-default d-flex w-full justify-space-between align-center">
    <div class="d-flex flex-column w-full">
      <span class="user-name">{{ user.displayName }}</span>
      <div class="content d-flex">
        <Icon :icon="notificationIcon" :color="notificationIconColor" :size="20" />
        <span class="action-text" :class="{ 'ml-2': notificationIcon }">{{ $t(`notifications.${notification.text}`) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import ENUMS from '@/constants/ENUMS';

export default {
  name: 'NotificationDefault',
  props: {
    user: {
      type: Object,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notificationIcon() {
      switch (this.notification.type) {
        case ENUMS.ROOM_NOTIFICATION_TYPES.MODERATOR_ROLE:
          return 'user-full-color';
        case ENUMS.ROOM_NOTIFICATION_TYPES.A_NEW_SURVEY_SHARED:
          return 'check-1';
        default:
          return '';
      }
    },
    notificationIconColor() {
      switch (this.notification.type) {
        case ENUMS.ROOM_NOTIFICATION_TYPES.MODERATOR_ROLE:
          return 'logo-color';
        default:
          return '';
      }
    },
  },
};
</script>
