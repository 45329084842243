<template>
  <div class="avatar-uploader">
    <input type="file" ref="file" @change="setFile" v-show="false" accept="image/jpeg, image/png, image/gif, image/bmp" />
    <v-form autocomplete="off">
      <confirm-popover @confirm="avatarUploader" @cancel="clearAvatar" popoverClass="avatar-popover" confirmText="upload" cancelText="remove">
        <template slot="content">
          <div class="avatar-uploader--list px-4">
            <v-row class="avatar-list">
              <v-col class="d-flex justify-center align-center" v-for="(avatar, index) in PREDEFINED_AVATARS" :key="index" cols="2" sm="2" md="2">
                <img v-close-popover class="avatar-images" height="35px" width="35px" :src="avatar" @click="setUserAvatar(avatar)" />
              </v-col>
            </v-row>
          </div>
        </template>
        <Avatar :avatar-size="80" class="avatar-icon" :loading="loading" :image="userAvatar" :display-name="localDisplayName" editable />
      </confirm-popover>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import ConfirmPopover from '@/components/Shared/ConfirmPopover';
import { PREDEFINED_AVATARS, PREDEFINED_RANDOM_AVATARS } from '@/constants/avatar';
import storage from '@/services/storage';
import Avatar from '@/components/Shared/Avatar';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'AvatarUploader',
  components: {
    Avatar,
    ConfirmPopover,
  },
  props: {
    localDisplayName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errors: null,
      fileSizeError: false,
      PREDEFINED_AVATARS,
      userAvatar: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser']),
    isAuthenticated() {
      return XperMeetLib?.keycloakManager?.keycloakInstance?.authenticated;
    },
  },
  created() {
    let avatar = storage.getItem('avatar');
    this.setUserAvatar(avatar);
  },
  methods: {
    ...mapActions('Conference', ['setDisplayName', 'setAvatar', 'uploadUserAvatar', 'deleteUserAvatar', 'uploadGuestAvatar']),
    ...mapActions('Notification', ['showToastNotification']),
    setUserAvatar(content) {
      if (this.getLocalUser?.id) {
        this.setAvatar(content);
      }
      this.userAvatar = content;
      this.$emit('change', content);
      storage.setItem('avatar', content);
    },
    clearAvatar() {
      let newAvatar = '';
      // Set default avatar
      if (this.$customerConfig?.system?.users?.autoRandomAvatar) {
        newAvatar = PREDEFINED_RANDOM_AVATARS[Math.floor(Math.random() * PREDEFINED_RANDOM_AVATARS.length)];
      }

      this.fileSizeError = false;
      if (this.isAuthenticated) {
        this.deleteUserAvatar();
      }
      this.setUserAvatar(newAvatar);
    },
    setFile(e) {
      const file = e.target.files[0];
      if (file) {
        this.fileSizeError = false;
        if (file.size > 10485760) {
          setTimeout(() => {
            this.fileSizeError = true;
            this.showToastNotification({
              body: this.$t('fileSizeError_customSize', { size: '10 MB' }),
              translate: false,
              config: { type: TOAST_TYPE.WARNING },
            });
            this.clearFileInput();
          }, 200);
          return;
        }
        if (this.isAuthenticated) {
          this.userAvatarUploader(file);
        } else {
          this.guestAvatarUploader(file);
        }
        this.clearFileInput();
      }
    },
    clearFileInput() {
      this.$refs.file.value = '';
    },
    avatarUploader() {
      this.$refs.file.click();
    },
    close() {
      this.$emit('close');
    },
    async userAvatarUploader(file) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('file_uploaded', file);
        const res = await this.uploadUserAvatar(formData);
        if (res) {
          this.setUserAvatar(res);
        } else {
          this.showToastNotification({ body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } });
        }
        this.loading = false;
      } catch (error) {
        this.showToastNotification({ body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } });
        this.loading = false;
      }
    },
    async guestAvatarUploader(file) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('formFile', file);
        const res = await this.uploadGuestAvatar(formData);
        if (res) {
          this.setUserAvatar(res);
        } else {
          this.showToastNotification({ body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showToastNotification({ body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } });
      }
    },
  },
};
</script>

<style lang="scss">
.avatar-uploader {
  .avatar-icon {
    cursor: pointer;
    height: 80px;
    min-width: 80px;
    width: 80px;
    margin: 4px;
    position: relative;
  }
}

.avatar-images {
  cursor: pointer;
}

.avatar-popover {
  z-index: 203;
  background-color: var(--v-primary-base);
  border-radius: 4px;
  max-width: 400px;
}

@media (max-width: $breakpoint-mobile) {
  .avatar-popover {
    .tooltip-inner {
      padding: 10px !important;
    }
  }
}
</style>
