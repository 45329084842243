var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quality-settings pa-3 pl-6" },
    [
      _c("div", { staticClass: "advanced-settings-title pa-0 mb-5" }, [
        _vm._v(" " + _vm._s(_vm.$t("qualitySettings.title")) + " "),
      ]),
      _c(
        "div",
        { staticStyle: { "min-height": "120px" } },
        [
          _c("v-slider", {
            attrs: {
              "thumb-color": "var(--v-logo-color-base)",
              "tick-labels": _vm.settingsLabels,
              "hide-details": "",
              max: 3,
              step: "1",
              ticks: "always",
              "tick-size": "10",
              color: "var(--v-secondary-base)",
            },
            model: {
              value: _vm.selectedQuality,
              callback: function ($$v) {
                _vm.selectedQuality = $$v
              },
              expression: "selectedQuality",
            },
          }),
        ],
        1
      ),
      _c(
        "v-alert",
        { attrs: { dense: "", type: "info", icon: "feather icon-info" } },
        [_vm._v(_vm._s(_vm.$t("qualitySettings.description")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }