<template>
  <div class="device-settings pa-3 pl-6">
    <div class="device-settings-title pa-0 mb-5">
      {{ $t('audioAndVideo') }}
    </div>
    <v-col cols="12" sm="12" md="12" class="px-0">
      <div class="d-flex align-center">
        <!-- <icon v-tooltip="$t('noiseCanceling')" size="20" icon="alert-circle" class="mr-3 tooltip-icon" /> -->
        <div class="label mr-3">{{ $t('noiseCanceling') }}</div>
        <v-switch class="my-0" color="success" v-model="noiseCanceling" :hide-details="true"></v-switch>
      </div>
    </v-col>
    <DeviceSelector ref="deviceSelector" @cameraChange="handleCameraChange" @microphoneChange="handleMicrophoneChange" @speakerChange="handleSpeakerChange" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeviceSelector from '@/components/Device/DeviceSelector';

export default {
  name: 'LocaleSettings',
  components: { DeviceSelector },
  data() {
    return {
      selectedMicrophoneId: null,
      selectedSpeakerId: null,
      selectedCameraId: null,
      noiseCanceling: true,
    };
  },
  computed: {
    ...mapGetters('Devices', [
      'getMicrophones',
      'getCameras',
      'getSpeakers',
      'hasCamera',
      'cameraAllowed',
      'hasMicrophone',
      'microphoneAllowed',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
      'getSelectedSpeakerId',
      'isNoiceCancelationEnabled',
    ]),
    defaultCameraDeviceName() {
      return this.getCameras.find(item => item.deviceId === this.selectedCameraId)?.label;
    },
    defaultMicrophoneDeviceName() {
      return this.getMicrophones.find(item => item.deviceId === this.selectedMicrophoneId)?.label;
    },
    defaultSpeakerDeviceName() {
      return this.getSpeakers.find(item => item.deviceId === this.selectedSpeakerId)?.label;
    },
  },
  methods: {
    ...mapActions('Devices', ['changeDevice', 'changeCameraById', 'changeMicrophoneById', 'changeSpeakerById', 'setNoiceCancelationIsEnabled']),
    handleSpeakerChange(deviceId) {
      this.selectedSpeakerId = deviceId;
    },
    handleMicrophoneChange(deviceId) {
      this.selectedMicrophoneId = deviceId;
    },
    handleCameraChange(deviceId) {
      this.selectedCameraId = deviceId;
    },
    save() {
      if (this.isNoiceCancelationEnabled !== this.noiseCanceling) {
        this.setNoiceCancelationIsEnabled(this.noiseCanceling);
      }
      if (this.selectedCameraId && this.selectedCameraId !== this.getSelectedCameraId) {
        this.changeCameraById(this.selectedCameraId);
      }
      if (this.selectedMicrophoneId && this.selectedMicrophoneId !== this.getSelectedMicrophoneId) {
        this.changeMicrophoneById(this.selectedMicrophoneId);
      }
      if (this.selectedSpeakerId && this.selectedSpeakerId !== this.getSelectedSpeakerId) {
        this.changeSpeakerById(this.selectedSpeakerId);
      }
    },
  },
  watch: {
    getSelectedMicrophoneId: {
      handler() {
        this.selectedMicrophoneId = this.getSelectedMicrophoneId || null;
      },
      immediate: true,
    },
    getSelectedSpeakerId: {
      handler() {
        this.selectedSpeakerId = this.getSelectedSpeakerId || null;
      },
      immediate: true,
    },
    getSelectedCameraId: {
      handler() {
        this.selectedCameraId = this.getSelectedCameraId || null;
      },
      immediate: true,
    },
    isNoiceCancelationEnabled: {
      handler() {
        this.noiseCanceling = Boolean(this.isNoiceCancelationEnabled);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.device-settings {
  .device-settings-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    opacity: 0.9;
    color: var(--v-white-base);
  }
  .device-content {
    border-bottom: 1px solid var(--v-gray-40) !important;
    display: flex;
    font-size: 16px;
  }
}
</style>
