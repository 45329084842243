var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "Modal",
        {
          attrs: {
            title: _vm.$t(_vm.title),
            "confirm-button-text": _vm.$t("confirm"),
            "cancel-button-text": _vm.$t("cancel"),
            "show-actions": "",
          },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t(_vm.message)) + " "),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }