<template>
  <div class="d-flex">
    <Logo class=" px-5 py-4 px-md-15 py-md-8" :hide-on-mobile="false" />

    <Modal persistent :title="$t('kicked')" :sub-title="$t('kickedDescription')" @close="$router.push('/')">
      <div class="d-flex justify-center pt-2 mb-3">
        <v-btn color="secondary" @click="$router.push('/')">{{ $t('goToHome') }}</v-btn>
      </div>
    </Modal>
  </div>
</template>

<script>
import Logo from '@/components/Shared/Logo';

export default {
  name: 'Kicked',
  components: { Logo },
};
</script>
