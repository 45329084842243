var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "message-notification-wrapper" }, [
      _vm.extraMessageText
        ? _c("span", { style: { color: _vm.extraMessageText.color } }, [
            _vm._v("(" + _vm._s(_vm.extraMessageText.text) + ")"),
          ])
        : _vm._e(),
      _c("span", { staticClass: "notification-message" }, [
        _vm._v(_vm._s(_vm.convertChatNotification(_vm.message))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }