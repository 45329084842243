var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "notification-hands-up d-flex w-full justify-space-between align-center",
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "d-flex flex-column w-full" }, [
          _c("span", { staticClass: "user-name" }, [
            _vm._v(_vm._s(_vm.user.displayName)),
          ]),
          _c(
            "div",
            { staticClass: "content d-flex" },
            [
              _c("Icon", {
                attrs: { icon: "hand", color: "warning", size: 20 },
              }),
              _c("span", { staticClass: "action-text ml-2" }, [
                _vm._v(
                  _vm._s(_vm.$t(`notifications.${_vm.notification.text}`))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm.showAllowSpeakRequestButton
        ? _c("div", { staticClass: "actions d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "action check text-center mr-3 d-flex justify-center align-center cursor-pointer",
                on: { click: _vm.confirm },
              },
              [
                !_vm.loading
                  ? _c("Icon", {
                      attrs: { icon: "check", color: "white", size: 14 },
                    })
                  : _c("v-progress-circular", {
                      attrs: {
                        indeterminate: "",
                        color: "white",
                        size: 14,
                        width: 2,
                      },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "action close text-center cursor-pointer",
                on: { click: _vm.cancel },
              },
              [
                _c("Icon", {
                  attrs: { icon: "close", color: "white", size: 14 },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }