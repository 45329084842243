var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notification-settings-wrapper pa-3" },
    [
      _c("div", { staticClass: "notification-settings-title pl-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("notificationSettings.title")) + " "),
      ]),
      _c(
        "v-col",
        { staticClass: "notification-settings" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t(
                        "notificationSettings.activateNotificationSound"
                      ),
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.enableSound,
                      callback: function ($$v) {
                        _vm.$set(_vm.soundSettings, "enableSound", $$v)
                      },
                      expression: "soundSettings.enableSound",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t("notificationSettings.userJoinedSound"),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.userJoined,
                      callback: function ($$v) {
                        _vm.$set(_vm.soundSettings, "userJoined", $$v)
                      },
                      expression: "soundSettings.userJoined",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t(
                        "notificationSettings.webinarStreamStarted"
                      ),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.webinarStarted,
                      callback: function ($$v) {
                        _vm.$set(_vm.soundSettings, "webinarStarted", $$v)
                      },
                      expression: "soundSettings.webinarStarted",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t(
                        "notificationSettings.webinarStreamStopped"
                      ),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.webinarStopped,
                      callback: function ($$v) {
                        _vm.$set(_vm.soundSettings, "webinarStopped", $$v)
                      },
                      expression: "soundSettings.webinarStopped",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t(
                        "notificationSettings.conferenceNewUnreadMessage"
                      ),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.conferenceIncomingMessage,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.soundSettings,
                          "conferenceIncomingMessage",
                          $$v
                        )
                      },
                      expression: "soundSettings.conferenceIncomingMessage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t(
                        "notificationSettings.webinarNewUnreadMessage"
                      ),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.webinarIncomingMessage,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.soundSettings,
                          "webinarIncomingMessage",
                          $$v
                        )
                      },
                      expression: "soundSettings.webinarIncomingMessage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-3",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "success",
                      label: _vm.$t("notificationSettings.onRecording"),
                      disabled: !_vm.soundSettings.enableSound,
                      "hide-details": true,
                    },
                    model: {
                      value: _vm.soundSettings.recording,
                      callback: function ($$v) {
                        _vm.$set(_vm.soundSettings, "recording", $$v)
                      },
                      expression: "soundSettings.recording",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }