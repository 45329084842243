var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toast-container" },
    [
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-leftTop",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["leftTop"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-leftCenter",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["leftCenter"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-leftBottom",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["leftBottom"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-rightTop",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["rightTop"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-rightCenter",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["rightCenter"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-rightBottom",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["rightBottom"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-centerTop",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["centerTop"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-centerCenter",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["centerCenter"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "toaster toaster-centerBottom",
          attrs: { name: "notification", tag: "div" },
        },
        _vm._l(_vm.toasts["centerBottom"], function (toast) {
          return _c("Toast", { key: toast.id, attrs: { toast: toast } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }