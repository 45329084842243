<template>
  <Modal :title="$t('dialIn')">
    <div class="text-center" v-if="available">
      <div class="mb-8 mt-3">{{ $t('dialInInfo') }}</div>
      <div class="" v-for="(value, key) in conferenceNumbers" :key="key">
        <div v-for="numbers in value" :key="numbers">
          <div class="phone-number ml-16 pl-6">
            <CopyLink :text="maskPhone(numbers)" :copy-text="$t('dialInCopy', { maskPhone: maskPhone(numbers), pinNumber })" />
          </div>
        </div>
      </div>
      <div class="pin-number mt-6 d-inline-block rounded-lg mb-10">
        <div class="mt-2 white--text">PIN: {{ pinNumber }}</div>
      </div>
    </div>
    <v-alert type="info" icon="feather icon-info" v-else>
      {{ $t('passwordProtectedWarn') }}
    </v-alert>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import xpermeetLib from 'xpermeet-lib';
import CopyLink from '@/components/Shared/CopyLink';

export default {
  name: 'DialInModal',
  components: { CopyLink },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      conferenceId: null,
      conferenceNumbers: [],
      errors: null,
      loading: true,
    };
  },
  computed: {
    pinNumber() {
      if (!this.conferenceId) return '-';
      const n = this.conferenceId.split('');
      return `${this.splice(n, 4)}-${this.splice(n, 3)}-${n.join('')}`;
    },
  },
  mounted() {
    xpermeetLib.dialIn.get(this.roomName()).then(res => {
      this.conferenceId = `${res.conferenceMapper.id}`;
      this.conferenceNumbers = res.numberList.numbers;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_DIAL_IN_MODAL']),
    roomName() {
      return this.$route?.params?.roomName;
    },
    close() {
      this.TOGGLE_DIAL_IN_MODAL();
    },
    splice(n, size) {
      return n.splice(0, size).join('');
    },
    maskPhone(conferenceNumbers) {
      if (!conferenceNumbers) return '-';
      const n = conferenceNumbers.split('');
      return `${this.splice(n, 3)}-${this.splice(n, 3)}-${this.splice(n, 3)}-${this.splice(n, 2)}-${n.join('')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.pin-number {
  width: 229px;
  height: 41px;
  background-color: var(--v-secondary-25);
}
.phone-number {
  width: 250px;
}
</style>
