var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        persistent: "",
        "show-actions": "",
        "full-screen": _vm.isFullScreen,
        title: _vm.$t(_vm.title),
        "confirm-button-text": _vm.$t("IamTheHost"),
        "cancel-button-text": _vm.$t("goToHome"),
        "hide-confirm-btn": _vm.isPrivateRoom,
        "hide-cancel-btn": _vm.showPrivateRoomEntrance,
        "show-close-button": !_vm.showPrivateRoomEntrance,
        "content-class": _vm.modalClass,
      },
      on: { close: _vm.close },
    },
    [
      _vm.showPrivateRoomEntrance
        ? _c("JoinPrivateRoom", {
            attrs: { "page-type": "join", "room-name": this.roomName },
            on: {
              ok: function ($event) {
                _vm.showPrivateRoomEntrance = false
              },
            },
          })
        : _c("span", [_vm._v(_vm._s(_vm.desc))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }