//eslint-disable-next-line
import { consoleError, consoleLog } from "../utils/logger";
import request from "./Request";

class PasswerksServices {
  constructor(conference) {
    this.conference = conference;
    consoleLog("PasswerksServices Initialized...");
    this.domain = window.XPER_CONFIG?.e2eeEncryption?.baseUrl;
  }

  async chipper(customerId, callback) {

    // get publick key -> send rsautil encrypt -> get pgp message

    let url = `${this.domain}/GetPublicKeyCustomer/${customerId}`;
    let testStr = 'test';
    let urlc = `http://127.0.0.1:23658/cipher/decrypt`;
    let bodyc = {
      customerId: customerId,
      cipher: ``,
    };

    // get public key
    request.get(
        url,
        {
          then: async (result) => {
            if (result && result.IsSuccess) {
              let publicKey = result.Message;

              // send rsi util encrypt check
              let encryptedText = await this.conference.room._rsaUtil.encrypt(publicKey, testStr);

              // set encrypted text to cipher
              bodyc.cipher = encryptedText;

              // set request timeout
              request.post(urlc, bodyc, (data) => {
                // data.result rsa utile gonderdigim encrypted text olmasını bekliyorum
                callback(data);
              }, 'json', 20000);
            }
          },
          exception: (ex) => {
            console.log(ex);
          },
        },
        "json",
    );

  }
  /**
   * register method grpc register
   * @param userPassword
   */
  register(userPassword, callback) {
    let url = `${this.domain}/Register`;
    console.log("register call");
    request.post(
        url,
        {
          userPassword: userPassword,
        },
        (result) => {
          if (callback) {
            console.log("register result", result);
            callback(result);
          }
        },
    );
  }

  /**
   * groc GetPublicKeyCustomer method
   * this mehod gets public key from passwerks services and send it to
   * conerence.setParticipantPublicKey to set jitsi.lib E2EEMessaging
   *
   * - get participant keycloak id from jwt token "sub": "xxx-xxx-xxx-xxx-xxx" property
   * - prepare body param object:
   * {
   *   "CustomerId": "2c928ecb-9ca1-4d3f-9c1d-cbfb62d07654"
   * }
   * - sent it to GetPublicKeyCustomer
   * - get the result and call conference.setParticipantPublicKey with that result
   *
   *
   * server request result ex:
   * {
      "IsSuccess": false,
      "Message": "Customer does not exist",
      "LangKey": "",
      "Result": null,
      "HttpStatusCode": 500
    }

   if result is false then return false;
   */
  getPublicKeyCustomer() {
    return async (participant) => {
      let id = participant.getProperty("KeycloakId");
      let url = `${this.domain}/GetPublicKeyCustomer/${id}`;
      if (!participant.publicKey && id) {
        await new Promise((resolve) => {
          request.get(
            url,
            {
              then: (result) => {
                if (result && result.IsSuccess) {
                  participant["publicKey"] = result.Message;
                }
                resolve();
              },
              exception: (ex) => {
                console.log(ex);
              },
            },
            "json",
          );
        });
      }
      return participant.publicKey;
    };
  }

  /**
   * GetPrivateKeyCustomer
   */
  /**
   * groc GetPrivateKeyCustomer method
   * this mehod gets public key from passwerks services and send it to
   * conerence.setParticipantPublicKey to set jitsi.lib E2EEMessaging
   *
   * - get partiipant keycloak id from jwt token
   * - prepare body param object:
   * {
   *   "CustomerId": "2c928ecb-9ca1-4d3f-9c1d-cbfb62d07654"
   * }
   * - sent it to GetPublicKeyCustomer
   * - get the result and call conference.setParticipantPublicKey with that result
   *
   * if user toggle to end to end encry.. if gets error show info message for registration
   */
  getPrivateKeyCustomer(callback) {
    let url = `${this.domain}/GetPrivateKeyCustomer`;
    console.log("getPrivateKeyCustomer call");
    request.get(
      url,
      {
        then: (result) => {
          callback(result.IsSuccess, result.Message);
        },
        exception: (ex) => {
          if(ex.response?.status === 400) {
            callback(false, 'unauthenticated');
          } else if(ex.response?.data?.HttpStatusCode === 500) {
            callback(ex.response.data.IsSuccess, 'customerDoesNotExistPleaseRegister');
          }
        },
      },
      "json",
    );

    // if error occured, message contains message text...
  }
}

export default PasswerksServices;
