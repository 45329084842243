var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: _vm.persistent,
        "max-width": _vm.maxWidth,
        "content-class": `modal-container ${_vm.contentClass} ${
          !_vm.showActions ? "hide-actions" : ""
        } `,
        "overlay-opacity": "0.6",
        "overlay-color": "modal-overlay",
        fullscreen: _vm.isFullScreen,
      },
      on: {
        "click:outside": function ($event) {
          return _vm.close("outside")
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "popup-color", elevation: "0" } },
        [
          _vm.showCloseButton
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                  },
                  attrs: { "data-test-id": "modal-close-icon" },
                },
                [
                  _c("icon", {
                    staticClass: "cursor-pointer",
                    attrs: { color: "white", icon: "x" },
                    on: {
                      click: function ($event) {
                        return _vm.close("cancel")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.smallTitle
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "10px",
                    top: "14px",
                  },
                  attrs: { "data-test-id": "modal-close-icon" },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _vm.showBackButton
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "cursor-pointer",
                    attrs: { color: "white", icon: "arrow-left" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("back")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.smallTitle
            ? _c(
                "v-card-title",
                { staticClass: "modal-title justify-center" },
                [_c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")])]
              )
            : _vm._e(),
          _vm.subTitle
            ? _c("v-card-subtitle", [_c("div", [_vm._v(_vm._s(_vm.subTitle))])])
            : _vm._e(),
          _c(
            "v-card-text",
            { class: { "padding-0": _vm.paddingNone } },
            [_vm._t("default")],
            2
          ),
          _vm.showBottomDivider ? _c("v-divider") : _vm._e(),
          _vm.showActions
            ? _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full d-flex justify-center align-center flex-wrap-reverse mb-n2",
                  },
                  [
                    !_vm.hideCancelBtn
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mb-2",
                            class: {
                              "mb-3": _vm.$isMobile,
                              "mx-2": !_vm.$isMobile,
                            },
                            attrs: {
                              "data-test-id": "modal-cancel-button",
                              block: _vm.$isMobile,
                              "min-width": "185",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.close("cancel")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]
                        )
                      : _vm._e(),
                    !_vm.hideConfirmBtn
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mb-2",
                            class: {
                              "mb-3": _vm.$isMobile,
                              "mx-2": !_vm.$isMobile,
                            },
                            attrs: {
                              "data-test-id": "modal-confirm-button",
                              block: _vm.$isMobile,
                              "min-width": "185",
                              color: "secondary",
                              disabled: _vm.confirmButtonDisabled,
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.close("confirm")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }