import XperMeetLib from 'xpermeet-lib';

import { stayAwake } from '@/helpers/screen';
import { fetchConfigs } from '@/services/config';
import { consoleError } from '@/helpers/logger';

fetchConfigs().then(() => {
  XperMeetLib.initKeycloakManager()
    .then(() => {
      XperMeetLib.initServices();
      stayAwake();
      require('./main');
    })
    .catch(e => {
      consoleError('Init Keycloak Manager Error: ', e);
      window.location.replace('./error.html');
    });
});
