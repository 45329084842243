var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t(_vm.title),
        "confirm-button-text": _vm.$t(_vm.confirmText),
        "cancel-button-text": _vm.$t("cancel"),
        "confirm-button-disabled": !_vm.password && !_vm.locked,
        "show-actions": "",
      },
      on: { close: _vm.onModalClose },
    },
    [
      _vm.locked
        ? _c("div", { staticClass: "text-center" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("conferenceIsGoingToBeUnlocked"))),
            ]),
          ])
        : _c("div", { staticClass: "pt-2 pb-2" }, [
            _c("div", { staticClass: "text-center mb-6 mt-" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("lockRoomWarning")))]),
            ]),
            _c(
              "form",
              { attrs: { autocomplete: "off", onsubmit: "return false;" } },
              [
                _c("TextField", {
                  attrs: {
                    autocomplete: "conference-password",
                    type: _vm.showPassword ? "text" : "password",
                    name: "conference-password",
                    label: _vm.$t("setConferencePassword"),
                    required: "",
                    "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                    "aria-required": "true",
                    "error-messages": _vm.errorMessages,
                    "hide-details": !_vm.errorMessages.length,
                  },
                  on: {
                    "click:append": function ($event) {
                      _vm.showPassword = !_vm.showPassword
                    },
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }