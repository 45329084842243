var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "room-error" },
        [
          _c("Logo", { staticClass: "mb-2" }),
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "amber" },
          }),
        ],
        1
      )
    : _vm.invalidRoom
    ? _c(
        "div",
        { staticClass: "room-error" },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("invalidRoomName")) + "!")]),
          _c("v-btn", { attrs: { color: "secondary", to: "/" } }, [
            _vm._v(_vm._s(_vm.$t("goToHome"))),
          ]),
        ],
        1
      )
    : _c(
        "div",
        { on: { mousemove: _vm.mouseMove } },
        [
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "20" },
              attrs: {
                value: _vm.showChatScreen,
                right: "",
                width: _vm.fullWidthRightMenu ? "100%" : "380",
                app: !_vm.rightMenuTemporary,
                absolute: _vm.rightMenuTemporary,
                temporary: _vm.rightMenuTemporary,
              },
              on: { input: _vm.onRightMenuInput },
            },
            [_c("right-menu", { on: { close: _vm.closeMenu } })],
            1
          ),
          _c(
            "div",
            {
              staticClass: "meet-container",
              attrs: {
                id: "meet-container",
                fullscreen: !!_vm.getFullScreenUserId,
              },
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "meet-wrapper d-flex flex-column",
                  attrs: { fluid: "" },
                },
                [
                  _c("div", { staticClass: "meet-header" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "meet-top-menu-container d-flex justify-space-between flex-row align-center flex-grow-1 h-full",
                      },
                      [
                        _c("meet-top-menu", {
                          staticClass: "flex-grow-1",
                          on: {
                            itemClicked: _vm.onConferenceMenuItemClicked,
                            changeCamera: (val) => (_vm.selectedCameraId = val),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "meet-content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "meet-page",
                          attrs: { "data-layout-type": _vm.activeLayoutType },
                        },
                        [
                          _vm.activeLayoutType === "grid"
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "users-container" },
                                  [
                                    _c("VideoGridlayout", {
                                      ref: "videoGridLayout",
                                      attrs: {
                                        layout: _vm.activeLayout,
                                        users: _vm.getUsers,
                                      },
                                      on: {
                                        fullscreen: _vm.onFullScreenClicked,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("VideoDynamicLayout", {
                                  attrs: { users: _vm.getUsers },
                                  on: { fullscreen: _vm.onFullScreenClicked },
                                }),
                              ],
                        ],
                        2
                      ),
                      _c("RecordControl"),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "meet-footer d-flex justify-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "meet-bottom-menu-container d-flex flex-row align-center flex-grow-1",
                        },
                        [
                          !_vm.$isMobile && _vm.conferenceJoined
                            ? _c(
                                "div",
                                { staticClass: "flex-grow-1 d-flex" },
                                [
                                  _vm.activeLayoutType !== "grid"
                                    ? _c("Pagination")
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("meet-bottom-menu", {
                            staticClass: "flex-grow-1",
                            on: {
                              itemClicked: _vm.onConferenceMenuItemClicked,
                            },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.$isMobile && _vm.conferenceJoined,
                                  expression: "!$isMobile && conferenceJoined",
                                },
                              ],
                              class: {
                                "d-flex flex-row justify-end align-center flex-grow-1":
                                  !_vm.$isMobile,
                              },
                            },
                            [
                              _vm.$can("lock", "Meet")
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "menu-button",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        class: {
                                                          "cursor-pointer":
                                                            _vm.$can(
                                                              "lock",
                                                              "Meet"
                                                            ),
                                                        },
                                                        attrs: {
                                                          color: _vm.locked
                                                            ? "success"
                                                            : "",
                                                          disabled: !_vm.$can(
                                                            "lock",
                                                            "Meet"
                                                          ),
                                                          icon: _vm.locked
                                                            ? "lock-2"
                                                            : "unlock-1",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.showLockConferenceModal,
                                                        },
                                                      },
                                                      "menu-button",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3047248551
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lockDescription"))
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c("room-timer", {
                                staticClass: "ml-2",
                                attrs: {
                                  "meeting-duration": _vm.meetingDuration,
                                },
                                on: { open: _vm.openStatistics },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c("MediaDevicesInitiator", {
            ref: "mediaDeviceInitiator",
            attrs: { "camera-device-id": _vm.selectedCameraId },
            on: { initiated: _vm.mediaDeviceInitiated },
          }),
          _c(
            "div",
            { staticClass: "meet-modal-container" },
            [
              [_c("LogoutConfirmModal")],
              _vm.showLobbyPreMeeting
                ? [
                    _c("LobbyPreMeeting", {
                      attrs: {
                        "is-response-waiting": _vm.responseWaitingForLobby,
                      },
                    }),
                  ]
                : _vm.waitingForTheHost
                ? [
                    _c("WaitingForTheHost", {
                      attrs: {
                        value: _vm.waitingForTheHost,
                        "room-name": _vm.roomName,
                      },
                    }),
                  ]
                : _vm.conferenceMaxUserReached
                ? [
                    _c("MaxUserReachedModal", {
                      attrs: { value: _vm.conferenceMaxUserReached },
                    }),
                  ]
                : _vm.showConferencePasswordModal
                ? [
                    _c("PasswordRequired", {
                      attrs: { value: _vm.showConferencePasswordModal },
                    }),
                  ]
                : _vm.showProfileModal
                ? [
                    _c("ProfileModal", {
                      attrs: {
                        showDialog: _vm.showProfileModal,
                        "display-name":
                          _vm.getLocalUser && _vm.getLocalUser.displayName,
                      },
                      on: {
                        layoutTypeChanged: _vm.CHANGE_LAYOUT_TYPE,
                        save: _vm.onProfileModalSave,
                        close: _vm.onProfileModalClose,
                      },
                    }),
                  ]
                : _vm.showWhiteBoardModal
                ? [
                    _c("WhiteBoard", {
                      attrs: { value: _vm.showWhiteBoardModal },
                    }),
                  ]
                : _vm.showE2eePromptModal
                ? _c("E2eePromptModal")
                : _vm._e(),
            ],
            2
          ),
          _vm.showNewDeviceAddedModal && _vm.getFirstAddedDevice
            ? [_c("NewDeviceModal")]
            : _vm._e(),
          _c("UsersAudioContainer"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }