var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("conferencePassword"),
        "confirm-button-text": _vm.$t("go"),
        "cancel-button-text": _vm.$t("cancel"),
        "show-actions": "",
        persistent: "",
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("password", {
        staticClass: "mb-3 mx-2",
        attrs: {
          type: "password",
          name: "conference-password",
          placeholder: _vm.$t("password"),
          required: "",
          "single-line": "",
          solo: "",
          dense: "",
          toggle: true,
          badge: false,
          showStrengthMeter: false,
          "aria-required": "true",
          autocomplete: "off",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.goToConference.apply(null, arguments)
          },
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _vm.wrongPassword
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "v-alert",
                { attrs: { dense: "", outlined: "", type: "error" } },
                [_vm._v(" " + _vm._s(_vm.$t("enteredIncorrectPassword")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }