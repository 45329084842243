<template>
  <div class="shared-file-list py-3">
    <div class="px-3">
      <Tab v-if="showWebinarChatTab && !isWebinar" :tabItems="tabItems" :sharedWith="sharedWith" @onTabClick="onTabClick"></Tab>
      <v-tabs v-if="!isWebinar" class="file-share-tab" v-model="sharedFilesTabs">
        <v-tab data-test-id="incoming-shared-file-tab" class="text-center" style="width: 50%">{{ $t('incomingFiles') }}</v-tab>
        <v-tab data-test-id="sent-shared-file-tab" class="text-center" style="width: 50%">{{ $t('sentFiles') }}</v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="sharedFilesTabs" class="v-tab-content" :class="{ webinar: isWebinar }">
      <v-tab-item class="tab-item-content px-4">
        <div v-for="file in sharedFiles" :key="file.name" class="tab-item-file my-5">
          <div class="shared-file-list--item d-flex align-center">
            <icon icon="file" size="30"></icon>
            <div class="ml-4 shared-file-list--item--content">
              <div class="text-body-2 d-flex">
                <div class="ellipsis shared-file-name">{{ getFileNameWithoutExt(file.fileName) }}</div>
                <div class="shared-file-extension">.{{ getFileExtension(file.fileName) }}</div>
              </div>
              <div class="d-flex align-center">
                <span class="shared-file-list--item--date mr-2">{{ formatDate(file.date) }}</span>
                <div class="shared-file-list--item--file-name ellipsis">{{ file.user }}</div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <icon v-if="!fileStatus[file.fileKey]" icon="download" role="button" @click="download(file)"></icon>
            <v-progress-circular v-else :value="fileStatus[file.fileKey]" color="secondary">
              <span style="font-size: 9px">%{{ fileStatus[file.fileKey] }}</span>
            </v-progress-circular>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item class="tab-item-content px-4">
        <div v-for="file in myFiles" :key="file.name" class="tab-item-file my-5">
          <div class="shared-file-list--item d-flex align-center">
            <icon icon="file" size="30"></icon>
            <div class="ml-4 shared-file-list--item--content">
              <div class="text-body-2 d-flex">
                <div class="ellipsis shared-file-name">{{ getFileNameWithoutExt(file.fileName) }}</div>
                <div class="shared-file-extension">.{{ getFileExtension(file.fileName) }}</div>
              </div>
              <div class="d-flex align-center">
                <span class="shared-file-list--item--date mr-2">{{ formatDate(file.date) }}</span>
                <div class="shared-file-list--item--file-name ellipsis">{{ file.user }}</div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <icon v-if="!fileStatus[file.fileKey]" icon="download" role="button" @click="download(file)"></icon>
            <v-progress-circular v-else :value="fileStatus[file.fileKey]" color="secondary">
              <span style="font-size: 9px">%{{ fileStatus[file.fileKey] }}</span>
            </v-progress-circular>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Xperdrive from 'xperdrive-lib';

import { getFileNameWithoutExt, getFileExtension, getMySharedFileKeys } from '@/helpers/file';

export default {
  name: 'SharedFileList',
  props: {
    isWebinar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileStatus: {},
      sharedWith: this.isWebinar ? 'webinar' : 'conference',
      sharedFilesTabs: 0,
      tabItems: [
        {
          text: this.$t('conferenceFiles'),
          icon: 'file',
          value: 'conference',
        },
        {
          text: this.$t('webinarFiles'),
          icon: 'tv-2',
          value: 'webinar',
        },
      ],
    };
  },
  computed: {
    ...mapState('Conference', { conferenceFiles: 'files' }),
    ...mapState('Webinar', { webinarFiles: 'files' }),
    ...mapGetters('Conference', { conferenceUser: 'getLocalUser' }),
    ...mapGetters('Webinar', { webinarUser: 'getLocalUser', showWebinarChatTab: 'showWebinarChatTab' }),
    files() {
      return this.sharedWith === 'webinar' ? this.webinarFiles : this.conferenceFiles;
    },
    sharedFiles() {
      if (this.isWebinar) {
        return this.files;
      }
      const myFiles = getMySharedFileKeys(this.isWebinar ? 'webinar' : 'room');
      return this.files.filter(f => !myFiles.includes(f.fileKey));
    },
    myFiles() {
      const myFiles = getMySharedFileKeys(this.isWebinar ? 'webinar' : 'room');
      return this.files.filter(f => myFiles.includes(f.fileKey));
    },
  },
  methods: {
    getFileNameWithoutExt,
    getFileExtension,
    formatDate(date) {
      return dayjs(date).format('HH:mm');
    },
    download(file) {
      Xperdrive.FileDownloadAnonymous(file, progress => {
        let status = Math.floor((progress.loaded * 100) / progress.total);
        this.$set(this.fileStatus, file.fileKey, status);
        if (status === 100) {
          setTimeout(() => {
            this.$set(this.fileStatus, file.fileKey, null);
          }, 1000);
        }
      });
    },
    onTabClick(value) {
      this.sharedWith = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-file-list {
  &--item {
    &--file-name {
      font-size: 12px;
    }

    &--date {
      background-color: var(--v-body-base);
      font-size: 8px;
      padding: 2px 3px;
      border-radius: 3px;
    }

    &--content {
      max-width: 230px;
    }
  }
  .file-share-tab {
    border-bottom: 2px solid var(--v-body-base);
    &.v-tabs {
      .v-tab {
        font-size: 12px;
        padding: 0 10px;
      }
    }
  }
  .v-tab-content {
    background-color: var(--v-darkblue-base);
    &.webinar {
      border-top: none;
      overflow-y: scroll;
      max-height: 100%;
    }
  }
  .tab-item-content {
    height: 100% !important;
    .tab-item-file {
      height: 100% !important;
    }
  }
  .v-tabs-items {
    border-top-color: transparent;
  }
}
</style>
