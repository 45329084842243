<template>
  <Modal
    max-width="500px"
    :sub-title="$t('fileShareDesc')"
    :title="$t('fileShare')"
    contentClass="file-share-wrapper"
    show-actions
    show-bottom-divider
    :auto-close="false"
    :cancelButtonText="$t(cancelText)"
    :confirmButtonText="$t(confirmText)"
    :loading="loading"
    :confirm-button-disabled="isConfirmButtonDisabled"
    @close="onModalClose"
  >
    <div class="file-share-modal mb-4">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="file-share px-8">
            <TextField hide-details :label="$t('searchUsersByUserName')" v-model="searchText" />
            <div class="d-flex align-center justify-space-between mt-3 mb-6">
              <Checkbox
                color="white"
                class="file-share--select-all"
                hide-details
                :label="$t('shareWithAllRoom')"
                :input-value="allRoom"
                @click.stop="changeAllRoomStatus"
              />
              <span class="file-share--count">{{ $t('participantSelected', { count: selectedUserCount }) }}</span>
            </div>
            <div class="file-share--user-list">
              <div v-for="user in filteredUsers" :key="user.id" class="d-flex cursor-pointer mb-4 align-center" @click="selectUser(user.id)">
                <Checkbox color="white" class="file-share--select-user" hide-details :input-value="allRoom || selectedUsers.indexOf(user.id) !== -1" />
                <Avatar :display-name="user.displayName" :image="user.avatar" :avatar-size="48" />
                <span class="ml-4 file-share--user-list--name ellipsis">{{ user.displayName }}</span>
              </div>
            </div>
            <Checkbox
              class="file-share--share-with-webinar"
              v-if="isWebinarStarted"
              color="white"
              hide-details
              :label="$t('shareWithWebinar')"
              v-model="shareWithWebinar"
            />
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="file-upload">
            <div class="dropzone d-flex flex-column align-center justify-space-between px-5 pb-1" @dragover="dragover" @drop="drop">
              <Icon icon="paperclip" class="mr-4" />
              <div class="choose-file-wrapper d-flex align-center justify-center">
                <label for="assetsFieldHandle">
                  <input style="display: none;" type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" ref="file" @change="onChange" />
                  <span class="choose-file cursor-pointer mr-1">{{ $t('chooseAFile') }}</span>
                </label>
                <span>{{ $t('or') }} {{ $t('dragAndDrop') }}</span>
              </div>
              <span class="dropzone--sub-text">{{ $t('maxSize', { size: 50, type: 'MB' }) }}</span>
            </div>
            <div class="file-upload--list my-6 mx-5">
              <FileUploadItem
                v-for="(file, index) in filelist"
                :key="file.name"
                :index="index"
                class="mb-7"
                :fileName="file.name"
                :progress="fileStatus[file.name]"
                :status="fileState[file.name]"
                @delete="deleteFile"
              />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import xperdrive from 'xperdrive-lib';
import FileUploadItem from '@/components/File/FileUploadItem';
import { consoleError } from '@/helpers/logger';
import { TOAST_TYPE } from '@/constants/toast';
import { addFileKeyToStorage } from '@/helpers/file';

export default {
  name: 'FileShareModal',
  components: {
    FileUploadItem,
  },
  data() {
    return {
      tab: 0,
      selectedUsers: [],
      maxPublicFileSize: 50000000,
      filelist: [],
      uploadedFiles: [],
      fileState: {},
      fileStatus: {},
      fileSources: {},
      loading: false,
      errorMessage: false,
      allRoom: false,
      shareWithWebinar: false,
      searchText: null,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getRemoteUsers', 'getLocalUser']),
    ...mapGetters('Webinar', ['isWebinarStarted']),
    filteredUsers() {
      if (!this.searchText) {
        return this.getRemoteUsers;
      }
      return this.getRemoteUsers.filter(u => new RegExp(this.searchText, 'gi').test(u.displayName));
    },
    selectedUserCount() {
      return this.selectedUsers.length;
    },
    isSubmitDisabled() {
      return this.filelist.length === 0;
    },
    confirmText() {
      if (this.tab === 0) {
        return 'continue';
      }

      return 'share';
    },
    cancelText() {
      return this.tab === 0 ? 'cancel' : 'back';
    },
    isAllSelected() {
      return this.getRemoteUsers.length === this.selectedUsers.length;
    },
    isConfirmButtonDisabled() {
      if (this.tab === 0) return this.selectedUserCount === 0 && !this.shareWithWebinar;
      return this.filelist.length === 0;
    },
  },
  methods: {
    ...mapActions('Conference', ['shareFile']),
    ...mapActions('Notification', ['showToastNotification']),
    onModalClose(type) {
      if (type === 'confirm') {
        this.next();
      } else {
        this.close();
      }
    },
    deleteFile(fileName) {
      this.filelist = this.filelist.filter(f => f.name !== fileName);
      this.uploadedFiles = this.uploadedFiles.filter(f => f.fileName !== fileName);
      this.cancel(fileName);
    },
    resetData() {
      this.filelist = [];
      this.fileStatus = {};
      this.fileSources = {};
      this.loading = false;
    },
    close(type) {
      if (this.tab === 0 || type === 'force') {
        this.$set(this, 'filelist', []);
        this.fileStatus = {};
        this.errorMessage = false;
        this.$modal?.close();
      } else {
        this.tab = 0;
      }
    },
    async next() {
      if (this.tab === 1) {
        this.share();
        // await this.share();
      } else if (this.tab !== 1) {
        this.tab = 1;
      } else {
        this.close('force');
      }
    },
    cancel(fileName) {
      const source = this.fileSources[fileName];
      if (source) {
        source.cancel('Upload operation canceled by the user.');
        this.$set(this.fileSources, fileName, undefined);
        this.$set(this.fileStatus, fileName, undefined);
      }
    },
    async share() {
      if (!this.filelist.length) {
        this.loading = false;
        return;
      }

      try {
        this.loading = true;
        const promises = this.filelist.map(file => {
          const formData = new FormData();
          formData.append('formFile', file);
          this.$set(this.fileState, file.name, 'process');

          const { promise, source } = xperdrive.FileUploadAnonymous(this.params, formData, this.trackFileProgress(file.name));
          this.fileSources[file.name] = source;

          promise
            .then(r => {
              if (r.data.httpStatusCode > 299) {
                this.errorMessage = r.data.result;
                this.$set(this.fileState, file.name, 'error');
              } else {
                const { result } = r.data;
                this.uploadedFiles.push(result);
                this.$set(this.fileState, file.name, 'success');
              }
            })
            .catch(err => {
              consoleError(err);
              this.$set(this.fileState, file.name, 'error');
            });

          return promise;
        });

        await Promise.all(promises);

        const payload = {
          files: this.uploadedFiles,
          fileStates: this.fileState,
          users: [...this.selectedUsers, this.getLocalUser.id],
          shareWithWebinar: this.shareWithWebinar,
          shareWithAllRoom: this.allRoom,
        };
        await this.shareFile(payload);

        const fileKeys = this.uploadedFiles.map(f => f.fileKey);
        const shareTo = [];
        if (payload.shareWithAllRoom || this.selectedUsers.length) {
          shareTo.push('room');
        }

        if (payload.shareWithWebinar) {
          shareTo.push('webinar');
        }

        addFileKeyToStorage(fileKeys, shareTo);
        this.close('force');
      } catch (err) {
        this.loading = false;
        consoleError(err);
        this.showToastNotification({ body: 'somethingWentWrong', config: { type: TOAST_TYPE.ERROR } });
      }
    },
    onChange() {
      Array.from(this.$refs.file.files).forEach(file => {
        if (!this.findFile(file)) {
          this.filelist.push(file);
          this.$set(this.fileState, file.name, 'idle');
        }
      });

      const filelist = this.filelist.filter(f => {
        if (f.size <= this.maxPublicFileSize) {
          return true;
        }

        this.errorMessage = true;
        return false;
      });

      this.$set(this, 'filelist', filelist);

      this.$refs.file.value = '';
    },
    findFile(file) {
      return this.filelist.find(existingFile => {
        return (
          existingFile.name === file.name &&
          existingFile.lastModified === file.lastModified &&
          existingFile.size === file.size &&
          existingFile.type === file.type
        );
      });
    },
    dragover(event) {
      event.preventDefault();
    },
    trackFileProgress(fileName) {
      return progress => {
        const p = Math.floor((progress.loaded * 100) / progress.total);
        this.$set(this.fileStatus, fileName, p);
      };
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    selectUser(user) {
      const index = this.selectedUsers.indexOf(user);
      if (index === -1) {
        this.selectedUsers.push(user);
      } else {
        this.selectedUsers.splice(index, 1);
      }

      this.allRoom = this.selectedUsers.length === this.getRemoteUsers.length;
    },
    changeAllRoomStatus() {
      this.allRoom = !this.allRoom;
      this.selectedUsers = this.allRoom ? this.getRemoteUsers.map(user => user.id) : [];
    },
  },
};
</script>

<style lang="scss">
.file-share-wrapper {
  .v-window-item {
    background-color: var(--v-popup-color-base);
  }

  .avatar-default-icon {
    min-width: 36px !important;
    min-height: 36px !important;
  }

  .avatar {
    min-width: 36px !important;
    min-height: 36px !important;
  }

  .v-item-group {
    border-top: none !important;
  }

  .file-upload {
    max-height: 307px;

    .choose-file-wrapper {
      color: white;
      flex: 1;

      .choose-file {
        color: var(--v-link-base);
        text-decoration: underline;
      }
    }

    .dropzone {
      position: relative;
      width: 100%;
      height: 100px;
      border-radius: 8px;
      border: 1px dashed var(--v-date-base);

      .v-icon {
        position: absolute;
        top: 36px;
        left: 22px;
      }

      &--sub-text {
        font-size: 10px;
        margin-top: -22px;
      }
    }

    &--list {
      overflow-x: hidden;
    }
  }

  .v-input--checkbox {
    margin: 0;
  }

  .file-share-modal {
    .file-share {
      max-height: 307px;

      &--count {
        font-size: 10px;
        color: white;
      }

      &--select-all,
      &--select-user {
        font-size: 14px;
        color: white;
      }

      &--share-with-webinar {
        font-size: 14px;
      }

      &--user-list {
        &--name {
          color: white;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
