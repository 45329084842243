var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "100px" } }, [
    _c(
      "div",
      [
        _c("v-btn", [_vm._v("Default Button Text")]),
        _c("v-btn", { attrs: { color: "primary" } }, [
          _vm._v("Default Button Text"),
        ]),
        _c(
          "v-btn",
          { attrs: { color: "secondary", "x-large": "" } },
          [
            _c("icon", { attrs: { left: "", icon: "plus" } }),
            _vm._v(" Konferans Oluştur "),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("text-field", {
          staticStyle: { width: "300px" },
          attrs: { placeholder: "Text Field", "hide-details": "" },
        }),
        _c("text-field", {
          staticClass: "mt-2",
          staticStyle: { width: "300px" },
          attrs: { label: "Text Field", "hide-details": "" },
        }),
        _c("text-field", {
          staticClass: "mt-2",
          staticStyle: { width: "300px" },
          attrs: {
            label: "Text Field",
            filled: "",
            "single-line": false,
            solo: false,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("icon", { attrs: { icon: "settings" } }),
        _c("icon", { attrs: { icon: "loader", loading: "" } }),
      ],
      1
    ),
    _c("div", [_c("MenuButton", { attrs: { icon: "mic" } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }