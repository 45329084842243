<script>
import { VTextarea } from 'vuetify/lib';

export default {
  name: 'Textarea',
  extends: VTextarea,
  mixins: [
    {
      props: {
        backgroundColor: {
          default: 'primary',
        },
        solo: { default: true },
        singleLine: { default: true },
        noResize: { default: true },
      },
    },
  ],
};
</script>
