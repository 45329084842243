<template>
  <div class="mt-4 mb-2 d-flex justify-end">
    <TextField readonly hide-details :value="toast.body" dense height="34" background-color="rgba(116, 132, 255, 0.2)">
      <template v-slot:append>
        <span class="toast-copy-icon cursor-pointer" v-clipboard:copy="toast.body" v-clipboard:success="onCopy" v-clipboard:error="onError">
          <icon size="24" class="mt-1" icon="copy" />
        </span>
      </template>
    </TextField>
  </div>
</template>

<script>
export default {
  name: 'ToastCopy',
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCopy() {
      if (this.toast?.config?.onCopy) {
        this.toast.config.onCopy();
      }
    },
    onError() {
      if (this.toast?.config?.onError) {
        this.toast.config.onError();
      }
    },
  },
};
</script>

<style lang="scss">
.toast-copy-icon {
  background-color: #1a213344;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  text-align: center;
  margin-top: -6px;
  margin-right: -8px;
}
</style>
