<template>
  <Modal :title="$t('recordingUsers')" max-width="500px">
    <div class="ma-3 mb-0 speaker-stats">
      <v-virtual-scroll :items="getRecordingDurations" height="300" item-height="64">
        <template v-slot="{ item }">
          <v-list-item class="mt-3" dense :key="item.user.id">
            <v-list-item-content>
              <div class="d-flex align-center">
                <avatar :display-name="item.user.displayName" :image="item.user.avatar" :avatar-size="48" />
                <span class="ml-2">{{ item.user.displayName }}</span>
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-row>{{ getRecordTime(item) }}</v-row>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { msToHour } from '@/helpers/time';

export default {
  name: 'RecordUsersModal',
  computed: {
    ...mapState('Conference', ['serverTime']),
    ...mapGetters('Conference', ['getUsers', 'remoteRecordStartedBy', 'getUserById', 'getRecordingDurations']),
  },
  methods: {
    getRecordTime(item) {
      const startedAt = new Date(item.startedAt);
      const seconds = this.serverTime - startedAt.getTime();
      return msToHour(seconds);
    },
  },
};
</script>
