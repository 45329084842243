<template>
  <v-dialog v-model="showWhiteBoardModal" fullscreen persistent>
    <v-card class="whiteboard-modal">
      <v-card-title class="headline font-weight-bold text-h6" primary-title>
        <p class="headlineText">{{ $t('whiteBoard') }}</p>
        <v-spacer />
        <v-btn icon @click="close" color="body">
          <icon icon="x" width="28" />
        </v-btn>
      </v-card-title>
      <v-card-text class="content">
        <WhiteBoard />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import WhiteBoard from '@/components/WhiteBoard/WhiteBoard';

export default {
  name: 'WhiteBoardModal',
  components: {
    WhiteBoard,
  },
  computed: {
    ...mapState('Conference', ['showWhiteBoardModal']),
    ...mapGetters('Conference', ['getLocalUser']),
    localUserIsModerator() {
      return this.getLocalUser?.isModerator;
    },
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_WHITE_BOARD']),
    close() {
      this.TOGGLE_WHITE_BOARD();
    },
  },
};
</script>

<style lang="scss" scoped>
.whiteboard-modal {
  height: 100%;
}
.headlineText {
  padding: 0px 0.5rem 0px 0px;
  margin: 0px;
}
.headline {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 10;

  border-radius: 5px;
}
.content {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0px !important;
}
</style>
