var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pre-join px-5 py-4 px-md-15 py-lg-0 py-xl-8 py-md-8" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c("logo", { staticClass: "mt-6" }),
          _c("lang-switch", { staticClass: "lang-switch" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pre-join--content" },
        [
          _c(
            "div",
            {
              staticClass:
                "description text-center mt-sm-1 mt-md-7 mb-sm-2 mb-md-10 mb-lg-14",
            },
            [
              _c("div", { staticClass: "title mb-2" }, [
                _vm._v(_vm._s(_vm.$t("preview"))),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.$t("prejoin.userNameWarning")) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("prejoin.description")) + " "),
              ]),
            ]
          ),
          !_vm.errorCode
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticStyle: { margin: "0px" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "prejoin-avatar-container",
                          attrs: { cols: "12", sm: "12", md: "7" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "prejoin-avatar-container--video d-flex justify-center align-center",
                              class: {
                                avatar:
                                  !_vm.isCameraActive ||
                                  !_vm.hasCameraAndAllowed,
                              },
                            },
                            [
                              _c("video", {
                                staticClass: "pre-join-video",
                                class: {
                                  hide:
                                    !_vm.isCameraActive ||
                                    !_vm.hasCameraAndAllowed,
                                },
                                staticStyle: { width: "100%" },
                                attrs: {
                                  playsinline: "",
                                  muted: "",
                                  id: "local-video",
                                },
                                domProps: { muted: true },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "prejoin-avatar-container--video--close d-flex justify-center align-center",
                                  class: {
                                    hide:
                                      _vm.isCameraActive &&
                                      _vm.hasCameraAndAllowed,
                                  },
                                },
                                [
                                  _vm.avatar
                                    ? _c("Avatar", {
                                        attrs: {
                                          image: _vm.avatar,
                                          "avatar-size": 150,
                                          "display-name": _vm.displayName,
                                        },
                                      })
                                    : _c("Icon", {
                                        attrs: { icon: "user", size: "50" },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center prejoin-avatar-container--video--actions",
                                },
                                [
                                  _c("menu-button", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      "click-disabled":
                                        !_vm.hasMicrophonAndAllowed,
                                      disabled: !_vm.hasMicrophonAndAllowed,
                                      icon: _vm.micIcon,
                                      "icon-size": "24",
                                      color: _vm.micIconColor,
                                    },
                                    on: { click: _vm.toggleMicrophone },
                                  }),
                                  _c("menu-button", {
                                    attrs: {
                                      "click-disabled":
                                        !_vm.hasCameraAndAllowed,
                                      disabled: !_vm.hasCameraAndAllowed,
                                      icon: _vm.videoIcon,
                                      color: _vm.videoIconColor,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.isCameraActive = !_vm.isCameraActive
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "hints-and-keypoints py-4" },
                            [
                              _c("icon", { attrs: { icon: "lamp" } }),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:0;" },
                                  on: { click: _vm.openHintsAndKeypointsModal },
                                },
                                [_vm._v(_vm._s(_vm.$t("hintsAndKeypoints")))]
                              ),
                            ],
                            1
                          ),
                          _vm.errorText
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2",
                                  attrs: {
                                    icon: false,
                                    outlined: "",
                                    dense: "",
                                    type: "warning",
                                  },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { icon: "info-circle" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "ml-2 text-body-2" },
                                    [_vm._v(_vm._s(_vm.errorText))]
                                  ),
                                ],
                                1
                              )
                            : _vm.checkVideoAndAudioStatus
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2",
                                  attrs: {
                                    icon: false,
                                    outlined: "",
                                    dense: "",
                                    type: "warning",
                                  },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { icon: "info-circle" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "ml-2 text-body-2" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.checkVideoAndAudioStatus)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          class: { "px-0": _vm.$vuetify.breakpoint.smAndDown },
                          attrs: { cols: "12", sm: "12", md: "5" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ml-4 mr-1 join-conference" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("avatar-uploader", {
                                    staticClass:
                                      "avatar-selector-prejoin mr-4 align-center",
                                    attrs: {
                                      localDisplayName: _vm.displayName,
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.avatar = $event
                                      },
                                    },
                                  }),
                                  _c("TextField", {
                                    staticClass: "set-name mt-5",
                                    attrs: {
                                      "data-test-id": "P_PJ_DN",
                                      autofocus: "",
                                      label: _vm.$t("participantName"),
                                      "error-messages": _vm.showError
                                        ? [_vm.$t("requiredField")]
                                        : null,
                                      disabled: _vm.displayNameDisabled,
                                    },
                                    on: {
                                      blur: _vm.onDisplayNameBlur,
                                      keyup: _vm.onDisplayNameKeyUp,
                                    },
                                    model: {
                                      value: _vm.displayName,
                                      callback: function ($$v) {
                                        _vm.displayName = $$v
                                      },
                                      expression: "displayName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isCreate
                                ? _c(
                                    "v-tabs",
                                    {
                                      staticClass: "prejoin-tabs-header",
                                      attrs: {
                                        right: "",
                                        "background-color": "transparent",
                                        grow: "",
                                      },
                                      model: {
                                        value: _vm.tab,
                                        callback: function ($$v) {
                                          _vm.tab = $$v
                                        },
                                        expression: "tab",
                                      },
                                    },
                                    _vm._l(_vm.items, function (item) {
                                      return _c(
                                        "v-tab",
                                        { key: item, attrs: { ripple: false } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "prejoin-tab-titles",
                                            },
                                            [_vm._v(_vm._s(item))]
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-tabs-items",
                                {
                                  staticClass: "prejoin-tabs",
                                  class: { "join-tabs": !_vm.isCreate },
                                  attrs: { touchless: "", value: _vm.tab },
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        value: 0,
                                        "data-test-id": "P-PJ-T-AAV",
                                      },
                                    },
                                    [
                                      _c("DeviceSelector", {
                                        ref: "deviceSelector",
                                        on: {
                                          cameraChange: _vm.handleCameraChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.isCreate
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            value: 1,
                                            "data-test-id": "P-PJ-T-AS",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "prejoin-advenced-settings mt-1",
                                            },
                                            [
                                              _c("StartMutedPolicySettings", {
                                                attrs: {
                                                  "size-prop": {
                                                    middle: "12",
                                                    small: "12",
                                                  },
                                                  remoteCameraControlSupported:
                                                    _vm.remoteCameraControlSupported,
                                                  "initial-data": {
                                                    ..._vm.settings,
                                                  },
                                                },
                                                on: {
                                                  change: (v) =>
                                                    (_vm.settings = v),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "py-4 d-flex justify-end" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-5",
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        block: _vm.$isMobile,
                                        "data-test-id": "P_PJ_JB",
                                        color: "secondary",
                                      },
                                      on: { click: _vm.join },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("join")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-col",
                        [
                          !_vm.showMobileJoinModal
                            ? _c("MediaDevicesInitiator", {
                                ref: "mediaDeviceInitiator",
                                attrs: {
                                  "camera-device-id": _vm.selectedCameraId,
                                },
                                on: { initiated: _vm.mediaDeviceInitiated },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.errorCode
            ? [_c("SystemErrorModal", { attrs: { errorCode: _vm.errorCode } })]
            : _vm._e(),
          _vm.showMobileJoinModal
            ? _c("JoinMeetingFromMobileModal", {
                attrs: { "room-name": _vm.getRoomName },
                on: { close: _vm.closeJoinFromMobileModal },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }