var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    [
      _c("v-list-item-avatar", [_c("v-icon", [_vm._v("mdi-file-video")])], 1),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", {
            domProps: { textContent: _vm._s(_vm.title) },
          }),
          _c("v-list-item-subtitle", {
            domProps: { textContent: _vm._s(_vm.subTitle) },
          }),
        ],
        1
      ),
      _c(
        "v-list-item-action",
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.$emit("download")
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: "grey lighten-1" } }, [
                _vm._v("feather icon-download"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }