import ENUMS from '@/constants/ENUMS';
import i18n from '@/i18n';

const { CHAT_NOTIFICATION_TYPES } = ENUMS;

export const convertChatNotification = message => {
  switch (message.body) {
    case CHAT_NOTIFICATION_TYPES.USER_SHARED_A_NEW_SURVEY:
    case CHAT_NOTIFICATION_TYPES.USER_JOINED_CONFERENCE:
    case CHAT_NOTIFICATION_TYPES.USER_LEFT_CONFERENCE:
    case CHAT_NOTIFICATION_TYPES.START_RECORDING_NOTIFICATION_BY_USER:
    case CHAT_NOTIFICATION_TYPES.STOP_RECORDING_NOTIFICATION_BY_USER:
    case CHAT_NOTIFICATION_TYPES.STOP_REMOTE_RECORDING_NOTIFICATION_BY_USER:
    case CHAT_NOTIFICATION_TYPES.USER_CHANGED_THE_CONFERENCE_PASSWORD:
    case CHAT_NOTIFICATION_TYPES.USER_REMOVED_THE_CONFERENCE_PASSWORD:
    case CHAT_NOTIFICATION_TYPES.USER_SET_THE_CONFERENCE_PASSWORD:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_LOBBY:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_LOBBY:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_TEACHER_MODE:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_TEACHER_MODE:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_FILE_SHARING:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_FILE_SHARING:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_PRIVATE_CHAT:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_PRIVATE_CHAT:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_SCREEN_SHARING:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_SCREEN_SHARING:
    case CHAT_NOTIFICATION_TYPES.USER_CHANGED_THE_WALLPAPER:
    case CHAT_NOTIFICATION_TYPES.USER_JOINED_WEBINAR:
    case CHAT_NOTIFICATION_TYPES.USER_LEFT_WEBINAR:
    case CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_E2EE:
    case CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_E2EE:
    case CHAT_NOTIFICATION_TYPES.USER_START_WEBINAR_BROADCAST:
    case CHAT_NOTIFICATION_TYPES.USER_END_WEBINAR_BROADCAST:
      return i18n.t(message.body, { displayName: message.sender });
    case CHAT_NOTIFICATION_TYPES.SURVEY_COMPLETED_BY_USER:
      return i18n.t(message.body, { displayName: message.sender, surveyName: message.surveyName });
    case CHAT_NOTIFICATION_TYPES.USER_INVITED_WEBINAR_VIEWER:
      return i18n.t(message.body, { displayName: message.sender, webinarViewerDisplayName: message.webinarViewerDisplayName });
    case CHAT_NOTIFICATION_TYPES.WEBINAR_VIEWER_JOINED_TO_CONFERENCE:
    case CHAT_NOTIFICATION_TYPES.WEBINAR_VIEWER_RETURNED_TO_WEBINAR:
      return i18n.t(message.body, { webinarViewerDisplayName: message.webinarViewerDisplayName });
    default:
      return i18n.t(message.body);
  }
};
