<template>
  <div class="user" :class="{ me: me, moderator: moderator, participant: participant }">
    <avatar :image="avatar" :display-name="displayName" style="--card-width: 200px" />
    <div :title="displayName" class="user-display-name">{{ displayName }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    displayName: {
      type: String,
    },
    avatar: {
      type: String,
    },
    me: {
      type: Boolean,
    },
    moderator: {
      type: Boolean,
    },
    participant: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.user {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: var(--default-radius);
  margin: 0 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  order: 3;
  padding: 0.5rem 0.5rem;

  .user-display-name {
    text-align: left;
    font-size: 14px;
    color: var(--v-bg-grey);
  }

  &.me {
    order: 0;
  }

  &.moderator {
    order: 1;
  }

  &.handsUp {
    order: 2;
  }
}
</style>
