var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("goToConference"),
        "confirm-button-text": _vm.$t("joinConference"),
        "cancel-button-text": _vm.$t("cancel"),
        "confirm-button-disabled": _vm.isDisabled,
        "show-actions": "",
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "mb-6 text-center" }, [
        _vm._v(_vm._s(_vm.$t("createConferenceInfoText"))),
      ]),
      _c("TextField", {
        attrs: {
          label: _vm.$t("conferenceName"),
          "error-messages": _vm.$t(_vm.conferenceNameError),
          "hide-details": "auto",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.goToConference()
          },
        },
        model: {
          value: _vm.conferenceName,
          callback: function ($$v) {
            _vm.conferenceName = $$v
          },
          expression: "conferenceName",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }