<template>
  <v-dialog
    v-model="dialog"
    :content-class="contentClass"
    v-bind:fullscreen="fullscreen"
    hide-overlay
    persistent
    no-click-animation
    style="box-shadow: 0 0 20px 6px #000000;"
  >
    <v-card class="pa-0 ma-0" style="box-shadow: 0 0 20px 6px #000000; position: relative" @mouseenter="showControls = true" @mouseleave="showControls = false">
      <div class="position: absolute; left:0; right: 0; bottom: 0; top:0;">
        <SharedVideo ref="sharedVideo" :width="width" :height="height" />
      </div>
      <transition-group name="fade">
        <template v-if="showControls">
          <div v-show="!$isMobile" key="top-overlay" class="top-overlay" :class="fullscreen ? 'top-overlay-huge' : ''"></div>
          <v-btn
            v-show="!$isMobile"
            key="left"
            v-if="position === 3 || position === 9"
            @click="moveLeft"
            small
            color="transparent"
            class="pos-button pos-button-4 pa-0"
          >
            <icon icon="arrow-left" />
          </v-btn>
          <v-btn
            v-show="!$isMobile"
            key="right"
            v-if="position === 1 || position === 7"
            @click="moveRight"
            small
            color="transparent"
            class="pos-button pos-button-6 pa-0"
          >
            <icon icon="arrow-right" />
          </v-btn>
          <v-btn
            v-show="!$isMobile"
            key="top"
            v-if="position === 3 || position === 1"
            @click="moveTop"
            small
            color="transparent"
            class="pos-button pos-button-8 pa-0"
          >
            <icon icon="arrow-left" style="transform: rotate(90deg)" />
          </v-btn>
          <v-btn
            v-show="!$isMobile"
            key="bottom"
            v-if="position === 7 || position === 9"
            @click="moveBottom"
            small
            color="transparent"
            class="pos-button pos-button-2 pa-0"
          >
            <icon icon="arrow-left" style="transform: rotate(-90deg)" />
          </v-btn>

          <v-btn key="close" v-if="showCloseButton" class="close-button" @click="close" color="transparent" small>
            <icon icon="x"></icon>
          </v-btn>

          <v-btn v-show="!$isMobile" key="fullscreen" class="fullscreen-button" color="transparent" @click="setFullscreen" small>
            <template v-if="fullscreen">
              <icon icon="minimize"></icon>
            </template>
            <template v-else>
              <icon icon="maximize"></icon>
            </template>
          </v-btn>
        </template>
      </transition-group>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SharedVideo from '@/components/SharedVideo/SharedVideo';
import Icon from '@/components/Shared/Icon';

export default {
  name: 'SharedVideoModal',
  components: { Icon, SharedVideo },
  data() {
    return {
      dialog: true,
      showControls: false,
      position: 3, // 1 bottom-left | 3 bottom-right | 9 top-right | 7 top-left - Positioned according to numpad arrangement
      fullscreen: false,
      width: '300px',
      height: '200px',
    };
  },
  computed: {
    ...mapState('Conference', ['sharedVideo']),
    ...mapGetters('Conference', ['getLocalUser', 'getUsers']),
    contentClass() {
      if (this.fullscreen) {
        return 'shared-video-modal shared-video-modal-fullscreen';
      } else {
        if (this.$isMobile) {
          return 'shared-video-modal mobile-position';
        } else {
          if (this.position === 1) {
            return 'shared-video-modal position-1';
          } else if (this.position === 3) {
            return 'shared-video-modal position-3';
          } else if (this.position === 7) {
            return 'shared-video-modal position-7';
          } else {
            return 'shared-video-modal position-9';
          }
        }
      }
    },
    showCloseButton() {
      return this.sharedVideo.from === this.getLocalUser.id || this.getLocalUser.isModerator;
    },
  },
  created() {
    this.width = this.$isMobile ? '270px' : '300px';
    this.height = this.$isMobile ? '180px' : '200px';
    this.showControls = this.$isMobile;
  },
  methods: {
    ...mapActions('Conference', ['sharedVideoUpdate']),
    close() {
      this.dialog = false;
      this.$modal?.delayedClose();
      this.$refs.sharedVideo.clearInterval();
      this.sharedVideoUpdate({ videoId: this.sharedVideo.value, status: 'stop', forceUpdate: this.getLocalUser.isModerator });
    },
    moveLeft() {
      this.position = this.position === 3 ? 1 : 7;
    },
    moveRight() {
      this.position = this.position === 1 ? 3 : 9;
    },
    moveTop() {
      this.position = this.position === 1 ? 7 : 9;
    },
    moveBottom() {
      this.position = this.position === 7 ? 1 : 3;
    },
    setFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        this.width = window.innerWidth + 'px';
        this.height = window.innerHeight + 'px';
      } else {
        this.width = '300px';
        this.height = '200px';
      }
    },
  },
  watch: {
    getUsers: {
      handler: function() {
        if (this.sharedVideo.from) {
          if (!this.getUsers.some(user => user.id === this.sharedVideo.from)) {
            // if the user that shared video left, close video for all
            this.sharedVideoUpdate({ videoId: this.sharedVideo.value, status: 'stop', forceUpdate: true });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.shared-video-modal {
  position: fixed;
  right: 10px;
  bottom: 30px;
  width: 300px;
  height: 200px;
  overflow: hidden;
  &-fullscreen {
    width: 100%;
    height: 100%;
  }

  &.mobile-position {
    width: 270px;
    height: 180px;
    left: 0;
    right: 0;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.position-1 {
    left: 10px;
    bottom: 30px;
  }
  &.position-3 {
    right: 10px;
    bottom: 30px;
  }
  &.position-7 {
    top: 50px;
    left: 10px;
  }
  &.position-9 {
    top: 50px;
    right: 10px;
  }

  .pos-button {
    position: absolute;

    &\-4 {
      left: 0;
      top: calc(50% - 10px);
    }
    &\-6 {
      right: 0;
      top: calc(50% - 10px);
    }
    &\-2 {
      bottom: 10px;
      left: calc(50% - 20px);
    }
    &\-8 {
      top: 15px;
      left: calc(50% - 20px);
    }
  }

  .fullscreen-button {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 10px;
  }

  .top-overlay {
    position: absolute;
    top: 0;
    height: 55px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    &-huge {
      height: 60px;
    }
  }
}
</style>
