import Vue from 'vue';
import XperMeetLib from 'xpermeet-lib';
import { isTablet } from '@/helpers/detect-browser';

export default {
  namespaced: true,
  state: {
    localStats: null,
    remoteStats: {},
  },
  mutations: {
    SET_LOCAL_STATS(state, payload) {
      Vue.set(state, 'localStats', payload);
    },
    SET_REMOTE_STATS(state, payload) {
      const { id, stats } = payload;
      Vue.set(state.remoteStats, id, stats);
    },
  },
  actions: {
    handleEvents({ commit }) {
      if (!isTablet()) {
        // Local User Stats Updated
        XperMeetLib.stats.on('LocalStatsUpdated', stats => {
          commit('SET_LOCAL_STATS', stats);
        });

        // Remote Users Stats Updated
        XperMeetLib.stats.on('RemoteStatsUpdated', ({ id, stats }) => {
          commit('SET_REMOTE_STATS', { id, stats });
        });
      }
    },
  },
  getters: {},
};
