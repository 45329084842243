var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", [
    _c("div", { staticClass: "hints-and-keypoints-modal" }, [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "icon-container", staticStyle: { padding: "9px" } },
          [_c("icon", { attrs: { icon: "lamp", size: "30" } })],
          1
        ),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.$t("tipsAndTricksList.1"))),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [_c("Icon", { attrs: { icon: "video-outline" } })],
          1
        ),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.$t("tipsAndTricksList.2"))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }