/* replace space with _, covert to lower case and return roomName string */
import XperMeetLib from 'xpermeet-lib';
import store from '@/store/index';
import ENUMS from '@/constants/ENUMS';

export function clearConferenceName(confName) {
  return decodeURI(confName)
    .toLowerCase()
    .replace(/ /g, '');
}

export function attachTrackToElements(users, audio = false, video = false) {
  users.forEach(user => {
    let userTracks = [];
    if (user.type === 'local') {
      userTracks = XperMeetLib.conference.localUser.tracks;
    } else {
      userTracks = XperMeetLib.conference.localUser.conferenceInstance.room.participants[user.id].getTracks();
    }

    const audioTrackCount = userTracks.filter(track => track.getType() === 'audio').length;
    const videoTrackCount = userTracks.filter(track => track.getType() === 'video').length;

    if (audioTrackCount === 0) {
      store.commit('Conference/UPDATE_USER', { userId: user.id, data: { audioMuted: true } });
    }
    if (videoTrackCount === 0) {
      store.commit('Conference/UPDATE_USER', { userId: user.id, data: { videoMuted: true } });
    }

    userTracks.forEach(track => {
      if ((audio && track.getType() === 'audio') || (video && track.getType() === 'video')) {
        const container = document.getElementById(`${user.type}-${track.getType()}-${user.id}`);
        XperMeetLib.conference.attachTrackToElement(container, user.type, user.id, track);
      }

      // Set initial mute state when remote track added
      const property = track.getType() === 'video' ? 'videoMuted' : 'audioMuted';
      store.commit('Conference/UPDATE_USER', { userId: user.id, data: { [property]: track.isMuted() } });
    });
  });
}

export function detachUserVideoTracks(userId, isLocalUser) {
  if (isLocalUser) {
    const track = XperMeetLib.conference.localUser.conferenceInstance.room.getLocalVideoTrack();
    if (track) {
      track.containers.forEach(container => {
        track.detach(container);
      });
    }
  } else {
    const participant = XperMeetLib.conference.localUser.conferenceInstance.room.getParticipantById(userId);
    if (participant) {
      const videoTracks = participant.getTracksByMediaType('video');
      videoTracks.forEach(track => {
        track.containers.forEach(container => {
          track.detach(container);
        });
      });
    }
  }
}

export function generateRoomConfig(config) {
  const { audioMuted, videoMuted, onlyModeratorControl } = config;

  const {
    AUDIO_UNMUTED,
    VIDEO_UNMUTED,
    USER_AUDIO_MUTED,
    USER_VIDEO_MUTED,
    MODERATOR_AUDIO_MUTED,
    MODERATOR_VIDEO_MUTED,
    START_MUTED_POLICY,
    START_VIDEO_MUTED_POLICY,
    ALLOW_ADMIN_START_VIDEO,
    ALLOW_SCREEN_SHARE,
    ALLOW_FILE_SHARE,
    ALLOW_PRIVATE_CHAT,
  } = ENUMS.RoomConfigEnums;

  const settings = {
    [START_MUTED_POLICY]: AUDIO_UNMUTED,
    [START_VIDEO_MUTED_POLICY]: VIDEO_UNMUTED,
  };

  if (audioMuted) {
    settings[START_MUTED_POLICY] = onlyModeratorControl ? MODERATOR_AUDIO_MUTED : USER_AUDIO_MUTED;
  }

  if (videoMuted) {
    settings[START_VIDEO_MUTED_POLICY] = onlyModeratorControl ? MODERATOR_VIDEO_MUTED : USER_VIDEO_MUTED;
  }

  settings[ALLOW_ADMIN_START_VIDEO] = Boolean(onlyModeratorControl);

  if ('allowScreenShare' in config) {
    settings[ALLOW_SCREEN_SHARE] = config.allowScreenShare;
  }

  if ('allowFileShare' in config) {
    settings[ALLOW_FILE_SHARE] = config.allowFileShare;
  }

  if ('allowPrivateChat' in config) {
    settings[ALLOW_PRIVATE_CHAT] = config.allowPrivateChat;
  }

  return settings;
}
