var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "icon-button pa-1",
          style: _vm.iconButtonStyle,
          attrs: {
            color: _vm.backgroundColor,
            fab: _vm.circle,
            "min-width": "initial",
            disabled: _vm.disabled,
            loading: _vm.loading,
            rounded: _vm.rounded,
          },
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("icon", {
        attrs: { icon: _vm.icon, color: _vm.color, size: _vm.iconSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }