<template>
  <div class="users pb-2 pl-3 pr-2">
    <div class="d-flex align-center px-4 mt-6 mb-8" v-if="isModerator">
      <span class="text-sm">{{ $t('muteUnmuteAll') }}</span>
      <v-spacer></v-spacer>

      <icon-dropdown
        v-if="showMuteButton"
        class="ml-2"
        dropdownClass="mt-2"
        :use-inner-model="!disableAudioControl"
        :list-items="muteAudioDropdownList"
        :icon-color="getAllAudioMutedState ? 'logo-color' : undefined"
        :icon="getAllAudioMutedState ? 'mic-off' : 'mic'"
      ></icon-dropdown>

      <icon-dropdown
        v-if="showMuteButton"
        class="ml-2"
        dropdownClass="mt-2"
        :use-inner-model="!disableVideoControl"
        :list-items="muteVideoDropdownList"
        :icon-color="getAllVideoMutedState ? 'logo-color' : undefined"
        :icon="getAllVideoMutedState ? 'video-off' : 'video'"
      ></icon-dropdown>

      <icon-button
        v-tooltip="$t('downloadUserList')"
        :loading="loading"
        :disabled="loading"
        icon="download"
        background-color="darkblue"
        width="28"
        icon-size="18"
        @click.stop="downloadUserList(users, 'conference')"
      ></icon-button>
    </div>

    <div class="d-flex flex-column">
      <text-field
        v-if="localUser.isModerator"
        name="search-user"
        autocomplete="off"
        backgroundColor="body"
        class="mx-2 mb-2"
        v-model="searchByUserNameText"
        :label="$t('searchUsersByUserName')"
        hide-details
      ></text-field>
      <div
        class="user"
        v-for="user in participants"
        :key="user.id"
        :class="{ me: user.id === localUser.id, moderator: user.type === 'moderator', handsUp: user.handsUp }"
      >
        <avatar iconSize="50%" :avatar-size="48" :display-name="user.displayName" :image="user.avatar" />

        <div
          class="user-display-name ml-2"
          :class="
            `user-display-name--title--${activeActionCount([
              user.handsUp,
              user.localRecording || (remoteRecordStartedBy && remoteRecordStartedBy === user.id),
              user.id !== getLocalUser.id,
            ])}`
          "
          :title="user.displayName"
        >
          {{ user.displayName }}
        </div>
        <div class="user-actions d-flex align-center justify-content-center">
          <menu-button v-if="user.handsUp" class="mr-1" width="24" color="warning" icon="hand" />
          <menu-button
            v-if="user.localRecording || (remoteRecordStartedBy && remoteRecordStartedBy === user.id)"
            class="mr-1"
            width="24"
            icon-size="16"
            color="logo-color"
            icon="recording"
            click-disabled
          />
          <menu-button
            data-test-id="user-list-mic-button"
            class="mr-1"
            width="24"
            icon-size="16"
            :color="user.audioMuted || !user.hasAudioTrack ? 'logo-color' : ''"
            :icon="user.audioMuted || !user.hasAudioTrack ? 'mic-off' : 'mic'"
            :disabled="!showMuteButton || !user.hasAudioTrack"
            @click="$emit('audioMuteClicked', user.id, !user.audioMuted)"
          />
          <menu-button
            data-test-id="user-list-video-button"
            class="mr-1"
            width="24"
            icon-size="16"
            :color="user.videoMuted || !user.hasVideoTrack ? 'logo-color' : ''"
            :icon="user.videoMuted || !user.hasVideoTrack ? 'video-off-1' : 'video-solid'"
            :disabled="!showMuteButton || !user.hasVideoTrack"
            @click="$emit('videoMuteClicked', user.id, !user.videoMuted)"
          />
          <menu-button
            data-test-id="user-list-more-button"
            v-if="user.id !== getLocalUser.id"
            icon="more-vertical"
            width="24"
            icon-size="16"
            use-inner-model
            hide-caret-down
            :dropdown-list="getMoreButtonList(user)"
          ></menu-button>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column" v-if="viewers && viewers.length">
      <div class="mt-4 mb-4 ml-n3 mr-n2" style="background-color: var(--v-body-base); height: 2px" />

      <div class="d-flex align-center px-4 mt-6 mb-4">
        <div class="text">{{ $t('viewerList') }}</div>
        <v-spacer></v-spacer>

        <icon-button
          v-tooltip="$t('downloadWebinarUserList')"
          :loading="loading"
          :disabled="loading"
          icon="download"
          background-color="darkblue"
          width="28"
          icon-size="18"
          @click.stop="downloadUserList(viewers, 'webinar')"
        ></icon-button>
      </div>
      <text-field
        v-if="localUser.isModerator"
        class="mt-1 mx-2 mb-2"
        v-model="searchByViewerNameText"
        backgroundColor="body"
        :label="$t('searchWebinarUsersByViewerName')"
        hide-details
      ></text-field>
      <div class="user" v-for="user in filteredViewers" :key="user.id" :class="{ me: user.id === localUser.id, handsUp: user.handsUp }">
        <div :title="user.displayName" class="webinar-viewer-list">{{ user.displayName }}</div>
        <div class="user-actions d-flex">
          <icon v-if="user.handsUp" class="mr-2" color="warning" size="16" icon="hand" @click="$emit('handsUpClicked', user)" />

          <confirm-popover
            v-if="webinarLocalUser.role === 'moderator'"
            confirmText="yes"
            cancelText="no"
            description="areYouSure"
            @confirm="$emit('getInClicked', user)"
          >
            <icon icon="user-plus" class="cursor-pointer" size="16"></icon>
          </confirm-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { jsonToCsv } from '@/helpers/export-csv';
import dayjs from 'dayjs';

import Avatar from '@/components/Shared/Avatar';
import ConfirmPopover from '@/components/Shared/ConfirmPopover';
import ENUMS from '@/constants/ENUMS';
import { turkishToLower } from '@/helpers/regex';

export default {
  name: 'Users',
  components: { Avatar, ConfirmPopover },
  props: {
    localUser: {
      type: Object,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      searchByUserNameText: '',
      searchByViewerNameText: '',
    };
  },
  computed: {
    ...mapState('Conference', ['roomConfig', 'roomName', 'allAudioMuted', 'allVideoMuted']),
    ...mapGetters('Conference', ['remoteRecordStartedBy', 'getLocalUser', 'remoteCameraControlSupported', 'getAllAudioMutedState', 'getAllVideoMutedState']),
    ...mapGetters('Webinar', { webinarLocalUser: 'getLocalUser', isWebinarStarted: 'isWebinarStarted' }),
    showWebinarKickButton() {
      return this.isWebinarStarted;
    },
    showMuteButton() {
      return this.remoteCameraControlSupported && this.isModerator;
    },
    disableAudioControl() {
      return !this?.roomConfig?.allowAdminStartVideo && this.allAudioMuted;
    },
    disableVideoControl() {
      return !this?.roomConfig?.allowAdminStartVideo && this.allVideoMuted;
    },
    isModerator() {
      return this.getLocalUser && this.getLocalUser.role === 'moderator';
    },
    muteAudioDropdownList() {
      return [
        {
          label: 'muteAll',
          icon: 'mic-off',
          onClick: () => this.muteAllUsersAudio(true),
        },
        {
          label: 'unmuteAll',
          icon: 'mic',
          onClick: () => this.muteAllUsersAudio(false),
        },
      ];
    },
    muteVideoDropdownList() {
      return [
        {
          label: 'muteAll',
          icon: 'video-off',
          onClick: () => this.muteAllUsersVideo(true),
        },
        {
          label: 'unmuteAll',
          icon: 'video',
          onClick: () => this.muteAllUsersVideo(false),
        },
      ];
    },
    participants() {
      return this.users.filter(u => {
        return u.role !== 'viewer' && turkishToLower(u.displayName).includes(turkishToLower(this.searchByUserNameText));
      });
    },
    filteredViewers() {
      if (this.searchByViewerNameText) {
        return this.users.filter(u => u.role === 'viewer' && turkishToLower(u.displayName).includes(turkishToLower(this.searchByViewerNameText)));
      } else {
        return this.users.filter(u => u.role === 'viewer');
      }
    },
    viewers() {
      return this.users.filter(u => u.role === 'viewer');
    },
    allowGrandAndRevokeModeratorRole() {
      return this.$customerConfig?.system?.modules?.allowGrandAndRevokeModerator;
    },
    activeActionCount() {
      return actions => {
        return actions.filter(action => Boolean(action)).length;
      };
    },
  },
  methods: {
    ...mapActions('Conference', [
      'kickParticipant',
      'grantModerator',
      'revokeModerator',
      'setAllRemoteAudioMute',
      'setAllRemoteVideoMute',
      'sendToLobby',
      'setPrivateMessageTo',
      'stopParticipantScreenShare',
    ]),
    ...mapActions('Notification', ['showToastNotification']),
    ...mapMutations('Conference', ['TOGGLE_CHAT_SCREEN']),
    muteAllUsersAudio(muteState) {
      if (!muteState && this.disableAudioControl) {
        this.showToastNotification('audioStartNotAllowed');
      } else {
        this.setAllRemoteAudioMute(muteState);
      }
    },
    muteAllUsersVideo(muteState) {
      if (!muteState && this.disableVideoControl) {
        this.showToastNotification('videoStartNotAllowed');
      } else {
        this.setAllRemoteVideoMute(muteState);
      }
    },
    getMoreButtonList(user) {
      return [
        {
          notRender: user.id === this.getLocalUser.id || user.features_jigasi,
          label: 'sendPrivateMessage',
          id: 'send-private-message-button',
          onClick: () => this.sendPrivateMessage(user),
        },
        {
          notRender:
            !(
              this.allowGrandAndRevokeModeratorRole &&
              this.getLocalUser.isModerator &&
              user.id !== this.getLocalUser.id &&
              !user.isModerator &&
              user.authenticated
            ) || user.features_jigasi,
          label: 'grantModerator',
          id: 'grant-moderator-button',
          confirmPopover: true,
          onClick: () => this.grantModerator(user.id),
        },
        {
          notRender:
            !(
              this.allowGrandAndRevokeModeratorRole &&
              this.getLocalUser.isModerator &&
              user.id !== this.getLocalUser.id &&
              user.isModerator &&
              user.authenticated
            ) || user.features_jigasi,
          label: 'revokeModerator',
          id: 'revoke-moderator-button',
          confirmPopover: true,
          onClick: () => this.revokeModerator(user.id),
        },
        {
          notRender: !this.getLocalUser.isModerator || !this.showWebinarKickButton || user.features_jigasi,
          label: 'sendUserToWebinar',
          id: 'send-user-webinar-button',
          confirmPopover: true,
          onClick: () => this.$emit('webinarKickClicked', user),
        },
        {
          notRender: !this.getLocalUser.isModerator,
          label: 'kickParticipant',
          id: 'kick-participant-button',
          confirmPopover: true,
          onClick: () => this.kickParticipant(user.id),
        },
        {
          notRender: !this.getLocalUser.isModerator || !this.roomConfig.lobbyEnabled,
          label: 'sendUserToLobby',
          id: 'send-user-to-lobby-button',
          confirmPopover: true,
          onClick: () => this.sendToLobby(user.id),
        },
        {
          notRender: !this.getLocalUser.isModerator || !user.screenSharing,
          label: 'stopScreenSharing',
          confirmPopover: true,
          onClick: () => {
            this.stopParticipantScreenShare(user.id);
          },
        },
      ];
    },
    downloadUserList(list, type) {
      this.loading = true;
      let userList = list.map(user => ({ Name: user.displayName, Role: user.role }));
      let name = type === 'conference' ? 'userList.csv' : 'webinarUserList.csv';
      let fileName = `${this.roomName}-${dayjs().format('MM_DD_YYYY_HH_mm')}-${name}`;
      jsonToCsv(userList, fileName);
      this.loading = false;
    },
    sendPrivateMessage(user) {
      if (this.roomConfig[ENUMS.RoomConfigEnums.ALLOW_PRIVATE_CHAT]) {
        this.TOGGLE_CHAT_SCREEN({ state: true });
      }
      this.setPrivateMessageTo({ id: user.id, name: user.displayName });
    },
  },
};
</script>

<style scoped lang="scss">
.users {
  overflow: auto;
  display: flex;
  flex-direction: column;

  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: var(--default-radius);
    margin: 0 0.5rem;
    font-size: 12px;
    line-height: 1.5;
    order: 3;
    padding: 0.5rem 0.5rem;

    .user-display-name {
      font-size: 14px;
      color: var(--v-bg-grey);
    }

    &.me {
      order: 0;
    }

    &.moderator {
      order: 1;
    }

    &.handsUp {
      order: 2;
    }
    .user-actions {
      .v-btn--disabled {
        opacity: 0.3;
      }
      .user-action-icon {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
