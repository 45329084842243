<template>
  <div class="room-timer" @click="$emit('open')">
    <div class="d-flex align-center">
      <icon class="mr-2" icon="clock" size="20"></icon>
      <span class="duration-time">{{ meetingDuration }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'roomTimer',
  props: {
    meetingDuration: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.room-timer {
  background-color: var(--v-primary-base);
  font-size: 14px;
  position: relative;
  top: 0;
  text-shadow: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 1rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  height: 40px;
  cursor: pointer;
  .font-14 {
    font-size: 14px;
  }
  .duration-time {
    display: inline-block;
    min-width: 60px;
    text-align: center;
  }
}
@media (max-width: $breakpoint-mobile) {
  .room-timer {
    display: none;
  }
}
</style>
