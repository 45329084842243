<template>
  <v-popover :popoverClass="popoverComputedClass" :placement="popoverPlacement">
    <slot></slot>
    <template slot="popover">
      <div class="d-flex justify-center mb-2 description" v-if="description">
        {{ $t(description) }}
      </div>
      <slot name="content"></slot>

      <div class="d-flex justify-center align-center my-4">
        <v-btn data-test-id="G-CP-CNF-B" small class="mr-2" color="secondary" v-close-popover @click="$emit('confirm')">{{ $t(confirmText) }}</v-btn>
        <v-btn data-test-id="G-CP-CNC-B" small color="error" v-close-popover @click="$emit('cancel')">{{ $t(cancelText) }}</v-btn>
      </div>
    </template>
  </v-popover>
</template>
<script>
import { VPopover } from 'v-tooltip';

export default {
  name: 'ConfirmPopover',
  components: {
    VPopover,
  },
  props: {
    confirmText: {
      type: String,
      default: 'true',
    },
    cancelText: {
      type: String,
      default: 'false',
    },
    description: {
      type: String,
    },
    popoverPlacement: {
      type: String,
      default: 'center',
    },
    popoverClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    popoverComputedClass() {
      return `confirm-popover xper-tooltip ${this.popoverClass}`;
    },
  },
};
</script>
<style lang="scss">
.confirm-popover {
  z-index: 1;
}
.confirm-popover.tooltip .popover-inner {
  background-color: var(--v-primary-base);
  box-shadow: 0 0 10px black;
  line-height: 22px;
  padding: 10px 0px;
  border-radius: var(--default-radius);
  pointer-events: initial !important;
}
@media (max-width: $breakpoint-tablet) {
  .avatar-popover {
    width: 75%;

    .tooltip-inner {
      max-width: initial !important;
    }
  }
}
</style>
