var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-upload-item d-flex align-center" },
    [
      _c("Icon", { attrs: { icon: "file", size: "30" } }),
      _c(
        "div",
        { staticClass: "file-upload-item--status ml-6" },
        [
          _c(
            "div",
            {
              staticClass:
                "file-upload-item--status--info d-flex align-center justify-space-between",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "file-upload-item--status--info--name text-body-2 ellipsis",
                },
                [_vm._v(_vm._s(_vm.fileName))]
              ),
              _c(
                "span",
                { staticClass: "file-upload-item--status--info--percent" },
                [_vm._v(_vm._s(_vm.progress) + "%")]
              ),
            ]
          ),
          _c("v-progress-linear", {
            attrs: { rounded: "", color: _vm.color, value: _vm.progress },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "file-upload-item--action d-flex align-center justify-center",
        },
        [
          _vm.hasError !== "error"
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("delete", _vm.fileName)
                    },
                  },
                },
                [_c("Icon", { attrs: { icon: "x" } })],
                1
              )
            : _c(
                "v-btn",
                { attrs: { fab: "", "x-small": "", color: "error" } },
                [_c("Icon", { attrs: { icon: "refresh" } })],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }