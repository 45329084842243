<template>
  <div class="quality-settings pa-3 pl-6">
    <div class="advanced-settings-title pa-0 mb-5">
      {{ $t('qualitySettings.title') }}
    </div>
    <div style="min-height: 120px">
      <v-slider
        v-model="selectedQuality"
        thumb-color="var(--v-logo-color-base)"
        :tick-labels="settingsLabels"
        hide-details
        :max="3"
        step="1"
        ticks="always"
        tick-size="10"
        color="var(--v-secondary-base)"
      ></v-slider>
    </div>
    <v-alert dense type="info" icon="feather icon-info">{{ $t('qualitySettings.description') }}</v-alert>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ENUMS from '@/constants/ENUMS';

export default {
  name: 'QualitySettings',
  data() {
    return {
      selectedQuality: 3,
      qualityMap: [ENUMS.VIDEO_QUALITY_LEVELS.LOW, ENUMS.VIDEO_QUALITY_LEVELS.STANDARD, ENUMS.VIDEO_QUALITY_LEVELS.HIGH, ENUMS.VIDEO_QUALITY_LEVELS.ULTRA],
    };
  },
  computed: {
    ...mapState(['theme']),
    ...mapState('Conference', ['preferredVideoQuality']),
    settingsLabels() {
      return [
        `${this.$t('qualitySettings.lowQuality')} (${ENUMS.VIDEO_QUALITY_LEVELS.LOW}p)`,
        `${this.$t('qualitySettings.standardQuality')} (${ENUMS.VIDEO_QUALITY_LEVELS.STANDARD}p)`,
        `${this.$t('qualitySettings.highQuality')} (${ENUMS.VIDEO_QUALITY_LEVELS.HIGH}p)`,
        `${this.$t('qualitySettings.ultraQuality')} (${ENUMS.VIDEO_QUALITY_LEVELS.ULTRA}p)`,
      ];
    },
  },
  created() {
    const index = this.qualityMap.indexOf(this.preferredVideoQuality);
    if (index !== -1) {
      this.selectedQuality = index;
    }
  },
  methods: {
    ...mapActions('Conference', ['setPreferredVideoQuality']),
    save() {
      this.setPreferredVideoQuality(this.qualityMap[this.selectedQuality]);
    },
    close(type) {
      this.$emit('close', type);
    },
  },
};
</script>

<style lang="scss">
.quality-settings {
  .v-slider__tick-label {
    padding-top: 8px;
    white-space: initial;
    font-size: 13px;
  }

  .advanced-settings-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    opacity: 0.9;
    color: var(--v-white-base);
  }
  .v-slider__tick {
    background: var(--v-primary-base);
    border-radius: 50%;

    &.v-slider__tick--filled {
      background: var(--v-primary-base);
      border-radius: 50%;
    }
    .v-slider__tick-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.9;
    }
  }
}
</style>
