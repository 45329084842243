<template>
  <div>
    <v-container fluid class="meet-bottom-menu" :style="{ visibility: conferenceJoined ? 'visible' : 'hidden' }">
      <v-row justify="center" align-items="center">
        <menu-button
          id="microphone-top-button"
          :dropdownList="microphoneList"
          class="mr-1"
          dropdownClass="mt-n2"
          :icon="microphoneIcon"
          :color="microphoneColor"
          :disabled="!hasMicrophone || !microphoneAllowed || (user.audioMutedByModerator && !user.isModerator)"
          show-menu-on-hover
          top
          @click="setAudioMute(!user.audioMuted)"
        />
        <menu-button
          id="camera-top-button"
          :dropdownList="cameraList"
          class="mr-1"
          dropdownClass="mt-n2"
          :icon="videoIcon"
          :color="videoColor"
          :disabled="!hasCamera || !cameraAllowed || (user.videoMutedByModerator && !user.isModerator)"
          top
          show-menu-on-hover
          @click="setVideoMute(!user.videoMuted)"
        />
        <menu-button
          id="speaker-top-button"
          :dropdownList="speakerList"
          dropdownClass="mt-n2"
          class="mr-1"
          :disabled="isSpeakerDisabled"
          :icon="speakerIcon"
          :color="speakerColor"
          top
          show-menu-on-hover
          @click="setSpeakerMute(!speakerMuted)"
        />

        <div v-if="!$isMobile" class="menu-btn-spacer mr-1"></div>
        <menu-button
          v-if="$can('handsUp', 'Meet')"
          id="hands-up-top-button"
          class="mr-1"
          icon="hand"
          :color="handsUpIconColor"
          @click="setHandsUp(!user.handsUp)"
          :tooltip-text="$t('raiseHand')"
        />
        <menu-button
          v-if="!$isMobile"
          id="desktop-share-top-button"
          class="mr-1"
          icon="monitor-1"
          :disabled="disableScreenShare"
          :color="user.screenSharing ? 'success' : ''"
          :tooltip-text="$t('screenShare')"
          @click="toggleScreenShare"
        />
        <menu-button
          v-if="showRecordingButton"
          id="recording-top-button"
          class="mr-1"
          :icon="recordingButtonIcon"
          :loading="recordLoading"
          :tooltip-text="$t('record')"
          :text="showRecordDuration"
          :width="showRecordDuration ? 120 : undefined"
          @click="setShowRecordingModal"
        />
        <menu-button
          v-if="$can('use', 'Chat')"
          id="messages-top-button"
          class="mr-1"
          icon="chat"
          :badgeContent="getUnreadMessageCount"
          :badgeWebinarContent="getWebinarUnreadMessageCount"
          :tooltip-text="$t('chat')"
          @click="TOGGLE_CHAT_SCREEN()"
        />
        <div v-if="!$isMobile" class="menu-btn-spacer mr-1"></div>
        <menu-button
          id="leave-conference-top-button"
          :dropdownList="logoutItems"
          dropdownClass="mt-n2"
          @click="leave"
          class="mr-1"
          icon="call"
          color="logo-color"
          top
          :tooltip-text="$t('leaveCall')"
          :useInnerModel="user.authenticated"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import Settings from '@/components/Settings/Modal/index';
import RecordModal from '@/components/RecordControl/Modal/RecordModal';
import ENUMS, { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';
import { isSafari, isFirefox } from '@/helpers/detect-browser';
import { msToHour } from '@/helpers/time';
import RecordListModal from '@/components/RecordControl/Modal/RecordListModal';
import XperMeetLib from 'xpermeet-lib';

export default {
  name: 'MeetBottomMenu',
  data() {
    return {
      showDrawerMenu: false,
    };
  },
  computed: {
    ...mapState('Conference', ['speakerMuted', 'recordingStatus', 'conferenceJoined', 'recordDuration', 'serverTime']),
    ...mapState('Notification', ['hideChatNotifications']),
    ...mapGetters('Conference', [
      'getLocalUser',
      'getUnreadMessageCount',
      'getIsRecording',
      'getIsRemoteRecordDisable',
      'getUsers',
      'remoteRecordStartedBy',
      'getIsRemoteRecording',
      'getRecordingDurations',
      'getIsRecordPending',
    ]),
    ...mapGetters('Devices', [
      'getMicrophones',
      'getCameras',
      'getSpeakers',
      'hasCamera',
      'hasMicrophone',
      'hasSpeaker',
      'cameraAllowed',
      'microphoneAllowed',
      'getSelectedMicrophone',
      'getSelectedSpeaker',
      'getSelectedCamera',
    ]),
    ...mapGetters('Webinar', ['webinarUrl', 'isWebinarStarted', 'isWebinarEnded', 'isWebinarRequestPending', 'getWebinarUnreadMessageCount']),
    ...mapGetters(['getLogo']),
    recordRequestIsPending() {
      return this.recordingStatus === ENUMS.GLOBAL_ENUM.RECORDING_STATUS.PENDING;
    },
    roomName() {
      return this.$route?.params?.roomName;
    },
    microphoneIcon() {
      return this.user.audioMuted || !this.hasMicrophone || !this.microphoneAllowed ? 'mic-off' : 'mic-solid';
    },
    microphoneColor() {
      return this.user.audioMuted || !this.hasMicrophone || !this.microphoneAllowed ? 'logo-color' : '';
    },
    videoIcon() {
      return this.user.videoMuted ? 'video-off-1' : 'video-solid';
    },
    videoColor() {
      return this.user.videoMuted ? 'logo-color' : '';
    },
    speakerIcon() {
      return this.speakerMuted ? 'volume-off-1' : 'volume-on-1';
    },
    speakerColor() {
      return this.speakerMuted ? 'logo-color' : '';
    },
    handsUpIconColor() {
      return this.user.handsUp ? 'var(--v-warning-base)' : '';
    },
    cameraList() {
      return [
        {
          id: 'selectCamera',
          label: this.selectedCameraLabel,
          moduleName: 'selectCamera',
          class: 'px-0',
          icon: 'video-solid',
          doNotTranslate: !!this.getSelectedCamera?.label,
          childs: [...this.getCameras].map(c => {
            return {
              label: c.label,
              active: c.label === this.selectedCameraLabel,
              onClick: () => this.changeDevice(c),
              doNotTranslate: true,
            };
          }),
          desktopOrder: 1,
          mobileOrder: 1,
        },
        {
          id: 'audioAndVideoSettings',
          label: 'audioAndVideoSettings',
          moduleName: 'audioAndVideoSettings',
          class: 'pr-0',
          onClick: () => {
            this.$showModal(Settings, { defaultPanel: '2' });
          },
          desktopOrder: 3,
          mobileOrder: 3,
        },
        {
          id: 'changeVideoStatus',
          label: this.changeVideoStatus,
          moduleName: 'changeVideoStatus',
          class: 'pr-0',
          onClick: () => {
            this.setVideoMute(!this.user.videoMuted);
          },
          desktopOrder: 3,
          mobileOrder: 3,
        },
      ];
    },
    microphoneList() {
      return [
        {
          id: 'selectMicrophone',
          label: this.selectedMicrophoneLabel,
          moduleName: 'selectMicrophone',
          icon: 'mic-solid',
          class: 'px-0',
          doNotTranslate: !!this.getSelectedMicrophone?.label,
          childs: [...this.getMicrophones].map(m => {
            return {
              active: m.label === this.selectedMicrophoneLabel,
              doNotTranslate: true,
              onClick: () => this.changeDevice(m),
              label: m.label,
            };
          }),
          desktopOrder: 1,
          mobileOrder: 1,
        },
        {
          id: 'audioAndVideoSettings',
          label: 'audioAndVideoSettings',
          moduleName: 'audioAndVideoSettings',
          class: 'pr-0',
          onClick: () => {
            this.$showModal(Settings, { defaultPanel: '2' });
          },
          desktopOrder: 2,
          mobileOrder: 2,
        },
        {
          id: 'changeMicrophoneStatus',
          label: this.changeMicrophoneStatus,
          moduleName: 'changeMicrophoneStatus',
          class: 'pr-0',
          onClick: () => {
            this.setAudioMute(!this.user.audioMuted);
          },
          desktopOrder: 3,
          mobileOrder: 3,
        },
      ];
    },
    speakerList() {
      return [
        {
          id: 'selectSpeaker',
          label: this.selectedAudioLabel,
          moduleName: 'selectSpeaker',
          class: this.getSpeakers.length === 0 ? 'hidden' : 'px-0',
          icon: 'volume-on-1',
          doNotTranslate: !!this.getSelectedSpeaker?.label,
          childs: [...this.getSpeakers].map(m => {
            return {
              label: m.label,
              doNotTranslate: true,
              active: m.label === this.selectedAudioLabel,
              onClick: () => this.changeDevice(m),
            };
          }),
          desktopOrder: 1,
          mobileOrder: 1,
        },
        {
          id: 'audioAndVideoSettings',
          label: 'audioAndVideoSettings',
          moduleName: 'audioAndVideoSettings',
          class: 'pr-0',
          onClick: () => {
            this.$showModal(Settings, { defaultPanel: '2' });
          },
          desktopOrder: 2,
          mobileOrder: 2,
        },
        {
          id: 'changeSpeakerStatus',
          label: !this.speakerMuted ? 'muteSpeaker' : 'unmuteSpeaker',
          moduleName: 'changeSpeakerStatus',
          class: 'pr-0',
          onClick: () => {
            this.setSpeakerMute(!this.speakerMuted);
          },
          desktopOrder: 3,
          mobileOrder: 3,
        },
      ];
    },
    user() {
      return this.getLocalUser || {};
    },
    showRecordingButton() {
      return this.user?.modules?.record && (this.$can('startLocal', 'Record') || this.$can('startRemote', 'Record')) && !this.$isMobile;
    },
    disableScreenShare() {
      return (this.recorderIsMe && this.getLocalUser.localRecording) || !this.$can('start', 'ScreenShare');
    },
    logoutItems() {
      let items = [
        {
          id: 'destroyRoomAndLeave',
          label: 'destroyRoomAndLeave',
          moduleName: 'destroyRoomAndLeave',
          onClick: () => {
            this.showDrawerMenu = false;
            this.TOGGLE_LOGOUT_CONFIRM_MODAL({
              type: 'destroy-room',
              message: 'doYouWantToCloseRoom',
            });
          },
          desktopOrder: 1,
          mobileOrder: 1,
        },
        {
          id: 'leaveCall',
          label: 'leaveCall',
          moduleName: 'leaveCall',
          onClick: () => {
            this.leaveConference();
          },
          desktopOrder: 2,
          mobileOrder: 2,
        },
        {
          id: 'logout',
          label: 'logout',
          moduleName: 'logout',
          onClick: () => {
            this.showDrawerMenu = false;
            this.TOGGLE_LOGOUT_CONFIRM_MODAL({
              type: 'logout',
              message: 'doYouWantToLogout',
            });
          },
          desktopOrder: 3,
          mobileOrder: 3,
        },
      ];
      if (!this.user.authenticated) {
        items = [];
      } else {
        if (this.user.isModerator) {
          items = items.filter(item => item.id !== 'leaveCall');
        } else {
          items = items.filter(item => item.id !== 'destroyRoomAndLeave');
        }
      }
      return items;
    },
    showRecordDuration() {
      if (this.user.localRecording || (this.getIsRemoteRecording && this.user.isModerator)) {
        const recorderUserId = this.user.localRecording ? this.user.id : this.remoteRecordStartedBy;
        const item = this.getRecordingDurations.find(i => i.user.id === recorderUserId);
        if (item) {
          const startedAt = new Date(item.startedAt);
          const seconds = this.serverTime - startedAt.getTime();
          return msToHour(seconds);
        }
      }
      return '';
    },
    recordingButtonIcon() {
      return this.recorderIsMe || (this.user.isModerator && this.getIsRemoteRecording) ? 'stop-circle' : 'recording';
    },
    isSpeakerDisabled() {
      return !this.hasSpeaker && !isSafari() && !isFirefox();
    },
    selectedMicrophoneLabel() {
      return this.getSelectedMicrophone?.label || 'selectAudioInputDevice';
    },
    selectedAudioLabel() {
      return this.getSelectedSpeaker?.label || 'selectAudioOutputDevice';
    },
    selectedCameraLabel() {
      return this.getSelectedCamera?.label || 'selectVideoInputDevice';
    },
    changeMicrophoneStatus() {
      return this.user.audioMuted ? 'unmuteMicrophone' : 'muteMicrophone';
    },
    changeVideoStatus() {
      return this.user.videoMuted ? 'unmuteVideo' : 'muteVideo';
    },
    recorderIsMe() {
      if (this.remoteRecordStartedBy === this.user.id && this.user.isModerator) {
        return true;
      } else if (this.user.localRecording) {
        return true;
      }
      return false;
    },
    recordLoading() {
      return this.recordRequestIsPending;
    },
  },
  mounted() {
    XperMeetLib.localRecordingManager.on(eventName => {
      if (eventName === 'stop') {
        this.$showModal(RecordListModal);
      }
    });
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_DIAL_IN_MODAL', 'TOGGLE_CHAT_SCREEN', 'TOGGLE_LOGOUT_CONFIRM_MODAL']),
    ...mapMutations(['SET_THEME', 'SET_BACKGROUND', 'SET_RADIUS']),
    ...mapActions('Conference', [
      'setAudioMute',
      'setVideoMute',
      'setSpeakerMute',
      'setHandsUp',
      'startScreenShare',
      'stopScreenShare',
      'redirectLoginPage',
      'leaveCall',
      'stopRecording',
    ]),
    ...mapActions('Devices', ['changeDevice']),
    ...mapActions('Webinar', ['startWebinarStream', 'stopWebinar']),
    ...mapActions('Notification', ['playSound']),
    toggleScreenShare() {
      if (!this.disableScreenShare) {
        if (this.user.screenSharing) {
          this.stopScreenShare();
        } else {
          this.startScreenShare();
        }
      }
    },
    setShowRecordingModal() {
      if (this.recorderIsMe || (this.user.isModerator && this.getIsRemoteRecording)) {
        const confirmModal = this.$showConfirmModal(
          {
            title: this.$t('stopRecording'),
            text: this.$t('recordingStopDesc'),
          },
          {
            confirm: () => {
              this.stopRecording();
              confirmModal.close();
            },
            cancel: () => {
              confirmModal.close();
            },
          },
        );
      } else {
        this.$showModal(RecordModal);
      }
    },
    toggleMobileFullScreen() {
      const doc = window.document;
      const docEl = doc.documentElement;
      const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    leaveConference() {
      const confirmModal = this.$showConfirmModal(
        {
          title: this.$t('leaveCall'),
          text: this.$t('doYouWantToLeaveTheRoom'),
        },
        {
          confirm: () => {
            this.leaveCall();
            confirmModal.close();
          },
          cancel: () => {
            confirmModal.close();
          },
        },
      );
    },
    leave() {
      if (this.user.authenticated) {
        return false;
      } else {
        this.leaveConference();
      }
    },
  },
  watch: {
    getUnreadMessageCount: debounce(function (newCount, oldCount) {
      if (this.getUnreadMessageCount && newCount !== oldCount && !this.hideChatNotifications) {
        this.playSound(NOTIFICATION_SOUND_TYPES.CONFERENCE_INCOMING_MESSAGE);
      }
    }, 300),
    getWebinarUnreadMessageCount: debounce(function (newCount, oldCount) {
      if (this.getWebinarUnreadMessageCount && newCount !== oldCount && !this.hideChatNotifications) {
        this.playSound(NOTIFICATION_SOUND_TYPES.WEBINAR_INCOMING_MESSAGE);
      }
    }, 300),
  },
};
</script>

<style lang="scss">
.meet-bottom-menu {
  z-index: 14;
  height: 64px;
  animation-duration: 0.3s;
  position: relative;
  width: fit-content;
  min-width: 412px;
  display: flex;
  align-items: center;

  .menu-btn-spacer {
    width: 10px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .desktop-only {
    display: none;
  }
}
</style>
