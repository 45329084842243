<template>
  <div class="conference-settings-wrapper">
    <v-col class="conference-settings pa-3 pl-6">
      <div class="advanced-settings-title pa-0">
        {{ $t('conferenceSettings') }}
      </div>

      <div v-if="!localUserIsModerator" class="mt-4 mb-n4">
        <v-alert dense type="info" icon="feather icon-info">{{ $t('doesntChangeConferenceSettings') }}</v-alert>
      </div>
      <v-row :style="{ opacity: localUserIsModerator ? '1' : '0.5' }">
        <v-col cols="12">
          <text-field
            class="modal-inputs"
            v-model="localPassword"
            v-bind:disabled="!localUserIsModerator"
            :label="$t('setConferencePassword')"
            hide-details
            autocomplete="off"
            @keydown.enter.prevent="save"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-alert dense type="info" icon="feather icon-info">{{ $t('LockRoomAlert') }}</v-alert>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="lobbySupported" class="py-0">
          <div class="d-flex align-center">
            <icon v-tooltip="$t('youCanPutParticipantsInLobby')" size="20" icon="alert-circle" class="mr-3 tooltip-icon" />
            <div class="label mr-3">{{ $t('enableLobby') }}</div>
            <v-switch class="my-0" color="success" v-bind:disabled="!localUserIsModerator" v-model="config.lobbyEnabled" :hide-details="true"></v-switch>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="localUserIsModerator" class="py-0">
          <div class="d-flex align-center">
            <icon v-tooltip="$t('followModeratorFullScreenDesc')" size="20" icon="alert-circle" class="mr-3 tooltip-icon" />
            <div class="label mr-3">{{ $t('followModeratorFullScreen') }}</div>
            <v-switch
              class="my-0"
              color="success"
              v-bind:disabled="!localUserIsModerator"
              v-model="config.followModeratorFullScreen"
              :hide-details="true"
            ></v-switch>
          </div>
        </v-col>

        <v-col cols="12">
          <StartMutedPolicySettings
            :size-prop="{ middle: '12', small: '12' }"
            :disabled="!localUserIsModerator"
            :initialData="config"
            :remoteCameraControlSupported="remoteCameraControlSupported"
            @change="onChangeStartMutedPolicy"
          />

          <v-expansion-panels v-model="expansionPanel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="head">
                  {{ $t('otherConfig') }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col>
                  <v-row class="other-configs">
                    <div class="d-flex align-center w-full justify-space-between">
                      <div class="label mr-3">{{ $t('allowFileShare') }}</div>
                      <v-switch :disabled="!localUserIsModerator" class="my-0" color="success" v-model="config.allowFileShare" :hide-details="true"></v-switch>
                    </div>
                    <div class="d-flex align-center w-full justify-space-between mt-2">
                      <div class="label mr-3">{{ $t('allowPrivateChat') }}</div>
                      <v-switch
                        :disabled="!localUserIsModerator"
                        class="my-0"
                        color="success"
                        v-model="config.allowPrivateChat"
                        :hide-details="true"
                      ></v-switch>
                    </div>
                    <div class="d-flex align-center w-full justify-space-between mt-2">
                      <div class="label mr-3">{{ $t('allowScreenShare') }}</div>
                      <v-switch
                        :disabled="!localUserIsModerator"
                        class="my-0"
                        color="success"
                        v-model="config.allowScreenShare"
                        :hide-details="true"
                      ></v-switch>
                    </div>
                    <div v-if="remoteCameraControlSupported" class="d-flex align-center w-full justify-space-between mt-2">
                      <div class="label mr-3">{{ $t('allowAdminVideoStart') }}</div>
                      <v-switch
                        :disabled="!localUserIsModerator"
                        class="my-0"
                        color="success"
                        v-model="config.allowAdminStartVideo"
                        :hide-details="true"
                      ></v-switch>
                    </div>
                  </v-row>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import StartMutedPolicySettings from '@/components/Settings/StartMutedPolicySettings';
import Icon from '@/components/Shared/Icon';
import ENUMS from '@/constants/ENUMS';

const {
  START_MUTED_POLICY,
  START_VIDEO_MUTED_POLICY,
  AUDIO_UNMUTED,
  VIDEO_UNMUTED,
  ALLOW_FILE_SHARE,
  ALLOW_PRIVATE_CHAT,
  ALLOW_SCREEN_SHARE,
  ALLOW_ADMIN_START_VIDEO,
  LOBBY_ENABLED,
  FOLLOW_MODERATOR_FULL_SCREEN,
} = ENUMS.RoomConfigEnums;

const { CHAT_NOTIFICATION_TYPES } = ENUMS;

export default {
  name: 'ConferenceSettings',
  components: {
    StartMutedPolicySettings,
    Icon,
  },
  data() {
    return {
      ENUMS,
      localPassword: '',
      config: {
        [START_MUTED_POLICY]: AUDIO_UNMUTED,
        [START_VIDEO_MUTED_POLICY]: VIDEO_UNMUTED,
        [ALLOW_FILE_SHARE]: true,
        [ALLOW_PRIVATE_CHAT]: true,
        [ALLOW_SCREEN_SHARE]: true,
        [ALLOW_ADMIN_START_VIDEO]: false,
        [LOBBY_ENABLED]: false,
        [FOLLOW_MODERATOR_FULL_SCREEN]: false,
      },
      showPassword: false,
      expansionPanel: [0],
    };
  },
  computed: {
    ...mapState(['theme']),
    ...mapState('Conference', ['showConferenceSettingsModal', 'password', 'roomConfig', 'activeRightMenuTab']),
    ...mapGetters('Conference', ['getLocalUser', 'getUsers']),
    localUserIsModerator() {
      return this.getLocalUser?.isModerator;
    },
    lobbySupported() {
      return this.$customerConfig?.system?.modules?.lobby && this.$can('enableLobby', 'Meet');
    },
    remoteCameraControlSupported() {
      return this.$customerConfig?.system?.admin?.modules?.remoteCameraControl;
    },
    anyAnotherUser() {
      return this.getUsers.length > 1;
    },
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_CHAT_SCREEN']),
    ...mapActions('Conference', ['lock', 'setRoomConfig', 'sendChatNotification']),
    ...mapActions('Lobby', ['enableLobby']),
    save() {
      this.sendConfigNotifications();
      this.lockRoom();
      this.enableLobby(this.config[LOBBY_ENABLED]);
      this.setRoomConfig(this.config);
      this.$set(this, 'config', { ...this.roomConfig });
    },
    lockRoom() {
      if (this.localPassword !== this.password) {
        this.lock(this.localPassword);
      }
    },
    sendConfigNotifications() {
      if (!this.anyAnotherUser) {
        return;
      }

      this.sendPasswordNotification();
      this.sendLobbyNotification();
      this.sendFileShareNotification();
      this.sendPrivateChatNotification();
      this.sendScreenShareNotification();
    },
    sendPasswordNotification() {
      if (this.localPassword !== this.password) {
        let notificationType;

        if (!this.localPassword) {
          notificationType = CHAT_NOTIFICATION_TYPES.USER_REMOVED_THE_CONFERENCE_PASSWORD;
        } else if (!this.password) {
          notificationType = CHAT_NOTIFICATION_TYPES.USER_SET_THE_CONFERENCE_PASSWORD;
        } else {
          notificationType = CHAT_NOTIFICATION_TYPES.USER_CHANGED_THE_CONFERENCE_PASSWORD;
        }

        this.sendChatNotification({ type: notificationType });
      }
    },
    sendLobbyNotification() {
      if (this.config[LOBBY_ENABLED] !== this.roomConfig[LOBBY_ENABLED]) {
        if (this.config[LOBBY_ENABLED]) {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_LOBBY });
        } else {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_LOBBY });
          if (this.activeRightMenuTab === 'lobby') {
            this.TOGGLE_CHAT_SCREEN({ close: true });
          }
        }
      }
    },
    sendFileShareNotification() {
      if (this.config?.allowFileShare !== this.roomConfig?.allowFileShare) {
        if (this.config?.allowFileShare) {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_FILE_SHARING });
        } else {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_FILE_SHARING });
        }
      }
    },
    sendPrivateChatNotification() {
      if (this.config?.allowPrivateChat !== this.roomConfig?.allowPrivateChat) {
        if (this.config?.allowPrivateChat) {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_PRIVATE_CHAT });
        } else {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_PRIVATE_CHAT });
        }
      }
    },
    sendScreenShareNotification() {
      if (this.config?.allowScreenShare !== this.roomConfig?.allowScreenShare) {
        if (this.config?.allowScreenShare) {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_ACTIVATED_THE_SCREEN_SHARING });
        } else {
          this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_DEACTIVATED_THE_SCREEN_SHARING });
        }
      }
    },
    close(type) {
      this.$emit('close', type);
    },
    onChangeStartMutedPolicy(startMutedPolicySettings) {
      this.config = {
        ...this.config,
        ...startMutedPolicySettings,
      };
    },
  },
  watch: {
    roomConfig: {
      deep: true,
      immediate: true,
      handler: function () {
        this.$set(this, 'config', { ...this.roomConfig });
      },
    },
    password: {
      handler: function () {
        this.localPassword = this.password;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.conference-settings-wrapper {
  .conference-settings {
    margin: auto;
    overflow-x: auto;

    .advanced-settings-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0px;
      opacity: 0.9;
      color: var(--v-white-base);
    }
  }
  .tooltip-icon {
    cursor: pointer;
  }
  .start-muted-policy-settings {
    ::v-deep .v-input.theme--dark.v-input--selection-controls.v-input--checkbox {
      .v-input__control {
        .v-input__slot {
          margin-left: 20px;
        }
      }
    }
  }
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.7;
    color: var(--v-white-base);
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
  ::v-deep .muted-policy-checkbox {
    .v-input__control {
      .v-input__slot {
        margin-left: 0px !important;
      }
    }
  }
  .actions {
    width: 100%;
  }
}
@media (max-width: $breakpoint-tablet) {
  .conference-settings-wrapper {
    .actions {
      width: 95%;
    }
  }
}
</style>
