import { render, staticRenderFns } from "./NotificationDefault.vue?vue&type=template&id=679740ed"
import script from "./NotificationDefault.vue?vue&type=script&lang=js"
export * from "./NotificationDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/xpermeet-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('679740ed')) {
      api.createRecord('679740ed', component.options)
    } else {
      api.reload('679740ed', component.options)
    }
    module.hot.accept("./NotificationDefault.vue?vue&type=template&id=679740ed", function () {
      api.rerender('679740ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Notifications/RoomNotifications/NotificationDefault.vue"
export default component.exports