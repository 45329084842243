import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import tr from 'vuetify/lib/locale/tr';
import en from 'vuetify/lib/locale/en';
import es from 'vuetify/lib/locale/es';
import ru from 'vuetify/lib/locale/ru';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const vuetify = new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#33364A',
        secondary: '#7484FF',
        success: '#91E499',
        body: '#454C66',
        date: '#6E7486',
        blackrussian: '#181A27',
        darkblue: '#171E2F',
        'night-blue': '#222a47',
        'bar-blue': '#273669',
        whisper: '#EEEEEE',
        error: '#FF5A6E',
        warning: '#FFDC77',
        'popup-color': '#1A2133',
        'popup-main': '#4E5EAF',
        link: '#2496ff',
        'modal-overlay': '#0C101B',
        purple: '#5D6EF4',
        line: '#AFB2C6',
        white: '#FFFFFF',
        silver: '#AFB2C6',
        gray: '#C8C8C8',
        'light-gray': '#C4C4C4',
        'logo-color': '#F95E59',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      expand: 'feather icon-chevron-down',
      dropdown: 'feather icon-chevron-down',
    },
  },
  breakpoint: {
    mobileBreakpoint: 600,
    thresholds: {
      sm: 800,
    },
  },
  lang: {
    locales: {
      tr,
      en,
      es,
      ru,
    },
  },
});
export default vuetify;
