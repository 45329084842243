<template>
  <div class="jibri">
    <div class="jibri-overlay" v-if="!isYoutube" :style="{ backgroundImage: backgroundImage }"></div>
    <div class="meet-container" id="meet-container" ref="video-dynamic-layout">
      <v-container fluid class="meet-wrapper">
        <div class="meet-page" :data-layout-type="activeLayoutType">
          <template v-if="activeLayoutType === 'grid'">
            <div class="users-container">
              <VideoGridlayout :layout="activeLayout" :users="getUsers" :screenSize="screenSize" @fullscreen="onFullScreenClicked" />
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-center align-center flex-wrap">
              <video-card
                v-for="user in getUsers"
                :key="user.id"
                :user="user"
                :avatar-size="24"
                :participant-count="participantCount"
                :fullScreen="participantCount === 1 || user.fullScreen"
                :hidden="getFullScreenUserId && getFullScreenUserId !== user.id"
                @dblclick.native="onFullScreenClicked(user)"
              />
            </div>
          </template>
        </div>
      </v-container>
    </div>

    <UsersAudioContainer />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import VideoCard from '@/components/VideoCard/VideoCard';

import { calculateVideoLayout } from '@/helpers/video';
import { mobileAndTabletCheck } from '@/helpers/detect-browser';
import VideoGridlayout from '@/components/Meet/VideoGridlayout';
import ENUMS from '@/constants/ENUMS';
import { attachTrackToElements } from '@/helpers/conference';
import UsersAudioContainer from '@/components/Meet/UsersAudioContainer';

export default {
  name: 'Jibri',
  components: {
    UsersAudioContainer,
    VideoGridlayout,
    VideoCard,
  },
  data() {
    return {
      screenSize: {
        screenWidth: 0,
        screenHeight: 0,
      },
      cardSize: {
        width: 0,
        height: 0,
      },
      showLockConferenceModal: false,
      showUnlockConferenceModal: false,
    };
  },
  computed: {
    ...mapState('Conference', [
      'waitingForTheHost',
      'showConferencePasswordModal',
      'showChatScreen',
      'showProfileModal',
      'showLocaleSettingsModal',
      'showConferenceSettingsModal',
      'locked',
      'clearQueryParams',
      'dominantUserId',
      'layoutType',
      'roomConfig',
    ]),
    ...mapState('Webinar', ['streamChannel']),
    ...mapGetters('Conference', [
      'getLocalUser',
      'getFullScreenUserId',
      'getUserById',
      'getScreenSharingUserList',
      'getUsers',
      'getBackground',
      'getSharedProperty',
    ]),
    backgroundImage() {
      let background = this.getBackground;
      let url = null;
      if (!background) {
        return '';
      } else if (background.startsWith('data:') || background.startsWith('http:') || background.startsWith('https:') || background.startsWith('/')) {
        url = `url("${background}")`;
      } else {
        url = '';
      }

      return url;
    },
    isYoutube() {
      return this.streamChannel === ENUMS.STREAM_CHANNELS.YOUTUBE;
    },
    showBranding() {
      return this.$route.hash.includes('branding=true');
    },
    participantCount() {
      return this.getUsers?.length || 0;
    },
    roomName() {
      return this.$route.params.roomName;
    },
    getPassword() {
      const { p } = this.$route.query;
      return p ? atob(p) : undefined;
    },
    activeLayout() {
      return this.roomConfig?.layout ? JSON.parse(JSON.stringify(this.roomConfig.layout)) : null;
    },
    activeLayoutType() {
      return this.isMobile ? 'dynamic' : this.getSharedProperty('jibriLayoutType') || this.layoutType;
    },
    isMobile() {
      return mobileAndTabletCheck();
    },
  },
  created() {
    this.initXperMeet(this.getConfig());
  },
  mounted() {
    this.setScreenSize();
    this.setBrandingStyle();
    this.removeGDPR();
  },
  methods: {
    ...mapActions('Conference', ['initXperMeet', 'setSpeakerMute', 'attachTrackToElement', 'setFullScreen', 'setReceiverConstraints']),
    ...mapMutations('Conference', ['TOGGLE_CHAT_SCREEN', 'SET_CLEAR_QUERY_PARAMS', 'TOGGLE_LOGOUT_CONFIRM_MODAL', 'TOGGLE_PROFILE_MODAL']),
    removeGDPR() {
      if (document.querySelector('.gdpr-section')) {
        document.querySelector('.gdpr-section').remove();
      }
    },
    setBrandingStyle() {
      const logoElem = document.getElementsByClassName('logo');
      if (logoElem && logoElem[0] && logoElem[0].style) {
        if (this.showBranding) {
          logoElem[0].style.zIndex = 100;
          logoElem[0].style.left = 'auto';
          logoElem[0].style.right = '20px';
        } else {
          logoElem[0].style.removeProperty('z-index');
          logoElem[0].style.removeProperty('left');
          logoElem[0].style.removeProperty('right');
        }
      }
    },
    getConfig() {
      const { p, s } = this.$route.query;
      const password = p ? atob(p) : undefined;
      return { roomName: this.roomName, password, settings: s, startMutedPolicyConfig: {} };
    },
    onFullScreenClicked(user) {
      if (user) {
        this.setFullScreen({
          userId: user.id,
          fullScreen: true,
        });
      } else {
        this.setFullScreen({
          userId: null,
          fullScreen: false,
        });
      }
    },
    setScreenSize() {
      const x = this.showChatScreen ? 450 : 0;
      const w = window.innerWidth - x;
      const h = window.innerHeight;
      const r = v => (v * 10) / 100;

      const { width, height } = calculateVideoLayout(this.participantCount, {
        screenWidth: Math.floor(w - r(w)),
        screenHeight: Math.floor(h - r(h)),
      });

      const layoutElement = this.$refs['video-dynamic-layout'];
      if (layoutElement) {
        layoutElement.style.setProperty('--card-width', width + 'px');
        layoutElement.style.setProperty('--card-height', height + 'px');
      }
    },
    selectParticipant(userId) {
      const constraints = {
        selectedEndpoints: [],
        onStageEndpoints: [],
        defaultConstraints: { maxHeight: 480 },
        constraints: {},
      };
      if (userId) {
        constraints.selectedEndpoints.push(userId);
        constraints.onStageEndpoints.push(userId);
        constraints.constraints[userId] = { maxHeight: 1080 };
      }
      this.setReceiverConstraints(constraints);
    },
  },
  watch: {
    showBranding: {
      handler: function() {
        this.setBrandingStyle();
      },
      immediate: true,
    },
    participantCount: {
      handler: function() {
        this.setScreenSize();

        if (this.participantCount === 1) {
          this.selectParticipant(this.getFullScreenUserId);
          this.onFullScreenClicked(this.getUsers[0]);
        } else if (this.getScreenSharingUserList.length === 0) {
          this.onFullScreenClicked();
        }
      },
      immediate: true,
    },
    showChatScreen() {
      this.setScreenSize();
    },
    roomName() {
      this.initXperMeet(this.getConfig());
    },
    dominantUserId() {
      if (this.dominantUserId && !this.getFullScreenUserId) {
        this.selectParticipant(this.dominantUserId);
      }
    },
    getFullScreenUserId() {
      if (this.getFullScreenUserId) {
        this.selectParticipant(this.getFullScreenUserId);
      } else {
        this.selectParticipant();
      }
    },
    getScreenSharingUserList: {
      handler: function() {
        const user = this.getScreenSharingUserList[this.getScreenSharingUserList.length - 1];
        if (user) {
          this.selectParticipant(user.userId);
          this.onFullScreenClicked(this.getUserById(user.userId));
        } else {
          this.onFullScreenClicked();
        }
      },
      immediate: true,
    },
    activeLayoutType() {
      // created hook'da yapılmamalı
      // layoutType değiştirildiğinde video ve audio tracklar tekrardan oluşturulmalı
      this.$nextTick().then(() => {
        attachTrackToElements(this.getUsers, false, true);
        this.setSpeakerMute(this.speakerMuted);
      });
    },
  },
};
</script>

<style lang="scss">
.jibri {
  width: 100%;
  height: 100%;
  display: flex;

  .jibri-overlay {
    position: fixed;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-position: center !important;
    background-size: cover !important;
  }

  .meet-container {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;

    .meet-wrapper {
      padding: 0px;
      position: relative;
    }

    .meet-page {
      height: 100%;
      display: flex;
      align-items: center;

      .meet {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
      }

      &[data-layout-type='grid'] {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;

        .meet {
          height: 100%;

          .users-container {
            width: 100%;
            height: 100%;
            display: flex;
          }
        }
      }
    }

    .room-information-box {
      position: fixed;
      right: 20px;
      top: 8px;
      display: flex;
      z-index: 2;
      align-items: center;

      .room-name {
        font-size: 12px;

        position: relative;
        top: 0;
        height: 40px;
        text-shadow: none;
        text-align: left;
        border-radius: 4px;
        display: inline-block;
        padding: 0.1rem 1rem;
        margin: 0 0rem;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        max-width: 200px;
        cursor: pointer;
      }

      .room-lock {
        z-index: 10;
        padding: 4px 0px;
        button {
          transition: var(--default-transition);
          border-radius: var(--default-button-radius);
        }
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .meet-container {
      .meet-page {
        height: 100%;
      }

      .room-information-box {
        top: unset;
        bottom: 0.5rem;
        left: 0.5rem;
        right: 0.5rem;

        .room-name {
          max-width: initial;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
