<template>
  <Modal
    :title="$t(title)"
    :confirm-button-text="$t(confirmText)"
    :cancel-button-text="$t('cancel')"
    :confirm-button-disabled="!password && !locked"
    show-actions
    @close="onModalClose"
  >
    <div v-if="locked" class="text-center">
      <span>{{ $t('conferenceIsGoingToBeUnlocked') }}</span>
    </div>
    <div class="pt-2 pb-2" v-else>
      <div class="text-center mb-6 mt-">
        <span>{{ $t('lockRoomWarning') }}</span>
      </div>
      <form autocomplete="off" onsubmit="return false;">
        <TextField
          autocomplete="conference-password"
          :type="showPassword ? 'text' : 'password'"
          name="conference-password"
          v-model="password"
          :label="$t('setConferencePassword')"
          required
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          aria-required="true"
          :error-messages="errorMessages"
          :hide-details="!errorMessages.length"
          @click:append="showPassword = !showPassword"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ENUMS from '@/constants/ENUMS';

const { CHAT_NOTIFICATION_TYPES } = ENUMS;

export default {
  name: 'LockConferenceModal',
  data() {
    return {
      password: '',
      errorMessages: [],
      showPassword: false,
    };
  },
  computed: {
    ...mapState('Conference', { locked: 'locked', conferencePassword: 'password' }),
    ...mapGetters('Conference', ['getUsers']),
    confirmText() {
      return this.locked ? 'confirm' : 'lockRoom';
    },
    title() {
      return this.locked ? 'areYouSure' : 'conferencePassword';
    },
    anyAnotherUser() {
      return this.getUsers.length > 1;
    },
  },
  methods: {
    ...mapActions('Conference', ['lock', 'unlock', 'sendChatNotification']),
    ...mapActions('Notification', ['showToastNotification']),
    onModalClose(type) {
      if (type !== 'confirm') {
        this.$emit('close');
        return;
      }
      if (this.$can('lock', 'Meet')) {
        if (this.locked) {
          this.unlock();
          if (this.anyAnotherUser) {
            this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_REMOVED_THE_CONFERENCE_PASSWORD });
          }
        } else {
          if (this.anyAnotherUser) {
            if (!this.conferencePassword) {
              this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_SET_THE_CONFERENCE_PASSWORD });
            } else {
              this.sendChatNotification({ type: CHAT_NOTIFICATION_TYPES.USER_CHANGED_THE_CONFERENCE_PASSWORD });
            }
          }
          this.lock(this.password);
        }
      } else {
        this.showToastNotification('onlyModeratorsCanUseThisFunctionality');
      }
    },
  },
};
</script>
