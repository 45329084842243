var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TextField", {
    staticClass: "white--text",
    attrs: { readonly: "", "hide-details": "", value: _vm.text, dense: "" },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.copyText,
                    expression: "copyText",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onCopy,
                    expression: "onCopy",
                    arg: "success",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:error",
                    value: _vm.onError,
                    expression: "onError",
                    arg: "error",
                  },
                ],
                staticClass: "cursor-pointer",
              },
              [
                _c("icon", {
                  staticClass: "mt-1",
                  attrs: { size: "24", icon: _vm.copyIcon },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }