var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 mb-2 d-flex justify-end" },
    [
      _c("TextField", {
        attrs: {
          readonly: "",
          "hide-details": "",
          value: _vm.toast.body,
          dense: "",
          height: "34",
          "background-color": "rgba(116, 132, 255, 0.2)",
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.toast.body,
                        expression: "toast.body",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error",
                      },
                    ],
                    staticClass: "toast-copy-icon cursor-pointer",
                  },
                  [
                    _c("icon", {
                      staticClass: "mt-1",
                      attrs: { size: "24", icon: "copy" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }