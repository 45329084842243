var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shared-file-list py-3" },
    [
      _c(
        "div",
        { staticClass: "px-3" },
        [
          _vm.showWebinarChatTab && !_vm.isWebinar
            ? _c("Tab", {
                attrs: { tabItems: _vm.tabItems, sharedWith: _vm.sharedWith },
                on: { onTabClick: _vm.onTabClick },
              })
            : _vm._e(),
          !_vm.isWebinar
            ? _c(
                "v-tabs",
                {
                  staticClass: "file-share-tab",
                  model: {
                    value: _vm.sharedFilesTabs,
                    callback: function ($$v) {
                      _vm.sharedFilesTabs = $$v
                    },
                    expression: "sharedFilesTabs",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "50%" },
                      attrs: { "data-test-id": "incoming-shared-file-tab" },
                    },
                    [_vm._v(_vm._s(_vm.$t("incomingFiles")))]
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "50%" },
                      attrs: { "data-test-id": "sent-shared-file-tab" },
                    },
                    [_vm._v(_vm._s(_vm.$t("sentFiles")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "v-tab-content",
          class: { webinar: _vm.isWebinar },
          model: {
            value: _vm.sharedFilesTabs,
            callback: function ($$v) {
              _vm.sharedFilesTabs = $$v
            },
            expression: "sharedFilesTabs",
          },
        },
        [
          _c(
            "v-tab-item",
            { staticClass: "tab-item-content px-4" },
            _vm._l(_vm.sharedFiles, function (file) {
              return _c(
                "div",
                { key: file.name, staticClass: "tab-item-file my-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "shared-file-list--item d-flex align-center",
                    },
                    [
                      _c("icon", { attrs: { icon: "file", size: "30" } }),
                      _c(
                        "div",
                        { staticClass: "ml-4 shared-file-list--item--content" },
                        [
                          _c("div", { staticClass: "text-body-2 d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "ellipsis shared-file-name" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFileNameWithoutExt(file.fileName)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "shared-file-extension" },
                              [
                                _vm._v(
                                  "." +
                                    _vm._s(_vm.getFileExtension(file.fileName))
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "shared-file-list--item--date mr-2",
                              },
                              [_vm._v(_vm._s(_vm.formatDate(file.date)))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "shared-file-list--item--file-name ellipsis",
                              },
                              [_vm._v(_vm._s(file.user))]
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      !_vm.fileStatus[file.fileKey]
                        ? _c("icon", {
                            attrs: { icon: "download", role: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.download(file)
                              },
                            },
                          })
                        : _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                value: _vm.fileStatus[file.fileKey],
                                color: "secondary",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "9px" } },
                                [
                                  _vm._v(
                                    "%" + _vm._s(_vm.fileStatus[file.fileKey])
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "v-tab-item",
            { staticClass: "tab-item-content px-4" },
            _vm._l(_vm.myFiles, function (file) {
              return _c(
                "div",
                { key: file.name, staticClass: "tab-item-file my-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "shared-file-list--item d-flex align-center",
                    },
                    [
                      _c("icon", { attrs: { icon: "file", size: "30" } }),
                      _c(
                        "div",
                        { staticClass: "ml-4 shared-file-list--item--content" },
                        [
                          _c("div", { staticClass: "text-body-2 d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "ellipsis shared-file-name" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFileNameWithoutExt(file.fileName)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "shared-file-extension" },
                              [
                                _vm._v(
                                  "." +
                                    _vm._s(_vm.getFileExtension(file.fileName))
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "shared-file-list--item--date mr-2",
                              },
                              [_vm._v(_vm._s(_vm.formatDate(file.date)))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "shared-file-list--item--file-name ellipsis",
                              },
                              [_vm._v(_vm._s(file.user))]
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      !_vm.fileStatus[file.fileKey]
                        ? _c("icon", {
                            attrs: { icon: "download", role: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.download(file)
                              },
                            },
                          })
                        : _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                value: _vm.fileStatus[file.fileKey],
                                color: "secondary",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "9px" } },
                                [
                                  _vm._v(
                                    "%" + _vm._s(_vm.fileStatus[file.fileKey])
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }