import { isMobile } from '@/helpers/os';

export default {
  computed: {
    $isMobile() {
      if (isMobile()) {
        return true;
      }
      return this.$vuetify.breakpoint.mobile;
    },
    $isTablet() {
      if (isMobile()) {
        return true;
      }
      return this.$vuetify.breakpoint.sm;
    },
  },
};
