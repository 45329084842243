<template>
  <JoinPrivateRoom :room-name="roomName" />
</template>

<script>
import JoinPrivateRoom from '@/components/Meet/JoinPrivateRoom.vue';

export default {
  name: 'CreatePrivateRoom',
  components: {
    JoinPrivateRoom,
  },
  computed: {
    roomName() {
      return this.$route.params.roomName;
    },
  },
};
</script>

<style>
</style>
