<template>
  <div class="question mt-6" :class="{ edit: isEditMode }">
    <Textarea
      hide-details
      background-color="primary"
      :disabled="disabled"
      :label="$t('question')"
      v-model="question.Questions"
      @keyup="controlQuestionsName"
    ></Textarea>
    <span v-if="maxCharError" class="max-char-warning">{{ $t('maxChar', { char: '250' }) }}</span>

    <div class="d-flex align-center mt-4" v-for="(option, index) in optionList" :key="index">
      <TextField v-model="option.Choice" :label="`${$t('option')} ${index + 1}`" hide-details />
      <Icon icon="trash" class="ml-2 cursor-pointer" @click="addToDeletedOptions(index, option.Id)" size="20" color="error" />
    </div>

    <div class="d-flex align-center justify-space-between mt-6">
      <v-checkbox
        v-model="question.HasMultichoices"
        color="white"
        :disabled="disabled"
        :label="$t('allowMultiChoice')"
        style="margin: 0; background: transparent"
        class="select-multiple-choice"
        hide-details
        dense
      ></v-checkbox>
      <div @click="addOption" role="button" class="text-body-2 d-flex align-center">
        <icon icon="plus-circle" class="mr-2" size="20" />
        {{ $t('addOption') }}
      </div>
    </div>
    <v-row class="mt-7">
      <v-col sm="12" md="6">
        <v-btn block outlined @click="cancel">{{ $t('cancel') }}</v-btn>
      </v-col>
      <v-col sm="12" md="6">
        <v-btn block color="secondary" @click="save" :disabled="!survey.Title" :loading="loading">{{ $t('save') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { consoleError } from '@/helpers/logger';
import Icon from '@/components/Shared/Icon';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'SurveyQuestionCreateNUpdate',
  components: {
    Icon,
  },
  props: {
    index: Number,
    survey: Object,
    editModel: Object,
  },
  data() {
    return {
      loading: false,
      selected: [],
      options: [],
      deletedOptionIds: [],
      maxCharError: false,
      question: {
        HasMultichoices: false,
        Questions: '',
      },
    };
  },
  computed: {
    questionIndex() {
      return this.index + 1;
    },
    disabled() {
      return this.loading;
    },
    isEditMode() {
      return Boolean(this.question.id);
    },
    optionList() {
      return this.options.filter(o => !this.deletedOptionIds.includes(o.Id));
    },
  },
  methods: {
    ...mapActions('Survey', ['getQuestionOptions', 'deleteQuestionOption', 'addQuestion', 'updateQuestion', 'addQuestionOption', 'updateOption']),
    ...mapActions('Notification', ['showToastNotification']),
    addToDeletedOptions(index, id) {
      if (!id) {
        const options = [...this.options];
        options.splice(index, 1);
        this.options = options;
      } else {
        this.deletedOptionIds.push(id);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    getOptions() {
      if (!this.question?.Id) {
        return null;
      }

      const payload = {
        SurveyQuestionsId: this.question.Id,
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 100,
          },
        },
      };

      this.getQuestionOptions(payload)
        .then(response => {
          this.options = response.data.Result.List;
        })
        .catch(err => {
          consoleError(err);
        });
    },
    deleteQuestion() {
      this.$emit('deleteQuestion', {
        id: this.question.Id,
        index: this.index,
      });
    },
    addOption() {
      if (!this.options.find(o => !o.Choice)) {
        this.options.push({
          Choice: '',
          IsDeleted: false,
        });
      }
    },
    async save() {
      if (this.optionList.length < 2) {
        this.showToastNotification({ body: 'youNeedAtLeast2Options', config: { type: TOAST_TYPE.WARNING } });
        return;
      }

      try {
        this.loading = true;
        const questionPayload = {
          ...this.question,
          Survey: { Id: this.survey.Id },
        };
        const questionMethod = questionPayload.Id ? 'updateQuestion' : 'addQuestion';
        const questionResponse = await this[questionMethod](questionPayload);
        const questionId = questionResponse.data.Result.Id;

        const optionRequests = this.options.map(o =>
          this[o.Id ? 'updateOption' : 'addQuestionOption']({
            SurveyQuestions: {
              Id: questionId,
            },
            Id: o.Id,
            Choice: o.Choice,
            IsDeleted: false,
          }),
        );

        this.deletedOptionIds.forEach(id => {
          optionRequests.push(this.deleteQuestionOption(id));
        });

        await Promise.all(optionRequests);
        this.resetData();
        this.loading = false;
        this.$emit('onSaveQuestion');
      } catch (err) {
        this.loading = false;
        consoleError('ERR', err);
      }
    },
    controlQuestionsName() {
      if (this.question?.Questions?.length > 249) {
        this.maxCharError = true;
        this.question.Questions = this.question.Questions.substr(0, 249);
      }
    },
    resetData() {
      this.question = {
        HasMultichoices: false,
        Questions: '',
      };
      this.options = [];
      this.deletedOptionIds = [];
    },
  },
  watch: {
    editModel: {
      immediate: true,
      deep: true,
      handler() {
        if (this.editModel) {
          this.question = this.editModel;
          this.getOptions();
        } else {
          this.options = [];
          this.question = {
            HasMultichoices: false,
            Questions: '',
          };
        }
      },
    },
  },
};
</script>

<style lang="scss">
.question {
  .v-input--checkbox {
    .v-label {
      font-size: 10px;
    }
    &.select-multiple-choice {
      font-size: 14px;
    }
  }
}
</style>
