import storage from '@/services/storage';

export function getCurrentLanguage() {
  const local = storage.getItem('lang');

  if (local) return local;

  const browserLang = navigator.userLanguage || navigator.language;

  if (browserLang) {
    return browserLang.split('-')[0];
  }

  return 'en';
}
