import JitsiMeetJS from "../JitsiMeetLib";
import EventEmitter from "../utils/EventEmitter";
const eventEmitter = new EventEmitter();

class MediaDevices {
  constructor() {
    this.enumerateDevices = JitsiMeetJS.mediaDevices.enumerateDevices;

    this.audioInputDeviceId = null;
    this.audioOutputDeviceId = null;
    this.videoInputDeviceId = null;

    this.handleEvents();
  }

  init() {}

  handleEvents() {
    JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, (browser) => {
      eventEmitter.emit("PermissionPromptIsShown", browser);
    });
    JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, (devices) => {
      eventEmitter.emit("DeviceListChanged", devices);
    });
  }

  setDefaultDevices(defaultDevices) {
    const { audioInput, audioOutput, videoInput } = defaultDevices;
    this.audioInputDeviceId = audioInput;
    this.audioOutputDeviceId = audioOutput;
    this.videoInputDeviceId = videoInput;
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }
  off(eventType, callbackFn) {
    eventEmitter.off(eventType, callbackFn);
  }

  clearEvents() {
    eventEmitter.clear();
  }
}

export default new MediaDevices();
