var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file" },
    [
      _c("icon", { attrs: { icon: "file", size: "20" } }),
      _c("div", { staticClass: "flex-grow-1 pl-3" }, [
        _vm._v(" " + _vm._s(_vm.truncate(_vm.message.file.fileName)) + " "),
      ]),
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "", loading: !!_vm.fileStatus },
              on: {
                click: function ($event) {
                  return _vm.download(_vm.message.file)
                },
              },
            },
            [_c("icon", { attrs: { icon: "download-1", size: "20" } })],
            1
          ),
          _vm.fileStatus
            ? _c("div", { staticClass: "file-status" }, [
                _vm._v("%" + _vm._s(_vm.fileStatus)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }