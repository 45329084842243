<template>
  <div
    class="message"
    :class="{
      incoming: message.incoming,
      private: message.private,
      'notification mb-3': message.type === 'notification-message',
      'mb-8': message.type !== 'notification-message',
    }"
  >
    <div class="d-flex align-center" :class="{ 'flex-row-reverse': !message.incoming }">
      <avatar
        v-if="showAvatar && message.type !== 'notification-message'"
        :avatar-size="48"
        :display-name="displayName"
        :image="getUserById(message.userId) ? getUserById(message.userId).avatar : ''"
      />
      <div class="d-flex flex-column px-2" :class="{ 'w-full': isNotificationMessage }">
        <div v-if="message.type !== 'notification-message'" class="message--sender mb-2 d-flex  align-center" :class="{ incoming: message.incoming }">
          <span class="message-text">
            {{ sender }}
          </span>
        </div>
        <div
          class="message--body d-flex align-center"
          :class="{ 'flex-row-reverse': !message.incoming && !isNotificationMessage, 'flex-column': isNotificationMessage }"
        >
          <div class="message--text" :class="{ incoming: message.incoming, 'notification-message': isNotificationMessage }">
            <component :is="messageComponent" :message="message" />
          </div>
          <div class="message--date mr-3" :class="{ 'mr-3': !message.incoming, 'ml-3': message.incoming }">
            {{ formatDate(message.date) }}
          </div>
        </div>
        <div class="message--footer mt-1" :class="{ 'd-flex flex-row-reverse': !message.incoming }" v-if="enablePrivateMessage && privateMessageFooter">
          {{ privateMessageFooter }}
        </div>
        <div v-if="enablePrivateMessage && showReplyButton" class="cursor-pointer message--text--private mt-2" @click="sendPrivateMessage">
          {{ $t('sendPrivateMessage') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import Icon from '@/components/Shared/Icon';
import MessageFile from '@/components/Chat/Message/MessageFile';
import MessageLink from '@/components/Chat/Message/MessageLink';
import MessageText from '@/components/Chat/Message/MessageText';
import MessageNotification from '@/components/Chat/Message/MessageNotification';
import Avatar from '@/components/Shared/Avatar';
import { mapGetters } from 'vuex';

export default {
  name: 'Message',
  components: {
    Icon,
    Avatar,
  },
  props: {
    message: Object,
    enablePrivateMessage: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    currentTime: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters('Conference', ['getUserById']),
    displayName() {
      return this.getUserById(this.message.userId) ? this.getUserById(this.message.userId).displayName : this.message.sender;
    },
    messageComponent() {
      if (this.message.file) {
        return MessageFile;
      } else if (this.isLink(this.message.body)) {
        return MessageLink;
      } else if (this.message?.type === 'notification-message') {
        return MessageNotification;
      } else {
        return MessageText;
      }
    },
    sender() {
      return this.message.incoming ? this.message.sender : this.$t('you');
    },
    privateMessageFooter() {
      if (!this.message.private) return null;

      if (this.message.to) return `${this.$t('user')}: ${this.message.to}`;

      return this.$t('privateMessage');
    },
    showReplyButton() {
      return this.message.incoming && this.message.type !== 'notification-message';
    },
    isNotificationMessage() {
      return this.message?.type === 'notification-message';
    },
  },

  methods: {
    isLink(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    formatDate(date) {
      return dayjs(date).format('HH:mm');
    },

    sendPrivateMessage() {
      this.$emit('privateMessageClicked', { id: this.message.userId, name: this.message.sender });
    },
  },
};
</script>

<style lang="scss">
.message {
  position: relative;
  border-radius: 4px;
  margin: 3px 0;

  .avatar {
    align-self: flex-start;
    .avatar-image {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
    }
    @media (max-width: $breakpoint-mobile) {
      img {
        width: 42px;
        height: 42px;
      }
    }
  }

  &--sender {
    font-size: 10px;
    color: var(--v-white-base);
    flex-direction: row-reverse;

    &.incoming {
      flex-direction: row;
    }
    .message-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 260px;
    }
  }

  &--text {
    background-color: var(--v-body-base);
    color: var(--v-silver-base);
    padding: 4px 8px;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    word-break: break-all;
    min-width: 100px;
    position: relative;
    &--private {
      font-size: 8px;
      color: var(--v-silver-base);
    }

    &.incoming {
      background-color: var(--v-night-blue-base);
    }
    &.notification-message {
      background: transparent;
    }
  }

  &--date {
    color: var(--v-silver-base);
    font-size: 11px;
    min-width: 27px;
  }

  &--footer {
    font-size: 10px;
    color: var(--v-logo-color-base);
  }

  &.private {
    .message--text {
      background-color: var(--v-bar-blue-base);
    }
  }

  &.notification {
    .body {
      text-align: center;
      border-bottom: 1px solid #a8a8a950;
    }
    .date {
      width: 100%;
      text-align: center;
      .date-text {
        width: 100%;
      }
    }
  }

  .date {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    text-align: right;

    padding: 0px 10px;
    margin: 5px;
    transition: var(--default-transition);
  }
  .headTitle {
    max-width: 200px;
    max-height: 3em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
