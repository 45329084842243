import storage from '@/services/storage';
import { CHANGE_DEVICE_TYPE } from '@/constants/devices';

export function getLocalSelectedDevices() {
  return {
    audioInput: storage.getItem('selected_audioinput'),
    audioOutput: storage.getItem('selected_audiooutput'),
    videoInput: storage.getItem('selected_videoinput'),
  };
}

export function checkDevicesDifferences(oldDevices, newDevices) {
  let changeType = null;
  let device = null;
  if (oldDevices.length > newDevices.length) {
    device = oldDevices.filter(device => !newDevices.map(d => d.deviceId).includes(device.deviceId));
    changeType = CHANGE_DEVICE_TYPE.REMOVED;


  } else if (newDevices.length > oldDevices.length) {
    device = newDevices.filter(device => !oldDevices.map(d => d.deviceId).includes(device.deviceId));
    changeType = CHANGE_DEVICE_TYPE.ADDED;
  }
  return {
    device,
    changeType
  };
}