<template>
  <a class="chat-message-link" :href="url" target="_blank">{{ message.body }}</a>
</template>

<script>
export default {
  name: 'MessageLink',
  props: {
    message: {
      type: Object,
    },
  },
  computed: {
    url() {
      return this.message.body.includes(':/') ? this.message.body : `http://${this.message.body}`;
    },
  },
};
</script>

<style lang="scss">
.message {
  a.chat-message-link {
    color: var(--v-link-base);
  }
}
</style>
