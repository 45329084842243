var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { title: _vm.$t("speakerStats"), "max-width": "500px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "div",
        { staticClass: "ma-3 mb-0 speaker-stats" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _vm.$can("downloadUserStatistics", "Meet")
                ? _c("IconButton", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("downloadSpeakerStats"),
                        expression: "$t('downloadSpeakerStats')",
                      },
                    ],
                    attrs: { icon: "download" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.downloadStatList.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-virtual-scroll", {
            attrs: {
              items: _vm.getUsers,
              height: _vm.height,
              "item-height": "64",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _vm.stats[item.id] &&
                    (_vm.stats[item.id]._isLocalStats ||
                      _vm.stats[item.id].displayName)
                      ? _c(
                          "v-list-item",
                          {
                            key: _vm.stats[item.id]._userId,
                            staticClass: "mt-3",
                            attrs: { dense: "" },
                          },
                          [
                            _c("v-list-item-content", [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("avatar", {
                                    attrs: {
                                      "display-name": item.displayName,
                                      image: item.avatar,
                                      "avatar-size": 48,
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stats[item.id]._isLocalStats
                                          ? `${item.displayName} (${_vm.$t(
                                              "you"
                                            )})`
                                          : item.displayName
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-row", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.msToHour(
                                        _vm.stats[
                                          item.id
                                        ].getTotalDominantSpeakerTime()
                                      )
                                    ) + "s"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.stats[item.id] &&
                    (_vm.stats[item.id]._isLocalStats ||
                      _vm.stats[item.id].displayName)
                      ? _c("v-divider")
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }