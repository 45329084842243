<template>
  <div class="survey">
    <div class="survey-container">
      <div v-if="loading">
        <v-progress-circular />
      </div>
      <div class="survey-content px-4 py-6">
        <survey-nav v-if="!isHomePage && title" class="mb-5" :title="title" :showAddButton="showAddButton" @back="back" :showBackButton="showBackButton" />

        <!-- Home page of survey -->
        <div class="survey-home" v-if="currentView === views.INDEX">
          <v-btn color="primary" class="mb-6" v-if="isModerator" block @click="pushRoute(views.MY_SURVEYS)" :disabled="!isAuth">
            <icon icon="check-circle" class="mr-5" />
            <div class="badge-title">
              {{ $t('mySurveys') }}
            </div>
          </v-btn>
          <v-btn color="primary" class="mb-6" block @click="pushRoute(views.ROOM_SURVEYS)" :disabled="!isAuth">
            <icon icon="check-circle" class="mr-5" />
            <v-badge :content="sharedSurveyCount">
              <div class="badge-title">
                {{ $t(getSurveyTitle) }}
              </div>
            </v-badge>
          </v-btn>
          <v-btn color="primary" block @click="pushRoute(views.SURVEY_RESULTS)" :disabled="!isAuth">
            <icon icon="check-circle" class="mr-5" />
            <v-badge :content="sharedSurveyResultsCount">
              <div class="badge-title">
                {{ $t(getSurveyResultsTitle) }}
              </div>
            </v-badge>
          </v-btn>
        </div>

        <!-- Room and User Survey List Tab -->
        <survey-list
          v-else-if="showSurveyList"
          :surveys="surveyListItems"
          :isMySurveys="isMySurveys"
          :isSharedSurvey="isSharedSurvey"
          @goToDetail="goToDetail"
          @goToSurvey="goToSurvey"
          @getSurveys="getMySurveys"
          @goToResult="goToResult"
          @openConfirm="openConfirm"
        />

        <!--Survey Detail Tab -->
        <survey-detail v-else-if="showSurveyDetail" @cancel="back" :survey="survey" @openConfirm="openConfirm" />

        <!--Survey Result Tab -->
        <survey-result :isWebinarSurvey="isWebinarSurvey" v-else-if="showSurveyResult" :survey="survey" :isSharedResult="isSharedResult" />

        <!--Survey Tab -->
        <survey v-else-if="showSurvey" :survey="survey" :assignedSurvey="assignedSurvey" :goToHome="goToHome" :addCompleted="addCompleted" />

        <v-overlay :value="showConfirm" absolute z-index="6"></v-overlay>
        <div class="survey-container--confirm text-center" v-if="showConfirm">
          <icon icon="share"></icon>
          <div class="my-4 text-body-2">
            <div class="ellipsis">
              {{ $t(confirmText, { surveyTitle: confirmObject.survey.Title }) }}
            </div>

            <div class="mx-auto my-8" style="width:150px">
              <Checkbox
                color="white"
                v-if="isModerator && !isDeleteConfirm && !confirmObject.survey.shared"
                v-model="confirmObject.survey.shareWithRoom"
                :disabled="confirmObject.survey.sharedWithWebinar"
                :label="$t('shareWithThisRoom')"
                hide-details
              ></Checkbox>
              <Checkbox
                color="white"
                v-if="isModerator && !isDeleteConfirm && isWebinarStarted && !confirmObject.survey.sharedWithWebinar"
                v-model="confirmObject.survey.shareWithWebinar"
                :disabled="confirmObject.survey.shared"
                :label="$t('shareWithWebinar')"
                hide-details
              ></Checkbox>
              <Checkbox color="white" v-if="!isDeleteConfirm" v-model="confirmObject.survey.ShareResults" :label="$t('resultsPublic')" hide-details></Checkbox>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn outlined class="mr-2" @click="confirmObject = null">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn :color="confirmBtnColor" class="ml-2" @click="confirm">
              {{ $t(confirmObject.type) }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';

import Icon from '@/components/Shared/Icon';
import SurveyNav from '@/components/Survey/SurveyNav';
import SurveyList from '@/components/Survey/SurveyList';
import SurveyDetail from '@/components/Survey/SurveyDetail';
import SurveyResult from '@/components/Survey/SurveyResult';
import Survey from '@/components/Survey/Survey';
import { consoleError, consoleLog } from '@/helpers/logger';
import storage from '@/services/storage';
import ENUMS from '@/constants/ENUMS';
import { TOAST_TYPE } from '@/constants/toast';

const views = Object.freeze({
  INDEX: 0,
  MY_SURVEYS: 1,
  ROOM_SURVEYS: 2,
  DETAIL: 3,
  RESULT: 4,
  SURVEY: 5,
  SURVEY_RESULTS: 6,
});
const { ROOM_NOTIFICATION_TYPES } = ENUMS;

export default {
  name: 'SurveyMainPage',
  components: {
    SurveyNav,
    Icon,
    Survey,
    SurveyList,
    SurveyDetail,
    SurveyResult,
  },
  props: {
    isModerator: {
      type: Boolean,
      default: true,
    },
    isWebinarSurvey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      views,
      completedSurveys: [],
      assignedSurveyMap: {},
      addNew: false,
      loading: false,
      isAuth: true,
      pathList: [views.INDEX],
      sharedSurveyResults: [],
      survey: null,
      isSharedResult: false,
      confirmObject: null,
    };
  },
  computed: {
    ...mapState('Conference', ['roomConfig', 'meetingId', 'roomName']),
    ...mapState('Survey', ['newSurveyShared', 'mySurveys']),
    ...mapState('Webinar', { webinarMeetingId: 'meetingId', webinarRoomName: 'roomName' }),
    ...mapGetters('Conference', ['getLocalUser', 'getUserGroupName', 'getUsers']),
    ...mapGetters('Webinar', { wGetLocalUser: 'getLocalUser', isWebinarStarted: 'isWebinarStarted' }),
    ...mapGetters('Survey', ['getUncompletedSharedSurveysByType']),
    showBackButton() {
      return this.pathList.length > 1 && this.pathList[this.pathList.length - 1] !== 0;
    },
    sharedSurveyCount() {
      return `${this.getUncompletedSharedSurveysByType(this.getType).length}`;
    },
    sharedSurveyResultsCount() {
      return `${this.sharedSurveyResults.length || 0}`;
    },
    assignedSurvey() {
      return this.assignedSurveyMap[this.survey?.Id];
    },
    showSurveyDetail() {
      return this.currentView === views.DETAIL;
    },
    showSurveyResult() {
      return this.currentView === views.RESULT;
    },
    showSurvey() {
      return this.currentView === views.SURVEY;
    },
    getSurveyTitle() {
      return this.isWebinarSurvey ? 'webinarSurveys' : 'roomSurveys';
    },
    getSurveyResultsTitle() {
      return this.isWebinarSurvey ? 'webinarSurveyResults' : 'roomSurveyResults';
    },
    isMySurveys() {
      return this.currentView === views.MY_SURVEYS;
    },
    isSharedSurvey() {
      return this.currentView === views.SURVEY_RESULTS;
    },
    isHomePage() {
      return this.currentView === views.INDEX;
    },
    showAddButton() {
      return this.currentView === views.MY_SURVEYS;
    },
    currentView() {
      return this.pathList[this.pathList.length - 1];
    },
    showSurveyList() {
      return this.currentView === views.MY_SURVEYS || this.currentView === views.ROOM_SURVEYS || this.currentView === views.SURVEY_RESULTS;
    },
    title() {
      switch (this.currentView) {
        case views.MY_SURVEYS:
          return this.$t('mySurveys');

        case views.ROOM_SURVEYS:
          return this.$t(this.getSurveyTitle);

        case views.CATEGORIES:
          return this.$t('categories');

        case views.SURVEY_RESULTS:
          return this.$t('roomSurveyResults');

        case views.DETAIL:
          return this.$t('detail');

        case views.RESULT:
          return this.$t('result');

        case views.SURVEY:
          return this.$t('survey');

        default:
          return null;
      }
    },
    surveyListItems() {
      if (this.currentView === views.MY_SURVEYS) {
        return this.mySurveys;
      }

      if (this.currentView === views.ROOM_SURVEYS) {
        return this.getUncompletedSharedSurveysByType(this.getType);
      }

      if (this.currentView === views.SURVEY_RESULTS) {
        return this.sharedSurveyResults;
      }

      return [];
    },
    userGroupId() {
      return `${this.getType}--${this.getMeetingId}--${this.getRoomName}--${dayjs().format('DD-MM-YYYY')}`;
    },
    getMeetingId() {
      return this.isWebinarSurvey ? this.webinarMeetingId : this.meetingId;
    },
    getRoomName() {
      return this.isWebinarSurvey ? this.webinarRoomName : this.roomName;
    },
    getType() {
      return this.isWebinarSurvey ? 'webinar' : 'room';
    },
    showConfirm() {
      return Boolean(this.confirmObject);
    },
    isDeleteConfirm() {
      return this.confirmObject.type === 'delete';
    },
    confirmBtnColor() {
      if (this.isDeleteConfirm) {
        return 'error';
      }
      return 'secondary';
    },
    confirmIcon() {
      if (this.isDeleteConfirm) {
        return 'trash';
      }
      return 'share-2';
    },
    confirmText() {
      if (this.isDeleteConfirm) {
        return 'surveyDeleteConfirm';
      }
      return 'surveyShareConfirm';
    },
  },
  created() {
    const completedSurveyObject = storage.getItem('completedSurveys') || {};
    const sharedSurveyResultsObject = storage.getItem('sharedSurveyResults') || {};
    this.completedSurveys = completedSurveyObject[this.userGroupId] || [];
    this.sharedSurveyResults = sharedSurveyResultsObject[this.userGroupId] || [];

    this.addUser()
      .then(() => {
        this.loading = false;
        this.isAuth = true;
        this.getMySurveys();
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions('Survey', ['shareSurvey', 'addUser', 'updateSurvey', 'assignSurvey', 'getMySurveys', 'deleteSurvey']),
    ...mapActions('Notification', ['sendRoomNotification', 'showToastNotification']),
    ...mapMutations('Survey', ['SET_NEW_SURVEY_SHARED', 'SET_AS_COMPLETED_SURVEY']),
    openConfirm(confirmObject) {
      let obj = confirmObject;
      if (confirmObject?.survey?.shared) {
        obj.survey.shareWithWebinar = true;
      }
      if (confirmObject?.survey?.sharedWithWebinar) {
        obj.survey.shareWithRoom = true;
      }
      this.confirmObject = obj;
    },
    async share(survey) {
      try {
        let { shareWithRoom, shareWithWebinar } = survey;
        delete survey.shareWithRoom;
        delete survey.shareWithWebinar;
        await this.updateSurvey({
          ...survey,
          IsDeleted: false,
        });
        if (shareWithRoom) await this.shareSurvey({ survey, type: 'room', meetingId: this.meetingId });
        if (shareWithWebinar) await this.shareSurvey({ survey, type: 'webinar', meetingId: this.meetingId });
        if (this.getUsers.length > 1) {
          this.sendRoomNotification({ text: 'aNewSurveyWasShared', userId: this.getLocalUser.id, type: ROOM_NOTIFICATION_TYPES.A_NEW_SURVEY_SHARED });
        }
        this.goToHome();
      } catch (err) {
        consoleError(err);
        this.showToastNotification({ body: 'couldNotShareSurvey', config: { type: TOAST_TYPE.ERROR } });
      }
    },
    addCompleted(surveyId) {
      const sharedSurvey = this.getUncompletedSharedSurveysByType(this.getType).find(r => r.Id === surveyId);
      this.completedSurveys.push(sharedSurvey.Id);

      storage.setItem('completedSurveys', {
        [this.userGroupId]: this.completedSurveys,
      });

      this.goToHome();
      this.survey = null;
      this.SET_AS_COMPLETED_SURVEY({ type: this.getType, Id: surveyId });
      if (sharedSurvey.ShareResults) {
        this.sharedSurveyResults.push(sharedSurvey);

        storage.setItem('sharedSurveyResults', {
          [this.userGroupId]: this.sharedSurveyResults,
        });
      }
    },
    goToDetail(survey) {
      this.pushRoute(views.DETAIL);
      this.survey = survey;
    },
    goToSurvey(survey) {
      if (!survey) return;
      this.survey = survey;

      const payload = {
        surveyId: survey?.Id,
        assignedUserGroupId: survey?.userGroup?.Id,
        isFinished: false,
        cookieId: this.isWebinarSurvey ? this.wGetLocalUser.id : this.getLocalUser.id,
        assignedDate: {
          seconds: new Date().getTime(),
          nanos: 0,
        },
      };

      if (!survey.IsPrivate) {
        payload.anonymousUsername = this.isWebinarSurvey ? this.wGetLocalUser.displayName : this.getLocalUser.displayName;
      }

      if (this.assignedSurveyMap[survey.Id]) {
        this.pushRoute(views.SURVEY);
        return;
      }

      this.assignSurvey(payload)
        .then(result => {
          this.assignedSurveyMap[survey.Id] = result.data.Result;
          this.pushRoute(views.SURVEY);
        })
        .catch(err => {
          consoleLog(err);
        });
    },
    goToResult(data) {
      this.isSharedResult = data.isSharedResult;
      this.pushRoute(views.RESULT);
      this.survey = data.survey;
    },
    pushRoute(page) {
      this.pathList.push(page);
    },
    back() {
      const pathList = [...this.pathList];
      if (pathList.length > 1) {
        pathList.pop();
      }
      this.pathList = pathList;
    },
    goToHome() {
      this.pushRoute(this.views.INDEX);
    },
    async deleteSelectedSurvey(survey) {
      try {
        if (!survey?.Id) return;

        await this.deleteSurvey(survey.Id);
        await this.getMySurveys();
      } catch (err) {
        consoleError(err);
      }
    },
    async confirm() {
      try {
        const { survey, completed, type } = this.confirmObject;

        if (type === 'delete') {
          await this.deleteSelectedSurvey(survey);
        } else if (type === 'share') {
          await this.share(survey);
        }

        if (completed) {
          completed();
        }

        this.confirmObject = null;
      } catch (err) {
        this.confirmObject = null;
        consoleError(err);
      }
    },
  },
  watch: {
    newSurveyShared: {
      immediate: true,
      handler() {
        if (!this.survey) {
          const surveys = this.getUncompletedSharedSurveysByType(this.getType);
          const survey = surveys[surveys.length - 1];
          this.goToSurvey(survey);
        }
        this.SET_NEW_SURVEY_SHARED(null);
      },
    },
  },
};
</script>

<style lang="scss">
$surveyPrimaryColor: var(--survey-primary-color);

.survey {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--right-menu-background-color);

  .survey-container {
    position: absolute;
    height: 100%;
    overflow-x: auto;
    width: 100%;

    &--confirm {
      position: absolute;
      top: 10rem;
      z-index: 10;
      left: 15px;
      width: 340px;
      background-color: var(--v-popup-color-base);
      border: 1px solid var(--v-body-base);
      border-radius: 8px;
      padding: 32px 16px;
    }
  }

  .survey-content {
    height: 100%;
    z-index: 6;
    position: relative;
  }

  .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    padding: 1rem;
  }

  .v-text-field--outlined.v-input--dense .v-label--active {
    transform: translateY(-19px) scale(0.8);
    padding: 0px 5px;
    font-size: 15px !important;
  }

  .v-badge__wrapper {
    flex: 0 1;
    height: 100%;
    left: 10px;
    pointer-events: none;
    position: absolute;
    top: 15px;
    width: 100%;
  }
  .badge-title {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .survey-home {
    display: flex;
    flex-direction: column;

    .v-btn {
      padding: 2rem 1rem !important;
      font-size: 14px;
      justify-content: flex-start;
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .survey {
    height: calc(100vh - 7rem);
  }
}
</style>
