import debounce from 'lodash.debounce';

const vhCalculator = {
  mounted() {
    this._$setCssVH();
    window.addEventListener('resize', this._$debouncedSetCssVH);
  },
  beforeMount() {
    window.removeEventListener('resize', this._$debouncedSetCssVH);
  },
  methods: {
    _$debouncedSetCssVH() {
      this._$setCssVH();
    },
    _$setCssVH: debounce(function() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      setTimeout(() => {
        document.body.scrollTo(0, 0);
      }, 10);
    }, 300),
  },
};

export default vhCalculator;
