var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { persistent: "", "show-close-button": false } },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("Logo", { staticClass: "mb-6", attrs: { height: "50" } }),
          _c("h2", { staticClass: "mb-6" }, [
            _vm._v(_vm._s(_vm.$t("joinMobileTitle"))),
          ]),
          _c("h4", { staticClass: "mb-6" }, [_vm._v(_vm._s(_vm.roomName))]),
          _c(
            "v-btn",
            {
              staticClass: "mt-4 mb-6 pl-10 pr-10",
              attrs: {
                color: "secondary",
                block: "",
                href: _vm.generateDeepLinkingURL(),
              },
            },
            [_vm._v(_vm._s(_vm.$t("joinInApp")))]
          ),
          _c("div", { staticClass: "mb-6" }, [
            _vm._v(_vm._s(_vm.$t("youDontHaveApp"))),
          ]),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "v-btn",
                { attrs: { color: "secondary", href: _vm.appLink, text: "" } },
                [_vm._v(_vm._s(_vm.$t("downloadFromStore")))]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.$t("or")))]),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("joinInBrowser")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }