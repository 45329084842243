<template>
  <icon v-if="privateRoomName" class="private-room-icon ml-1" icon="blue-t" size="18" @click="handleClick" />
</template>

<script lang="ts">
import { mapState } from 'vuex';

export default {
  props: {
    privateRoomName: String,
    isLocalUser: Boolean,
  },
  computed: {
    ...mapState('Conference', ['roomName']),
  },
  methods: {
    handleClick() {
      if (this.privateRoomName && this.privateRoomName !== this.roomName) {
        const confirmModal = this.$showConfirmModal(
          {
            title: this.$t('privateRoom'),
            text: this.$t('areYouSureGoToPrivateRoom'),
          },
          {
            confirm: () => {
              window.location.href = `${this.privateRoomName}`;
            },
            cancel: () => {
              confirmModal.close();
            },
          },
        );
      }
    },
  },
};
</script>

<style>
.private-room-icon {
  cursor: pointer;
}
</style>
