<template>
  <div class="device-selector camera-device-selector">
    <template v-if="hasCamera">
      <div
        class="camera-device-selector--item px-8 py-3 d-flex align-center"
        :class="{ selected: selectedCameraId === item.deviceId }"
        v-for="item in getCameras"
        :key="item.deviceId"
        @click="selectedCameraId = item.deviceId"
        role="button"
      >
        <span class="text-body-2">{{ item.label }}</span>
        <v-spacer />
        <div v-if="showPreview">
          <video :id="`video-preview-${item.deviceId}`"></video>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import debounce from 'lodash.debounce';

export default {
  name: 'CameraDeviceSelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    showPreview: {
      type: Boolean,
    },
  },
  data() {
    return {
      videoTracks: {},
      selectedCameraId: this.value || null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getCameras', 'hasCamera']),
    cameraCount() {
      return this.getCameras.length;
    },
  },
  mounted() {
    this.onToggle();
  },
  beforeDestroy() {
    this.clearTracks();
  },
  methods: {
    onChange(item) {
      this.$emit('change', item);
    },
    setVideoTracks() {
      return new Promise(resolve => {
        this.clearTracks();

        const promises = new Array(this.getCameras.length);

        this.getCameras.forEach((cameraDevice, index) => {
          promises[index] = XperMeetLib.lib.createLocalTracks({ devices: ['video'], cameraDeviceId: cameraDevice.deviceId }).then(tracks => {
            this.$set(this.videoTracks, cameraDevice.deviceId, tracks[0]);
          });
        });

        Promise.all(promises).finally(() => {
          resolve();
        });
      });
    },
    onToggle: debounce(function() {
      if (!this.showPreview) {
        return;
      }
      this.setVideoTracks().then(() => {
        Object.keys(this.videoTracks).forEach(deviceId => {
          const video = document.getElementById(`video-preview-${deviceId}`); // document.querylSelector firefox ta hata veriyor
          if (video) {
            video.srcObject = this.videoTracks[deviceId].stream;
            video.onloadedmetadata = function() {
              video.play();
            };
          }
        });
      });
    }, 500),
    clearTracks() {
      Object.keys(this.videoTracks).forEach(key => {
        const video = document.getElementById(`video-preview-${key}`); // document.querylSelector firefox ta hata veriyor
        if (video) {
          video.srcObject = null;
        }
        this.videoTracks[key].dispose();
      });
      this.$set(this, 'videoTracks', {});
    },
  },
  watch: {
    cameraCount() {
      if (!this.showPreview || this.disabled) {
        return;
      }
      this.setVideoTracks();
    },
    selectedCameraId(selectedCameraId) {
      this.onChange(selectedCameraId);
    },
  },
};
</script>

<style lang="scss">
.camera-device-selector {
  border-radius: 15px;
  border: 1px solid var(--v-date-base);
  background-color: var(--v-primary-base);

  &--item:first-child {
    border-radius: 15px 15px 0 0;
  }

  &--item:last-child {
    border-radius: 0 0 15px 15px;
  }

  &--item {
    &.selected {
      background-color: var(--v-darkblue-40);

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--v-error-base);
        border-radius: 50%;
        margin-right: 1rem;
      }
    }

    video {
      height: 50px;
      border: 1px solid var(--v-date-base);
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
</style>
