var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meet-top-menu d-flex" },
    [
      !_vm.$isMobile
        ? [
            _c(
              "div",
              {
                staticClass: "mr-8",
                staticStyle: { position: "relative", "flex-grow": "1" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("logo"),
                    _vm.webinarTitle
                      ? _c("div", { staticClass: "room-webinar-title" }, [
                          _vm._v(" " + _vm._s(_vm.webinarTitle) + " "),
                        ])
                      : _vm._e(),
                    _vm.meetingInfo
                      ? _c("div", { staticClass: "ml-4" }, [
                          _c("div", { staticClass: "page-title" }, [
                            _vm._v(_vm._s(_vm.meetingInfo.title)),
                          ]),
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v(_vm._s(_vm.meetingInfo.subTitle)),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("license-information"),
              ],
              1
            ),
            _vm.conferenceJoined
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow-1 d-flex justify-end top-menu-actions align-center",
                  },
                  [
                    !_vm.$isMobile ? _c("participant-counter") : _vm._e(),
                    _vm.getE2eeEnabled
                      ? _c("menu-button", {
                          staticClass: "mr-1",
                          attrs: {
                            id: "e2ee-top-button",
                            color: "success",
                            icon: "shield-1",
                            "icon-size": "20",
                          },
                          on: { click: _vm.handleE2eeModal },
                        })
                      : _vm._e(),
                    _c("NotificationsWrapper"),
                    _vm.showWebinarButton
                      ? _c("menu-button", {
                          staticClass: "mr-1",
                          attrs: {
                            id: "webinar-top-button",
                            "icon-background-color": _vm.webinarSessionId
                              ? "secondary"
                              : undefined,
                            icon: "tv-2",
                            "icon-size": "20",
                            dropdownClass: "mt-2",
                            loading: _vm.webinarIconLoading,
                            dropdownList: _vm.webinarMenuList,
                            disabled: !_vm.isWebinarAvailable,
                            "use-inner-model":
                              !_vm.getLocalUser.isModerator ||
                              !_vm.getLocalUser.authenticated ||
                              !_vm.$can("start", "Webinar"),
                            "tooltip-text":
                              _vm.getLocalUser.isModerator &&
                              _vm.getLocalUser.authenticated &&
                              _vm.isWebinarAvailable
                                ? _vm.$t(_vm.webinarButtonTooltipText)
                                : "",
                          },
                          on: { click: _vm.onWebinarButtonClicked },
                        })
                      : _vm._e(),
                    _c("menu-button", {
                      staticClass: "mr-1",
                      attrs: {
                        id: "user-profile-top-button",
                        dropdownClass: "mt-2",
                        icon: "more-horizontal-2",
                        dropdownList: _vm.userProfileMenu,
                        useInnerModel: true,
                      },
                    }),
                    !_vm.$isMobile
                      ? _c("menu-button", {
                          staticClass: "mr-1",
                          attrs: {
                            id: "settings-top-button",
                            icon: "settings",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("itemClicked", "settings")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : [
            _c(
              "div",
              { staticClass: "meet-mobile-top-menu" },
              [
                _c("menu-button", {
                  staticClass: "mb-2",
                  attrs: {
                    id: "switch-camera-button",
                    disabled:
                      _vm.getCameras.length < 2 &&
                      (!_vm.isFacingModeSupported || _vm.waitRefreshButton),
                    width: "32",
                    "icon-background-color": "var(--v-whisper-50)",
                    icon: "refresh-ccw",
                    useInnerModel: true,
                  },
                  on: { click: _vm.switchCamera },
                }),
                _c("menu-button", {
                  staticClass: "mb-2",
                  attrs: {
                    id: "user-profile-top-button-mobile",
                    width: "32",
                    "icon-background-color": "var(--v-whisper-50)",
                    icon: "more-horizontal-2",
                    useInnerModel: true,
                  },
                  on: { click: _vm.openMobileMenu },
                }),
              ],
              1
            ),
            _c("meet-mobile-left-menu", {
              attrs: {
                "about-us-text": _vm.aboutUsText,
                "menu-items": _vm.userProfileMenu,
              },
              model: {
                value: _vm.mobileMenuOpened,
                callback: function ($$v) {
                  _vm.mobileMenuOpened = $$v
                },
                expression: "mobileMenuOpened",
              },
            }),
          ],
      _vm.showDisableE2eeModal
        ? _c("ConfirmModal", {
            attrs: {
              title: _vm.$t("closeE2ee"),
              text: _vm.$t("closeE2eeDescription"),
              "confirm-text": _vm.$t("close"),
              persistent: "",
            },
            on: {
              confirm: function ($event) {
                return _vm.setMasterKeyCustomer({
                  e2eePassword: "",
                  e2eeEnabled: false,
                })
              },
              cancel: function ($event) {
                _vm.showDisableE2eeModal = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }