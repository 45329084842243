<template>
  <div class="lobby-right-menu-container pl-3 pr-2 pt-2">
    <div class="lobby-right-menu-waiting-list">
      <div class="hosts-title flex-grow-0 px-2 py-3 d-flex align-center">
        <icon icon="users" size="20" class="mr-2"></icon>
        <div class="text">{{ $t('waitingParticipantList') }}</div>
      </div>
      <text-field
        name="search-user"
        autocomplete="off"
        backgroundColor="body"
        class="mx-2 mb-2"
        v-model="searchByUserNameText"
        :label="$t('searchUsersByUserName')"
        hide-details
      ></text-field>
      <div class="overflow-auto">
        <UserCard
          v-for="user in waitingUsers"
          :key="user.id"
          :me="getLocalUser && user.id === getLocalUser.id"
          :display-name="getDisplayName(user)"
          :moderator="user.role === 'moderator'"
          :participant="user.role === 'participant'"
        >
          <div v-if="getLocalUser.isModerator" class="user-actions">
            <IconButton icon="check" background-color="success" width="24" icon-size="16" @click="allow(user)" />
            <IconButton icon="close" background-color="error" width="24" icon-size="16" class="ml-3" @click="reject(user)" />
          </div>
        </UserCard>
      </div>
    </div>
    <div class="lobby-right-menu-chat-container">
      <div class="divider mt-3" />
      <div class="hosts-title flex-grow-0 px-2 py-3 d-flex align-center">
        <icon icon="chat-1" size="20" class="mr-2"></icon>
        <div class="text">{{ $t('chat') }}</div>
      </div>
      <Chat
        class="lobby-chat"
        body-id="conference-lobby-chat"
        :chat-enabled="true"
        :messages="lobbyMessages"
        :show-file-share-button="false"
        :enablePrivateMessage="false"
        @sendButtonClicked="onSendButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Chat from '@/components/Chat/Chat';
import UserCard from '@/components/Users/UserCard';
import { turkishToLower } from '@/helpers/regex';

export default {
  name: 'LobbyRightMenuTabContent',
  components: { UserCard, Chat },
  data() {
    return {
      searchByUserNameText: '',
    };
  },
  computed: {
    ...mapGetters('Conference', ['getLocalUser']),
    ...mapState('Lobby', ['lobbyUsers', 'lobbyMucMembers', 'lobbyMessages']),
    moderators() {
      return this.lobbyMucMembers.filter(m => m.role === 'moderator');
    },
    waitingUsers() {
      const users = this.getLocalUser.isModerator ? this.lobbyUsers : this.lobbyMucMembers;
      return users.filter(m => m.role !== 'moderator').filter(m => turkishToLower(m.name).includes(turkishToLower(this.searchByUserNameText)));
    },
  },
  methods: {
    ...mapActions('Lobby', ['sendMessage', 'lobbyApproveAccess', 'lobbyDenyAccess']),
    getDisplayName(user) {
      return user?.name || '';
    },
    onSendButtonClicked(message) {
      this.sendMessage({
        message: message,
      });
    },
    allow(user) {
      this.lobbyApproveAccess(user.id);
    },
    reject(user) {
      this.lobbyDenyAccess(user.id);
    },
  },
};
</script>

<style scoped lang="scss">
.lobby-right-menu-container {
  overflow: hidden;
  flex-direction: column;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto;

  .lobby-right-menu-waiting-list {
    grid-row: 1 / 2;
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;
  }

  .lobby-right-menu-chat-container {
    grid-row: 2 / 3;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .divider {
      border-top: solid 1.5px var(--v-date-base);
      height: 1px;
      width: 100%;
    }
  }

  .lobby-chat {
    height: calc(var(--50vh) - 96px);
  }
}
</style>
