<template>
  <Modal
    width="550"
    :title="$t('e2eeModalTitle')"
    :confirm-button-text="isLoggedIn ? $t('save') : $t('login')"
    :cancel-button-text="$t('cancel')"
    :sub-title="$t('encryptYourAudioAndVideoByActivatingSecretMeetingMode')"
    :auto-close="false"
    show-actions
    persistent
    :show-back-button="encryptType === 'password' && activeTab === 'register'"
    @back="setActiveTab('login')"
    @close="close"
  >
    <div v-if="available" class="mx-3 mb-0 smart-card-container">
      <div v-if="!isLoggedIn" class="d-flex flex-column align-start">
        {{ $t('roomEncryptedPleaseLogin') }}
      </div>

      <div v-else>
        <div v-if="e2eeSupported" class="mx-3 mb-0">
          <v-row class="ml-0">
            <v-radio-group v-model="encryptType" row hide-details class="mt-0">
              <v-radio color="white" :label="$t('withPassword')" value="password"></v-radio>
              <v-radio color="white" :label="$t('withSmartCard')" value="smartCard"></v-radio>
            </v-radio-group>
          </v-row>

          <v-alert v-model="onMessage.show" class="mt-3" text prominent :type="onMessage.type" icon="mdi-alert" dismissible>
            {{ $t(this.onMessage.text) }}
          </v-alert>
          <div v-if="encryptType === 'smartCard'" class="d-flex flex-column justify-center align-center mt-6">
            <div class="smart-card-adaptor-file" v-html="$t('smartCardAdaptorExe')"></div>
            <v-alert class="mt-6" dense type="info" icon="feather icon-info">{{ $t('smartCardOnlyGoogleChrome') }}</v-alert>
          </div>
          <div v-if="encryptType === 'password'">
            <div v-if="activeTab === 'login'" class="mt-6">
              <div class="text-body-2 white-text">{{ $t('activateSecretMeetingModeWithPassword') }}</div>
              <text-field
                class="mt-4"
                v-model="e2eePassword"
                :label="$t('e2eePassword')"
                color="white"
                required
                autocomplete="off"
                hide-details="auto"
                @keydown.enter.prevent="onE2eePromptModalSave"
                :type="showE2eePassword ? 'text' : 'password'"
                :append-icon="showE2eePassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showE2eePassword = !showE2eePassword"
                aria-required="true"
              />
            </div>
            <div v-else class="mt-6">
              <text-field
                v-model="register.e2eeMasterPassword1"
                :label="$t('e2eeMasterPassword')"
                color="white"
                required
                autocomplete="off"
                @keydown.enter.prevent="onE2eePromptModalSave"
                :type="showE2eeMasterPassword1 ? 'text' : 'password'"
                :append-icon="showE2eeMasterPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showE2eeMasterPassword1 = !showE2eeMasterPassword1"
              />

              <text-field
                v-model="register.e2eeMasterPassword2"
                :label="$t('reE2eeMasterPassword')"
                required
                color="white"
                autocomplete="off"
                @keydown.enter.prevent="onE2eePromptModalSave"
                :type="showE2eeMasterPassword2 ? 'text' : 'password'"
                :append-icon="showE2eeMasterPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showE2eeMasterPassword2 = !showE2eeMasterPassword2"
              />

              <v-alert type="info" icon="feather icon-info">
                {{ $t('e2eePromptModalRegisterImportantNote') }}
              </v-alert>

              <v-progress-linear v-if="progress" color="amber" indeterminate rounded height="6"></v-progress-linear>
            </div>
            <div v-if="activeTab === 'login'" class="change-tab mt-6">
              <span class="text" @click="setActiveTab('register')">{{ $t('createPassword') }}</span>
            </div>
            <v-alert v-if="errors && errors.length" v-model="showFormError" class="mt-4" dense outlined type="error" dismissible>
              {{ errors }}
            </v-alert>
          </div>
          <div v-if="encryptType === 'close'" class="d-flex flex-column justify-center align-center  ">
            <v-alert class="mt-6" dense type="info" icon="feather icon-info">{{ $t('closeE2ee') }}</v-alert>
          </div>
        </div>
      </div>
    </div>
    <v-alert text prominent type="warning" icon="mdi-alert" v-else>
      {{ $t('e2eWebinarError') }}
    </v-alert>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import { parseJwt } from '@/helpers/token';
import TextField from '../../Shared/TextField.vue';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'E2eePromptModal',
  components: {
    TextField,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    profileSettings: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      showFormError: false,
      errors: '',
      e2eeEnabled: false,
      e2eePassword: '',
      progress: false,
      activeTab: 'login',
      onMessage: {
        type: 'success',
        show: false,
        text: '',
      },
      register: {
        e2eeMasterPassword1: '',
        e2eeMasterPassword2: '',
      },
      speakerMutedInitialState: false,
      encryptType: 'password',
      showE2eePassword: false,
      showE2eeMasterPassword1: false,
      showE2eeMasterPassword2: false,
    };
  },
  computed: {
    ...mapState('Conference', ['e2eeMasterPassword', 'speakerMuted', 'showE2eePromptModal', 'smartCardAdapterEnabled', 'roomConfig']),
    ...mapGetters('Conference', ['getLocalUser', 'getE2eeEnabled']),
    ...mapGetters('Webinar', ['isWebinarRequestPending', 'isWebinarStarted']),
    e2eeSupported() {
      return this.$customerConfig?.system?.modules?.e2eEncrypt;
    },
    isLoggedIn() {
      return this.getLocalUser.authenticated;
    },
    available() {
      return !this.isWebinarRequestPending && !this.isWebinarStarted;
    },
  },
  beforeDestroy() {
    this.setSpeakerMute(this.speakerMutedInitialState);
  },
  methods: {
    ...mapActions('Conference', ['setSpeakerMute', 'setMasterKeyCustomer']),
    ...mapActions('Notification', ['showToastNotification']),
    ...mapMutations('Conference', ['SET_E2EE_MASTER_PASSWORD', 'SET_SHOW_E2EE_MODAL', 'SET_SMART_CARD_ADAPTER_ENABLED']),
    close(type) {
      if (type === 'confirm') {
        if (this.isLoggedIn) {
          this.onE2eePromptModalSave();
        } else {
          this.redirectLoginPage();
        }
      } else if (type === 'cancel') {
        this.SET_SHOW_E2EE_MODAL(false);
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.onMessage.show = false;
    },
    requestLocalChipper() {
      let customer = parseJwt(window.localStorage.getItem('keycloak-token'));
      this.loading = true;
      XperMeetLib.passwerks.chipperTest(customer.sub, result => {
        /* response;
          errorCode: null
          result: "test"
          status: "OK"
        * */
        if (result?.status === 'OK') {
          XperMeetLib.passwerks.smartCardInit(customer.sub, (msg, type) => {
            const isSuccess = type === 'success';
            this.SET_SMART_CARD_ADAPTER_ENABLED(isSuccess);

            if (isSuccess) {
              // XperMeetLib.conference.setRoomProperty(ENUMS.RoomConfigEnums.E2EE_ENABLED, true);
              XperMeetLib.conference.localUser.toggleE2ee(true);
              XperMeetLib.conference.room.toggleE2EE(true);
              // TODO: https://bugs.chromium.org/p/chromium/issues/detail?id=1103280
              // XperMeetLib.conference.room._restartMediaSessions();
              this.showToastNotification({ body: msg, config: { type: TOAST_TYPE.SUCCESS } });
              this.SET_SHOW_E2EE_MODAL(false);
              this.loading = false;
            }
          });
        } else {
          this.showErrorMessage('chipperError', 'error');
          this.loading = false;
          this.SET_SMART_CARD_ADAPTER_ENABLED(false);
        }
      });
    },
    redirectLoginPage() {
      XperMeetLib.keycloakManager.redirectLoginPage();
    },
    showErrorMessage(msg, color) {
      this.onMessage.show = true;
      this.onMessage.type = color;
      this.onMessage.text = msg ? msg : 'unknownError';
    },
    onE2eePromptModalSave() {
      if (this.encryptType === 'smartCard') {
        const confirmModal = this.$showConfirmModal(
          {
            title: this.$t('smartCardAdapter'),
            text: this.$t('smartCardAdapterUsageWarning'),
          },
          {
            confirm: () => {
              this.requestLocalChipper();
              confirmModal.close();
            },
            cancel: () => {
              confirmModal.close();
              if (this.smartCardAdapterEnabled) {
                this.this.encryptType === 'smartCard';
              } else {
                this.encryptType === 'password';
              }
            },
          },
        );
      } else if (this.activeTab === 'login') {
        this.errors = '';
        this.errors += !this.e2eePassword ? `${this.$t('masterPassword')}` : '';
        if (this.errors) {
          this.errors += this.$t('required');
          this.showFormError = true;
          return;
        }
        this.setMasterKey(true);
      } else if (this.activeTab === 'register') {
        if (this.register.e2eeMasterPassword1 === this.register.e2eeMasterPassword2) {
          XperMeetLib.passwerks.register(this.register.e2eeMasterPassword1, result => {
            if (result.IsSuccess) {
              this.showToastNotification({ body: 'registrationComplete', config: { type: TOAST_TYPE.SUCCESS } });
              this.activeTab = 'login';
            } else {
              this.showToastNotification({ body: 'registrationFailedAlreadyExist', config: { type: TOAST_TYPE.ERROR } });
            }
          });
        } else if (this.activeTab === 'chipper') {
          this.activeTab = 'chipper';
        } else {
          this.showToastNotification({ body: 'passwordsAreNotEqual', config: { type: TOAST_TYPE.ERROR } });
        }
      } else {
        this.SET_SHOW_E2EE_MODAL(false);
      }
    },
    setMasterKey(value) {
      this.setMasterKeyCustomer({
        e2eePassword: this.e2eePassword,
        e2eeEnabled: value,
      })
        .then(() => {
          this.SET_SHOW_E2EE_MODAL(false);
        })
        .catch(reason => {
          let { msg } = reason;
          if (reason.msg === 'e2eeIsNotEnabled') {
            msg += ` ${this.$t('e2eeChromeHint')}`;
            this.showErrorMessage(msg, reason.color);
          } else if (reason.msg === 'encryptionError') {
            this.showErrorMessage(msg, reason.color);
          } else if (reason.msg === 'customerDoesNotExistPleaseRegister') {
            this.activeTab = 'register';
            this.showErrorMessage(msg, reason.color);
          } else if (reason.msg === 'unauthenticated') {
            this.showErrorMessage(msg, reason.color);
          }
        });
    },
  },
  watch: {
    getE2eeEnabled: {
      immediate: true,
      handler: function() {
        this.e2eeEnabled = this.getE2eeEnabled;
        // this.SET_SHOW_E2EE_MODAL(this.getE2eeEnabled && !this.e2eeMasterPassword && !this.getLocalUser.localUserE2ee);
      },
    },
    smartCardAdapterEnabled: {
      immediate: true,
      handler: function() {
        if (this.getE2eeEnabled && this.smartCardAdapterEnabled) {
          this.this.encryptType === 'smartCard';
        } else if (this.getE2eeEnabled) {
          this.encryptType === 'password';
        } else {
          this.encryptType === 'cancel';
        }
      },
    },
    e2eeMasterPassword() {
      this.e2eePassword = this.e2eeMasterPassword;
    },
    showE2eePromptModal: {
      handler: function() {
        if (this.showE2eePromptModal && this.roomConfig?.e2eeEnabled) {
          this.speakerMutedInitialState = this.speakerMuted;
          this.setSpeakerMute(true);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.smart-card-container {
  .form-container {
    border-radius: 5px;
  }

  .Password__strength-meter {
    position: relative;
    height: 3px;
    margin: 10px auto 20px;
    border-radius: 3px;
  }

  .v-tab {
    justify-content: left !important;
  }

  .e2eeHeaderImage {
    border-radius: var(--default-radius) !important;
  }
  .smart-card-adaptor-file {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--v-white-base);
    .file-link {
      color: var(--v-secondary-base);
    }
  }
  .white-text {
    color: var(--v-white-base);
  }
  .Password__toggle {
    button {
      color: var(--v-white-base);
    }
  }
  .change-tab {
    display: flex;
    justify-content: center;
    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: var(--v-link-base);
      border-bottom: 1px solid var(--v-link-base);
      cursor: pointer;
    }
  }
}
</style>
