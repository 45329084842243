<template>
  <Modal
    persistent
    show-actions
    :full-screen="isFullScreen"
    :title="$t(title)"
    :confirm-button-text="$t('IamTheHost')"
    :cancel-button-text="$t('goToHome')"
    :hide-confirm-btn="isPrivateRoom"
    :hide-cancel-btn="showPrivateRoomEntrance"
    :show-close-button="!showPrivateRoomEntrance"
    :content-class="modalClass"
    @close="close"
  >
    <JoinPrivateRoom v-if="showPrivateRoomEntrance" page-type="join" :room-name="this.roomName" @ok="showPrivateRoomEntrance = false" />
    <span v-else>{{ desc }}</span>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import JoinPrivateRoom from '@/components/Meet/JoinPrivateRoom.vue';
import { createATagAndClick } from '@/helpers/create-element';
import { canSkipPrivateRoomEntrance } from '@/helpers/private-room';

export default {
  name: 'WaitingForTheHost',
  components: {
    JoinPrivateRoom,
  },
  props: {
    roomName: {
      type: [String, Number],
    },
  },
  data() {
    return {
      showPrivateRoomEntrance: false,
    };
  },
  computed: {
    ...mapState('Conference', ['isPrivateRoom']),
    modalClass() {
      let modalClass = 'text-center';

      if (this.showPrivateRoomEntrance) {
        modalClass += ' join-private-room-modal';
      }

      return modalClass;
    },
    isFullScreen() {
      return this.isPrivateRoom && this.showPrivateRoomEntrance;
    },
    title() {
      if (this.showPrivateRoomEntrance) {
        return null;
      }
      return this.isPrivateRoom ? 'waitingForTheRoomOwner' : 'waitingForTheHost';
    },
    desc() {
      if (this.isPrivateRoom) {
        return this.$t('waitingForTheRoomOwnerDesc');
      }

      return `${this.roomName ? this.roomName : ''} ${this.$t('conferenceOpening')}`;
    },
  },
  created() {
    if (this.isPrivateRoom && !canSkipPrivateRoomEntrance(this.roomName)) {
      this.showPrivateRoomEntrance = true;
    }
  },
  methods: {
    ...mapActions('Conference', ['redirectKeycloakLoginPage']),
    close(type) {
      if (type === 'confirm') {
        this.redirectKeycloakLoginPage();
      } else if (type === 'cancel') {
        this.goToHome();
      }
    },
    goToHome() {
      const homeUrl = window.location.origin;
      createATagAndClick(homeUrl);
    },
  },
};
</script>

<style lang="scss">
.join-private-room-modal {
  .modal-title {
    display: none;
  }
}
</style>
