<template>
  <Modal :title="$t('notifications.title')" small-title padding-none>
    <div class="content mt-8">
      <Notifications v-if="roomNotifications.length" />
      <div v-else class="px-4 pb-4 d-flex mt-10 flex-column align-center text-center">
        <Icon color="white" icon="bell" size="40" class="mb-3" />
        <span class="no-notification-text">{{ $t('notifications.noNotificationsYet') }}</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notifications from '@/components/Notifications/RoomNotifications/Notifications';
export default {
  name: 'NotificationModal',
  components: {
    Notifications,
  },
  computed: {
    ...mapState('Notification', ['roomNotifications']),
  },
  beforeDestroy() {
    this.readRoomNotifications();
  },
  methods: {
    ...mapActions('Notification', ['readRoomNotifications']),
  },
};
</script>
<style scoped lang="scss">
.content {
  .no-notification-text {
    opacity: 0.75;
    font-size: 20px;
    max-width: 250px;
  }
}
</style>
