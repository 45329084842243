<template>
  <div class="participant-counter">
    <div class="d-flex align-center" :class="{ 'cursor-pointer': !webinar }" @click="toggleUserList">
      <icon icon="users" size="20"></icon>
      <span class="participant-count px-2">{{ participantCount }}</span>
    </div>
    <div class="divider"></div>
    <template v-if="isWebinarStarted || webinar">
      <v-divider vertical class="mr-2"></v-divider>
      <icon icon="tv-2" size="20"></icon>
      <span class="participant-count px-2">{{ getViewerCount }}</span>
    </template>
    <div v-if="roomConfig.lobbyEnabled && getLocalUser.isModerator" class="d-flex cursor-pointer" @click="openRightMenuLobby">
      <v-divider vertical class="mr-2"></v-divider>
      <icon icon="user-clock" size="20"></icon>
      <span class="participant-count px-2">{{ lobbyUserCount }}</span>
    </div>
    <menu-button
      v-tooltip="!webinar && showMustLoggedIn"
      class="plus-menu-icon"
      icon-background-color="body"
      rounded
      icon="plus"
      width="26"
      icon-size="16"
      dropdownClass="mt-4"
      :disabled="!webinar && !isLoggedIn"
      :dropdown-list="inviteItems"
      :use-inner-model="true"
      :click-disabled="!webinar && !isLoggedIn"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import MeetingRequest from '@/components/Meet/Modal/MeetingRequest';
import DialInModal from '@/components/DialIn/Modal/DialInModal';
import { copyToClipboard } from '@/helpers/clipboard';

export default {
  name: 'ParticipantCounter',
  props: {
    webinar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Conference', ['locked', 'roomConfig']),
    ...mapState('Lobby', ['lobbyUsers', 'lobbyMucMembers']),
    ...mapGetters('Webinar', ['webinarUrl', 'isWebinarStarted', 'getViewerCount', 'getParticipantCount']),
    ...mapGetters('Conference', ['getUsers', 'getE2eeEnabled', 'getLocalUser']),
    inviteItems() {
      let items = [
        {
          label: 'emailInvite',
          onClick: () => {
            this.$showModal(MeetingRequest);
          },
        },
        {
          label: 'phoneInvite',
          onClick: () => {
            this.$showModal(DialInModal, { available: this.isDialInAvailable });
          },
        },
        {
          webinar: true,
          label: 'copyWebinarUrl',
          notRender: !this.isWebinarStarted,
          onClick: () => {
            this.copyWebinarUrl();
          },
        },
      ];
      if (!this.$customerConfig?.system?.admin?.modules?.dialIn) {
        items = items.filter(i => i.label !== 'phoneInvite');
      }
      return items.filter(i => !this.webinar || i.webinar);
    },
    isDialInAvailable() {
      return !this.locked && !this.getE2eeEnabled;
    },
    isLoggedIn() {
      return this.getLocalUser?.authenticated;
    },
    showMustLoggedIn() {
      return this.isLoggedIn ? '' : this.$t('youMustBeLoggedInToUseThisFeature');
    },
    participantCount() {
      return this.webinar ? this.getParticipantCount : this.getUsers.length;
    },
    lobbyUserCount() {
      const users = this.getLocalUser.isModerator ? this.lobbyUsers : this.lobbyMucMembers;
      return users.filter(m => m.role !== 'moderator')?.length || 0;
    },
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_RIGHT_MENU']),
    copyWebinarUrl() {
      const text = this.webinar ? this.$t('webinarCopy', { url: this.webinarUrl }) : this.webinarUrl;
      copyToClipboard(text);
    },
    toggleUserList() {
      if (!this.webinar) {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'user-list' });
      }
    },
    openRightMenuLobby() {
      this.TOGGLE_RIGHT_MENU({ open: true, tab: 'lobby' });
    },
  },
};
</script>

<style lang="scss">
.participant-counter {
  background-color: var(--v-primary-base);
  height: 40px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 20px;

  .participant-count {
    font-size: 14px;
  }

  .plus-menu-icon {
    margin-right: -10px;
    border: solid 4px var(--v-darkblue-90);
    border-radius: 50%;
  }

  .menu-button-wrapper {
    &.disabled {
      opacity: 1 !important;

      .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #1a2031 !important;
      }
      button::before {
        opacity: 1 !important;
        background-color: #1a2031 !important;
        border-color: #1a2031 !important;
      }
      svg {
        color: #45485a;
      }
    }
  }
}
</style>
