var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list",
        {
          attrs: { color: "primary" },
          nativeOn: {
            mouseover: function ($event) {
              return _vm.onMouseOver.apply(null, arguments)
            },
            mouseleave: function ($event) {
              return _vm.onMouseLeave.apply(null, arguments)
            },
          },
        },
        [
          [
            _vm.item.type === "text"
              ? _c(
                  "v-list-item",
                  {
                    staticClass: "list-type-text",
                    class: { [_vm.item.class]: _vm.item.class },
                  },
                  [_c("div", [_vm._v(_vm._s(_vm.item.label))])]
                )
              : _vm.item.type === "divider"
              ? _c("div", { staticClass: "list-type-divider" })
              : !_vm.item.notRender && !_vm.item.confirmPopover
              ? _c(
                  "v-list-item",
                  {
                    class: {
                      active: _vm.item.selected,
                      disabled: _vm.item.disabled,
                      [_vm.item.class]: _vm.item.class,
                      "has-child ": _vm.item.childs,
                    },
                    attrs: {
                      dense: "",
                      disabled: _vm.item.disabled,
                      "data-test-id": _vm.item.id ? _vm.item.id : undefined,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("onChildItemClicked", _vm.item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          [
                            !!_vm.item.icon && !_vm.item.childs
                              ? _c("icon", {
                                  staticClass: "ml-4 mr-2",
                                  attrs: {
                                    icon: _vm.item.icon,
                                    width: "20",
                                    height: "20",
                                    color: _vm.item.iconColor,
                                  },
                                })
                              : _vm._e(),
                            _vm.hasChildren
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "open-on-hover": "",
                                      top: "",
                                      "offset-x": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "menu-button-child-item pr-2 w-full d-flex align-center justify-space-between",
                                                    },
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      !!_vm.item.icon
                                                        ? _c("icon", {
                                                            staticClass:
                                                              "ml-4 mr-2",
                                                            attrs: {
                                                              icon: _vm.item
                                                                .icon,
                                                              color:
                                                                _vm.item
                                                                  .iconColor,
                                                              width: "20",
                                                              height: "20",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            !_vm.item
                                                              .doNotTranslate
                                                              ? _vm.$t(
                                                                  _vm.item.label
                                                                )
                                                              : _vm.item.label
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("icon", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      icon: "chevron-right",
                                                      color: "white",
                                                      width: "20",
                                                      height: "20",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      285529172
                                    ),
                                  },
                                  _vm._l(_vm.item.childs, function (child, k) {
                                    return _c("menu-button-list", {
                                      key: k,
                                      attrs: { index: k, item: child },
                                      on: {
                                        onMouseOver: _vm.onMouseOver,
                                        onMouseLeave: _vm.onMouseLeave,
                                        onChildItemClicked: function ($event) {
                                          return _vm.$emit(
                                            "onChildItemClicked",
                                            child
                                          )
                                        },
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full d-flex justify-space-between align-center mr-4",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.item.doNotTranslate
                                              ? _vm.$t(_vm.item.label)
                                              : _vm.item.label
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm.item.active
                                      ? _c("icon", {
                                          staticClass: "ml-2",
                                          attrs: { icon: "check" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : !_vm.item.notRender && _vm.item.confirmPopover
              ? _c(
                  "confirm-popover",
                  {
                    key: _vm.index,
                    staticClass: "menu-button-popover",
                    attrs: {
                      confirmText: "yes",
                      cancelText: "no",
                      description: "areYouSure",
                    },
                    on: {
                      confirm: function ($event) {
                        return _vm.$emit("onChildItemClicked", _vm.item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        class: {
                          active: _vm.item.selected,
                          disabled: _vm.item.disabled,
                          [_vm.item.class]: _vm.item.class,
                        },
                        attrs: { dense: "", disabled: _vm.item.disabled },
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                !!_vm.item.icon
                                  ? _c("icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: _vm.item.icon },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.item.doNotTranslate
                                        ? _vm.$t(_vm.item.label)
                                        : _vm.item.label
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }