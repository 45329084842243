var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltipText,
          expression: "tooltipText",
        },
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "menu-button-wrapper ml-1",
      class: { disabled: _vm.disabled },
      attrs: { title: _vm.disableTooltip },
      on: {
        mouseover: function ($event) {
          return _vm.onMouseOver("1")
        },
        mouseleave: function ($event) {
          return _vm.onMouseLeave(null)
        },
      },
    },
    [
      _c(
        "v-menu",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disabled,
              expression: "!disabled",
            },
          ],
          attrs: {
            "content-class": _vm.dropdownClass,
            off: "",
            transition: "slide-y-transition",
            "offset-y": "",
            top: _vm.top,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "v-badge",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: !!_vm.badgeWebinarContent
                                ? _vm.$t("unreadWebinarMessageCount", {
                                    count: _vm.badgeWebinarContent,
                                  })
                                : "",
                              expression:
                                "!!badgeWebinarContent ? $t('unreadWebinarMessageCount', { count: badgeWebinarContent }) : ''",
                            },
                          ],
                          attrs: {
                            top: "",
                            left: _vm.left,
                            color: "secondary",
                            content: _vm.badgeWebinarContent,
                            value: !!_vm.badgeWebinarContent,
                            bordered: "",
                            "offset-x": "20",
                            "offset-y": "14",
                          },
                        },
                        [
                          _c(
                            "v-badge",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: !!_vm.badgeContent
                                    ? _vm.$t("unreadMessageCount", {
                                        count: _vm.badgeContent,
                                      })
                                    : "",
                                  expression:
                                    "!!badgeContent ? $t('unreadMessageCount', { count: badgeContent }) : ''",
                                },
                              ],
                              attrs: {
                                bottom: "",
                                left: _vm.left,
                                content: _vm.badgeContent,
                                value: !!_vm.badgeContent,
                                bordered: "",
                                "offset-x": "20",
                                "offset-y": "14",
                              },
                            },
                            [
                              !_vm.text
                                ? _c("IconButton", {
                                    attrs: {
                                      icon: _vm.icon,
                                      color: _vm.color,
                                      loading: _vm.loading,
                                      iconSize: _vm.iconSize,
                                      width: _vm.width,
                                      "background-color":
                                        _vm.iconBackgroundColor,
                                      rounded: _vm.rounded,
                                      disabled: _vm.clickDisabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onButtonClicked.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.clickDisabled,
                                        loading: _vm.loading,
                                        color: _vm.iconBackgroundColor,
                                        width: _vm.width,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onButtonClicked.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          icon: _vm.icon,
                                          color: _vm.color,
                                          size: _vm.iconSize,
                                        },
                                      }),
                                      _c("span", { staticClass: "pl-2" }, [
                                        _vm._v(_vm._s(_vm.text)),
                                      ]),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dropdownList &&
                      _vm.dropdownList.length &&
                      !_vm.hideCaretDown
                        ? _c(
                            "IconButton",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "menu-dropdown-button",
                                  attrs: {
                                    circle: "",
                                    icon: "caret-down",
                                    width: "20",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleDropdown.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                "IconButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.openDropdown,
            callback: function ($$v) {
              _vm.openDropdown = $$v
            },
            expression: "openDropdown",
          },
        },
        [
          _vm.showDropdownMenu &&
          _vm.dropdownList &&
          _vm.dropdownList.length &&
          !_vm.dropdownList.every((d) => d.notRender)
            ? _c(
                "div",
                _vm._l(_vm.dropdownList, function (list, key) {
                  return _c("menu-button-list", {
                    key: key,
                    attrs: { item: list, index: key },
                    on: {
                      onMouseOver: _vm.onMouseOver,
                      onMouseLeave: _vm.onMouseLeave,
                      onChildItemClicked: _vm.onChildItemClicked,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }