var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "content-class": _vm.dropdownClass, "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        text: "",
                        ripple: false,
                        small: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("icon", {
                    attrs: { color: _vm.iconColor, icon: _vm.icon, size: "20" },
                  }),
                  _c("icon", {
                    staticClass: "ml-1",
                    attrs: { color: "date", icon: "chevron-down", size: "16" },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        {
          staticStyle: { background: "var(--v-primary-base) !important" },
          attrs: { dense: "" },
        },
        _vm._l(_vm.listItems, function (item, index) {
          return _c(
            "v-list-item",
            { key: index, on: { click: item.onClick } },
            [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t(item.label)))])],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }