<template>
  <div v-if="loading" class="room-error">
    <Logo class="mb-2" />
    <v-progress-circular indeterminate color="amber"></v-progress-circular>
  </div>
  <div v-else-if="invalidRoom" class="room-error">
    <p>{{ $t('invalidRoomName') }}!</p>
    <v-btn color="secondary" to="/">{{ $t('goToHome') }}</v-btn>
  </div>
  <div @mousemove="mouseMove" v-else>
    <v-navigation-drawer
      :value="showChatScreen"
      right
      :width="fullWidthRightMenu ? '100%' : '380'"
      :app="!rightMenuTemporary"
      :absolute="rightMenuTemporary"
      :temporary="rightMenuTemporary"
      style="z-index: 20"
      @input="onRightMenuInput"
    >
      <right-menu @close="closeMenu" />
    </v-navigation-drawer>

    <div class="meet-container" id="meet-container" :fullscreen="!!getFullScreenUserId">
      <v-container fluid class="meet-wrapper d-flex flex-column">
        <div class="meet-header">
          <div class="meet-top-menu-container d-flex justify-space-between flex-row align-center flex-grow-1 h-full">
            <meet-top-menu class="flex-grow-1" @itemClicked="onConferenceMenuItemClicked" @changeCamera="val => (selectedCameraId = val)" />
          </div>
        </div>
        <div class="meet-content">
          <div class="meet-page" :data-layout-type="activeLayoutType">
            <template v-if="activeLayoutType === 'grid'">
              <div class="users-container">
                <VideoGridlayout ref="videoGridLayout" :layout="activeLayout" :users="getUsers" @fullscreen="onFullScreenClicked" />
              </div>
            </template>
            <template v-else>
              <VideoDynamicLayout :users="getUsers" @fullscreen="onFullScreenClicked" />
            </template>
          </div>
          <RecordControl />
        </div>
        <div class="meet-footer d-flex justify-center">
          <div class="meet-bottom-menu-container d-flex flex-row align-center flex-grow-1">
            <div v-if="!$isMobile && conferenceJoined" class="flex-grow-1 d-flex">
              <Pagination v-if="activeLayoutType !== 'grid'" />
            </div>
            <meet-bottom-menu class="flex-grow-1" @itemClicked="onConferenceMenuItemClicked" />
            <div v-show="!$isMobile && conferenceJoined" :class="{ 'd-flex flex-row justify-end align-center flex-grow-1': !$isMobile }">
              <v-tooltip v-if="$can('lock', 'Meet')" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <menu-button
                    v-bind="attrs"
                    v-on="on"
                    :color="locked ? 'success' : ''"
                    :class="{ 'cursor-pointer': $can('lock', 'Meet') }"
                    :disabled="!$can('lock', 'Meet')"
                    :icon="locked ? 'lock-2' : 'unlock-1'"
                    @click="showLockConferenceModal"
                  ></menu-button>
                </template>
                <span>{{ $t('lockDescription') }}</span>
              </v-tooltip>

              <room-timer class="ml-2" :meeting-duration="meetingDuration" @open="openStatistics"></room-timer>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <MediaDevicesInitiator ref="mediaDeviceInitiator" @initiated="mediaDeviceInitiated" :camera-device-id="selectedCameraId" />

    <div class="meet-modal-container">
      <template>
        <LogoutConfirmModal />
      </template>
      <template v-if="showLobbyPreMeeting">
        <LobbyPreMeeting :is-response-waiting="responseWaitingForLobby" />
      </template>
      <template v-else-if="waitingForTheHost">
        <WaitingForTheHost :value="waitingForTheHost" :room-name="roomName" />
      </template>
      <template v-else-if="conferenceMaxUserReached">
        <MaxUserReachedModal :value="conferenceMaxUserReached" />
      </template>

      <template v-else-if="showConferencePasswordModal">
        <PasswordRequired :value="showConferencePasswordModal" />
      </template>
      <template v-else-if="showProfileModal">
        <ProfileModal
          :showDialog="showProfileModal"
          :display-name="getLocalUser && getLocalUser.displayName"
          @layoutTypeChanged="CHANGE_LAYOUT_TYPE"
          @save="onProfileModalSave"
          @close="onProfileModalClose"
        />
      </template>
      <template v-else-if="showWhiteBoardModal">
        <WhiteBoard :value="showWhiteBoardModal" />
      </template>

      <E2eePromptModal v-else-if="showE2eePromptModal" />
    </div>
    <template v-if="showNewDeviceAddedModal && getFirstAddedDevice">
      <NewDeviceModal />
    </template>
    <UsersAudioContainer />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

import MeetBottomMenu from '@/components/Menu/MeetBottomMenu';
import MeetTopMenu from '@/components/Menu/MeetTopMenu';
import RecordControl from '@/components/RecordControl/RecordControl';
import RightMenu from '@/components/Meet/RightMenu/RightMenu';
import LobbyPreMeeting from '@/views/LobbyPreMeeting';
import VideoGridlayout from '@/components/Meet/VideoGridlayout';
import MediaDevicesInitiator from '@/components/Device/MediaDevicesInitiator';

import WaitingForTheHost from '@/components/Meet/Modal/WaitingForTheHost';
import MaxUserReachedModal from '@/components/Meet/Modal/MaxUserReachedModal';
import PasswordRequired from '@/components/Meet/Modal/PasswordRequired';
import ProfileModal from '@/components/Profile/Modal/Profile';
import E2eePromptModal from '@/components/E2EE/Modal/E2eePromptModal';
import ConferenceCreateModal from '@/components/Meet/Modal/ConferenceCreate';
import LogoutConfirmModal from '@/components/Meet/Modal/LogoutConfirmModal';
import NewDeviceModal from '@/components/Meet/Modal/NewDeviceModal.vue';
import Settings from '@/components/Settings/Modal/index';
import RecordListModal from '@/components/RecordControl/Modal/RecordListModal';
import SpeakerStats from '@/components/Meet/Modal/SpeakerStats';
import WhiteBoard from '../components/WhiteBoard/Modal/WhiteBoard';
import LayoutSettings from '@/components/Settings/Modal/LayoutSettings';
import SharedVideoModal from '@/components/SharedVideo/Modal/SharedVideoModal';
import VideoDynamicLayout from '@/components/Meet/VideoDynamicLayout';
import RoomTimer from '@/components/Meet/RoomTimer';
import FileShareModal from '@/components/File/Modal/FileShareModal';
import LockConferenceModal from '@/components/Meet/Modal/LockConferenceModal';
import MeetingRequest from '@/components/Meet/Modal/MeetingRequest';

import storage from '@/services/storage';
import { mobileAndTabletCheck } from '@/helpers/detect-browser';
import { msToHour } from '@/helpers/time';
import { attachTrackToElements, clearConferenceName } from '@/helpers/conference';
import ENUMS, { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';
import { parseJwt } from '@/helpers/token';
import { isTrue } from '@/helpers/validation';
import UsersAudioContainer from '@/components/Meet/UsersAudioContainer';
import ShareVideoModal from '@/components/SharedVideo/Modal/ShareVideoModal';
import NotificationModal from '@/components/Notifications/RoomNotifications/Modal/NotificationModal';
import Pagination from '@/components/Meet/Pagination';
import { TOAST_TYPE } from '@/constants/toast';
import { consoleLog } from '@/helpers/logger';
import { canSkipPrivateRoomEntrance } from '@/helpers/private-room';

export default {
  name: 'Meet',
  components: {
    Pagination,
    UsersAudioContainer,
    MediaDevicesInitiator,
    VideoGridlayout,
    VideoDynamicLayout,
    RoomTimer,
    WhiteBoard,
    LobbyPreMeeting,
    LogoutConfirmModal,
    NewDeviceModal,
    RightMenu,
    PasswordRequired,
    WaitingForTheHost,
    ProfileModal,
    E2eePromptModal,
    MeetTopMenu,
    MeetBottomMenu,
    RecordControl,
    MaxUserReachedModal,
  },
  data() {
    return {
      isPrivateRoom: false,
      meetingDuration: '00:00:00',
      permissionState: null,
      errorCode: null,
      showMediaDeviceInitiator: true,
      invalidRoom: false,
      youtubeVideoModal: null,
      timeout: null,
      lastMouseMoveTime: this.serverTime,
      showWarningTimeNotification: false,
      showWarningTime: this.$customerConfig?.system?.movementSensor?.showWarningTime,
      kickTime: this.$customerConfig?.system?.movementSensor?.kickTime,
      selectedCameraId: null,
      responseWaitingForLobby: false,
      loading: true,
    };
  },
  computed: {
    ...mapState(['lang', 'theme']),
    ...mapState('Conference', [
      'waitingForTheHost',
      'showConferencePasswordModal',
      'showChatScreen',
      'showProfileModal',
      'e2eePromptModal',
      'showE2eePromptModal',
      'showWhiteBoardModal',
      'clearQueryParams',
      'files',
      'createdTimestamp',
      'layoutType',
      'roomConfig',
      'speakerMuted',
      'e2eeMasterPassword',
      'sharedVideo',
      'conferenceMaxUserReached',
      'showRecordList',
      'locked',
      'conferenceJoined',
      'serverTime',
    ]),
    ...mapState('Lobby', ['showLobbyPreMeeting', 'joinResponseWaiting']),
    ...mapState('Survey', ['sharedSurveys']),
    ...mapState('Webinar', { webinarUsers: 'users' }),
    ...mapState('Devices', ['showNewDeviceAddedModal']),
    ...mapGetters('Devices', ['getFirstAddedDevice']),
    ...mapGetters('Conference', [
      'getLocalUser',
      'getRemoteUsers',
      'getUsers',
      'getE2eeEnabled',
      'remoteRecordStartedBy',
      'getSharedProperty',
      'getFullScreenUserId',
      'getVideoShareAvailability',
      'getPrivateRoomName',
      'isFollowModeratorFullScreen',
    ]),
    ...mapGetters('Survey', ['getRoomName', 'getUncompletedSharedSurveysByType']),
    ...mapGetters('Webinar', ['isWebinarStarted', 'isWebinarRequestPending']),
    rightMenuTemporary() {
      return this.$vuetify?.breakpoint?.width < 1330;
    },
    fullWidthRightMenu() {
      return this.$vuetify?.breakpoint?.width < 450;
    },
    e2eeSupported() {
      return this.$customerConfig?.system?.modules?.e2eEncrypt;
    },
    isMobile() {
      return mobileAndTabletCheck();
    },
    activeLayout() {
      return this.roomConfig?.layout ? JSON.parse(JSON.stringify(this.roomConfig.layout)) : null;
    },
    activeLayoutType() {
      return this.isMobile ? 'dynamic' : this.getSharedProperty('layoutType') || this.layoutType;
    },
    participantCount() {
      return this.getUsers?.length || 0;
    },
    roomName() {
      return clearConferenceName(this.$route.params.roomName);
    },
    getPassword() {
      const { p } = this.$route.query;
      return p ? atob(p) : undefined;
    },
    allowAdminStartVideo() {
      return this.roomConfig.allowAdminStartVideo;
    },
    movementSensor() {
      return Boolean(this.showWarningTime) && Boolean(this.kickTime);
    },
  },
  beforeDestroy() {
    this.leaveCall();
    clearInterval(this._timerIntervalId);
    clearTimeout(this.timeout);
  },
  created() {
    if (this.$route?.query['authenticator-token']) {
      localStorage.setItem('authenticator-token', this.$route.query['authenticator-token']);
    }
  },
  mounted() {
    if (!window.location.hash && window.addEventListener) {
      window.addEventListener('load', function () {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 0);
      });
      window.addEventListener('orientationchange', function () {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 0);
      });
      window.addEventListener('touchstart', function () {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 0);
      });
    }

    this._timerIntervalId = setInterval(() => {
      if (this.createdTimestamp) {
        const ms = this.serverTime - this.createdTimestamp;
        this.meetingDuration = msToHour(ms);
        if (this.movementSensor) {
          const now = this.serverTime;
          const difference = Math.floor((now - this.lastMouseMoveTime) / 60000);
          if (difference >= this.showWarningTime) {
            this.showWarningTimeNotification = true;
          }
          if (difference >= this.showWarningTime + this.kickTime) {
            if (this.getLocalUser?.id) {
              this.logout();
            }
            this.leaveCall();
          }
        }
      }
    }, 1000);

    if (this.$route?.query?.eventWebinar) {
      this.startWebinarAuto();
    }

    if (this.$route?.query?.redirected) {
      this.showToastNotification({ body: 'youMovedToLobbyByModerator', config: { timeout: 0 } });
    }

    this.checkIsFromWebinar();
    this.fetchInitialData().then(data => this.beforeRouteEnter(data));
  },
  methods: {
    ...mapActions('Conference', [
      'initXperMeet',
      'lock',
      'unlock',
      'selectParticipant',
      'isE2EESupported',
      'setDisplayName',
      'leaveCall',
      'logout',
      'attachTrackToElement',
      'setLayoutConfig',
      'setSpeakerMute',
      'setFullScreen',
      'validateRoomName',
      'redirectLoginPage',
      'fetchUserInfo',
      'fetchRoomOwnerInfo',
    ]),
    ...mapActions('Webinar', ['startWebinarStream']),
    ...mapMutations('Conference', [
      'TOGGLE_CHAT_SCREEN',
      'SET_CURRENT_RIGHT_MENU_TAB',
      'SET_CLEAR_QUERY_PARAMS',
      'TOGGLE_LOGOUT_CONFIRM_MODAL',
      'TOGGLE_PROFILE_MODAL',
      'CHANGE_LAYOUT_TYPE',
      'SET_SHOW_E2EE_MODAL',
      'TOGGLE_RIGHT_MENU',
      'SET_RECORD_DURATION',
    ]),
    ...mapMutations('Survey', ['SET_NEW_SURVEY_SHARED']),
    ...mapMutations('Lobby', ['SET_SHOW_LOBBY_PRE_MEETING']),
    ...mapActions('Notification', ['showToastNotification', 'showRoomNotification', 'playSound']),
    ...mapMutations('Notification', ['SET_ROOM_NOTIFICATION_HIGHLIGHT', 'REMOVE_TOAST_NOTIFICATION']),
    async fetchInitialData() {
      try {
        const user = await this.fetchUserInfo();
        const privateRoomInfo = await this.fetchRoomOwnerInfo({ roomName: this.roomName });
        return { user, privateRoomInfo };
      } catch (err) {
        consoleLog('User could not fetch ', err);
        return {};
      }
    },
    beforeRouteEnter({ user, privateRoomInfo }) {
      storage.removeItem('disableDisplayNameChange');
      if (this.$route.query['authenticator-token']) {
        const token = parseJwt(this.$route.query['authenticator-token']);
        if (token?.context?.user?.name) {
          storage.setItem('displayName', token.context.user.name);
          storage.setItem('disableDisplayNameChange', true);
        }
      }

      const currentUserPrivateRoom = user?.private_room_name;
      this.isPrivateRoom = privateRoomInfo && currentUserPrivateRoom !== this.roomName;
      const isCreate = this.$route.query?.create;
      if (!canSkipPrivateRoomEntrance(this.roomName) && currentUserPrivateRoom !== this.roomName) {
        const route = { name: 'privateRoomEntrance' };

        if (currentUserPrivateRoom && !privateRoomInfo && isCreate) {
          this.$router.replace(route);
          return;
        }
      }

      if (storage.getItem('skipPreJoinRoom') !== this.roomName) {
        // FIXME: add query  params for from webinar
        this.$router.replace({ name: 'prejoin', params: { roomName: this.roomName }, query: this.$route?.query });
        return;
      }

      this.loading = false;
    },
    onRightMenuInput(value) {
      if (!value) {
        this.closeMenu();
      }
    },
    openStatistics() {
      if (this.$can('seeUserStatistics', 'Meet')) {
        this.$showModal(SpeakerStats);
      }
    },
    mediaDeviceInitiated({ errorCode }) {
      this.$refs.mediaDeviceInitiator.destroyVideoStreams();
      if (errorCode) {
        this.errorCode = errorCode;
      } else {
        this.init();
      }
    },
    saveLayoutSettings({ layoutType, backgroundImage, sharedProperties }) {
      const layout = this.$refs?.videoGridLayout ? this.$refs.videoGridLayout.localLayout : false;
      this.setLayoutConfig({ layout, layoutType, backgroundImage, sharedProperties });
    },
    onConferenceMenuItemClicked(itemName) {
      if (itemName === 'layoutSettings') {
        this.$showModal(
          LayoutSettings,
          {
            settings: this.roomConfig,
            user: this.getLocalUser,
            activeLayoutType: this.activeLayoutType,
          },
          {
            save: this.saveLayoutSettings,
          },
        );
      } else if (itemName === 'e2eEncryption') {
        this.SET_SHOW_E2EE_MODAL(true);
      } else if (itemName === 'settings') {
        this.$showModal(Settings);
      } else if (itemName === 'goToConference') {
        this.$showModal(ConferenceCreateModal);
      } else if (itemName === 'userList') {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'user-list' });
      } else if (itemName === 'fileShare') {
        this.$showModal(FileShareModal);
      } else if (itemName === 'sharedFiles') {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'shared-files' });
      } else if (itemName === 'shareVideo') {
        if (this.getVideoShareAvailability) {
          this.$showModal(ShareVideoModal);
        }
      } else if (itemName === 'whiteBoard') {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'whiteboard' });
      } else if (itemName === 'survey') {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'survey' });
      } else if (itemName === 'recordList') {
        this.$showModal(RecordListModal);
      } else if (itemName === 'lockConference') {
        this.showLockConferenceModal();
      } else if (itemName === 'login') {
        this.redirectLoginPage();
      } else if (itemName === 'meetingRequest') {
        this.$showModal(MeetingRequest);
      } else if (itemName === 'augmentedReality') {
        this.TOGGLE_RIGHT_MENU({ open: true, tab: 'ar' });
      } else if (itemName === 'notifications') {
        this.$showModal(NotificationModal);
      }
    },
    onProfileModalSave({ displayName, layoutType }) {
      this.setDisplayName(displayName);
      this.CHANGE_LAYOUT_TYPE(layoutType);
    },
    onProfileModalClose({ displayName }) {
      this.TOGGLE_PROFILE_MODAL(!displayName);
    },
    getConfig() {
      const { AUDIO_UNMUTED, VIDEO_UNMUTED } = ENUMS.RoomConfigEnums;
      const mutedPolicy = window.customerConfig?.system?.conference?.defaultSettings?.mutedPolicy;
      const {
        startMutedPolicy = mutedPolicy?.startMutedPolicy || AUDIO_UNMUTED,
        startVideoMutedPolicy = mutedPolicy?.startVideoMutedPolicy || VIDEO_UNMUTED,
        allowAdminStartVideo = mutedPolicy?.allowAdminStartVideo || false,
        token,
      } = this.$route.query;
      const startWithAudioMuted = startMutedPolicy !== AUDIO_UNMUTED || storage.getItem('startWithAudioMuted');
      const startWithVideoMuted = startVideoMutedPolicy !== VIDEO_UNMUTED || storage.getItem('startWithVideoMuted');

      return {
        password: this.getPassword,
        startWithAudioMuted,
        startWithVideoMuted,
        roomName: this.roomName,
        token: token,
        joinAsGuest: this.isPrivateRoom,
        startMutedPolicyConfig: {
          startMutedPolicy,
          startVideoMutedPolicy,
          allowAdminStartVideo,
        },
      };
    },
    closeMenu() {
      this.TOGGLE_CHAT_SCREEN({ close: true });
    },
    onFullScreenClicked(user) {
      if (this.getLocalUser.isModerator || !this.isFollowModeratorFullScreen) {
        this.setFullScreen({
          userId: user.id,
          fullScreen: !user.fullScreen,
        });
      }
    },
    async init() {
      const config = this.getConfig();
      if (!config.token && !this.$route?.query.fromWebinar) {
        const isValid = await this.validateRoomName(config.roomName);
        this.invalidRoom = !isValid;
      }

      if (!this.invalidRoom) {
        this.initXperMeet(config);
      }
    },
    startWebinarAuto() {
      if (!this.isWebinarStarted) {
        this.timeout = setTimeout(() => {
          if (!this.isWebinarRequestPending) {
            this.startWebinarStream({ roomName: this.roomName }).catch(() => {
              this.showToastNotification('webinarAutoStartFailed');
              this.startWebinarAuto();
            });
          }
        }, 10000);
      }
    },
    mouseMove: debounce(function () {
      if (this.movementSensor) {
        const now = this.serverTime;
        const difference = Math.floor((now - this.lastMouseMoveTime) / 60000);
        if (this.showWarningTimeNotification) {
          this.showWarningTimeNotification = false;
        }
        if (difference >= 1) {
          this.lastMouseMoveTime = now;
        }
      }
    }, 1000),
    showLockConferenceModal() {
      this.$showModal(LockConferenceModal);
    },
    checkIsFromWebinar() {
      if (this.$route.query.fromWebinar) {
        this.SET_SHOW_LOBBY_PRE_MEETING(true);
        this.responseWaitingForLobby = true;
      }
    },
  },
  watch: {
    remoteRecordStartedBy: {
      handler(newVal, oldVal) {
        if (this.remoteRecordStartedBy) {
          if (newVal !== oldVal) {
            this.playSound(NOTIFICATION_SOUND_TYPES.RECORDING);
          }
          if (this.getLocalUser?.id !== this.remoteRecordStartedBy) {
            this.showRoomNotification({ text: 'recording', userId: this.remoteRecordStartedBy, type: ENUMS.ROOM_NOTIFICATION_TYPES.RECORD });
          }
        }
      },
      immediate: true,
    },
    getE2eeEnabled: {
      immediate: true,
      handler: function (val) {
        this.SET_SHOW_E2EE_MODAL(val && !this.e2eeMasterPassword && !this.getLocalUser.localUserE2ee);
      },
    },
    roomName() {
      this.init();
    },
    getFullScreenUserId() {
      if (this.getFullScreenUserId) {
        this.TOGGLE_CHAT_SCREEN({ close: true });
      }
    },
    clearQueryParams() {
      if (this.clearQueryParams) {
        this.SET_CLEAR_QUERY_PARAMS(false);
        const query = {};
        if (this.$route?.query?.eventWebinar) {
          query.eventWebinar = true;
        }
        if (this.$route?.query?.token) {
          query.token = this.$route?.query?.token;
        }
        this.$router.replace({ query, params: { roomName: this.roomName } }).catch(() => {});
      }
    },
    activeLayoutType() {
      // created hook'da yapılmamalı
      // layoutType değiştirildiğinde video ve audio tracklar tekrardan oluşturulmalı
      this.$nextTick().then(() => {
        attachTrackToElements(this.getUsers, false, true);
        this.setSpeakerMute(this.speakerMuted);
      });
    },
    'sharedVideo.status': {
      handler: function (newVal, oldValue) {
        console.log(newVal); //eslint-disable-line
        if (newVal === 'start' || (newVal === 'playing' && oldValue === 'stop') || newVal === 'pause') {
          if (!this.youtubeVideoModal) {
            this.youtubeVideoModal = this.$showModal(SharedVideoModal);
          }
        } else if (newVal === 'stop') {
          if (this.youtubeVideoModal) {
            this.youtubeVideoModal.close();
            this.youtubeVideoModal = null;
          }
        }
      },
    },
    sharedSurveys: {
      immediate: true,
      deep: true,
      handler() {
        const surveys = this.getUncompletedSharedSurveysByType('room');
        if (surveys.length > 0) {
          this.SET_NEW_SURVEY_SHARED('room');
          this.TOGGLE_RIGHT_MENU({ open: true, tab: 'survey' });
        }
      },
    },
    allowAdminStartVideo: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (oldValue === 'undefined' && (newValue === false || newValue === 'false')) {
          return;
        }

        let notificationText;
        let notificationType = TOAST_TYPE.ERROR;

        if (isTrue(newValue)) {
          notificationText = 'moderatorWarning';
        } else if (!isTrue(newValue) && oldValue) {
          notificationText = 'moderatorWarningClosed';
          notificationType = TOAST_TYPE.WARNING;
        }
        if (notificationText) {
          this.showToastNotification({
            body: notificationText,
            config: {
              type: notificationType,
              closeOnClick: false,
              timeout: 0,
              buttons: [
                {
                  text: 'understand',
                  close: true,
                  bold: true,
                },
              ],
            },
          });
        }
      },
    },
    showWarningTimeNotification: {
      handler() {
        if (this.showWarningTimeNotification) {
          const message = this.$t('movementSensorNotification', { time: this.kickTime });
          this.showToastNotification({
            body: message,
            config: {
              closeOnClick: false,
              timeout: 0,
              buttons: [
                {
                  text: 'understand',
                  action: () => {
                    this.showWarningTimeNotification = false;
                    this.lastMouseMoveTime = this.serverTime;
                  },
                  bold: true,
                  close: true,
                },
              ],
            },
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
.meet-container {
  display: flex;

  .meet-header {
    height: 96px;
    flex-shrink: 0;
    background-color: var(--v-darkblue-60);
    padding: 0 40px;
    z-index: 2;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 16px;
      background-color: transparent;
      z-index: 11;
      position: absolute;
      right: 0;
    }

    .meet-top-menu-container {
      margin: auto;
      .meet-top-menu {
        @media (max-width: $breakpoint-mobile) {
          margin-left: 0;
        }
      }
    }
  }
  .meet-content {
    flex-grow: 1;
  }
  .meet-footer {
    height: 96px;
    flex-shrink: 0;
    background-color: var(--v-darkblue-60);
    padding: 0 40px;
    z-index: 3;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 16px;
      height: 70px;
      background-color: transparent;
    }

    .meet-bottom-menu-container {
      margin: auto;
    }
  }
  &[fullscreen='true'] {
    .meet-header {
      background-color: transparent;
      .logo {
        visibility: hidden;
      }
    }
    .meet-footer {
      background-color: transparent;
    }
  }

  .meet-wrapper {
    padding: 0px;
    position: relative;
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .meet-page {
    height: calc(var(--100vh) - 192px);
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &[data-layout-type='grid'] {
      max-height: calc(var(--100vh) - 192px);
      overflow-x: hidden;
      overflow-y: auto;

      .users-container {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }

    .meet {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
    }
  }
}

.room-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    margin-bottom: 0.5rem;
    font-size: 24px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .file-list {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    .v-list {
      height: 100%;
      .v-virtual-scroll {
        height: 100%;
      }
    }
  }
  .user-on-recording {
    top: 1rem;
    right: 1rem;
    left: auto !important;
    bottom: auto !important;
  }

  .meet-container {
    .meet-page {
      height: 100%;
    }
  }
}
</style>
