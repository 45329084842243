<template>
  <div class="video-dynamic-layout d-flex justify-center align-center flex-wrap" ref="video-dynamic-layout">
    <video-card
      ref="video-card"
      v-for="user in getActivePageUsers"
      :data-test-user-id="user.id"
      :key="user.id"
      :user="user"
      :hideFullScreen="!getLocalUser.isModerator && roomConfig && roomConfig.followModeratorFullScreen"
      :participant-count="participantCount"
      :fullScreen="user.fullScreen"
      :pinned="checkPinUserVideo(user.id)"
      :pinnedPosition="getPinnedUserPosition(user.id)"
      :avatar-size="18"
      @pinnedPositionChanged="setPinnedDominantUserPosition"
      @dblclick="$emit('fullscreen', user)"
    />
    <div v-show="pageChanging" :class="{ 'd-flex': pageChanging }" class="page-loading justify-center align-center">
      <icon icon="loader" loading></icon>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';

import VideoCard from '@/components/VideoCard/VideoCard';

import { consoleLog } from '@/helpers/logger';
import { calculateVideoLayout } from '@/helpers/video';
import { isMobile } from '@/helpers/detect-browser';

const JIBRI_PAGE_SIZE = 1000;

export default {
  name: 'VideoDynamicLayout',
  components: { VideoCard },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      debouncedActivePage: 1,
      pageChanging: false,
      isMobile: isMobile(),
      pinnedDominantUserId: null,
      pinnedDominantUserPosition: {
        top: '70px',
      },
    };
  },
  computed: {
    ...mapState('Conference', ['showChatScreen', 'roomConfig', 'isJibri', 'activePage']),
    ...mapGetters('Conference', ['getLocalUser', 'getFullScreenUserId', 'getUserById', 'getPageSize', 'getDominantUserId', 'getPaginationEnabled']),
    videoConstraints() {
      const constraints = {
        colibriClass: 'ReceiverVideoConstraints',
        lastN: this.lastN,
        selectedEndpoints: this.getActivePageUsers.map(u => u.id),
        onStageEndpoints: [],
        defaultConstraints: { maxHeight: 180 },
        constraints: {},
      };

      if (this.getDominantUserId) {
        constraints.onStageEndpoints.push(this.getDominantUserId);
        constraints.constraints[this.getDominantUserId] = { maxHeight: 1080 };
      }

      if (this.getFullScreenUserId) {
        constraints.constraints[this.getFullScreenUserId] = { maxHeight: 1080 };
        constraints.onStageEndpoints.push(this.getFullScreenUserId);
      }

      constraints.selectedEndpoints = constraints.selectedEndpoints.filter(id => !constraints.onStageEndpoints.includes(id));

      return constraints;
    },
    lastN() {
      return this.getActivePageUsers.length;
    },
    getActivePageUsers() {
      const users = this.getActivePageUserIds.map(userId => this.getUserById(userId)).map(u => u);
      if (this.pinnedDominantUserId) {
        const pinnedUser = this.getUserById(this.pinnedDominantUserId);
        if (pinnedUser) {
          users.push(pinnedUser);
        }
      }
      return users;
    },
    getActivePageUserIds() {
      if (this.isMobile) {
        if (this.getFullScreenUserId) {
          return [...new Set([this.getFullScreenUserId, this.getLocalUser.id])];
        } else if (this.getDominantUserId) {
          return [...new Set([this.getDominantUserId, this.getLocalUser.id])];
        } else if (!this.getDominantUserId && this.users.length > 1) {
          return [...new Set([this.users[1].id, this.getLocalUser.id])];
        } else if (this.getLocalUser) {
          return [this.getLocalUser.id];
        } else {
          return [];
        }
      } else {
        if (this.getPaginationEnabled) {
          if (this.getFullScreenUserId) {
            return [...new Set([this.getFullScreenUserId, this.getLocalUser.id])];
          } else {
            return this.users.slice((this.debouncedActivePage - 1) * this.getPageSize, this.debouncedActivePage * this.getPageSize).map(user => user.id);
          }
        } else {
          return this.users.map(user => user.id);
        }
      }
    },
    getActivePageUserIdsString() {
      const ids = [...this.getActivePageUserIds];
      return ids.sort().join('_');
    },
    getActivePageUserIdsLength() {
      return this.getActivePageUserIds.length;
    },
    participantCount() {
      return this.users?.length || 0;
    },
  },
  mounted() {
    if (this.$isMobile || this.$isTablet) {
      this.SET_PAGE_SIZE(4);
    }
    this.setScreenSize();

    window.addEventListener('resize', this.debouncedScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedScreenSize);
  },
  methods: {
    ...mapActions('Conference', ['attachTrackToElement', 'setReceiverConstraints']),
    ...mapMutations('Conference', ['SET_PAGE_SIZE']),
    setScreenSize() {
      const x = this.showChatScreen ? 450 : 0;
      const w = window.innerWidth - x;
      const h = window.innerHeight;
      const r = v => (v * 10) / 100;
      const { width, height } = calculateVideoLayout(this.getActivePageUserIds.length, {
        screenWidth: Math.floor(w - r(w)),
        screenHeight: Math.floor(h - r(h)),
      });

      const layoutElement = this.$refs['video-dynamic-layout'];
      layoutElement.style.setProperty('--card-width', width + 'px');
      layoutElement.style.setProperty('--card-height', height + 'px');
    },
    debouncedScreenSize: debounce(function () {
      this.setScreenSize();
    }, 200),
    setLastNandSelectParticipants() {
      if (!this.getPaginationEnabled) return;
      if (!this.users.length) return;

      this.$nextTick(() => {
        consoleLog('videoConstraints', this.videoConstraints);
        this.setReceiverConstraints(this.videoConstraints);
      });
    },
    checkPinUserVideo(userId) {
      if (this.isMobile || this.isJibri) {
        return false;
      }

      if (this.pinnedDominantUserId === userId) {
        return true;
      }

      return (
        this.getFullScreenUserId &&
        !this.getUserById(this.getFullScreenUserId).screenSharing &&
        this.getLocalUser.id === userId &&
        this.getFullScreenUserId !== userId
      );
    },
    onActivePageUserIdChanged: debounce(function () {
      if (!this.isMobile) {
        this.setLastNandSelectParticipants();
      }
    }, 500),
    onActivePageChanged: debounce(function () {
      this.debouncedActivePage = this.activePage;
      this.pageChanging = false;
    }, 750),
    setPinnedDominantuser() {
      if (this.getActivePageUserIds.includes(this.getDominantUserId)) {
        this.pinnedDominantUserId = null;
      } else {
        this.pinnedDominantUserId = this.getDominantUserId;
      }
    },
    setPinnedDominantUserPosition(position) {
      this.pinnedDominantUserPosition = position;
    },
    getPinnedUserPosition(userId) {
      if (this.checkPinUserVideo(userId)) {
        if (this.pinnedDominantUserId === userId) {
          return this.pinnedDominantUserPosition;
        }
      }
      return null;
    },
  },
  watch: {
    activePage() {
      this.pageChanging = true;
      this.onActivePageChanged();
    },
    getDominantUserId() {
      if (this.isJibri || this.isMobile) {
        if (this.getDominantUserId && !this.getFullScreenUserId) {
          this.setReceiverConstraints(this.videoConstraints);
        }
      } else {
        this.setPinnedDominantuser();
        this.setLastNandSelectParticipants();
      }
    },
    getFullScreenUserId() {
      this.setLastNandSelectParticipants();
    },
    getActivePageUserIdsString: {
      handler: function (newVal, oldVal) {
        this.onActivePageUserIdChanged(newVal, oldVal);
      },
      immediate: true,
    },
    getActivePageUserIdsLength() {
      this.setScreenSize();
    },
    showChatScreen() {
      this.setScreenSize();
    },
    isJibri() {
      this.SET_PAGE_SIZE(JIBRI_PAGE_SIZE);
    },

    getActivePageUserIds() {
      this.setPinnedDominantuser();
    },
  },
};
</script>

<style scoped lang="scss">
.video-dynamic-layout {
  position: relative;
  .page-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}
</style>
