<template>
  <div class="notification-settings-wrapper pa-3">
    <div class="notification-settings-title pl-3">
      {{ $t('notificationSettings.title') }}
    </div>
    <v-col class="notification-settings ">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.enableSound"
            :label="$t('notificationSettings.activateNotificationSound')"
            :hide-details="true"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.userJoined"
            :label="$t('notificationSettings.userJoinedSound')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.webinarStarted"
            :label="$t('notificationSettings.webinarStreamStarted')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.webinarStopped"
            :label="$t('notificationSettings.webinarStreamStopped')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.conferenceIncomingMessage"
            :label="$t('notificationSettings.conferenceNewUnreadMessage')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.webinarIncomingMessage"
            :label="$t('notificationSettings.webinarNewUnreadMessage')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="py-0 px-3">
          <v-switch
            color="success"
            v-model="soundSettings.recording"
            :label="$t('notificationSettings.onRecording')"
            :disabled="!soundSettings.enableSound"
            :hide-details="true"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';

export default {
  name: 'NotificationSettings',
  data() {
    return {
      soundSettings: {
        enableSound: true,
        [NOTIFICATION_SOUND_TYPES.USER_JOINED]: true,
        [NOTIFICATION_SOUND_TYPES.WEBINAR_STARTED]: true,
        [NOTIFICATION_SOUND_TYPES.WEBINAR_STOPPED]: true,
        [NOTIFICATION_SOUND_TYPES.CONFERENCE_INCOMING_MESSAGE]: true,
        [NOTIFICATION_SOUND_TYPES.WEBINAR_INCOMING_MESSAGE]: false,
        [NOTIFICATION_SOUND_TYPES.RECORDING]: true,
      },
    };
  },
  computed: {
    ...mapState(['theme']),
    ...mapState('Notification', ['notificationSoundSettings']),
  },
  created() {
    this.SET_NOTIFICATION_FROM_LOCAL_STORAGE();
    this.soundSettings = { ...this.notificationSoundSettings };
  },
  methods: {
    ...mapMutations('Notification', ['SET_NOTIFICATION_FROM_LOCAL_STORAGE', 'SET_NOTIFICATION_SOUND_SETTING']),
    save() {
      this.SET_NOTIFICATION_SOUND_SETTING(this.soundSettings);
    },
    close(type) {
      this.$emit('close', type);
    },
  },
  watch: {
    soundSettings: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.soundSettings.enableSound) {
          Object.keys(this.soundSettings).forEach(setting => {
            this.soundSettings[setting] = false;
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-settings-wrapper {
  .notification-settings {
    margin: auto;
    overflow-x: auto;
  }
  .notification-settings-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    opacity: 0.9;
    color: var(--v-white-base);
  }
}
</style>
