import { ENUMS } from 'xpermeet-lib';

export default Object.freeze({
  ...ENUMS,
  LOGOUT_MODAL_ENUM: {
    LEAVE_TYPE_CALL: 'leave-call',
    LOG_OUT: 'logout',
    DESTROY_ROOM: 'destroy-room',
  },
  GLOBAL_ENUM: {
    RECORDING_STATUS: {
      STOP: 0,
      PENDING: 1,
      REMOTE_RECORD: 2,
      LOCAL_RECORD: 3,
      READY_TO_DOWNLOAD: 4,
      REMOTE_RECORD_ERROR: 5,
    },
  },
  STREAM_CHANNELS: {
    XPERMEET: 1,
    YOUTUBE: 2,
  },
  WEBINAR_CONNECTION_STATE: {
    IDLE: 1,
    PENDING: 2,
    WATCHING: 3,
    ENDED: 4,
  },
  OS: {
    ANDROID: 1,
    IOS: 2,
    MAC: 3,
    UNKNOWN: 4,
    WINDOWS: 5,
  },
  UI_ERROR_CODES: {
    UNSUPPORTED_DEVICE: 'UNSUPPORTED_DEVICE',
    USER_CANT_JOIN: 'USER_CANT_JOIN',
  },
  HOME_TAB: {
    GO_TO_CONFERENCE: 1,
    CREATE_CONFERENCE: 2,
  },
  DEFAULT_PAGE_SIZE: 25,
  CHAT_NOTIFICATION_TYPES: {
    USER_JOINED_CONFERENCE: 'userJoinedConference',
    USER_LEFT_CONFERENCE: 'userLeftConference',
    USER_JOINED_WEBINAR: 'userJoinedWebinar',
    USER_LEFT_WEBINAR: 'userLeftWebinar',
    START_RECORDING_NOTIFICATION_BY_USER: 'startRecordingNotificationByUser',
    STOP_RECORDING_NOTIFICATION_BY_USER: 'stopRecordingNotificationByUser',
    STOP_REMOTE_RECORDING_NOTIFICATION_BY_USER: 'stopRemoteRecordingNotificationByUser',
    USER_CHANGED_THE_CONFERENCE_PASSWORD: 'userChangedTheConferencePassword',
    USER_REMOVED_THE_CONFERENCE_PASSWORD: 'userRemovedTheConferencePassword',
    USER_SET_THE_CONFERENCE_PASSWORD: 'userSetTheConferencePassword',
    USER_ACTIVATED_THE_LOBBY: 'userActivatedTheLobby',
    USER_DEACTIVATED_THE_LOBBY: 'userDectivatedTheLobby',
    USER_ACTIVATED_THE_TEACHER_MODE: 'userActivatedTheTeacherMode',
    USER_DEACTIVATED_THE_TEACHER_MODE: 'userDeactivatedTheTeacherMode',
    USER_ACTIVATED_THE_FILE_SHARING: 'userActivatedTheFileSharing',
    USER_DEACTIVATED_THE_FILE_SHARING: 'userDeactivatedTheFileSharing',
    USER_ACTIVATED_THE_PRIVATE_CHAT: 'userActivatedThePrivateChat',
    USER_DEACTIVATED_THE_PRIVATE_CHAT: 'userDeactivatedThePrivateChat',
    USER_ACTIVATED_THE_SCREEN_SHARING: 'userActivatedTheScreenSharing',
    USER_DEACTIVATED_THE_SCREEN_SHARING: 'userDeactivatedTheScreenSharing',
    USER_CHANGED_THE_WALLPAPER: 'userChangedTheWallpaper',
    USER_SHARED_A_NEW_SURVEY: 'userSharedANewSurvey',
    USER_ACTIVATED_E2EE: 'userActivatedE2ee',
    USER_DEACTIVATED_E2EE: 'userDeactivatedE2ee',
    SURVEY_COMPLETED_BY_USER: 'surveyCompletedByUser',
    USER_START_WEBINAR_BROADCAST: 'userStartWebinarBroadcast',
    USER_END_WEBINAR_BROADCAST: 'userEndWebinarBroadcast',
    USER_INVITED_WEBINAR_VIEWER: 'userInvitedWebinarViever',
    WEBINAR_VIEWER_JOINED_TO_CONFERENCE: 'webinarViewerJoinedToConference',
    WEBINAR_VIEWER_RETURNED_TO_WEBINAR: 'webinarViewerReturnedToWebinar',
    PARTICIPANT_CONN_STATUS_CHANGED: 'participantConnStatusChanged',
  },
  USER_CONNECTION_STATUS: {
    INTERRUPTED: 'interrupted',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  ROOM_NOTIFICATION_TYPES: {
    TEXT: 0,
    RECORD: 1,
    HANDS_UP: 2,
    FILE_SHARE: 3,
    MODERATOR_ROLE: 4,
    A_NEW_SURVEY_SHARED: 5,
    STOP_RECORD: 6,
  },
  ROOM_NOTIFICATION_BADGE_COLORS: {
    1: 'record',
    2: 'hands-up',
    3: 'file-share',
    4: 'moderator-role',
    5: 'survey-shared',
  },
  NOTIFICATION_TYPES: {
    CHAT: 1,
    ROOM: 2,
    TOAST: 3,
  },
});

export const NOTIFICATION_SOUND_TYPES = {
  USER_JOINED: 'userJoined',
  USER_LEFT: 'userLeft',
  CONFERENCE_INCOMING_MESSAGE: 'conferenceIncomingMessage',
  WEBINAR_INCOMING_MESSAGE: 'webinarIncomingMessage',
  WEBINAR_STARTED: 'webinarStarted',
  WEBINAR_STOPPED: 'webinarStopped',
  RECORDING: 'recording',
  NEW_DEVICE_SELECTED: 'newDeviceSelected'
};

export const NOTIFICATION_SOUNDS = {
  [NOTIFICATION_SOUND_TYPES.USER_JOINED]: 'user_joined.mp3',
  [NOTIFICATION_SOUND_TYPES.USER_LEFT]: 'user_left.mp3',
  [NOTIFICATION_SOUND_TYPES.CONFERENCE_INCOMING_MESSAGE]: 'incoming_message.mp3',
  [NOTIFICATION_SOUND_TYPES.WEBINAR_INCOMING_MESSAGE]: 'incoming_message.mp3',
  [NOTIFICATION_SOUND_TYPES.WEBINAR_STARTED]: 'webinar_started.mp3',
  [NOTIFICATION_SOUND_TYPES.WEBINAR_STOPPED]: 'webinar_stopped.mp3',
  [NOTIFICATION_SOUND_TYPES.RECORDING]: 'record.mp3',
  [NOTIFICATION_SOUND_TYPES.NEW_DEVICE_SELECTED]: 'new_device_selected.mp3',
};
