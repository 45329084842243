<template>
  <v-container fluid class="home-container px-5 py-4 px-md-15 py-md-8 overflow-auto">
    <div class="header d-flex justify-space-between align-center">
      <logo></logo>
      <lang-switch />
    </div>
    <div class="d-flex flex-column justify-center align-center">
      <div>
        <img src="@/assets/images/welcome-image.svg" class="w-full welcome-image" :style="{ 'margin-left': $vuetify.breakpoint.smAndUp ? '-40px' : '0' }" />
      </div>

      <v-row v-if="!isGoToConferenceTab" class="mt-16">
        <v-col class="text-right" cols="12" sm="6" md="6">
          <v-btn
            min-width="220"
            color="primary"
            class="text-capitalize"
            :block="$vuetify.breakpoint.mobile"
            :large="$vuetify.breakpoint.smAndUp"
            :class="{ active: true }"
            data-test-id="W_H_GC"
            @click="onJoinClicked"
          >
            <icon left dense icon="link" size="24" class="mr-2 text-capitalize" />
            {{ $t('joinConference') }}
          </v-btn>
        </v-col>
        <v-col class="text-left" cols="12" sm="6" md="6">
          <v-btn
            min-width="220"
            color="secondary"
            :block="$vuetify.breakpoint.mobile"
            :large="$vuetify.breakpoint.smAndUp"
            data-test-id="W_H_CC"
            @click="onCreateClicked"
          >
            <icon left dense icon="plus-circle" size="24" class="mr-2 text-capitalize" />
            {{ $t('createAConference') }}
          </v-btn>
        </v-col>
      </v-row>
      <div v-else class="mt-4 mt-xl-16 join-conference">
        <div class="pl-0">
          <icon class="mr-4" color="secondary" icon="video" />
          <span class="text-capitalize">{{ $t('conferenceName') }}</span>
        </div>
        <text-field
          v-model="conferenceName"
          :label="$t('enterAConferenceName')"
          :error-messages="$t(conferenceNameError)"
          class="mt-5"
          data-test-id="W_H_CN"
          autocomplete="conference-name"
          @keydown.enter.prevent="goToConference()"
        ></text-field>
        <div class="d-flex justify-end flex-wrap">
          <v-btn min-width="140" outlined data-test-id="W_H_GC_C_BTN" :block="$vuetify.breakpoint.xs" @click="$router.push('/')">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            min-width="140"
            color="secondary"
            data-test-id="W_H_GC_BTN"
            :class="{ 'ml-4': $vuetify.breakpoint.smAndUp, 'mt-3': $vuetify.breakpoint.xs }"
            :block="$vuetify.breakpoint.xs"
            @click="goToConference()"
          >
            {{ $t(buttonText) }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import XperMeetLib from 'xpermeet-lib';

import LangSwitch from '@/components/Shared/LangSwitch';
import ENUMS from '@/constants/ENUMS';
import storage from '@/services/storage';
import { createATagAndClick } from '@/helpers/create-element';
import { sendGaEvent } from '@/helpers/gtag';
import { clearSkipPrivateRoomEntrance } from '@/helpers/private-room';

export default {
  name: 'Home',
  components: {
    LangSwitch,
  },
  data() {
    return {
      ENUMS,
      conferenceName: '',
      conferenceNameError: false,
      tab: null,
    };
  },
  computed: {
    ...mapState(['theme']),
    ...mapGetters('Conference', ['remoteCameraControlSupported']),
    isGoToConferenceTab() {
      return this.$route.hash === '#join';
    },
    buttonText() {
      return this.isGoToConferenceTab ? 'goToConferenceCapitalize' : 'createConference';
    },
  },
  created() {
    clearSkipPrivateRoomEntrance();
    storage.setItem('skipPreJoinRoom', '');
    storage.setItem('background', '');
    this.SET_SYSTEM_ERROR(null);
  },
  methods: {
    ...mapMutations(['SET_SYSTEM_ERROR']),
    ...mapActions('Conference', ['generateRoomName', 'validateRoomName', 'fetchUserInfo']),
    onJoinClicked() {
      sendGaEvent('konferansa katıl', 'meet-bizbize');
      this.$router.push('#join');
      this.conferenceNameError = null;
    },
    async onCreateClicked() {
      sendGaEvent('konferans oluştur', 'meet-bizbize');
      const response = await this.generateRoomName();
      const roomName = response.data.room;

      const auth = XperMeetLib.keycloakManager.keycloakInstance.authenticated;
      const settings = { create: true };
      if (auth) {
        const route = {
          name: 'meet',
          params: {
            roomName,
          },
        };
        route.query = settings;
        return this.$router.push(route).catch(() => {});
      } else {
        this.redirectLoginPage(roomName, settings);
      }
    },
    redirectLoginPage(roomName, queryParams = {}) {
      const qs = new URLSearchParams(queryParams).toString();

      const redirectUri = `${window.location.origin}/${roomName}?${qs}`;

      const url = XperMeetLib.keycloakManager.keycloakInstance.createLoginUrl({ redirectUri });

      createATagAndClick(url);
    },
    async goToConference() {
      let roomName = this.conferenceName.replace(/ /g, '');

      if (!roomName) {
        this.conferenceNameError = 'conferenceNameRequired';
        return null;
      }

      const isValid = await this.validateRoomName(roomName);
      if (!isValid) {
        this.conferenceNameError = 'invalidRoomName';
        return null;
      }

      return this.$router
        .push({
          name: 'meet',
          params: {
            roomName,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.home-container {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  align-items: center;

  .welcome-image {
    width: 100%;
    height: 100%;

    @media (max-width: 1600px) {
      max-height: 40vh;
    }
  }

  .header {
    width: 100%;
  }

  .join-advanced-settings-container {
    max-width: 720px;
    display: flex;
    justify-content: center;
  }

  .create-conference {
    width: 720px;
  }
  .join-conference {
    width: 506px;
    margin: auto;
  }
  .content {
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .advanced-settings-title i {
    padding-right: 5px !important;
  }

  @media (max-width: $breakpoint-tablet) {
    .create-conference {
      width: 520px;
    }
    .join-conference {
      width: 520px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .create-conference {
      width: auto;
    }
    .join-conference {
      width: 100%;
    }
  }
}
</style>
