<template>
  <TextField readonly hide-details class="white--text" :value="text" dense>
    <template v-slot:append>
      <span class="cursor-pointer" v-clipboard:copy="copyText" v-clipboard:success="onCopy" v-clipboard:error="onError">
        <icon size="24" class="mt-1" :icon="copyIcon" />
      </span>
    </template>
  </TextField>
</template>

<script>
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'CopyLink',
  props: {
    text: {
      type: String,
    },
    copyText: {
      type: String,
    },
  },
  data() {
    return {
      copyIcon: 'copy',
    };
  },
  methods: {
    onCopy() {
      this.copyIcon = 'check';
      setTimeout(() => {
        this.copyIcon = 'copy';
      }, 2000);
    },
    onError() {
      this.showToastNotification({ body: 'failedToCopyText', config: { type: TOAST_TYPE.WARNING } });
    },
  },
};
</script>
