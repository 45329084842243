var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.svgContent
    ? _c(
        "svg",
        _vm._g(
          {
            style: { color: _vm.getColor },
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: _vm.width,
              height: _vm.width,
              viewBox: `0 0 ${_vm.width} ${_vm.width}`,
              "aria-labelledby": _vm.icon,
              role: "presentation",
            },
          },
          _vm.$listeners
        ),
        [
          _c("g", {
            attrs: {
              "stroke-width": _vm.stroke,
              stroke: "currentColor",
              fill: "currentColor",
            },
            domProps: { innerHTML: _vm._s(_vm.svgContent) },
          }),
        ]
      )
    : _c(
        "v-icon",
        _vm._g(
          _vm._b(
            {
              class: { "spinner-animation": _vm.loading },
              attrs: { dark: "" },
            },
            "v-icon",
            { ..._vm.$props, ..._vm.$attrs },
            false
          ),
          _vm.$listeners
        ),
        [_vm._v(_vm._s(_vm.iconLibName) + " icon-" + _vm._s(_vm.icon))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }