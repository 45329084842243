var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    {
      attrs: {
        popoverClass: _vm.popoverComputedClass,
        placement: _vm.popoverPlacement,
      },
    },
    [
      _vm._t("default"),
      _c(
        "template",
        { slot: "popover" },
        [
          _vm.description
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mb-2 description" },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.description)) + " ")]
              )
            : _vm._e(),
          _vm._t("content"),
          _c(
            "div",
            { staticClass: "d-flex justify-center align-center my-4" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    { name: "close-popover", rawName: "v-close-popover" },
                  ],
                  staticClass: "mr-2",
                  attrs: {
                    "data-test-id": "G-CP-CNF-B",
                    small: "",
                    color: "secondary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(_vm.confirmText)))]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    { name: "close-popover", rawName: "v-close-popover" },
                  ],
                  attrs: {
                    "data-test-id": "G-CP-CNC-B",
                    small: "",
                    color: "error",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(_vm.cancelText)))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }