var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "private-room-entrance" },
    [
      _c(
        "div",
        {
          staticClass:
            "private-room-entrance--header px-5 py-4 px-md-15 py-md-8",
        },
        [
          _c("router-link", { attrs: { to: "/" } }, [_c("Logo")], 1),
          _c("LangSwitch"),
        ],
        1
      ),
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "white", size: 14, width: 2 },
          })
        : _vm.errorText
        ? _c(
            "div",
            { staticClass: "d-flex flex-column justify-center" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "mb-5",
                  attrs: {
                    color: "amber",
                    border: "bottom",
                    outlined: "",
                    shaped: "",
                  },
                },
                [_vm._v(_vm._s(_vm.errorText))]
              ),
              _c("v-btn", { attrs: { color: "secondary", to: "/" } }, [
                _vm._v(_vm._s(_vm.$t("goToHome"))),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "private-room-entrance--content" },
            [
              _vm.pageTypeIsJoin
                ? _c("icon", {
                    staticClass: "icon",
                    attrs: { icon: "blue-t", size: "34" },
                  })
                : _vm._e(),
              _c("div", { staticClass: "title", style: _vm.titleStyle }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm.subTitle
                ? _c("div", { staticClass: "sub-title" }, [
                    _vm._v(_vm._s(_vm.subTitle)),
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "private-room-entrance--content--button",
                  style: _vm.buttonStyle,
                  attrs: { color: "#29847e" },
                  on: { click: _vm.primaryBtnClicked },
                },
                [
                  !_vm.roomOwnerInfo
                    ? _c("icon", {
                        staticClass: "icon",
                        attrs: { icon: "white-t", size: "24" },
                      })
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(_vm.primaryActionText))]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "private-room-entrance--content-separator" },
                [_c("span", [_vm._v(_vm._s(_vm.$t("or")))])]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "private-room-entrance--content--button",
                  style: _vm.buttonStyle,
                  attrs: { color: "secondary" },
                  on: { click: _vm.secondaryBtnClicked },
                },
                [
                  !_vm.roomOwnerInfo
                    ? _c("icon", {
                        staticClass: "icon",
                        attrs: { icon: "plus-circle", size: "24" },
                      })
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(_vm.secondaryActionText))]),
                ],
                1
              ),
            ],
            1
          ),
      _c("div", { staticClass: "private-room-entrance--footer" }, [
        _vm.pageTypeIsJoin || _vm.errorText
          ? _c("div", [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("clickForSetupYourPrivateRoomName", {
                      url: _vm.setupPrivateRoomUrl,
                    })
                  ),
                },
              }),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("clickIfYouDontHaveLicence", {
                      url: _vm.buyLicenceUrl,
                    })
                  ),
                },
              }),
            ])
          : _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("clickForSettingsYourPrivateRoomName", {
                    url: _vm.setupPrivateRoomUrl,
                  })
                ),
              },
            }),
      ]),
      _vm.showMobileJoinModal
        ? _c("JoinMeetingFromMobileModal", {
            attrs: { "room-name": _vm.roomName },
            on: {
              close: function ($event) {
                _vm.disableMobileJoinPage = true
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }