<template>
  <div class="video-grid-layout grid-1">
    <grid-layout
      :responsive="false"
      :layout="localLayout"
      :col-num="colNum"
      :row-height="rowHeight"
      :is-draggable="editEnabled && editMode"
      :is-resizable="false"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="item in localLayout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.userId"
        :static="item.static"
        :class="{
          'grid-item-fullscreen': (isJibri && participantCount === 1) || (getUserById(item.userId) && getUserById(item.userId).fullScreen),
          ['grid-item-size-' + item.w]: true,
          'grid-item-pinned': checkPinUserVideo(item.userId),
        }"
      >
        <video-card
          v-if="getUserById(item.userId)"
          style="margin: 0"
          :avatar-size="item.w <= 3 ? 18 : 48"
          :data-test-user-id="item.userId"
          :class="{ 'edit-mode-video-card': editMode }"
          :user="getUserById(item.userId)"
          :participant-count="participantCount"
          :style="getVideoCardStyle(item)"
          :hideFullScreen="!getLocalUser.isModerator && roomConfig && roomConfig.followModeratorLayout"
          :fullScreen="(isJibri && participantCount === 1) || (getUserById(item.userId) && getUserById(item.userId).fullScreen)"
          :pinned="checkPinUserVideo(item.userId)"
          :hidden="isJibri && getFullScreenUserId && getFullScreenUserId !== user.id"
          @dblclick="setFullScreen(getUserById(item.userId))"
        />
        <div v-if="editMode && getUserById(item.userId)" class="edit-mode-video-container">
          <div class="user-abbreviation text-h3">{{ abbreviation(getUserById(item.userId).displayName) }}</div>
          <div v-if="item.static" class="static-placeholder"></div>
          <div class="d-flex flex-row justify-end edit-mode-buttons">
            <menu-button
              :icon="!item.static ? 'unlock' : 'lock'"
              color="white"
              icon-background-color="darkblue"
              icon-size="12"
              width="24"
              class="card-menu-btn mt-1 mx-0"
              @click.stop="item.static = !item.static"
            ></menu-button>
            <menu-button
              icon="minimize-2"
              color="white"
              icon-background-color="darkblue"
              icon-size="12"
              width="24"
              class="card-menu-btn mt-1 mx-0"
              @click.stop="sizeDown(item)"
            ></menu-button>
            <menu-button
              icon="maximize-2"
              color="white"
              icon-background-color="darkblue"
              icon-size="12"
              width="24"
              class="card-menu-btn mt-1 mx-0"
              @click.stop="sizeUp(item)"
            ></menu-button>
          </div>
        </div>
      </grid-item>
      <div v-if="editEnabled" v-show="!getFullScreenUserId" class="d-flex justify-end">
        <menu-button id="edit-grid-layout" :icon="editMode ? 'save' : 'edit'" color="white" @click="editMode = !editMode" iconBackgroundColor="secondary" />
      </div>
    </grid-layout>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { mapGetters, mapActions, mapState } from 'vuex';
import VideoCard from '@/components/VideoCard/VideoCard';
import { mobileAndTabletCheck } from '@/helpers/detect-browser';
import VueGridLayout from 'vue-grid-layout';
import { getServerConfig } from '@/services/config';

// eslint-disable-next-line
const Item = (layout, colNum, userId) => {
  return {
    x: (layout.length * 3) % (colNum || 12),
    y: layout.length + (colNum || 12), // puts it at the bottom
    w: 3,
    h: 3,
    i: userId,
    static: false,
    userId: userId,
  };
};

export default {
  name: 'VideoGridlayout',
  components: { VideoCard, GridLayout: VueGridLayout.GridLayout, GridItem: VueGridLayout.GridItem },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editMode: false,
      forceChange: false,
      grid: null,
      localLayout: [],
      rowHeight: 30,
      colNum: 24,
      cardSize: {
        width: 400,
        height: 300,
      },
    };
  },
  computed: {
    ...mapState('Conference', ['roomConfig', 'showChatScreen', 'dominantUserId', 'conferenceJoined', 'isJibri']),
    ...mapGetters('Conference', ['getUserById', 'getFullScreenUserId', 'getLocalUser', 'getSharedProperties']),
    videoConstraints() {
      const constraints = {
        lastN: getServerConfig().channelLastN,
        selectedEndpoints: [],
        onStageEndpoints: [],
        defaultConstraints: { maxHeight: 480 },
        constraints: {},
      };

      if (this.dominantUserId) {
        constraints.onStageEndpoints.push(this.dominantUserId);
        constraints.constraints[this.dominantUserId] = { maxHeight: 1080 };
      }

      if (this.getFullScreenUserId) {
        constraints.constraints[this.getFullScreenUserId] = { maxHeight: 1080 };
        constraints.onStageEndpoints.push(this.getFullScreenUserId);
      }

      return constraints;
    },
    participantCount() {
      return this.users?.length || 0;
    },
    editEnabled() {
      return !this.getSharedProperties.includes('layoutType') || this.getLocalUser.isModerator;
    },
    isMobile() {
      return mobileAndTabletCheck();
    },
  },
  mounted() {
    if (this.conferenceJoined) {
      this.setReceiverConstraints(this.videoConstraints);
    }
    window.addEventListener('resize', this.debouncedScreenSize);
    this.setRowHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedScreenSize);
  },
  methods: {
    ...mapActions('Conference', ['updateRoomLayout', 'setLastN', 'setReceiverConstraints']),
    getVideoCardStyle(item) {
      if (this.checkPinUserVideo(item.userId)) {
        return {};
      } else if (this.getUserById(item.userId).fullScreen) {
        return {
          '--card-width': `100vw`,
          '--card-height': '100%',
        };
      }
      return {
        '--card-width': `${this.cardSize.width * item.w - 10}px`,
        '--card-height': '100%',
      };
    },
    abbreviation(name) {
      let str = name.toString();
      const words = str.split(' ');
      return words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
    },
    debouncedScreenSize() {
      this.setRowHeight();
    },
    setRowHeight: debounce(function () {
      const containerWidth = document.querySelector('.vue-grid-layout').getBoundingClientRect().width;
      const boxWidth = Math.floor(containerWidth / this.colNum);

      // Calculate 16 ÷ 9 ratio
      const boxHeight = Math.round((boxWidth * 9) / 16);
      this.rowHeight = boxHeight;

      this.cardSize = {
        width: boxWidth,
        height: boxHeight,
      };
    }, 200),
    setFullScreen(user) {
      if (this.getLocalUser.isModerator || !this.roomConfig?.followModeratorLayout) {
        this.$emit('fullscreen', user);
      }
    },
    sizeUp(item) {
      if (item.x + item.w >= this.colNum) {
        return;
      }
      const layout = JSON.parse(JSON.stringify(this.localLayout));
      const selectedItem = layout.find(i => i.i === item.i);
      selectedItem.w++;
      selectedItem.h++;
      this.$set(this, 'localLayout', layout);
    },
    sizeDown(item) {
      if (item.w === 2) {
        return;
      }
      const layout = JSON.parse(JSON.stringify(this.localLayout));
      const selectedItem = layout.find(i => i.i === item.i);
      selectedItem.w--;
      selectedItem.h--;
      this.$set(this, 'localLayout', layout);
    },
    addMissingUsers() {
      this.users.forEach(user => {
        if (!this.localLayout.some(item => item.userId === user.id)) {
          this.localLayout.push(Item(this.localLayout, this.colNum, user.id));
        }
      });
    },
    checkPinUserVideo(userId) {
      return (
        !this.isMobile &&
        !this.isJibri &&
        this.getFullScreenUserId &&
        !this.getUserById(this.getFullScreenUserId).screenSharing &&
        userId === this.getLocalUser.id &&
        this.getFullScreenUserId !== userId
      );
    },
  },
  watch: {
    dominantUserId() {
      if (this.dominantUserId && !this.getFullScreenUserId) {
        this.setReceiverConstraints(this.videoConstraints);
      }
    },
    getFullScreenUserId() {
      if (this.getFullScreenUserId) {
        this.setReceiverConstraints(this.videoConstraints);
      } else {
        this.setReceiverConstraints(this.videoConstraints);
      }
    },
    showChatScreen() {
      this.setRowHeight();
      setTimeout(() => {
        this.setRowHeight();
      }, 200);
    },
    participantCount: {
      handler: function () {
        this.addMissingUsers();

        this.$nextTick(() => {
          this.localLayout.forEach(item => {
            if (!this.users.some(user => user.id === item.userId)) {
              const index = this.localLayout.findIndex(l => l.userId === item.userId);
              this.$delete(this.localLayout, index);
            }
          });
        });
      },
      immediate: true,
    },
    localLayout: {
      handler: debounce(function () {
        if (!isEqual(this.localLayout, this.layout) && !this.forceChange) {
          this.updateRoomLayout({ layout: this.localLayout });
        }
        if (this.forceChange) {
          this.forceChange = false;
        }
      }, 2000),
      immediate: true,
      deep: true,
    },
    layout: {
      handler: function () {
        if (this.layout && Array.isArray(this.layout)) {
          this.forceChange = true;
          this.localLayout = JSON.parse(JSON.stringify(this.layout));

          this.addMissingUsers();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.video-grid-layout {
  position: relative;
  width: calc(100% - 20px);
  margin: auto 10px;

  .grid-item-fullscreen {
    transform: none !important;
  }
  .grid-item-pinned {
    transform: none !important;
  }
  .edit-mode-video-card {
    pointer-events: none;
  }

  .edit-mode-video-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--v-secondary-25) 1px, transparent 1%) center, linear-gradient(var(--v-secondary-25) 1px, transparent 1%) center,
      #a799cc50;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    background-size: 22px 22px;
    z-index: 15;
    .static-placeholder {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #680204;
      opacity: 0.5;
      pointer-events: none;
    }

    .user-abbreviation {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      pointer-events: none;
      display: none;
    }

    .user-displayname {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
    .edit-mode-buttons {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }

  .grid-item-size-1 {
    .user-displayname {
      font-size: 0.5rem !important;
    }
    .user-abbreviation {
      font-size: 1rem !important;
    }
  }
}
</style>
