var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-settings pl-6 pr-3" },
    [
      _c("v-col", { staticClass: "profile-settings-content pt-0 pb-0" }, [
        _c("div", { staticClass: "mb-5 ml-2 profile-settings-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("profileSettings")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "avatar-username-container align-center d-flex" },
          [
            _c("avatar-uploader", {
              staticClass: "mr-6 mb-2",
              attrs: { localDisplayName: _vm.localDisplayName },
            }),
            _c("text-field", {
              staticClass: "mb-2",
              attrs: {
                "data-test-id": "M-S-PS-UN",
                disabled: !_vm.displayNameChangeable,
                label: _vm.$t("userName"),
                "error-messages": _vm.errors,
                "hide-details": "",
              },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
              model: {
                value: _vm.localDisplayName,
                callback: function ($$v) {
                  _vm.localDisplayName = $$v
                },
                expression: "localDisplayName",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }