var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "start-muted-policy-settings" },
    [
      _c(
        "v-col",
        {
          staticClass: "py-0 mt-4",
          attrs: {
            cols: "12",
            md: _vm.sizeProp.middle,
            sm: _vm.sizeProp.small,
          },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              [
                _c("icon", {
                  attrs: { color: "secondary", icon: "mic-outline" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "advanced-settings-title pa-0",
                class: _vm.classProp,
              },
              [
                _c("div", { staticClass: "ml-3 mb-n1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("audioConfig")) + " "),
                ]),
                _c("checkbox", {
                  staticClass: "muted-policy-checkbox ml-1",
                  attrs: {
                    color: "white",
                    "hide-details": "",
                    disabled: _vm.disabled,
                    label: _vm.$t("startConferenceMuted"),
                    "data-test-id": "W_SMPS_AS_0",
                  },
                  model: {
                    value: _vm.settings.audioMuted,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "audioMuted", $$v)
                    },
                    expression: "settings.audioMuted",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "py-0 mt-4",
          attrs: {
            cols: "12",
            md: _vm.sizeProp.middle,
            sm: _vm.sizeProp.small,
          },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              [
                _c("icon", {
                  attrs: { color: "secondary", icon: "video-outline" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "advanced-settings-title pa-0",
                class: _vm.classProp,
              },
              [
                _c("div", { staticClass: "ml-3 mb-n1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("videoConfig")) + " "),
                ]),
                _c("checkbox", {
                  staticClass: "muted-policy-checkbox",
                  attrs: {
                    color: "white",
                    "hide-details": "",
                    disabled: _vm.disabled,
                    label: _vm.$t("startConferenceVideoMuted"),
                    "data-test-id": "W_SMPS_AS_1",
                  },
                  model: {
                    value: _vm.settings.videoMuted,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "videoMuted", $$v)
                    },
                    expression: "settings.videoMuted",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.remoteCameraControlSupported
        ? _c(
            "v-col",
            {
              staticClass: "py-0 mt-4",
              attrs: {
                cols: "12",
                md: _vm.sizeProp.middle,
                sm: _vm.sizeProp.small,
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  [_c("icon", { attrs: { color: "secondary", icon: "user" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "advanced-settings-title pa-0",
                    class: _vm.classProp,
                  },
                  [
                    _c("div", { staticClass: "ml-3 mb-n1" }, [
                      _vm._v(" " + _vm._s(this.$t("moderatorConfig")) + " "),
                    ]),
                    _c("checkbox", {
                      staticClass: "muted-policy-checkbox",
                      attrs: {
                        color: "white",
                        "hide-details": "",
                        value: _vm.moderatorControlValue,
                        disabled: _vm.isModeratorControlDisabled,
                        label: _vm.$t("moderatorControl"),
                        "data-test-id": "W_SMPS_AS_2",
                      },
                      model: {
                        value: _vm.settings.moderatorControl,
                        callback: function ($$v) {
                          _vm.$set(_vm.settings, "moderatorControl", $$v)
                        },
                        expression: "settings.moderatorControl",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }