<template>
  <v-popover :open="showPopover">
    <div class="bar-chart" @mouseenter="showPopover = true" @mouseleave="showPopover = false">
      <div class="bars" :class="{ [connectionIcon]: true }">
        <div class="first-bar"></div>
        <div class="second-bar"></div>
        <div class="third-bar"></div>
        <div class="fourth-bar"></div>
      </div>
    </div>
    <table style="font-size: 12px;" slot="popover">
      <tr v-for="(stat, key) in stats" :key="key">
        <td>{{ $t(`stats.${key}`) }}:</td>
        <td>{{ statText(stat) }}</td>
      </tr>
    </table>
  </v-popover>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Stats',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  computed: {
    ...mapState('Stats', ['localStats', 'remoteStats']),
    ...mapGetters('Conference', ['getLocalUser']),
    isLocalUser() {
      return this.getLocalUser.id === this.userId;
    },
    connectionIcon() {
      const quality = this.statsObject?.connectionQuality;
      if (quality < 33) {
        return 'signal-2';
      } else if (quality < 66) {
        return 'signal-3';
      } else {
        return 'signal-4';
      }
    },
    statsObject() {
      return this.isLocalUser ? this.localStats : this.remoteStats[this.userId];
    },
    stats() {
      if (this.isLocalUser) {
        return {
          connection: this.connection(this.statsObject?.connectionQuality),
          bitRate: this.bitRate(this.statsObject?.bitrate),
          packetLoss: this.packetLoss(this.statsObject?.packetLoss),
          resolution: this.resolution(this.statsObject?.resolution[this.userId], this.statsObject?.maxEnabledResolution),
          frameRate: this.frameRate(this.statsObject?.framerate[this.userId]),
          remoteIp: this.getIp(this.statsObject?.transport[0]?.ip),
          remotePort: this.getPort(this.statsObject?.transport[0]?.ip),
          localIp: this.getIp(this.statsObject?.transport[0]?.localip),
          localPort: this.getPort(this.statsObject?.transport[0]?.localip),
          p2p: this.statsObject?.transport[0]?.p2p,
        };
      } else {
        return {
          connection: this.connection(this.statsObject?.connectionQuality),
          bitRate: this.bitRate(this.statsObject?.bitrate),
          packetLoss: this.packetLoss(this.statsObject?.packetLoss),
          resolution: this.resolution(this.localStats?.resolution?.[this.userId], this.statsObject?.maxEnabledResolution),
          frameRate: this.frameRate(this.localStats?.framerate[this.userId]),
        };
      }
    },
  },
  methods: {
    statText(stat) {
      if (stat === true || stat === false) {
        return this.$t(stat.toString());
      } else if (stat) {
        return stat;
      } else {
        return '-';
      }
    },
    connection(quality) {
      if (quality < 33) {
        return this.$t('quality.poor');
      } else if (quality < 66) {
        return this.$t('quality.nonOptimal');
      } else {
        return this.$t('quality.good');
      }
    },
    getIp(text) {
      if (!text || !text.toString().trim()) {
        return '-';
      }
      const ipArr = text.split(':');
      return ipArr.length ? ipArr[0] : '-';
    },
    getPort(text) {
      if (!text || !text.toString().trim()) {
        return '-';
      }
      const ipArr = text.split(':');
      return ipArr.length ? ipArr[1] : '-';
    },
    resolution(resolution, maxEnabledResolution) {
      let resolutionString =
        Object.keys(resolution || {})
          .map(ssrc => {
            const { width, height } = resolution[ssrc];

            return `${width}x${height}`;
          })
          .join(', ') || 'N/A';

      if (maxEnabledResolution && maxEnabledResolution < 720) {
        const maxEnabledResolutionTitle = this.$t('stats.maxEnabledResolution');
        resolutionString += ` (${maxEnabledResolutionTitle} ${maxEnabledResolution}p)`;
      }

      return resolutionString;
    },
    frameRate(framerate) {
      const frameRateString =
        Object.keys(framerate || {})
          .map(ssrc => framerate[ssrc])
          .join(', ') || 'N/A';

      return frameRateString;
    },
    bitRate(bitRateObject) {
      if (!bitRateObject) {
        return '';
      }
      const { upload, download } = bitRateObject;
      return `↓ ${download ? `${download} Kbps` : 'N/A'} / ↑ ${upload ? `${upload} Kbps` : 'N/A'}`;
    },
    packetLoss(packetLossObject) {
      if (!packetLossObject) {
        return '';
      }
      const { upload, download } = packetLossObject;
      return `↓ ${download === null ? 'N/A' : `${download}%`} / ↑ ${upload === null ? 'N/A' : `${upload}%`}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.bar-chart {
  background-color: var(--v-darkblue-80);
  width: 20px;
  height: 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .bars {
    display: flex;
    align-items: baseline;
    .first-bar,
    .second-bar,
    .third-bar,
    .fourth-bar {
      width: 2px;
      background: var(--v-success-base);
      border-radius: 8px;
      margin: 0 1px 0 0;
    }
    .first-bar {
      height: 2px;
    }
    .second-bar {
      height: 4px;
    }
    .third-bar {
      height: 6px;
    }
    .fourth-bar {
      height: 8px;
    }
    &.signal-3 {
      .fourth-bar {
        display: none;
      }
    }
    &.signal-2 {
      .fourth-bar,
      .third-bar {
        display: none;
      }
    }
  }
}
</style>
