<template>
  <div class="lobby-premeeting-screen">
    <LobbyJoinModal :is-response-waiting="isResponseWaiting" />
  </div>
</template>

<script>
import LobbyJoinModal from '@/components/Lobby/Modal/LobbyJoinModal';

export default {
  name: 'LobbyPreMeeting',
  components: { LobbyJoinModal },
  props: {
    isResponseWaiting: Boolean,
  },
};
</script>
