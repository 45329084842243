<template>
  <Modal
    show-actions
    :confirm-button-text="$t('send')"
    :cancel-button-text="$t('cancel')"
    :title="$t('meetingRequest')"
    persistent
    :auto-close="false"
    :confirm-button-disabled="requestLoading"
    @close="onModalClose"
  >
    <div class="meeting-request-container">
      <div class="text-center mb-10 opacity-80">{{ $t('meetingRequestSubtitle') }}</div>
      <v-form autocomplete="off">
        <v-radio-group v-model="currentMeeting" row>
          <v-radio :value="true" color="white" :label="$t('forCurrentMeeting')" hide-details></v-radio>
          <v-radio :value="false" color="white" :label="$t('forNewMeeting')" hide-details></v-radio>
        </v-radio-group>
        <v-row align="center">
          <v-col xs="12" sm="12" md="12">
            <CopyLink :text="getConferenceUrl" :copy-text="getConferenceUrl" />

            <h3 class="opacity-90">
              {{ currentMeeting ? $t('conferenceInformation') : $t('newConference') }}
            </h3>
            <text-field
              class="pb-4"
              hide-details
              :label="$t('conferenceName')"
              :placeholder="$t('enterAConferenceName')"
              :value="getRoomName"
              readonly
            ></text-field>
            <text-field
              class="modal-inputs pb-5"
              hide-details
              :label="$t('enterAConferencePassword')"
              :type="showConferencePassword ? 'text' : 'password'"
              :append-icon="showConferencePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConferencePassword = !showConferencePassword"
              v-model="meetingPassword"
              name="new-password"
              autocomplete="new-password"
            ></text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-alert class="opacity-90" dense type="info" icon="feather icon-info">
              {{ $t('LockRoomAlert') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row ref="participantInformation">
          <v-col xs="12" sm="12" md="12">
            <h3 class="opacity-90">{{ $t('participantInformation') }}</h3>
            <template v-if="showEmailAutoComplete">
              <v-autocomplete
                autocomplete="email-list"
                :items="emailList"
                v-model="selectedParticipantEmails"
                :label="$t('participantsEmailAddress')"
                :search-input.sync="emailSearchValue"
                hide-details
                outlined
                deletable-chips
                multiple
                small-chips
                attach
                hide-selected
                class="primary autocomplete-custom"
                :hide-no-data="!emailList.length"
                @blur="onBlurAutoComplete"
                @keydown.enter.space.tab.stop="onEnterEmail"
              >
                <template slot="no-data">
                  <div v-if="emailListSearching" class="autocomplete-data">{{ $t('searching') }}</div>
                  <div v-else-if="!emailListSearching" class="autocomplete-data"></div>
                </template>
              </v-autocomplete>
            </template>
            <template v-else>
              <v-combobox
                v-model="selectedParticipantEmails"
                :items="selectedParticipantEmails"
                :label="$t('participantsEmailAddress')"
                color="white"
                class="primary autocomplete-custom"
                hide-details
                outlined
                deletable-chips
                multiple
                small-chips
                attach
                hide-selected
                :hide-no-data="!emailList.length"
              >
                <template slot="no-data">
                  <div v-if="emailListSearching" class="autocomplete-data">{{ $t('searching') }}</div>
                  <div v-else-if="!emailListSearching" class="autocomplete-data">
                    {{ $t('noData') }}
                  </div>
                </template>
              </v-combobox>
            </template>
            <v-alert v-if="emailFieldError" class="mt-4" type="error">
              {{ $t('typeAnEmailAddressAndPressEnter') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row align="baseline" v-if="!currentMeeting">
          <v-col xs="12" sm="12" md="12">
            <v-menu
              v-model="startDatePicker"
              :disabled="currentMeeting"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <text-field
                  class="w-full"
                  v-model="displayedStartDate"
                  :label="$t('startDate')"
                  :placeholder="displayedStartDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></text-field>
              </template>
              <v-date-picker v-model="startDate" :min="minStartDate" @input="startDatePicker = false"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col xs="12" sm="6" md="6">
            <v-text-field
              v-model="startTime"
              class="start-time-input"
              :label="$t('startTime')"
              placeholder="- - : - -"
              :disabled="currentMeeting"
              v-mask="timeMask"
              name="start-time"
              autocomplete="false"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col xs="12" sm="6" md="6">
            <v-text-field
              v-model="duration"
              class="duration-input"
              :label="$t('duration')"
              type="number"
              min="0"
              :disabled="currentMeeting"
              name="duration"
              placeholder="- - -"
              autocomplete="false"
              @blur="onDurationBlur"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="3">
            <span style="font-size:13px">{{ durationText }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vue-editor class="vue-editor" :editorToolbar="htmlEditorProperty" v-model="htmlBody"></vue-editor>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="12" md="12">
            <div class="d-flex align-center">
              <label>{{ $t('shareParticipantListWithOthers') }}</label>
              <v-switch class="my-0 pl-4" color="success" v-model="shareParticipantListWithOthers" :hide-details="true"></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { timeMask } from '@/helpers/mask';
import { validateEmail } from '@/helpers/validation';
import CopyLink from '@/components/Shared/CopyLink';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'MeetingRequest',
  components: {
    CopyLink,
    VueEditor,
  },
  data() {
    return {
      timeMask,
      generatedRoomName: '',
      conferencePassword: '',
      showConferencePassword: false,
      errorMessage: null,
      email: null,
      meetingDuration: 1,
      startDate: null,
      duration: null,
      startDatePicker: false,
      endDatePicker: false,
      startTime: null,
      endTime: null,
      startTimePicker: false,
      endTimePicker: false,
      currentMeeting: true,
      meetingPassword: null,
      htmlBody: null,
      requestLoading: false,
      shareParticipantListWithOthers: false,
      htmlEditorProperty: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          {
            list: 'bullet',
          },
        ],
        ['link'],
      ],
      emailSearchValue: '',
      emailList: [],
      selectedParticipantEmails: [],
      emailListSearching: false,
      validateForm: true,
      emailFieldError: false,
    };
  },
  computed: {
    ...mapState('Conference', ['roomName', 'user']),
    ...mapGetters('Conference', ['getLocalUser']),
    displayedStartDate() {
      return this.startDate ? dayjs(this.startDate).format('DD MMMM, dddd YYYY') : dayjs().format('DD MMMM, dddd YYYY');
    },
    showEmailAutoComplete() {
      return this.getLocalUser.modules?.meetingRequest?.emailAutoComplete;
    },
    getConferenceUrl() {
      return `${location.protocol}//${location.host}/${this.getRoomName}`;
    },
    durationText() {
      const duration = this.duration || 0;
      const hour = Math.floor(duration / 60);
      const minute = duration % 60;

      const hourText = hour > 1 ? this.$t('hours') : this.$t('hour');
      const minuteText = minute > 1 ? this.$t('minutes') : this.$t('minute');

      let durationText = '';

      if (minute > 0) {
        durationText += `${minute} ${minuteText}`;
      }
      if (hour > 0) {
        durationText = `${hour} ${hourText} ${durationText}`;
      }

      return durationText;
    },
    minStartDate() {
      return dayjs(new Date()).format('YYYY-MM-DD');
    },
    startDateTime() {
      const sDate = this.startDate || dayjs().format('YYYY-MM-DD');
      const startDate = dayjs(`${sDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm');
      return startDate;
    },
    endDateTime() {
      const endDate = dayjs(`${this.endDate} ${this.endTime}`, 'YYYY-MM-DD HH:mm');
      return endDate;
    },
    participantsEmails() {
      return this.selectedParticipantEmails.join(', ');
    },
    getRoomName() {
      return this.currentMeeting ? this.roomName : this.generatedRoomName;
    },
  },
  created() {
    this.generateRoomName()
      .then(response => {
        this.generatedRoomName = response.data.room;
      })
      .catch(() => {
        this.errorMessage = 'generateRoomNameError';
      });
  },
  methods: {
    validateEmail,
    ...mapActions('Conference', ['sendMeetingRequest', 'generateRoomName', 'fetchUsers']),
    ...mapActions('Notification', ['showToastNotification']),

    onDurationBlur() {
      if (this.duration < 0) {
        this.duration = 0;
      }
    },
    onEnterEmail() {
      const searchValueArr = this.emailSearchValue.split(' ');
      searchValueArr.forEach((val, index) => {
        if (!validateEmail(val)) {
          return;
        }
        if (!this.emailList.includes(val)) {
          this.emailList.push(val);
        }
        if (!this.selectedParticipantEmails.includes(val)) {
          this.selectedParticipantEmails.push(val);
        }
        searchValueArr.splice(index, 1);
      });
      this.emailSearchValue = searchValueArr.join('');
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.send();
      } else {
        this.close();
      }
    },
    async send() {
      /*
      Sender email has to be defined in customer_config.js file.
       */

      if (!this.$customerConfig.mail?.meetingRequestSender) {
        this.showToastNotification({ body: 'senderEmailCanNotBeNull', config: { type: TOAST_TYPE.WARNING } });
      }

      this.email = this.$customerConfig.mail.meetingRequestSender;
      let sDate = this.startDateTime;
      this.validateForm = true;
      this.emailFieldError = false;
      let pEmail = this.participantsEmails;
      let mName = this.getRoomName?.replace(/ /g, '') || '';
      let isCurrent = this.currentMeeting;
      let meetingPassword = this.meetingPassword || '';
      let sDateSeconds = 0;
      let eDateSeconds = 0;
      let meetingUrl = null;
      let meetinName = '';
      let htmlBody = this.htmlBody ? this.htmlBody : '';
      let shareParticipantList = this.shareParticipantListWithOthers;

      if (!pEmail.length) {
        this.emailFieldError = true;
        this.validateForm = false;
      }
      if (!isCurrent) {
        meetinName = location.origin + '/' + mName;
        meetingUrl = meetinName;
        if (!sDate) {
          this.showToastNotification({ body: 'meetingRequestErrors.startDateCantBeBlank', config: { type: TOAST_TYPE.ERROR } });
          return;
        }
        if (!this.startTime) {
          this.showToastNotification({ body: 'meetingRequestErrors.startTimeCantBeBlank', config: { type: TOAST_TYPE.ERROR } });
          return;
        }
        if (!this.duration) {
          this.showToastNotification({ body: 'meetingRequestErrors.durationCantBeBlank', config: { type: TOAST_TYPE.ERROR } });
          return;
        }
        if (!this.startDate) {
          this.startDate = dayjs().format('YYYY-MM-DD');
        }
        const selectedHour = dayjs(`${dayjs(this.startDate).format('YYYY-MM-DD')} ${this.startTime}`);
        const now = dayjs().format('YYYY-MM-DD HH:mm');
        if (selectedHour.diff(now) <= 0) {
          this.showToastNotification({ body: 'meetingRequestErrors.enterAValidTime', config: { type: TOAST_TYPE.ERROR } });
          return;
        }
        sDate = new Date(sDate);
        sDateSeconds = sDate.getTime() / 1000;

        let eDate = new Date(sDate.getTime() + this.duration * 60000);
        eDateSeconds = eDate.getTime() / 1000;
      } else {
        meetingUrl = window.location.href;
      }
      const razorObject = {
        IsCurrentMeetingAttr: `${isCurrent}`,
        PasswordAttr: meetingPassword,
        ImageLinkAttr: this.$customerConfig?.system?.modules?.invitationMailLogoUrl || 'https://bizbize.live/images/logo.png',
        MeetingUrlAttr: meetingUrl,
        HtmlBodyAttr: htmlBody,
        ArrengerEmail: this.user.email,
      };
      const payload = {
        subject: mName,
        participantsEmail: pEmail,
        addCallendar: !isCurrent,
        calendarTemplate: {
          startDate: { Seconds: sDateSeconds, Nanos: 0 },
          endDate: { Seconds: eDateSeconds, Nanos: 0 },
          shareParticipantList: shareParticipantList,
          summary: mName,
        },
        shareParticipantList: shareParticipantList,
        templateKey: 'MeetingRequest',
        razorObj: JSON.stringify(razorObject),
      };

      if (this.validateForm) {
        this.requestLoading = true;
        const res = await this.sendMeetingRequest(payload);
        if (res) {
          this.close();
        }
      } else {
        this.$refs.participantInformation.scrollIntoView({ behavior: 'smooth' });
      }
      this.requestLoading = false;
    },
    close() {
      this.dialog = false;
      this.$modal?.close();
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val !== null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    onBlurAutoComplete() {
      if (this.emailSearchValue && validateEmail(this.emailSearchValue.trim())) {
        this.onEnterEmail();
      }
    },
  },
  watch: {
    emailSearchValue: {
      handler: debounce(async function() {
        if (this.showEmailAutoComplete) {
          if (this.emailSearchValue && this.emailSearchValue.length > 2) {
            this.emailListSearching = true;
            const users = await this.fetchUsers({ search: this.emailSearchValue });
            this.emailList = [...new Set([...this.selectedParticipantEmails, ...users.map(user => user.email)])];
            this.emailListSearching = false;
          }
        }
      }, 1000),
    },
    currentMeeting() {
      this.startDate = null; // moment(new Date()).format('YYYY-MM-DD');
      // this.startDate = val ? null : dayjs(new Date()).format('YYYY-MM-DD'); // moment(new Date()).format('YYYY-MM-DD');
      this.startTime = null; // moment(new Date()).format('HH:mm');
      this.endDate = null;
      this.endTime = null;
    },
    startTime() {
      if (Date.parse(this.startDate) === Date.parse(this.endDate)) {
        let start = this.startDateTime;
        let end = this.endDateTime;
        if (start.hour() > end.hour()) this.endTime = null;
        if (start.hour() === end.hour() && start.minute() > end.minute()) this.endTime = null;
      }
    },
    startDate(val) {
      this.startTime = '';
      if (Date.parse(val) > Date.parse(this.endDate)) this.endDate = null;
    },
  },
};
</script>

<style lang="scss">
.meeting-request-container {
  color: var(--v-white-base);

  fieldset {
    border: none !important;
  }

  .autocomplete-custom.v-text-field--outlined .v-label {
    color: var(--v-silver-base) !important;
    font-size: 12px;

    &.v-label--active {
      margin-top: 10px;
    }
  }

  .v-select__selections {
    margin-top: 10px;
    .v-chip {
      opacity: 0.9;
      .v-chip__content {
        font-size: 12px;
        font-weight: 400;
        line-height: 27px;
        .v-icon {
          color: var(--v-light-gray-base);
        }
      }
    }
    input {
      caret-color: var(--v-white-base);
    }
  }

  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding: 10px 0;
  }

  .text-field-2 {
    .v-input__slot {
      padding-right: 0 !important;
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
    }

    .v-icon {
      font-size: 24px;
      padding: 13px;
      background-color: var(--v-popup-color-base);
      border-radius: 5px;
      opacity: 0.4;
    }

    .v-input__append-inner {
      margin: 0;
    }
  }
  .start-time-input,
  .duration-input {
    .v-label {
      &.primary--text {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

.autocomplete-custom {
  min-height: 52px !important;
  .v-input__icon--append {
    display: none;
  }
}

.autocomplete-data {
  background-color: var(--v-darkblue-80);
}
</style>
