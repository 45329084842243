<template>
  <section v-if="!gdprAccepted" class="gdpr-section" style="z-index: 4">
    <v-container class="d-flex align-center pa-2 justify-center">
      <div class="text-body-2">
        <span>{{ $t('cookiePolicyInformation') }}</span>
        &nbsp;
        <a target="_blank" :href="cookieLink">{{ $t('learnMore') }}</a>
      </div>
      <v-btn data-test-id="gdpr-accept" rounded outlined class="ml-2" small style="background: transparent !important" @click="acceptPolicy">
        {{ $t('accept') }}
      </v-btn>
    </v-container>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'GDPR',
  data() {
    return {
      gdprAccepted: false,
    };
  },
  computed: {
    ...mapState(['lang']),
    cookieLink() {
      return this.lang === 'tr' ? 'https://bizbize.live/cerez-politikasi' : 'https://bizbize.live/en/cookie-policy/';
    },
  },
  created() {
    this.gdprAccepted = Cookies.get('gdprAccepted') || false;
  },
  methods: {
    acceptPolicy() {
      Cookies.set('gdprAccepted', true, { expires: 730 });
      this.gdprAccepted = Cookies.get('gdprAccepted') || false;
    },
  },
};
</script>

<style scoped lang="scss">
.gdpr-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--v-primary-base);

  a {
    color: var(--v-link-base);
  }
}
</style>
