import ENUMS from '@/constants/ENUMS';
const { userAgent, maxTouchPoints, platform } = navigator;

export const getOsName = () => {
  if (userAgent.match(/Android/i)) {
    return ENUMS.OS.ANDROID;
  }

  if (userAgent.match(/iP(ad|hone|od)/i) || (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))) {
    return ENUMS.OS.IOS;
  }

  if (userAgent.match(/Mac(intosh| OS X)/i)) {
    return ENUMS.OS.MAC;
  }

  if (userAgent.match(/Windows/i)) {
    return ENUMS.OS.WINDOWS;
  }

  return ENUMS.OS.UNKNOWN;
};

export const isMobile = () => {
  let osName = getOsName();
  return osName === ENUMS.OS.ANDROID || osName === ENUMS.OS.IOS;
};

export const isMacos = () => getOsName() === ENUMS.OS.MAC || getOsName() === ENUMS.OS.IOS;

export const isIOS = () => getOsName() === ENUMS.OS.IOS;

export const isAndroid = () => getOsName() === ENUMS.OS.ANDROID;
