<template>
  <div
    class="menu-button-wrapper ml-1"
    :class="{ disabled: disabled }"
    :title="disableTooltip"
    v-tooltip="tooltipText"
    v-click-outside="onClickOutside"
    @mouseover="onMouseOver('1')"
    @mouseleave="onMouseLeave(null)"
  >
    <v-menu :content-class="dropdownClass" off transition="slide-y-transition" offset-y :top="top" v-model="openDropdown" v-show="!disabled">
      <template v-slot:activator="{ on, attrs }">
        <div style="position:relative;">
          <v-badge
            v-tooltip="!!badgeWebinarContent ? $t('unreadWebinarMessageCount', { count: badgeWebinarContent }) : ''"
            top
            :left="left"
            color="secondary"
            :content="badgeWebinarContent"
            :value="!!badgeWebinarContent"
            bordered
            offset-x="20"
            offset-y="14"
          >
            <v-badge
              v-tooltip="!!badgeContent ? $t('unreadMessageCount', { count: badgeContent }) : ''"
              bottom
              :left="left"
              :content="badgeContent"
              :value="!!badgeContent"
              bordered
              offset-x="20"
              offset-y="14"
            >
              <IconButton
                v-if="!text"
                :icon="icon"
                :color="color"
                :loading="loading"
                :iconSize="iconSize"
                :width="width"
                :background-color="iconBackgroundColor"
                :rounded="rounded"
                :disabled="clickDisabled"
                @click.stop="onButtonClicked"
              />
              <v-btn v-else :disabled="clickDisabled" :loading="loading" :color="iconBackgroundColor" :width="width" @click.stop="onButtonClicked">
                <Icon :icon="icon" :color="color" :size="iconSize" />
                <span class="pl-2">{{ text }}</span>
              </v-btn>
            </v-badge>
          </v-badge>

          <IconButton
            v-if="dropdownList && dropdownList.length && !hideCaretDown"
            v-bind="attrs"
            v-on="on"
            class="menu-dropdown-button"
            circle
            icon="caret-down"
            width="20"
            @click.stop="toggleDropdown"
          />
        </div>
      </template>
      <div v-if="showDropdownMenu && dropdownList && dropdownList.length && !dropdownList.every(d => d.notRender)">
        <menu-button-list
          v-for="(list, key) in dropdownList"
          :key="key"
          :item="list"
          :index="key"
          @onMouseOver="onMouseOver"
          @onMouseLeave="onMouseLeave"
          @onChildItemClicked="onChildItemClicked"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/Shared/Icon';
import IconButton from '@/components/Shared/IconButton';
import MenuButtonList from '@/components/Shared/MenuButtonList';

export default {
  name: 'MenuButton',
  components: {
    IconButton,
    Icon,
    MenuButtonList,
  },
  props: {
    dropdownList: {
      type: Array,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    useInnerModel: {
      type: Boolean,
      default: false,
    },
    showMenuOnHover: {
      type: Boolean,
      default: false,
    },
    badgeContent: {
      type: Number,
      default: null,
    },
    badgeWebinarContent: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickDisabled: {
      type: Boolean,
    },
    disableTooltip: {
      type: [Number, String],
    },
    translateItemLabels: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
    },
    hideCaretDown: {
      type: Boolean,
      default: true,
    },
    iconBackgroundColor: {
      type: String,
      default: 'primary',
    },
    iconSize: {
      type: [String, Number],
    },
    rounded: {
      type: Boolean,
    },
    top: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    dropdownClass: {
      type: String,
      default: '',
    },
    sendOnDropdownStatusChange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDropdown: false,
      hoveredItem: null,
    };
  },
  computed: {
    left() {
      return window.innerWidth < 700;
    },
    showDropdownMenu() {
      if (this.showMenuOnHover && this.$isTablet) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions('Devices', ['changeDevice']),
    toggleDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    onButtonClicked(e) {
      if (!this.disabled) {
        if (this.useInnerModel) {
          this.toggleDropdown();
        }
        this.$emit('click', e);
      }
    },
    onChildItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
      this.$emit('child-item-clicked', item);
    },
    onClickOutside() {
      this.openDropdown = false;
    },
    onMouseOver(value) {
      this.hoveredItem = value;
      if (!this.openDropdown && this.showMenuOnHover && !this.disabled) {
        this.openDropdown = true;
      }
    },
    async onMouseLeave(value) {
      this.hoveredItem = value;
      await this.$nextTick();
      if (this.showMenuOnHover && !this.disabled) {
        setTimeout(() => {
          if (!this.hoveredItem) {
            this.openDropdown = false;
          }
        }, 100);
      }
    },
  },
  watch: {
    openDropdown() {
      if (this.sendOnDropdownStatusChange) {
        this.$emit('changeDropdownStatus', this.openDropdown);
      }
    },
  },
};
</script>

<style lang="scss">
.menu-button-wrapper {
  position: relative;

  .menu-dropdown-button {
    position: absolute;
    bottom: -5px;
    right: -5px;
    z-index: 3;
  }

  &.disabled {
    opacity: 0.5 !important;
    cursor: default !important;
    * {
      cursor: default !important;
    }
  }
}

.v-list {
  .list-type-divider {
    min-height: 0;
    padding: 0;
    border-bottom: solid 1px var(--v-darkblue-lighten3);
    margin: 0 15px;
    height: 0;
  }

  .list-type-text {
    pointer-events: none;
    font-size: 10px;
    white-space: nowrap;
    min-height: 36px;
  }
  .v-list-item {
    width: 100%;
    height: 100%;
    text-align: center;
    .v-list-item__content {
      padding: 0;
      .v-list-item__title {
        display: flex;
        align-items: center;
        .menu-button-child-item {
          min-height: 40px;
          height: 100%;
        }
      }
    }
    cursor: pointer;
  }

  .child-list-item {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &.active {
      background-color: var(--v-darkblue-40);
    }
  }
}
.menu-button-popover {
  cursor: pointer;
  .trigger {
    width: 100%;
  }
}
</style>
