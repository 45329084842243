var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ConfirmModal", {
    attrs: {
      title: _vm.$t(`newDeviceDetected.${_vm.getFirstAddedDevice.kind}`),
      text: _vm.getFirstAddedDevice.label,
      "confirm-text": _vm.$t("newDeviceDetected.change"),
      "cancel-text": _vm.$t("newDeviceDetected.notChange"),
      persistent: "",
    },
    on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }