var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-popover", { attrs: { open: _vm.showPopover } }, [
    _c(
      "div",
      {
        staticClass: "bar-chart",
        on: {
          mouseenter: function ($event) {
            _vm.showPopover = true
          },
          mouseleave: function ($event) {
            _vm.showPopover = false
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "bars", class: { [_vm.connectionIcon]: true } },
          [
            _c("div", { staticClass: "first-bar" }),
            _c("div", { staticClass: "second-bar" }),
            _c("div", { staticClass: "third-bar" }),
            _c("div", { staticClass: "fourth-bar" }),
          ]
        ),
      ]
    ),
    _c(
      "table",
      {
        staticStyle: { "font-size": "12px" },
        attrs: { slot: "popover" },
        slot: "popover",
      },
      _vm._l(_vm.stats, function (stat, key) {
        return _c("tr", { key: key }, [
          _c("td", [_vm._v(_vm._s(_vm.$t(`stats.${key}`)) + ":")]),
          _c("td", [_vm._v(_vm._s(_vm.statText(stat)))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }