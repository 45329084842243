/**
 * @author ahmet.sari
 */
import base64js from 'base64-js';
import { getLogger } from '@jitsi/logger';
import { v4 as uuidv4 } from 'uuid';

import * as JitsiConferenceEvents from '../../JitsiConferenceEvents';
import Deferred from '../util/Deferred';
import Listenable from '../util/Listenable';
import { JITSI_MEET_MUC_TYPE } from '../xmpp/xmpp';

import { RsaUtil } from './RsaUtil';


const logger = getLogger(__filename);

const REQ_TIMEOUT = 5 * 1000;
const E2EE_MESSAGE_TYPE = 'e2ee-messaging';
const E2EE_MESSAGE_TYPES = {
    ERROR: 'error',
    KEY_INFO: 'key-info',
    KEY_INFO_ACK: 'key-info-ack'
};

const E2EEMessagingEvents = {
    PARTICIPANT_KEY_UPDATED: 'e2e.participant_key_updated'
};

// eslint-disable-next-line require-jsdoc
export class E2EEMessaging extends Listenable {
    /**
     * Creates an E2EEMessaging instance for the given conference.
     */
    constructor(conference) {
        super();
        this._conf = conference;
        this._key = undefined;
        this._keyIndex = -1;
        this._reqs = new Map();
        this._pendingKeys = new Map();
        this._active = false;
        this._rsaUtil = new RsaUtil();
        this._masterPassword = undefined;
        this._publicKey = undefined;
        this._privateKey = undefined;
        this._getPublicKeyFunction = undefined;
        this._decryptionFunction = undefined;

        this._conf.on(JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this._onEndpointMessageReceived.bind(this));
        this._conf.on(JitsiConferenceEvents.CONFERENCE_JOINED, this._onConferenceJoined.bind(this));
        this._conf.on(JitsiConferenceEvents.CONFERENCE_LEFT, this._onConferenceLeft.bind(this));
    }

    /**
     * Updates the current participant key.
     *
     * @param {Uint8Array|boolean} key - The new key.
     * @returns {number}
     */
    async updateCurrentKey(key) {
        this._key = key;

        return this._keyIndex;
    }

    /**
     * Enables / disables End-To-End encrypted messaging.
     *
     * @param {boolean} active - whether E2EE messaging should be active or not.
     * @returns {void}
     */
    setActive(active) {
        this._active = active;
    }

    /**
     * sets the function which retrieves public key of given participant
     * @param f
     */
    setPublicKeyFunction(f) {
        this._getPublicKeyFunction = f;
    }

    /**
     * sets the decryption function
     * @param f
     */
    setDecryptionFunction(f) {
        this._decryptionFunction = f;
    }


    /**
     * Decrypts given cipher text and emits the PARTICIPANT_KEY_UPDATED event
     * @param {string} privateKey
     * @param {string} masterPass
     * @param {string} cipherText
     * @param {string} participantId
     * @private
     */
    async _decryptAndEmitKey({privateKey, masterPass, cipherText, participantId}) {
        let decryptedCipher;
        if (this._decryptionFunction){
            decryptedCipher = await this._decryptionFunction(cipherText);
        }else{
            decryptedCipher = await this._rsaUtil.decrypt(privateKey, masterPass, cipherText);
        }
        const json = safeJsonParse(decryptedCipher);

        if (json.key !== undefined && json.keyIndex !== undefined) {
            const key = json.key ? base64js.toByteArray(json.key) : false;
            const keyIndex = json.keyIndex;

            if (key && keyIndex !== undefined) {
                this.eventEmitter.emit(E2EEMessagingEvents.PARTICIPANT_KEY_UPDATED, participantId, key, keyIndex);

                return true;
            }
        }

        return false;
    }

    /**
     * Updates the current participant key and distributes it to all participants in the conference
     * by sending a key-info message.
     *
     * @param {Uint8Array|boolean} key - The new key.
     * @retrns {Promise<Number>}
     */
    async updateKey(key) {
        // Store it locally for new sessions.
        this._key = key;
        this._keyIndex++;

        // Broadcast it.
        const promises = [];

        for (const participant of this._conf.getParticipants()) {
            const pId = participant.getId();
            let publicKey;
            try {
                publicKey = await this._getPublicKeyFunction(participant);
            } catch (e) {
                logger.warn(`Participant ${pId} has not been registered.`);
            }

            if (!publicKey) {
                logger.warn(`Tried to send key to participant ${pId} but we have no session`);

                // eslint-disable-next-line no-continue
                continue;
            }

            const uuid = uuidv4();
            const data = {
                [JITSI_MEET_MUC_TYPE]: E2EE_MESSAGE_TYPE,
                message: {
                    type: E2EE_MESSAGE_TYPES.KEY_INFO,
                    data: {
                        indexIfDisabled: key === false ? this._keyIndex : false,
                        cipherText: await this._encryptKeyInfo(publicKey),
                        uuid
                    }
                }
            };

            const d = new Deferred();

            d.setRejectTimeout(REQ_TIMEOUT);
            d.catch(() => {
                this._reqs.delete(uuid);
            });
            this._reqs.set(uuid, d);
            promises.push(d);

            this._sendMessage(data, pId);
        }

        await Promise.allSettled(promises);

        // TODO: retry failed ones?

        return this._keyIndex;
    }

    /**
     * Internal helper for encrypting the current key information for a given participant.
     *
     * @param {string} publicKey - Participant's public key (base64 encoded).
     * @returns {string} - The encrypted text with the key information.
     * @private
     */
    async _encryptKeyInfo(publicKey) {
        const keyInfo = {};

        if (this._key !== undefined) {
            keyInfo.key = this._key ? base64js.fromByteArray(this._key) : false;
            keyInfo.keyIndex = this._keyIndex;
        }

        return this._rsaUtil.encrypt(publicKey, JSON.stringify(keyInfo));
    }


    /**
     * Check master password calls by e2eencryption.js
     * @param pk
     * @param masterPassword
     * @private
     * @returns {boolean}
     */
    async _checkMasterPassword(pk, masterPassword) {
        return this._rsaUtil.checkIfPassphraseCorrect(pk, masterPassword);
    }

    /**
     * Setter for masterPassword, xpermeet-lib gets masterPassword and set it
     * to _masterPassword param.
     * @param masterPassword
     */
    _setLocalParticipantMasterPassword(masterPassword) {
        console.log(masterPassword);
        this._masterPassword = masterPassword;
    }

    /**
     * Internal helper for getting the master password of the local user.
     * @returns {string} Master password
     * @private
     */
    _getLocalParticipantMasterPassword() {
        // get master key from ui
        return this._masterPassword;
    }


    /**
     * Internal helper for sets the private key of the local user.
     * @public
     */
    _setLocalParticipantPrivateKey(privateKey) {
        // get master key from ui
        // TODO return user's master password
        this._privateKey = privateKey;
    }

    /**
     * Internal helper for getting the private key of the local user.
     * @returns {string} Master password
     * @private
     */
    _getLocalParticipantPrivateKey() {
        // get private key from passwerks set it to object
        // TODO return user's private key
        return this._privateKey;
    }

    /**
     * Main message handler. Handles 1-to-1 messages received from other participants
     * and send the appropriate replies.
     *
     * @private
     */
    async _onEndpointMessageReceived(participant, payload) {
        if (payload[JITSI_MEET_MUC_TYPE] !== E2EE_MESSAGE_TYPE) {
            return;
        }

        if (!payload.message) {
            logger.warn('Incorrectly formatted message');

            return;
        }

        const msg = payload.message;
        const participantId = participant.getId();


        switch (msg.type) {
        case E2EE_MESSAGE_TYPES.ERROR: {
            logger.error(msg.data.error);
            break;
        }
        case E2EE_MESSAGE_TYPES.KEY_INFO: {
            const { cipherText, indexIfDisabled } = msg.data;

            if (indexIfDisabled !== false) { // Participant disabling e2ee
                this.eventEmitter.emit(E2EEMessagingEvents.PARTICIPANT_KEY_UPDATED, participantId, false, indexIfDisabled);
            } else if (this._active) {
                const masterPass = this._getLocalParticipantMasterPassword();
                const privateKey = this._getLocalParticipantPrivateKey();

                if (this._decryptionFunction || (masterPass && privateKey)) {
                    if (await this._decryptAndEmitKey({privateKey, masterPass, cipherText, participantId})) {
                        let publicKey;
                        try {
                            publicKey = await this._getPublicKeyFunction(participant);
                        } catch (e) {
                            logger.warn(`Participant ${participantId} has not been registered.`);
                            break;
                        }


                        // Send ACK.
                        const ack = {
                            [JITSI_MEET_MUC_TYPE]: E2EE_MESSAGE_TYPE,
                            message: {
                                type: E2EE_MESSAGE_TYPES.KEY_INFO_ACK,
                                data: {
                                    cipherText: await this._encryptKeyInfo(publicKey),
                                    uuid: msg.data.uuid
                                }
                            }
                        };

                        this._sendMessage(ack, participantId);
                    }
                } else {
                    logger.error('Could not find private key or master password!');
                }
            }
            break;
        }
        case E2EE_MESSAGE_TYPES.KEY_INFO_ACK: {
            if (this._active) {
                const { cipherText } = msg.data;
                const masterPass = this._getLocalParticipantMasterPassword();
                const privateKey = this._getLocalParticipantPrivateKey();

                if (this._decryptionFunction || (masterPass && privateKey)) {
                    await this._decryptAndEmitKey({privateKey, masterPass, cipherText, participantId});
                } else {
                    logger.error('Could not find private key or master password!');
                }
            }
            const d = this._reqs.get(msg.data.uuid);

            if (d){
                this._reqs.delete(msg.data.uuid);
                d.resolve();
            }
            break;
        }
        }

    }

    /**
     * Handles the conference joined event.
     *
     * @private
     */
    async _onConferenceJoined() {
        // TODO ?
    }

    /**
     * Handles leaving the conference, cleaning up.
     *
     * @private
     */
    async _onConferenceLeft() {
        // TODO ?
    }

    /**
     * Builds and sends an error message to the target participant.
     *
     * @param {JitsiParticipant} participant - The target participant.
     * @param {string} error - The error message.
     * @returns {void}
     */
    _sendError(participant, error) {
        const pId = participant.getId();
        const err = {
            [JITSI_MEET_MUC_TYPE]: E2EE_MESSAGE_TYPE,
            message: {
                type: E2EE_MESSAGE_TYPES.ERROR,
                data: {
                    error
                }
            }
        };

        this._sendMessage(err, pId);
    }

    /**
     * Internal helper to send the given object to the given participant ID.
     * This function merely exists so the transport can be easily swapped.
     * Currently messages are transmitted via XMPP MUC private messages.
     *
     * @param {object} data - The data that will be sent to the target participant.
     * @param {string} participantId - ID of the target participant.
     */
    _sendMessage(data, participantId) {
        this._conf.sendMessage(data, participantId);
    }
}

E2EEMessaging.events = E2EEMessagingEvents;

/**
 * Helper to ensure JSON parsing always returns an object.
 *
 * @param {string} data - The data that needs to be parsed.
 * @returns {object} - Parsed data or empty object in case of failure.
 */
function safeJsonParse(data) {
    try {
        return JSON.parse(data);
    } catch (e) {
        return {};
    }
}
