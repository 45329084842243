var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "youtube-video-wrapper" },
    [
      _c("youtube", {
        ref: "youtube",
        staticClass: "youtube-video-player",
        class: { "disable-interaction": !_vm.controls },
        attrs: {
          "video-id": _vm.videoId,
          width: _vm.width,
          height: _vm.height,
          "player-vars": _vm.playerVars,
        },
        on: {
          ready: _vm.onReady,
          ended: _vm.onEnded,
          playing: _vm.onPlaying,
          paused: _vm.onPaused,
          buffering: _vm.onBuffering,
          cued: _vm.onCued,
          error: _vm.onError,
        },
      }),
      !_vm.isPlaying
        ? _c("div", [
            _c(
              "div",
              { staticClass: "play-icon", on: { click: _vm.playVideo } },
              [
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: {
                          "border-radius": "20px",
                          "box-shadow": "0px 0px 20px 4px black !important",
                        },
                        attrs: { "x-large": "", color: "red", dark: "" },
                      },
                      [
                        _c("v-icon", { attrs: { medium: "" } }, [
                          _vm._v("mdi-play"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }