<template>
  <Modal :title="$t('recordList')" :confirm-button-text="$t('downloadRecords')" :cancel-button-text="$t('close')" @close="onModalClose">
    <div>
      <Tab :tabItems="tabItems" :sharedWith="selectedTab" @onTabClick="selectedTab = $event" class="mb-2"></Tab>

      <div v-if="selectedTab === 'local'">
        <div v-if="!localRecords || localRecords.length === 0" class="pl-4 mb-5 text-center">
          <p class="mt-6">{{ $t('noData') }}</p>
        </div>
        <v-list two-line v-else>
          <v-virtual-scroll :items="localRecords" :item-height="50" height="210">
            <template v-slot="{ item }">
              <RecordListItem :title="item.name" :loading="localRecordDownloading" @download="downloadLocalRecord(item)" />
            </template>
          </v-virtual-scroll>
        </v-list>
      </div>
      <div v-if="selectedTab === 'remote'">
        <v-skeleton-loader v-if="loading" type="list-item-two-line@5"></v-skeleton-loader>
        <div v-else-if="!remoteRecords || remoteRecords.length === 0" class="pl-4 mb-5 text-center">
          <p>{{ $t('recordsDescription') }}</p>
          <p class="mt-6">{{ $t('noData') }}</p>
        </div>

        <v-list two-line v-else>
          <v-virtual-scroll :items="remoteRecords" :item-height="50" height="210">
            <template v-slot="{ item }">
              <RecordListItem
                :title="item.name"
                :subTitle="fileStatus[item.fileKey] ? `%${fileStatus[item.fileKey]}` : ''"
                :loading="!!fileStatus[item.fileKey]"
                @download="download(item)"
              />
            </template>
          </v-virtual-scroll>
        </v-list>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import XperdriveLib from 'xperdrive-lib';
import Xpermeet from 'xpermeet-lib';
import ENUMS from '@/constants/ENUMS';
import RecordListItem from '@/components/RecordControl/Modal/RecordListItem';

export default {
  name: 'RecordListModal',
  components: { RecordListItem },
  data() {
    return {
      loading: false,
      localRecordDownloading: false,
      fileStatus: {},
      selectedTab: 'local',
      tabItems: [
        {
          text: this.$t('local'),
          value: 'local',
        },
        {
          text: this.$t('remote'),
          value: 'remote',
        },
      ],
    };
  },
  computed: {
    ...mapState('Conference', ['remoteRecords', 'recordingStatus', 'roomName']),
    localRecords() {
      // TODO yerel kayıt sadece 1 adet listeleniyor. Bir depolama sistemi tasarlanarak birden fazla kayıt listelenebilir.
      if (this.recordingStatus === ENUMS.GLOBAL_ENUM.RECORDING_STATUS.READY_TO_DOWNLOAD) {
        return [
          {
            name: `${Xpermeet.localRecordingManager.getFilename()} - ${this.roomName}.webm`,
          },
        ];
      }
      return [];
    },
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('Conference', ['getConferenceRecords']),
    downloadLocalRecord() {
      this.localRecordDownloading = true;
      Xpermeet.localRecordingManager.save(() => {
        this.localRecordDownloading = false;
        this.SET_RECORDING_STATUS(ENUMS.GLOBAL_ENUM.RECORDING_STATUS.STOP);
      });
    },
    getData() {
      this.loading = true;
      this.getConferenceRecords(() => {
        this.loading = false;
      });
    },
    download(file) {
      const { fileKey, name: fileName } = file;
      XperdriveLib.FileDownload({ fileKey, fileName }, progress => {
        const p = Math.floor((progress.loaded * 100) / progress.total);
        this.$set(this.fileStatus, fileKey, p);

        if (p === 100) {
          setTimeout(() => {
            this.$set(this.fileStatus, fileKey, null);
          }, 1000);
        }
      });
    },
    onModalClose(type) {
      if (type === 'confirm') {
        this.confirm();
      } else {
        this.$emit('close', type);
      }
    },
  },
};
</script>
