<template>
  <div class="wrapper">
    <div v-if="showRecordInfo" class="record-control px-2 cursor-pointer" @click="handleIconClick">
      <icon color="logo-color" icon="stop-circle" size="16" />
      <span class="ml-2">{{ $t('conferenceIsRecording') }}</span>
    </div>
    <v-popover v-if="showSharedPropertiesInfo" :open="showPopover">
      <div class="record-control px-2 mt-1" @mouseenter="showPopover = true" @mouseleave="showPopover = false">
        <icon color="secondary" icon="info" size="16" />
        <span class="ml-2">{{ $t('someSettingsControlledByModerator') }}</span>
      </div>
      <div slot="popover" style="text-align: left">
        <ul v-for="key in moderatorControlledSettings" :key="key">
          <li>{{ $t(key) }}</li>
        </ul>
      </div>
    </v-popover>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Icon from '@/components/Shared/Icon';
import RecordUsersModal from '@/components/RecordControl/Modal/RecordUsersModal';

export default {
  name: 'RecordControl',
  components: {
    Icon,
  },
  data: () => ({
    showPopover: false,
  }),
  computed: {
    ...mapState('Conference', ['isJibri']),
    ...mapGetters('Conference', ['getIsRemoteRecording', 'getAnybodyLocalRecord', 'getSharedProperties', 'isFollowModeratorFullScreen']),
    showRecordInfo() {
      return this.getAnybodyLocalRecord || this.getIsRemoteRecording;
    },
    showSharedPropertiesInfo() {
      return !this.isJibri && (this.getSharedProperties.length > 0 || this.isFollowModeratorFullScreen);
    },
    moderatorControlledSettings() {
      const list = [];

      if (this.isFollowModeratorFullScreen) {
        list.push('followModeratorFullScreen');
      }

      this.getSharedProperties.forEach(prop => {
        if (prop === 'background') {
          list.push('backgroundSelection');
        } else if (prop === 'layoutType') {
          list.push('layoutSettings');
        } else {
          list.push(prop);
        }
      });

      return list;
    },
  },
  methods: {
    handleIconClick() {
      this.$showModal(RecordUsersModal);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  bottom: 100px;
  left: 40px;
  z-index: 999;

  .record-control {
    display: flex;
    align-items: center;
    background-color: var(--v-primary-base);
    border-radius: 8px;
    height: 32px;
    font-size: 10px;
  }
}
</style>
