export const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return Object.fromEntries(params.entries());
};

export function getBaseUrl() {
  const { protocol, host } = window.location;

  return `${protocol}//${host}`;
}
