<template>
  <div class="file-upload-item d-flex align-center">
    <Icon icon="file" size="30" />
    <div class="file-upload-item--status ml-6">
      <div class="file-upload-item--status--info d-flex align-center justify-space-between">
        <span class="file-upload-item--status--info--name text-body-2 ellipsis">{{ fileName }}</span>
        <span class="file-upload-item--status--info--percent">{{ progress }}%</span>
      </div>
      <v-progress-linear rounded :color="color" :value="progress"></v-progress-linear>
    </div>
    <div class="file-upload-item--action d-flex align-center justify-center">
      <v-btn icon x-small v-if="hasError !== 'error'" @click="$emit('delete', fileName)">
        <Icon icon="x" />
      </v-btn>
      <v-btn fab x-small color="error" v-else>
        <Icon icon="refresh" />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileUploadItem',
  props: {
    fileName: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
  },
  computed: {
    color() {
      if (this.hasError) {
        return 'error';
      }
      switch (this.status) {
        case 'process':
          return 'purple';
        case 'error':
          return 'logo-color;';
        case 'success':
          return 'success';
        case 'idle':
          return 'purple';
        default:
          return 'logo-color';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload-item {
  &--status {
    flex: 1;
    &--info {
      margin-bottom: 7px;

      &--name {
        color: white;
        max-width: 160px;
      }

      &--percent {
        color: white;
        opacity: 0.8;
        font-size: 12px;
      }
    }
  }

  &--action {
    margin-left: 20px;
    width: 40px;

    .v-btn--fab {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
