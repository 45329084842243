import axios from './axios';

function Request() {
  this.axios = axios;
}

Request.prototype = {
  _getHeader: function() {
    let header = {
      headers: {
        "Content-Type": "message/x-protobuf",
        "Access-Control-Allow-Origin": "*",
        "tenant": "pass.xperlms.com",
        "Authorization": "Bearer " + localStorage.getItem("keycloak-token"),
      },
    };
    return header;
  },
  _getRestHeader: function() {
    let header = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "tenant": "pass.xperlms.com",
        "Authorization": "Bearer " + localStorage.getItem("keycloak-token"),
      },
    };
    return header;
  },

  getHeaderByType(type) {
    let header = null;
    if(type && type === 'json') {
      header = this._getRestHeader();
    } else {
      header = this._getHeader();
    }
    return header;
  },
  get(url, callback, type, params) {
    let header = this.getHeaderByType(type);
    if (params) {
      header['params'] = params;
    }
    this.returnPromise(this.axios.get(url, header), callback);
  },
  post(url, body, callback, type, timeout) {
    let header = this.getHeaderByType(type);

    if(timeout) {
      this.axios.defaults.timeout = timeout;
    }

    this.returnPromise(this.axios.post(url, body, header), callback);
  },
  delete(url, callback, params) {
    let header = this._getHeader();
    if (params) {
      header['data'] = params;
    }
    this.returnPromise(this.axios.delete(url, header), callback);
  },
  put(url, body, callback) {
    this.returnPromise(this.axios.put(url, body, this._getHeader()), callback);
  },
  patch(url, body, callback) {
    this.returnPromise(this.axios.patch(url, body, this._getHeader()), callback);
  },
  /**
   *
   * @param {*} request http request
   * @param {*} callback function or object ex:
   *  Function usage: ()=>{}
   *  Promise Usage:
   *  {
   *      then: (resultData) => {...},
   *      exception: (exception) => {...},
   *      final: (empty) => {...},
   *  }
   */
  returnPromise(request, callback) {
    let promise = null;
    let { then, exception, final } = callback || {};

    if (callback && typeof callback == 'function') {
      promise = callback;
    }
    // server original result returns
    request
      .then(result => {
        // original results checked by errorHandler
        if (promise) {
          promise(result.data); // returns data of original Result
        } else if (then) {
          then(result.data); // returns data of original Result
        }
      })
      .catch(exceptionMessage => {
        if (exception) {
          exception(exceptionMessage);
        }
      })
      .finally((msg) => {
        if (final) {
          final(msg);
        }
      });
  },
};

export default new Request();
