<template>
  <div class="microphone-meter">
    <div class="level-item" :class="{ 'active-item': item <= barLevel }" v-for="item in 8" :key="item"></div>
  </div>
</template>

<script>
export default {
  name: 'MicrophoneMeter',
  props: {
    level: {
      type: Number,
    },
  },
  computed: {
    /**
     * return between 0-7
     * @return {number}
     */
    barLevel() {
      return Math.floor(this.level / 0.125);
    },
  },
};
</script>

<style scoped lang="scss">
.microphone-meter {
  display: flex;

  .level-item {
    background-color: var(--v-date-base);
    width: 4px;
    height: 22px;
    margin-right: 4px;
    border-radius: 4px;

    &.active-item {
      background-color: var(--v-success-base);
    }
  }
}
</style>
