<template>
  <v-row class="start-muted-policy-settings">
    <v-col cols="12" class="py-0 mt-4" :md="sizeProp.middle" :sm="sizeProp.small">
      <div class="d-flex">
        <div>
          <icon color="secondary" icon="mic-outline"></icon>
        </div>
        <div class="advanced-settings-title  pa-0" :class="classProp">
          <div class="ml-3 mb-n1">
            {{ $t('audioConfig') }}
          </div>

          <checkbox
            class="muted-policy-checkbox ml-1"
            color="white"
            hide-details
            v-model="settings.audioMuted"
            :disabled="disabled"
            :label="$t('startConferenceMuted')"
            data-test-id="W_SMPS_AS_0"
          ></checkbox>
        </div>
      </div>
    </v-col>

    <v-col cols="12" class="py-0 mt-4" :md="sizeProp.middle" :sm="sizeProp.small">
      <div class="d-flex">
        <div>
          <icon color="secondary" icon="video-outline"></icon>
        </div>
        <div class="advanced-settings-title  pa-0 " :class="classProp">
          <div class="ml-3 mb-n1">
            {{ $t('videoConfig') }}
          </div>
          <checkbox
            class="muted-policy-checkbox"
            color="white"
            hide-details
            v-model="settings.videoMuted"
            :disabled="disabled"
            :label="$t('startConferenceVideoMuted')"
            data-test-id="W_SMPS_AS_1"
          ></checkbox>
        </div>
      </div>
    </v-col>

    <v-col v-if="remoteCameraControlSupported" class="py-0 mt-4" cols="12" :md="sizeProp.middle" :sm="sizeProp.small">
      <div class="d-flex">
        <div>
          <icon color="secondary" icon="user"></icon>
        </div>
        <div class="advanced-settings-title  pa-0 " :class="classProp">
          <div class="ml-3 mb-n1">
            {{ this.$t('moderatorConfig') }}
          </div>
          <checkbox
            color="white"
            hide-details
            class="muted-policy-checkbox"
            :value="moderatorControlValue"
            :disabled="isModeratorControlDisabled"
            v-model="settings.moderatorControl"
            :label="$t('moderatorControl')"
            data-test-id="W_SMPS_AS_2"
          ></checkbox>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ENUMS from '@/constants/ENUMS';
import { generateRoomConfig } from '@/helpers/conference';

const {
  AUDIO_UNMUTED,
  VIDEO_UNMUTED,
  MODERATOR_AUDIO_MUTED,
  MODERATOR_VIDEO_MUTED,
  START_MUTED_POLICY,
  START_VIDEO_MUTED_POLICY,
  ALLOW_ADMIN_START_VIDEO,
} = ENUMS.RoomConfigEnums;

export default {
  name: 'StartMutedPolicySettings',
  props: {
    remoteCameraControlSupported: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => ({
        [START_MUTED_POLICY]: AUDIO_UNMUTED,
        [START_VIDEO_MUTED_POLICY]: VIDEO_UNMUTED,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sizeProp: {
      type: Object,
      default: () => {
        return {
          middle: '4',
          small: '4',
        };
      },
    },
    classProp: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ENUMS,
      settings: {
        audioMuted: false,
        videoMuted: false,
        moderatorControl: false,
      },
    };
  },
  computed: {
    moderatorControlValue() {
      return (this.settings.audioMuted || this.settings.videoMuted) && this.settings.moderatorControl;
    },
    isModeratorControlDisabled() {
      return this.disabled || (!this.settings.audioMuted && !this.settings.videoMuted);
    },
  },
  watch: {
    initialData: {
      deep: true,
      immediate: true,
      handler() {
        this.settings.audioMuted = this.initialData[START_MUTED_POLICY] !== AUDIO_UNMUTED;
        this.settings.videoMuted = this.initialData[START_VIDEO_MUTED_POLICY] !== VIDEO_UNMUTED;
        this.settings.moderatorControl =
          (this.initialData[START_MUTED_POLICY] === MODERATOR_AUDIO_MUTED || this.initialData[START_VIDEO_MUTED_POLICY] === MODERATOR_VIDEO_MUTED) &&
          this.initialData[ALLOW_ADMIN_START_VIDEO];
      },
    },
    settings: {
      deep: true,
      immediate: true,
      handler() {
        const { audioMuted, videoMuted, moderatorControl } = this.settings;

        const settings = generateRoomConfig({ audioMuted, videoMuted, onlyModeratorControl: moderatorControl });

        this.$emit('change', settings);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.start-muted-policy-settings {
  ::v-deep .v-input__slot {
    align-items: unset;
  }
  .advanced-settings-title {
    border-bottom: 1px solid var(--default-border-color);
    padding: 0 10px;
    text-align: left;

    .advanced-settings-title i {
      padding-right: 6px !important;
    }
  }
  .muted-policy-checkbox {
    font-size: 14px;
  }
}
</style>
