import JitsiMeetJS from "../JitsiMeetLib";
import EventEmitter from "../utils/EventEmitter";
import Conference from "./Conference";

const eventEmitter = new EventEmitter();

class Stats {
  handleEvents() {
    Conference.room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, this.onLocalStatsUpdated.bind(this));
    Conference.room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, this.onRemoteStatsUpdated.bind(this));
  }

  onLocalStatsUpdated(stats) {
    eventEmitter.emit("LocalStatsUpdated", stats);
  }

  onRemoteStatsUpdated(id, stats) {
    eventEmitter.emit("RemoteStatsUpdated", { id, stats });
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }
}

export default new Stats();
