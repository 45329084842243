<template>
  <Modal persistent :show-close-button="false">
    <div class="text-center">
      <Logo class="mb-6" height="50" />
      <h2 class="mb-6">{{ $t('joinMobileTitle') }}</h2>
      <h4 class="mb-6">{{ roomName }}</h4>
      <v-btn color="secondary" block class="mt-4 mb-6 pl-10 pr-10" :href="generateDeepLinkingURL()">{{ $t('joinInApp') }}</v-btn>
      <div class="mb-6">{{ $t('youDontHaveApp') }}</div>
      <div class="mb-3">
        <v-btn color="secondary" :href="appLink" text>{{ $t('downloadFromStore') }}</v-btn>
      </div>
      <div class="mb-3">{{ $t('or') }}</div>
      <div>
        <v-btn color="secondary" text @click="$emit('close')">{{ $t('joinInBrowser') }}</v-btn>
      </div>
    </div>
  </Modal>
</template>

<script>
import Logo from '@/components/Shared/Logo.vue';
import { IOS_APP_URL, ANDROID_APP_URL } from '@/constants/mobile';
import { isIOS } from '@/helpers/os';

export default {
  name: 'JoinMeetingFromMobileModal',
  components: {
    Logo,
  },
  props: {
    roomName: {
      type: String,
    },
  },
  data() {
    return {
      isIos: isIOS(),
    };
  },
  computed: {
    appLink() {
      return this.isIos ? IOS_APP_URL : ANDROID_APP_URL;
    },
    appScheme() {
      return 'com.aselsan.bizbize';
    },
    appPackage() {
      return 'com.aselsan.bizbize';
    },
  },
  methods: {
    openInApp() {
      window.replace(this.generateDeepLinkingURL());
    },
    generateDeepLinkingURL() {
      const domain = window.location.hostname;
      const meetingAddress = `${domain}/${this.roomName}`;

      if (isIOS()) {
        return `bizbize://${this.roomName}`;
      }

      return `intent://${meetingAddress}#Intent;scheme=${this.appScheme};package=${this.appPackage};end`;
    },
  },
};
</script>

<style lang="scss" scoped>
.system-error {
  text-align: center;
  font-size: 20px;
  line-height: 1.5rem;
}
</style>
