<template>
  <div>
    <div v-if="roomNotifications.length">
      <ExpansionPanelNotificationItem
        v-for="(notification, key) in roomNotifications"
        :key="key"
        :user="getUserById(notification.userId)"
        :notification="notification"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import ExpansionPanelNotificationItem from '@/components/Notifications/RoomNotifications/ExpansionPanelNotificationItem';
import ENUMS from '@/constants/ENUMS';

export default {
  name: 'Notifications',
  components: {
    ExpansionPanelNotificationItem,
  },
  data() {
    return {
      menu: false,
      ENUMS,
    };
  },
  computed: {
    ...mapState('Notification', ['roomNotificationHighlight', 'roomNotifications']),
    ...mapGetters('Notification', ['getNotificationByType']),
    ...mapGetters('Conference', ['getUserById']),
    handsUpNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.HANDS_UP);
    },
    handsUpNotificationsSubTitle() {
      return this.$t('notifications.participantsRaiseTheirHands', { count: this.handsUpNotifications.length });
    },
    recordNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.RECORD);
    },
    moderatorNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.MODERATOR_ROLE);
    },
    fileShareNotifications() {
      return this.getNotificationByType(ENUMS.ROOM_NOTIFICATION_TYPES.FILE_SHARE);
    },
    recordNotificationsSubTitle() {
      return this.$t('notifications.participantsRecording', { count: this.recordNotifications.length });
    },
  },
};
</script>
<style scoped lang="scss"></style>
