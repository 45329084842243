import axios from "axios";
import { consoleError, consoleLog } from "../utils/logger";
import EventEmitter from "../utils/EventEmitter";

const eventEmitter = new EventEmitter();
const Keycloak = window.Keycloak;

class KeycloakManager {
  constructor() {
    this.TOKEN_GENERATOR_ENDPOINT = window.XPER_CONFIG?.keycloakTokenGeneratorUrl;
    this.MANAGE_SERVICE_ENDPOINT = window.XPER_CONFIG?.manageService;
    this.keycloakInstance = null;
    this.active = window.XPER_CONFIG?.isKeycloakActive;
    this.autoRefreshToken = true;
    this.config = {
      url: "",
      realm: "",
      clientId: "",
    };
  }

  getOrigin() {
    return window.location.hostname === "localhost" ? "beta.xpermeet.com" : window.location.hostname;
  }

  async createKeycloakInstance({ endpoint = this.TOKEN_GENERATOR_ENDPOINT, force = false } = {}) {
    try {
      if (this.keycloakInstance && !force) {
        return this.keycloakInstance;
      }

      const keycloakJson = await axios.get(`${endpoint}/api/keycloak.json`);
      this.config = {
        url: keycloakJson.data["auth-server-url"],
        realm: keycloakJson.data.realm,
        clientId: keycloakJson.data.resource,
      };

      this.keycloakInstance = new Keycloak(this.config);

      window.kc = this.keycloakInstance;
      this.keycloakInstance.onTokenExpired = this.onTokenExpired.bind(this);

      return this.keycloakInstance;
    } catch (e) {
      consoleError("Could not get keycloakJson!");
      return null;
    }
  }

  handleAuthentication(roomName) {
    return new Promise((resolve, reject) => {
      if (!this.active) {
        return resolve();
      }

      this.getJwt(this.TOKEN_GENERATOR_ENDPOINT, roomName)
        .then((jwt) => {
          eventEmitter.emit("authenticated");
          localStorage.setItem("authenticator-token", jwt);
          resolve();
        })
        .catch((e) => {
          if (e?.response?.data?.error === "Wrong user login. Forbidden") {
            reject("WrongUserLogin", e);
          } else {
            consoleError("Get JWT failed!", e);
            reject("GuestLogin");
          }
        });
    });
  }

  checkSSO(keycloak, checkLoginIframe) {
    const cfg = checkLoginIframe ? { silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html" } : { checkLoginIframe: false };

    return new Promise(async (resolve, reject) => {
      keycloak
        .init({
          onLoad: "check-sso",
          ...cfg,
        })
        .then(async (auth) => {
          consoleLog("auth", auth);
          if (!auth) {
            return resolve(auth);
          }

          if (keycloak.token && keycloak.refreshToken) {
            localStorage.setItem("keycloak-token", keycloak.token);
            localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);
          } else {
            localStorage.removeItem("keycloak-token");
            localStorage.removeItem("keycloak-refresh-token");
          }

          if (keycloak.isTokenExpired()) {
            try {
              await this.refreshToken();
            } catch (e) {
              consoleError("checkSSO error");
              reject(e);
              return;
            }
          }

          resolve(auth);
        })
        .catch((e) => {
          consoleError("checkSSO error", JSON.stringify(e));
          reject(e);
        });
    });
  }

  getTokenGeneratorPath({ endpoint, roomName }) {
    return `${endpoint}/api/config/${roomName}`;
  }

  async getJwt(endpoint, roomName) {
    await this.keycloakInstance.loadUserProfile();
    const response = await axios.get(this.getTokenGeneratorPath({ endpoint, roomName }), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("keycloak-token")}`,
      },
    });

    return response.data.token;
  }

  onTokenExpired() {
    if (this.autoRefreshToken) {
      consoleLog("Auth token expired, refreshing " + new Date());

      return this.refreshToken();
    } else {
      consoleLog("Auth token expired");
    }
  }

  async refreshToken(minValidity = 30) {
    try {
      const refreshed = await this.keycloakInstance.updateToken(minValidity);
      if (refreshed) {
        consoleLog("token refreshed " + new Date());
        localStorage.setItem("keycloak-token", this.keycloakInstance.token);
        localStorage.setItem("keycloak-refresh-token", this.keycloakInstance.refreshToken);
        eventEmitter.emit("TokenRefreshed", this.keycloakInstance.token);
      } else {
        consoleLog("token not refreshed " + new Date());
        this.keycloakInstance.clearToken();
      }
    } catch (e) {
      consoleError("Failed to refresh token " + new Date(), e);
      this.keycloakInstance.clearToken();
    }
  }

  redirectLoginPage() {
    this.keycloakInstance.login();
  }

  async getUsers(params = {}) {
    try {
      const { search } = params;
      const response = await axios.get(`${this.TOKEN_GENERATOR_ENDPOINT}/api/users?search=${search}`);
      return response?.data || [];
    } catch (e) {
      consoleError(e); // eslint-disable-line
      return [];
    }
  }

  on(eventType, callbackFn) {
    eventEmitter.on(eventType, callbackFn);
  }
}

export default KeycloakManager;
