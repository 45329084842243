<template>
  <div class="device-selector microphone-device-selector">
    <template v-if="hasMicrophone">
      <div
        class="microphone-device-selector--item px-8 py-3 d-flex align-center"
        :class="{ selected: selectedMicId === item.deviceId }"
        v-for="item in getMicrophones"
        :key="item.deviceId"
        @click="selectedMicId = item.deviceId"
        role="button"
      >
        <span class="text-body-2 ellipsis" style="max-width: 220px">{{ item.label }}</span>
        <v-spacer />
        <MicrophoneMeter class="ml-4" :level="audioLevels[item.deviceId]" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XperMeetLib from 'xpermeet-lib';
import MicrophoneMeter from '@/components/Device/MicrophoneMeter';

export default {
  name: 'MicrophoneDeviceSelector',
  components: { MicrophoneMeter },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      audioLevels: {},
      audioTracks: [],
      selectedMicId: this.value || null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getMicrophones', 'hasMicrophone']),
  },
  mounted() {
    this.initAudioLevel();
  },
  beforeDestroy() {
    this.clearTracks();
  },
  methods: {
    initAudioLevel() {
      this.clearTracks();

      this.getMicrophones.forEach(microphoneDevice => {
        XperMeetLib.lib
          .createLocalTracks({
            devices: ['audio'],
            micDeviceId: microphoneDevice.deviceId,
          })
          .then(tracks => {
            this.audioTracks.push(tracks[0]);
            tracks[0].on(XperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED, level => {
              this.$set(this.audioLevels, microphoneDevice.deviceId, level);
            });
          });
      });
    },
    clearTracks() {
      this.audioTracks.forEach(track => {
        track.removeAllListeners(XperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED);
        track.dispose();
      });
      this.audioTracks = [];
    },
    onChange(item) {
      this.$emit('change', item);
    },
    onAudioLevelChange() {
      this.$emit('onAudioLevelChange', this.audioLevels);
    },
  },
  watch: {
    selectedMicId: 'onChange',
    audioLevels: 'onAudioLevelChange',
  },
};
</script>

<style lang="scss">
.microphone-device-selector {
  border-radius: 15px;
  border: 1px solid var(--v-date-base);
  background-color: var(--v-primary-base);

  &--item:first-child {
    border-radius: 15px 15px 0 0;
  }

  &--item:last-child {
    border-radius: 0 0 15px 15px;
  }

  &--item {
    &.selected {
      background-color: var(--v-darkblue-40);

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--v-error-base);
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}
</style>
