<template>
  <div class="device-selector speaker-device-selector">
    <div
      class="speaker-device-selector--item px-8 py-3 d-flex align-center"
      :class="{ selected: selectedSpeakerId === item.deviceId }"
      v-for="item in getSpeakers"
      :key="item.deviceId"
      @click="selectedSpeakerId = item.deviceId"
      role="button"
    >
      <span class="text-body-2 ellipsis">{{ item.label }}</span>
      <v-spacer />
      <audio src="/sound/speakerTest.mp3" :id="`audio-test-${item.deviceId}`" />
      <v-btn small text @click.stop="speakerTest(item.deviceId)">
        {{ playingDeviceId === item.deviceId ? $t('stop') : $t('test') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SpeakerDeviceSelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      playingDeviceId: null,
      selectedSpeakerId: this.value || null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getSpeakers']),
  },
  methods: {
    onChange(item) {
      this.$emit('change', item);
    },
    async speakerTest(deviceId) {
      if (this.playingDeviceId) {
        document.getElementById(`audio-test-${this.playingDeviceId}`).pause();
      }

      if (this.playingDeviceId === deviceId) {
        this.playingDeviceId = null;
        return;
      }

      this.playingDeviceId = deviceId;

      await document.getElementById(`audio-test-${deviceId}`).setSinkId(deviceId);
      document.getElementById(`audio-test-${deviceId}`).play();
    },
  },
  watch: {
    selectedSpeakerId: 'onChange',
  },
};
</script>

<style lang="scss">
.speaker-device-selector {
  border-radius: 15px;
  border: 1px solid var(--v-date-base);
  background-color: var(--v-primary-base);

  &--item:first-child {
    border-radius: 15px 15px 0 0;
  }

  &--item:last-child {
    border-radius: 0 0 15px 15px;
  }

  &--item {
    &.selected {
      background-color: var(--v-darkblue-40);

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--v-error-base);
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}
</style>
