<template>
  <div class="meet-top-menu d-flex">
    <template v-if="!$isMobile">
      <div class="mr-8" style="position: relative; flex-grow: 1">
        <div class="d-flex">
          <logo />
          <div v-if="webinarTitle" class="room-webinar-title">
            {{ webinarTitle }}
          </div>
          <div v-if="meetingInfo" class="ml-4">
            <div class="page-title">{{ meetingInfo.title }}</div>
            <div class="text-caption">{{ meetingInfo.subTitle }}</div>
          </div>
        </div>
        <license-information />
      </div>
      <div v-if="conferenceJoined" class="flex-grow-1 d-flex justify-end top-menu-actions align-center">
        <participant-counter v-if="!$isMobile" />
        <menu-button v-if="getE2eeEnabled" id="e2ee-top-button" class="mr-1" color="success" icon="shield-1" icon-size="20" @click="handleE2eeModal" />
        <NotificationsWrapper />
        <menu-button
          id="webinar-top-button"
          class="mr-1"
          v-if="showWebinarButton"
          :icon-background-color="webinarSessionId ? 'secondary' : undefined"
          icon="tv-2"
          icon-size="20"
          dropdownClass="mt-2"
          :loading="webinarIconLoading"
          :dropdownList="webinarMenuList"
          :disabled="!isWebinarAvailable"
          :use-inner-model="!getLocalUser.isModerator || !getLocalUser.authenticated || !$can('start', 'Webinar')"
          :tooltip-text="getLocalUser.isModerator && getLocalUser.authenticated && isWebinarAvailable ? $t(webinarButtonTooltipText) : ''"
          @click="onWebinarButtonClicked"
        />
        <menu-button
          id="user-profile-top-button"
          class="mr-1"
          dropdownClass="mt-2"
          icon="more-horizontal-2"
          :dropdownList="userProfileMenu"
          :useInnerModel="true"
        />
        <menu-button v-if="!$isMobile" id="settings-top-button" class="mr-1" icon="settings" @click="$emit('itemClicked', 'settings')" />
      </div>
    </template>
    <template v-else>
      <div class="meet-mobile-top-menu">
        <menu-button
          id="switch-camera-button"
          :disabled="getCameras.length < 2 && (!isFacingModeSupported || waitRefreshButton)"
          width="32"
          icon-background-color="var(--v-whisper-50)"
          class="mb-2"
          icon="refresh-ccw"
          :useInnerModel="true"
          @click="switchCamera"
        />
        <menu-button
          id="user-profile-top-button-mobile"
          width="32"
          icon-background-color="var(--v-whisper-50)"
          class="mb-2"
          icon="more-horizontal-2"
          :useInnerModel="true"
          @click="openMobileMenu"
        />
      </div>
      <meet-mobile-left-menu v-model="mobileMenuOpened" :about-us-text="aboutUsText" :menu-items="userProfileMenu" />
    </template>
    <ConfirmModal
      v-if="showDisableE2eeModal"
      :title="$t('closeE2ee')"
      @confirm="
        setMasterKeyCustomer({
          e2eePassword: '',
          e2eeEnabled: false,
        })
      "
      :text="$t('closeE2eeDescription')"
      :confirm-text="$t('close')"
      @cancel="showDisableE2eeModal = false"
      persistent
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import WebinarStartConfirm from '@/components/Webinar/Modal/WebinarStartConfirm';
import NotificationsWrapper from '@/components/Notifications/RoomNotifications/NotificationsWrapper';
import ENUMS, { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';
import { copyToClipboard } from '@/helpers/clipboard';
import ParticipantCounter from '@/components/Shared/ParticipantCounter';
import MeetMobileLeftMenu from '@/components/Menu/MeetMobileLeftMenu';
import LicenseInformation from '@/components/Menu/LicenseInformation';
import ConfirmModal from '@/components/Shared/Modal/ConfirmModal';
import { TOAST_TYPE } from '@/constants/toast';
import { consoleError } from '@/helpers/logger';
import { getCurrentYear } from '@/helpers/time';

export default {
  name: 'MeetTopMenu',
  components: { LicenseInformation, MeetMobileLeftMenu, ParticipantCounter, ConfirmModal, NotificationsWrapper },
  data() {
    return {
      webinarIconLoading: false,
      mobileMenuOpened: false,
      showDisableE2eeModal: false,
      waitRefreshButton: false,
      stream: null,
      shouldFaceUser: true,
    };
  },
  computed: {
    ...mapState('Conference', ['roomName', 'conferenceJoined', 'roomConfig', 'e2eeMasterPassword', 'isPrivateRoom']),
    ...mapState('Notification', ['roomNotifications', 'roomNotificationHighlight']),
    ...mapState('Webinar', ['webinarSessionId', 'streamChannel']),
    ...mapGetters(['getAppVersion']),
    ...mapGetters('Conference', ['getLocalUser', 'getUsers', 'getVideoShareAvailability', 'getE2eeEnabled', 'showARTab']),
    ...mapGetters('Webinar', ['webinarUrl', 'isWebinarStarted', 'isWebinarEnded']),
    ...mapGetters('Devices', ['getCameras', 'hasCamera', 'cameraAllowed', 'getSelectedCamera', 'getSelectedCameraId']),
    ...mapGetters('Notification', ['lastNotificationBadgeColor']),
    aboutUsText() {
      return `${this.$t('madeBy', { year: getCurrentYear() })} ${this.$t('version')} ${this.getAppVersion}`;
    },
    meetingInfo() {
      return this.roomConfig.pageTitle
        ? {
            title: this.roomConfig.pageTitle,
            subTitle: this.roomConfig.pageSubTitle,
          }
        : null;
    },
    isFacingModeSupported() {
      let supports = navigator.mediaDevices.getSupportedConstraints();
      return supports['facingMode'];
    },
    fileShareSupported() {
      return this.$customerConfig?.system?.modules?.fileShare && this.$can('see', 'FileShare') && this.roomConfig?.allowFileShare;
    },
    showWebinarButton() {
      if (this.webinarSupported && !this.$isMobile) {
        if (this.getLocalUser.isModerator) {
          if (this.isWebinarStarted) {
            return this.$can('stop', 'Webinar');
          } else {
            return this.$can('start', 'Webinar');
          }
        } else {
          if (this.isWebinarStarted) {
            return true;
          }
        }
      }
      return false;
    },
    webinarSupported() {
      return this.getLocalUser?.modules?.webinar?.enabled;
    },
    isWebinarAvailable() {
      return !this.getE2eeEnabled && !this.webinarIconLoading;
    },
    webinarMenuList() {
      let items = [];
      if (this.isWebinarStarted && this.streamChannel === ENUMS.STREAM_CHANNELS.XPERMEET && !this.getLocalUser?.isModerator) {
        items.push({
          label: 'copyWebinarUrl',
          icon: 'clipboard',
          onClick: () => {
            copyToClipboard(this.webinarUrl);
          },
        });
      }
      return items;
    },
    webinarButtonTooltipText() {
      return this.webinarSessionId ? 'webinarStop' : 'webinarStart';
    },
    userProfileMenu() {
      if (!this.getLocalUser?.modules) {
        return [];
      }

      // If Order is -1 then item will be hidden.
      let menu = [
        {
          id: 'lockConference',
          label: 'conferencePasswordApply',
          moduleName: 'lockConference',
          onClick: () => this.itemClicked('lockConference'),
          desktopOrder: -1,
          mobileOrder: 1,
        },
        {
          id: 'goToConference',
          label: 'goToConference',
          moduleName: 'createOrGoToConference',
          onClick: () => this.itemClicked('goToConference'),
          desktopOrder: 1,
          mobileOrder: 2,
        },
        {
          id: 'userList',
          label: 'userList',
          moduleName: 'userList',
          onClick: () => this.itemClicked('userList'),
          desktopOrder: -1,
          mobileOrder: 3,
        },
        {
          id: 'e2eEncryption',
          label: 'e2eEncryption',
          moduleName: 'e2eEncrypt',
          onClick: () => this.itemClicked('e2eEncryption'),
          desktopOrder: 1,
          mobileOrder: 7,
        },
        {
          id: 'meetingRequest',
          label: 'meetingRequest',
          moduleName: 'meetingRequest',
          onClick: () => this.itemClicked('meetingRequest'),
          desktopOrder: -1,
          mobileOrder: 8,
        },
        {
          id: 'whiteBoard',
          label: 'whiteBoard',
          moduleName: 'whiteBoard',
          onClick: () => this.itemClicked('whiteBoard'),
          desktopOrder: 1,
          mobileOrder: -1,
        },
        {
          id: 'augmentedReality',
          label: 'augmentedReality',
          moduleName: 'augmentedReality',
          onClick: () => this.itemClicked('augmentedReality'),
          desktopOrder: 1,
          mobileOrder: -1,
        },
        {
          id: 'sharedFiles',
          label: 'sharedFiles',
          moduleName: 'sharedFiles',
          onClick: () => this.itemClicked('sharedFiles'),
          desktopOrder: 1,
          mobileOrder: 4,
        },
        {
          id: 'fileShare',
          label: 'fileShare',
          moduleName: 'fileShare',
          onClick: () => this.itemClicked('fileShare'),
          desktopOrder: 1,
          mobileOrder: 4,
        },
        {
          id: 'survey',
          label: 'survey',
          moduleName: 'survey',
          onClick: () => this.itemClicked('survey'),
          desktopOrder: 1,
          mobileOrder: 6,
        },
        {
          id: 'shareVideo',
          label: 'playVideo',
          moduleName: 'shareVideo',
          disabled: !this.getVideoShareAvailability,
          onClick: () => this.itemClicked('shareVideo'),
          desktopOrder: 1,
          mobileOrder: 5,
        },
        {
          id: 'layoutSettings',
          label: 'themeSettings',
          moduleName: 'layoutSettings',
          onClick: () => this.itemClicked('layoutSettings'),
          desktopOrder: 1,
          mobileOrder: -1,
        },
        {
          id: 'recordList',
          label: 'recordList',
          moduleName: 'recordList',
          onClick: () => this.itemClicked('recordList'),
          desktopOrder: 1,
          mobileOrder: -1,
        },
        {
          id: 'settings',
          label: 'settings',
          moduleName: 'settings',
          onClick: () => this.itemClicked('settings'),
          desktopOrder: -1,
          mobileOrder: 9,
        },
        {
          id: 'login',
          label: 'login',
          moduleName: 'login',
          onClick: () => this.itemClicked('login'),
          desktopOrder: 1,
          mobileOrder: 9,
        },
        {
          id: 'notifications',
          label: 'notifications.title',
          moduleName: 'notifications',
          onClick: () => this.itemClicked('notifications'),
          class: this.roomNotifications.length ? this.notificationClass : '',
          desktopOrder: 1,
          mobileOrder: 10,
        },
        {
          type: 'divider',
          desktopOrder: 1,
          mobileOrder: -1,
        },
        {
          id: 'aboutUs',
          type: 'text',
          label: this.aboutUsText,
          class: 'opacity-50',
          desktopOrder: 1,
          mobileOrder: -1,
        },
      ];

      const allowedItems = ['lockConference', 'userList', 'aboutUs', 'recordList', 'augmentedReality'];
      if (this.$isMobile) {
        allowedItems.push('notifications');
      }
      menu = menu.filter(
        item => this.getLocalUser.modules[item.moduleName] || allowedItems.includes(item.moduleName) || item.type === 'text' || item.type === 'divider',
      );
      // remove login button from menu when keycloak is not active
      if (!window.XPER_CONFIG.isKeycloakActive) {
        menu = menu.filter(item => item.moduleName !== 'logout');
      }

      if (this.isPrivateRoom) {
        menu = menu.filter(item => item.moduleName !== 'login');
      }

      if (!this.$customerConfig?.system?.modules?.survey || !this.$can('see', 'Survey')) {
        menu = menu.filter(item => item.moduleName !== 'survey');
      }

      if (this.getLocalUser.authenticated) {
        menu = menu.filter(item => item.moduleName !== 'login');
      } else {
        menu = menu.filter(item => item.moduleName !== 'logout');
      }

      if (!this.getLocalUser?.isModerator || !this.$can('sendMeetingRequest', 'Meet')) {
        menu = menu.filter(item => item.moduleName !== 'meetingRequest');
      }
      if (!this.fileShareSupported) {
        menu = menu.filter(item => item.moduleName !== 'fileShare');
      }
      if (!this.$can('see', 'FileShare')) {
        menu = menu.filter(item => item.moduleName !== 'sharedFiles');
      }
      if (!this.$can('shareVideo', 'Meet')) {
        menu = menu.filter(item => item.moduleName !== 'shareVideo');
      }
      if (!this.$can('seeSIPNumber', 'Meet') || !this.$customerConfig?.system?.admin?.modules?.dialIn) {
        menu = menu.filter(item => item.moduleName !== 'dialIn');
      }
      if (!this.$can('enableE2EE', 'Meet') || this.isPrivateRoom) {
        menu = menu.filter(item => item.moduleName !== 'e2eEncrypt');
      }
      if (!this.$can('see', 'WhiteBoard')) {
        menu = menu.filter(item => item.moduleName !== 'whiteBoard');
      }

      if (!this.showARTab) {
        menu = menu.filter(item => item.moduleName !== 'augmentedReality');
      }

      // Order
      menu = !this.$isMobile ? menu.filter(a => a.desktopOrder !== -1) : menu.filter(a => a.mobileOrder !== -1);
      menu = !this.$isMobile ? menu.sort((a, b) => a.desktopOrder - b.desktopOrder) : menu.sort((a, b) => a.mobileOrder - b.mobileOrder);

      return menu;
    },
    notificationClass() {
      return this.roomNotificationHighlight ? `notification active ${this.lastNotificationBadgeColor}` : 'notification';
    },
    webinarTitle() {
      return this.roomConfig?.webinarTitle;
    },
  },
  methods: {
    ...mapActions('Webinar', ['startWebinarStream', 'stopWebinar']),
    ...mapActions('Conference', ['setMasterKeyCustomer', 'setRoomConfig']),
    ...mapActions('Notification', ['showToastNotification', 'playSound']),
    ...mapActions('Devices', ['changeDevice']),
    ...mapMutations('Conference', ['SET_SHOW_E2EE_MODAL']),
    ...mapMutations('Devices', ['SET_DEVICE', 'SELECT_DEVICE', 'SET_DEVICE_STATE']),
    switchCamera() {
      if (this.waitRefreshButton === false) {
        this.waitRefreshButton = true;

        setTimeout(() => {
          this.waitRefreshButton = false;
        }, 1500);

        const anotherDevice = this.getSelectedCamera.deviceId === this.getCameras[0].deviceId ? this.getCameras[1] : this.getCameras[0];
        if (anotherDevice) {
          this.changeDevice(anotherDevice);
          return;
        }
        this.showToastNotification({ body: 'deviceAccessError', config: { type: TOAST_TYPE.WARNING } });
      }
    },
    itemClicked(eventName) {
      this.mobileMenuOpened = false;
      this.$emit('itemClicked', eventName);
    },
    openMobileMenu() {
      this.mobileMenuOpened = true;
    },
    onWebinarButtonClicked() {
      if (!this.getLocalUser.isModerator) {
        return;
      }
      if (this.getE2eeEnabled) {
        this.showToastNotification({ body: 'youCannotOpenWebinarWhileE2eEnabled', config: { type: TOAST_TYPE.WARNING } });
        return;
      }
      if (this.isWebinarAvailable) {
        this.$showModal(
          WebinarStartConfirm,
          { isWebinarStarted: this.isWebinarStarted, enableWebinarRecord: this.$can('startRemote', 'Record') },
          { confirm: this.toggleWebinar },
        );
      }
    },
    toggleWebinar(streamKey, saveWebinarStream, webinarTitle) {
      if (this.webinarSessionId) {
        this.stopWebinar();
        this.setRoomConfig({
          ...this.roomConfig,
          [ENUMS.RoomConfigEnums.WEBINAR_TITLE]: '',
        });
      } else {
        if (!this.webinarIconLoading) {
          this.webinarIconLoading = true;
          this.startWebinarStream({ roomName: this.roomName, streamId: streamKey, saveWebinarStream, webinarTitle }).catch(err => {
            consoleError('startWebinarStream ', err);

            this.webinarIconLoading = false;
            if (err.textContent === 'all Jibris are busy') {
              this.showToastNotification('webinarJibriBusy');
            } else {
              this.showToastNotification('webinarStartFailed');
            }
          });
        }
      }
    },
    handleE2eeModal() {
      if (this.e2eeMasterPassword) {
        this.showDisableE2eeModal = true;
      } else {
        this.SET_SHOW_E2EE_MODAL(true);
      }
    },
  },
  watch: {
    isWebinarEnded() {
      if (this.isWebinarEnded) {
        this.webinarIconLoading = false;
        this.showToastNotification('webinarStreamStopped');
        this.playSound(NOTIFICATION_SOUND_TYPES.WEBINAR_STOPPED);
      }
    },
    isWebinarStarted() {
      if (this.isWebinarStarted) {
        this.webinarIconLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.meet-top-menu {
  @media (max-width: $breakpoint-mobile) {
    position: relative;
    .meet-mobile-top-menu {
      position: absolute;
      top: -30px;
      right: 0;
      flex-direction: column;
    }
  }
  .room-webinar-title {
    z-index: 1;
    font-size: 13px;
    max-width: 50vw;
    max-height: 3.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    word-wrap: break-word;
    position: absolute;
    left: 8rem;
    top: 1rem;
  }
  .notification-top-button {
    &.active {
      .v-btn__content {
        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: 7px;
          background: var(--v-logo-color-base);
        }
      }
    }
  }
}
</style>
