/**
 * @author ahmet.sari
 */

import * as openpgp from './openpgp.min';


// eslint-disable-next-line require-jsdoc
export class RsaUtil {

    /**
     * Encrypts given cipher using given public key.
     * @param {string} publicKey
     * @param {string} cipher
     * @returns {Promise<string>} Base64 encoded encrypted bytes
     */
    async encrypt(publicKey, cipher) {
        const pkBytes = openpgp.util.b64_to_Uint8Array(publicKey);
        const pub = await openpgp.key.read(pkBytes);
        const { data: encrypted } = await openpgp.encrypt({
            message: openpgp.message.fromText(cipher), // input as Message object
            publicKeys: pub.keys // for encryption
        });

        return encrypted;
    }

    /**
     * Decrypts given cipher using given private key and master password.
     * @param {string} privateKey
     * @param {string} masterPassword
     * @param {string} cipher
     * @returns {Promise<string>} Decoded cipher
     */
    async decrypt(privateKey, masterPassword, cipher) {
        const pkBytes = openpgp.util.b64_to_Uint8Array(privateKey);
        const { keys: [ pk ] } = await openpgp.key.read(pkBytes);

        await pk.decrypt(masterPassword);
        const { data: decrypted } = await openpgp.decrypt({
            message: await openpgp.message.readArmored(cipher), // parse armored message
            privateKeys: [ pk ] // for decryption
        });

        return decrypted;
    }

    async checkIfPassphraseCorrect(privateKey, masterPassword) {
        const pkBytes = openpgp.util.b64_to_Uint8Array(privateKey);
        const { keys: [ pk ] } = await openpgp.key.read(pkBytes);

        try {
            await pk.decrypt(masterPassword);

            return true;
        } catch (e) {
            return false;
        }
    }

}
