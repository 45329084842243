<template>
  <div class="survey-result-wrapper">
    <div class="d-flex align-end">
      <v-select
        solo
        background-color="primary"
        v-if="!isSharedResult"
        :label="$t('conferenceSelection')"
        :items="rooms"
        outlined
        dense
        hide-details
        v-model="selectedRoom"
        offset-y
      ></v-select>
      <v-btn v-if="selectedRoom && questions.length" class="download-result" v-tooltip="$t('downloadSurveyResults')" text small @click="downloadSurveyResults">
        <icon class="user-action-icon" icon="download" size="16" />
        <span class="text-body-2 download-result--text ml-2">{{ $t('downloadSurveyResults') }}</span>
      </v-btn>
    </div>

    <div class="survey-result my-4" v-for="(question, questionIndex) in questions" :key="questionIndex">
      <div class="question pb-2">
        <div class="question-text">{{ questionIndex + 1 }}. {{ question.Questions }}</div>
      </div>
      <div class="options mt-2">
        <div
          class="option-wrapper my-2"
          :class="{ public: !survey.IsPrivate }"
          v-tooltip="getChoicesUsers(option.Id) ? `${getChoicesUsers(option.Id)}, ${usersLength(option.Id) ? $t('showAllUsers') : ''}` : null"
          v-for="(option, optionIndex) in question.options"
          :key="optionIndex"
          @click="showUsersDialog(option, option.Id, optionIndex)"
        >
          <div class="option">
            <div class="option-text" :class="{ active: option.Id === openedResultId }">
              <div class="pr-5">
                {{ option.Choice }}
                <div class="vote-count">{{ getVoteCount(option.Id) }} {{ $t('vote') }}</div>
                <div class="percent">%{{ getRate(option.Id) }}</div>
                <transition name="collapse" mode="out-in">
                  <div
                    :class="{ userList: userList.length && userList.length > 4 }"
                    v-show="userList.length && openedResultId == option.Id && openedResultIndex == optionIndex"
                  >
                    <v-divider></v-divider>

                    <div v-for="(user, index) in userList" :key="index">
                      <div class="d-flex justify-space-between" :class="{ 'mt-2': index === 0 }">
                        <span class="voter">{{ user }}</span>
                        <div class="close-icon" v-if="index == 0" @click.stop="closeList">
                          <icon icon="times" style="color: #ffffff"></icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="bar" :style="{ width: `${getRate(option.Id)}%` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/Shared/Icon';
import { jsonToCsv } from '@/helpers/export-csv';

export default {
  name: 'SurveyResult',
  components: {
    Icon,
  },
  props: {
    survey: Object,
    isSharedResult: Boolean,
    isWebinarSurvey: Boolean,
  },
  data() {
    return {
      choicesMap: {},
      questions: [],
      rooms: [],
      selectedRoom: null,
      userList: [],
      openedResultId: null,
      openedResultIndex: null,
    };
  },
  computed: {
    shortedFileName() {
      let trimmedTitle = this.survey.Title.trim();
      return trimmedTitle.length > 60 ? `${trimmedTitle.substr(0, 60)}...` : trimmedTitle;
    },
  },
  async created() {
    this.getQuestions();
    this.getRooms();
  },
  methods: {
    ...mapActions('Survey', ['getSurveyStatistic', 'getSurveyQuestions', 'getQuestionOptions', 'getAssignedUserGroup']),
    showUsersDialog(option, optionId, optionIndex) {
      if (this.survey.IsPrivate) return;
      this.userList = [];
      this.openedResultId = optionId;
      this.openedResultIndex = optionIndex;
      this.userList = this.choicesMap[option.Id]?.users || [];
    },
    getStats() {
      if (!this.selectedRoom) return;
      this.userList = [];
      this.openedResultId = null;
      this.openedResultIndex = null;
      const payload = { SurveyId: this.survey.Id, AssignedUserGroupId: this.selectedRoom };
      this.getSurveyStatistic(payload).then(response => {
        this.choicesMap = response.data.Result.QuestionChoiceModels.reduce((acc, cur) => {
          acc[cur.ChoiceId] = {
            voteCount: cur.Count,
            rate: cur.Rate,
            users: cur.UserNames,
          };
          return acc;
        }, {});
        this.totalVote = response.data.Result.Total;
      });
    },
    async getQuestions() {
      this.loading = true;
      const payload = {
        SurveyId: this.survey.Id,
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 10,
          },
        },
      };

      const questionRes = await this.getSurveyQuestions(payload);
      const questions = questionRes.data.Result.List;

      const optionRequests = questions.map(q => this.getOptions(q));
      const options = await Promise.all(optionRequests);

      const qList = questions.map((q, i) => {
        const question = q;
        q.number = i + 1;
        question.options = options[i];
        this.$set(this.choicesMap, q.Id, q.HasMultichoices ? [] : null);
        return question;
      });

      this.loading = false;
      this.$set(this, 'questions', qList);
    },
    async getOptions(question) {
      const payload = {
        SurveyQuestionsId: question.Id,
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 10,
          },
        },
      };

      const response = await this.getQuestionOptions(payload);
      return response.data.Result.List;
    },
    async getRooms() {
      const payload = {
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 10,
          },
        },
      };

      const response = await this.getAssignedUserGroup(payload);
      const rooms = response.data.Result.List.filter(d => d.Survey.Id === this.survey.Id)
        .map(d => ({
          text: `${d.UserGroupName.split('--')[2]}${d.UserGroupName.split('--')[0] === 'webinar' ? '(webinar)' : ''}` || '-',
          value: d.Id,
        }))
        .filter(room => {
          if (this.isSharedResult) {
            if (this.isWebinarSurvey) {
              return room.text.includes('(webinar)');
            } else {
              return !room.text.includes('(webinar)');
            }
          } else {
            return room;
          }
        })
        .reverse();

      this.selectedRoom = rooms[0]?.value || null;
      this.rooms = rooms;
    },
    getRate(choiceId) {
      return this.choicesMap[choiceId]?.rate || 0;
    },
    getVoteCount(choiceId) {
      return this.choicesMap[choiceId]?.voteCount || 0;
    },
    closeList() {
      this.openedResultId = null;
      this.openedResultIndex = null;
      this.userList = [];
    },
    getChoicesUsers(optionId) {
      let users = this.choicesMap[optionId]?.users;
      if (users) {
        return users.filter((user, index) => index < 4)?.join(', ');
      } else {
        return '';
      }
    },
    usersLength(optionId) {
      let users = this.choicesMap[optionId]?.users;
      if (users && users.length > 4) {
        return true;
      } else {
        return false;
      }
    },
    downloadSurveyResults() {
      let mappedQuestions = [];
      this.questions.forEach((question, questionIndex) => {
        question.options.forEach((option, indexOption) => {
          const choiceResults = this.choicesMap[option.Id];
          let Rate = choiceResults?.rate || 0 + '%';
          let Users = choiceResults?.users.toString() || '';
          let VoteCount = choiceResults?.voteCount || 0;
          mappedQuestions.push({
            [this.$t('questionNo').toLocaleUpperCase()]: indexOption === 0 ? questionIndex + 1 : '',
            [this.$t('question').toLocaleUpperCase()]: indexOption !== 0 ? '' : question.Questions,
            [this.$t('choice').toLocaleUpperCase()]: option.Choice,
            [this.$t('rate').toLocaleUpperCase()]: Rate,
            [this.$t('users').toLocaleUpperCase()]: Users,
            [this.$t('voteCount').toLocaleUpperCase()]: VoteCount,
          });
        });
        mappedQuestions.push({});
      });
      let fileName = `${this.shortedFileName}-${this.rooms.find(room => room.value === this.selectedRoom).text}.csv`;
      jsonToCsv(mappedQuestions, fileName);
    },
  },
  watch: {
    selectedRoom() {
      this.getStats();
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-result-wrapper {
  position: relative;

  .download-result {
    position: absolute;
    right: 0;
    top: -3rem;
    &--text {
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .survey-result {
    font-size: 14px;
    border: 1px solid var(--v-body-base);
    border-radius: 8px;
    padding: 18px 10px;

    .question {
      font-size: 14px;
      border-radius: 20px;

      .question-text {
        word-break: break-word;
        max-height: 77px;
        overflow: auto;
      }
    }

    .option-wrapper {
      position: relative;
      border: 1px solid var(--v-body-base);
      border-radius: 8px;

      &.public {
        cursor: pointer;
      }

      .option {
        padding: 0.5rem;

        margin-bottom: 0.5rem;
        border-radius: 8px;
        position: relative;
        z-index: 10;
        .option-text {
          word-break: break-word;
          max-height: 80px;
          overflow: auto;

          &.active {
            max-height: unset;
          }
        }
        .percent {
          position: absolute;
          top: 0;
          right: 0;
          background-color: var(--survey-result-percent-backgorund); // var(--survey-result-percent-color)
          border-radius: 0px 8px 0 8px;
          padding: 0.3rem;
          font-size: 12px;
          color: var(--survey-result-percent-color); // #09f05d
        }

        .vote-count {
          font-size: 13px;
        }
      }

      .bar {
        position: absolute;
        left: 0%;
        top: 0%;
        height: 100%;
        border-radius: 8px;
        transition: width 0.4s;
        background-color: var(--v-bar-blue-base);
        z-index: 1;
      }
    }
  }
  .userList {
    max-height: 100px;
    overflow-y: scroll;
  }
  .voter {
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 95%;
  }
}
</style>
