var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "meet-bottom-menu",
          style: { visibility: _vm.conferenceJoined ? "visible" : "hidden" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "align-items": "center" } },
            [
              _c("menu-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "microphone-top-button",
                  dropdownList: _vm.microphoneList,
                  dropdownClass: "mt-n2",
                  icon: _vm.microphoneIcon,
                  color: _vm.microphoneColor,
                  disabled:
                    !_vm.hasMicrophone ||
                    !_vm.microphoneAllowed ||
                    (_vm.user.audioMutedByModerator && !_vm.user.isModerator),
                  "show-menu-on-hover": "",
                  top: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.setAudioMute(!_vm.user.audioMuted)
                  },
                },
              }),
              _c("menu-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "camera-top-button",
                  dropdownList: _vm.cameraList,
                  dropdownClass: "mt-n2",
                  icon: _vm.videoIcon,
                  color: _vm.videoColor,
                  disabled:
                    !_vm.hasCamera ||
                    !_vm.cameraAllowed ||
                    (_vm.user.videoMutedByModerator && !_vm.user.isModerator),
                  top: "",
                  "show-menu-on-hover": "",
                },
                on: {
                  click: function ($event) {
                    return _vm.setVideoMute(!_vm.user.videoMuted)
                  },
                },
              }),
              _c("menu-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "speaker-top-button",
                  dropdownList: _vm.speakerList,
                  dropdownClass: "mt-n2",
                  disabled: _vm.isSpeakerDisabled,
                  icon: _vm.speakerIcon,
                  color: _vm.speakerColor,
                  top: "",
                  "show-menu-on-hover": "",
                },
                on: {
                  click: function ($event) {
                    return _vm.setSpeakerMute(!_vm.speakerMuted)
                  },
                },
              }),
              !_vm.$isMobile
                ? _c("div", { staticClass: "menu-btn-spacer mr-1" })
                : _vm._e(),
              _vm.$can("handsUp", "Meet")
                ? _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "hands-up-top-button",
                      icon: "hand",
                      color: _vm.handsUpIconColor,
                      "tooltip-text": _vm.$t("raiseHand"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setHandsUp(!_vm.user.handsUp)
                      },
                    },
                  })
                : _vm._e(),
              !_vm.$isMobile
                ? _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "desktop-share-top-button",
                      icon: "monitor-1",
                      disabled: _vm.disableScreenShare,
                      color: _vm.user.screenSharing ? "success" : "",
                      "tooltip-text": _vm.$t("screenShare"),
                    },
                    on: { click: _vm.toggleScreenShare },
                  })
                : _vm._e(),
              _vm.showRecordingButton
                ? _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "recording-top-button",
                      icon: _vm.recordingButtonIcon,
                      loading: _vm.recordLoading,
                      "tooltip-text": _vm.$t("record"),
                      text: _vm.showRecordDuration,
                      width: _vm.showRecordDuration ? 120 : undefined,
                    },
                    on: { click: _vm.setShowRecordingModal },
                  })
                : _vm._e(),
              _vm.$can("use", "Chat")
                ? _c("menu-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "messages-top-button",
                      icon: "chat",
                      badgeContent: _vm.getUnreadMessageCount,
                      badgeWebinarContent: _vm.getWebinarUnreadMessageCount,
                      "tooltip-text": _vm.$t("chat"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.TOGGLE_CHAT_SCREEN()
                      },
                    },
                  })
                : _vm._e(),
              !_vm.$isMobile
                ? _c("div", { staticClass: "menu-btn-spacer mr-1" })
                : _vm._e(),
              _c("menu-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "leave-conference-top-button",
                  dropdownList: _vm.logoutItems,
                  dropdownClass: "mt-n2",
                  icon: "call",
                  color: "logo-color",
                  top: "",
                  "tooltip-text": _vm.$t("leaveCall"),
                  useInnerModel: _vm.user.authenticated,
                },
                on: { click: _vm.leave },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }