<template>
  <div class="lang-switch-temp text-center">
    <v-menu class="text-center lang-switch-menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn height="30" width="60" small class="text-uppercase change-lang-btn" color="var(--v-date-20)" v-bind="attrs" v-on="on">
          <span class="lang-text">{{ lang }}</span>
          <v-icon class="ml-1 lang-switch-chevron-icon" right x-small left dense>feather icon-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list color="blackrussian" class="select-language-list ma-0 pa-0" tile>
        <v-list-item class="px-0 select-language-list-items d-flex justify-center align-center" v-for="(language, index) in languages" :key="index">
          <v-btn
            small
            width="52"
            height="18"
            :color="lang === language ? 'var(--v-date-20)' : 'transparent'"
            class="text-uppercase select-lang-button change-lang-btn"
            depressed
            @click="setLang(language)"
          >
            <span class="lang-text ">
              {{ language }}
            </span>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'LangSwitch',
  computed: {
    ...mapState(['lang']),
    languages() {
      return Object.keys(this.$i18n.messages);
    },
  },
  methods: {
    ...mapMutations(['SET_LANG']),
    setLang(lang) {
      this.SET_LANG(lang);
      this.$vuetify.lang.current = lang;
    },
  },
};
</script>
<style lang="scss" scoped>
.lang-switch-temp {
  .change-lang-btn {
    border-radius: 8px;
    box-shadow: none;
    .lang-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
  .v-list.select-language-list.v-sheet.theme--dark {
    &:not(.v-sheet--outlined) {
      box-shadow: none !important;
    }
    .v-list-item {
      min-height: auto !important;
    }
  }
}
.v-menu__content.theme--dark.menuable__content__active {
  box-shadow: none;
  border-radius: 8px !important;
}
.select-language-list-items {
  min-height: 30px !important;
}
</style>
