import Enums from "./enums";

/**
 *
 * @name defaultRoomConfig
 */
export default {
  [Enums.RoomConfigEnums.ALLOW_FILE_SHARE]: true,
  [Enums.RoomConfigEnums.ALLOW_PRIVATE_CHAT]: true,
  [Enums.RoomConfigEnums.ALLOW_SCREEN_SHARE]: true,
  [Enums.RoomConfigEnums.ALLOW_ADMIN_START_VIDEO]: null,
  [Enums.RoomConfigEnums.START_MUTED_POLICY]: Enums.RoomConfigEnums.AUDIO_UNMUTED,
  [Enums.RoomConfigEnums.START_VIDEO_MUTED_POLICY]: Enums.RoomConfigEnums.VIDEO_UNMUTED,
  [Enums.RoomConfigEnums.REMOTE_RECORD_STARTED_BY]: false,
  [Enums.RoomConfigEnums.SHARED_PROPERTIES]: [],
  [Enums.RoomConfigEnums.E2EE_ENABLED]: false,
  [Enums.RoomConfigEnums.PACKAGE_CONFIG]: {},
  [Enums.RoomConfigEnums.LOBBY_ENABLED]: false,
  [Enums.RoomConfigEnums.FOLLOW_MODERATOR_FULL_SCREEN]: false,
};
