<template>
  <div class="right-menu">
    <div class="right-menu--header">
      <div class="py-6 px-4 close-icon"><icon @click="$emit('close')" icon="x"></icon></div>
      <v-tabs :value="activeRightMenuTab" grow show-arrows icons-and-text>
        <v-tab v-show="$can('use', 'Chat')" href="#chat" :ripple="false" @click.native="SET_CURRENT_RIGHT_MENU_TAB('chat')">
          <div class="h-full">
            <div class="icon">
              <icon icon="chat-1"></icon>
            </div>
            <span class="mt-2">
              {{ $t('chat') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#user-list" :ripple="false" @click="SET_CURRENT_RIGHT_MENU_TAB('user-list')">
          <div class="h-full">
            <div class="icon">
              <icon icon="users"></icon>
            </div>
            <span class="mt-2">
              {{ $t('userList') }}
            </span>
          </div>
        </v-tab>
        <v-tab v-if="showLobbyTab" href="#lobby" :ripple="false" @click="SET_CURRENT_RIGHT_MENU_TAB('lobby')">
          <div class="h-full">
            <div class="icon">
              <icon icon="users"></icon>
            </div>
            <span class="mt-2">
              {{ $t('lobby') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#webinar-chat" v-if="showWebinarChatTab" @click.native="toggleWebinarChatAndReadMessages">
          <div class="h-full">
            <div class="icon">
              <icon icon="webinar-chat"></icon>
            </div>
            <span class="mt-2">{{ $t('webinarChat') }}</span>
          </div>
        </v-tab>
        <v-tab v-if="isSurveyUsable" href="#survey" :ripple="false" @click.native="SET_CURRENT_RIGHT_MENU_TAB('survey')">
          <div class="h-full">
            <div class="icon">
              <icon icon="check-1"></icon>
            </div>
            <span class="mt-2">
              {{ $t('survey') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#ar" :ripple="false" v-if="showARTab" @click.native="SET_CURRENT_RIGHT_MENU_TAB('ar')">
          <div class="h-full">
            <div class="icon">
              <icon icon="xperremote"></icon>
            </div>
            <span class="mt-2">
              {{ $t('augmentedReality') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#whiteboard" :ripple="false" v-if="whiteBoardSupported" @click.native="SET_CURRENT_RIGHT_MENU_TAB('whiteboard')">
          <div class="h-full">
            <div class="icon">
              <icon icon="clipboard-1"></icon>
            </div>
            <span class="mt-2 text-capitalize">
              {{ $t('whiteBoard') }}
            </span>
          </div>
        </v-tab>
        <v-tab href="#shared-files" :ripple="false" @click.native="SET_CURRENT_RIGHT_MENU_TAB('shared-files')">
          <div class="h-full">
            <div class="icon">
              <icon icon="folder"></icon>
            </div>
            <span class="mt-2 text-capitalize">
              {{ $t('sharedFiles') }}
            </span>
          </div>
        </v-tab>
      </v-tabs>
    </div>
    <div>
      <v-tabs-items touchless :value="activeRightMenuTab" style="flex-grow: 1;">
        <v-tab-item value="chat" v-if="$can('use', 'Chat')">
          <chat
            body-class="chat-window"
            body-id="conference-chat"
            show-actions
            :messages="messages"
            :sendPrivateMessageTo="sendPrivateMessageTo"
            :enablePrivateMessage="true"
            :chatEnabled="chatEnabled"
            :show-download-messages="isModerator && $can('downloadMessages', 'Chat')"
            :show-file-share-button="fileShareSupported"
            @sendButtonClicked="onSendButtonClicked"
            @privateMessageClicked="onPrivateMessageClicked"
            @privateMessageCancelled="onPrivateMessageCancalled"
            @scrollEnd="onChatScrollEnd"
            @readMessages="READ_MESSAGES"
          />
        </v-tab-item>
        <v-tab-item value="user-list">
          <users
            :users="userList"
            :local-user="getLocalUser"
            @audioMuteClicked="muteAudio"
            @videoMuteClicked="muteVideo"
            @handsUpClicked="onHandsUpClicked"
            @getInClicked="onGetInClicked"
            @webinarKickClicked="onGetOutClicked"
          />
        </v-tab-item>
        <v-tab-item v-if="showLobbyTab" value="lobby">
          <lobby-right-menu-tab-content />
        </v-tab-item>
        <v-tab-item v-if="showWebinarChatTab" value="webinar-chat">
          <chat
            show-actions
            body-id="conference-webinar-chat"
            :chat-enabled="isWebinarStarted"
            :show-avatar="false"
            body-class="webinar-window"
            :messages="webinarMessages"
            :show-download-messages="isWebinarModerator && $can('downloadWebinarMessages', 'Chat')"
            :show-file-share-button="fileShareSupported"
            @sendButtonClicked="onWebinarSendButtonClicked"
            @scrollEnd="onWebinarChatScrollEnd"
            @readMessages="READ_WEBINAR_MESSAGES"
          />
        </v-tab-item>
        <v-tab-item value="survey">
          <survey :isModerator="isModerator" />
        </v-tab-item>
        <v-tab-item value="shared-files">
          <shared-file-list></shared-file-list>
        </v-tab-item>
        <v-tab-item v-if="showARTab" value="ar">
          <ar-right-menu />
        </v-tab-item>
        <v-tab-item v-if="whiteBoardSupported" value="whiteboard">
          <div style="position:relative; width: 100%; height: 100%">
            <WhiteBoard />
            <div class="white-board-extend-button cursor-pointer d-flex align-center justify-center" @click="TOGGLE_WHITE_BOARD">
              <Icon icon="full-screen" color="body" />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Chat from '@/components/Chat/Chat';
import Users from '@/components/Users/Users';
import Icon from '@/components/Shared/Icon';
import Survey from '@/components/Survey/SurveyHome';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import ArRightMenu from '@/components/AR/RightMenu/ArRightMenu';
import WhiteBoard from '@/components/WhiteBoard/WhiteBoard';
import SharedFileList from '@/components/File/SharedFileList';
import LobbyRightMenuTabContent from '@/components/Lobby/LobbyRightMenuTabContent';

export default {
  name: 'RightMenu',
  components: {
    LobbyRightMenuTabContent,
    WhiteBoard,
    SharedFileList,
    ArRightMenu,
    Icon,
    Users,
    Chat,
    Survey,
  },
  computed: {
    ...mapState('Conference', ['activeRightMenuTab', 'messages', 'sendPrivateMessageTo', 'conferenceJoined', 'roomConfig']),
    ...mapGetters('Conference', ['getUsers', 'getLocalUser', 'remoteCameraControlSupported', 'showARTab']),
    ...mapState('Webinar', { webinarMessages: 'messages' }),
    ...mapState('Lobby', ['joinResponseWaiting']),
    ...mapGetters('Webinar', {
      webinarUsers: 'getUsers',
      isWebinarStarted: 'isWebinarStarted',
      showWebinarChatTab: 'showWebinarChatTab',
      webinarLocalUser: 'getLocalUser',
    }),
    showLobbyTab() {
      return (this.roomConfig.lobbyEnabled && this.getLocalUser.isModerator) || this.joinResponseWaiting;
    },
    whiteBoardSupported() {
      return this.getLocalUser?.modules?.whiteBoard && this.$can('see', 'WhiteBoard');
    },
    userList() {
      return [...this.getUsers, ...this.webinarUsers.filter(u => u.type !== 'local' && u.role === 'viewer')];
    },
    chatEnabled() {
      return this.conferenceJoined && this.$can('use', 'Chat');
    },
    isModerator() {
      return this.getLocalUser && this.getLocalUser.role === 'moderator';
    },
    isWebinarModerator() {
      return this?.webinarLocalUser?.role === 'moderator';
    },

    isSurveyUsable() {
      return this.$customerConfig?.system?.modules?.survey && this.$can('see', 'Survey');
    },
    fileShareSupported() {
      return this.$customerConfig?.system?.modules?.fileShare && this.$can('see', 'FileShare') && this.roomConfig?.allowFileShare;
    },
  },
  methods: {
    ...mapMutations('Conference', ['SET_CURRENT_RIGHT_MENU_TAB', 'TOGGLE_WHITE_BOARD', 'READ_MESSAGES']),
    ...mapMutations('Webinar', ['READ_WEBINAR_MESSAGES']),
    ...mapActions('Conference', ['setAudioMute', 'setVideoMute', 'setRemoteAudioMute', 'setRemoteVideoMute', 'sendMessage', 'setPrivateMessageTo']),
    ...mapActions('Webinar', { webinarSendMessage: 'sendMessage', getInViewer: 'getInViewer', kickUserToWebinar: 'kickUserToWebinar' }),
    muteAudio(userId, muteState) {
      if (userId === this.getLocalUser.id) {
        this.setAudioMute(muteState);
      } else {
        this.setRemoteAudioMute({ participantId: userId, muteState });
      }
    },
    muteVideo(userId, muteState) {
      if (userId === this.getLocalUser.id) {
        this.setVideoMute(muteState);
      } else {
        this.setRemoteVideoMute({ participantId: userId, muteState });
      }
    },
    onSendButtonClicked(message) {
      this.sendMessage({
        userId: this.sendPrivateMessageTo?.id,
        message: message,
      });
    },
    onWebinarSendButtonClicked(message) {
      this.webinarSendMessage({
        message: message,
      });
    },
    onPrivateMessageClicked(event) {
      this.setPrivateMessageTo({ id: event.id, name: event.name });
    },
    onPrivateMessageCancalled() {
      this.setPrivateMessageTo(null);
    },
    onChatScrollEnd() {
      this.READ_MESSAGES();
    },
    onWebinarChatScrollEnd() {
      if (this.activeRightMenuTab === 'webinar-chat') {
        this.READ_WEBINAR_MESSAGES();
      }
    },
    onHandsUpClicked() {},
    onGetInClicked(user) {
      this.getInViewer(user.id);
    },
    onGetOutClicked(user) {
      this.kickUserToWebinar(user.id);
    },
    toggleWebinarChatAndReadMessages() {
      this.SET_CURRENT_RIGHT_MENU_TAB('webinar-chat');
      this.READ_WEBINAR_MESSAGES();
    },
  },
};
</script>

<style lang="scss">
.right-menu {
  .v-tabs-items > .v-window__container,
  .v-tabs-items > .v-window-item,
  .v-tabs-items > .v-window__container > .v-window-item {
    & > div {
      height: calc(var(--100vh) - 165px);
    }
  }
}
</style>
<style scoped lang="scss">
.right-menu {
  animation-duration: 0.5s;
  animation-name: slideout;
  z-index: 17;
  background-color: var(--v-darkblue-base);

  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: ease all 0.3s;
  grid-template-rows: auto 1fr;
  display: grid;
  height: 100vh;
  @media (max-width: $breakpoint-tablet) {
    & {
      width: 100%;
      position: absolute;
    }
  }

  &--header {
    min-width: 320px;
    max-width: 100vw;

    .close-icon {
      text-align: right;
      .v-icon {
        cursor: pointer;
      }
    }
  }
  &--content {
    display: grid;
    .right-menu-chat {
      display: grid;
      grid-template-rows: 1fr auto;
      max-height: calc(var(--100vh) - 60px);
    }
  }

  ::v-deep .text {
    font-weight: 100;

    font-size: 16px;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }

  .white-board-extend-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
  }
}
</style>
