var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message",
      class: {
        incoming: _vm.message.incoming,
        private: _vm.message.private,
        "notification mb-3": _vm.message.type === "notification-message",
        "mb-8": _vm.message.type !== "notification-message",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center",
          class: { "flex-row-reverse": !_vm.message.incoming },
        },
        [
          _vm.showAvatar && _vm.message.type !== "notification-message"
            ? _c("avatar", {
                attrs: {
                  "avatar-size": 48,
                  "display-name": _vm.displayName,
                  image: _vm.getUserById(_vm.message.userId)
                    ? _vm.getUserById(_vm.message.userId).avatar
                    : "",
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column px-2",
              class: { "w-full": _vm.isNotificationMessage },
            },
            [
              _vm.message.type !== "notification-message"
                ? _c(
                    "div",
                    {
                      staticClass: "message--sender mb-2 d-flex align-center",
                      class: { incoming: _vm.message.incoming },
                    },
                    [
                      _c("span", { staticClass: "message-text" }, [
                        _vm._v(" " + _vm._s(_vm.sender) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "message--body d-flex align-center",
                  class: {
                    "flex-row-reverse":
                      !_vm.message.incoming && !_vm.isNotificationMessage,
                    "flex-column": _vm.isNotificationMessage,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "message--text",
                      class: {
                        incoming: _vm.message.incoming,
                        "notification-message": _vm.isNotificationMessage,
                      },
                    },
                    [
                      _c(_vm.messageComponent, {
                        tag: "component",
                        attrs: { message: _vm.message },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "message--date mr-3",
                      class: {
                        "mr-3": !_vm.message.incoming,
                        "ml-3": _vm.message.incoming,
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.formatDate(_vm.message.date)) + " "
                      ),
                    ]
                  ),
                ]
              ),
              _vm.enablePrivateMessage && _vm.privateMessageFooter
                ? _c(
                    "div",
                    {
                      staticClass: "message--footer mt-1",
                      class: {
                        "d-flex flex-row-reverse": !_vm.message.incoming,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.privateMessageFooter) + " ")]
                  )
                : _vm._e(),
              _vm.enablePrivateMessage && _vm.showReplyButton
                ? _c(
                    "div",
                    {
                      staticClass: "cursor-pointer message--text--private mt-2",
                      on: { click: _vm.sendPrivateMessage },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("sendPrivateMessage")) + " ")]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }