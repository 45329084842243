<template>
  <v-app class="xpermeet" :class="classList">
    <div class="xpermeet-bg" v-if="showBackground" :style="{ backgroundImage: backgroundImage, backgroundColor: backgroundColor }"></div>
    <v-main>
      <router-view></router-view>
    </v-main>
    <logo-left-bottom />
    <GDPR />
    <toaster />
    <SystemErrorModal />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import XperMeetLib from 'xpermeet-lib';

import LogoLeftBottom from '@/components/Shared/LogoLeftBottom';
import SystemErrorModal from '@/components/Shared/Modal/SystemErrorModal';
import GDPR from '@/components/Shared/GDPR';

import { PREDEFINED_BACKGROUNDS } from '@/constants/background';
import ENUMS from '@/constants/ENUMS';
import storage from '@/services/storage';
import { consoleLog } from '@/helpers/logger'; // eslint-disable-line
import Toaster from '@/components/Notifications/Toast/Toaster';

export default {
  name: 'Xpermeet',
  components: {
    Toaster,
    GDPR,
    LogoLeftBottom,
    SystemErrorModal,
  },
  data: () => ({
    user: {},
    isThemeOpened: false,
    timestampInterval: null,
  }),
  computed: {
    ...mapGetters('Conference', ['getBackground', 'getTheme', 'getRadius']),
    ...mapGetters(['getAppVersion', 'getCustomerBackground']),
    ...mapState(['lang']),
    classList() {
      return {
        'theme-light': this.getTheme === 'light',
        'theme-dark': this.getTheme === 'dark',
        'theme-flat': this.getRadius === 'flat',
        'theme-rounded': this.getRadius === 'rounded',
      };
    },
    showBackground() {
      return this.$route.name !== 'webinar';
    },
    copyrightText() {
      return this.$customerConfig?.page?.copyright?.[this.lang]?.text;
    },
    copyrightUrl() {
      return this.$customerConfig?.page?.copyright?.[this.lang]?.url;
    },
    copyrightLink() {
      return this.$customerConfig?.page?.copyright?.[this.lang]?.link;
    },
    showVersion() {
      return this.$customerConfig?.system?.showVersion;
    },
    backgroundImage() {
      let background = this.getBackground;

      if (this.$route.name === 'prejoin') {
        return 'url("/img/background/background-default.png")';
      }

      if (this.$route.name === 'home' || this.$route.name === 'notfound' || !background) {
        return '';
      }

      if (background.startsWith('data:') || background.startsWith('http:') || background.startsWith('https:') || background.startsWith('/')) {
        return `url("${background}")`;
      }

      return '';
    },
    backgroundColor() {
      let background = this.getBackground;

      if (background && (background.startsWith('rgb') || background.startsWith('#'))) {
        return background;
      } else {
        return '';
      }
    },
    isPrejoin() {
      return this.$route.name === 'prejoin';
    },
  },
  created() {
    if (this.getCustomerBackground) {
      this.SET_BACKGROUND(this.getCustomerBackground);
    } else {
      this.SET_BACKGROUND(storage.getItem('background') || PREDEFINED_BACKGROUNDS[0]);
    }

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.SET_PAGE_SIZE(this.$customerConfig.page?.pagination?.defaultPageSize || ENUMS.DEFAULT_PAGE_SIZE);
    this.setTimeStamp();
  },

  beforeDestroy() {
    clearInterval(this.timestampInterval);
  },
  mounted() {
    this.$goc.set('XperMeetLib', XperMeetLib);
    consoleLog('App Mounted.');
  },
  methods: {
    ...mapMutations(['SET_BACKGROUND']),
    ...mapMutations('Conference', ['SET_PAGE_SIZE']),
    ...mapActions('Conference', ['fetchServerTime', 'setServerTime']),
    async setTimeStamp() {
      const serverTime = await this.fetchServerTime();
      let sec = 0;
      this.timestampInterval = setInterval(() => {
        const time = serverTime + sec * 1000;
        sec += 1;
        this.setServerTime(time);
      }, 1000);
    },
  },
  watch: {
    lang: {
      immediate: true,
      handler() {
        this.$vuetify.lang.current = this.lang;
      },
    },
  },
};
</script>

<style lang="scss">
.v-main {
  z-index: 1;
}
.xpermeet-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center !important;
  background-size: cover !important;
  background-color: var(--v-darkblue-base);
}
.xpermeet-bg::before {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 0;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
}

.copyright-wrapper {
  display: flex;
  flex-direction: column;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  .copyright {
    font-size: 0.75rem;
    line-height: 1.8;

    a {
      text-decoration: none;
    }
  }

  .app-version {
    font-size: 12px;
  }
}

div[role='menu'] > .app-transition {
  transition: var(--default-transition) !important;
  background-color: var(--right-menu-background-color);
  border-radius: var(--default-radius);
}
</style>
