import { ERROR_HANDLER_ENUMS } from "../constants/enums";

class ErrorHandler {
  constructor() {
    this.callbacks = [];
    this.errors = [];
    this.callback = null;
    var gOldOnError = window.onerror;

    window.onerror = (errorMsg, url, lineNumber, columnNo, error) => {
      let errorObj = {
        source: "window",
        message: "Window Error Message",
        error: error,
        url: url,
        lineNumber: lineNumber,
        column: columnNo,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
      };

      if (errorObj.error?.message) {
        let stropheError = errorObj.error.message.toUpperCase().indexOf("STROPHE") >= 0;
        let totalDominantSpeakerTimeError = errorObj.error.message.indexOf("totalDominantSpeakerTime") >= 0;
        let websocketError = errorObj.error.message.toUpperCase().indexOf("WEBSOCKET") >= 0;

        if (errorObj.error.message === "Ping timeout") {
          errorObj.code = ERROR_HANDLER_ENUMS.NETWORK_ERROR;
          errorObj.message = "Connection lost...";
          console.log("%cConnection lost...", "color: red;");
          this.addError(errorObj);
        }

        if (stropheError && websocketError && !totalDominantSpeakerTimeError) {
          errorObj.code = ERROR_HANDLER_ENUMS.STROPHE_ERROR;
          errorObj.message = "Websocket connection lost...";
          console.log("%cWebsocket connection lost...", "color: blue;");
          this.addError(errorObj);
        }

        if (websocketError && !totalDominantSpeakerTimeError) {
          errorObj.code = ERROR_HANDLER_ENUMS.SOCKET_ERROR;
          errorObj.message = "Websocket connection lost...";
          console.log("%cWebsocket connection lost...", "color: blue;");
          this.addError(errorObj);
        }
      }

      if (gOldOnError) return gOldOnError(errorMsg, url, lineNumber, columnNo, error);
      return false;
    };
  }
  addError(error) {
    if (this.errors.length > 25) {
      this.errors.shift();
    }
    this.errors.push(error);

    if (this.callbacks.length) {
      this.callbacks.forEach((e) => e(error));
    }
  }
  getLastError() {
    return this.errors[this.errors.length - 1];
  }
  getErrors() {
    return this.errors;
  }
  onError(callback) {
    this.callbacks.push(callback);
  }
  setAxiosErrorInterceptor(axios) {
    axios.interceptors.response.use(
      (successfulReq) => {
        return successfulReq;
      },
      (error) => {
        let errorObj = {
          source: "axios",
          message: "serviceNetworkError",
          error: error,
        };

        errorObj.code = ERROR_HANDLER_ENUMS.SRVICE_ERROR;

        this.addError(errorObj);

        return Promise.reject(error);
      },
    );
    return axios;
  }
}
export default new ErrorHandler();
