export function msToHour(ms) {
  const hours = `0${new Date(ms).getUTCHours()}`.slice(-2);
  const minutes = `0${new Date(ms).getUTCMinutes()}`.slice(-2);
  const seconds = `0${new Date(ms).getUTCSeconds()}`.slice(-2);

  return `${hours}:${minutes}:${seconds}`;
}

export function secondsToTime(seconds = 0) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function getCurrentYear() {
  return new Date().getFullYear();
}
