<template>
  <div v-if="questionsLoading" class="text-center my-5">
    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
  </div>
  <div v-else-if="isFinished" class="finish">
    <p>{{ $t('theSurveyWasCompleted') }}</p>
    <v-btn color="secondary" @click="goToHome">{{ $t('goToHome') }}</v-btn>
  </div>
  <div class="survey-content" v-else>
    <div class="content" v-for="(q, qIndex) in questions" :key="q.Id">
      <div class="survey-content--question">
        <b style="color: var(--v-whisper-50)">{{ qIndex + 1 }}.</b>
        {{ q.Questions }}
      </div>
      <v-radio-group hide-details v-model="choicesMap[q.Id]" v-if="!q.HasMultichoices">
        <v-radio v-for="o in q.options" :key="o.Id" :label="o.Choice" :value="o.Id"></v-radio>
      </v-radio-group>
      <div v-else>
        <v-checkbox color="white" hide-details v-model="choicesMap[q.Id]" v-for="o in q.options" :label="o.Choice" :value="o.Id" :key="o.Id"></v-checkbox>
      </div>
      <v-divider v-if="qIndex !== questions.length - 1" class="my-2"></v-divider>
      <div v-else class="d-flex flex-end">
        <v-btn small color="secondary" class="my-3" :disabled="isEmptyQuestionExists" :loading="loading" @click="complete">
          {{ $t('complete') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { consoleError } from '@/helpers/logger';

export default {
  name: 'Survey',
  props: {
    survey: Object,
    assignedSurvey: Object,
    goToHome: Function,
    addCompleted: Function,
  },
  data() {
    return {
      loading: false,
      questionsLoading: true,
      questions: [],
      choicesMap: {},
      answerMap: {},
      answers: [],
    };
  },
  computed: {
    isFinished() {
      return this.answers.length === this.questions.length;
    },
    isEmptyQuestionExists() {
      const choices = Object.values(this.choicesMap);

      if (choices.length !== this.questions.length) {
        return true;
      }

      return choices.some(a => !a || (Array.isArray(a) && a.length === 0));
    },
  },
  mounted() {
    this.getQuestions();
  },
  methods: {
    ...mapActions('Survey', ['getSurveyQuestions', 'getQuestionOptions', 'updateSurveyAnswers']),
    ...mapActions('Notification', ['showToastNotification']),
    async getQuestions() {
      try {
        this.questionsLoading = true;
        const payload = {
          SurveyId: this.survey.Id,
          Parameters: {
            Filters: [
              {
                Property: 'IsDeleted',
                Comparison: '',
                Value: 'false',
              },
            ],
            Page: {
              Index: 0,
              Size: 10,
            },
          },
        };

        const questionRes = await this.getSurveyQuestions(payload);
        const questions = questionRes.data.Result.List;

        const optionRequests = questions.map(q => this.getOptions(q));
        const options = await Promise.all(optionRequests);

        const qList = questions.map((q, i) => {
          const question = q;
          q.number = i + 1;
          question.options = options[i];
          this.$set(this.choicesMap, q.Id, q.HasMultichoices ? [] : null);
          return question;
        });

        this.questionsLoading = false;
        this.$set(this, 'questions', qList);
      } catch (err) {
        consoleError(err);
        this.questionsLoading = false;
      }
    },
    async getOptions(question) {
      const payload = {
        SurveyQuestionsId: question.Id,
        Parameters: {
          Filters: [
            {
              Property: 'IsDeleted',
              Comparison: '',
              Value: 'false',
            },
          ],
          Page: {
            Index: 0,
            Size: 10,
          },
        },
      };

      const response = await this.getQuestionOptions(payload);
      return response.data.Result.List;
    },
    async complete() {
      try {
        if (this.isEmptyQuestionExists) {
          this.showToastNotification('atLeastOneQuestionRequired');
        }

        this.loading = true;
        const promises = this.questions.map(q => this.sendChoices(q));
        await Promise.all(promises);

        this.loading = false;

        if (this.answers.length === this.questions.length) {
          this.addCompleted(this.survey.Id);
        }
      } catch (err) {
        consoleError(err);
        this.loading = false;
      }
    },
    async sendChoices(question) {
      try {
        const choices = this.choicesMap[question.Id];
        const id = this.answerMap[question.Id] || undefined;

        const payload = {
          Id: id,
          assignedSurveyId: this.assignedSurvey.Id,
          surveyQuestionId: question.Id,
          choiceIds: Array.isArray(choices) ? choices : [choices],
        };
        const response = await this.updateSurveyAnswers(payload);
        const answer = response.data.Result;

        if (!payload.Id) {
          this.answers.push(answer);
          this.answerMap[question.Id] = answer.Id;
        }
      } catch (err) {
        consoleError(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.finish {
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.v-input {
  padding: 0.5rem;
}

.v-stepper {
  background-color: transparent;
  box-shadow: none;

  & > div {
    border: 1px solid var(--v-primary-base);
    border-radius: 8px;
    padding: 16px 12px;
    margin-bottom: 1rem;
  }

  .v-label {
    word-break: break-word;
  }
}

.v-stepper__step {
  padding: 0.5rem 0 0.5rem;
}

.v-stepper__content {
  margin: 0 !important;
  padding: 0;
}

.survey-form {
  .question-footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}

.survey-content {
  word-break: break-all;
}
</style>
