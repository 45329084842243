var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "mobile-left-menu",
      attrs: {
        value: _vm.showDrawerMenu,
        fixed: "",
        permanent: _vm.showDrawerMenu,
        width: "100%",
        color: "primary",
      },
      on: { input: _vm.onDrawerInput },
    },
    [
      _c(
        "div",
        { staticClass: "mobile-menu-header d-flex justify-space-between px-6" },
        [
          _c("logo", {
            staticStyle: { left: "30px" },
            attrs: { hideOnMobile: false, height: "30" },
          }),
          _c("icon", { attrs: { icon: "x" }, on: { click: _vm.closeDrawer } }),
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "mobile-menu-list", attrs: { nav: "", dense: "" } },
        [
          _vm._l(_vm.menuItems, function (item, index) {
            return [
              _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "pl-3",
                      class: item.class,
                      on: { click: item.onClick },
                    },
                    [_vm._v(_vm._s(_vm.$t(item.label)))]
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c("template", { slot: "append" }, [
        _c("div", { staticClass: "mobile-menu-bottom-text" }, [
          _vm._v(" " + _vm._s(_vm.aboutUsText) + " "),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }