var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("notifications.title"),
        "small-title": "",
        "padding-none": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "content mt-8" },
        [
          _vm.roomNotifications.length
            ? _c("Notifications")
            : _c(
                "div",
                {
                  staticClass:
                    "px-4 pb-4 d-flex mt-10 flex-column align-center text-center",
                },
                [
                  _c("Icon", {
                    staticClass: "mb-3",
                    attrs: { color: "white", icon: "bell", size: "40" },
                  }),
                  _c("span", { staticClass: "no-notification-text" }, [
                    _vm._v(_vm._s(_vm.$t("notifications.noNotificationsYet"))),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }