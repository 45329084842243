var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { title: _vm.$t("recordingUsers"), "max-width": "500px" } },
    [
      _c(
        "div",
        { staticClass: "ma-3 mb-0 speaker-stats" },
        [
          _c("v-virtual-scroll", {
            attrs: {
              items: _vm.getRecordingDurations,
              height: "300",
              "item-height": "64",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-list-item",
                      {
                        key: item.user.id,
                        staticClass: "mt-3",
                        attrs: { dense: "" },
                      },
                      [
                        _c("v-list-item-content", [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("avatar", {
                                attrs: {
                                  "display-name": item.user.displayName,
                                  image: item.user.avatar,
                                  "avatar-size": 48,
                                },
                              }),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(item.user.displayName)),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-row", [
                              _vm._v(_vm._s(_vm.getRecordTime(item))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }