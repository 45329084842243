export const ErrorWithCodes = {
  INVALID_CONFIG: "Invalid Config",
  AUTH_FAILED: "Authentication Failed",
  CONNECTION_SUCCESSFUL: "Connection Successful",
  CONNECTION_FAILED: "Connection Failed",
  CONFERENCE_MISSING_CONFIG: "Conference missing config",
  MODERATOR_MUTED: "Moderator Muted",
  PASSWORD_REQUIRED: "conference.passwordRequired",
  CONNECTION_PASSWORD_REQUIRED: "connection.passwordRequired",
  AUTHENTICATION_REQUIRED: "conference.authenticationRequired",
  ROOM_MAX_USERS_ERROR: "conference.max_users",
  INVALID_POLICY_TYPE: "Invalid Mute Policy Type",
  MEMBERS_ONLY: "conference.connectionError.membersOnly",
  CONFERENCE_ACCESS_DENIED: "conference.connectionError.accessDenied",
  LOBBY_NOT_SUPPORTED: "Lobby Not Supported!",
  ROOM_IS_NOT_ACTIVE: "ROOM_IS_NOT_ACTIVE",
  NOT_ALLOWED: "NOT_ALLOWED",
  NOT_ENOUGHT_SPACE: "NOT_ENOUGHT_SPACE",
  RECORD_FAILED_TO_START:"RECORD_FAILED_TO_START"
};
