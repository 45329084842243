var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lobby-right-menu-container pl-3 pr-2 pt-2" },
    [
      _c(
        "div",
        { staticClass: "lobby-right-menu-waiting-list" },
        [
          _c(
            "div",
            {
              staticClass:
                "hosts-title flex-grow-0 px-2 py-3 d-flex align-center",
            },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { icon: "users", size: "20" },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("waitingParticipantList"))),
              ]),
            ],
            1
          ),
          _c("text-field", {
            staticClass: "mx-2 mb-2",
            attrs: {
              name: "search-user",
              autocomplete: "off",
              backgroundColor: "body",
              label: _vm.$t("searchUsersByUserName"),
              "hide-details": "",
            },
            model: {
              value: _vm.searchByUserNameText,
              callback: function ($$v) {
                _vm.searchByUserNameText = $$v
              },
              expression: "searchByUserNameText",
            },
          }),
          _c(
            "div",
            { staticClass: "overflow-auto" },
            _vm._l(_vm.waitingUsers, function (user) {
              return _c(
                "UserCard",
                {
                  key: user.id,
                  attrs: {
                    me: _vm.getLocalUser && user.id === _vm.getLocalUser.id,
                    "display-name": _vm.getDisplayName(user),
                    moderator: user.role === "moderator",
                    participant: user.role === "participant",
                  },
                },
                [
                  _vm.getLocalUser.isModerator
                    ? _c(
                        "div",
                        { staticClass: "user-actions" },
                        [
                          _c("IconButton", {
                            attrs: {
                              icon: "check",
                              "background-color": "success",
                              width: "24",
                              "icon-size": "16",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.allow(user)
                              },
                            },
                          }),
                          _c("IconButton", {
                            staticClass: "ml-3",
                            attrs: {
                              icon: "close",
                              "background-color": "error",
                              width: "24",
                              "icon-size": "16",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reject(user)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "lobby-right-menu-chat-container" },
        [
          _c("div", { staticClass: "divider mt-3" }),
          _c(
            "div",
            {
              staticClass:
                "hosts-title flex-grow-0 px-2 py-3 d-flex align-center",
            },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { icon: "chat-1", size: "20" },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("chat"))),
              ]),
            ],
            1
          ),
          _c("Chat", {
            staticClass: "lobby-chat",
            attrs: {
              "body-id": "conference-lobby-chat",
              "chat-enabled": true,
              messages: _vm.lobbyMessages,
              "show-file-share-button": false,
              enablePrivateMessage: false,
            },
            on: { sendButtonClicked: _vm.onSendButtonClicked },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }