var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("maxUsersLimitReachedTitle"),
        "show-close-button": false,
        persistent: "",
        "confirm-button-text": _vm.$t("goToHome"),
        "cancel-button-text": _vm.$t("tryAgain"),
        "show-actions": "",
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.$t("maxUsersLimitReached"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }