var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t("startRecording"),
        "confirm-button-text": _vm.$t("startRecording"),
        "cancel-button-text": _vm.$t("cancel"),
        "show-actions": "",
        persistent: "",
        "confirm-button-disabled": _vm.startButtonIsDisabled,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.$t(_vm.desc))),
      ]),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-radio-group",
            {
              attrs: { "hide-details": "" },
              model: {
                value: _vm.recordCheck,
                callback: function ($$v) {
                  _vm.recordCheck = $$v
                },
                expression: "recordCheck",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row align-start" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("saveDevice"),
                      disabled: _vm.localRecordIsDisabled,
                      value: 1,
                      color: "white",
                    },
                  }),
                  _c("v-radio", {
                    staticClass: "ml-10",
                    attrs: {
                      label: _vm.remoteRecordText,
                      disabled: _vm.remoteRecordIsDisabled,
                      value: 0,
                      color: "white",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _vm.recordCheck === 1
            ? [
                _vm.localRecord && !_vm.userSharedScreen
                  ? _c(
                      "div",
                      [
                        _vm.remoteRecordWarningText
                          ? _c(
                              "v-alert",
                              {
                                attrs: {
                                  dense: "",
                                  type: "info",
                                  icon: "feather icon-info",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t(_vm.remoteRecordWarningText))
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              dense: "",
                              type: "info",
                              icon: "feather icon-info",
                            },
                          },
                          [
                            _c("p", { staticClass: "mb-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("localRemoteRecordInfo.description")
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "localRemoteRecordInfo.localDescription"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm.userSharedScreen
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              dense: "",
                              type: "info",
                              icon: "feather icon-info",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("localRecordScreenSharingWarning"))
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.recordCheck === 0
            ? [
                _c(
                  "div",
                  [
                    _c(
                      "v-alert",
                      { attrs: { type: "info", icon: "feather icon-info" } },
                      [
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            _vm._s(_vm.$t("localRemoteRecordInfo.description"))
                          ),
                        ]),
                        _c("p", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.remoteRecordDescription)),
                        ]),
                        !_vm.changeRemoteRecordText
                          ? _c(
                              "p",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "localRemoteRecordInfo.remoteLinkDescription"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("Link", {
                                  attrs: {
                                    text: _vm.$t(
                                      "localRemoteRecordInfo.remoteLink"
                                    ),
                                    href: _vm.priceUrl,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.getE2eeEnabled && !_vm.localRecord
            ? _c(
                "div",
                [
                  _c(
                    "v-alert",
                    { attrs: { type: "info", icon: "feather icon-info" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("localRemoteRecordInfo.remoteE2eInfo")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }