var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.gdprAccepted
    ? _c(
        "section",
        { staticClass: "gdpr-section", staticStyle: { "z-index": "4" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-2 justify-center" },
            [
              _c("div", { staticClass: "text-body-2" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("cookiePolicyInformation")))]),
                _vm._v("   "),
                _c("a", { attrs: { target: "_blank", href: _vm.cookieLink } }, [
                  _vm._v(_vm._s(_vm.$t("learnMore"))),
                ]),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  staticStyle: { background: "transparent !important" },
                  attrs: {
                    "data-test-id": "gdpr-accept",
                    rounded: "",
                    outlined: "",
                    small: "",
                  },
                  on: { click: _vm.acceptPolicy },
                },
                [_vm._v(" " + _vm._s(_vm.$t("accept")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }