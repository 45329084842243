<template>
  <div class="notification-file-share d-flex w-full justify-space-between align-center">
    <div class="d-flex">
      <div class="d-flex flex-column w-full">
        <span class="user-name">{{ user ? user.displayName : $t('notifications.participant') }}</span>
        <div class="content d-flex">
          <Icon icon="file" color="white" :size="20" />
          <span class="action-text ml-2">{{ $t(`notifications.${notification.text}`) }}</span>
        </div>
      </div>
    </div>
    <div class="actions d-flex ">
      <div class="check cursor-pointer" @click="confirm">
        <Icon v-if="!loading" icon="download-1" color="white" :size="20" />
        <v-progress-circular v-else indeterminate color="white" :size="20" :width="2"></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Xperdrive from 'xperdrive-lib';

export default {
  name: 'NotificationFileShare',
  props: {
    user: {
      type: Object,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('Conference', ['allowSpeakRequest', 'handsDown']),
    confirm() {
      if (!this.loading) {
        this.loading = true;
        Xperdrive.FileDownloadAnonymous(this.notification.file, progress => {
          const status = Math.floor((progress.loaded * 100) / progress.total);
          if (status === 100) {
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }
        });
      }
    },
  },
};
</script>
