var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "users pl-3 pr-2" }, [
    _c("div", { staticClass: "hosts-title flex-grow-0 px-5 py-3" }, [
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.$t("hosts")))]),
    ]),
    _c(
      "div",
      [
        _c("text-field", {
          staticClass: "mx-2",
          attrs: {
            name: "search-user",
            autocomplete: "off",
            backgroundColor: "body",
            label: _vm.$t("searchUsersByUserName"),
            "hide-details": "",
          },
          model: {
            value: _vm.searchByUserNameText,
            callback: function ($$v) {
              _vm.searchByUserNameText = $$v
            },
            expression: "searchByUserNameText",
          },
        }),
        _vm._l(_vm.participants, function (user) {
          return _c(
            "div",
            {
              key: user.id,
              staticClass: "user",
              class: {
                moderator: user.role === "moderator",
                participant: user.role === "participant",
              },
            },
            [
              _c("avatar", {
                attrs: {
                  "display-name": user.displayName,
                  image: user.avatar,
                  "avatar-size": 48,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "user-display-name webinar",
                  attrs: { title: user.displayName },
                },
                [_vm._v(" " + _vm._s(user.displayName) + " ")]
              ),
              _c(
                "div",
                { staticClass: "user-actions" },
                [
                  user.handsUp
                    ? _c("icon", {
                        staticClass: "user-action-icon hand-action-icon",
                        attrs: { icon: "hand", size: "medium" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
    _c("div", {
      staticClass: "mt-4 mb-4 ml-n3 mr-n2",
      staticStyle: { "background-color": "var(--v-body-base)", height: "2px" },
    }),
    _c("div", { staticClass: "viewers-title flex-grow-0 px-5 pb-3" }, [
      _c("div", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("viewerList"))),
      ]),
    ]),
    _c(
      "div",
      [
        _c("text-field", {
          staticClass: "mt-1 mx-2",
          attrs: {
            backgroundColor: "body",
            label: _vm.$t("searchWebinarUsersByViewerName"),
            "hide-details": "",
          },
          model: {
            value: _vm.searchByViewerNameText,
            callback: function ($$v) {
              _vm.searchByViewerNameText = $$v
            },
            expression: "searchByViewerNameText",
          },
        }),
        _vm._l(_vm.filteredViewers, function (user) {
          return _c(
            "div",
            {
              key: user.id,
              staticClass: "user",
              class: {
                me: _vm.getLocalUser && user.id === _vm.getLocalUser.id,
              },
            },
            [
              user.role === "moderator"
                ? _c("avatar", {
                    attrs: {
                      "display-name": user.displayName,
                      image: user.avatar,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "user-display-name",
                  attrs: { title: user.displayName },
                },
                [_vm._v(" " + _vm._s(user.displayName) + " ")]
              ),
              _c(
                "div",
                { staticClass: "user-actions" },
                [
                  user.handsUp
                    ? _c("icon", {
                        staticClass: "user-action-icon hand-action-icon",
                        attrs: { icon: "hand" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }