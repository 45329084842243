var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "room-timer",
      on: {
        click: function ($event) {
          return _vm.$emit("open")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("icon", {
            staticClass: "mr-2",
            attrs: { icon: "clock", size: "20" },
          }),
          _c("span", { staticClass: "duration-time" }, [
            _vm._v(_vm._s(_vm.meetingDuration)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }