<script>
import { VCheckbox } from 'vuetify/lib';

export default {
  name: 'Checkbox',
  extends: VCheckbox,
  mixins: [
    {
      props: {
        color: {
          default: 'date',
        },
      },
    },
  ],
};
</script>
