var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-menu" }, [
    _c(
      "div",
      { staticClass: "right-menu--header" },
      [
        _c(
          "div",
          { staticClass: "py-6 px-4 close-icon" },
          [
            _c("icon", {
              attrs: { icon: "x" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "v-tabs",
          {
            attrs: {
              value: _vm.activeRightMenuTab,
              grow: "",
              "show-arrows": "",
              "icons-and-text": "",
            },
          },
          [
            _c(
              "v-tab",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$can("use", "Chat"),
                    expression: "$can('use', 'Chat')",
                  },
                ],
                attrs: { href: "#chat", ripple: false },
                nativeOn: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("chat")
                  },
                },
              },
              [
                _c("div", { staticClass: "h-full" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("icon", { attrs: { icon: "chat-1" } })],
                    1
                  ),
                  _c("span", { staticClass: "mt-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("chat")) + " "),
                  ]),
                ]),
              ]
            ),
            _c(
              "v-tab",
              {
                attrs: { href: "#user-list", ripple: false },
                on: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("user-list")
                  },
                },
              },
              [
                _c("div", { staticClass: "h-full" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("icon", { attrs: { icon: "users" } })],
                    1
                  ),
                  _c("span", { staticClass: "mt-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("userList")) + " "),
                  ]),
                ]),
              ]
            ),
            _vm.showLobbyTab
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#lobby", ripple: false },
                    on: {
                      click: function ($event) {
                        return _vm.SET_CURRENT_RIGHT_MENU_TAB("lobby")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("icon", { attrs: { icon: "users" } })],
                        1
                      ),
                      _c("span", { staticClass: "mt-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lobby")) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.showWebinarChatTab
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#webinar-chat" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.toggleWebinarChatAndReadMessages.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("icon", { attrs: { icon: "webinar-chat" } })],
                        1
                      ),
                      _c("span", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.$t("webinarChat"))),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isSurveyUsable
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#survey", ripple: false },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.SET_CURRENT_RIGHT_MENU_TAB("survey")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("icon", { attrs: { icon: "check-1" } })],
                        1
                      ),
                      _c("span", { staticClass: "mt-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("survey")) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.showARTab
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#ar", ripple: false },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.SET_CURRENT_RIGHT_MENU_TAB("ar")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("icon", { attrs: { icon: "xperremote" } })],
                        1
                      ),
                      _c("span", { staticClass: "mt-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("augmentedReality")) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.whiteBoardSupported
              ? _c(
                  "v-tab",
                  {
                    attrs: { href: "#whiteboard", ripple: false },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.SET_CURRENT_RIGHT_MENU_TAB("whiteboard")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("icon", { attrs: { icon: "clipboard-1" } })],
                        1
                      ),
                      _c("span", { staticClass: "mt-2 text-capitalize" }, [
                        _vm._v(" " + _vm._s(_vm.$t("whiteBoard")) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "v-tab",
              {
                attrs: { href: "#shared-files", ripple: false },
                nativeOn: {
                  click: function ($event) {
                    return _vm.SET_CURRENT_RIGHT_MENU_TAB("shared-files")
                  },
                },
              },
              [
                _c("div", { staticClass: "h-full" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("icon", { attrs: { icon: "folder" } })],
                    1
                  ),
                  _c("span", { staticClass: "mt-2 text-capitalize" }, [
                    _vm._v(" " + _vm._s(_vm.$t("sharedFiles")) + " "),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "v-tabs-items",
          {
            staticStyle: { "flex-grow": "1" },
            attrs: { touchless: "", value: _vm.activeRightMenuTab },
          },
          [
            _vm.$can("use", "Chat")
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "chat" } },
                  [
                    _c("chat", {
                      attrs: {
                        "body-class": "chat-window",
                        "body-id": "conference-chat",
                        "show-actions": "",
                        messages: _vm.messages,
                        sendPrivateMessageTo: _vm.sendPrivateMessageTo,
                        enablePrivateMessage: true,
                        chatEnabled: _vm.chatEnabled,
                        "show-download-messages":
                          _vm.isModerator &&
                          _vm.$can("downloadMessages", "Chat"),
                        "show-file-share-button": _vm.fileShareSupported,
                      },
                      on: {
                        sendButtonClicked: _vm.onSendButtonClicked,
                        privateMessageClicked: _vm.onPrivateMessageClicked,
                        privateMessageCancelled: _vm.onPrivateMessageCancalled,
                        scrollEnd: _vm.onChatScrollEnd,
                        readMessages: _vm.READ_MESSAGES,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-tab-item",
              { attrs: { value: "user-list" } },
              [
                _c("users", {
                  attrs: {
                    users: _vm.userList,
                    "local-user": _vm.getLocalUser,
                  },
                  on: {
                    audioMuteClicked: _vm.muteAudio,
                    videoMuteClicked: _vm.muteVideo,
                    handsUpClicked: _vm.onHandsUpClicked,
                    getInClicked: _vm.onGetInClicked,
                    webinarKickClicked: _vm.onGetOutClicked,
                  },
                }),
              ],
              1
            ),
            _vm.showLobbyTab
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "lobby" } },
                  [_c("lobby-right-menu-tab-content")],
                  1
                )
              : _vm._e(),
            _vm.showWebinarChatTab
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "webinar-chat" } },
                  [
                    _c("chat", {
                      attrs: {
                        "show-actions": "",
                        "body-id": "conference-webinar-chat",
                        "chat-enabled": _vm.isWebinarStarted,
                        "show-avatar": false,
                        "body-class": "webinar-window",
                        messages: _vm.webinarMessages,
                        "show-download-messages":
                          _vm.isWebinarModerator &&
                          _vm.$can("downloadWebinarMessages", "Chat"),
                        "show-file-share-button": _vm.fileShareSupported,
                      },
                      on: {
                        sendButtonClicked: _vm.onWebinarSendButtonClicked,
                        scrollEnd: _vm.onWebinarChatScrollEnd,
                        readMessages: _vm.READ_WEBINAR_MESSAGES,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-tab-item",
              { attrs: { value: "survey" } },
              [_c("survey", { attrs: { isModerator: _vm.isModerator } })],
              1
            ),
            _c(
              "v-tab-item",
              { attrs: { value: "shared-files" } },
              [_c("shared-file-list")],
              1
            ),
            _vm.showARTab
              ? _c(
                  "v-tab-item",
                  { attrs: { value: "ar" } },
                  [_c("ar-right-menu")],
                  1
                )
              : _vm._e(),
            _vm.whiteBoardSupported
              ? _c("v-tab-item", { attrs: { value: "whiteboard" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      },
                    },
                    [
                      _c("WhiteBoard"),
                      _c(
                        "div",
                        {
                          staticClass:
                            "white-board-extend-button cursor-pointer d-flex align-center justify-center",
                          on: { click: _vm.TOGGLE_WHITE_BOARD },
                        },
                        [
                          _c("Icon", {
                            attrs: { icon: "full-screen", color: "body" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }