var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.survey && _vm.surveys.length === 0 && !_vm.loading
    ? _c("div", { staticClass: "survey-list-wrapper mt-5" }, [
        _c("div", { staticClass: "mt-7 mb-6 text-center" }, [
          _vm._v(" " + _vm._s(this.$t(_vm.emptyStateText)) + " "),
        ]),
        _vm.isMySurveys
          ? _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-auto",
                    attrs: {
                      text: "",
                      disabled: !_vm.getLocalUser.authenticated,
                    },
                    on: { click: _vm.addNewSurvey },
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "plus-circle" },
                    }),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("addSurvey")) + " "),
                    ]),
                  ],
                  1
                ),
                !_vm.getLocalUser.authenticated
                  ? _c("icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("surveyCreateAuthInfo"),
                          expression: "$t('surveyCreateAuthInfo')",
                        },
                      ],
                      staticClass: "ml-2 info-icon flex-shrink-0",
                      attrs: { icon: "info" },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("div", { staticClass: "survey-list" }, [
        _vm.loading
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    staticStyle: { height: "100px", width: "100%" },
                    attrs: { justify: "center" },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "black" },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.surveys.length > 0 && !_vm.survey
          ? _c(
              "div",
              [
                _vm.isMySurveys
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "survey-list-wrapper--add-survey",
                        attrs: { text: "" },
                        on: { click: _vm.addNewSurvey },
                      },
                      [
                        _c("icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "plus-circle" },
                        }),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("addSurvey")) + " "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.surveys, function (survey) {
                  return _c("survey-list-item", {
                    key: survey.Id,
                    attrs: {
                      data: survey,
                      isMySurveys: _vm.isMySurveys,
                      isSharedSurvey: _vm.isSharedSurvey,
                    },
                    on: {
                      edit: _vm.edit,
                      goToDetail: _vm.goToDetail,
                      goToSurvey: _vm.goToSurvey,
                      goToResult: _vm.goToResult,
                      openConfirm: _vm.openConfirm,
                    },
                  })
                }),
              ],
              2
            )
          : _vm.survey
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("span", { staticClass: "text-body-2" }, [
                      _vm._v(_vm._s(_vm.$t(_vm.title))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-center mr-2",
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  medium: "",
                                                  color: "primary",
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-information-outline ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("privateSurveyExplanation"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-switch", {
                          attrs: {
                            color: "secondary",
                            label: _vm.$t("privateSurvey"),
                          },
                          model: {
                            value: _vm.survey.IsPrivate,
                            callback: function ($$v) {
                              _vm.$set(_vm.survey, "IsPrivate", $$v)
                            },
                            expression: "survey.IsPrivate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("text-field", {
                  staticClass: "mb-5",
                  attrs: { "hide-details": "", label: _vm.$t("surveyTitle") },
                  model: {
                    value: _vm.survey.Title,
                    callback: function ($$v) {
                      _vm.$set(_vm.survey, "Title", $$v)
                    },
                    expression: "survey.Title",
                  },
                }),
                _vm.maxCharError
                  ? _c(
                      "v-alert",
                      { attrs: { type: "error", icon: "feather icon-info" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("maxChar", { char: "200" })) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12", md: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { block: "", outlined: "" },
                            on: {
                              click: function ($event) {
                                _vm.survey = null
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "12", md: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              color: "secondary",
                              disabled: !_vm.survey.Title,
                              loading: _vm.loading,
                            },
                            on: { click: _vm.save },
                          },
                          [_vm._v(_vm._s(_vm.$t("save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }