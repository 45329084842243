<template>
  <v-container fluid class="webinar-bottom-menu d-flex align-center">
    <v-row justify="center">
      <menu-button data-test-id="webinar-hands-up-button" icon="hand" :color="user.handsUp ? 'warning' : ''" @click="handsUp" class="mr-1" />
      <menu-button
        data-test-id="webinar-chat-button"
        :badgeWebinarContent="getWebinarUnreadMessageCount"
        icon="chat"
        class="mr-1"
        @click="toggleScreenAndReadMessages"
      />
      <menu-button
        data-test-id="webinar-speaker-button"
        :icon="speakerIcon"
        :color="speakerColor"
        :translateItemLabels="false"
        :tooltip-text="!speakerMuted ? $t('muteSpeaker') : $t('unmuteSpeaker')"
        @click="$emit('itemClicked', 'speakerMuted')"
      />

      <div v-if="!$isMobile" class="menu-btn-spacer mr-1"></div>
      <menu-button data-test-id="webinar-log-out-button" icon="phone" @click="logout" color="red" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

export default {
  name: 'WebinarBottomMenu',
  props: {
    speakerMuted: {
      type: Boolean,
    },
  },
  data() {
    return {
      openUserDropdown: false,
    };
  },
  computed: {
    ...mapState('Conference', ['showChatScreen']),
    ...mapGetters('Webinar', ['getLocalUser', 'getWebinarUnreadMessageCount']),
    user() {
      return this.getLocalUser || {};
    },
    speakerIcon() {
      return this.speakerMuted ? 'volume-off' : 'volume';
    },
    speakerColor() {
      return this.speakerMuted ? 'logo-color' : '';
    },
  },
  methods: {
    ...mapMutations('Conference', ['TOGGLE_CHAT_SCREEN', 'TOGGLE_PROFILE_MODAL', 'TOGGLE_LOGOUT_CONFIRM_MODAL']),
    ...mapMutations('Webinar', ['READ_WEBINAR_MESSAGES']),
    ...mapActions('Webinar', ['setHandsUp', 'leave']),
    logout() {
      this.$emit('setLogout');
      this.leave();
    },
    toggleScreenShare() {
      if (this.user.screenSharing) {
        this.stopScreenShare();
      } else {
        this.startScreenShare();
      }
    },
    handsUp() {
      this.setHandsUp(!this.user.handsUp);
    },
    toggleScreenAndReadMessages() {
      this.TOGGLE_CHAT_SCREEN();
      this.READ_WEBINAR_MESSAGES();
    },
  },
};
</script>

<style lang="scss">
.webinar-bottom-menu {
  z-index: 10;
  height: 96px;
  padding: 0 40px;
  animation-duration: 0.3s;
  position: relative;
  width: fit-content;

  .menu-btn-spacer {
    width: 10px;
  }
}
</style>
