<template>
  <Modal>
    <div class="hints-and-keypoints-modal">
      <div class="card">
        <div class="icon-container" style="padding: 9px">
          <icon icon="lamp" size="30" />
        </div>
        <div class="text">{{ $t('tipsAndTricksList.1') }}</div>
      </div>
      <div class="card">
        <div class="icon-container">
          <Icon icon="video-outline"></Icon>
        </div>
        <div class="text">{{ $t('tipsAndTricksList.2') }}</div>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'HintsAndKeypoints',
};
</script>
<style lang="scss">
.hints-and-keypoints-modal {
  .card {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    margin-bottom: 2rem;
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--v-secondary-base);
      float: left;
      margin-right: 2rem;
      padding: 12px;
      font-size: 21px;
    }
  }
}
</style>
