import { Ability, AbilityBuilder } from '@casl/ability';

export const ROLE_ADMIN = 'Admin';
export const ROLE_PARTICIPANT = 'Participant';

// function subjectName(item) {
//   if (!item || typeof item === 'string') {
//     return item;
//   }
//   return item.__type;
// }

export const ability = new Ability([]);
/* eslint-disable */
export function changeRoles(roles) {
  ability.update(defineRulesFor(roles));
}

export function revokeRule(action, subject) {
  const { cannot, rules } = new AbilityBuilder(Ability);

  cannot(action, subject);
  ability.update([...ability.rules, ...rules]);
}

export function grantRule(action, subject) {
  const { can, rules } = new AbilityBuilder(Ability);

  can(action, subject);
  ability.update([...ability.rules, ...rules]);
}

export function routerGuard(_can) {
  if (!_can) {
    return false;
  }
  const rule = _can.split(' ') && _can.split(' ').length && _can.split(' ')[0];
  const module = _can.split(' ') && _can.split(' ').length && _can.split(' ')[1];
  return ability.can(rule, module);
}

export function defineRulesFor(roles = []) {
  const { can, rules } = new AbilityBuilder(Ability);

  // Global Rules
  // can('see', 'Home');

  // Default Roles
  if (roles.includes(ROLE_ADMIN)) {
    can('mute', 'Audio');
    can('unMute', 'Audio');
    can('muteParticipant', 'Audio');
    can('unMuteParticipant', 'Audio');
    can('mute', 'Video');
    can('unMute', 'Video');
    can('muteParticipant', 'Video');
    can('unMuteParticipant', 'Video');

    can('downloadUserList', 'Meet');
    can('downloadWebinarUserList', 'Meet');
    can('changeDisplayName', 'Meet');
    can('kickParticipant', 'Meet');
  }
  if (roles.includes(ROLE_PARTICIPANT)) {
    can('mute', 'Audio');
    can('unMute', 'Audio');
    can('mute', 'Video');
    can('unMute', 'Video');

    can('changeDisplayName', 'Meet');
  }

  return rules;
}

export function setPackagePermissions(role, features, useUserConfig = false, userConfig) {
  if (features && typeof features === 'object') {
    const {
      fileShare = true,
      roomPassword = true,
      screenShare = true,
      userStatistics = true,
      chat = true,
      videoShare = true,
      sendMeetingRequest = true,
      reports = false,
      highDefinitionAudio = true,
      highDefinitionVideo = true,
      bgCustomize = false,
      layoutCustomize = false,
      survey = false,
      lobby = false,
      record = false,
      recording = false, // CloudRecord
      handsUp = false,
      whiteBoard = false,
      SIP = false,
      E2EE = false,
      // changeLogo = false,
      livestreaming = false, // Webinar
    } = features;

    if (role === ROLE_ADMIN) {
      // DEFAULT ADMIN PERMISSIONS
      grantRule('closeConference', 'Meet');

      grantRule('seeWebinarButton', 'Webinar'); // Paket

      // PACKAGE PERMISSIONS
      if (fileShare) {
        grantRule('see', 'FileShare'); // Paket
      }
      if (roomPassword) {
        grantRule('lock', 'Meet'); // Paket
      }
      if (screenShare) {
        grantRule('start', 'ScreenShare'); // Paket
      }
      if (userStatistics) {
        grantRule('seeUserStatistics', 'Meet'); // Paket
        grantRule('downloadUserStatistics', 'Meet'); // Paket
      }
      if (chat) {
        grantRule('use', 'Chat');
        grantRule('sendPrivateMessage', 'Chat');
        grantRule('downloadMessages', 'Chat');
        grantRule('downloadWebinarMessages', 'Chat');
      }
      if (videoShare) {
        grantRule('shareVideo', 'Meet');
      }
      if (sendMeetingRequest) {
        grantRule('sendMeetingRequest', 'Meet');
      }
      if (!reports) {
        revokeRule('downloadMessages', 'Chat');
        revokeRule('downloadWebinarMessages', 'Chat');
        revokeRule('downloadUserStatistics', 'Meet');
      }
      if (highDefinitionAudio) {
        // TODO
      }
      if (highDefinitionVideo) {
        // TODO
      }
      if (bgCustomize) {
        grantRule('setBackground', 'Meet'); // Paket
        grantRule('setRoomBackground', 'Meet');
      }
      if (layoutCustomize) {
        grantRule('setLayoutSettings', 'Meet'); // Paket
        grantRule('setRoomLayoutSettings', 'Meet');
        grantRule('changeLayoutType', 'Meet'); // Paket
      }
      if (survey) {
        grantRule('see', 'Survey'); // Paket
        grantRule('create', 'Survey'); // Paket
        grantRule('downloadResult', 'Survey'); // Paket
      }
      if (lobby) {
        grantRule('enableLobby', 'Meet'); // Paket
      }
      if (record) {
        grantRule('startLocal', 'Record'); // Paket
      }
      if (recording && (!useUserConfig || userConfig?.recording)) {
        grantRule('startRemote', 'Record');
        // Paket
      }
      if (handsUp) {
        grantRule('handsUp', 'Meet'); // Paket
      }
      if (whiteBoard) {
        grantRule('see', 'WhiteBoard'); // Paket
      }
      if (SIP) {
        grantRule('seeSIPNumber', 'Meet'); // Paket
      }
      if (E2EE) {
        grantRule('enableE2EE', 'Meet'); // Paket
      }
      if (livestreaming && (!useUserConfig || userConfig?.livestreaming)) {
        grantRule('start', 'Webinar'); // Paket
        grantRule('sendParticipantToWebinar', 'Webinar'); // Paket
        grantRule('getParticipantFromWebinar', 'Webinar'); // Paket
      }

      if (livestreaming) {
        grantRule('stop', 'Webinar'); // Paket
      }
    }

    if (role === ROLE_PARTICIPANT) {
      // PACKAGE PERMISSIONS
      if (fileShare) {
        grantRule('see', 'FileShare'); // Paket
      }
      if (screenShare) {
        grantRule('start', 'ScreenShare'); // Paket
      }
      if (chat) {
        grantRule('use', 'Chat');
        grantRule('sendPrivateMessage', 'Chat');
      }
      if (videoShare) {
        grantRule('shareVideo', 'Meet');
      }
      // if (highDefinitionAudio) {} // Not implemented yet
      // if (highDefinitionVideo) {} // Not implemented yet
      if (bgCustomize) {
        grantRule('setBackground', 'Meet'); // Paket
      }
      if (layoutCustomize) {
        grantRule('setLayoutSettings', 'Meet'); // Paket
        grantRule('changeLayoutType', 'Meet'); // Paket
      }
      if (survey) {
        grantRule('see', 'Survey'); // Paket
      }
      if (record) {
        grantRule('startLocal', 'Record'); // Paket
      }
      if (handsUp) {
        grantRule('handsUp', 'Meet'); // Paket
      }
      if (whiteBoard) {
        grantRule('see', 'WhiteBoard'); // Paket
      }
      if (SIP) {
        grantRule('seeSIPNumber', 'Meet'); // Paket
      }
      if (E2EE) {
        grantRule('enableE2EE', 'Meet'); // Paket
      }
      if (userStatistics) {
        grantRule('seeUserStatistics', 'Meet'); // Paket
        grantRule('downloadUserStatistics', 'Meet'); // Paket
      }
    }
  }
}
