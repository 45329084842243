<template>
  <Modal
    :title="$t('themeSettings')"
    :confirm-button-text="$t('confirm')"
    :cancel-button-text="$t('cancel')"
    showActions
    :confirm-button-disabled="loading"
    @close="close"
  >
    <div class="layout-settings">
      <div class="text-center text-body-2 mb-4">
        {{ $t('layout.description_1') }}
      </div>

      <div v-if="$can('setLayoutSettings', 'Meet')">
        <span>{{ $t('layoutSettings') }}:</span>
        <span v-if="disableLayoutChange" class="text-caption">{{ ' ' + `(${$t('lockedByModerator')})` }}</span>
      </div>

      <div class="d-flex mb-2">
        <v-card color="primary" class="layout-selector py-2 px-4 my-2" elevation="0">
          <div class="d-flex align-center justify-center flex-wrap mt-n3">
            <icon icon="grid" size="medium" class="mr-3 mt-3" />

            <div class="d-flex align-center text-caption mt-3">
              <label :class="{ 'opacity-50': layoutType === 'dynamic' }">{{ $t('layout.grid') }}</label>
              <div>
                <v-switch
                  :input-value="isLayoutDynamic"
                  hide-details
                  class="mt-0 pt-0 ml-3 mr-0"
                  color="line"
                  v-bind:disabled="disableLayoutChange"
                  @change="onLayoutSwitchChanged"
                />
              </div>
              <label :class="{ 'opacity-50': layoutType === 'grid' }">{{ $t('layout.dynamic') }}</label>
            </div>
          </div>
        </v-card>
      </div>

      <div v-if="layoutType === 'dynamic'">
        <span>{{ $t('layout.layoutSelection') }}:</span>
        <span v-if="disablePageSizeChange" class="text-caption">{{ ' ' + `(${$t('lockedByModerator')})` }}</span>
        <div class="d-flex">
          <v-card color="primary" class="layout-selector py-2 px-4 my-2" elevation="0">
            <div class="d-flex page-sizes" :class="{ disabled: disablePageSizeChange }">
              <div
                v-for="pageSize in pageSizes"
                :key="pageSize"
                class="px-1 mx-1 cursor-pointer"
                :class="{ selected: pageSize === selectedPageSize }"
                @click="selectedPageSize = pageSize"
              >
                {{ pageSize }}
              </div>
            </div>
          </v-card>
        </div>
      </div>

      <v-form autocomplete="off">
        <template v-if="$can('setBackground', 'Meet') && !configHasBg">
          <BackgroundSelector @setLoading="setLoading" :disabled="disableBackgroundChange" :show-title="true" />
        </template>

        <template v-if="isModerator">
          <div class="subtitle-2 mt-4">{{ $t('layout.applyInteractionsToParticipants') }}:</div>
          <div v-for="item in layoutShareItems" :key="item.name" class="d-flex justify-space-between align-center my-2">
            {{ item.text }}
            <v-switch :data-test-id="`M_LS_${item.testId}`" color="success" v-model="item.shared" hide-details class="pt-0 mt-0" />
          </div>
        </template>
      </v-form>
    </div>
  </Modal>
</template>

<script>
import BackgroundSelector from '@/components/Shared/BackgroundSelector';
import { mapMutations, mapGetters, mapState } from 'vuex';
import storage from '@/services/storage';
import { consoleError } from '@/helpers/logger';

export default {
  name: 'LayoutSettings',
  components: { BackgroundSelector },
  props: {
    value: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
    user: {
      type: Object,
    },
    activeLayoutType: {
      type: String,
    },
  },
  data() {
    return {
      layoutType: null,
      localBackground: null,
      selectedModeratorProps: [],
      pageSizes: [4, 6, 10, 15, 25],
      selectedPageSize: 6,
      layoutShareItems: [
        {
          name: 'layoutType',
          shared: false,
          testId: 'LT',
          text: this.$t('layoutSettings'),
          hidden: false,
        },
        {
          name: 'background',
          shared: false,
          testId: 'BG',
          text: this.$t('backgroundSelection'),
          hidden: Boolean(this.$customerConfig?.resources?.backgroundImg),
        },
        {
          name: 'pageSize',
          shared: false,
          testId: 'PS',
          text: this.$t('pageSize'),
          hidden: false,
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters('Conference', ['getTheme', 'getSharedProperties', 'getPageSize']),
    ...mapState(['theme', 'background']),
    configHasBg() {
      return Boolean(this.$customerConfig?.resources?.backgroundImg);
    },
    isLayoutDynamic() {
      return this.layoutType === 'dynamic';
    },
    configObject() {
      return {
        layoutType: this.layoutType,
        backgroundImage: this.background,
        sharedProperties: this.compareSelected,
      };
    },
    compareSelected() {
      let compareSelectedArray = [];

      const sharedItems = this.layoutShareItems.filter(i => i.shared);
      sharedItems.forEach(item => {
        const select = item.name;
        if (select === 'theme') {
          compareSelectedArray.push({
            type: 'theme',
            value: this.theme,
          });
        } else if (select === 'pageSize') {
          compareSelectedArray.push({
            type: select,
            value: this.selectedPageSize,
          });
        } else {
          if (select in this) {
            compareSelectedArray.push({
              type: select,
              value: this[select],
            });
          } else {
            consoleError(`Shared property:${select} is not found in this scope!`);
          }
        }
      });

      return compareSelectedArray;
    },
    isModerator() {
      return this.user?.isModerator;
    },
    disableLayoutChange() {
      return !this.isModerator && this.getSharedProperties.includes('layoutType');
    },
    disableBackgroundChange() {
      return !this.isModerator && this.getSharedProperties.includes('background');
    },
    disablePageSizeChange() {
      return (!this.isModerator && this.getSharedProperties.includes('pageSize')) || !this.$can('setLayoutSettings', 'Meet');
    },
  },
  created() {
    this.localBackground = this.background;
    this.pageSizes = this.$customerConfig?.page?.pagination?.pageSizes || this.pageSizes;
    this.layoutShareItems = this.layoutShareItems.filter(item => !item.hidden);
    this.setLayoutSharedItems();
  },
  methods: {
    ...mapMutations(['SET_BACKGROUND']),
    ...mapMutations('Conference', ['SET_PAGE_SIZE']),
    setLayoutSharedItems() {
      this.getSharedProperties.forEach(p => {
        const sharedItem = this.layoutShareItems.find(i => i.name === p);
        if (sharedItem) {
          sharedItem.shared = true;
        }
      });
    },
    onLayoutSwitchChanged() {
      this.layoutType = this.layoutType === 'grid' ? 'dynamic' : 'grid';
    },
    save() {
      if (this.configObject?.backgroundImage) {
        storage.setItem('background', this.configObject?.backgroundImage);
      }
      this.$emit('save', this.configObject);
      if (!this.disablePageSizeChange) {
        this.SET_PAGE_SIZE(this.selectedPageSize);
      }
    },
    close(type) {
      if (type === 'confirm') {
        this.save();
      } else {
        this.SET_BACKGROUND(this.localBackground);
        this.$emit('close', this.configObject);
        this.$modal?.close();
      }
    },
    setLoading(val) {
      this.loading = val;
    },
  },
  watch: {
    settings: {
      handler: function () {
        this.layoutType = this.settings?.layoutType || this.activeLayoutType || 'dynamic';
        this.selectedPageSize = this.getPageSize;
        this.setLayoutSharedItems();
      },
      deep: true,
      immediate: true,
    },
    pageSize: {
      immediate: true,
      handler: function () {
        this.selectedPageSize = this.getPageSize;
      },
    },
  },
};
</script>
<style lang="scss">
.layout-settings {
  .page-sizes {
    height: 20px;
    line-height: 20px;
    font-size: 13px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    > div {
      min-width: 20px;
      text-align: center;
    }
    .selected {
      background-color: var(--v-date-base);
      border-radius: 8px;
    }
  }
}
</style>
