var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "question mt-6", class: { edit: _vm.isEditMode } },
    [
      _c("Textarea", {
        attrs: {
          "hide-details": "",
          "background-color": "primary",
          disabled: _vm.disabled,
          label: _vm.$t("question"),
        },
        on: { keyup: _vm.controlQuestionsName },
        model: {
          value: _vm.question.Questions,
          callback: function ($$v) {
            _vm.$set(_vm.question, "Questions", $$v)
          },
          expression: "question.Questions",
        },
      }),
      _vm.maxCharError
        ? _c("span", { staticClass: "max-char-warning" }, [
            _vm._v(_vm._s(_vm.$t("maxChar", { char: "250" }))),
          ])
        : _vm._e(),
      _vm._l(_vm.optionList, function (option, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex align-center mt-4" },
          [
            _c("TextField", {
              attrs: {
                label: `${_vm.$t("option")} ${index + 1}`,
                "hide-details": "",
              },
              model: {
                value: option.Choice,
                callback: function ($$v) {
                  _vm.$set(option, "Choice", $$v)
                },
                expression: "option.Choice",
              },
            }),
            _c("Icon", {
              staticClass: "ml-2 cursor-pointer",
              attrs: { icon: "trash", size: "20", color: "error" },
              on: {
                click: function ($event) {
                  return _vm.addToDeletedOptions(index, option.Id)
                },
              },
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between mt-6" },
        [
          _c("v-checkbox", {
            staticClass: "select-multiple-choice",
            staticStyle: { margin: "0", background: "transparent" },
            attrs: {
              color: "white",
              disabled: _vm.disabled,
              label: _vm.$t("allowMultiChoice"),
              "hide-details": "",
              dense: "",
            },
            model: {
              value: _vm.question.HasMultichoices,
              callback: function ($$v) {
                _vm.$set(_vm.question, "HasMultichoices", $$v)
              },
              expression: "question.HasMultichoices",
            },
          }),
          _c(
            "div",
            {
              staticClass: "text-body-2 d-flex align-center",
              attrs: { role: "button" },
              on: { click: _vm.addOption },
            },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { icon: "plus-circle", size: "20" },
              }),
              _vm._v(" " + _vm._s(_vm.$t("addOption")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-7" },
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", outlined: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    color: "secondary",
                    disabled: !_vm.survey.Title,
                    loading: _vm.loading,
                  },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }