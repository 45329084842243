var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "background-selector",
      class: { "hide-background": _vm.hideBackground, disabled: _vm.disabled },
    },
    [
      _vm.disabled ? _c("div", { staticClass: "overlay" }) : _vm._e(),
      _vm.showTitle
        ? _c(
            "div",
            { staticClass: "my-3", staticStyle: { "font-size": "16px" } },
            [
              _vm._v(" " + _vm._s(_vm.$t("backgroundSelection")) + " "),
              _vm.disabled
                ? _c("span", { staticClass: "text-caption" }, [
                    _vm._v(_vm._s(" " + `(${_vm.$t("lockedByModerator")})`)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "background-list" },
        _vm._l(_vm.PREDEFINED_BACKGROUNDS, function (bg, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "background-box",
              style: {
                backgroundColor: _vm.getBackground(bg),
                backgroundImage: _vm.getBackground(bg),
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.setBackground(bg)
                },
              },
            },
            [
              _vm.background === bg
                ? _c(
                    "div",
                    {
                      staticClass:
                        "selected-box d-flex justify-center align-center",
                    },
                    [_c("icon", { attrs: { icon: "check" } })],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      !_vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "mb-3 mt-5 text-body-2" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("selectBackgroundFromComputer.preLink")) +
                    " "
                ),
                _c("Link", {
                  attrs: {
                    text: _vm.$t("selectBackgroundFromComputer.selectFile"),
                  },
                  on: { click: _vm.fileSelect },
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("selectBackgroundFromComputer.afterLink")) +
                    " "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  ref: "background-file",
                  attrs: {
                    type: "file",
                    id: "background-file",
                    accept: "image/*",
                  },
                  on: { change: _vm.onFileChange },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "background-list" },
              _vm._l(_vm.backgrounds, function (bg, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "user-background-box",
                    style: {
                      backgroundColor: _vm.getBackground(bg),
                      backgroundImage: _vm.getBackground(bg),
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.setBackground(bg)
                      },
                    },
                  },
                  [
                    _vm.background === bg
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "selected-box d-flex justify-center align-center",
                          },
                          [_c("icon", { attrs: { icon: "check" } })],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ])
        : _c(
            "div",
            [
              _c("v-progress-circular", {
                staticClass: "my-2",
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          ),
      _vm.fileSizeError
        ? _c(
            "div",
            { staticClass: "pt-3" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "pt-1 pb-1",
                  attrs: { dense: "", outlined: "", type: "error" },
                },
                [
                  _c("span", { staticClass: "text-body-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("fileSizeError_customSize", { size: "10 MB" })
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }