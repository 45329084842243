var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.$t(_vm.title),
        "confirm-button-text": _vm.$t(_vm.confirmText),
        "cancel-button-text": _vm.$t("cancel"),
        "show-actions": "",
        "confirm-button-disabled": _vm.confirmDisabled,
      },
      on: { close: _vm.onModalClose },
    },
    [
      _c("div", { staticClass: "pl-2 pr-2 text-center" }, [
        _c("div", { staticClass: "ma-0 pt-1 pb-3" }, [
          _vm._v(" " + _vm._s(_vm.text) + " "),
        ]),
        _vm.isWebinarStarted
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c("TextField", {
                  staticClass: "started-webinar-title-field",
                  attrs: {
                    label: _vm.$t("webinarTitle"),
                    maxlength: "90",
                    "hide-details": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "secondary",
                                  loading: _vm.loadingWebinarTitle,
                                },
                                on: { click: _vm.saveWebinarTitle },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    48640739
                  ),
                  model: {
                    value: _vm.webinarTitle,
                    callback: function ($$v) {
                      _vm.webinarTitle = $$v
                    },
                    expression: "webinarTitle",
                  },
                }),
                _vm.webinarTitleUpdated
                  ? _c(
                      "div",
                      { staticClass: "webinar-title-update-info-text" },
                      [
                        _c(
                          "span",
                          {
                            class: `webinar-title-update-info-text--${_vm.webinarTitleUpdated}`,
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `updateWebinarTitleResponseText.${_vm.webinarTitleUpdated}`
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        !_vm.isWebinarStarted && _vm.isYoutubeStreamEnabled
          ? _c(
              "div",
              { staticClass: "mt-2 mb-3" },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c("span", { staticClass: "mt-1 pr-3 white--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("selectStreamChannel")) + " "),
                  ]),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex-grow-1 d-flex flex-column align-end",
                      },
                      [
                        _c(
                          "v-radio-group",
                          {
                            staticClass: "ma-0",
                            attrs: { row: "", "hide-details": "", dense: "" },
                            model: {
                              value: _vm.streamChannel,
                              callback: function ($$v) {
                                _vm.streamChannel = $$v
                              },
                              expression: "streamChannel",
                            },
                          },
                          [
                            _c("v-radio", {
                              staticClass: "webinar-radio",
                              attrs: {
                                label: "BizBize",
                                value: "xpermeet",
                                color: "white",
                              },
                            }),
                            _c("v-radio", {
                              staticClass: "webinar-radio",
                              attrs: {
                                label: "YouTube",
                                value: "youtube",
                                color: "white",
                                disabled: _vm.isYoutubeStreamDisabled,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm.streamChannel === "youtube"
                  ? _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c("TextField", {
                          attrs: {
                            autofocus: "",
                            label: _vm.$t("liveStreamKey"),
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.streamKey,
                            callback: function ($$v) {
                              _vm.streamKey = $$v
                            },
                            expression: "streamKey",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c("TextField", {
                      attrs: {
                        label: _vm.$t("webinarTitle"),
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.webinarTitle,
                        callback: function ($$v) {
                          _vm.webinarTitle = $$v
                        },
                        expression: "webinarTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-space-between align-center mt-6",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center white--text" },
                      [
                        _c("div", { staticClass: "pr-3" }, [
                          _vm._v(_vm._s(_vm.$t("saveWebinarStream"))),
                        ]),
                        _c("v-switch", {
                          staticClass: "mt-0 pt-0",
                          attrs: {
                            disabled: !_vm.enableWebinarRecord,
                            color: "success",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.saveWebinarStream,
                            callback: function ($$v) {
                              _vm.saveWebinarStream = $$v
                            },
                            expression: "saveWebinarStream",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                !_vm.enableWebinarRecord
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          dense: "",
                          type: "info",
                          icon: "feather icon-info",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("remoteRecordLicenseInfo")))]
                    )
                  : _vm.showErrorMessageForMaxChar
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-2",
                        attrs: { dense: "", type: "error", outlined: "" },
                      },
                      [_vm._v(_vm._s(_vm.$t("maxChar", { char: 90 })))]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }