var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-bar" },
    _vm._l(_vm.colors, function (color) {
      return _c(
        "div",
        {
          key: color,
          staticClass: "color-item",
          class: {
            selected: color === _vm.selectedColor,
            bgColor: color === "",
          },
          style: { backgroundColor: color },
          on: {
            click: function ($event) {
              return _vm.selectColor(color)
            },
          },
        },
        [
          color === _vm.selectedColor
            ? _c("icon", {
                attrs: {
                  icon: "check",
                  color: color === "#FFFFFF" ? "#000000" : "#FFFFFF",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }