var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { title: _vm.$t("dialIn") } },
    [
      _vm.available
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("div", { staticClass: "mb-8 mt-3" }, [
                _vm._v(_vm._s(_vm.$t("dialInInfo"))),
              ]),
              _vm._l(_vm.conferenceNumbers, function (value, key) {
                return _c(
                  "div",
                  { key: key },
                  _vm._l(value, function (numbers) {
                    return _c("div", { key: numbers }, [
                      _c(
                        "div",
                        { staticClass: "phone-number ml-16 pl-6" },
                        [
                          _c("CopyLink", {
                            attrs: {
                              text: _vm.maskPhone(numbers),
                              "copy-text": _vm.$t("dialInCopy", {
                                maskPhone: _vm.maskPhone(numbers),
                                pinNumber: _vm.pinNumber,
                              }),
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              }),
              _c(
                "div",
                {
                  staticClass:
                    "pin-number mt-6 d-inline-block rounded-lg mb-10",
                },
                [
                  _c("div", { staticClass: "mt-2 white--text" }, [
                    _vm._v("PIN: " + _vm._s(_vm.pinNumber)),
                  ]),
                ]
              ),
            ],
            2
          )
        : _c(
            "v-alert",
            { attrs: { type: "info", icon: "feather icon-info" } },
            [_vm._v(" " + _vm._s(_vm.$t("passwordProtectedWarn")) + " ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }