import Vue from 'vue';
import VueOutsideClick from 'vue-outside-click';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import VueYoutube from 'vue-youtube';
import { VueMaskDirective } from 'v-mask';

import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import modalPlugin from './plugins/modal';
import Casl from './plugins/casl';
import i18n from './i18n';
import { getCurrentLanguage } from '@/helpers/language';
import VHCalculator from '@/mixins/vhCalculator';
import Responsivity from '@/mixins/responsivity';
import { clearTokens } from '@/helpers/token';

// Import local components
import Icon from '@/components/Shared/Icon';
import IconButton from '@/components/Shared/IconButton';
import Logo from '@/components/Shared/Logo';
import Checkbox from '@/components/Shared/Checkbox';
import TextField from '@/components/Shared/TextField';
import Textarea from '@/components/Shared/Textarea';
import IconDropdown from '@/components/Shared/IconDropdown';
import Modal from '@/components/Shared/Modal/Modal';
import Link from '@/components/Shared/Link';
import Avatar from '@/components/Shared/Avatar';
import MenuButton from '@/components/Shared/MenuButton';
import Tab from '@/components/Shared/Tab';

// Import Styles
import './assets/scss/app.scss';

import storage from '@/services/storage';
import { PREDEFINED_RANDOM_AVATARS } from '@/constants/avatar';

// Register Error Handler before vue initialize
import errorHandler from './errorHandler';

// Plugins Register
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.use(VueYoutube);
Vue.use(VTooltip, { defaultClass: 'xper-tooltip tooltip', popover: { defaultClass: 'xper-tooltip', defaultContainer: '.xpermeet' }, defaultHtml: false });

clearTokens();
Casl.init();

Vue.config.productionTip = false;

// Mixins Register
Vue.mixin(VHCalculator);
Vue.mixin(Responsivity);

// Directives Register
Vue.directive('click-outside', VueOutsideClick);
Vue.directive('mask', VueMaskDirective);

// Global Components Register
Vue.component('IconButton', IconButton);
Vue.component('IconDropdown', IconDropdown);
Vue.component('Icon', Icon);
Vue.component('Logo', Logo);
Vue.component('Checkbox', Checkbox);
Vue.component('TextField', TextField);
Vue.component('Textarea', Textarea);
Vue.component('Modal', Modal);
Vue.component('Link', Link);
Vue.component('Avatar', Avatar);
Vue.component('MenuButton', MenuButton);
Vue.component('Tab', Tab);

function GOC() {
  this.errorHandler = errorHandler;
}
GOC.prototype = {
  set: function(key, val) {
    this[key] = val;
  },
  get: function(key) {
    return this[key];
  },
};
const goc = new GOC();
window.goc = goc;
Vue.prototype.$goc = window.goc;
Vue.prototype.$customerConfig = window.customerConfig;

function createApp() {
  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    created() {
      if (this.$isMobile) {
        VTooltip.enabled = false;
      }

      // Set default avatar
      if (this.$customerConfig?.system?.users?.autoRandomAvatar) {
        if (!storage.getItem('avatar') || PREDEFINED_RANDOM_AVATARS.includes(storage.getItem('avatar'))) {
          storage.setItem('avatar', PREDEFINED_RANDOM_AVATARS[Math.floor(Math.random() * PREDEFINED_RANDOM_AVATARS.length)]);
        }
      }

      // this.$vuetify.breakpoint.mobileBreakpoint = 600;
    },
    mounted() {
      const lang = getCurrentLanguage();
      this.$store.commit('SET_LANG', lang);
      let titleDom = document.getElementsByTagName('title');
      const title = this.$customerConfig?.page?.title;
      if (title) {
        titleDom[0].innerText = title;
      }
    },
    render: h => h(App),
  }).$mount('#app');

  Vue.use(modalPlugin, { appInstance: app });
}

createApp();
