var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "survey-result-wrapper" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-end" },
        [
          !_vm.isSharedResult
            ? _c("v-select", {
                attrs: {
                  solo: "",
                  "background-color": "primary",
                  label: _vm.$t("conferenceSelection"),
                  items: _vm.rooms,
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "offset-y": "",
                },
                model: {
                  value: _vm.selectedRoom,
                  callback: function ($$v) {
                    _vm.selectedRoom = $$v
                  },
                  expression: "selectedRoom",
                },
              })
            : _vm._e(),
          _vm.selectedRoom && _vm.questions.length
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("downloadSurveyResults"),
                      expression: "$t('downloadSurveyResults')",
                    },
                  ],
                  staticClass: "download-result",
                  attrs: { text: "", small: "" },
                  on: { click: _vm.downloadSurveyResults },
                },
                [
                  _c("icon", {
                    staticClass: "user-action-icon",
                    attrs: { icon: "download", size: "16" },
                  }),
                  _c(
                    "span",
                    { staticClass: "text-body-2 download-result--text ml-2" },
                    [_vm._v(_vm._s(_vm.$t("downloadSurveyResults")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.questions, function (question, questionIndex) {
        return _c(
          "div",
          { key: questionIndex, staticClass: "survey-result my-4" },
          [
            _c("div", { staticClass: "question pb-2" }, [
              _c("div", { staticClass: "question-text" }, [
                _vm._v(
                  _vm._s(questionIndex + 1) + ". " + _vm._s(question.Questions)
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "options mt-2" },
              _vm._l(question.options, function (option, optionIndex) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getChoicesUsers(option.Id)
                          ? `${_vm.getChoicesUsers(option.Id)}, ${
                              _vm.usersLength(option.Id)
                                ? _vm.$t("showAllUsers")
                                : ""
                            }`
                          : null,
                        expression:
                          "getChoicesUsers(option.Id) ? `${getChoicesUsers(option.Id)}, ${usersLength(option.Id) ? $t('showAllUsers') : ''}` : null",
                      },
                    ],
                    key: optionIndex,
                    staticClass: "option-wrapper my-2",
                    class: { public: !_vm.survey.IsPrivate },
                    on: {
                      click: function ($event) {
                        return _vm.showUsersDialog(
                          option,
                          option.Id,
                          optionIndex
                        )
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "option" }, [
                      _c(
                        "div",
                        {
                          staticClass: "option-text",
                          class: { active: option.Id === _vm.openedResultId },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pr-5" },
                            [
                              _vm._v(" " + _vm._s(option.Choice) + " "),
                              _c("div", { staticClass: "vote-count" }, [
                                _vm._v(
                                  _vm._s(_vm.getVoteCount(option.Id)) +
                                    " " +
                                    _vm._s(_vm.$t("vote"))
                                ),
                              ]),
                              _c("div", { staticClass: "percent" }, [
                                _vm._v("%" + _vm._s(_vm.getRate(option.Id))),
                              ]),
                              _c(
                                "transition",
                                { attrs: { name: "collapse", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.userList.length &&
                                            _vm.openedResultId == option.Id &&
                                            _vm.openedResultIndex ==
                                              optionIndex,
                                          expression:
                                            "userList.length && openedResultId == option.Id && openedResultIndex == optionIndex",
                                        },
                                      ],
                                      class: {
                                        userList:
                                          _vm.userList.length &&
                                          _vm.userList.length > 4,
                                      },
                                    },
                                    [
                                      _c("v-divider"),
                                      _vm._l(
                                        _vm.userList,
                                        function (user, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between",
                                                class: { "mt-2": index === 0 },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "voter" },
                                                  [_vm._v(_vm._s(user))]
                                                ),
                                                index == 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "close-icon",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.closeList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("icon", {
                                                          staticStyle: {
                                                            color: "#ffffff",
                                                          },
                                                          attrs: {
                                                            icon: "times",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "bar",
                      style: { width: `${_vm.getRate(option.Id)}%` },
                    }),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }