<template>
  <v-menu :content-class="dropdownClass" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :disabled="disabled" text :ripple="false" small>
        <icon :color="iconColor" :icon="icon" size="20"></icon>
        <icon color="date" class="ml-1" icon="chevron-down" size="16"></icon>
      </v-btn>
    </template>
    <v-list style="background: var(--v-primary-base) !important;" dense>
      <v-list-item v-for="(item, index) in listItems" :key="index" @click="item.onClick">
        <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'IconDropdown',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      deafult: 'white',
    },
    disabled: {
      type: Boolean,
      deafult: false,
    },
    listItems: {
      type: Array,
      required: true,
    },
    dropdownClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
