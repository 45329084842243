/* eslint-disable prefer-destructuring */
import Vue from 'vue';
import XperMeetLib, { ERROR_WITH_CODES } from 'xpermeet-lib'; // eslint-disable-line
import { v4 as uuidv4 } from 'uuid';
import ManageService from '@/services/manage'; // eslint-disable-line
import storage from '@/services/storage';
import { consoleError, consoleLog } from '@/helpers/logger';
import router from '@/router';
import ENUMS, { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';
import i18n from '@/i18n/index';
import { TOAST_TYPE } from '@/constants/toast';

const { CHAT_NOTIFICATION_TYPES } = ENUMS;

const User = ({ id, type = 'remote', displayName, role }) => ({
  id,
  type,
  displayName: displayName || `Viewer ${id}`,
  tracks: [],
  role: role || 'viewer', // viewer|moderator|participant|none
  audioMuted: false,
  audioMutedByModerator: false,
  videoMuted: false,
  videoMutedByModerator: false,
  screenSharing: false,
  handsUp: false,
});

const initialState = () => {
  return {
    roomName: null,
    meetingId: null,
    users: [],
    userObject: {},
    connectionState: ENUMS.WEBINAR_CONNECTION_STATE.IDLE,
    webinarStreamId: null,
    webinarSessionId: null,
    messages: [],
    files: [],
    autoPlayError: true,
    streamChannel: ENUMS.STREAM_CHANNELS.XPERMEET,
    isMaxViewerReached: false,
    webinarTitle: '',
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ADD_USER(state, payload) {
      const userIndex = state.users.findIndex((u) => u.id === payload.id);
      if (userIndex > -1) {
        // If user is already registered, update record
        Vue.set(state.users, userIndex, {
          ...payload,
          type: state.users[userIndex].type,
        });
      } else {
        // add new user
        state.users.push(payload);
      }
      state.userObject[payload.id] = { ...payload };
    },
    ADD_USERS(state, payload) {
      payload.forEach((u) => {
        const user = User({
          id: u.id,
          displayName: u.alias,
          role: u?.role?.toLowerCase(),
        });
        const userIndex = state.users.findIndex((u) => u.id === user.id);
        if (userIndex > -1) {
          // If user is already registered, update record
          Vue.set(state.users, userIndex, {
            ...user,
            type: state.users[userIndex].type,
          });
        } else {
          // add new user
          state.users.push(user);
        }
        state.userObject[user.id] = { ...user };
      });
    },
    UPDATE_USER(state, payload) {
      if (payload) {
        const { userId, data } = payload;

        if (!userId || !data) {
          consoleError('UserId or Data is missing!');
          return;
        }

        const user = state.users.find((u) => u.id === userId);

        if (user) {
          Object.entries(data).forEach((item) => {
            Vue.set(user, item[0], item[1]);
          });
        }
        state.userObject[userId] = { ...user };
      }
    },
    REMOVE_USER(state, payload) {
      const userIndex = state.users.findIndex((u) => u.id === payload);
      if (userIndex > -1) {
        Vue.delete(state.users, userIndex);
        delete state.userObject.userIndex;
      }
    },
    SET_ROOM_NAME(state, payload) {
      state.roomName = payload;
    },
    SET_ROOM_ID(state, payload) {
      state.meetingId = payload;
    },
    SET_CONNECTION_STATE(state, payload) {
      state.connectionState = payload;
    },
    SET_DISPLAY_NAME(state, payload) {
      state.displayName = payload;
    },
    SET_WEBINAR_STREAM_ID(state, payload) {
      state.webinarStreamId = payload;
    },
    SET_WEBINAR_SESSION_ID(state, payload) {
      state.webinarSessionId = payload;
    },
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    CLEAR_USERS(state) {
      state.users = [];
    },
    // eslint-disable-next-line
    SET_INITIAL_STATE(state) {
      // eslint-disable-next-line
      state = initialState();
    },
    SET_AUTO_PLAY_ERROR(state, payload) {
      state.autoPlayError = payload;
    },
    SET_STREAM_CHANNEL(state, payload) {
      state.streamChannel = payload;
    },
    READ_WEBINAR_MESSAGES(state) {
      state.messages = state.messages.map((m) => {
        m.unread = false;
        return m;
      });
    },
    SET_IS_MAX_VIEWER_REACHED(state, payload) {
      state.isMaxViewerReached = payload;
    },
    ADD_FILE(state, payload) {
      state.files.push(payload);
    },
    SET_TITLE(state, payload) {
      state.webinarTitle = payload;
    },
  },
  actions: {
    // Check webinar for moderator
    checkExistingWebinarStream({ rootState, dispatch }, { roomName }) {
      if (!rootState.Conference.conferenceJoined) {
        return;
      }
      const streams = XperMeetLib.webinar.checkExistingStream();
      if (streams && streams.length) {
        if (streams.length > 1) {
          consoleError('Multiple webinar stream found. How it is possible? Check this!');
        } else {
          try {
            dispatch('onWebinarStarted', {
              res: XperMeetLib.conference.room.recordingManager.getSession(streams[0]),
              roomName,
            });
          } catch (e) {
            consoleError('Webinar Event Handler Register Error');
          }
        }
      } else {
        localStorage.removeItem('moderatorSecret');
      }
      return streams;
    },
    // Start Live for Moderator
    // eslint-disable-next-line
    startWebinarStream({ state, commit, dispatch, getters, rootState, rootGetters }, payload) {
      const { streamId: sId, roomName, saveWebinarStream = false, webinarTitle = '' } = payload;

      commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.PENDING);
      const streamId = sId || uuidv4(); // This is moderatorSecret key

      if (streamId.startsWith('youtube.')) {
        commit('SET_STREAM_CHANNEL', ENUMS.STREAM_CHANNELS.YOUTUBE);
        XperMeetLib.conference.roomConfig.setProperty(ENUMS.RoomConfigEnums.WEBINAR_STREAM_CHANNEL, ENUMS.STREAM_CHANNELS.YOUTUBE);
      } else {
        commit('SET_STREAM_CHANNEL', ENUMS.STREAM_CHANNELS.XPERMEET);
        XperMeetLib.conference.roomConfig.setProperty(ENUMS.RoomConfigEnums.WEBINAR_STREAM_CHANNEL, ENUMS.STREAM_CHANNELS.XPERMEET);
      }

      localStorage.setItem('moderatorSecret', streamId);
      if (webinarTitle) {
        storage.setItem('webinarOptions', {
          prosody_meeting_id: rootState.Conference.meetingId,
          title: webinarTitle,
        });
      } else {
        if (storage.getItem('webinarOptions')) {
          storage.removeItem('webinarOptions');
        }
      }
      return XperMeetLib.webinar.startWebinar({ streamId, saveWebinarStream, webinarTitle, meetingId: rootState.Conference.meetingId }).then((res) => {
        const sessionId = res.getID();
        commit('SET_ROOM_NAME', roomName);
        commit('SET_WEBINAR_SESSION_ID', sessionId);
        commit('SET_WEBINAR_STREAM_ID', streamId);

        const remoteModerators = rootGetters['Conference/getRemoteUsers'].filter((u) => u.isModerator);
        remoteModerators.forEach((u) => {
          XperMeetLib.conference.sendWebinarStreamId(streamId, u.id);
        });

        return res;
      });
    },
    stopWebinar({ state }) {
      XperMeetLib.webinar.stopWebinar(state.webinarSessionId);
    },
    joinWithModeratorRole({ state, rootGetters }, { roomName }) {
      if (XperMeetLib.conference.localUser.getUser.isJibri) {
        return;
      }

      const webinarStreamId = state.webinarStreamId || localStorage.getItem('moderatorSecret');
      XperMeetLib.webinar.joinWithModeratorRole(rootGetters['Conference/getLocalUser'].displayName, roomName, webinarStreamId);
    },
    joinWithParticipantRole({ rootGetters }, { roomName }) {
      if (XperMeetLib.conference.localUser.getUser.isJibri) {
        return;
      }

      XperMeetLib.webinar.joinWithParticipantRole(rootGetters['Conference/getLocalUser'].displayName, roomName);
    },
    // Stream Check for viewer
    //eslint-disable-next-line
    streamCheck({}, { roomName, protocol, token }) {
      return XperMeetLib.webinar.streamCheck(roomName, protocol, token);
    },
    // Live Watch
    start({ commit, dispatch }, payload) {
      const { videoId, roomId, displayName, token } = payload;

      if (token) {
        localStorage.setItem('event-token', token);
      }
      XperMeetLib.webinar.start(videoId, roomId, null, displayName, token);
      commit('SET_ROOM_NAME', roomId);
      dispatch('handleEvents', { roomName: roomId });
    },
    handleEvents({ state, commit, getters, dispatch, rootState, rootGetters }, { roomName }) {
      XperMeetLib.webinar.on('VideoStarted', () => {
        commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.WATCHING);
        commit('SET_AUTO_PLAY_ERROR', false);
      });

      XperMeetLib.webinar.on('VideoError', (err) => {
        if (err?.name === 'NotAllowedError') {
          commit('SET_AUTO_PLAY_ERROR', true);
        }
      });

      XperMeetLib.webinar.on('WebinarEnded', () => {
        commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.ENDED);
        commit('SET_WEBINAR_SESSION_ID', null);
        commit('SET_WEBINAR_STREAM_ID', null);
        commit('CLEAR_USERS');
      });

      XperMeetLib.webinar.on('WebinarStopped', (response) => {
        if (response.getID() === state.webinarSessionId) {
          commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.ENDED);
          commit('SET_WEBINAR_SESSION_ID', null);
          commit('SET_WEBINAR_STREAM_ID', null);
          commit('CLEAR_USERS');
        }
      });

      dispatch('checkExistingWebinarStream', { roomName });
      XperMeetLib.webinar.on('WebinarStarted', (res) => {
        dispatch('onWebinarStarted', { res, roomName });
      });

      XperMeetLib.webinar.on('UserList', (message) => {
        const userList = message?.payload?.userList;
        commit('ADD_USERS', userList);
      });

      XperMeetLib.webinar.on('UserJoined', (message) => {
        const u = message?.payload?.user;
        if (u) {
          const user = User({
            id: u.id,
            displayName: u.alias,
            role: u.role.toLowerCase(),
          });
          if (user?.role === 'viewer' && user?.id !== getters?.getLocalUser?.id) {
            dispatch('onChatNotificationReceived', { message: CHAT_NOTIFICATION_TYPES.USER_JOINED_WEBINAR, sender: user.displayName || user.alias });
          }
          commit('ADD_USER', user);
        }
      });

      XperMeetLib.webinar.on('UserAccepted', (message) => {
        const u = message?.payload?.user;
        const meetingId = message?.payload?.meetingId;
        commit('SET_ROOM_ID', meetingId);
        if (Vue.prototype.$customerConfig?.system?.modules?.survey && router.app.$route.name === 'webinar') {
          dispatch('Survey/getSharedSurveys', 'webinar', { root: true });
        }
        if (u) {
          const user = User({
            id: u.id,
            displayName: u.alias,
            type: 'local',
          });
          commit('ADD_USER', user);
        }
      });

      XperMeetLib.webinar.on('UserLeft', (message) => {
        const u = message?.payload?.user;
        if (u) {
          const sender = getters.getUserById(u.id);
          if (sender) {
            const { role } = sender;
            if (role === 'viewer') {
              const displayName = sender?.alias || sender?.displayName;
              dispatch('onChatNotificationReceived', { message: CHAT_NOTIFICATION_TYPES.USER_LEFT_WEBINAR, sender: displayName });
            }
            commit('REMOVE_USER', u.id);
          }
        }
      });
      XperMeetLib.webinar.on('UserRoleChanged', (message) => {
        const u = message?.payload?.user;
        const role = message?.payload?.role;
        if (u) {
          commit('UPDATE_USER', {
            userId: u.id,
            data: {
              role: role.toLowerCase(),
            },
          });
        }
      });

      // Messages
      XperMeetLib.webinar.on('onMessageReceived', (data) => {
        const messageObject = data?.payload;

        const userId = rootGetters['Conference/getLocalUser'].id;
        const nickName = messageObject.user.alias;
        const message = messageObject.message;
        const id = messageObject.id;
        const timestamp = messageObject.timestamp;
        try {
          // file share
          const parsedMessage = JSON.parse(message);
          if (parsedMessage?.type === 'FETCH_WEBINAR_TITLE') {
            if (router.app.$route.name === 'webinar') {
              commit('SET_TITLE', parsedMessage.title);
            }
            return;
          }
          parsedMessage.forEach((file) => {
            commit('ADD_FILE', { ...file, user: nickName, date: new Date(), userId });
          });
          // TODO Hata giderildiğinde route kontrolü kaldırılmalı! https://xpergroup.atlassian.net/browse/BIZBIZE-903
          if (router.app.$route.name === 'webinar') {
            commit('Conference/TOGGLE_ROGHT_MENU', { open: true, tab: 'shared-files' }, { root: true });
          }
        } catch (e) {
          let unread = !rootState.Conference.showChatScreen && getters.getLocalUser?.id !== userId;
          if (rootState.Conference.showChatScreen) {
            let isChatActive =
              router.app.$route.name === 'webinar'
                ? rootState.Conference.activeRightMenuTab !== 'chat'
                : rootState.Conference.activeRightMenuTab !== 'webinar-chat';
            let scrolledChatType = router.app.$route.name === 'meet' && rootState.Conference.activeRightMenuTab === 'webinar-chat' ? 'webinar' : 'conference';
            unread = getters.getLocalUser?.id !== userId && (!!rootState.Conference.isChatScrolled[scrolledChatType] || isChatActive);
          }
          commit('ADD_MESSAGE', {
            userId,
            id: id,
            body: message,
            date: timestamp ? new Date(timestamp) : new Date(),
            sender: nickName,
            incoming: getters.getLocalUser?.id !== userId,
            unread,
          });
        }
      });

      XperMeetLib.webinar.on('ChangeAlias', (message) => {
        const u = message?.payload?.user;
        if (u) {
          commit('UPDATE_USER', {
            userId: u.id,
            data: {
              displayName: u.alias,
            },
          });
        }
      });
      XperMeetLib.webinar.on('MaxViewerReached', () => {
        commit('SET_IS_MAX_VIEWER_REACHED', true);
      });

      XperMeetLib.webinar.on('Hands', (message) => {
        const u = message?.payload?.user;
        const handsUp = message?.payload.direction === 'up';
        if (u) {
          commit('UPDATE_USER', {
            userId: u.id,
            data: {
              handsUp: handsUp,
            },
          });
        }
      });

      XperMeetLib.webinar.on('Command', (message) => {
        if (message?.payload?.message === 'WEBINAR_SURVEY_SHARED') {
          if (router.app.$route.name === 'webinar') {
            dispatch('Survey/getSharedSurveys', 'webinar', { root: true });
          }
        }

        if (message?.payload?.message === 'GET_IN_START') {
          const eventToken = localStorage.getItem('event-token');
          if (eventToken) {
            localStorage.setItem('previous-event-token', eventToken);
          }
          localStorage.removeItem('event-token');
          XperMeetLib.webinar.getInStarted();
          dispatch('leave');
          commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.ENDED);
          commit('CLEAR_USERS');
          router.push({ name: 'meet', params: { roomName }, query: { fromWebinar: true } });
          return;
        }
      });
    },
    async onWebinarStarted({ commit, dispatch, getters, state, rootState, rootGetters }, { res, roomName }) {
      let streamChannel = XperMeetLib.conference.roomConfig.getProperty(ENUMS.RoomConfigEnums.WEBINAR_STREAM_CHANNEL);
      if (!getters.isWebinarStarted && !streamChannel) {
        consoleError('streamChannel yok, roomconfig fetch ediliyor');
        await XperMeetLib.conference.roomConfig.fetchDataSource();
        streamChannel = XperMeetLib.conference.roomConfig.getProperty(ENUMS.RoomConfigEnums.WEBINAR_STREAM_CHANNEL);
        consoleLog(streamChannel);
      }
      commit('SET_STREAM_CHANNEL', streamChannel);

      if (getters.isWebinarStarted || !streamChannel) {
        return;
      }
      commit('SET_ROOM_NAME', roomName);
      commit('SET_CONNECTION_STATE', ENUMS.WEBINAR_CONNECTION_STATE.WATCHING);
      commit('SET_WEBINAR_SESSION_ID', res.getID());
      if (localStorage.getItem('moderatorSecret') && !state.webinarStreamId) {
        commit('SET_WEBINAR_STREAM_ID', localStorage.getItem('moderatorSecret'));
      }

      if (!XperMeetLib.conference.localUser.getUser.isJibri) {
        let subTitle = rootState.Conference.roomConfig?.webinarTitle || '';
        if (storage.getItem('webinarOptions')) {
          const options = storage.getItem('webinarOptions');
          dispatch('Conference/setWebinarTitle', options, { root: true });
          const res = await ManageService.setAndUpdateWebinarTitle(options);
          subTitle = res?.data?.title;
          storage.removeItem('webinarOptions');
        }
        if (state.streamChannel === ENUMS.STREAM_CHANNELS.XPERMEET) {
          const message = i18n.t('webinarStreamStarted');

          dispatch(
            'Notification/showToastNotification',
            {
              title: message,
              subTitle: subTitle ? `${i18n.t('webinarTitle')} : ${subTitle}` : '',
              body: getters.webinarUrl,
              config: { icon: 'tv-2', type: TOAST_TYPE.COPY, timeout: 0 },
            },
            { root: true },
          );
        } else {
          dispatch('Notification/showToastNotification', { body: 'webinarStreamStartedOnYoutube', config: { timeout: 0 } }, { root: true });
        }

        dispatch('Notification/playSound', NOTIFICATION_SOUND_TYPES.WEBINAR_STARTED, { root: true });
      }

      if (state.streamChannel === ENUMS.STREAM_CHANNELS.XPERMEET) {
        setTimeout(async () => {
          if (rootGetters['Conference/getLocalUser'].isModerator) {
            dispatch('Webinar/joinWithModeratorRole', { roomName }, { root: true });
          } else {
            dispatch('Webinar/joinWithParticipantRole', { roomName }, { root: true });
          }
        }, 3000);
      }
    },
    sendMessage({ commit }, payload) {
      commit('READ_WEBINAR_MESSAGES');
      XperMeetLib.webinar.sendChatMessage(payload);
    },
    changeAlias({}, payload) {
      XperMeetLib.webinar.changeAlias(payload);
    },
    setHandsUp({}, payload) {
      XperMeetLib.webinar.handsUp(payload);
    },
    getInViewer({}, viewerId) {
      XperMeetLib.webinar.getInViewer(viewerId);
    },
    kickUserToWebinar({}, userId) {
      XperMeetLib.webinar.kickUserToWebinar(userId);
    },
    leave() {
      XperMeetLib.webinar.leave();
    },
    playVideoManually({}, { video, protocol }) {
      XperMeetLib.webinar.startVideo(video, protocol);
    },
    sendFetcWebinarTitleMessage({}, title) {
      XperMeetLib.webinar.fetchWebinarTitle(title);
    },
    onChatNotificationReceived({ commit, state, getters }, payload) {
      const { time, message, sender } = payload;
      commit('ADD_MESSAGE', {
        userId: getters.getLocalUser.id,
        id: state.messages.length + 1,
        date: time || new Date(),
        body: message,
        type: 'notification-message',
        sender,
        incoming: true,
        unread: true,
      });
    },
    sendChatNotification({ dispatch }, payload) {
      dispatch('Notification/sendChatNotification', payload, { root: true });
    },
    async fetchTitle({ state, commit }) {
      try {
        const res = await ManageService.getWebinarTitle(state.meetingId);
        commit('SET_TITLE', res?.data?.title);
      } catch (error) {
        consoleLog(error);
      }
    },
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getLocalUser(state) {
      return state.users.find((user) => user.type === 'local');
    },
    isWebinarRequestPending(state) {
      return state.connectionState === ENUMS.WEBINAR_CONNECTION_STATE.PENDING;
    },
    isWebinarStarted(state) {
      return state.connectionState === ENUMS.WEBINAR_CONNECTION_STATE.WATCHING;
    },
    isWebinarEnded(state) {
      return state.connectionState === ENUMS.WEBINAR_CONNECTION_STATE.ENDED;
    },
    showWebinarChatTab(state) {
      return (
        state.streamChannel === ENUMS.STREAM_CHANNELS.XPERMEET &&
        (state.connectionState === ENUMS.WEBINAR_CONNECTION_STATE.WATCHING || state.connectionState === ENUMS.WEBINAR_CONNECTION_STATE.ENDED)
      );
    },
    webinarUrl(state) {
      return state.streamChannel === ENUMS.STREAM_CHANNELS.XPERMEET ? `${window.location.origin}/webinar/${state.roomName}` : '';
    },
    getWebinarUnreadMessageCount(state) {
      return state.messages.filter((m) => m.unread && m?.type !== 'notification-message').length;
    },
    getUserById: (state) => (id) => {
      return state.userObject[id];
    },
    getViewerCount(state) {
      return state.users.filter((u) => u.role === 'viewer').length;
    },
    getParticipantCount(state) {
      return state.users.filter((u) => u.role !== 'viewer').length;
    },
  },
};
